<section class="catalogoDesk"
  *ngIf="id_cat !=91 && id_cat != 41 && id_cat != 105 && id_cat != 107 && id_cat != 21 && id_cat != 1822 && id_cat != 1824 && id_cat != 1825 && id_cat != 910 && id_cat != 111 && id_cat != 1175 && id_cat != 92 && id_cat != 1178 && id_cat != 224 && id_cat != 140 && id_cat != 1939 && id_cat != 81 && id_cat != 144 && id_cat != 61 && id_cat != 524 && id_cat != 183 && id_cat != 60 && id_cat != 59 && id_cat != 64 && id_cat != 393 && id_cat != 129 && id_cat != 93 && id_cat != 887 && id_cat != 22 && id_cat != 35 && id_cat != 20 && id_cat != 1426 && id_cat != 1424 && id_cat != 44 && id_cat != 72 && id_cat != 23 && id_cat != 137 && id_cat != 149 && id_cat != 75 && id_cat != 2028 && id_cat != 1924">

  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>

  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">





      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>




  <div class="contDesk" *ngIf="!cargando">

    <div class="title-cont" *ngIf="id_cat == 2254">
      <img src="/assets/img/Promociones.png" />
    </div>
    <!-- <div class="title-cont" *ngIf = "id_cat == 2639">
      <img src="/assets/img/mega-fin-crafter/Mega-Fin-Crafter-25-de-Octubre-2024-Banner-Seccion.png"/>
    </div> -->

    <div class="title-cont" *ngIf="id_cat != 2058">
      <h1 #focus>{{catalogo_cat}}</h1>
    </div>
    <div class="title-cont" *ngIf="id_cat == 2058">
      <img src="/assets/img/HotFest.jpg" alt="">
    </div>
    <div class="conten-cats">
      <div class="cardin" *ngFor="let producto of currentItemsToShow"
        (click)="navigateToDescripcionProducto(producto.category_link,producto.id_product,producto.link_rewrite,producto.paquete,producto.sku)">
        <!-- items [routerLink]="['/descripcion-producto',producto.id_product,producto.link_rewrite]" -->
        <div class="contImg">
          <img src="{{producto.image_link}}" alt="">
        </div>
        <div class="line-tb">
          <div *ngIf="producto?.detalles?.atributos?.[0]?.valores?.length > 1" class="colors-container">
            <div *ngFor="let color of producto?.detalles?.atributos?.[0]?.valores; let i = index" class="r-color"
              [ngStyle]="{'background-color': color.color, 'background-image': color.image ? 'url(' + color.image + ')' : 'none'}"
              [class.hidden]="!showAllColorsMap[producto.id_product] && i >= maxVisibleColors">
            </div>
            <div
              *ngIf="producto?.detalles?.atributos?.[0]?.valores?.length > maxVisibleColors && !showAllColorsMap[producto.id_product]"
              class="r-color more-colors" (click)="toggleShowAllColors(producto.id_product); $event.stopPropagation()">
              ...
            </div>
            <div *ngIf="showAllColorsMap[producto.id_product]" class="r-color more-colors up-arrow"
              (click)="toggleShowAllColors(producto.id_product); $event.stopPropagation()">
              ↑
            </div>
          </div>
        </div>
        <span class="cat-name">{{producto.title}}</span>
        <ng-container *ngIf="producto.reduction !== undefined && producto.reduction !== null else noReduction">
          <span class="pric">${{producto.precio - (producto.precio * producto.reduction) | number: '1.2-2' }} mxn</span>
          <del class="pric">{{producto.precio | currency: '$'}} mxn</del>
        </ng-container>
        <ng-template #noReduction>
          <span class="pric">{{producto.precio | currency: '$'}} mxn</span>
        </ng-template>
        @if(this.loginService.getUserData()){
        <button (click)="confirmarCompra(producto.id_product)"
          class="hidden lg:block p-2 border-2 border-solid text-[#17add6] border-[#17add6] hover:text-white rounded-md hover:bg-[#17add6] ">
          <i class="fa-solid fa-bag-shopping"></i>
          VISTA RAPIDA
        </button>
        }
      </div>
    </div>

    <label for="d" class="paginatorS">
      <mat-paginator (page)="onPageChange($event)" [pageSize]="24" [length]="tamanoLista"
        [pageSizeOptions]="[24,48,60,120]" aria-label="d" showFirstLastButtons></mat-paginator>
    </label>

  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat ==91">

  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',851,'almohadillas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/ALMOHADILLAS.jpg" alt="">
        </div>
        <span class="cat-name">ALMOHADILLAS</span>
        <!-- <span class="pric">{{producto.precio | currency: '$'}} mxn</span>  -->

      </a>
      <a class="cardin" [routerLink]="['/catalogo',852,'maletines-y-cubiertas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/MALETINES_Y_CUBIERTAS.jpg" alt="">
        </div>
        <span class="cat-name">MALETINES Y CUBIERTAS</span>
        <!-- <span class="pric">{{producto.precio | currency: '$'}} mxn</span>  -->

      </a>
      <a class="cardin" [routerLink]="['/catalogo',853,'resistencias']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/RESISTENCIAS.jpg" alt="">
        </div>
        <span class="cat-name">RESISTENCIAS</span>
        <!-- <span class="pric">{{producto.precio | currency: '$'}} mxn</span>  -->

      </a>
    </div>

    <!-- <div class="conten-cats">

    </div>

    <div class="conten-cats">

    </div> -->

    <!-- <label for="d" class="paginatorS">
      <mat-paginator (page)="onPageChange($event)" [pageSize]="12" [length]="tamanoLista" [pageSizeOptions]="[12,24,48,60,120]" aria-label="d" showFirstLastButtons></mat-paginator>
    </label> -->

  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 41">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>



  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1828,'resistencias']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/borradores.jpg" alt="">
        </div>
        <span class="cat-name">BORRADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1900,'cintas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cintas.jpg" alt="">
        </div>
        <span class="cat-name">CINTAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',854,'decorativos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos_Decorativo.jpg" alt="">
        </div>
        <span class="cat-name">DECORATIVOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',855,'doble-cara']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos_Doble-cara.jpg" alt="">
        </div>
        <span class="cat-name">DOBLE CARA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',856,'liquido']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos_Liquido.jpg" alt="">
        </div>
        <span class="cat-name">LIQUIDO</span>
      </a>
      <!-- <div class="cardin"  [routerLink]="['/catalogo',856]"></div> -->
      <a class="cardin" [routerLink]="['/catalogo',1156,'masking']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Masking.jpg" alt="">
        </div>
        <span class="cat-name">MASKING</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',857,'para-joyeria']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos_Joyeria.jpg" alt="">
        </div>
        <span class="cat-name">PARA JOYERIA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',858,'para-relieve']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos_Relieve.jpg" alt="">
        </div>
        <span class="cat-name">PARA RELIEVE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',859,'para-telas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos_Tela.jpg" alt="">
        </div>
        <span class="cat-name">PARA TELAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',860,'pistolas-de-silicón']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PISTOLAS-DE-SILICON.jpg" alt="">
        </div>
        <span class="cat-name">PISTOLAS DE SILICÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',861,'reposicionable']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Adhesivos-reposisionable.jpg" alt="">
        </div>
        <span class="cat-name">REPOSICIONABLE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',862,'silicon-caliente']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/SILICON-CALIENTE.jpg" alt="">
        </div>
        <span class="cat-name">SILICON CALIENTE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',863,'silicon-liquido']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/SILICON-LIQUIDO.jpg" alt="">
        </div>
        <span class="cat-name">SILICON LIQUIDO</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 54">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',869]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Silhouette.jpg" alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span> 
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 105">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>


  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',895,'acetatos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Acetato.jpg" alt="">
        </div>
        <span class="cat-name">ACETATOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',896,'cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartones.jpg" alt="">
        </div>
        <span class="cat-name">CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1899,'cartulinas-decoradas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulinas-decoradas.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS DECORADAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',897,'cartulinas-doble-vista']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulina-doble-vista.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS DOBLE VISTA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',898,'cartulinas-glitter']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulina-glitter.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS GLITTER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',899,'cartulinas-holograficas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulina-holografica.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS HOLOGRAFICAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',900,'cartulinas-lisas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulinas-lisas.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS LISAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',901,'cartulinas-lisaspremium']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulinas-lisas-premium.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS LISAS/PREMIUM</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',902,'cartulinas-metalicas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulinas-metalicas.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS METALICAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',903,'foamygoma-eva']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/foamy.jpg" alt="">
        </div>
        <span class="cat-name">FOAMY/GOMA EVA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',904,'glitter']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/glitter.jpg" alt="">
        </div>
        <span class="cat-name">GLITTER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',905,'papel-tipo-cuero']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PAPEL-TIPO-CUERO.jpg" alt="">
        </div>
        <span class="cat-name">PAPEL TIPO CUERO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',906,'papeles-con-adhesivo']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PAPELES-CON-ADHESIVO.jpg" alt="">
        </div>
        <span class="cat-name">PAPEL CON ADHESIVO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',907,'papeles-especiales']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PAPELES-ESPECIALES.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES ESPECIALES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',908,'premium']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PREMIUM.jpg" alt="">
        </div>
        <span class="cat-name">PREMIUM</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',909,'texturizadospremium']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/texturizados.jpg" alt="">
        </div>
        <span class="cat-name">TEXTURIZADOS/PREMIUM</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 107">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',917,'acuarelas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/acuarelas.jpg" alt="">
        </div>
        <span class="cat-name">ACUARELAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',918,'godetes']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/godetes.jpg" alt="">
        </div>
        <span class="cat-name">GODETES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',921,'herramientas-para-tintas-de-alcohol']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/HERRAMIENTAS-PARA-TINTAS-DE-ALCOHOL.jpg"
            alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA TINTAS DE ALCOHOL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',922,'kits']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/kits_.jpg" alt="">
        </div>
        <span class="cat-name">KITS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',923,'liensos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/liensos.jpg" alt="">
        </div>
        <span class="cat-name">LIENSOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',924,'pinceles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/pinceles.jpg" alt="">
        </div>
        <span class="cat-name">PINCELES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',925,'pinturas-acrilicas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PINTURAS-ACRILICAS.jpg" alt="">
        </div>
        <span class="cat-name">PINTURAS ACRILICAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',926,'tintas-de-alcohol']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TINTAS-DE-ALCOHOL2.jpg" alt="">
        </div>
        <span class="cat-name">TINTAS DE ALCOHOL</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 21">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',943,'almohadillas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/almohadillas.jpg" alt="">
        </div>
        <span class="cat-name">ALMOHADILLAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1827,'bases-de-acrílico']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/bases-de-acrilico.jpg" alt="">
        </div>
        <span class="cat-name">BASES DE ACRILICO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',944,'diy']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/diy.jpg" alt="">
        </div>
        <span class="cat-name">DIY</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',945,'herramientas-de-aplicación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/HERRAMIENTAS-DE-APLICACION.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS DE APLICACIÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',946,'mint-termicos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/MINT-(TERMICOS).jpg" alt="">
        </div>
        <span class="cat-name">MINT (TERMICOS)</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',947,'sellos-clasicos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/SELLOS-CLASICOS.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS CLASICOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',948,'sellos-de-goma']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/SELLOS-DE-GOMA.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS DE GOMA</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 1820">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',891]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CALIENTE.jpg" alt="">
        </div>
        <span class="cat-name">LAMINADORES</span> 
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 1821">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',890]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/KIT-INICIO-RHINESTONE.jpg" alt="">
        </div>
        <span class="cat-name">KITS</span> 
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 1822">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',911,'aditamentos']">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/aditamentos.jpg" alt="">
        </div>
        <span class="cat-name">ADITAMENTOS</span>
      </a>
      <!-- <div class="cardin"  [routerLink]="['/catalogo',912]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/fotobotonera.jpg" alt="">
        </div>
        <span class="cat-name">FOTOBOTONERA</span>
      </div> -->
      <a class="cardin" [routerLink]="['/catalogo',913,'fotobotoneras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/fotobotoneras.jpg" alt="">
        </div>
        <span class="cat-name">FOTOBOTONERAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',914,'fotobotones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/fotobotones.jpg" alt="">
        </div>
        <span class="cat-name">FOTOBOTONES</span>
      </a>
      <!-- <div class="cardin"  [routerLink]="['/catalogo',915]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Herramientas.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS</span>
      </div> -->
      <a class="cardin" [routerLink]="['/catalogo',916,'moldes']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/moldes.jpg" alt="">
        </div>
        <span class="cat-name">MOLDES</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 1823">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',929]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Resina-epoxica-silhouette.jpg" alt="">
        </div>
        <span class="cat-name">RESINA EPOXICA/SILHOUETTE</span> 
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 1824">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',931,'herramientas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/HERRAMIENTAS-R.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',930,'lentejuelas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/LENTEJUELAS.jpg" alt="">
        </div>
        <span class="cat-name">LENTEJUELAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',932,'piedras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Piedras.jpg" alt="">
        </div>
        <span class="cat-name">PIEDRAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',942,'plantillas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PLANTILLAS.jpg" alt="">
        </div>
        <span class="cat-name">PLANTILLAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 1825">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',874,'cortadores-rotatorios']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cortadores-rotatorios2.jpg" alt="">
        </div>
        <span class="cat-name">CORTADORES ROTATORIOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',875,'cuttersexactos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cutters-exactos.jpg" alt="">
        </div>
        <span class="cat-name">CUTTERS/EXACTOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',876,'guillotinas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/GUILLOTINAS.jpg" alt="">
        </div>
        <span class="cat-name">GUILLOTINAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',877,'guillotinastableros-de-dobles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/GUILLOTINAS-TABLEROS-DE-DOBLES.jpg" alt="">
        </div>
        <span class="cat-name">GUILLOTINAS/TABLEROS DE DOBLES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',878,'herramientas-de-encuadernado']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/HERRAMIENTAS-DE-ENCUADERNADO.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS DE ENCUADERNADO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',879,'kits']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/KITS.jpg" alt="">
        </div>
        <span class="cat-name">KITS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',880,'pinzas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PINZAS.jpg" alt="">
        </div>
        <span class="cat-name">PINZAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',882,'reglas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/REGLAS.jpg" alt="">
        </div>
        <span class="cat-name">REGLAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',883,'tableros-de-dobles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TABLERO-DE-DOBLES.jpg" alt="">
        </div>
        <span class="cat-name">TABLEROS DE DOBLES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',884,'tableros-de-dobles-y-corte']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TABLEROS-DE-DOBLES-Y-CORTE.jpg" alt="">
        </div>
        <span class="cat-name">TABLEROS DE DOBLES Y CORTE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',885,'tapetes-de-corte']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TAPETES-DE-CORTE2.jpg" alt="">
        </div>
        <span class="cat-name">TAPETES DE CORTE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',886,'tijeras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TIJERAS1.jpg" alt="">
        </div>
        <span class="cat-name">TIJERAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 910">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',868,'cortadores-de-esquinas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cortadores-de-esquina.jpg" alt="">
        </div>
        <span class="cat-name">CORTADORES DE ESQUINAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1904,'ojillado']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/OJILLADO.jpg" alt="">
        </div>
        <span class="cat-name">OJILLADO</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 111">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1905,'aditamentos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/aditamentos2.jpg" alt="">
        </div>
        <span class="cat-name">ADITAMENTOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1906,'blancos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/blancos.jpg" alt="">
        </div>
        <span class="cat-name">BLANCOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1907,'cintas-térmicas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/cinta-termica.jpg" alt="">
        </div>
        <span class="cat-name">CINTAS TERMICAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1908,'especiales']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/sublimacion-especiales.jpg" alt="">
        </div>
        <span class="cat-name">ESPECIALES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',889,'hojas-teflonadas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/hojas-teflonadas.jpg" alt="">
        </div>
        <span class="cat-name">HOJAS TEFLONADAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1909,'horno-3d-mini']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Horno-3d-mini.jpg" alt="">
        </div>
        <span class="cat-name">HORNO 3D MINI</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1910,'infusible-ink']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/infusible-ink.jpg" alt="">
        </div>
        <span class="cat-name">INFUSIBLE INK</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1911,'papeles-para-sublimar']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/papeles-para-sublimar.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES PARA SUBLIMAR</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1912,'tintas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TINTAS-sublimacion.jpg" alt="">
        </div>
        <span class="cat-name">TINTAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 1175">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1172,'estabilizadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/textiles-estabilizadores.jpg" alt="">
        </div>
        <span class="cat-name">ESTABILIZADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1173,'fieltro']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/textiles-fieltro.jpg" alt="">
        </div>
        <span class="cat-name">FIELTRO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1170,'gorras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/textiles-gorras.jpg" alt="">
        </div>
        <span class="cat-name">GORRAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1174,'mochilas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/textiles-mochilas.jpg" alt="">
        </div>
        <span class="cat-name">MOCHILAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 92">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1919,'cricut']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/ACCESORIOS-PARA-PLOTTER-CRICUT.jpg" alt="">
        </div>
        <span class="cat-name">CRICUT</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1915,'silhouette']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/ACCESORIOS-PARA-PLOTTERS-SILHOUETTE.jpg"
            alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 1177">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1920]"> items 
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CONSUMIBLES-PARA-3D-CREALITY.jpg" alt="">
        </div>
        <span class="cat-name">CREALITY</span>
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1921]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CONSUMIBLES-PARA-3D-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span> 
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 1177">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
       <div class="cardin"  [routerLink]="['/catalogo',1920]"> items 
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CONSUMIBLES-PARA-3D-CREALITY.jpg" alt="">
        </div>
        <span class="cat-name">CREALITY</span>
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1921]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CONSUMIBLES-PARA-3D-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span> 
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 1178">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1922,'cricut']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CONSUMIBLES-PARA-PLOTTER-cricut.jpg" alt="">
        </div>
        <span class="cat-name">CRICUT</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1923,'silhouette']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CONSUMIBLES-PARA-PLOTTER-SILHOUETTE.jpg"
            alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 71">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1008]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-acrilico.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA ACRÍLICO</span> 
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 70">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1019]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Grabado-en-cristal.jpg" alt="">
        </div>
        <span class="cat-name">GRABADO EN CRISTAL</span> 
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 523">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1022]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-cartones.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span> 
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 840">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1925]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Costura.jpg" alt="">
        </div>
        <span class="cat-name">COSTURA</span> 
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 134">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1023]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Resina.jpg" alt="">
        </div>
        <span class="cat-name">RESINA</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 1926">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',17]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/vinil-textil.jpg" alt="">
        </div>
        <span class="cat-name">VINIL TEXTIL</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 136">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1927]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Productos-para-Foil.jpg" alt="">
        </div>
        <span class="cat-name">PRODUCTOS PARA FOIL</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 66">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1062]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Adhesivos.jpg" alt="">
        </div>
        <span class="cat-name">ADHESIVOS</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 78">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1060]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-de-transferencia.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES DE TRANSFERENCIA</span>
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 224">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1046,'albums']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Albums.jpg" alt="">
        </div>
        <span class="cat-name">ALBUMS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1047,'artículos-de-papeleria']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Articulos-de-papeleria.jpg" alt="">
        </div>
        <span class="cat-name">ARTICULOS DE PAPELERIA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1048,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-cartones-Doodlebug.jpg"
            alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1049,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sellos-Doodlebug.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1051,'tarjetas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Tarjetas-Doodlebug.jpg" alt="">
        </div>
        <span class="cat-name">TARJETAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1052,'troqueles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Troqueles-Doodlebug.jpg" alt="">
        </div>
        <span class="cat-name">TROQUELES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1053,'washi-tape']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Washi-tape-Doodlebug.jpg" alt="">
        </div>
        <span class="cat-name">WASHI TAPE</span>
      </a>
      <!-- <div class="cardin"  [routerLink]="['/catalogo',1907]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/cinta-termica.jpg" alt="">
        </div>
        <span class="cat-name">CINTAS TERMICAS</span>
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1908]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/sublimacion-especiales.jpg" alt="">
        </div>
        <span class="cat-name">ESPECIALES</span> 
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',889]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/hojas-teflonadas.jpg" alt="">
        </div>
        <span class="cat-name">HOJAS TEFLONADAS</span> 
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1909]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Horno-3d-mini.jpg" alt="">
        </div>
        <span class="cat-name">HORNO 3D MINI</span>
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1910]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/infusible-ink.jpg" alt="">
        </div>
        <span class="cat-name">INFUSIBLE INK</span> 
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1911]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/papeles-para-sublimar.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES PARA SUBLIMAR</span> 
      </div>
      <div class="cardin"  [routerLink]="['/catalogo',1912]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/TINTAS-sublimacion.jpg" alt="">
        </div>
        <span class="cat-name">TINTAS</span>
      </div> -->
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 488">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1028]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Grabado-laser.jpg" alt="">
        </div>
        <span class="cat-name">GRABADO LASER</span>
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 140">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1075,'foil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Foil.jpg" alt="">
        </div>
        <span class="cat-name">Foil</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2133,'hojas-transportadoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Hojas-Trasportadoras-LIDEART.jpg" alt="">
        </div>
        <span class="cat-name">Hojas Transportadoras</span>
      </a>


      <a class="cardin" [routerLink]="['/catalogo',1076,'laminadoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Laminadoras.jpg" alt="">
        </div>
        <span class="cat-name">Laminadoras</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="catalogoDesk" *ngIf="id_cat == 934">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1077]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Embossing.jpg" alt="">
        </div>
        <span class="cat-name">Embossing</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 77">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1144]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Embossing.jpg" alt="">
        </div>
        <span class="cat-name">Impresión de Tarjetas PVC</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 143">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1020]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sublimación.jpg" alt="">
        </div>
        <span class="cat-name">Sublimación</span>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="catalogoDesk" *ngIf="id_cat == 522">
  <div class="contDesk">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin"  [routerLink]="['/catalogo',1025]">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles, cartulinas y cartones.jpg" alt="">
        </div>
        <span class="cat-name">Papeles, cartulinas y cartones</span>
      </div>
    </div>
  </div>
</section> -->

<section class="catalogoDesk" *ngIf="id_cat == 1939">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1937,'creality']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/IMPRESION-3D-CREALITY.jpg" alt="">
        </div>
        <span class="cat-name">Creality</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1938,'silhouette']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/IMPRESION-3D-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">Silhouette</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 81">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1106,'embossing']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Embossing-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">EMBOSSING</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1107,'glitters']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Glitters-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">GLITTERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1108,'herramientas-para-troquelado']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-troquelado-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA TROQUELADO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1109,'organizadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Organizadores-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">ORGANIZADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1947,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-carton-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1948,'plumas-marcadores-y-rotuladores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Plumas-marcadores-y-rotuladores-sizzix.jpg"
            alt="">
        </div>
        <span class="cat-name">PLUMAS, MARCADORES Y ROTULADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1112,'rhinestone']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Rhinestone-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">RHINESTONE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1113,'sellos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sellos-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1114,'shakers']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Shakers-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">SHAKERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1949,'stencil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Stencil-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">STENCIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1116,'troqueladoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Troqueladoras-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">TROQUELADORAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1117,'troqueles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Troqueles-sizzix.jpg" alt="">
        </div>
        <span class="cat-name">TROQUELES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 144">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',2015,'adhesivos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Adhesivos-american.jpg" alt="">
        </div>
        <span class="cat-name">ADHESIVOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2347,'guillotinas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Guillotinas.jpg" alt="">
        </div>
        <span class="cat-name">GUILLOTINAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1010,'organizadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Organizadores-american.jpg" alt="">
        </div>
        <span class="cat-name">ORGANIZADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1011,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-cartones-american.jpg"
            alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2344,'perforadoras-de-esquinas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/esquinas.jpg" alt="">
        </div>
        <span class="cat-name">PERFORADORAS DE ESQUINAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2345,'perforadoras-para-bordes']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Bordes.jpg" alt="">
        </div>
        <span class="cat-name">PERFORADORAS PARA BORDES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1012,'pinturas-y-pinceles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Pinturas-y-pinceles-american.jpg" alt="">
        </div>
        <span class="cat-name">PINTURAS Y PINCELES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2019,'plumas-marcadores-y-rotuladores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Plumas-marcadores-y-rotuladores-american.jpg"
            alt="">
        </div>
        <span class="cat-name">PLUMAS, MARCADORES Y ROTULADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1014,'sellos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sellos-Ame.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1015,'stickers']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Stickers-american.jpg" alt="">
        </div>
        <span class="cat-name">STIKERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2346,'tablas-perforadoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Tablas.jpg" alt="">
        </div>
        <span class="cat-name">TABLAS PERFORADORAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1016,'tarjetas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Tarjetas-american.jpg" alt="">
        </div>
        <span class="cat-name">TARJETAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1017,'washi-tape']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Washi-tape-american.jpg" alt="">
        </div>
        <span class="cat-name">WASHI TAPE</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 61">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1950,'accesorios-para-planchas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Accesorios-para-planchas-cricut.jpg" alt="">
        </div>
        <span class="cat-name">ACCESORIOS PARA PLANCHAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1951,'accesorios-para-plotters']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Accesorios-para-plotter-cricut.jpg" alt="">
        </div>
        <span class="cat-name">NAVAJAS Y ACCESORIOS PARA PLOTTERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1030,'consumibles-para-plotter']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/consumibles-PARA-PLOTTER-cricut.jpg" alt="">
        </div>
        <span class="cat-name">NAVAJAS Y CONSUMIBLES PARA PLOTTER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1952,'foil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Foil-cricut.jpg" alt="">
        </div>
        <span class="cat-name">FOIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1032,'herramientas-para-corte-manual-y-dobleses']"> <!-- items -->
        <div class="contImg">
          <img
            src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-corte-manual-y-dobleses-cricut.jpg"
            alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA CORTE MANUAL Y DOBLESES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1953,'herramientas-para-vinil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/HERRAMIENTAS-PARA-VINIL-CRICUT.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA VINIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2049,'hojas-de-alumino']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Cricut-hojas-de-aluminio.jpg" alt="">
        </div>
        <span class="cat-name">HOJAS DE ALUMINIO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1954,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-carton-cricut.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTÓNES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1035,'planchas-de-calor']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Planchas-de-calor-cricut.jpg" alt="">
        </div>
        <span class="cat-name">PLANCHAS DE CALOR</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1036,'plotters-de-corte']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/plotters-de-corte-cricut.jpg" alt="">
        </div>
        <span class="cat-name">PLOTTERS DE CORTE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1037,'plumas-marcadores-y-rotuladores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Plumas-marcadores-y-rotuladores-cricut.jpg"
            alt="">
        </div>
        <span class="cat-name">PLUMAS, MARCADORES Y ROTULADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2050,'stencil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Cricut-Stencil.jpg" alt="">
        </div>
        <span class="cat-name">STENCIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1038,'sublimacion']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sublimacion-cricut.jpg" alt="">
        </div>
        <span class="cat-name">SUBLIMACIÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1039,'tarjetas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Tarjetas-cricut.jpg" alt="">
        </div>
        <span class="cat-name">TARJETAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1955,'textiles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Textiles-Cricut.jpg" alt="">
        </div>
        <span class="cat-name">TEXTILES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1041,'vinil-textil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Vinil-textil-cricut.jpg" alt="">
        </div>
        <span class="cat-name">VINIL TEXTIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1042,'viniles-y-materiales-para-rotulación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Viniles-y-materiales-para-rotulacion-cricut.jpg"
            alt="">
        </div>
        <span class="cat-name">VINILES Y MATERIALES PARA ROTULACIÓN</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 524">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1043,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-cartones-dcwv.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1044,'tableros-para-notas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Tableros-para-notas.jpg" alt="">
        </div>
        <span class="cat-name">TABLEROS PARA NOTAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1045,'tarjetas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Tarjetas-DCWV.jpg" alt="">
        </div>
        <span class="cat-name">TARJETAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 183">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1054,'adhesivos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Adhesivos-ektools.jpg" alt="">
        </div>
        <span class="cat-name">ADHESIVOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1055,'herramientas-para-corte-manual-y-dobleses']"> <!-- items -->
        <div class="contImg">
          <img
            src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-corte-manual-y-dobleses-ektools.jpg"
            alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA CORTE MANUAL Y DOBLESES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1056,'herramientas-para-troquelado']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-troquelado-ektools.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA TROQUELADO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1057,'perforadoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Perforadora-ektools.jpg" alt="">
        </div>
        <span class="cat-name">PERFORADORAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1058,'plumas-marcadores-y-rotuladores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Plumas-marcadores-y-rotuladores-ektools.jpg"
            alt="">
        </div>
        <span class="cat-name">PLUMAS, MARCADORES Y ROTULADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1059,'texturizadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Texturizador-ektools.jpg" alt="">
        </div>
        <span class="cat-name">TEXTURIZADORES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 60">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1127,'accesorios-para-fotografía-y-video']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Accesorios-para-fotografia-y-video-we.jpg"
            alt="">
        </div>
        <span class="cat-name">ACCESORIOS PARA FOTOGRAFÍA Y VIDEO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1128,'albums']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Albums-we.jpg" alt="">
        </div>
        <span class="cat-name">ALBUMS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1129,'consumibles-y-accesorios-para-engargolado']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Consumibles-y-accesorios-para-engargolado-we.jpg"
            alt="">
        </div>
        <span class="cat-name">CONSUMIBLES Y ACCESORIOS PARA ENGARGOLADO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2002,'crop-a-diles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Crop-a-dile-WER.jpg" alt="">
        </div>
        <span class="cat-name">CROP A DILES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1131,'engargoladoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Engargoladoras-we.jpg" alt="">
        </div>
        <span class="cat-name">ENGARGOLADORAS</span>
      </a>
      <!-- <a class="cardin" [routerLink]="['/catalogo',1132,'glitters']">
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Glitter-wer.jpg" alt="">
        </div>
        <span class="cat-name">GLITTERS</span>
      </a> -->
      <a class="cardin" [routerLink]="['/catalogo',2004,'herramientas-manuales-todo-en-uno']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Todo-en-uno-WER.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS MANUALES TODO EN UNO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1134,'herramientas-para-corte-manual-y-dobleses']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-corte-manual-y-dobleses-we.jpg"
            alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA CORTE MANUAL Y DOBLESES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1135,'herramientas-para-vinil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Herramientas-para-vinil-wer.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS PARA VINIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1136,'organizadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Organizador-we.jpg" alt="">
        </div>
        <span class="cat-name">ORGANIZADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1137,'perforadoras']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Perforadoras-we.jpg" alt="">
        </div>
        <span class="cat-name">PERFORADORAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1139,'pines']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Pines-we.jpg" alt="">
        </div>
        <span class="cat-name">PINES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2003,'resina']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Resina-WER.jpg" alt="">
        </div>
        <span class="cat-name">RESINA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1141,'rhinestone']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Rhinestone-WER.jpg" alt="">
        </div>
        <span class="cat-name">RHINESTONE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1142,'sellos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sellos-wer.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1143,'stickers']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Stickers-WER.jpg" alt="">
        </div>
        <span class="cat-name">STICKERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2033,'troqueles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Troqueles-wer.jpg" alt="">
        </div>
        <span class="cat-name">TROQULES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 59">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1957,'accesorios-para-plotters']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/ACCESORIOS-PARA-PLOTTERS-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">ACCESORIOS PARA PLOTTERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1958,'consumibles-para-3d']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/CONSUMIBLES-PARA-3D-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">CONSUMIBLES PARA 3D</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1093,'consumible-para-plotter']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/CONSUMIBLES-PARA-PLOTTER-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">CONSUMIBLES PARA PLOTTER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1094,'cursos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/CURSOS-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">CURSOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1959,'impresión-3d']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/IMPRESION-3D-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">IMPRESIÓN 3D</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1095,'licencias-de-software']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Licencias-para-software-silhouette.jpg" alt="">
        </div>
        <span class="cat-name">LICENCIAS DE SOFTWARE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1960,'plotters-de-corte']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/PLOTTERS-DE-CORTE-SILHOUETTE.jpg" alt="">
        </div>
        <span class="cat-name">PLOTTERS DE CORTE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1961,'plumas-marcadores-y-rotuladores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/PLUMAS-MARCADORES-Y-ROTULADORES-SILHOUETTE.jpg"
            alt="">
        </div>
        <span class="cat-name">PLUMAS, MARCADORES Y ROTULADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1098,'repuestos-para-plotters']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Repuestos-para-plotter-silhouette.jpg" alt="">
        </div>
        <span class="cat-name">REPUESTOS PARA PLOTTERS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1099,'sellos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sellos-silhouette.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <!-- <a class="cardin"  [routerLink]="['/catalogo',2024,'sublimación']"> 
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sublimacion-silhouette.jpg" alt="">
        </div>
        <span class="cat-name">SUBLIMACIÓN</span>
      </a> -->
      <a class="cardin" [routerLink]="['/catalogo',2025,'textiles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Textiles-silhouette.jpg" alt="">
        </div>
        <span class="cat-name">TEXTILES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1962,'viniles-y-materiales-para-rotulación']"> <!-- items -->
        <div class="contImg">
          <img
            src="https://new.lideart.com.mx/img/categoria/por-marca/VINILES-Y-MATERIALES-PARA-ROTULACION-SILHOUETTE.jpg"
            alt="">
        </div>
        <span class="cat-name">VINILES Y MATERIALES PARA ROTULACIÓN</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 64">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1103,'sublimación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sublimacion-siser.jpg" alt="">
        </div>
        <span class="cat-name">SUBLIMACIÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1104,'vinil-textil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Vinil-textil-siser.jpg" alt="">
        </div>
        <span class="cat-name">VINIL TEXTIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2001,'viniles-y-materiales-para-rotulación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Vinil-y-materiales-para-rotulación-siser.jpg"
            alt="">
        </div>
        <span class="cat-name">VINIL Y MATERIALES PARA ROTULACIÓN</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 393">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1081,'adhesivos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/ADHESIVOS-RANGER.jpg" alt="">
        </div>
        <span class="cat-name">ADHESIVOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1082,'embossing']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/EMBOSSING-RANGER.jpg" alt="">
        </div>
        <span class="cat-name">EMBOSSING</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1964,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/PAPELES-CARTULINAS-Y-CARTONES-RANGER.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1084,'pinturas-y-pinceles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/PINTURAS-Y-PINCELES-RANGER.jpg" alt="">
        </div>
        <span class="cat-name">PINTURAS Y PINCELES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1085,'sellos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/SELLOS-RANGER.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1086,'texturizadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/TEXTURIZADORES-RANGER.jpg" alt="">
        </div>
        <span class="cat-name">TEXTURIZADORES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 129">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1072,'accesorios-para-planchas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Accesorios-para-planchas-lideart.jpg" alt="">
        </div>
        <span class="cat-name">ACCESORIOS PARA PLANCHAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1073,'planchas-de-calor']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Planchas-de-calor-lideart.jpg" alt="">
        </div>
        <span class="cat-name">PLANCHAS DE CALOR</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1074,'textiles']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Textiles-lideart.jpg" alt="">
        </div>
        <span class="cat-name">TEXTILES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 93">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',2045,'aplicación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Foil-Aplicacion.jpg" alt="">
        </div>
        <span class="cat-name">APLICACIÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1967,'calor']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/calor-foil.jpg" alt="">
        </div>
        <span class="cat-name">CALOR</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',46,'pulseras-para-eventos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/FOIL-QUILL.jpg" alt="">
        </div>
        <span class="cat-name">FOIL QUILL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1965,'impacto']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/IMPACTO-FOIL.jpg" alt="">
        </div>
        <span class="cat-name">IMPACTO</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1966,'reactivo-laser']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/REACTIVO-LASER-FOIL.jpg" alt="">
        </div>
        <span class="cat-name">REACTIVO LASER</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 887">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1968,'cricut']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CRICUT-HERRAMIENTAS-PARA-VINIL.jpg" alt="">
        </div>
        <span class="cat-name">CRICUT</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2046,'quelli']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Quelli-herramientas.jpg" alt="">
        </div>
        <span class="cat-name">QUELLI</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1969,'silhouette']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/SILHOUETTE-HERRAMIENTAS-PARA-VINIL.jpg"
            alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1970,'wer-memory-keepers']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/WER-HERRAMIENTAS-PARA-VINIL.jpg" alt="">
        </div>
        <span class="cat-name">WER MEMORY KEEPERS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 22">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1973,'almohadillas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/ALMOHADILLAS-EMBOSSING.jpg" alt="">
        </div>
        <span class="cat-name">ALMOHADILLAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1974,'papel-adhesivo-para-polvos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PAPEL-ADHESIVO-PARA-POLVOS-EMBOSSING.jpg"
            alt="">
        </div>
        <span class="cat-name">PAPEL ADHESIVO PARA POLVOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1975,'pistolas-de-calor']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PISTOLAS-EMBOSSING.jpg" alt="">
        </div>
        <span class="cat-name">PISTOLAS DE CALOR</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1976,'plumas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PLUMAS-EMBOSSING.jpg" alt="">
        </div>
        <span class="cat-name">PLUMAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1977,'polvos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/POLVOS-EMBOSSING.jpg" alt="">
        </div>
        <span class="cat-name">POLVOS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 35">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1971,'cricut']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/cricut-planchas-de-calor.jpg" alt="">
        </div>
        <span class="cat-name">CRICUT</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1972,'sublimarts']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/sublimarts-planchas-de-calor.jpg" alt="">
        </div>
        <span class="cat-name">SUBLIMARTS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 20">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1979,'cricut']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/cricut-plotters-de-corte.jpg" alt="">
        </div>
        <span class="cat-name">CRICUT</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1980,'silhouette']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/SILHOUETTE-PLOTTERS-DE-CORTE.jpg" alt="">
        </div>
        <span class="cat-name">SILHOUETTE</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 1426">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1981,'cricut']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/CRICUT-PMR.jpg" alt="">
        </div>
        <span class="cat-name">CRICUT</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1982,'foil-quill']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/foil-quill-PMR.jpg" alt="">
        </div>
        <span class="cat-name">FOIL QUILL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1984,'marcadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/MARCADORES-PMR.jpg" alt="">
        </div>
        <span class="cat-name">MARCADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1983,'marcadores-para-plotter']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/MARCADORES-para-plotter-PMR.jpg" alt="">
        </div>
        <span class="cat-name">MARCADORES PARA PLOTTER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1985,'marcadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/plumas-para-plotter-PMR.jpg" alt="">
        </div>
        <span class="cat-name">PLUMAS PARA PLOTTER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',108,'plumas-quill']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PLUMAS-QUILL.jpg" alt="">
        </div>
        <span class="cat-name">PLUMAS QUILL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',53,'plumas-y-marcadores']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/plumas-y-marcadores.jpg" alt="">
        </div>
        <span class="cat-name">PLUMAS Y MARCADORES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 1424">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1986,'aplicación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/aplicacion-productos-para-foil.jpg" alt="">
        </div>
        <span class="cat-name">APLICACIÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1987,'herramientas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/herramientas-productos-para-foil.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',109,'productos-foil-reactivo']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/productos-foil-reactivo.jpg" alt="">
        </div>
        <span class="cat-name">APLICACIÓN FOIL REACTIVO LÁSER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2034,'reactivos-laser']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Reactivos-laser-PRODUCTOS.jpg" alt="">
        </div>
        <span class="cat-name">REACTIVOS LASER</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 44">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1989,'decoración']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/RESINA-DECORACIONES.jpg" alt="">
        </div>
        <span class="cat-name">DECORACIONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1990,'epoxica']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/RESINA-EPOXICA.jpg" alt="">
        </div>
        <span class="cat-name">EPOXICA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1991,'herramientas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/resina-herramientas.jpg" alt="">
        </div>
        <span class="cat-name">HERRAMIENTAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1992,'moldes']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/resina-moldes.jpg" alt="">
        </div>
        <span class="cat-name">MOLDES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1993,'pigmentos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/resina-pigmentos.jpg" alt="">
        </div>
        <span class="cat-name">PIGMENTOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1994,'resina']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/resina-resina.jpg" alt="">
        </div>
        <span class="cat-name">RESINA</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1995,'tintas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/resina-tintas.jpg" alt="">
        </div>
        <span class="cat-name">TINTAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 72">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1996,'foil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Foil-Heidi.jpg" alt="">
        </div>
        <span class="cat-name">FOIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1065,'foil-heidi']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Laminadoras-Heidi.jpg" alt="">
        </div>
        <span class="cat-name">LAMINADORAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1066,'organizadores-heidi']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Organizadores-Heidi.jpg" alt="">
        </div>
        <span class="cat-name">ORGANIZADORES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1998,'productos-para-foil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Productos-para-foil-Heidi.jpg" alt="">
        </div>
        <span class="cat-name">PRODUCTOS PARA FOIL</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1068,'sellos-heidi']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sellos-Heidi.jpg" alt="">
        </div>
        <span class="cat-name">SELLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1069,'washi-tape-heidi']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Washi-tape-Heidi.jpg" alt="">
        </div>
        <span class="cat-name">WASHI TAPE</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 23">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',2005,'agujas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/agujas-costura.jpg" alt="">
        </div>
        <span class="cat-name">AGUJAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2007,'maquinas-de-coser']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Maquinas-de-coser-costura.jpg" alt="">
        </div>
        <span class="cat-name">MAQUINAS DE COSER</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2008,'refacciones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Refacciones-costura.jpg" alt="">
        </div>
        <span class="cat-name">REFACCIONES</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 137">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1026,'sublimacion-colortex']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sublimacion-colortext.jpg" alt="">
        </div>
        <span class="cat-name">SUBLIMACIÓN</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2023,'vinil-textil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Vinil-Textil-colortext.jpg" alt="">
        </div>
        <span class="cat-name">VINIL TEXTIL</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 149">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',2020,'papeles-cartulinas-y-cartones']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Papeles-cartulinas-y-cartones-color.jpg" alt="">
        </div>
        <span class="cat-name">PAPELES, CARTULINAS Y CARTONES</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2022,'vinil-textil']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Vinil-Textil-color.jpg" alt="">
        </div>
        <span class="cat-name">VINIL TEXTIL</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 75">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',1120,'planchas-de-calor']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/PLANCHAS-DE-CALOR-sublimarts.jpg" alt="">
        </div>
        <span class="cat-name">PLANCHAS DE CALOR</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',1121,'sublimación']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-marca/Sublimación-sublimarts.jpg" alt="">
        </div>
        <span class="cat-name">SUBLIMACIÓN</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 2028">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',2029,'arillos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/ARILLOS-consumibles.jpg" alt="">
        </div>
        <span class="cat-name">ARILLOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2031,'discos']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/DISCOS-consumibles.jpg" alt="">
        </div>
        <span class="cat-name">DISCOS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2030,'pastas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/PASTAS-consumibles.jpg" alt="">
        </div>
        <span class="cat-name">PASTAS</span>
      </a>
    </div>
  </div>
</section>

<section class="catalogoDesk" *ngIf="id_cat == 1924">
  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>


  </div>
  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>{{catalogo_cat}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo',2035,'cartulinas-decoradas']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Cartulinas-decoradas-decoraciones.jpg" alt="">
        </div>
        <span class="cat-name">CARTULINAS DECORADAS</span>
      </a>
      <a class="cardin" [routerLink]="['/catalogo',2036,'masking']"> <!-- items -->
        <div class="contImg">
          <img src="https://new.lideart.com.mx/img/categoria/por-producto/Masking-decoraciones.jpg" alt="">
        </div>
        <span class="cat-name">MASKING</span>
      </a>
    </div>
  </div>
</section>


<div class="quita-ionic-content cont-categoria-cel" *ngIf="isCel && cargando">

  <a class="card-catalogo" id="card-link" target="_blank">
    <div class="card__header">
      <div>
        <img class="header__img skeleton" id="logo-img" alt="" />
      </div>
      <h3 class="card__header header__title" id="card-title">
        <div class="skeleton skeleton-text"></div>
        <div class="skeleton skeleton-text"></div>
      </h3>
    </div>

    <div class="card__body">
      <div class="card__body body__text" id="card-details">
        <div class="skeleton skeleton-text skeleton-text__body"></div>
      </div>

      <div class="card__body body__img">
        <img class="skeleton" alt="" id="cover-img" />
      </div>
    </div>

    <div class="card__footer" id="card-footer">
      <div class="skeleton skeleton-text skeleton-footer"></div>
    </div>
  </a>
  <a class="card-catalogo" id="card-link" target="_blank">
    <div class="card__header">
      <div>
        <img class="header__img skeleton" id="logo-img" alt="" />
      </div>
      <h3 class="card__header header__title" id="card-title">
        <div class="skeleton skeleton-text"></div>
        <div class="skeleton skeleton-text"></div>
      </h3>
    </div>

    <div class="card__body">
      <div class="card__body body__text" id="card-details">
        <div class="skeleton skeleton-text skeleton-text__body"></div>
      </div>

      <div class="card__body body__img">
        <img class="skeleton" alt="" id="cover-img" />
      </div>
    </div>

    <div class="card__footer" id="card-footer">
      <div class="skeleton skeleton-text skeleton-footer"></div>
    </div>
  </a>
  <a class="card-catalogo" id="card-link" target="_blank">
    <div class="card__header">
      <div>
        <img class="header__img skeleton" id="logo-img" alt="" />
      </div>
      <h3 class="card__header header__title" id="card-title">
        <div class="skeleton skeleton-text"></div>
        <div class="skeleton skeleton-text"></div>
      </h3>
    </div>

    <div class="card__body">
      <div class="card__body body__text" id="card-details">
        <div class="skeleton skeleton-text skeleton-text__body"></div>
      </div>

      <div class="card__body body__img">
        <img class="skeleton" alt="" id="cover-img" />
      </div>
    </div>

    <div class="card__footer" id="card-footer">
      <div class="skeleton skeleton-text skeleton-footer"></div>
    </div>
  </a>

</div>


<div class="modal-c-wp" #modal_wp id="modal_wp">
  <div class="btn-wp-cont">
    <button class="btn-wp-close" (click)="handleWP()">X</button>
  </div>
  <div style="min-height: 15em;" *ngIf="cargandoProductoInfo ==1">
    <!-- <div class="cont-back-btn">
        <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
      </div> -->
    <div class="columnas">
      <ion-row>
        <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
          <ion-card *ngFor="let producto of productoData"
            style="box-shadow: none;border: none; -webkit-margin-start: 0px; margin-inline-start: 0px;">
            <!-- <div *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></div> -->
            <!-- <ion-text *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></ion-text>  -->


            <ion-text *ngIf="cargandoProductoInfo == 1" class="titulillo">{{producto.title}}</ion-text>

            <div class="swiper-container-wrapper">

              <div class="swiper-container" id="swiperEscritorio">
                <img src="{{producto.image_link}}" alt="">
              </div>
            </div>


          </ion-card>
        </ion-col>



        <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
          <ion-card *ngFor="let producto of productoData"
            style="box-shadow: none; border:none; overflow: unset; -webkit-margin-start: 0px; margin-inline-start: 0px;">
            <ion-card-content>
              <ion-grid>
                <ion-row>

                  <ion-col *ngIf="cargandoPrecio == 0">
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-col>



                  <ion-col *ngIf="!descuentillo && cargandoPrecio == 1 && precioDyn >0">
                    <ion-text class="precio">${{precioDyn | number: '1.2-2'}}</ion-text>
                    <span
                      style="font-size: 14px;color: rgb(29, 171, 209);font-family: 'RobotoCondensed-Light';text-transform: uppercase;">
                      mxn </span>
                  </ion-col>

                  <ion-col *ngIf="!descuentillo && cargandoPrecio == 1 && precioDyn ==0">
                    <ion-text class="precio" style="color: red;">No Disponible</ion-text>

                  </ion-col>



                  <ion-col *ngIf="descuentillo && cargandoPrecio == 1 && precioDyn >0">

                    <ion-text class="text">${{precioDyn*descuento | number: '1.2-2'}} <del>${{precioDyn | number:
                        '1.2-2'}}mxn </del> - {{resdesc*100 | number: '1.2-2'}}% de descuento</ion-text>

                  </ion-col>

                  <ion-col *ngIf="descuentillo && cargandoPrecio == 1 && precioDyn ==0">
                    <ion-text class="precio" style="color: red;">No Disponible</ion-text>

                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-text style="font-size: 12px; font-family: 'RobotoCondensed-Light';color: rgb(51,51,51);">IVA
                      incluido</ion-text>
                  </ion-col>
                </ion-row>

                <div
                  style="border-top: 1px solid #eaeaea;border-bottom: 1px solid #eaeaea; color: #333333; padding-top: 12px;padding-bottom: 12px;font-size: 18px;">
                  <div>
                    <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                      <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.2em 0.5em;">Código</dt>
                      <dt id="referencia"
                        style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; color: #111; font-size: .9em; padding: 0.2em 0.5em;">
                        {{ producto.sku }}</dt>
                    </dl>
                    <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                      <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.2em 0.5em;">
                        Color
                      </dt>
                      <dt
                        style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; color: #111; font-size: .9em; position: relative;"
                        class="line-tb">
                        {{colorSeleccionado}}

                        <div class="r-color" style="background-color: {{varColorSelected}};"></div>
                        <div class="r-color" style="background-image: url ({{varColorSelected}})"></div>
                      </dt>
                    </dl>
                  </div>

                </div>

                <ion-row>
                </ion-row>
                <ion-row id="existencia">
                  <ion-col>
                    <ion-text class="text">Existencia:</ion-text>
                  </ion-col>
                  <ion-col>
                    <ion-col *ngIf=" cargandoInventario == 0">
                      <ion-list>
                        <ion-item>
                          <ion-label>
                            <h3>
                              <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            </h3>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </ion-col>

                    <ion-text class="text" *ngIf="existencias>0 && cargandoInventario == 1"
                      style="color:green">Disponible</ion-text>
                    <ion-text class="text" *ngIf="existencias<=0 && cargandoInventario == 1"
                      style="color:red">Agotado</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row id="existenciaDesk">
                  <div class="conten-exist">

                    <span class="lefti">Existencia:</span>
                    <ion-col *ngIf=" cargandoInventario == 0">
                      <ion-list>
                        <ion-item>
                          <ion-label>
                            <h3>
                              <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            </h3>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                    <span class="rigti" *ngIf="existencias>0 && cargandoInventario == 1"
                      style="color:green">{{muestroStock}}</span>
                    <span class="rigti" *ngIf="existencias<=0 && cargandoInventario == 1" style="color:red">No
                      Disponible</span>
                  </div>
                </ion-row>
                <ion-row id="colorSeleccionado">
                  <ion-col>
                    <ion-text class="text">Color Seleccionado:</ion-text>
                  </ion-col>
                  <ion-col>
                    <p class="text"><span>{{colorSeleccionado}}</span></p>
                  </ion-col>
                </ion-row>
                <ion-row>

                  <div *ngFor="let atributo of atributos" style="width: 100%;">
                    <ion-col>
                      <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                    </ion-col>
                    <ion-col>
                      <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop">
                        <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                          class="selector-color id_group_{{atributo.id_attribute_group}}">
                          <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)"
                            style="background-color: {{valor.color}}">
                          <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.image)"
                            style="background-image: url({{valor.image}});">

                          <div class="preview">
                            <div class="diva-color-p" style="background-color: {{valor.color}};"></div>
                            <span class="name-color-p">{{valor.name}}</span>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop-list">
                        <div class="conten-names-opts">
                          <button class="btn-list-opt" *ngFor="let valor of atributo.valores"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)">{{valor.name}}</button>
                        </div>
                      </div>

                      <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos"
                        (change)="cambiaSku()" class="form-group {{atributo.id_attribute_group}}">
                        <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}
                        </option>
                      </select>
                    </ion-col>
                  </div>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div>

                    </div>
                  </ion-col>
                  <ion-col>
                  </ion-col>

                </ion-row>



                <!-- <button id="irCarrito" routerLink="/cart"  style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;" >Ir a Carrito</button> -->

                <!-- <button style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;" *ngIf="usuario" id="wishlist" (click)="agregarWishlist()" >Agregar a Wishlist</button> -->

              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col size="12" size-md="6" size-lg="4">
          <ion-card *ngFor="let producto of productoData"
            style="box-shadow: none;border: none; height: min-content; -webkit-margin-start: 0px; margin-inline-start: 0px;">
            <ion-row>
              <ion-col>
                <ion-label style="font-family: 'RobotoCondensed-Light'; font-weight: bold;">
                  CANTIDAD
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>
                  <ion-item>
                    <input type="number" min="1" [(ngModel)]="cantidad" name="cantidad" class="inp-manage">
                  </ion-item>
                </ion-label>
              </ion-col>
            </ion-row>

            <!-- <div *ngIf="existencias==0" class="nohay-cont">
              <span class="textin">Producto Agotado</span>
              <i class="fa-solid fa-face-sad-tear"></i>
            </div> -->
            <br>
            <button
              style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
              *ngIf="existencias>0 && cargoprecio == true && precioDyn>0" id="addCarrito"
              (click)="comprarRegalo2(productoData[0])">Añadir a Carrito</button>

            <button
              style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
              *ngIf="existencias>0 && cargoprecio == true && precioDyn>0" id="addCarrito"
              (click)="comprarRegalo(productoData[0])">Ir a Comprar</button>

          </ion-card>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>




<!--<div class="quita-ionic-content cont-categoria-cel" *ngIf="!cargando">

  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <ion-grid class="display-grid" >
    <ion-row>
      <ion-col size="12" size-md="6" size-lg="4" size-xl="3"  style="display: flex; flex-wrap: wrap; justify-content: center;">
        <div *ngFor="let producto of items" (click)="navigateToDescripcionProducto(producto.category_link,producto.id_product,producto.link_rewrite)" style="padding-top: 5px;width: 270px ;margin-top: 20px; border-radius: 10px;height: 25em; display: flex; flex-direction: column; justify-content: center; align-items: center; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
          <img class="imgItem" src="{{producto.image_link}}" width="250px" height="250px">
          <div class="fixin">{{producto.title}}</div>
          <p class="ion-text-center"><b style="color:#5dc0e0!important;font-size: 1.3em; font-weight: 400;">${{producto.precio | number: '1.2-2'}} mxn</b></p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button (click)="onIonInfinite($event)" expand="block">Ver Mas Productos</ion-button>

  <ion-list></ion-list>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="onIonInfinite($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</div>-->



<!-- <div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div> -->