import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

import { NavigationStart, Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { Subscription } from 'rxjs';

/**
 * Componenete reutilizable para patantallas de carga. Cuenta con funcionalidad que previene de pantallas de carga infinitas.
 */
@Component({
  selector: 'app-modal-carga',
  standalone: true,
  templateUrl: './modal-carga.component.html',
  styleUrls: ['./modal-carga.component.scss'],
})

export class ModalCargaComponent implements OnChanges {
  /**
   * @param {boolean} isLoading Campo obligatorio. Aqui se coloca el estado que indica si se esta cargando.
   */
  @Input() isLoading: boolean = false;
  @Input() textillo = "";


  /**
   * @param {number=} ttl Campo opcional. Segundos de timeout para prevenir carga infinita. Por defecto 30. 
   */
  @Input() ttl?: number = 30;


  /**
   * @param {string=} alertTitle Campo opcional. Titulo que aparecera en la alerta. 
   */
  @Input() alertTitle?: string = "Ocurrió un error";


  /**
   * @param {string=} alertText Campo opcional. Texto que aparecera en la alerta. 
   */
  @Input() alertText?: string = "Sucedió algo inesperado, por favor vuelve a intentarlo más tarde.";

  /**
   * @param {SweetAlertIcon=} icon Campo opcional. Icono que aparecera en la alerta. 
   */
  @Input() icon?: SweetAlertIcon = "error";

  timeOut?: NodeJS.Timeout;
  private routerSubscription?: Subscription;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.clearTimeout();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading'] && changes['isLoading'].currentValue !== undefined) {
      this.isLoadingChanges(changes['isLoading'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  isLoadingChanges(bol: boolean): void {
    this.clearTimeout();
    if (bol) {
      this.timeOut = setTimeout(() => {
        Sentry.captureMessage("Se disparó una pantalla de carga infinita");
        Swal.fire(this.alertTitle, this.alertText, this.icon);
        this.isLoading = false;
      }, (this.ttl && this.ttl * 1000));
    }
  }

  private clearTimeout(): void {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = undefined;
    }
  }

}
