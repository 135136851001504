import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiBuscadorGoogleService } from '../../services/api-buscador-google.service';
import { HandleInpSearchService } from '../../services/handle-inp-search.service';
import { Item, searchResult } from '../../types/googleSearch';

@Component({
  selector: 'app-google-search',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  templateUrl: './google-search.component.html',
  styleUrls: ['./google-search.component.scss'],
})
export class GoogleSearchComponent implements OnInit {

  searchQuery!: string | any;


  query: string = '';
  results: searchResult = {
    kind: "",
    url: {
      type: "",
      template: ""
    },
    queries: {
      request: [
        {
          title: "",
          totalResults: "",
          searchTerms: "",
          count: 0,
          startIndex: 0,
          inputEncoding: "",
          outputEncoding: "",
          safe: "",
          cx: ""
        }
      ],
      nextPage: [], // Inicializa nextPage como un array vacío
      previousPage: []
    },
    context: {
      title: ""
    },
    searchInformation: {
      searchTime: 0,
      formattedSearchTime: "",
      totalResults: "",
      formattedTotalResults: ""
    },
    items: []
  };
  startIndex: number = 1;
  sortOrder: string = '';
  resultsPerPage: number = 10;
  currentPage: number = 1;
  isTodos: boolean = false;
  resultsPerPageForSelect: number = 10;
  showAllColors: boolean = false;
  coloresMostrados: string[] = [];
  imagesLoaded: boolean = false;

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private txtBuscadorService: HandleInpSearchService,
    private router: Router,
    public customSearchService: ApiBuscadorGoogleService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    /*this.route.queryParams.subscribe(params => {
      this.searchQuery = params['query'];
      this.performSearch(this.searchQuery);
    });*/
    /*this.txtBuscadorService.txtBuscador$.subscribe((txtb) => {
      this.searchQuery = txtb;
      this.performSearch(this.searchQuery);
    });*/

    // this.txtBuscadorService.txtBuscador$.subscribe((txtb) => {
    // loquetenia = txtb
    // if (loquetenia != traielbuscador) {
    //   this.searchQuery = traielbuscador
    // }else{
    //   this.searchQuery = loquetenia
    // }
    this.route.params.subscribe(params => {
      this.searchQuery = params['busqueda']
      this.performSearch(this.searchQuery);
    })
    this.currentPage = 1;

    // this.searchQuery = sessionStorage.getItem('searchQuery');

    // if (this.searchQuery) {

    //   this.performSearch(this.searchQuery!);
    // }
    // });

    // Handle back navigation
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('popstate', (event) => {
        const initialUrl = sessionStorage.getItem('initialUrl');
        if (initialUrl && this.router.url === '/google-search/' + this.query) {
          sessionStorage.removeItem('initialUrl');
          this.router.navigateByUrl(initialUrl); // Navigate back to the initial URL
        }
      });
    }
  }

  performSearch(query: string): void {
    if (query) {
      this.currentPage = 1;
      setTimeout(() => {
        const searchBox = document.getElementById('gsc-i-id1') as HTMLInputElement;
        searchBox.value = query;
        this.query = query;

        const searchButton = document.getElementById('gsc-search-button-v2') as HTMLButtonElement;
        //console.log(searchButton);
        searchButton.click();

      }, 1000);
    }
    /*if (query) {
      const cx = '72f3d3fcd275e4e7e'; // Replace with your search engine ID
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://cse.google.com/cse.js?cx=${cx}`;
      const s : any = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(script, s);

      setTimeout(() => {
        const searchBox = document.getElementById('gsc-i-id1') as HTMLInputElement;
        searchBox.value = query;
        const searchButton = document.getElementsByClassName('gsc-search-button-v2')[0] as HTMLButtonElement;
        //console.log(searchButton);
        searchButton.click();
      }, 1500); 
    }*/
  }

  onSearch(): void {
    // Reinicia valores al realizar una búsqueda
    // console.log('Iniciando búsqueda para:', this.query);
    this.startIndex = 1;
    this.results.items = [];
    this.results.queries.nextPage = [];
    this.results.queries.previousPage = [];

    const requests = [];
    const totalPages = Math.ceil(this.resultsPerPage / 10);

    for (let i = 0; i < totalPages; i++) {
      const start = this.startIndex + i * 10; // Calcula el índice inicial para cada página
      requests.push(this.customSearchService.realSearch(this.query, start, this.sortOrder));
    }

    forkJoin(requests).subscribe({
      next: (responses: searchResult[]) => {
        this.results.items = responses
          .flatMap((response) => response.items)
          .filter((item): item is Item => item !== undefined)
          .map((item: Item) => ({
            ...item,
            link: this.extractPath(item.link),
          }));

        const firstResponse = responses[0];
        this.results.queries.nextPage = firstResponse?.queries?.nextPage || [];
        this.results.queries.previousPage = firstResponse?.queries?.previousPage || [];
      }
    });
  }

  nextPage(): void {
    if (!this.results.queries.nextPage || this.results.queries.nextPage.length === 0) {
      console.warn('No hay más páginas siguientes.');
      return;
    }

    // Incrementa el índice inicial basado en resultsPerPage
    this.startIndex += this.resultsPerPage;

    const requests = [];
    const totalPages = Math.ceil(this.resultsPerPage / 10);

    for (let i = 0; i < totalPages; i++) {
      const start = this.startIndex + i * 10;
      requests.push(this.customSearchService.realSearch(this.query, start, this.sortOrder));
    }

    forkJoin(requests).subscribe((responses: searchResult[]) => {
      this.results.items = responses
        .flatMap(response => response.items)
        .filter((item): item is Item => item !== undefined)
        .map((item: Item) => ({
          ...item,
          link: this.extractPath(item.link)
        }));

      const firstResponse = responses[0];
      this.results.queries.nextPage = firstResponse?.queries?.nextPage || [];
      this.results.queries.previousPage = firstResponse?.queries?.previousPage || [];
      console.log('Next Page Loaded. Start Index:', this.startIndex);
    });
  }

  prevPage(): void {
    // Verificar si ya estamos en la primera página
    if (this.startIndex <= 1) {
      console.warn('Ya estás en la primera página.');
      return;
    }

    if (!this.results.queries.previousPage || this.results.queries.previousPage.length === 0) {
      console.warn('No hay más páginas anteriores.');
      return;
    }

    // Decrementa el índice inicial basado en resultsPerPage
    this.startIndex -= this.resultsPerPage;
    if (this.startIndex < 1) this.startIndex = 1; // Asegura que no baje de 1

    const requests = [];
    const totalPages = Math.ceil(this.resultsPerPage / 10);

    for (let i = 0; i < totalPages; i++) {
      const start = this.startIndex + i * 10;
      requests.push(this.customSearchService.realSearch(this.query, start, this.sortOrder));
    }

    forkJoin(requests).subscribe((responses: searchResult[]) => {
      this.results.items = responses
        .flatMap(response => response.items)
        .filter((item): item is Item => item !== undefined)
        .map((item: Item) => ({
          ...item,
          link: this.extractPath(item.link)
        }));

      const firstResponse = responses[0];
      this.results.queries.nextPage = firstResponse?.queries?.nextPage || [];
      this.results.queries.previousPage = firstResponse?.queries?.previousPage || [];
      console.log('Previous Page Loaded. Start Index:', this.startIndex);
    });
  }


  scrollUp(): void {
    setTimeout(() => {
      if (this.tableInput && this.tableInput.nativeElement) {
        this.tableInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.warn('tableInput o nativeElement no están definidos');
      }
    });
  }

  private extractPath(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return `/${parsedUrl.pathname}`;
    } catch (error) {
      return url;
    }
  }

  onResultsPerPageChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const value = parseInt(target.value, 10);

    this.resultsPerPageForSelect = value;

    if (value === 0) {
      // Lógica para mostrar todos los resultados
      this.resultsPerPage = parseInt(this.results.searchInformation.totalResults || '150', 10); // Establece un número grande
      this.isTodos = true;
      this.results.queries.nextPage = [];
    } else {
      this.resultsPerPage = value;
      this.isTodos = false;
    }

    // Reinicia la búsqueda y actualiza el índice inicial
    this.startIndex = 1;
    this.results.items = [];
    // this.results.queries.nextPage = [];
    this.results.queries.previousPage = [];
    this.onSearch();

    console.log('Results Per Page Changed:', this.resultsPerPage);
  }

  toggleShowColors(event: MouseEvent, link: string): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.coloresMostrados.includes(link)) {
      this.coloresMostrados = this.coloresMostrados.filter(itemLink => itemLink !== link);
    } else {
      this.coloresMostrados.push(link);
    }
  }

  onImagesLoaded() {
    this.imagesLoaded = true;
  }
  /*
  this.productoService.getDescripcionProducto(this.id_product).subscribe((data:any) =>{
  });

  
  */
}
