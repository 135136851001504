.check-step-cont{
  width: 100%;
  height: auto;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .step-1-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    .titles-steper{
      color: #0b9dc7;
      font-weight: 100;
      text-shadow: 2px 2px 1px #d1d1d1;
      font-size: 1.5em;
      text-align: center;
    }

    .direct-cont{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //padding: 1em;
      gap: 1em;
      box-shadow: 0px 0px 10px 1px #1da8ce52;
      border-radius: 1.1em;
      overflow: hidden;


      input[type="radio"]{
       display: flex;
       justify-content: center;
       align-items: center;
       margin-left: 20px;
      }

      input[type="radio"]:checked + label{
        background-color: #c6f4ff;
      }

      .inpa-cont-dir{
        //width: 10%;
        height: auto;

        .radio-dir{
          width: 1.2em;
          height: 1.2em;
        }
      }

      .dir-data-cont{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: .5em;
        background-color: #fff;
        padding: 1em;
        cursor: pointer;
        transition: .3s all ease-in-out;

        .roxi-cont-dir{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          .col-data-dir{
            width: 50%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .hedin-data-txt{
              color: #0cacd1;
              font-weight: 400;
              font-size: 1em;          
            }

            .direc{
              color: #000;
              font-weight: 100;
              font-size: .9em;
            }
          }
        }
      }
    }

    .direct-cont:hover{
      background-color: #c6f4ff;
    }

    .dir-data-cont:hover{
      background-color: #c6f4ff;
    }

    


    .divbotones{
      display: flex;
      flex-direction: row;

      .continuar-checkout{
        flex-direction: column;
        border: 1px solid #0ebde5;
        border-radius: 3em;
        color: #0ebde5;
        font-size: 1.1em;
        background: #fff;
        padding: .7em 3em;
        margin-bottom: 1em;
        transition: .4s all ease-in-out;
        margin: 5px;
      }
  
      .continuar-checkout:hover{
        border: 1px solid #ffffff;
        color: #fff;
        background: #0ebde5;
      }
    }


  }

  .step-2-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    .titles-steper{
      color: #0b9dc7;
      font-weight: 100;
      text-shadow: 2px 2px 1px #d1d1d1;
      font-size: 1.5em;
      text-align: center;
    }

    .envio-cont{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;

      .opt-cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        //padding: 1em;
        //gap: 1em;
        box-shadow: 0px 0px 10px 1px #1da8ce52;
        border-radius: 1.1em;
        overflow: hidden;

     

        input[type="radio"]{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
         }

         input[type="radio"]:hover + label{
          background-color: #c6f4ff;
        }
  
        input[type="radio"]:checked + label{
          background-color: #c6f4ff;
        }

        .txt{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          gap: .3em;
          transition: .3s all ease-in-out;
          background-color: #fff;
          padding: 1em;
          cursor: pointer;
        }
      }
    }

    input[type="radio"]:hover + label{
      background-color: #c6f4ff;
    }
    .opt-cont:hover{
      background-color: #c6f4ff;
    }

    .continuar-checkout{
      border: 1px solid #0ebde5;
      border-radius: 3em;
      color: #0ebde5;
      font-size: 1.1em;
      background: #fff;
      padding: .7em 3em;
      margin-bottom: 1em;
      transition: .4s all ease-in-out;
    }

    .continuar-checkout:hover{
      border: 1px solid #ffffff;
      color: #fff;
      background: #0ebde5;
    }

    .back-checkout{
      background-color: #09afd5;
      color: #fff;
      border-radius: 2em;
      padding: .7em 2em;
      font-size: .8em;
      transition: .4s all ease-in-out;
      border: 1px solid #fff;
    }

    .back-checkout:hover{
      background-color: #fff;
      color: #09afd5;
      border: 1px solid #09afd5;
    }
  }

  .step-3-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    .titles-steper{
      color: #0b9dc7;
      font-weight: 100;
      text-shadow: 2px 2px 1px #d1d1d1;
      font-size: 1.5em;
      text-align: center;
    }

    .pago-cont{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1em;
      margin-bottom: 4em;

      .terms-cont{
        border-top: 1px solid #00c1f938;
        padding-top: 1em;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .txt-terms{
          width: 85%;
          text-align: center;
          color: #000;
          font-weight: 100;
          font-size: .9em;

          a{
            color: #08b5e7;
          }
        }
      }

      .debestc{
        color: #ff5c6a;
        font-weight: bold;
        font-size: 1em;
        text-align: center;
        width: 100%;
      }

      .suma-total{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1em;
        border-bottom: 1px solid #00c1f938;
        padding-bottom: 1em;

        .txt-mon{
          color: #09afd5;
          font-weight: 300;
          font-size: 1.2em;
        }

        .mon{
          font-weight: 400;
          font-size: 1em;
          color: #000;
        }
      }

      .facturas-cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;

        .txt-cont-facturas{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 1em;

          .txt-facts{
            color: #ff5c6a;
            font-weight: 500;
            font-size: 1.2em;
            text-shadow: 2px 2px 1px #ff5c6a47;
          }
        }
      }

      .cont-opts-acord{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .opt-acord{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .up{
            width: 100%;

            input[type="radio"]{
              display: none;
            }

            input[type="radio"]:disabled + label{
              background-color: #000;
            }

            label{
              width: 100%;
              background-color: #09afd5;
              color: #fff;
              text-align: start;
              display: block;
              padding: .7em 1em;
              font-size: 1em;
              font-weight: 100;
              cursor: pointer;
            }
          }

          .compra-ya{
            background-color: #ff5c6a;
            color: #fff;
            border-radius: 3em;
            font-size: .95em;
            padding: .6em 1.8em;
            font-weight: 100;
          }

          .compra-ya:disabled {
            background-color: #cccccc; /* Color de fondo para el estado deshabilitado */
            color: #666666; /* Color del texto para el estado deshabilitado */
            cursor: not-allowed; /* Cambia el cursor para indicar que está deshabilitado */
            opacity: 0.6; /* Reduce la opacidad para dar un efecto visual de deshabilitado */
          }

          .abirwp{
            background-color: #ff5c6a;
            color: #fff;
            border-radius: 3em;
            font-size: .95em;
            padding: .6em 1.8em;
            font-weight: 100;
          }

          .modal-c-wp{
            width: 320px;
            height: 500px;
            position: fixed;
            overflow-y: auto;
            display: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: #fff;
            top: calc(50% - 250px);
            left: calc(50% - 160px);
            z-index: 99;
            box-shadow: 0px 0px 12px 1px #42dcffbf;

            .btn-wp-cont{
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-start;

              .btn-wp-close{
                background-color: #df0b0b;
                color: #fff;
                font-size: 1.1em;
                padding: .7em 2em;
              }
            }
          }
        }
      }
    }

    .back-checkout-3{
      background-color: #fff;
      color: #ff5c6a;
      border-radius: 2em;
      padding: 0.7em 2em;
      font-size: 0.8em;
      border: 1px solid #ff5c6a;
      transition: .4s all ease-in-out;
    }

    .back-checkout-3:hover{
      background-color: #ff5c6a;
      color: #fff;
      border: 1px solid #ffffff;
    }
  }
}

@media(max-width: 992px){
  .check-step-cont{
    .step-1-cont{
      .direct-cont{
        .dir-data-cont{
          .roxi-cont-dir{
            flex-direction: column;
            justify-content: flex-start;
            gap: .5em;

            .col-data-dir{
              width: 100%;
              gap: .2em;
            }
          }
        }
      }
    }

    
  }
}

@media(min-width: 993px){
  .check-step-cont{
    .step-1-cont{
      width: 40em;
    }

    .step-2-cont{
      width: 34em;
    }

    .step-3-cont{
      width: 30em;
    }
  }
}

img.ng-lazyloaded {
  animation: fadein 1.5s;
}

.mat-step-icon-selected {
  background-color: #00c1f9!important;
}
  
  
  ion-button {
    --box-shadow: transparent !important;
  }
  
  button {
    //background-color: transparent !important;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }

  ion-select{
    color: #fff;
  }
  
  ion-searchbar {
    --icon-color: #1ca3c9;
    width: 95%;
    margin-left: 10px;
  }
  
  .search-container {
    width: 90vw;
    right: 5vw;
    height: auto;
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;
  }
  
  .line-filter {
    text-align: center;
    line-height: 20px;
    background: #70c2d1;
    padding: 0;
    margin: 0;
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .header-ios ion-toolbar:last-of-type {
    --border-width: 0;
  }
  .header-translucent-ios ion-toolbar {
    --opacity: 1;
  }
  
  ion-img::part(image) {
  width: 100px;
  height: 30px;
  }
  
  
  .redes img{
   height: 30px;
   width: 30px;
  }
  
  .redes{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .marcas img{
    height: 150px;
    width: 150px;
    text-align: center;
    margin: 0px;
    
  }
  
  .marcas{
    text-align: center;
  }
  
  .bottomBanner img{
    height: auto;
    width: 100%;
  }
  
  .imagensita{
    height: auto!important;
    width: auto!important;
  }

  .imageNumero{
      height: 60px;
      width: 100px; 
  }

  .imageNumero2{
    height: 50px;
    width: 50px; 
}

.noquiero{
    text-align: start;
}

.imagencuatro{
    height: 50px;
    width: 50px; 
}

.imagentres{
    height: 60px;
    width: 60px; 


}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  margin-right: 5px;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  width: .70em;
  height: .70em;
}


// ion-content{
//   --ion-background-color: url('../../../assets/img/tile_background2.png')!important; //rgb(189, 193, 194) !important

// }

.part5{

  width: 100%;

  height: auto;

  display: flex;

  flex-direction: row;

  justify-content: center;

  align-items: center;

  padding: 1rem 0rem;

  gap: 1em;




  ion-checkbox{

    width: 1.5em;

    height: 1.5em;

  }




  p{

    font-size: 1em;

    max-width: 80%;

  }

}


ion-card{
  
  margin: auto;
  width: 100%;
  padding: 10px;
  background: #fcfcfc;//     #fdfdfd            #14A8CD
}

.divis{
  margin: auto;
  width: 100%;
  padding: 10px;
  background: #fcfcfc;//     #fdfdfd            #14A8CD
}

ion-item{
  width: 100%;
  --background: none!important; //     #fdfdfd            #14A8CD
}

ion-radio-group{
  --background: none!important;
}

ion-list{
  --background: none!important;
}

.list-ios{
  background: none;
}

.list-md{
  background: none;
}

ion-list-header{
  --background: none!important;
}

.labelT{
  font-size: 6vw;
  color: rgb(0, 0, 0);
//   text-shadow: 0px 0px 8px rgba(0, 0, 0, 1),
//                0px 0px 12px rgba(0, 0, 0, 1);
}

.labelC{
  font-size: 4vw;
  color: rgb(0, 0, 0);
  // text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
}

.contR{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

// .cont{
//   --ion-background-color: url('../../../assets/img/tile_background2.png')!important; //rgb(189, 193, 194) !important 
// }

ion-content{
  --background: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  --keyboard-offset:0px!important;
}

.personal {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
  //background-image: black;

  ion-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: none;
    border-radius: none;
    background: #fff;

    form{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background: none;
      border-radius: none;
      background: #fff;
    }

    .part1{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      ion-card-title{
        font-size: .9rem;
        color: #00a3c7;
        padding: 1rem 0rem;
        font-weight: 300;
      }

      .cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .nin{
          width: 100%;
          height: auto;
          border: 1px solid rgb(84, 84, 84);

          input{
            background: none;
            width: 100%;
            height: 2.5rem;
            text-indent: 1rem;
            font-size: 1rem;
            border: 0px;
          }
        }

        input:focus{
          outline: none;
        }

        p{
          font-size: .75rem;
        }
      }
    }

    .part2{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0rem .5rem;

      .terms{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 1.5rem 0rem;

        .inp{
          width: 10%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .txt{
          width: 90%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          p{
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-style: italic;
            font-size: 1rem;
            text-align: justify;
          }
        }
      }
    }

    .btn{
      background: #00c0f6;
      color: #fff;
      border-radius: .5em;
      width: 15rem;
      height: 3rem;
      font-size: 1.3rem;
      font-weight: lighter;
      margin-top: 2rem;
      margin-bottom: 4rem;
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }
  }

}

.dir-cel-cont{
  width: 100%;
  height: auto;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1em;
  box-shadow: rgb(0 192 246 / 33%) 0px 0px 8px 0px;
  border-radius: 1em;
  padding: .5em;
  margin-bottom: 1em;

  .left-s{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .right-s{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.6em;
    
    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: #000;
      font-weight: 400;
      font-size: 1em;
    }
  }
}

.dir-cel-cont:last-child{
  margin-bottom: 0em;
}

.opts-me-cont{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1em;

  .custom-col{
    width: 100%;
    
    .paq{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //margin: 1.5rem 0rem;
  
      //box-shadow: 0 0 10px rgba(50, 50, 93, 0.25);
      transition: box-shadow 0.3s;
      padding: 10px;
      //margin-bottom: 20px;
  
      .inp{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
  
      .txt{
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
  
        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: .9rem;
          text-align: justify;
        }
      }
    }
  }
}

@media(max-width: 992px){
  .dir-cel-cont{
    display: flex;
  }
}

@media screen and ((max-width:992px)){
  .item-title{
    font-size: 1em !important;
    font-weight: normal !important;
    color: black !important;
    
  }
  
  .item-description{
    font-size: 1em !important;
    color: black !important;
  }
  
  .item-price{
    font-size: 1em !important;
    font-weight: normal !important;
    color: black !important;
  }

  .paq:hover{
    box-shadow: none !important;
  }
  #compraEscritorio{
    display: none;
  }

  .subtitulosSecciones{
    font-size: 1em;
  }

  #itemsDirecciones{
    box-shadow: none !important;
  }

  .nombreClienteDirecciones{
    margin-bottom: .3em;
  }

  #resumenEscritorio{
    display: none !important;
  }

  .direccion .part2{
    width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // border-top: 1px solid rgb(84, 84, 84);
      // border-bottom: 1px solid rgb(84, 84, 84);
     // padding: 1rem 0rem;
      padding-left: 4rem;
      border-bottom: 1px solid black;
      border-top: 1px solid black;     
  
      font-family: 'RobotoCondensed-Light';
      font-size: 1.5em;
  }
  .inps{
    width: 100%;
    height: 2.5rem;
    background: none;
    text-indent: 1rem;
    border-radius: 5rem;
    border: 1px solid #33a1c3;
    margin-bottom: .5rem;
  }
  
  .inps:focus{
    outline: none;
  }
  .selict{
    width: 100%;
    height: 2.5rem;
    background: none;
    padding: 0rem 1rem;
    border-radius: .5rem;
    border: 1px solid #33a1c3;
    margin-bottom: .5rem;
  }
  
  .selict:focus{
    outline: none;
  }
}

.direccion{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-image: black;

  ion-card{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: none;
    border-radius: none;
    background: #fff;

    .part1{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;     
      justify-content: flex-start;
      align-items: center;
      text-align: center;
    

      .titulosSecciones{
        font-size: .9rem;
        color: rgb(84, 84, 84);
        padding: 1rem 0rem;
        font-weight: 300;
      }

      p{
        font-size: .9rem;
      }
    }

    .part2{
      // width: 100%;
      // height: auto;
      // display: flex;
      // flex-direction: column;
      // justify-content: flex-start;
      // align-items: flex-start;
      // border-top: 1px solid rgb(223, 223, 223);
      // border-bottom: 1px solid rgb(223, 223, 223);
      // padding: 1rem 0rem;
      // padding-left: 4rem;

      .pas{
        margin-bottom: 2em;
      }

      .pas:last-child{
        margin-bottom: 0em;
      }

      p{
        margin-block-start: 0em;
        margin-block-end: 0em;
        font-size: .9rem;
      }
    }

    .part3{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem;

      .sub{
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        ion-icon{
          font-size: 1.2rem;
        }

        a{
          font-size: 1rem;
          text-decoration: none;
        }
      }

      .sub2{
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        ion-icon{
          font-size: 1.2rem;
        }

        a{
          font-size: 1rem;
          text-decoration: none;
        }
      }
    }

    .part4{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem;
      gap: 1em;

      .btn{
        background: #00c0f6!important;
        color: #fff!important;
        border-radius: .5em;
        width: 12rem;
        height: 3rem;
        font-size: 1rem;
        font-weight: lighter;
        margin-top: 2rem;
        margin-bottom: 4rem;
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      }

      .b1{
        margin-right: .5rem;
      }

      .b2{
        margin-left: .5rem;
      }
    }
  }
}

.envio{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-image: black;

  ion-card{
    width: 100%;
    height: 100%;
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: none;
    border-radius: none;
    background: #fff;

    .part1{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      ion-card-title{
        font-size: .9rem;
        color: rgb(84, 84, 84);
        padding: 1rem 0rem;
        font-weight: 300;
      }
    }

    .part4{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem;
      gap: 1em;

      .btn{
        background: #00c0f6!important;
        color: #fff!important;
        border-radius: .5em;
        width: 8rem;
        height: 3rem;
        font-size: 1rem;
        font-weight: lighter;
        margin-top: 2rem;
        margin-bottom: 4rem;
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      }

      .b1{
        margin-right: .5rem;
      }

      .b2{
        margin-left: .5rem;
      }
    }
  }
}

/*.paq:hover{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}*/

.pago{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-image: black;

  ion-card{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: none;
    border-radius: none;
    background: #fff;

    .part1{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      ion-card-title{
        font-size: .9rem;
        color: rgb(84, 84, 84);
        padding: 1rem 0rem;
        font-weight: 300;
      }
    }

    .pago{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: .3rem 0rem;

      .rowin{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1em;
      }

      .inp{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

      }

      .txt{
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        p{
          margin-block-start: .2em;
          margin-block-end: .2em;
          font-size: .9rem;
          text-align: justify;
        }
      }

      .paypalcont{
        width: 100%;
        height: auto;
        display: none;
      }
    }

    .fixink{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1em;
    }

    .terms{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 2rem;
      margin-top: 2rem;

      .titl{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: rgb(140, 140, 140);
        padding: 0rem .5rem;

        p{
          color: black;
          font-size: .9rem;
          font-weight: 400;
        }

        .icons{
          width: 10%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: black;
        }
      }
    }

    .part4{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem;
      gap: 1em;

      .btn{
        background: #00c0f6!important;
        color: #fff!important;
        border-radius: .5em;
        width: 8rem;
        height: 3rem;
        font-size: 1rem;
        font-weight: lighter;
        margin-top: 2rem;
        margin-bottom: 4rem;
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      }

      .b1{
        margin-right: .5rem;
      }

      .b2{
        margin-left: .5rem;
      }
    }


  }
}

.item-title{
  font-size: 2em;
  font-weight: bold;
  color: #333;
  
}

.item-description{
  font-size: 0.6em;
  color: #666;
}

.item-price{
  font-size: 0.75em;
  font-weight: bold;
  color: #0088a7;
}



.core {
  background: #cedce0;
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 10px rgba(2, 255, 255, 0.15) solid;
  animation: flicker 0.2s infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.core2 {
  background: #cedce0;
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 10px rgba(2, 255, 255, 0.15) solid;
  animation: flicker2 0.2s infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.big_core {
  background: #cedce0;
  width: 200px;
  height: 200px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 10px rgba(2, 255, 255, 0.15) solid;
  animation: big_flicker 0.2s infinite;
}

.c_ease {
  animation: colour_ease 3s infinite ease-in-out;
}

.counterspin5 {
  animation: rotate_anti 5s linear infinite;
}

.counterspin4 {
  animation: rotate_anti 4s linear infinite;
}

.semi_arc {
  width: 100px;
  height: 100px;
  border: 6px solid #02feff;
  background: rgba(2, 254, 255, 0.2);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  transform: rotateZ(0deg);
  transition: box-shadow 3s ease;
  text-align: center;
  line-height: 100px;
}

.semi_arc:hover {
  box-shadow: 0px 0px 30px rgba(2, 254, 255, 0.8);
  transition: 0.3s;
}

.semi_arc_2 {
  content: "";
  position: absolute;
  width: 94%;
  height: 94%;
  left: 3%;
  top: 3%;
  border: 5px solid #02feff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  animation: rotate 4s linear infinite;
  text-align: center;
  line-height: 129px;
}

.semi_arc_2:after {
  content: "";
  position: absolute;
  width: 94%;
  height: 94%;
  left: 3%;
  top: 3%;
  border: 4px solid #02feff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  animation: rotate_anti 2s linear infinite;
}

.semi_arc_3 {
  content: "";
  position: absolute;
  width: 94%;
  height: 94%;
  left: 3%;
  top: 3%;
  border: 5px solid #02feff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  animation: rotate 4s linear infinite;
  text-align: center;
  line-height: 129px;
}

.arc {
  width: 100px;
  height: 100px;
  border: 6px solid #02feff;
  background: rgba(2, 254, 255, 0.2);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transform: rotateY(-30deg) translateZ(-200px);
  -ms-transform: rotateY(-30deg) translateZ(-200px);
  -webkit-transform: rotateY(-30deg) translateZ(-200px);
  transform: rotateY(-30deg) translateZ(-200px);
  transform: rotateZ(0deg);
  transition: box-shadow 3s ease;
  text-align: center;
  line-height: 100px;
}

.arc:hover {
  box-shadow: 0px 0px 30px rgba(2, 254, 255, 0.8);
  transition: 0.3s;
}

.arc:after {
  content: "";
  position: absolute;
  width: 94%;
  height: 94%;
  left: 3%;
  top: 3%;
  border: 4px solid #02feff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  animation: rotate 4s linear infinite;
}

.e1:after {
  border-color: rgba(2, 255, 255, 0.6);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.e2:after {
  border-color: rgba(2, 255, 255, 0.6);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.e3 {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  animation: rotate 5s linear infinite;
}

.e3:after {
  border-color: rgba(2, 255, 255, 0.6);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.e4 {
  width: 150px;
  height: 150px;
}

.e4_1 {
  border-color: rgba(2, 255, 255, 0.3);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.e4_1:after {
  border-color: rgba(2, 255, 255, 0.6);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.e5 {
  width: 200px;
  height: 200px;
}

.e5_1 {
  color: rgba(2, 255, 255, 0.15);
  border: 2px solid;
  border-left: 2px solid transparent;
  animation: rotate 5s linear infinite;
}

.e5_2 {
  color: rgba(2, 255, 255, 0.7);
  border: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  animation: rotate_anti 4s linear infinite;
}

.e5_3 {
  color: rgba(2, 255, 255, 0.5);
  border: 2px solid;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  animation: rotate 3s linear infinite;
}

.e5_4 {
  color: rgba(2, 255, 255, 0.15);
  border: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  animation: rotate_anti 2s linear infinite;
}

.e6 {
  border-color: transparent;
  background: rgba(255, 255, 255, 0);
  width: 200px;
  height: 200px;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotate_anti {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes colour_ease {
  0% {
    border-color: #02feff;
  }
  50% {
    border-color: rgba(2, 254, 255, 0.5);
  }
  100% {
    border-color: #02feff;
  }
}
@keyframes flicker {
  0% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
  40% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
  50% {
    box-shadow: 0px 0px 16px 6px rgba(150, 255, 255, 0.5), inset 0px 1px 100px 2px rgba(21, 211, 233, 0.3);
  }
  60% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
  100% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
}
@keyframes flicker2 {
  0% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
  40% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
  50% {
    box-shadow: 0px 0px 50px 17px rgba(150, 255, 255, 0.5), inset 0px 1px 100px 2px rgba(21, 211, 233, 0.3);
  }
  60% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
  100% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
}
@keyframes big_flicker {
  0% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }
  40% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }
  50% {
    box-shadow: 0px 0px 35px 17px rgba(150, 255, 255, 0.5), inset 0px 1px 50px 40px rgba(21, 211, 233, 0.3);
  }
  60% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }
  100% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }
}
html,
body {
  height: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.arc_reactor {
  position: relative;
  top: 50%;
  margin-top: -125px;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0px 0px 50px 15px rgba(2, 255, 255, 0.3), inset 0px 0px 50px 15px rgba(2, 255, 255, 0.3);
}

.core2 {
  background: #cedce0;
  width: 110px;
  height: 110px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(2, 255, 255, 0.15);
  animation: flicker2 0.2s infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.e7 {
  width: 95.25%;
  height: 95.25%;
  left: 2.5475%;
  right: 2.5475%;
  border: 6px solid transparent;
  background: transparent;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  transform: rotateZ(0deg);
  transition: box-shadow 3s ease;
  text-align: center;
  line-height: 100px;
}

.case_container {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  position: absolute;
  margin-left: 20px;
  margin-top: 20px;
}

.marks li {
  display: block;
  width: 3px;
  height: 11px;
  background: rgba(2, 254, 255, 0.8);
  position: absolute;
  margin-left: 105px;
  margin-top: -110px;
  animation: colour_ease2 3s infinite ease-in-out;
}

@keyframes colour_ease2 {
  0% {
    background: #02feff;
  }
  50% {
    background: rgba(2, 254, 255, 0.3);
  }
  100% {
    background: #02feff;
  }
}
.marks li:first-child {
  transform: rotate(6deg) translateY(125px);
}

.marks li:nth-child(2) {
  transform: rotate(12deg) translateY(125px);
}

.marks li:nth-child(3) {
  transform: rotate(18deg) translateY(125px);
}

.marks li:nth-child(4) {
  transform: rotate(24deg) translateY(125px);
}

.marks li:nth-child(5) {
  transform: rotate(30deg) translateY(125px);
}

.marks li:nth-child(6) {
  transform: rotate(36deg) translateY(125px);
}

.marks li:nth-child(7) {
  transform: rotate(42deg) translateY(125px);
}

.marks li:nth-child(8) {
  transform: rotate(48deg) translateY(125px);
}

.marks li:nth-child(9) {
  transform: rotate(54deg) translateY(125px);
}

.marks li:nth-child(10) {
  transform: rotate(60deg) translateY(125px);
}

.marks li:nth-child(11) {
  transform: rotate(66deg) translateY(125px);
}

.marks li:nth-child(12) {
  transform: rotate(72deg) translateY(125px);
}

.marks li:nth-child(13) {
  transform: rotate(78deg) translateY(125px);
}

.marks li:nth-child(14) {
  transform: rotate(84deg) translateY(125px);
}

.marks li:nth-child(15) {
  transform: rotate(90deg) translateY(125px);
}

.marks li:nth-child(16) {
  transform: rotate(96deg) translateY(125px);
}

.marks li:nth-child(17) {
  transform: rotate(102deg) translateY(125px);
}

.marks li:nth-child(18) {
  transform: rotate(108deg) translateY(125px);
}

.marks li:nth-child(19) {
  transform: rotate(114deg) translateY(125px);
}

.marks li:nth-child(20) {
  transform: rotate(120deg) translateY(125px);
}

.marks li:nth-child(21) {
  transform: rotate(126deg) translateY(125px);
}

.marks li:nth-child(22) {
  transform: rotate(132deg) translateY(125px);
}

.marks li:nth-child(23) {
  transform: rotate(138deg) translateY(125px);
}

.marks li:nth-child(24) {
  transform: rotate(144deg) translateY(125px);
}

.marks li:nth-child(25) {
  transform: rotate(150deg) translateY(125px);
}

.marks li:nth-child(26) {
  transform: rotate(156deg) translateY(125px);
}

.marks li:nth-child(27) {
  transform: rotate(162deg) translateY(125px);
}

.marks li:nth-child(28) {
  transform: rotate(168deg) translateY(125px);
}

.marks li:nth-child(29) {
  transform: rotate(174deg) translateY(125px);
}

.marks li:nth-child(30) {
  transform: rotate(180deg) translateY(125px);
}

.marks li:nth-child(31) {
  transform: rotate(186deg) translateY(125px);
}

.marks li:nth-child(32) {
  transform: rotate(192deg) translateY(125px);
}

.marks li:nth-child(33) {
  transform: rotate(198deg) translateY(125px);
}

.marks li:nth-child(34) {
  transform: rotate(204deg) translateY(125px);
}

.marks li:nth-child(35) {
  transform: rotate(210deg) translateY(125px);
}

.marks li:nth-child(36) {
  transform: rotate(216deg) translateY(125px);
}

.marks li:nth-child(37) {
  transform: rotate(222deg) translateY(125px);
}

.marks li:nth-child(38) {
  transform: rotate(228deg) translateY(125px);
}

.marks li:nth-child(39) {
  transform: rotate(234deg) translateY(125px);
}

.marks li:nth-child(40) {
  transform: rotate(240deg) translateY(125px);
}

.marks li:nth-child(41) {
  transform: rotate(246deg) translateY(125px);
}

.marks li:nth-child(42) {
  transform: rotate(252deg) translateY(125px);
}

.marks li:nth-child(43) {
  transform: rotate(258deg) translateY(125px);
}

.marks li:nth-child(44) {
  transform: rotate(264deg) translateY(125px);
}

.marks li:nth-child(45) {
  transform: rotate(270deg) translateY(125px);
}

.marks li:nth-child(46) {
  transform: rotate(276deg) translateY(125px);
}

.marks li:nth-child(47) {
  transform: rotate(282deg) translateY(125px);
}

.marks li:nth-child(48) {
  transform: rotate(288deg) translateY(125px);
}

.marks li:nth-child(49) {
  transform: rotate(294deg) translateY(125px);
}

.marks li:nth-child(50) {
  transform: rotate(300deg) translateY(125px);
}

.marks li:nth-child(51) {
  transform: rotate(306deg) translateY(125px);
}

.marks li:nth-child(52) {
  transform: rotate(312deg) translateY(125px);
}

.marks li:nth-child(53) {
  transform: rotate(318deg) translateY(125px);
}

.marks li:nth-child(54) {
  transform: rotate(324deg) translateY(125px);
}

.marks li:nth-child(55) {
  transform: rotate(330deg) translateY(125px);
}

.marks li:nth-child(56) {
  transform: rotate(336deg) translateY(125px);
}

.marks li:nth-child(57) {
  transform: rotate(342deg) translateY(125px);
}

.marks li:nth-child(58) {
  transform: rotate(348deg) translateY(125px);
}

.marks li:nth-child(59) {
  transform: rotate(354deg) translateY(125px);
}

.marks li:nth-child(60) {
  transform: rotate(360deg) translateY(125px);

}

// .compra{
//   width: 100%;
//   height: auto;
//   background: #fff;
//   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 1.5rem 1rem;
//   margin-bottom: .5rem;

//   .info{
//     width: 100%;
//     height: auto;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: flex-end;
//     position: relative;

//     .text{
//       width: 100%;
//       height: auto;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;

//       p{
//         margin-block-start: 0em;
//         margin-block-end: 0em;
//         font-size: 1rem;
//         color: rgb(107, 107, 107);
//       }
//     }

//     .icons{
//       width: 10%;
//       height: auto;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       position: absolute;
//       right: 0;

//       ion-icon{
//         font-size: 2.2rem;
//       }
//     }
//   }

//   .ent{
//     background: #1ed5cc;
//     width: 12rem;
//     height: 2.5rem;
//     border-radius: 5rem;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
//     color: #fff;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 1.5rem;
//   }
// }

// .inps{
//   width: 100%;
//   height: 2.5rem;
//   background: none;
//   text-indent: 1rem;
//   border-radius: 5rem;
//   border: 1px solid #33a1c3;
//   margin-bottom: .5rem;
// }

// .inps:focus{
//   outline: none;
// }







.loader {
  display: flex;
  width: 100%;
  height: 100%;
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: fixed;
  top: calc(0%);
  left: calc(0%);
  background-color: white;
  z-index: 9999999;

 

  img{
    width: 200px;
    height: auto;

  }

}

.comprassss{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: fixed;
  transition: .75s all ease-in-out;
  top: 0;
  opacity: 1;
  left: 0%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  button{
    background: #1ca3c9!important;
    color: #fff!important;
    border-radius: 5rem;
    width: 12rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: lighter;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    position: absolute;
    left: calc(50% - 6rem);
    top: calc(60% + 5rem);
  }
}

.custom-col{
  padding: 0em;
  border: 1px solid transparent;
  //transition: border-color 0.3s;
  display: flex;
  justify-content: left;
  align-items: center;
}



@media screen and (min-width:993px){
  .tituloItemCompra{
    width: fit-content; 
    font-size: 1.5em;
    text-overflow: unset;
  }

  .titulosSecciones{
    font-size: 1.5em;
  }

  .custom-col{
  padding: 20px;
  border: 1px solid transparent;
  transition: border-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  /*.custom-col:hover{
    border-color:#3dc2ff ;
  }*/
  .nombreClienteDirecciones{
    margin-bottom: .3em; font-size: 1.8em;width:fit-content;
  }

  .subtitulosSecciones{
    font-size: 2.8em;
  }


  #resumenEscritorio{
    display: flex;
    box-shadow: rgba(65, 212, 238, 0.2) 0px 7px 29px 0px;
    padding: 0.82em; 
    //width: 18em;
    left: 0;
  }

  #resumen{
    //display: none !important;
    //visibility: hidden;
  }

  .etiquetasResumen{
    color: #00a3c7;
  }

  .cantidadesResumen{
    font-weight: bold;
  }

  .rowResumen{
    font-size: 1.2em;
    font-weight:100;
  }

  .paq{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px !important;
  }

  .inp ion-radio{
    margin-right: 20px;
  }
  .txt{
    flex: 1;
    align-items: center;
  }
  
  .direccion .part2{
    border-bottom: 2px solid rgb(137, 212, 247);
    border-top: 2px solid rgb(137, 212, 247);
    

    font-family: 'RobotoCondensed-Light';
    font-size: 2.3em;
  }

  .direccion .part1{
    color:#00a3c7;
    
  }
  
  .compra{
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: green;
    //box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;
  
    .info{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;
  
      .text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: 1rem;
          color: rgb(107, 107, 107);
        }
      }
  
      .icons{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
  
        ion-icon{
          font-size: 2.2rem;
        }
      }
    }
  
    .ent{
      background: #1ed5cc;
      width: 12rem;
      height: 2.5rem;
      border-radius: 5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }
  .inps{
    width: 100%;
    height: 2.5rem;
    background:   rgb(235, 244, 247);
    text-indent: 1rem;
    border-radius: 5rem;
    border: 1px solid rgb(0 163 197);
    margin-bottom: .5rem;
  }
  
  .inps:focus{
    outline: none;
  }

  .selict{
    width: 100%;
    height: 2.5rem;
    background: rgb(235, 244, 247);
    padding: 0rem 1rem;
    border-radius: .5rem;
    border: 1px solid rgb(0 163 197);
    margin-bottom: .5rem;
  }
  
  .selict:focus{
    outline: none;
  }
}

.custom-card{
  //box-shadow: 0 0 10px rgba(0,0,0,0.2);
  //transition: box-shadow 0.3s;
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.custom-card:hover{
  //box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.custom-card-title{
  color:#0088a7;
  font-size: 1.8em;
}

.custom-hr{
  width: 100%;
  height: 1px;
  background: rgb(128,128,128);
  border: none;
  margin: 1em 0;
}

// .custom-col{
//   padding: 20px;
//   border: 1px solid transparent;
//   transition: border-color 0.3s;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .custom-col:hover{
//   border-color:#3dc2ff ;
// }

.inp ion-radio{
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 1px solid #3880ff;
}

.direc-list-cont{
  width: 40em;
  height: auto;
  //border: 1px solid red;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;

  .direct-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .3em;
    padding: 1em;
    padding-left: 4em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: .4s all ease-in-out;
    position: relative;
    border-radius: 1em;

    .direc{
      color: #000;
      font-size: .96em;
      font-weight: 100;
      transition: .4s all ease-in-out;
    }
  }

  .direct-cont:hover{
    box-shadow: rgba(0, 163, 199, 0.66) 0px 2px 8px 0px;

    .direc{
      color: #0196b7;
    }
  }
}

.deskheader{
  display: none;
}

.deskfooter{
  display: none;
}

.deskbtn{
  display: none;
}

.modal-nd{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #0000004f;

  .form-conten{
    width: 30em;
    height: auto;
    padding: 1em;
    background-color: #fff;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    position: relative;

    .inps{
      width: 100%;
      height: 2.5em;
      background: rgb(255, 255, 255);
      text-indent: 0em;
      border-radius: 0em;
      border: none;
      border-bottom: 1px solid rgb(0, 163, 197);
      margin-bottom: 0em;
      padding: 0em 1em;
      color: #00a3c7;
    }

    .inps::placeholder{
      color: #000;
      font-size: .9em;
    }

    .selict{
      width: 100%;
      height: 2.5rem;
      background: rgb(255, 255, 255);
      padding: 0rem 1rem;
      border-radius: 0em;
      border: none;
      border-bottom: 1px solid rgb(0, 163, 197);
      margin-bottom: 0em;
      color: #00a3c7;
    }

    button{
      padding: 0.5em 3em;
      font-size: 1.1em;
      font-weight: 100;
      background-color: #ffffff;
      color: #00a3d0;
      border: 1px solid #00a3d0;
      transition: .5s all ease-in-out;
      border-radius: 2em;
    }

    button:hover{
      background-color: #00a3d0;
      color: #fff;
    }

    .cancel{
      color: #f00;
      position: absolute;
      right: 1.5em;
      bottom: 1.5em;
      cursor: pointer;
      transition: .5s all ease-in-out;
    }

    .cancel:hover{
      color: rgba(255, 0, 0, 0.568);
    }
  }
}

.super-cont-envio{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0em 1em;

  .envio-cont{
    width: 40em;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    .opt-cont{
      width: 100%;
      height: auto;
      padding: 1em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: .5em;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: .4s all ease-in-out;
      padding-left: 4em;
      position: relative;
      border-radius: 1em;
    }

    .opt-cont:hover{
      box-shadow: rgba(0, 163, 199, 0.66) 0px 2px 8px 0px;
    }
  }

  @media(max-width: 992px){
    .envio-cont{
      width: 100%;
    }
  }

  .part4{
    padding: 2em 0em;
    display: flex;
    gap: 1em;

    .btn{
      background: #fff !important;
      color: #00a3c7 !important;
      border-radius: 5rem;
      width: 12rem;
      height: 3rem;
      font-size: 1rem;
      font-weight: lighter;
      margin-top: 0em;
      margin-bottom: 0em;
      box-shadow: none;
      border: 1px solid #00a3c7;
      transition: .5s all ease-in-out;
    }

    .btn:hover{
      background: #00a3c7 !important;
      color: #fff !important;
    }
  }
}

@media(min-width: 992px){
  .pagoContDesk{
    width: 40em!important;
    box-shadow: none!important;
  }

  .super-cont-envio{
    display: flex;
  }

  ion-header{
    display: none;
  }

  .deactbtn{
    display: none;
  }

  .deskbtn{
    display: block;
  }

  .pago{
    ion-card{
      .part4{
        padding: 2em 0em;
    
        .b1{
          background: #fff !important;
          color: #00a3c7 !important;
          border-radius: 5rem;
          width: 12rem;
          height: 3rem;
          font-size: 1rem;
          font-weight: lighter;
          margin-top: 0em;
          margin-bottom: 0em;
          box-shadow: none;
          border: 1px solid #00a3c7;
          transition: .5s all ease-in-out;
          margin-right: 0em;
        }
    
        .b1:hover{
          background: #00a3c7 !important;
          color: #fff !important;
        }
      }
    }
  }

  .direccion{
    ion-card{
      .part4{
        padding: 2em 0em;

        .btn{
          background: #fff !important;
          color: #00a3c7 !important;
          border-radius: 5rem;
          width: 12rem;
          height: 3rem;
          font-size: 1rem;
          font-weight: lighter;
          margin-top: 0em;
          margin-bottom: 0em;
          box-shadow: none;
          border: 1px solid #00a3c7;
          transition: .5s all ease-in-out;
        }

        .btn:hover{
          background: #00a3c7 !important;
          color: #fff !important;
        }
      }
    }
  }

  .form-control{
    width: .5em;
    height: .5em;
    cursor: pointer;
  }

  .deskheader{
    display: block;
  }

  .deskfooter{
    display: block;
  }

  .part2{
    display: none;
  }

  .titulosSecciones{
    font-size: 1.8em!important;
    color: #00a3c7!important;
  }

  .subtitulosSecciones{
    font-size: 1.2em!important;
  }

  .direc-list-cont{
    display: flex;
  }

  .custom-card{
    box-shadow: none;
    padding: 10px;
    margin-bottom: 20px;
    width: 50% !important;
  }

  .env-cont{
    display: none!important;
  }
}

/* Fondo oscuro detrás del modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenedor del modal */
.modal-content {
  background-color: #ffffff;
  padding: 20px;
  width: 80%;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  
  button:hover {
    background-color: #0056b3;
  }
 
}


/* Fondo oscuro detrás del modal */
.modal-backdrop2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenedor del modal */
.modal-content2 {
  background-color: #ffffff;
  padding: 20px;
  width: 80%;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  
  button:hover {
    background-color: #0056b3;
  }
 
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: #40e0d0;
}
.option-input:checked::before {
  width: 40px;
  height: 40px;
  display:flex;
  content: '\f00c';
  font-size: 25px;
  font-weight:bold;
  position: absolute;
  align-items:center;
  justify-content:center;
  font-family:'Font Awesome 5 Free';
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}