@import "swiper/css";
@import "swiper/css/navigation";

.head-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .buscador{
        width: 100%;
        height: 7rem;
        background: #00abcf;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        form{
            width: 100%;
            height: auto;
            margin-bottom: .5rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            
            button{
                width: 3rem;
                height: 2rem;
                background: #fff;
                color: #00abcf;
                font-size: 2rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            input{
                width: 80%;
                height: 2rem;
                background: #fff;
                border: 0px;
            }

            input:focus{
                outline: none;
            }
        }
    }

    .rest{
        width: 100%;
        height: 3rem;
        background: #4ed4e0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .burgir{
            width: 15%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            ion-icon{
                color: #fff;
                font-size: 2rem;
            }
        }

        .titled{
            width: 70%;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p{
                color: #fff;
                font-size: 1rem;
            }
        }

        .icons{
            width: 30%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .i1{
                width: 30%;
                height: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                ion-icon{
                    color: #fff;
                    font-size: 1.8rem;
                }
            }

            .i2{
                width: 70%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                ion-icon{
                    color: #fff;
                    font-size: 1.8rem;
                }
            }
        }
    }
}




.wishlist{
    width: 100%;
    height: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .titulo{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0rem;

        p{
            font-size: .9rem;
            color: #4ed4e0;
            font-weight: 600;
            margin-block-start: 0em;
            margin-block-end: 0em;
        }
    }

    .invitar{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .btn{
            width: 70%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #00abcf;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            border-radius: 5rem;

            ion-icon{
                font-size: 1.8rem;
                color: #fff;
                margin-right: 1rem;
            }

            p{
                font-size: 1.2rem;
                color: #fff;
                font-weight: lighter;
                margin-block-start: 0em;
                margin-block-end: 0em;
            }
        }
    }

    .productos{
        width: 100%;
        height: auto;
        padding: 1rem .5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .prod{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 1rem;

            .imagen{
                width: 9rem;
                height: 9rem;
                border: 1px solid #000;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                img{
                    width: 100%;
                    height: auto;
                }
            }

            .info{
                width: 60%;
                height: 9rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-left: .5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;


                .parrafos{
                    margin-bottom: .2rem;

                    p{
                        font-size: 1rem;
                        color: black;
                        font-weight: lighter;
                        margin-block-start: 0em;
                        margin-block-end: 0em;
                    }
                }

                .links{
                    display: flex;
                    flex-direction: column;

                    a{
                        text-decoration: none;
                        color: #4ed4e0;
                        border-left: 1px solid #4ed4e0;
                        padding-left: .4rem;
                        margin: .4rem 0rem;
                        font-weight: 600;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .seguir{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0rem;

        .comp{
            width: 70%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #00abcf;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            border-radius: 5rem;

            p{
                font-size: 1.2rem;
                color: #fff;
                font-weight: lighter;
                margin-block-start: 0em;
                margin-block-end: 0em;
            }
        }
    }
}

.invitar-cont{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.666);
    position: fixed;
    top: 0;
    opacity: 0;
    z-index: -1;

    #cerrar{
        font-size: 1.8rem;
        color: #000;
        right: .3rem;
        top: .3rem;
        position: absolute;
    }

    .opts{
        width: 90%;
        height: auto;
        background: #fff;
        position: absolute;
        left: 5%;
        top: 35%;
        padding: 1rem;
    }

    .lectura{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0rem 2rem;

        .bl{
            font-size: 1.1rem;
            font-weight: 600;
            //color: #35a7dc; //Azul si es seleccionado
            color: rgb(94, 94, 94);
        }

        p{
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-size: .9rem;
            color: rgb(94, 94, 94);
        }
    }

    .mid{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0rem 1rem;
        border-top: 1.2px solid rgb(65, 65, 65);
        border-bottom: 1.2px solid rgb(65, 65, 65);
        padding: 0rem 0rem;                                                     //Este
        padding-left: 1rem;
        margin: 1rem 0rem;

        .midl{
            width: 100%;
            height: auto;
            display: none;                                                      //Este
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            ion-icon{
                color: #000;
                font-size: 1.7rem;
            }

            p{
                margin-block-start: 1em;
                margin-block-end: 1em;
                font-size: .9rem;
                color: rgb(94, 94, 94);
                margin-left: 1rem;
            }

            .copys{
                position: relative;

                span{
                    display: none;                                              //Este
                    position: absolute;
                    top: -1rem;
                    left: 0;
                    width: 10rem;
                    height: auto;
                    font-size: .8rem;
                    color: #4ed4e0;
                }
            }
        }
    }

    .ve{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0rem 2rem;

        .bl{
            font-size: 1.1rem;
            font-weight: 600;
            //color: #35a7dc; //Azul si es seleccionado
            color: rgb(94, 94, 94);
        }

        p{
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-size: .9rem;
            color: rgb(94, 94, 94);
        }
    }

    .correo{
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: -5;

        .cancelar{
            position: absolute;
            top: 10rem;
            color: #00abcf;
            font-size: 1.2rem;
            border-bottom: 1px solid #00abcf;
            border-bottom-style: dashed;
            width: 100%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 1rem;

            ion-icon{ margin-right: .4rem; }
        }

        .cont-next{
            width: 100%;
            height: auto;
            position: absolute;
            top: 13rem;
            display: flex;
            flex-direction: column;

            .titled{
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                p{
                    margin-block-start: .5em;
                    margin-block-end: .5em;
                    font-size: 1.8rem;
                    color: #000;
                    font-weight: 600;
                }
            }

            form{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .pas{   
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 1rem;

                    p{
                        margin-block-start: 0em;
                        margin-block-end: 0em;
                        font-size: .9rem;
                        color: rgb(94, 94, 94); 
                    }

                    input{
                        background: none;
                        border: 0px;
                        border-bottom: 1px solid #00abcf;
                        width: 80%;
                        height: 2rem;
                        margin-left: 1rem;
                    }

                    input:focus{
                        outline: none;
                    }
                }

                button{
                    width: 40%;
                    height: 2rem;
                    background: #00abcf;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    font-size: 1.2rem;
                    color: #fff;
                    border-radius: 5rem;
                    margin-top: 1rem;
                }
            }
        }
    }
}

.wishDesk{
    width: 100%;
    height: auto;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;

    .wishCont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3em 0em;
        gap: 2em;

        .taitl-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: .2em;

            .prin{
                color: #000000;
                font-size: 1.5em;
            }

            .secon{
                color: #00a3c7;
                font-size: 1.2em;
            }
        }

        .wish-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0em 2em;
            gap: 2em;

            .prod{
                width: 20em;
                height: 20em;
                border-radius: 20em 20em 20em 20em;
                overflow: hidden;
                position: relative;
                box-shadow: rgb(0 163 197 / 20%) 0px 4px 30px;
                border: 1px solid #00a3c742;
                transition: .5s all ease-in-out;

                .imag{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .parrafos{
                    position: absolute;
                    width: 100%;
                    height: 7em;
                    background-color: rgb(0 163 199 / 80%);
                    z-index: 2;
                    bottom: -7em;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: .7em;
                    gap: .2em;
                    transition: .5s all ease-in-out;

                    .nom{
                        color: #fff;
                        font-size: .8em;
                        font-weight: 600;
                    }

                    .rest{
                        color: #fff;
                        font-size: .8em;
                    }
                }

                .links{
                    position: absolute;
                    top: -3em;
                    width: 100%;
                    height: 3em;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: .5em;
                    transition: .5s all ease-in-out;

                    a{
                        background-color: #00a3c7;
                        width: 2em;
                        height: 2em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        cursor: pointer;

                        i{
                            color: #fff;
                        }
                    }
                }
            }

            .prod:hover{
                border-radius: 20em 20em 3em 3em;

                .parrafos{
                    bottom: 0em;
                }

                .links{
                    top: 0em;
                }
            }
        }
    }
}

@media(min-width: 992px){
    ion-header{
        display: none;
    }

    ion-content{
        display: none;
    }

    .wishDesk{
        display: flex;
    }
}

.wishDesk::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
}
  
.wishDesk::-webkit-scrollbar-thumb {
    background-color: #00a2c779;
    //border-radius: 1vh;
    width: 0.25em;
}

.loader {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: fixed;
    top: calc(0%);
    left: calc(0%);
    background-color: rgb(255, 255, 255);
    opacity: 0.9;
  
    img{
      width: 200px;
      height: auto;
  
    }
  
  }

  .modal-c-wp{
    width: 1200px;
    height: 800px;
    position: fixed;
    overflow-y: auto;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    top: calc(50% - 400px);
    left: calc(50% - 600px);
    z-index: 99;
    box-shadow: 0px 0px 12px 1px #42dcffbf;

    .btn-wp-cont{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;

      .btn-wp-close{
        background-color: #df0b0b;
        color: #fff;
        font-size: 1.1em;
        padding: .7em 2em;
      }
    }
  }
  .inp-manage{
    width: 100%;
    height: 2.5em;
    border: none;
  }
  
  .inp-manage:focus{
    outline: none;
  }
  
  ion-content{
      background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
      ion-button {
        --background: #0089c5;     
        --color:white;
        
      
    }
    
    ion-card{
        background: rgba(255, 255, 255, 0.164); //     #fdfdfd            #14A8CD*
        backdrop-filter: blur(2px);
        /*background: rgb(28,138,242);
        background: linear-gradient(0deg, rgba(28,138,242,1) 47%, rgba(45,166,253,1) 47%); //     #fdfdfd            #14A8CD*/
        display: flex;
        justify-content:space-between;
       
        flex-direction: column;
        //box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        //box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        width: 100%;
        height: 100%;
        border-radius: .25rem;
        //padding: 1rem;
    
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.150);
        border-radius: 10px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        border: .5px solid rgba(0, 0, 0, 0.082);
    
        .contImg{
          width: 100%;
          height: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;
    
          img{
            width: 80%;
            height: auto;
          }
        }
    
        .text{
          width: 100%;
          height: 40%;
          color: black;
          font-size: 15px;
          font-weight: bold;
          ion-text{
            width: 100%;
            font-size: 20vw;
            text-align: center;
            color: rgb(0, 0, 0);
            // text-shadow: 4px 3px 4px rgb(65, 64, 64); 
          }
    
        }
    
    
    
      }
  
      .tituloDescripcion{
        color: rgb(27,172,209);
        font-family: 'RobotoCondensed-Light';
        font-size: 18px;
        font-style: italic;
        text-align: center;
      }
  
      .titulillo{
        font-size: 20px;
        margin-bottom: 0em;
        color: black;
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
        font-size: 20px;
        letter-spacing: 2px;
      }
    
      .textillo{
          font-size: 18px;
          margin-bottom: 10px;
          color: black;
          font-weight: normal;
          //text-transform: uppercase;
          text-align: center;
          font-family: 'RobotoCondensed-Light';
      }
    
      .form-group{
    
     margin-left: 100px;
      }
  
      .color-cont-prop{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: .5em;
      }
  
      .color-cont-prop-list{
        width: 100%;
        height: auto;
        margin-top: .5em;
  
        .conten-names-opts{
          width: 100%;
          height: 8.8em;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 1em 0em;
          gap: 1em;
  
          .btn-list-opt{
            width: 100%;
            padding: 1em 0em;
            color: #1ca3c9;
            background-color: transparent;
            border-top: 1px solid #0abcee;
            border-bottom: 1px solid #0abcee;
            box-shadow: 0px 0px 5px 0px #0abcee;
          }
        }
  
        .conten-names-opts::-webkit-scrollbar {
          width: .6em;
          background-color: transparent;
          height: .7em;
        }
          
        .conten-names-opts::-webkit-scrollbar-thumb {
          background-color: #00a2c779;
          //border-radius: 1vh;
          width: 0.25em;
        }
      }
    
      .selector-color {
        display: inline-block;
        margin: 0px 2px;
        width: 40px;
        height: 40px;
        position: relative;
        border-radius: 3px;
        cursor: pointer;
        //overflow: hidden;
        transform: rotate(0deg);
        transition: .5s;
        //border-radius: 50%;
        padding: .5em;
        display: flex; 
        justify-content: center;
        align-items: center;
      }
  
      .line-tb{
        padding: 1.5em 0.5em;
  
        .r-color{
          position: absolute;
          width: 3.5em;
          height: 3.5em;
          border: 1px solid #cfcfcf;
          border-radius: 50%;
          right: 1em;
          top: 0.5em;
        }
      }
  
      .preview{
        position: absolute;
        z-index: 5;
        background-color: #fff;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0.7em;
        padding: 1em;
        border-radius: 1em;
        bottom: 3em;
        right: calc(50% - 2em);
        box-shadow: rgb(0 230 241 / 28%) 0px 5px 29px 0px;
  
        .diva-color-p{
          background-color: rgb(255, 255, 255);
          width: 6em;
          height: 6em;
          border: 1px solid #ddd;
          border-radius: 50%;
        }
  
        .name-color-p{
          font-size: .9em;
          color: #000;
          font-weight: 500;
        }
      }
  
      .selector-color:hover{
        .preview{
          display: flex;
        }
      }
  
      .texto-descripcion {
        color: white;
        font-size: 1.3em;
      }
      
      .selected.selector-color input{
        border-radius: 50%;
        animation: lux 2s ease-in-out infinite;
        -webkit-animation: lux 2s ease-in-out infinite;
      }
  
      @keyframes lux{
        0%{
          border: 2px solid rgb(16, 169, 196);
          box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
        }
        25%{
          border: 2px solid rgb(14, 206, 240);
          box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
        }
        50%{
          border: 2px solid rgb(14, 240, 221);
          box-shadow: 0px 0px 10px 1px rgb(14, 240, 221);
        }
        75%{
          border: 2px solid rgb(14, 206, 240);
          box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
        }
        100%{
          border: 2px solid rgb(16, 169, 196);
          box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
        }
      }
  
      @-webkit-keyframes lux {
        0%{
          border: 2px solid rgb(16, 169, 196);
          box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
        }
        25%{
          border: 2px solid rgb(14, 206, 240);
          box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
        }
        50%{
          border: 2px solid rgb(14, 240, 221);
          box-shadow: 0px 0px 10px 1px rgb(14, 240, 221);
        }
        75%{
          border: 2px solid rgb(14, 206, 240);
          box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
        }
        100%{
          border: 2px solid rgb(16, 169, 196);
          box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
        }
      }
      
      .selector-color input {
        width: 30px;
        background-size: cover;
        position: absolute;
        height: 30px;
        top: 5px;
        left: 5px;
        box-shadow: inset rgb(0 0 0 / 20%) 0px 0px 12px;
        transition: 1s;
        border-radius: .2em;
        border: none;
        animation: none;
      }
  
      @media(max-width: 992px){
        .selector-color:hover{
          .preview{
            display: none;
          }
        }
      }
      
      .selected.selector-color input {
        width: 40px;
        height: 40px;
        top: 0px;
        left: 0px;
      }
      
      .contiene-descripcion > table > tbody > tr > td > div > img {
        max-width: 100%;
        height: auto;
      }
  
      .swiper {
        width: 100%;
        height: 100%;
      }
      
      swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
      
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      
      swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      swiper-slide video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
      }
      
      swiper-slide {
        background-size: cover;
        background-position: center;
      }
      
      .mySwiper2 {
        height: 80%;
        width: 45%;
        border: 5px;
      }
  
      .swiper-container-wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
      }
  
      .swiper-container-gallery{
        width: 80%;
        margin: 0 auto;
      }
  
      swiper-slide{
        text-align: center;
      }
      
      .swiper-image{
        max-width: 5em;
        max-height: 5em;
      }
  
      .swiper-pagination{
        margin-top: 10px;
      }
  
      .swiper-button-next,
      .swiper-button-prev{
        color:#0089c5
      }
  
      .swiper-pagination-bullet{
        background: #1c81a0;
        width: 8px;
        height: 8px;
        margin: 0 3px;      
      }
  
      /*.swiper-container{
        padding-top: 0em;
        width: 25em;
        height: auto;
       
        margin: 0 auto;
        display: flex;
        justify-content: center;
        //align-items: center;
      }*/
  
      .mySwiper3{
           
        cursor: default;
        inline-size: 100%;
        block-size: auto;
        inset-inline-start: 0;
        inset-block-start: 0px ;
       
      }
      
      .mySwiper {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
      }
      
      .mySwiper swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
      }
      
      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }
  
      .precio{
        color: rgb(29,171,209);
        font-family: 'RobotoCondensed-Light';
        font-size: 35px;
        letter-spacing: normal;
        line-height: 38.5px;
        text-size-adjust: 100%;
        text-transform: uppercase;
      }
      
      swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      swiper-slide video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
     
  
      .loader {
        display: flex;
        width: 100%;
        height: 100%;
        background-position: center;
        justify-content: center;
        align-items: center;
        background-size: cover;
        position: fixed;
        top: calc(0%);
        left: calc(0%);
        background-color: rgb(255, 255, 255);
        opacity: 0.9;
      
        img{
          width: 200px;
          height: auto;
     
        }
    
      }
  
      /* Styles for the selected image container */
  .selected-image-container {
    margin-bottom: 20px; /* Adjust the spacing as needed */
  }
  
  .selected-image-swiper {
    width: 100%;
    max-height: 400px; /* Adjust the height of the selected image */
    margin: 0 auto;
  }
  
  /* Styles for the gallery container */
  .gallery-container {
    margin-top: 20px; /* Adjust the spacing as needed */
  }
  
  .gallery-swiper {
    width: 100%;
    max-height: 100px; /* Adjust the height of the gallery */
    margin: 0 auto;
  }
  
  .gallery-image {
    max-width: 100px; /* Adjust the size of gallery images */
    max-height: 80px; /* Adjust the size of gallery images */
  }
  
  @media screen and (max-width:992px){
    
  
    .columnas{
      height: min-content;
    }
    .swiper-container{
      height: auto;
    }
  
    .columnas ion-col:nth-of-type(2){
      order: 1;
    .columnas ion-col:nth-of-type(1){
      order: 2;
    }
  }
  #CodigoYColor{
    //margin-right: 10em;
  }
  }
  
  @media screen and (min-width:993px){
      .columnas {
        height: min-content;
      }
  
      .columnas ion-col{
        width: 25%;
      }
      .columnas ion-col:nth-of-type(2){
        order: 1;
      }
      .columnas ion-col:nth-of-type(1){
        order: 2;
      }
      .columnas ion-col:nth-of-type(3){
        order: 3;
      }
  
      ion-header{
        display: none;
      }
  }
  
  .swal-wide{
    width:20%;
    height: 20%;
  }
  
  #existenciaDesk{
    display: none;
  
    .conten-exist{
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      padding: .5em 0em;
  
      .lefti{
        color: #000;
        font-size: 1.2em;  
      }
  
      .rigti{
        color: #00a3c7;
        font-size: 1.1em;
      }
    }
  }
  
  @media screen  and (min-width:993px){
    #caracteristicas{
      //padding-left: 20%;
      //padding-left: 15em;
      //padding-right: 15em;
      box-sizing: border-box;
      margin: auto;
      width: 100%;
      height: fit-content;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    #irCarrito{
      display: none;
    }
    #existencia{
      display: none;
    }
    #colorSeleccionado{
      display: none;
    }
  }
  
  .deskheader{
    display: none;
  }
  
  .deskfooter{
    display: none;
  }
  
  .nohay-cont{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: 0.7em 0em;
    border-bottom: 1px solid #f00;
    margin-bottom: 1em;
  
    .textin{
      color: #f00;
      font-weight: 600;
      font-size: 1.2em;
    }
  
    i{
      color: #f00;
      font-size: 2.2em;
    }
  }
  
  .cont-car{
    width: 70%;
    height: auto;
  
    table{
      width: 100%;
      height: auto;
    }
    
    ul{
      width: 100%;
      padding: 0em 25%;
    }
    
    td{
      border: 1px solid #cdcdcd;
    }
    
    div{
      width: 100%;
      overflow-x: scroll;
    }
    
    tbody{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }
    
    h5{
      span{
        font-size: 1.7em;
      }
    }
    
    p{
      span{
        font-size: 1.4em;
        color: #00a3c7;
      }
    }
    
    hr{
      background-color: #000;
      margin: 2em 0em;
    }
  }
  
  .prods-related-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    background-color: #fff;
  
    .taitl{
      color: #222;
      font-size: 1.8em;
      font-weight: 100;
    }
  
    #related{
      width: 100%;
      height: auto;
      //margin-top: 2em;
  
      .swiper-button-next{
          background-color: #fff;
          border: 1px solid rgb(194, 194, 194);
          color: rgb(194, 194, 194);
          width: 3em;
          height: 3em;
          transition: .5s all ease-in-out;
          --swiper-navigation-size: 1em;
          top: calc(50% - 1.5em);
          opacity: 1;
      }
  
      .swiper-button-prev{
          background-color: #fff;
          border: 1px solid rgb(194, 194, 194);
          color: rgb(194, 194, 194);
          width: 3em;
          height: 3em;
          transition: .5s all ease-in-out;
          --swiper-navigation-size: 1em;
          top: calc(50% - 1.5em);
          opacity: 1;
      }
      
      .swiper-button-next:hover, .swiper-button-prev:hover{
          background-color: rgb(23, 66, 131);
          border: 1px solid rgb(23, 66, 131);
      }
      
      .swiper-button-next{
          right: 0;
      }
      
      .swiper-button-prev{
          left: 0;
      }
      
      .swiper-pagination-bullet{
          width: 1em;
          height: 1em;
          background-color: #000;
          opacity: .8;
      }
      
      .swiper-pagination-bullet-active{
          background-color: rgb(11, 68, 143);
      }
  
      swiper-slide{
          text-align: center;
          font-size: 18px;
          background: transparent;
            
          /* Center slide text vertically */
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          position: relative;
  
          .all-cont{
              width: 100%;
              height: auto;
  
              .prod-cont{
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  position: relative;
                  padding: 1em;
                  gap: 1em;
                  border: 1px solid #eee;
                  padding-bottom: 1.5em;
  
                  .offer{
                      position: absolute;
                      top: 1em;
                      left: 1em;
                      color: #fff;
                      background-color: #e67e22;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      z-index: 10;
                      font-size: .6em;
                      padding: .3em 1em;
                  }
  
                  .new{
                      position: absolute;
                      top: 1em;
                      right: 1em;
                      color: #fff;
                      background-color: #00ABF0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      z-index: 10;
                      font-size: .6em;
                      padding: .3em 1em;
                  }
  
                  .foto-cont{
                      width: 167px;
                      height: 167px;
                      background-position: center;
                      background-size: cover;
                      position: relative;
  
                      img{
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          position: absolute;
                          top: 0;
                          left: 0;
                          transition: .4s all ease-in-out;
                      }
  
                      #uno{
                          opacity: 1;
                      }
  
                      #dos{
                          opacity: 0;
                      }
                  }
  
                  .time-cont{
                      width: 100%;
                      height: auto;
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: flex-start;
                      gap: .1em;
  
                      .quad-time{
                          width: 25%;
                          height: 2.2em;
                          background-color: #999999;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          padding: .4em;
  
                          .up{
                              color: #fff;
                              font-weight: 900;
                              font-size: .8em;
                          }
  
                          .down{
                              color: #fff;
                              font-weight: 400;
                              font-size: .5em;
                          }
                      }
                  }
  
                  .rate-cont{
                      width: 100%;
                      height: auto;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      gap: .4em;
  
                      i{
                          font-size: .62em;
                          color: #f1a43e;
                      }
                  }
  
                  .prod-name{
                      font-size: 15px;
                      color: #444;
                      font-weight: normal;
                      text-transform: uppercase;
                      text-decoration: none;
                  }
  
                  .price-cont{
                      width: 100%;
                      height: auto;
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: flex-start;
  
                      .now{
                          color: #e74c3c;
                          font-size: 18px;
                          font-weight: 600;
                      }
  
                      .bef{
                          line-height: 20px;
                          padding: 0 5px;
                          display: inline-block;
                          text-decoration: line-through;
                          color: #999;
                          font-size: 12px;
                          font-weight: normal;
                      }
                  }
              }
  
              .prod-cont:hover{
                  //border: 1px solid #aaa;
  
                  .foto-cont{
  
                      #uno{
                          opacity: 0;
                      }
  
                      #dos{
                          opacity: 1;
                      }
                  }
  
                  .time-cont{
  
                      .quad-time{
  
                          background-color: #2e4980;
                      }
                  }
              }
          }
      }
    }
  
    #swiperSupercategory:hover{
  
        /*.swiper-button-prev{
            opacity: 1;
        }*/
  
        .swiper-button-prev:hover{
            color: #fff;
        }
  
        .swiper-button-next{
            opacity: 1;
        }
  
        .swiper-button-next:hover{
            color: #fff;
        }
    }
  }
  
  @media(min-width: 992px){
    .deskheader{
      display: block;
    }
  
    .deskfooter{
      display: block;
    }
  
    .titulillo{
      letter-spacing: 0px;
      font-size: 1.8em;
    }
  
    #existenciaDesk{
      display: block;
    }
  
    .cont-car{
      height: auto;
      // overflow-y: scroll;
      box-shadow: rgb(3 165 198 / 43%) 0px 2px 8px 0px;
      padding: 1em;
    }
  }
  
  @media(max-width: 992px){
    .cont-car{
      width: 100%;
    }
  }
  
  
  @import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
  
  .supercategory-cont{
      //border: 1px solid red;
      width: 100%;
      height: auto;
      display: block;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 2em;
  
  
      .top-con{
          position: relative;
          width: 100%;
          height: 4em;
          border-top: 1px solid rgb(218, 218, 218);
          border-right: 1px solid rgb(218, 218, 218);
  
          .line{
              width: 100%;
              height: .2em;
              position: absolute;
              bottom: 0;
              left: 0;
          }
  
          .title-cont{
              height: 115%;
              position: absolute;
              bottom: 0;
              left: 0;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 1em;
              padding: 0em 3em;
  
              i{
                  color: #fff;
                  font-size: 1.5em;
              }
  
              span{
                  color: #fff;
                  font-size: 1.2em;
              }
          }
      }
  
      .topin{
          width: 100%;
          height: auto;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
  
          .pops-cont{
              width: 40%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              padding-bottom: 1em;
  
              .title-cont{
                  width: 100%;
                  height: 2.5em;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #eee;
  
                  span{
                      font-weight: 600;
                      color: #000;
                  }
              }
  
              .opcs-cont{
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  padding-left: .75em;
                  padding-top: 1em;
                  gap: .5em;
  
                  button{
                      border: none;
                      background-color: transparent;
                      color: #000;
                      transition: .3s all ease-in-out;
                      font-size: 1.2em;
                      text-align: start;
                  }
  
                  button:hover{
                      color: rgb(15, 86, 133);
                      padding-left: .5em;
                  }
              }
          }
  
          img{
              width: 75%;
              height: auto;
          }
  
          @media(min-width: 992px){
              .pops-cont{
                  width: 25%;
              }
          }
  
          @media(max-width: 768px){
              .pops-cont{
                  width: 100%;
  
                  .title-cont{
  
                  }
              }
  
              img{
                  display: none;
              }
          }
      }
  }
  
  .supercategory-cont:nth-child(1){
      .top-con{
          .line{
              background-color: #009589;
          }
  
          .title-cont{
              background-color: #009589;
          }
      }
  }
  
  .supercategory-cont:nth-child(3){
      .top-con{
          .line{
              background-color: #ff5924;
          }
  
          .title-cont{
              background-color: #ff5924;
          }
      }
  }
  
  .supercategory-cont:nth-child(5){
      .top-con{
          .line{
              background-color: #32ad59;
          }
  
          .title-cont{
              background-color: #32ad59;
          }
      }
  }
  
  
  
  .first-cont{
  
      .firsty-cont{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
      }
  
      .mercados-cont{
          width: 100%;
          height: auto;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          margin-top: 2em;
  
          .opc-cont{
              width: 100%;
              height: 10em;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
  
              .shopinkref{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  position: relative;
  
                  span{
                      font-family: 'Poiret One', cursive;
                      letter-spacing: 1px;
                      font-weight: 600;
                      font-size: 2.8em;
                      transition: .6s all ease-in-out;
                  }
  
                  .sh1{
                      color: #f526617e;
                  }
  
                  .sh2{
                      color: #00a9bf70;
                  }
  
                  .sh3{
                      color: #ffc2336e;
                  }
  
                  .got1{
                      position: absolute;
                      background-color: rgba(0, 0, 0, 0.398);
                      border-radius: 48% 52% 24% 76% / 70% 41% 59% 30% ;
                      width: 1.8em;
                      height: 1.8em;
                      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                      left: calc(50% - 6em);
                      top: 2em;
                      transition: .3s all ease-in-out;
                  }
  
                  .got2{
                      position: absolute;
                      background-color: #ffc2336e;
                      border-radius: 71% 29% 53% 47% / 39% 50% 50% 61%  ;
                      width: 1.5em;
                      height: 1.5em;
                      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                      left: calc(50% - 4.25em);
                      top: 6em;
                      transition: .3s all ease-in-out;
                  }
  
                  .got3{
                      position: absolute;
                      background-color: #f526617e;
                      border-radius: 57% 43% 44% 56% / 59% 29% 71% 41%  ;
                      width: 2.3em;
                      height: 2.3em;
                      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                      left: calc(50% + .5em);
                      top: 1.8em;
                      transition: .3s all ease-in-out;
                  }
  
                  .got4{
                      position: absolute;
                      background-color: #00a9bf70;
                      border-radius: 61% 39% 67% 33% / 49% 81% 19% 51%   ;
                      width: 2em;
                      height: 2em;
                      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                      left: calc(50% + 3.6em);
                      top: 5.5em;
                      transition: .3s all ease-in-out;
                  }
              }
  
              .shopinkref:hover{
  
                  span{
                      font-weight: 900;
                  }
  
                  .sh1{
                      color: #f52661;
                  }
  
                  .sh2{
                      color: #00a9bf;
                  }
  
                  .sh3{
                      color: #ffc233;
                  }
  
                  .got1{
                      background-color: rgb(0, 0, 0);
                      border-radius: 50%;
                      top: 1.8em;
                  }
  
                  .got2{
                      background-color: #ffc233;
                      border-radius: 50%;
                      top: 6.2em;
                  }
  
                  .got3{
                      background-color: #f52661;
                      border-radius: 50%;
                      top: 1.6em;
                  }
  
                  .got4{
                      background-color: #00a9bf;
                      border-radius: 50%;
                      top: 5.7em;
                  }
              }
  
              .signotrafref{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  position: relative;
  
                  span{
                      font-family: 'Poiret One', cursive;
                      letter-spacing: 1px;
                      font-weight: 600;
                      font-size: 2.8em;
                      transition: .6s all ease-in-out;
                  }
  
                  .sh1{
                      color: #ffa92974;
                  }
  
                  .sh2{
                      color: #16192a74;
                  }
  
                  .warnr{
                      width: 3em;
                      height: 3em;
                      background-color: #c70c1579;
                      position: absolute;
                      clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 1.1em;
                      left: calc(50% - 9.5em);
                      transition: .6s all ease-in-out;
                  }
  
                  .warnr1{
                      position: absolute;
                      width: 2.7em;
                      height: 2.7em;
                      clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                      background-color: rgba(255, 255, 255, 0);
                      transition: .5s all ease-in-out;
                      box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0);
                  }
  
                  .warnr2{
                      width: 3.3em;
                      height: 3.3em;
                      clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                      //border: 1px solid #fff;
                      background-color: #c70c1500;
                      position: absolute;
                      transition: .5s all ease-in-out;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: .75em;
                      color: rgba(255, 255, 255, 0);
                  }
  
                  .warny{
                      width: 3.5em;
                      height: 3.5em;
                      background-color: #fa960082;
                      position: absolute;
                      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 5.1em;
                      right: calc(50% - 10em);
                      transition: .6s all ease-in-out;
                  }
  
                  .warny1{
                      position: absolute;
                      width: 3em;
                      height: 3em;
                      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                      background-color: rgba(255, 255, 255, 0);
                      transition: .5s all ease-in-out;
                      box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0);
                  }
  
                  .warny2{
                      width: 3.4em;
                      height: 3.4em;
                      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                      //border: 1px solid #fff;
                      background-color: #c70c1500;
                      position: absolute;
                      transition: .5s all ease-in-out;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: .8em;
                      color: rgba(255, 255, 255, 0);
                  }
              }
  
              .signotrafref:hover{
  
                  .sh1{
                      color: #ffa929;
                  }
  
                  .sh2{
                      color: #16192a;
                  }
  
                  .warnr{
                      background-color: #c70c15;
                  }
  
                  .warnr1{
                      background-color: rgba(255, 255, 255, 1);
                      box-shadow: 0px 0px 10px 2px #fff;
                  }
  
                  .warnr2{
                      background-color: #c70c15;
                      color: rgba(255, 255, 255, 1);
                      text-shadow: 0px 0px 2px #fff;
                  }
  
                  .warny{
                      background-color: #fa9600;
                  }
  
                  .warny1{
                      background-color: rgba(255, 255, 255, 1);
                      box-shadow: 0px 0px 10px 2px #fff;
                  }
  
                  .warny2{
                      background-color: #fa9600;
                      color: rgba(255, 255, 255, 1);
                      text-shadow: 0px 0px 2px #fff;
                  }
              }
  
              .wrapref{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  position: relative;
  
                  span{
                      font-family: 'Poiret One', cursive;
                      letter-spacing: 1px;
                      font-weight: 600;
                      font-size: 2.8em;
                      transition: .6s all ease-in-out;
                      position: relative;
                      z-index: 10;
                  }
  
                  .sh1{
                      color: #ff38497c;
                  }
  
                  .sh2{
                      color: #0000007e;
                  }
  
                  .cari{
                      position: absolute;
                      width: 10em;
                      height: auto;
                      top: calc(2.1em);
                      opacity: 0;
                      transition: .6s all ease-in-out;
                  }
              }
  
              .wrapref:hover{
  
                  .sh1{
                      color: #ff3849;
                      margin-bottom: 1.7em;
                  }
  
                  .sh2{
                      color: #000000;
                      margin-bottom: 1.7em;
                  }
  
                  .cari{
                      opacity: 1;
                      top: calc(3.7em);
                  }
              }
  
              .signoledref{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  position: relative;
  
                  span{
                      font-family: 'Poiret One', cursive;
                      letter-spacing: 1px;
                      font-weight: 600;
                      font-size: 2.8em;
                      transition: .6s all ease-in-out;
                      position: relative;
                      z-index: 1;
                  }
  
                  .sh1{
                      color: rgba(0, 0, 0, 0.501);
                  }
  
                  .sh2{
                      color: #ff2a5c7c;
                  }
  
                  .sh3{
                      color: #b2e55b7c;
                  }
  
                  .sh4{
                      color: #00acdc79;
                  }
  
                  .ad1{
                      position: absolute;
                      width: 4.5em;
                      height: 4.5em;
                      border: 2px solid #ff2a5c7c;
                      border-radius: 50%;
                      animation: ap1 10s linear infinite;
                  }
  
                  @keyframes ap1{
                      0%{
                          border: 3px solid #ffffff7c;
                      }
  
                      25%{
                          border: 3px solid #ff2a5c7c;
                      }
  
                      50%{
                          border: 3px solid #b2e55b7c;
                      }
  
                      75%{
                          border: 3px solid #00acdc79;
                      }
  
                      100%{
                          border: 3px solid #ffffff7c;
                      }
                  }
              }
  
              .signoledref:hover{
  
                  .sh1{
                      color: rgb(0, 0, 0);
                      text-shadow: 0px 0px 8px #fff;
                  }
  
                  .sh2{
                      color: #ff2a5c;
                      text-shadow: 0px 0px 8px #ff2a5c;
                  }
  
                  .sh3{
                      color: #b2e55b;
                      text-shadow: 0px 0px 8px #b2e55b;
                  }
  
                  .sh4{
                      color: #00acdc;
                      text-shadow: 0px 0px 8px #00acdc;
                  }
  
                  .ad1{
                      animation: ap2 10s linear infinite;
                  }
  
                  @keyframes ap2{
                      0%{
                          border: 3px solid #ffffff;
                          box-shadow: 0px 0px 15px 2px #ffffff;
                      }
  
                      25%{
                          border: 3px solid #ff2a5c;
                          box-shadow: 0px 0px 15px 2px #ff2a5c;
                      }
  
                      50%{
                          border: 3px solid #b2e55b;
                          box-shadow: 0px 0px 15px 2px #b2e55b;
                      }
  
                      75%{
                          border: 3px solid #00acdc;
                          box-shadow: 0px 0px 15px 2px #00acdc;
                      }
  
                      100%{
                          border: 3px solid #ffffff;
                          box-shadow: 0px 0px 15px 2px #ffffff;
                      }
                  }
              }
          }
      }
  }
  
  @media(max-width: 992px){
      .first-cont{
          .mercados-cont{
              grid-template-columns: repeat(2, 1fr);
  
              .opc-cont{
  
                  .shopinkref{
  
                      .sh1{
                          color: rgb(245, 38, 97);
                      }
  
                      .sh2{
                          color: rgb(0, 169, 191);
                      }
  
                      .sh3{
                          color: rgb(255, 194, 51);
                      }
  
                      .got1{
                          background-color: rgb(0, 0, 0);
                          top: 1.8em;
                      }
  
                      .got2{
                          background-color: rgb(255, 194, 51);
                          top: 6.2em;
                      }
  
                      .got3{
                          background-color: rgb(245, 38, 97);
                          top: 1.6em;
                      }
  
                      .got4{
                          background-color: rgb(0, 169, 191);
                          top: 5.7em;
                      }
                  }
  
                  .wrapref{
  
                      .sh1{
                          color: rgb(255, 56, 73);
                          margin-bottom: 1.7em;
                      }
  
                      .sh2{
                          color: rgb(0, 0, 0);
                          margin-bottom: 1.7em;
                      }
  
                      .cari{
                          opacity: 1;
                          top: calc(3.7em);
                      }
                  }
  
                  .signotrafref{
  
                      .sh1{
                          color: rgb(255, 169, 41);
                      }
  
                      .sh2{
                          color: rgb(22, 25, 42);
                      }
  
                      .warnr{
                          background-color: rgb(199, 12, 21);
  
                          .warnr1{
                              background-color: rgb(255, 255, 255);
                          }
  
                          .warnr2{
                              background-color: rgb(199, 12, 21);
                              color: rgb(255, 255, 255);
                          }
                      }
  
                      .warny{
                          background-color: rgb(250, 150, 0);
  
                          .warny1{
                              background-color: rgb(255, 255, 255);
                          }
  
                          .warny2{
                              background-color: rgb(250, 150, 0);
                              color: rgb(255, 255, 255);
                          }
                      }
                  }
  
                  .signoledref{
                      
                      .sh1{
                          color: rgb(0, 0, 0);
                      }
  
                      .sh2{
                          color: rgb(255, 42, 92);
                      }
  
                      .sh3{
                          color: rgb(178, 229, 91);
                      }
  
                      .sh4{
                          color: rgb(0, 172, 220);
                      }
  
                      .ad1{
                          animation: ap2 10s linear infinite;
                      }
                  }
              }
          }
      }
  }
  
  @media(max-width: 610px){
      .first-cont{
          .mercados-cont{
              grid-template-columns: repeat(1, 1fr);
          }
      }
  }
  
  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .skeleton-text__body {
    width: 75%;
  }
  
  .skeleton-footer {
    width: 30%;
  }