import { Routes } from '@angular/router';
import { AdministraDireccionesComponent } from './pages/administra-direcciones/administra-direcciones.component';
import { ArticuloBlogComponent } from './pages/articulo-blog/articulo-blog.component';
import { BlogsitoComponent } from './pages/blogsito/blogsito.component';
import { BrothaComponent } from './pages/brotha/brotha.component';
import { CartComponent } from './pages/cart/cart.component';
import { CatalogoPaquetesComponent } from './pages/catalogo-paquetes/catalogo-paquetes.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { CategoriaPorPaquetesComponent } from './pages/categoria-por-paquetes/categoria-por-paquetes.component';
import { CategoriaPromocionesCricutPage } from './pages/categoria-promociones-cricut/categoria-promociones-cricut.component';
import { CategoriasComponent } from './pages/categorias/categorias.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ClasesCreativasComponent } from './pages/clases-creativas/clases-creativas.component';
import { CodigoQrComponent } from './pages/codigo-qr/codigo-qr.component';
import { CompraYRecogeComponent } from './pages/compra-y-recoge/compra-y-recoge.component';
import { ConoceMasDeLuminosComponent } from './pages/conoce-mas-de-luminos/conoce-mas-de-luminos.component';
import { ConoceMasDeVaraliComponent } from './pages/conoce-mas-de-varali/conoce-mas-de-varali.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { CreativeGiftComponent } from './pages/creative-gift/creative-gift.component';
import { CursosGratisComponent } from './pages/cursos-gratis/cursos-gratis.component';
import { DescripcionPaquetesComponent } from './pages/descripcion-paquetes/descripcion-paquetes.component';
import { DescripcionProductoComponent } from './pages/descripcion-producto/descripcion-producto.component';
import { DistribuidorComponent } from './pages/distribuidor/distribuidor.component';
import { EspecificacionesPlottersCricutComponent } from './pages/especificaciones-plotters-cricut/especificaciones-plotters-cricut.component';
import { ExperienciaCompraComponent } from './pages/experiencia-compra/experiencia-compra.component';
import { FacturacionComponent } from './pages/facturacion/facturacion.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FormularioExpoComponent } from './pages/formulario-expo/formulario-expo.component';
import { GoogleSearchComponent } from './pages/google-search/google-search.component';
import { HomeComponent } from './pages/home/home.component';
import { LidepuntosComponent } from './pages/lidepuntos/lidepuntos.component';
import { LoginComponent } from './pages/login/login.component';
import { MesaRegalosCajaComponent } from './pages/mesa-regalos-caja/mesa-regalos-caja.component';
import { MesaRegalosCompartirRegalosComponent } from './pages/mesa-regalos-compartir-regalos/mesa-regalos-compartir-regalos.component';
import { MesaRegalosCompartirComponent } from './pages/mesa-regalos-compartir/mesa-regalos-compartir.component';
import { MesaRegalosConfComponent } from './pages/mesa-regalos-conf/mesa-regalos-conf.component';
import { MesaRegalosConstructComponent } from './pages/mesa-regalos-construct/mesa-regalos-construct.component';
import { MesaRegalosElegirComponent } from './pages/mesa-regalos-elegir/mesa-regalos-elegir.component';
import { MesaRegalosLandingComponent } from './pages/mesa-regalos-landing/mesa-regalos-landing.component';
import { MesaRegalosPrincipalComponent } from './pages/mesa-regalos-principal/mesa-regalos-principal.component';
import { MesaRegalosRegistroComponent } from './pages/mesa-regalos-registro/mesa-regalos-registro.component';
import { MesaRegalosTipoComponent } from './pages/mesa-regalos-tipo/mesa-regalos-tipo.component';
import { MesaRegalosTiposComponent } from './pages/mesa-regalos-tipos/mesa-regalos-tipos.component';
import { MesaRegalosVerComponent } from './pages/mesa-regalos-ver/mesa-regalos-ver.component';
import { MesaRegalosComponent } from './pages/mesa-regalos/mesa-regalos.component';
import { MiPerfilComponent } from './pages/mi-perfil/mi-perfil.component';
import { ModalRecuperarComponent } from './pages/modal-recuperar/modal-recuperar.component';
import { ModalRegistroComponent } from './pages/modal-registro/modal-registro.component';
import { NosotrosComponent } from './pages/nosotros/nosotros.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { ProductosRelacionadosToolComponent } from './pages/productos-relacionados-tool/productos-relacionados-tool.component';
import { RallyComponent } from './pages/rally/rally.component';
import { ReseteoPasswordComponent } from './pages/reseteo-password/reseteo-password.component';
import { ResumenCompraComponent } from './pages/resumen-compra/resumen-compra.component';
import { ResumenVentaComponent } from './pages/resumen-venta/resumen-venta.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { SoftwareDeEquiposComponent } from './pages/software-de-equipos/software-de-equipos.component';
import { TerminosPoliticasComponent } from './pages/terminos-politicas/terminos-politicas.component';
import { TiposCategoriasComponent } from './pages/tipos-categorias/tipos-categorias.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';

export const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'silhouette-cameo-5/6700-paquete-hot-stamping-con-silhouette-cameo-5.html', redirectTo: 'paquetes/6700-SILH-0047.html', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/3900-4410-1900.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5491-paquete-silhouette-cameo-4-designer-edition.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5021-SILH-0004.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6670-combo-plotter-de-corte-silhouette-cameo-4-negro-rotulacion.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6853-duo-silhouette-cameo-4-rojo-laminadora-foil.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6852-SILH-0001-BLK.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6851-SILH-0001-RED.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette0', pathMatch: 'full' },
    { path: 'paquetes/6290-paquete-cameo-4-empire-red-laminadora-luminos-y-viniles.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6853-SILH-0003-RED.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6854-SILH-0003-BLK.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6527-SILH-0028.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6851-13393-paquete-silhouette-cameo-4-rojo-laminadora.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5026-SILH-0015.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/4999-SILH-0003.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6528-SILH-0029.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6352-SILH-0038.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5022-5443-SILH-0008.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6527-duo-silhouette-cameo-4-color-blanco-laminadora-pink-9.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5027-SILH-0016.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5490-paquete-silhouette-cameo-4-stickers.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'inicio/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'inicio/5020-5441-SILH-0002.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/4998-SILH-0001.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-cubiertas-totes/4450-4420-1995.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5024-SILH-0012.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/3901-4410-1902.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5839-paquete-silhouette-cameo-4-bw.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6352-duo-blanco-minc-silhouette-cameo-4-laminadora-12.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5022-SILH-0008.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/6285-plotter-de-corte-silhouette-cameo-4-color-rojo.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6668-combo-plotter-de-corte-silhouette-cameo-4-rojo-rotulacion.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6352-duo-blanco-minc-silhouette-cameo-4-laminadora-12.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5022-SILH-0008.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6668-combo-plotter-de-corte-silhouette-cameo-4-rojo-rotulacion.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6292-SILH-0033.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/6285-plotter-de-corte-silhouette-cameo-4-color-rojo.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6528-duo-silhouette-cameo-4-color-blanco-laminadora-pink-13.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6855-paquete-silhouette-lovers-rojo.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6856-paquete-silhouette-lovers-negro.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/7083-PREV-4425-0007.html', redirectTo: 'cricut/7083-plotter-de-corte-cricut-explore-air-2-azul-2005420.html', pathMatch: 'full' },



    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'registro',
        component: ModalRegistroComponent
    },
    {
        path: 'recuperar-cuenta',
        component: ModalRecuperarComponent
    },
    {
        path: 'cart',
        component: CartComponent
    },
    {
        path: 'blog',
        component: BlogsitoComponent
    },
    {
        path: 'articulo-blog/:id_blog',
        component: ArticuloBlogComponent
    },
    {
        path: 'contacto',
        component: ContactoComponent
    },
    {
        path: 'cursos-gratis',
        component: CursosGratisComponent
    },
    {
        path: 'distribuidor',
        component: DistribuidorComponent
    },
    {
        path: 'lidepuntos',
        component: LidepuntosComponent
    },
    {
        path: 'compra-y-recoge',
        component: CompraYRecogeComponent
    },
    {
        path: 'conoce-mas-de-varali',
        component: ConoceMasDeVaraliComponent
    },
    {
        path: 'conoce-mas-de-luminos',
        component: ConoceMasDeLuminosComponent
    },
    {
        path: 'rally',
        component: RallyComponent
    },
    {
        path: 'clases-creativas',
        component: ClasesCreativasComponent
    },
    {
        path: 'especificaciones-plotters-cricut',
        component: EspecificacionesPlottersCricutComponent
    },
    {
        path: 'codigo-qr',
        component: CodigoQrComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },
    {
        path: 'nosotros',
        component: NosotrosComponent
    },
    {
        path: 'resetPassword',
        component: ReseteoPasswordComponent
    },
    {
        path: 'venta/:ov',
        component: ResumenVentaComponent
    },
    {
        path: 'software-de-equipos',
        component: SoftwareDeEquiposComponent
    },
    {
        path: 'paquetes/:id_product-:sku',
        component: DescripcionPaquetesComponent
    },
    {
        path: 'catalogo/:id_categoria/:link_rewrite',
        component: CatalogoComponent
    },
    {
        path: 'categoria/:id_parent/:link_rewrite',
        component: CategoriasComponent
    },
    {
        path: 'paquetes',
        component: CategoriaPorPaquetesComponent
    },
    {
        path: 'catalogo-paquetes/:id_categoria/:link_rewrite',
        component: CatalogoPaquetesComponent
    },
    {
        path: 'categorias',
        component: TiposCategoriasComponent
    },
    {
        path: 'checkout',
        component: CheckoutComponent
    },
    {
        path: 'checkout-invitado',
        component: CheckoutComponent
    },
    {
        path: 'perfil/:rec',
        component: PerfilComponent
    },
    {
        path: 'wishlist',
        component: WishlistComponent
    },
    {
        path: 'promociones-cricut',
        component: CategoriaPromocionesCricutPage
    },
    {
        path: 'formulario-expo',
        component: FormularioExpoComponent
    },
    {
        path: 'google-search/:busqueda',
        component: GoogleSearchComponent
    },
    {
        path: 'reviews',
        component: ReviewsComponent
    },
    {
        path: 'direcciones',
        component: AdministraDireccionesComponent
    },
    {
        path: 'resumen-compra',
        component: ResumenCompraComponent
    },
    {
        path: '840-brother',
        component: BrothaComponent
    },
    {
        path: 'mi-perfil',
        component: MiPerfilComponent
    },
    {
        path: 'facturacion',
        component: FacturacionComponent
    },
    {
        path: 'mesa-regalos/:id_customer',
        component: MesaRegalosComponent
    },
    {
        path: 'mesa-regalos-caja/:id_customer/:id_regalo',
        component: MesaRegalosCajaComponent
    },
    {
        path: 'mesa-regalos-comparte/:referencia',
        component: MesaRegalosCompartirComponent
    },
    {
        path: 'mesa-regalos-comparte-regalos/:referencia',
        component: MesaRegalosCompartirRegalosComponent
    },
    {
        path: 'mesa-regalos-conf',
        component: MesaRegalosConfComponent
    },
    {
        path: 'mesa-regalos-landing',
        component: MesaRegalosLandingComponent
    },
    {
        path: 'mesa-regalos-principal',
        component: MesaRegalosPrincipalComponent
    },
    {
        path: 'mesa-regalos-registro',
        component: MesaRegalosRegistroComponent
    },
    {
        path: 'mesa-escoge-regalos',
        component: MesaRegalosElegirComponent
    },
    {
        path: 'mesa-regalos-tipos',
        component: MesaRegalosTiposComponent
    },
    {
        path: 'herramienta-productos-relacionados',
        component: ProductosRelacionadosToolComponent
    },
    {
        path: 'mesa-regalos-tipo/:id',
        component: MesaRegalosTipoComponent
    },
    {
        path: 'mesa-regalos-construct',
        component: MesaRegalosConstructComponent
    },
    {
        path: 'creative-gift',
        component: CreativeGiftComponent
    },
    {
        path: 'mesa-regalos-ver',
        component: MesaRegalosVerComponent
    },
    {
        path: 'ejecutivoskam',
        component: ExperienciaCompraComponent
    },

    {
        path: 'terminos-politicas/:id',
        component: TerminosPoliticasComponent
    },
    //#########################esta ruta debe estar siempre semifinal###################################################################
    {
        path: ':category/:id_product-:link_rewrite',
        component: DescripcionProductoComponent
    },

    //TODO: Hacer un 404 aqui


    //#########################esta ruta debe estar siempre al final si o si###################################################################
    /*{
        path: '**',
        redirectTo: 'google-search'
    }*/
];
