import { CommonModule, isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import Swal from "sweetalert2";
import { Usuario } from "../../Models/usuario";
import { LoginService } from "../../services/login.service";


@Component({
  selector: 'app-mesa-regalos-landing',
  standalone:true,
  imports: [RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule ],
  templateUrl: './mesa-regalos-landing.component.html',
  styleUrls: ['./mesa-regalos-landing.component.scss'],
})
export class MesaRegalosLandingComponent  implements OnInit {

  public isDesk! : boolean;
  public isCel! : boolean;
  usuario!: Usuario;
  mesita:any=[]
  public tieneMesa : boolean = true;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private usuarioService: LoginService,
    private router : Router
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if ( window.innerWidth > 992 ) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
      try {
      this.usuarioService.usuario.subscribe(res =>{
        if (!res) return
        this.usuario = res;


      });
    } catch (error) {
      this.tieneMesa = false;
      
    }

    }
  }


  checaMesa(){
    this.usuarioService.checaMesa(this.usuario.usuario).subscribe(mesita =>{
            

      this.mesita = mesita;
    
      
      if (this.mesita == 0) {
        this.router.navigate(['/mesa-regalos-tipos/']);
      }else{
       this.faltanCampos()
      }


  })
  }

  faltanCampos(){
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Ya cuentas con una Mesa de Regalos Creada',
      timer:3000
    });
  }

}
