<section class="catalogoDesk" *ngIf="id_cat !=91 && id_cat != 41 && id_cat != 105 && id_cat != 107 && id_cat != 21 && id_cat != 1822 && id_cat != 1824 && id_cat != 1825 && id_cat != 910 && id_cat != 111 && id_cat != 1175 && id_cat != 92 && id_cat != 1178 && id_cat != 224 && id_cat != 140 && id_cat != 1939 && id_cat != 81 && id_cat != 144 && id_cat != 61 && id_cat != 524 && id_cat != 183 && id_cat != 60 && id_cat != 59 && id_cat != 64 && id_cat != 393 && id_cat != 129 && id_cat != 93 && id_cat != 887 && id_cat != 22 && id_cat != 35 && id_cat != 20 && id_cat != 1426 && id_cat != 1424 && id_cat != 44 && id_cat != 72 && id_cat != 23 && id_cat != 137 && id_cat != 149 && id_cat != 75 && id_cat != 2028 && id_cat != 1924">
  
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>

  <div class="contDesk" *ngIf="cargando">

    <div class="conten-cats">





      <div class="cardin" >
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>
      
          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>
      
            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>
      
          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
 
      </div>
      <div class="cardin" >
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>
      
          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>
      
            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>
      
          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
 
      </div>
      <div class="cardin" >
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>
      
          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>
      
            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>
      
          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
 
      </div>
      <div class="cardin" >
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>
      
          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>
      
            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>
      
          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
 
      </div>
    </div>


  </div>
  

  
  
  <div class="contDesk" *ngIf="!cargando">

    <div class="title-cont">
      <!-- <h1 #focus>{{catalogo_cat}}</h1> -->
       <img src="/assets/img/creativeBanner.webp">
    </div>
    <!-- <div class="title-cont" *ngIf="id_cat == 2058">
      <img src="/assets/img/HotFest.jpg" alt="">
    </div> -->
    <div class="conten-cats">
      <div class="cardin" *ngFor="let producto of currentItemsToShow" (click)="navigateToDescripcionProducto(producto.category_link,producto.id_product,producto.link_rewrite,producto.paquete,producto.sku)"> <!-- items [routerLink]="['/descripcion-producto',producto.id_product,producto.link_rewrite]" -->
        <div class="contImg">
          <img src="{{producto.image_link}}" alt="">
        </div>
        <span class="cat-name">{{producto.title}}</span> 
        <ng-container *ngIf="producto.reduction !== undefined && producto.reduction !== null else noReduction">
          <span class="pric">${{producto.precio - (producto.precio * producto.reduction) | number: '1.2-2' }} mxn</span>
          <del class="pric">{{producto.precio | currency: '$'}} mxn</del>
        </ng-container>
        <ng-template #noReduction>
          <span class="pric">{{producto.precio | currency: '$'}} mxn</span>
        </ng-template>
      </div>
    </div>

    <label for="d" class="paginatorS">
      <mat-paginator (page)="onPageChange($event)" [pageSize]="24" [length]="tamanoLista" [pageSizeOptions]="[24,48,60,120]" aria-label="d" showFirstLastButtons></mat-paginator>
    </label>

  </div>
</section>