<section class="" style=" margin-top: 100px;">
    <div class="conten-cont" style="">
        <!-- <p class="texto-azul-desk" style="font-size: 1.5em;"><strong>¡COMPRA Y RECOGE EN ALMACÉN CHIHUAHUA!</strong></p> -->
        <div class="container-1" style="justify-content: center; align-items: flex-start; gap: 5em;">
            <!-- Imagen del Banner -->
            <div class="banner-container"
                style="align-items: center;width: auto;height: auto;padding-right: 0em; position: relative; display: block;">
                <img src="/assets/img/conoce-mas-de-luminos/Acceso-por-codigo-QR-09.webp" alt="">
            </div>
            <div class="banner-container"
                style="align-items: center;width: auto;height: auto;padding-right: 0em; position: relative; display: block;">
                <img src="/assets/img/conoce-mas-de-luminos/Acceso-por-codigo-QR-10.webp" alt="">
                <div class="texto-sobre-imagen"
                    style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%); color: #d38090; font-size: 2.5em; text-align: center;">
                    Encuentra tips, ideas y técnicas para aprovechar al máximo nuestros productos.
                </div>
                <div class="texto-sobre-imagen"
                    style="position: absolute; top: 29%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                    <a href="https://youtu.be/8txNFqScAEs?feature=shared"><img
                            src="/assets/img/conoce-mas-de-luminos/Acceso-por-codigo-QR-11.webp" alt=""></a>
                </div>
                <div class="texto-sobre-imagen"
                    style="position: absolute; top: 52%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                    <a href="https://youtu.be/nBIP26_6zLM"><img
                            src="/assets/img/conoce-mas-de-luminos/Acceso-por-codigo-QR-12.webp" alt=""></a>
                </div>
                <div class="texto-sobre-imagen"
                    style="position: absolute; top: 75%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                    <a href="https://youtu.be/SdDSNwetabA"><img
                            src="/assets/img/conoce-mas-de-luminos/Acceso-por-codigo-QR-13.webp" alt=""></a>
                </div>
            </div>
            <div class="banner-containerMovil"
                style="align-items: center;width: auto;height: auto;padding-right: 0em; position: relative; display: none;">
                <img class="imagen-movil" src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR-03.webp"
                    alt="" style="display: block;">
                <img class="imagen-movil2" src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR.webp"
                    alt="" style="display: none;">
                <div class="texto-sobre-imagenMovil"
                    style="position: absolute; top: 39%; left: 50%; transform: translate(-50%, -50%); color: #d38090; font-size: 2em; text-align: center;width: 80%; padding: 0.5em; line-height: 1.2;">
                    Encuentra tips, ideas y técnicas para aprovechar al máximo nuestros productos.
                </div>
                <div class="texto-sobre-imagen1"
                    style="position: absolute; top: 32%; left: 74%; transform: translate(-50%, -50%); text-align: center; width: 14%;">
                    <img src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR-08.webp" alt="">
                </div>
                <div class="texto-sobre-imagen2"
                    style="position: absolute; top: 49%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                    <a href="https://youtu.be/8txNFqScAEs?feature=shared"><img
                            src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR-05.webp" alt=""></a>
                </div>
                <div class="texto-sobre-imagen3"
                    style="position: absolute; top: 61%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                    <a href="https://youtu.be/nBIP26_6zLM"><img
                            src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR-06.webp" alt=""></a>
                </div>
                <div class="texto-sobre-imagen4"
                    style="position: absolute; top: 73%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                    <a href="https://youtu.be/SdDSNwetabA"><img
                            src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR-07.webp" alt=""></a>
                </div>
                <div class="texto-sobre-imagen5"
                    style="position: absolute; top: 92%; left: 50%; transform: translate(-50%, -50%); text-align: center; width: 91%;">
                    <img src="/assets/img/conoce-mas-de-luminos/MOVIL-acceso-por-codigo-QR-04.webp" alt="">
                </div>
            </div>
        </div>
    </div>
</section>