import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MailchimpServiceService {
  constructor(private http: HttpClient) { }

  MailChimpReg(customerData: any): Observable<any> {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/mailchimpRegistro.php', customerData);
    // return this.http.post('https://inax2.aytcloud.com/inaxapiv2/api/ecommerce/mailchimp',customerData, this.httpOptions);
  }
}
