import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation } from '@angular/core';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CommonModule, Location } from '@angular/common';
import Fuse from 'fuse.js';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-tipos-categorias',
  standalone: true,
  imports: [CommonModule,FormsModule,RouterModule,IonicModule],
  templateUrl: './tipos-categorias.component.html',
  styleUrls: ['./tipos-categorias.component.scss'],
  providers:[CarritoServiceService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TiposCategoriasComponent  implements OnInit {

  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  public totalItems: number = 0;
  id_parent:any;
  categorias: any;
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre','refi','tags'],
  };

  constructor(private shoppingCart: CarritoServiceService, private location : Location) { }

  ngOnInit() {}


  goBack() {
    this.location.back();
  }
  
  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);
      
      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

}
