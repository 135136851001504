import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import Swal from 'sweetalert2';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { LoginService } from '../../services/login.service';
import { RegistroService } from '../../services/registro.service';

@Component({
  selector: 'app-mesa-regalos-conf',
  standalone:true,
  imports: [  RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule ],
  templateUrl: './mesa-regalos-conf.component.html',
  styleUrls: ['./mesa-regalos-conf.component.scss'],
})
export class MesaRegalosConfComponent  implements OnInit {

  cargando = false;
  usuario!: Usuario;
  direccion: any = [];
  nombreEstado: any;
  email:any;
  id_address:any;
  estoycargando = false;


  modal_wpIs = false;
  modal_wpIs2 = false;
  direccionesForm!: UntypedFormGroup;
  public datosDireccion: any = []
  public ciudadsinCodigo:any;
  public nombreColonia:any;
  public ciudadSeleccionada:any;
  public codigoEscrito = 0;
  public countyId:any;
  public codiguillo: any =[];
  public arrayCiudades:any=[];
  public arrayColonias:any =[]
  public arraicitoCiudades:any =[];
  public arraisitoColonias:any=[];
  public arraicitoEstado:any =[];
  public ciudadilla:any;
  datosDireccionsita:any =[]
  public accion: any;
  notiene = 0;


  constructor(private cdr: ChangeDetectorRef,
    private renderer2: Renderer2,
    public registroService: RegistroService,
    private loginService: LoginService,
    private formBuilder : UntypedFormBuilder,
    private route: ActivatedRoute,
    private checkoutService: CheckoutServiceService,
    private productoService: ApiProductosService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {

      this.direccionesForm = this.formBuilder.group({
        zipcode: ['', Validators.required],
        stateValue: ['', Validators.required],
        cityValue: ['', Validators.required],
        colonyValue: ['', Validators.required],
        countryValue: [''],
        alias: [''],
        address1: ['', Validators.required],
        address2: [''],
        phonenumber: ['',Validators.required]
      });
     }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      this.loginService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;
        //console.log(this.usuario);
      });

      this.tambienChecalo();

    }

  }

  tambienChecalo() {
    this.cargando = true;
    this.checkoutService.getDireccionMesa(this.usuario.usuario).subscribe(res => {
      try {

        this.direccion = res;

        if (this.direccion == 0) {

          this.notiene = 1;
          this.cargando = false;
        }else{
          this.notiene = 0;

          
    
          for (let i = 0; i < this.direccion.length; i++) {
            const element = this.direccion[i].id_state;
            // if (element == 62) {
            //   this.nombreEstado = "Chihuahua";
            // }
            if (element ==57){
              this.nombreEstado = "Aguascalientes"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==58){
              this.nombreEstado = "Baja California"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==59){
              this.nombreEstado = "Baja California Sur"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==60){
              this.nombreEstado = "Campeche"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==61){
              this.nombreEstado = "Chiapas"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==62){
              this.nombreEstado = "Chihuahua"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==63){
              this.nombreEstado = "Coahuila"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==64){
              this.nombreEstado = "Colima"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==65){
              this.nombreEstado = "Distrito Federal"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==66){
              this.nombreEstado = "Durango"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==67){
              this.nombreEstado = "Guanajuato"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==68){
              this.nombreEstado = "Guerrero"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==69){
              this.nombreEstado = "Hidalgo"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==70){
              this.nombreEstado = "Jalisco"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==71){
              this.nombreEstado = "Estado de Mexico"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==72){
              this.nombreEstado = "Michoacan"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==73){
              this.nombreEstado = "Morelos"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==74){
              this.nombreEstado = "Nayarit"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==75){
              this.nombreEstado = "Nuevo Leon"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==76){
              this.nombreEstado = "Oaxaca"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==77){
              this.nombreEstado = "Puebla"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==78){
              this.nombreEstado = "Queretaro"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==79){
              this.nombreEstado = "Quintana Roo"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==80){
              this.nombreEstado = "San Luis Potosi"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==81){
              this.nombreEstado = "Sinaloa"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==82){
              this.nombreEstado = "Sonora"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==83){
              this.nombreEstado = "Tabasco"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==84){
              this.nombreEstado = "Tamaulipas"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==85){
              this.nombreEstado = "Tlaxcala"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==86){
              this.nombreEstado = "Veracruz"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==87){
              this.nombreEstado = "Yucatan"
              this.ciudadsinCodigo = this.nombreEstado
            }
            else if (element ==88){
              this.nombreEstado = "Zacatecas"
              this.ciudadsinCodigo = this.nombreEstado
            } 
            else if (element ==null ){
              this.nombreEstado = ""
              this.ciudadsinCodigo = this.nombreEstado
            } 
            else if (element ==undefined ){
              this.nombreEstado = ""
              this.ciudadsinCodigo = this.nombreEstado
            } 
            
          } 


    
          if (this.usuario) {
            this.email = this.usuario.usuario
          }else{
            this.email = "";
          }
    
          let datos = {
            "email": this.email
          }

          this.direccionesForm.controls['zipcode'].setValue(this.direccion[0].postcode);
          this.direccionesForm.controls['stateValue'].setValue(this.direccion[0].estado);
          this.direccionesForm.controls['cityValue'].setValue(this.direccion[0].city);
          this.direccionesForm.controls['stateValue'].setValue(this.nombreEstado);
          this.direccionesForm.controls['colonyValue'].setValue(this.direccion[0].colony);
          this.direccionesForm.controls['countryValue'].setValue(this.direccion[0].id_country);
          this.direccionesForm.controls['alias'].setValue(this.direccion[0].alias);
          this.direccionesForm.controls['address1'].setValue(this.direccion[0].address1);
          this.direccionesForm.controls['address2'].setValue(this.direccion[0].address2);
          this.direccionesForm.controls['phonenumber'].setValue(this.direccion[0].phone);
          // this.ciudadsinCodigo = this.nombreEstado;
          this.ciudadSeleccionada = this.direccion[0].city;
          this.nombreColonia = this.direccion[0].colony;

  

          this.cargando = false;
        }
        
     
    } catch (error) {
      this.cargando = false; 
    }
    })
  }

  imprimir(){
    //console.log(this.direccionesForm.controls.colonyValue.value);
    var separame = this.direccionesForm.controls['colonyValue'].value.split('-');
    var a = separame[0];
    var b = separame[1]
    this.nombreColonia = a;
    this.countyId = b;
  
  }

  buscameXCodigoPostal(codigo:any){
    try {
      
  
    this.cargando = true;
    this.nombreColonia = "--- Selecciona Colonia ---";
    this.registroService.getEstados(codigo).subscribe(codigazo =>{
      this.arraicitoEstado = codigazo
      this.codiguillo = this.arraicitoEstado.data;
      if (this.codiguillo == null || this.codiguillo == "") {
        this.nohaycodigo();
        this.cargando = false;
        this.codigoEscrito = 0;
      }else{
        this.codigoEscrito = 1;
        this.ciudadilla = this.codiguillo.stateId;
       
        if (this.ciudadilla == 'AGS'){ this.ciudadsinCodigo ="Aguascalientes"}
        else if (this.ciudadilla == 'BCA'){ this.ciudadsinCodigo ="Baja California"}
        else if (this.ciudadilla == 'BCS'){ this.ciudadsinCodigo ="Baja California Sur"}
        else if (this.ciudadilla == 'CAM'){ this.ciudadsinCodigo ="Campeche"}
        else if (this.ciudadilla == 'CHI'){ this.ciudadsinCodigo ="Chiapas"}
        else if (this.ciudadilla == 'CHH'){ this.ciudadsinCodigo ="Chihuahua"}
        else if (this.ciudadilla == 'COA'){ this.ciudadsinCodigo ="Coahuila"}
        else if (this.ciudadilla == 'COL'){ this.ciudadsinCodigo ="Colima"}
        else if (this.ciudadilla == 'DIF'){ this.ciudadsinCodigo ="Distrito Federal"}
        else if (this.ciudadilla == 'DGO'){ this.ciudadsinCodigo ="Durango"}
        else if (this.ciudadilla == 'GTO'){ this.ciudadsinCodigo ="Guanajuato"}
        else if (this.ciudadilla == 'GRO'){ this.ciudadsinCodigo ="Guerrero"}
        else if (this.ciudadilla == 'HGO'){ this.ciudadsinCodigo ="Hidalgo"}
        else if (this.ciudadilla == 'JAL'){ this.ciudadsinCodigo ="Jalisco"}
        else if (this.ciudadilla == 'CDMX'){ this.ciudadsinCodigo ="Ciudad de México"}
        else if (this.ciudadilla == 'MIC'){ this.ciudadsinCodigo ="Michoacán"}
        else if (this.ciudadilla == 'MEX'){ this.ciudadsinCodigo ="Estado de México"}
        else if (this.ciudadilla == 'MOR'){ this.ciudadsinCodigo ="Morelos"}
        else if (this.ciudadilla == 'NAY'){ this.ciudadsinCodigo ="Nayarit"}
        else if (this.ciudadilla == 'NLE'){ this.ciudadsinCodigo ="Nuevo León"}
        else if (this.ciudadilla == 'OAX'){ this.ciudadsinCodigo ="Oaxaca"}
        else if (this.ciudadilla == 'PUE'){ this.ciudadsinCodigo ="Puebla"}
        else if (this.ciudadilla == 'QRO'){ this.ciudadsinCodigo ="Querétaro"}
        else if (this.ciudadilla == 'ROO'){ this.ciudadsinCodigo ="Quintana Roo"}
        else if (this.ciudadilla == 'SLP'){ this.ciudadsinCodigo ="San Luis Potosí"}
        else if (this.ciudadilla == 'SIN'){ this.ciudadsinCodigo ="Sinaloa"}
        else if (this.ciudadilla == 'SON'){ this.ciudadsinCodigo ="Sonora"}
        else if (this.ciudadilla == 'TAB'){ this.ciudadsinCodigo ="Tabasco"}
        else if (this.ciudadilla == 'TAM'){ this.ciudadsinCodigo ="Tamaulipas"}
        else if (this.ciudadilla == 'TLA'){ this.ciudadsinCodigo ="Tlaxcala"}
        else if (this.ciudadilla == 'VER'){ this.ciudadsinCodigo ="Veracruz"}
        else if (this.ciudadilla == 'YUC'){ this.ciudadsinCodigo ="Yucatán"}
        else if (this.ciudadilla == 'ZAC'){ this.ciudadsinCodigo ="Zacatecas"}
        this.direccionesForm.controls['stateValue'].setValue(this.ciudadsinCodigo);
        
        this.registroService.getCiudades(codigo,this.ciudadilla).subscribe(ciudadcillas =>{
        
          this.arraicitoCiudades = ciudadcillas
          this.arrayCiudades =  this.arraicitoCiudades.data;
          this.ciudadSeleccionada= this.arraicitoCiudades.data[0].ciudadSeleccionada
          this.direccionesForm.controls['cityValue'].setValue(this.ciudadSeleccionada);
  
  
          this.registroService.getColonias(codigo).subscribe(coloniecillas =>{
            this.arraisitoColonias = coloniecillas
            this.arrayColonias = this.arraisitoColonias.data;
            this.direccionesForm.controls['colonyValue'].setValue(this.nombreColonia);
  
  
            this.cargando =false;
          })
        })
      }
    
    })
  } catch (error) {
      this.cargando = false;
  }
  }


  guardaDireccion(){
    this.cargando =true;
      var datosDireccion: any = Object.values(this.direccionesForm.value);
      
        let alias = "";
        // let id_state = datosDireccion[1];
        // let id_state = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;
        
        // let nombreEstado = datosDireccion[2];
        let postcode = datosDireccion[0];
        let colonyArray = datosDireccion[3];
        let separados = colonyArray.split('-');
        let phone = datosDireccion[8];
        let colony = separados[0];
        // let city = datosDireccion[2];
        var city = (<HTMLSelectElement>document.getElementsByName('city')[0]).selectedOptions[0].innerText;
        let address1 = datosDireccion[6];
        let address2 = datosDireccion[7];
        
  
        let id_address = this.id_address;
  
        let datosDir = {
                        "alias":alias,
                        "stateValue":this.ciudadsinCodigo,
                        "zipcode":postcode,
                        "colonyValue":colony,
                        "phonenumber":phone,
                        "cityValue":city,
                        "address1":address1,
                        "address2":address2,
                        "idDir":id_address,
                        "correo":this.usuario.usuario
                        }
        this.loginService.actualizaDatosDireccionMesa(datosDir).subscribe(response=>{
         
            this.actualizacionCorrecta();
          // }else if(this.accion = 'agregar'){
          //   this.agregaDireccionCorrecta();
          // }
            // setTimeout(() => {
              
              this.cargando = false;
              window.location.reload();
              // location.reload();
            // },1000);
          });

      
    }
  
  nohaycodigo(){ 
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Código postal no encontrado',
    });
    
  }


  nuevitaDireccion(){
this.cargando =true;
    var datosDireccion: any = Object.values(this.direccionesForm.value);
      
        let alias = "";
        // let id_state = datosDireccion[1];
        // let id_state = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;
        
        // let nombreEstado = datosDireccion[2];
        let postcode = datosDireccion[0];
        let colonyArray = datosDireccion[3];
        let separados = colonyArray.split('-');
        let phone = datosDireccion[8];
        let colony = separados[0];
        // let city = datosDireccion[2];
        var city = (<HTMLSelectElement>document.getElementsByName('city')[0]).selectedOptions[0].innerText;
        let address1 = datosDireccion[6];
        let address2 = datosDireccion[7];
 
    let arregloDir = {
        "alias": alias,
        "phonenumber": phone,
        "zipcode": postcode,
        "address1":address1,
        "address2": address2,
        "stateValue": this.ciudadsinCodigo,
        "cityValue": city,
        "colony":colony,
        "correo": this.usuario.usuario
    }

    this.loginService.enviaDireccionMesa(arregloDir).subscribe((datos:any) =>{
      this.cargando =  false;
      this.agregaDireccionCorrecta();
      setTimeout(() => {
        
        location.reload();
      }, 1500);
    })


  }

  agregaDireccionCorrecta(){ 
    Swal.fire({
      icon: 'success',
      title: 'Confirmación',
      text: 'Se ha agregado su direccion correctamente',
    });
    
  }
  
  actualizacionCorrecta(){ 
    Swal.fire({
      icon: 'success',
      title: 'Confirmación',
      text: 'Su direccion ha sido modificada',
    });
    
  }
  
  faltanCampos(){
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Por favor llene todos los campos',
      timer:3000
    });
  }

}
