import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { IonicModule, ModalController } from '@ionic/angular';


import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { CommonModule, Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { ProductService } from '../../services/handle-categorias.service';


@Component({
  selector: 'app-creative-gift',
  standalone: true,
  imports: [MatPaginatorModule,CommonModule,RouterModule,IonicModule],
  templateUrl: './creative-gift.component.html',
  styleUrls: ['./creative-gift.component.scss'],
})
export class CreativeGiftComponent  implements OnInit {

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  items :any=[];
  sum:number = 1;

  public page = 1;
  perPage = 10;
  
  totalData = 0;
  totalPage = 10;
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public totalItems: number = 0;
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  productosCatalogo: any;
  cargando = false;
  precio: any;
  id_product : any;
  cp: number = 1;
  descuento:any;
  id_cat: any;
  
  public isDesk : boolean = false;
  public isCel : boolean = false;

  catalogo_cat="";
  public currentItemsToShow : any = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  public tamanoLista : number = 0;

  private productSubscription!: Subscription;
  public productCat: any;

  constructor(private productoService: ApiProductosService, public modalCtrl: ModalController,private activatedRoute:ActivatedRoute,
    private shoppingCart: CarritoServiceService, private productService : ProductService, private location: Location,private router : Router, private titleService:Title) { }

  ngOnInit() {
    this.catalogo_cat = "Creative Gift";
    this.productoService.getCategorias(2342).subscribe((data:any) =>{
      this.productosCatalogo = data;
      this.productService.setCatalogo(this.productosCatalogo);
      this.productSubscription = this.productService.selectedCata$.subscribe((product:any)=>  {
        this.productCat = product;
      });
      this.tamanoLista = this.productCat.length;
      this.currentItemsToShow = this.productCat.slice(0,24);
      this.totalData = this.productCat.length;
      this.precio = this.productCat.precio;
      this.id_product = this.productCat.id_product;
        
      
      for (let i = 0; i < 4; i++) {
        let imp = this.sum;
        if (this.sum <= this.totalData) {
          this.items.push(this.productCat[imp - 1]);
        }
        this.sum++;
      }
    })

    if (this.catalogo_cat) {
      this.titleService.setTitle(this.catalogo_cat);
    }

    this.shoppingCart.cartItems.subscribe((d:any)=>{
      this.totalItems = d.length;
    });

  }


  onPageChange($event:PageEvent) {
    /*this.productoService.getDescuento(jsondescuento).subscribe(resdesc =>{
        this.resdesc = resdesc;
  
        if (this.resdesc === 1) {
        
          this.descuentillo = false;
          this.descuento = 1;
        }else{
        
          this.descuentillo = true;
          this.descuento = this.resdesc;
          this.descuento = 1- this.descuento
        }
    });*/
    this.currentItemsToShow =  this.productCat.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    this.scrollUp();
  }

  scrollUp(): void {
    setTimeout(() => {
      if (this.tableInput && this.tableInput.nativeElement) {
        this.tableInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        console.warn('tableInput o nativeElement no están definidos');
      }
    });
}

  navigateToDescripcionProducto(c:any,b:any,a:any,d:any,e:any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    const paquete = d; // Set the link_rewrite dynamically
    const sku = e; // Set the link_rewrite dynamically
    if (d == 1) {
      this.router.navigateByUrl(`/paquetes/${id_product}-${sku}`+".html");
    } else {
      this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}`+".html");
    }
  }

  goBack() {
    this.location.back();
  }

}
