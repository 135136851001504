<section class="distribuidorDesk" style=" margin-top: 100px;">
  <div class="dist-cont">
    <div class="taitl-cont">
      <span class="taitl">Distribuidor</span>
    </div>
    <div class="info-cont">
      <img src="https://new.lideart.com.mx/img/cms/tiendas-autorizadas/Banner%20subdistribuidores-01.jpg" alt="">
      <span class="inf">
        ¡Bienvenido al emocionante mundo de las manualidades! Si estás buscando una oportunidad para hacer crecer tu
        negocio en el sector de las manualidades,
        te invitamos a convertirte en nuestro subdistribuidor.
      </span>
      <span class="inf">
        Como subdistribuidor, tendrás acceso un gran catálogo de materiales de alta calidad, además, disfrutarás de
        precios especiales al comercializar nuestros
        productos, lo que te permitirá obtener atractivas ganancias y beneficios exclusivos para subdistribuidores.
        También te proporcionaremos capacitación y soporte.
      </span>
      <span class="inf">
        ¡No esperes más para unirte a nuestro equipo de subdistribuidores Lideart!
      </span>
      <div class="cont-form">
        <div class="signupFrm">
          <div action="" class="form">
            <h1 class="title">Ingresa tu Información</h1>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="nombre_negocio" placeholder="a">
              <label for="" class="label">Nombre de Tu Negocio</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="nombre_cliente" placeholder="a">
              <label for="" class="label">Nombre</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="correo" placeholder="a">
              <label for="" class="label">Correo Electrónico</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="telefono" placeholder="a">
              <label for="" class="label">Telefono</label>
            </div>

            <div class="inputContainer">
              <!-- <textarea id="w3review" class="textarea" [(ngModel)]="mensaje"  rows="4" cols="50" placeholder="a"></textarea> -->
              <input type="text" class="input" [(ngModel)]="mensaje" placeholder="a">
              <label for="" class="label">Mensaje</label>
            </div>

            <input type="button" class="submitBtn" (click)="enviaEncuesta()" value="Enviar">
          </div>
        </div>
        <!-- <iframe src="https://webforms.pipedrive.com/f/1AERpEy5t2p8Ko0OBOEoDdmSbXq57CZvegZQktlfTdPfhSczDIxC0S9zTF7y0Wt7t" height="900px" width="500px" frameborder="0" ></iframe> -->
      </div>
      <!-- <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1AERpEy5t2p8Ko0OBOEoDdmSbXq57CZvegZQktlfTdPfhSczDIxC0S9zTF7y0Wt7t" style="text-align: center;"></div>
<div style="position: relative; display: inline-block; text-align: center;"><img src="/img/cms/tiendas-autorizadas/Banner subdistribuidores-02.jpg" style="max-width: 100%; width: auto; height: auto;" /></div> -->
      <!-- <div class="cont-form">
        <form>
          <span class="form-taitl">Ingresa tu información</span>
          <div class="partin">
            <span class="qe">Nombre de tu negocio</span>
            <input type="text">
          </div>
          <div class="partin">
            <span class="qe">Nombre</span>
            <input type="text">
          </div>
          <div class="partin">
            <span class="qe">Correo eléctronico</span>
            <input type="text">
          </div>
          <div class="partin">
            <span class="qe">Teléfono (opcional)</span>
            <input type="text">
          </div>
          <div class="partin">
            <span class="qe">Tu mensaje</span>
            <textarea name="" id="" cols="30" rows="2"></textarea>
          </div>
        </form>
      </div> -->
      <img src="https://new.lideart.com.mx/img/cms/tiendas-autorizadas/Banner%20subdistribuidores-02.jpg" alt="">
      <span class="desp">¡Ten tu propio negocio factible y exitoso!</span>
    </div>
  </div>
</section>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>