.distribuidorDesk{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    gap: 1em;

    .dist-cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3em 0em;
        gap: 2em;

        .taitl-cont{
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            .taitl{
                color: #00a3c7;
                font-weight: 100;
                font-size: 2em;
            }
        }

        .info-cont{
            width: 70%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1em;

            img{
                width: 100%;
                height: auto;
            }

            .inf{
                color: #000;
                font-size: .95em;
                font-weight: 100;
            }

            .cont-form{
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;

                form{
                    width: 30em;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1em;

                    .form-taitl{
                        color: #111;
                        font-weight: 600;
                        font-size: 1.7em;
                        margin: 0.5em 0em;
                    }

                    .partin{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: .4em;

                        .qe{
                            color: #000;
                            font-weight: 100;
                            font-size: .9em;
                        }

                        input{
                            width: 100%;
                            height: 2.5em;
                            padding: 0em 1em;
                            background-color: #fff;
                            border: none;
                            border-bottom: 1px solid #00a3c7;
                            color: #000;
                        }

                        textarea{
                            width: 100%;
                            height: auto;
                            resize: none;
                            padding: 1em;
                            background-color: #00a3c7;
                            color: #fff;
                            border: none;
                        }

                        input:focus{
                            outline: none;
                        }

                        textarea:focus{
                            outline: none;
                        }
                    }
                }
            }

            .desp{
                width: 100%;
                color: #00a3c7;
                font-weight: 100;
                font-size: 1.8em;
                text-align: center;
                margin-top: 1em;
            }
        }

        @media(max-width: 600px){
          .info-cont{
            width: 100%;
          }
        }
    }
}

@media(min-width: 992px){
    ion-header{
        display: none;
    }

    ion-content{
        display: none;
    }

    .distribuidorDesk{
        display: flex;
    }
}

.distribuidorDesk::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
}
  
.distribuidorDesk::-webkit-scrollbar-thumb {
    background-color: #00a2c779;
    //border-radius: 1vh;
    width: 0.25em;
}

.signupFrm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .form {
    background-color: white;
    width: 600px;
    border-radius: 8px;
    padding: 20px 40px;
    box-shadow: 0 10px 25px rgba(92, 99, 105, .2);
  }

  @media(max-width: 600px){
    .form{
      width: 100%;
      padding: 1em 1em;
    }
  }
  
  .title {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 1em;
    color: #3f3f3f;
  }

  .inputContainer {
    position: relative;
    height: 45px;
    width: 90%;
    margin-bottom: 17px;
  }

  .input {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 7px;
    font-size: 16px;
    padding: 0 20px;
    outline: none;
    background: none;
    z-index: 1;
    color: #000;
  }

  .textarea {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    border: 1px solid #DADCE0;
    border-radius: 7px;
    font-size: 16px;
    padding: 0 20px;
    outline: none;
    background: none;
    z-index: 1;
  }

  .textarea:focus + .label {
    top: -7px;
    left: 3px;
    z-index: 10;
    font-size: 14px;
    font-weight: 600;
    color: rgb(0 155 186);
  }

  .textarea:focus {
    border: 2px solid rgb(0 155 186);
  }

  .textarea:not(:placeholder-shown)+ .label {
    top: -7px;
    left: 3px;
    z-index: 10;
    font-size: 14px;
    font-weight: 600;
  }
  
  /* Hide the placeholder texts (a) */
  
  ::placeholder {
    color: transparent;
  }

  .label {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 0 4px;
    background-color: white;
    color: #979797;
    font-size: 16px;
    transition: 0.5s;
    z-index: 0;
  }

  .submitBtn {
    display: block;
    margin-left: auto;
    padding: 15px 30px;
    border: none;
    background-color: rgb(0 155 186);
    color: white;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 30px;
  }
  
  .submitBtn:hover {
    background-color: #9867C5;
    transform: translateY(-2px);
  }

  .input:focus + .label {
    top: -7px;
    left: 3px;
    z-index: 10;
    font-size: 14px;
    font-weight: 600;
    color: rgb(46, 245, 252);
  }

  .input:focus {
    border: 2px solid rgb(46, 245, 252);
  }

  .input:not(:placeholder-shown)+ .label {
    top: -7px;
    left: 3px;
    z-index: 10;
    font-size: 14px;
    font-weight: 600;
  }

  .loader {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: fixed;
    top: calc(0%);
    left: calc(0%);
    background-color: rgb(255, 255, 255);
    opacity: 0.9;
  
    img{
      width: 200px;
      height: auto;
 
    }

  }
