<section class="cont-gen">
        <div class="contan">
          <div class="progreso">
            <label for="file">Progreso de Perfil:</label>
            <progress id="file" max="100" value="{{progresito}}"></progress>
          <label> {{progresito}}% de tu perfil completado.</label>
          </div>
<br>
          <h1>¡Dinos cuál es tu técnicas(s) favorita(s)! y te estaremos enviando contenido más enfocado a tus gustos y necesidades.</h1>
          <div >
            <div >
              <div >
                <label class="container"  for="arteCheck">Arte en papel, decoración en paredes y rígidos.
                  <input type="checkbox" id="arteCheck" name="checkbox" (click)="arteCheck()" [checked]="arte==1">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="decoracionCheck">Decoración textil, sublimación y promocionales.
                  <input type="checkbox" id="decoracionCheck" name="checkbox" (click)="decoracionCheck()" [checked]="decoracion==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="costuraCheck">Costura.
                  <input type="checkbox" id="costuraCheck" name="checkbox" (click)="costuraCheck()" [checked]="costura==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="troqueladocheck">Troquelado.
                  <input type="checkbox" id="troqueladocheck" name="checkbox" (click)="troqueladocheck()" [checked]="troquelado==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="grabadocheck">Grabado.
                  <input type="checkbox" id="grabadocheck" name="checkbox" (click)="grabadocheck()" [checked]="grabado==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="engargoladocheck">Engargolado y scrapbook.
                  <input type="checkbox" id="engargoladocheck" name="checkbox" (click)="engargoladocheck()" [checked]="engargolado==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="arteResinacheck">Arte en resina.
                  <input type="checkbox" id="arteResinacheck" name="checkbox" (click)="arteResinacheck()" [checked]="arteResina==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <label class="container"  for="impresion3dcheck">Impresión en 3D y moldes de plástico.
                  <input type="checkbox" id="impresion3dcheck" name="checkbox" (click)="impresion3dcheck()" [checked]="impresion3d==1" >
                  <span class="checkmark"></span>
                </label>
              </div>
              <div >
                <!-- <input type="text" value="{{this.usuario.usuario}}" formControlName="correo" name="correo" hidden="true" > -->
                <textarea  name="otroTecnicaTexto" id="" cols="30" rows="1" [(ngModel)]="otroTecnica" required placeholder="Otros"></textarea>
              </div>
            </div>
          </div>

          <!-- SENGUDO ROW DE CHECKLISTS -->


          <h1>¿Con cuáles de estas marcas haz trabajado anteriormente?</h1>
          <div class="segunda-ronda">
           
            <div class="column">
              <label class="container"  for="bazzilcheck">Bazzil
                <input type="checkbox" id="bazzilcheck" name="checkbox" (click)="bazzilcheck()" [checked]="bazzil==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="brothercheck">Brother
                <input type="checkbox" id="brothercheck" name="checkbox" (click)="brothercheck()" [checked]="brother==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="colorPourcheck">Color Pour
                <input type="checkbox" id="colorPourcheck" name="checkbox" (click)="colorPourcheck()" [checked]="colorPour==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="colorPrintcheck">Color Print
                <input type="checkbox" id="colorPrintcheck" name="checkbox" (click)="colorPrintcheck()" [checked]="colorPrint==1">
                <span class="checkmark"></span>
              </label>
          
              <label class="container"  for="colortexcheck">Colotex
                <input type="checkbox" id="colortexcheck" name="checkbox" (click)="colortexcheck()" [checked]="colortex==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="corvuscheck">Corvus
                <input type="checkbox" id="corvuscheck" name="checkbox" (click)="corvuscheck()" [checked]="corvus==1">
                <span class="checkmark"></span>
              </label>
          
              <label class="container"  for="crealitycheck">Creatility
                <input type="checkbox" id="crealitycheck" name="checkbox" (click)="crealitycheck()" [checked]="creality==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="cricutcheck">Cricut
                <input type="checkbox" id="cricutcheck" name="checkbox" (click)="cricutcheck()" [checked]="cricut==1">
                <span class="checkmark"></span>
              </label>
            
              <label class="container"  for="dcwvcheck">DCWV
                <input type="checkbox" id="dcwvcheck" name="checkbox" (click)="dcwvcheck()" [checked]="dcwv==1">
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="column">
              <label class="container"  for="doodlebugcheck">Doodlebug
                <input type="checkbox" id="doodlebugcheck" name="checkbox" (click)="doodlebugcheck()" [checked]="doodlebug==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="ektoolscheck">Ektools
                <input type="checkbox" id="ektoolscheck" name="checkbox" (click)="ektoolscheck()" [checked]="ektools==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="foilquillcheck">Foil Quill
                <input type="checkbox" id="foilquillcheck" name="checkbox" (click)="foilquillcheck()" [checked]="foilquill==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="forevercheck">Forever
                <input type="checkbox" id="forevercheck" name="checkbox" (click)="forevercheck()" [checked]="forever==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="gutermanncheck">Gütermann
                <input type="checkbox" id="gutermanncheck" name="checkbox" (click)="gutermanncheck()" [checked]="gutermann==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="heidicheck">Heidi Swapp
                <input type="checkbox" id="heidicheck" name="checkbox" (click)="heidicheck()" [checked]="heidi==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="hotronixcheck">Hotronix
                <input type="checkbox" id="hotronixcheck" name="checkbox" (click)="hotronixcheck()" [checked]="hotronix==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="kronalinecheck">Kronaline
                <input type="checkbox" id="kronalinecheck" name="checkbox" (click)="kronalinecheck()" [checked]="kronaline==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="luminoscheck">Luminos
                <input type="checkbox" id="luminoscheck" name="checkbox" (click)="luminoscheck()" [checked]="luminos==1">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="column">
              <label class="container"  for="lustercheck">Luster
                <input type="checkbox" id="lustercheck" name="checkbox" (click)="lustercheck()" [checked]="luster==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="minccheck">Minc
                <input type="checkbox" id="minccheck" name="checkbox" (click)="minccheck()" [checked]="minc==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="oracalcheck">Oracal
                <input type="checkbox" id="oracalcheck" name="checkbox" (click)="oracalcheck()" [checked]="oracal==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sawgrasscheck">Sawgrass
                <input type="checkbox" id="sawgrasscheck" name="checkbox" (click)="sawgrasscheck()" [checked]="sawgrass==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="siluetcheck">Silhouette
                <input type="checkbox" id="siluetcheck" name="checkbox" (click)="siluetcheck()" [checked]="siluet==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sisercheck">Siser
                <input type="checkbox" id="sisercheck" name="checkbox" (click)="sisercheck()" [checked]="siser==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sizzixcheck">Sizzix
                <input type="checkbox" id="sizzixcheck" name="checkbox" (click)="sizzixcheck()" [checked]="sizzix==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sublimartscheck">Sublimarts
                <input type="checkbox" id="sublimartscheck" name="checkbox" (click)="sublimartscheck()" [checked]="sublimarts==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="wercheck">We R
                <input type="checkbox" id="wercheck" name="checkbox" (click)="wercheck()" [checked]="wer==1">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div >
            <!-- <input type="text" value="{{this.usuario.usuario}}" formControlName="correo" name="correo" hidden="true" > -->
            <textarea  name="otraMarca" id="" cols="30" rows="1" [(ngModel)]="otraMarca" required placeholder="Otra"></textarea>
          </div>
                                        <!-- TERCER ROW DE CHECKLISTS -->

          <h1>¿Cuáles de las marcas que no conoces te gustaría conocer?</h1>
          <div class="segunda-ronda">
           
            <div class="column">
              <label class="container"  for="bazzilcheck2">Bazzil
                <input type="checkbox" id="bazzilcheck2" name="checkbox" (click)="bazzilcheck2()" [checked]="bazzil2==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="brothercheck2">Brother
                <input type="checkbox" id="brothercheck2" name="checkbox" (click)="brothercheck2()" [checked]="brother2==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="colorPourcheck2">Color Pour
                <input type="checkbox" id="colorPourcheck2" name="checkbox" (click)="colorPourcheck2()" [checked]="colorPour2==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="colorPrintcheck2">Color Print
                <input type="checkbox" id="colorPrintcheck2" name="checkbox" (click)="colorPrintcheck2()" [checked]="colorPrint2==1">
                <span class="checkmark"></span>
              </label>
          
              <label class="container"  for="colortexcheck2">Colotex
                <input type="checkbox" id="colortexcheck2" name="checkbox" (click)="colortexcheck2()" [checked]="colortex2==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="corvuscheck2">Corvus
                <input type="checkbox" id="corvuscheck2" name="checkbox" (click)="corvuscheck2()" [checked]="corvus2==1">
                <span class="checkmark"></span>
              </label>
          
              <label class="container"  for="crealitycheck2">Creatility
                <input type="checkbox" id="crealitycheck2" name="checkbox" (click)="crealitycheck2()" [checked]="creality2==1">
                <span class="checkmark"></span>
              </label>
           
              <label class="container"  for="cricutcheck2">Cricut
                <input type="checkbox" id="cricutcheck2" name="checkbox" (click)="cricutcheck2()" [checked]="cricut2==1" >
                <span class="checkmark"></span>
              </label>
            
              <label class="container"  for="dcwvcheck2">DCWV
                <input type="checkbox" id="dcwvcheck2" name="checkbox" (click)="dcwvcheck2()" [checked]="dcwv2==1">
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="column">
              <label class="container"  for="doodlebugcheck2">Doodlebug
                <input type="checkbox" id="doodlebugcheck2" name="checkbox" (click)="doodlebugcheck2()" [checked]="doodlebug2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="ektoolscheck2">Ektools
                <input type="checkbox" id="ektoolscheck2" name="checkbox" (click)="ektoolscheck2()" [checked]="ektools2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="foilquillcheck2">Foil Quill
                <input type="checkbox" id="foilquillcheck2" name="checkbox" (click)="foilquillcheck2()" [checked]="foilquill2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="forevercheck2">Forever
                <input type="checkbox" id="forevercheck2" name="checkbox" (click)="forevercheck2()" [checked]="forever2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="gutermanncheck2">Gütermann
                <input type="checkbox" id="gutermanncheck2" name="checkbox" (click)="gutermanncheck2()" [checked]="gutermann2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="heidicheck2">Heidi Swapp
                <input type="checkbox" id="heidicheck2" name="checkbox" (click)="heidicheck2()" [checked]="heidi2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="hotronixcheck2">Hotronix
                <input type="checkbox" id="hotronixcheck2" name="checkbox" (click)="hotronixcheck2()" [checked]="hotronix2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="kronalinecheck2">Kronaline
                <input type="checkbox" id="kronalinecheck2" name="checkbox" (click)="kronalinecheck2()" [checked]="kronaline2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="luminoscheck2">Luminos
                <input type="checkbox" id="luminoscheck2" name="checkbox" (click)="luminoscheck2()" [checked]="luminos2==1">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="column">
              <label class="container"  for="lustercheck2">Luster
                <input type="checkbox" id="lustercheck2" name="checkbox" (click)="lustercheck2()" [checked]="luster2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="minccheck2">Minc
                <input type="checkbox" id="minccheck2" name="checkbox" (click)="minccheck2()" [checked]="minc2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="oracalcheck2">Oracal
                <input type="checkbox" id="oracalcheck2" name="checkbox" (click)="oracalcheck2()" [checked]="oracal2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sawgrasscheck2">Sawgrass
                <input type="checkbox" id="sawgrasscheck2" name="checkbox" (click)="sawgrasscheck2()" [checked]="sawgrass2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="siluetcheck2">Silhouette
                <input type="checkbox" id="siluetcheck2" name="checkbox" (click)="siluetcheck2()" [checked]="siluet2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sisercheck2">Siser
                <input type="checkbox" id="sisercheck2" name="checkbox" (click)="sisercheck2()" [checked]="siser2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sizzixcheck2">Sizzix
                <input type="checkbox" id="sizzixcheck2" name="checkbox" (click)="sizzixcheck2()" [checked]="sizzix2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="sublimartscheck2">Sublimarts
                <input type="checkbox" id="sublimartscheck2" name="checkbox" (click)="sublimartscheck2()" [checked]="sublimarts2==1">
                <span class="checkmark"></span>
              </label>
              <label class="container"  for="wercheck2">We R
                <input type="checkbox" id="wercheck2" name="checkbox" (click)="wercheck2()" [checked]="wer2==1">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div >
            <!-- <input type="text" value="{{this.usuario.usuario}}" formControlName="correo" name="correo" hidden="true" > -->
            <textarea  name="otraMarca2" id="" cols="30" rows="1" [(ngModel)]="otraMarca2" required placeholder="Otro"></textarea>
          </div>

          <button style="display: flex;justify-content: center;align-items: center;margin-top: 15px;" *ngIf="respuestaPerfil ==0" (click)="mandame()">Registrar Perfil</button>
          <button style="display: flex;justify-content: center;align-items: center;margin-top: 15px;" *ngIf="respuestaPerfil !=0" (click)="actualizaPerfil()">Actualizar Perfil</button>
        </div>

 


    
</section>