<div style="min-height: 15em; margin-top: 200px;" *ngIf="cargandoProductoInfo==0">
  <div class="cont-back-btn">
  </div>
  <div class="columnas">
    <ion-row>
      <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
        <ion-card style="box-shadow: none;border: none; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <!-- <div *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></div> -->
          <!-- <ion-text *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></ion-text>  -->



          <ion-list>
            <ion-item>
              <ion-label>
                <h3>
                  <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                  <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>

          <div class="swiper-container-wrapper">

            <div style="padding-top: 0em;" class="swiper-container" style="align-items: baseline;" id="zoom">

              <ion-card-content>
                <ion-list>
                  <ion-item>
                    <ion-label>
                      <h3>
                        <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                      </h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
                <br>
                <ion-list>
                  <ion-item>
                    <ion-label>
                      <h3>
                        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>

                      </h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-card-content>



            </div>

            <div class="swiper-container" id="swiperEscritorio">

              <ion-card-content>
                <ion-list>
                  <ion-item>
                    <ion-label>
                      <h3>
                        <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                      </h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
                <br>
                <ion-list>
                  <ion-item>
                    <ion-label>
                      <h3>
                        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                        <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                      </h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-card-content>



            </div>

          </div>


        </ion-card>
      </ion-col>

      <ion-col size="12" size-md="6" size-lg="4">
        <ion-card
          style="box-shadow: none;border: none; height: min-content; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <ion-list>
            <ion-item>
              <ion-label>
                <h3>
                  <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-label>
                  <h3>
                    <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  </h3>
                </ion-label>
              </ion-item>
            </ion-list>
            <br>
            <ion-list>
              <ion-item>
                <ion-label>
                  <h3>
                    <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                    <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                    <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                    <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                    <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                  </h3>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
        <ion-card
          style="box-shadow: none; border:none; overflow: unset; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <ion-card-content>
            <ion-grid>
              <ion-row>

                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>
              <ion-row>

              </ion-row>

              <div
                style="border-top: 1px solid #eaeaea;border-bottom: 1px solid #eaeaea; color: #333333; padding-top: 12px;padding-bottom: 12px;font-size: 18px;">
                <div>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </div>



              </div>

              <ion-row>

              </ion-row>
              <ion-row id="existencia">
                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>

                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
                <ion-col>
                  <ion-col>
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-col>

                </ion-col>
              </ion-row>
              <ion-row id="existenciaDesk">
                <div class="conten-exist">

                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                  <ion-col>
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </div>
              </ion-row>
              <!-- <ion-row>
                <ion-col>
                  <ion-text class="text" style="color:rgb(27, 172, 209);">Unidad de Venta:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text"><span id="unidadVenta">{{producto.unitId}}</span></p>
                </ion-col>
              </ion-row> -->
              <ion-row id="colorSeleccionado">
                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>

                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>
              <ion-row>

                <div style="width: 100%;">
                  <ion-col>
                    <ion-text class="text" for=""></ion-text>
                  </ion-col>
                  <ion-col>
                    <div class="color-cont-prop">
                      <div id="" class="selector-color id_group">
                        <ion-list>
                          <ion-item>
                            <ion-label>
                              <h3>
                                <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                                <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                              </h3>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                        <div class="preview">
                          <div class="diva-color-p"></div>
                          <span class="name-color-p"></span>
                        </div>
                      </div>
                    </div>

                    <div class="color-cont-prop-list">
                      <div class="conten-names-opts">
                        <ion-list>
                          <ion-item>
                            <ion-label>
                              <h3>
                                <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>

                              </h3>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </div>
                    </div>
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>

                  </ion-col>
                </div>
              </ion-row>
              <ion-row>
                <ion-col>
                  <!-- <ion-text class="text">Cantidad:</ion-text>    -->
                  <div>

                  </div>
                </ion-col>


                <ion-col>

                </ion-col>

              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>
              <ion-list>
                <ion-item>
                  <ion-label>
                    <h3>
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>

                    </h3>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>



    <ion-card style="-webkit-margin-start: 0px; margin-inline-start: 0px;">


      <ion-card-content>
        <ion-grid>

          <ion-row>

          </ion-row>
          <ion-row>
            <!-- <ion-col>
              <ion-text class="text">Cantidad:</ion-text>   
            </ion-col> -->
            <ion-col style="display: flex;align-items: center; justify-content:start ;">
              <ion-list>
                <ion-item>
                  <ion-label>
                    <h3>
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                      <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                    </h3>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-card-content>

      <!-- <ion-card-content>
        <ion-card-title class="ion-text-center">Descripción del Producto</ion-card-title>
       <br>
        <div [innerHTML]="descripcionsilla"></div>
      </ion-card-content> -->

      <ion-card-content id="caracteristicas">
        <ion-list>
          <ion-item>
            <ion-label>
              <h3>
                <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
              </h3>
            </ion-label>
          </ion-item>
        </ion-list>
        <br>
        <div class="cont-car">
          <ion-list>
            <ion-item>
              <ion-label>
                <h3>
                  <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list>
            <ion-item>
              <ion-label>
                <h3>
                  <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                  <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-card-content>





    </ion-card>




  </div>



</div>

<div class="md:mt-[130px] min-h[15em]" *ngIf="cargandoProductoInfo ==1 && estoyactivo ==1">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <div class="columnas">
    <ion-row>
      <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
        <ion-card *ngFor="let producto of productoData"
          style="box-shadow: none;border: none; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <!-- <div *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></div> -->
          <!-- <ion-text *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></ion-text>  -->


          <ion-text *ngIf="cargandoProductoInfo == 1" class="titulillo">{{producto.title}}</ion-text>

          <div class="swiper-container-wrapper">

            <div style="padding-top: 0em;" class="swiper-container" style="align-items: baseline;" id="zoom">
              <swiper-container thumbs-swiper=".mySwiper" class="mySwiper3" navigation="true">
                <ng-container *ngFor="let item of slider; index as i">
                  <swiper-slide>
                    <img src="{{item.slider}}" alt="{{producto.title}}" />
                  </swiper-slide>
                </ng-container>
                <ng-container *ngFor="let item of slidervideos; index as i">
                  <swiper-slide>
                    <iframe width="560" height="315" frameborder="0" allowfullscreen
                      [src]="getSafeYoutubeUrl('https://youtube.com/embed/'+item.uid)"></iframe>
                  </swiper-slide>
                </ng-container>
              </swiper-container>
            </div>
            <div class="swiper-container hidden sm:block" id="swiperEscritorio">
              <swiper-container space-between="10" slides-per-view="5" free-mode="true" watch-slides-progress="true"
                class="mySwiper">
                <ng-container *ngFor="let item of slider; index as i">
                  <swiper-slide>
                    <img src="{{ item.slider }}" alt="{{producto.title}}" />
                  </swiper-slide>
                </ng-container>
                <ng-container *ngFor="let item of slidervideos; index as i">
                  <swiper-slide>
                    <img src="/assets/img/videito.jpg" alt="wrapmaster slider product" />
                  </swiper-slide>
                </ng-container>
              </swiper-container>
            </div>
          </div>


        </ion-card>
      </ion-col>

      <ion-col size="12" size-md="6" size-lg="4">
        <ion-card *ngFor="let producto of productoData"
          style="box-shadow: none;border: none; height: min-content; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <ion-text class="tituloDescripcion"><u>{{producto.title}}</u></ion-text>
          <!-- <swiper [navigation]="true" [virtual]="true" class="mySwiper2">
                  <ng-template style=" border: .5px solid rgb(255, 0, 0,1)" *ngFor="let item of slider" swiperSlide><img  src="{{item.slider}}"></ng-template>
              </swiper> -->
          <ion-card-content>
            <ion-card-title class="ion-text-center">Descripción del Producto</ion-card-title>
            <br>
            <div [innerHTML]="descripcionsilla"></div>
          </ion-card-content>
        </ion-card>
        <!-- <ion-card-title *ngIf="relacionados.length>0" class="ion-text-center">Productos de Categorias Relacionadas</ion-card-title>
      <div class="swiper-container" id="swiperEscritorio" *ngIf="relacionados.length>0">
        <swiper-container space-between="10" slides-per-view="5" free-mode="true" watch-slides-progress="true" class="mySwiper" >
          <ng-container *ngFor="let item of relacionados; index as i">
            <swiper-slide>
              <a (click)="navigateToDescripcionProducto(item.linki,item.id_product,item.link_prod,item.paquete,item.sku)" style="color: #15dbf5;"><img src="{{ item.image_link }}"  /> {{item.title}}</a>
              
            </swiper-slide>
          </ng-container>
        </swiper-container>
       </div>       -->
      </ion-col>

      <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
        <ion-card *ngFor="let producto of productoData"
          style="box-shadow: none; border:none; overflow: unset; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <ion-card-content>
            <ion-grid>
              <ion-row>

                @if(cargandoPrecio == 0){
                <ion-col>
                  <ion-list>
                    <ion-item>
                      <ion-label>
                        <h3>
                          <!-- <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text> -->
                          <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
                        </h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
                }
                @else if(!descuentillo && cargandoPrecio == 1 && precioDyn >0){
                <ion-col>
                  <ion-text class="precio">${{precioDyn | number: '1.2-2'}}</ion-text>
                  <span
                    style="font-size: 14px;color: rgb(29, 171, 209);font-family: 'RobotoCondensed-Light';text-transform: uppercase;">
                    mxn </span>
                </ion-col>
                }
                @else if(!descuentillo && cargandoPrecio == 1 && precioDyn ==0){
                <ion-col>
                  <ion-text class="precio" style="color: red;">{{Disponibilidad}}</ion-text>
                </ion-col>
                }
                @else if(descuentillo && cargandoPrecio == 1 && precioDyn >0){
                <ion-col>
                  <ion-text class="textDesc">{{pruebaDescuento*100 | number: '1.2-2'}}% de descuento</ion-text><br>
                  <del class="price-strikethrough">${{precioDyn | number: '1.2-2'}} </del>
                  <ion-text class="text">${{precioDyn*(1 - pruebaDescuento) | number: '1.2-2'}}mxn </ion-text>
                </ion-col>
                }
                @else if(descuentillo && cargandoPrecio == 1 && precioDyn ==0){
                <ion-col>
                  <ion-text class="precio" style="color: red;">{{Disponibilidad}}</ion-text>
                </ion-col>
                }

              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-text style="font-size: 12px; font-family: 'RobotoCondensed-Light';color: rgb(51,51,51);">IVA
                    incluido</ion-text>
                </ion-col>
              </ion-row>

              <div
                style="border-top: 1px solid #eaeaea;border-bottom: 1px solid #eaeaea; color: #333333; padding-top: 12px;padding-bottom: 12px;font-size: 18px;">
                <div>
                  <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                    <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.2em 0.5em;">Código</dt>
                    <dt id="referencia"
                      style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; color: #111; font-size: .9em; padding: 0.2em 0.5em;">
                      {{ producto.sku }}</dt>
                  </dl>
                  <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                    <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.2em 0.5em;">
                      Color
                    </dt>
                    <dt
                      style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; color: #111; font-size: .9em; position: relative;"
                      class="line-tb">
                      {{colorSeleccionado}}

                      <div class="r-color" style="background-color: {{varColorSelected}};"></div>
                      <div class="r-color" *ngIf="varColorSelected"
                        [style.background-image]="'url(' + varColorSelected + ')'"></div>
                    </dt>
                  </dl>
                </div>


                @if(precioDyn > 300){
                <kueskipay-widget data-kpay-color-scheme="black" data-kpay-widget-font-size="16">
                </kueskipay-widget>
                }
              </div>

              <ion-row>
                <!-- <ion-col>
                 <ion-text class="text" >SKU:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text">
                    
                      <span id="referencia">{{ producto.sku  }}
                      </span>
                  </p>
                </ion-col> -->
              </ion-row>
              <ion-row id="existencia">
                <ion-col>
                  <ion-text class="text">Existencia:</ion-text>
                </ion-col>
                <ion-col>
                  <ion-col *ngIf=" cargandoInventario == 0">
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-col>

                  <ion-text class="text" *ngIf="existencias>0 && cargandoInventario == 1"
                    style="color: green;">{{muestroStock}}</ion-text>
                  <ion-text class="text" *ngIf="existencias<=0 && cargandoInventario == 1"
                    style="color: red;">{{muestroStock}}</ion-text>
                  <!-- <ion-text  class="text"  *ngIf="existencias<=0 && cargandoInventario == 1" style="color:red">{{muestroStock}}</ion-text>  -->
                </ion-col>
              </ion-row>
              <ion-row id="existenciaDesk">
                <div class="conten-exist">

                  <span class="lefti">Existencia:</span>
                  <ion-col *ngIf=" cargandoInventario == 0">
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                  <span class="rigti" *ngIf="existencias>0 && cargandoInventario == 1"
                    style="color:green">{{muestroStock}}</span>
                  <span class="rigti" *ngIf="existencias<=0 && cargandoInventario == 1"
                    style="color:red">{{muestroStock}}</span>
                </div>
              </ion-row>
              <!-- <ion-row>
                <ion-col>
                  <ion-text class="text" style="color:rgb(27, 172, 209);">Unidad de Venta:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text"><span id="unidadVenta">{{producto.unitId}}</span></p>
                </ion-col>
              </ion-row> -->
              <ion-row id="colorSeleccionado">
                <ion-col>
                  <ion-text class="text">Color Seleccionado:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text"><span>{{colorSeleccionado}}</span></p>
                </ion-col>
              </ion-row>
              <ion-row>

                <div *ngFor="let atributo of atributos" style="width: 100%;">
                  <ion-col>
                    <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                  </ion-col>
                  <ion-col>
                    <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop">
                      <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                        class="selector-color id_group_{{atributo.id_attribute_group}}">
                        <!-- <ion-button *ngIf="valor.color != ''" title="{{valor.name}}"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                            style="background-color: {{valor.color}}">{{valor.name}}</ion-button>
                          <ion-button *ngIf="valor.color == ''" title="{{valor.name}}"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                            style="background-image: url({{valor.image}});">{{valor.name}}</ion-button> -->
                        <!-- <div class="color-container"> -->
                        <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)"
                          style="background-color: {{valor.color}}">
                        <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.image)"
                          style="background-image: url({{valor.image}});">
                        <div class="color-name">{{ valor.name }}</div>
                        <!-- <div class="preview">
                              <div class="diva-color-p" style="background-color: {{valor.color}};"></div>
                              <span class="name-color-p">{{valor.name}}</span>
                            </div> -->
                        <!-- </div> -->
                      </div>
                    </div>

                    <!-- <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop-list">
                      <div class="conten-names-opts">
                        <button class="btn-list-opt" *ngFor="let valor of atributo.valores"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)">{{valor.name}}</button>
                      </div>
                    </div> -->

                    <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos"
                      (change)="cambiaSku()" class="form-group {{atributo.id_attribute_group}}">
                      <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}
                      </option>
                    </select>
                  </ion-col>
                </div>
              </ion-row>
              <ion-row>
                <ion-col>
                  <!-- <ion-text class="text">Cantidad:</ion-text>    -->
                  <div>
                    <!-- <ion-row >
                      <ion-col>
                        <ion-label style="font-family: 'RobotoCondensed-Light'; font-weight: bold;">
                          CANTIDAD
                        </ion-label>
                      </ion-col>
                    </ion-row>                  
                    <ion-row>
                      <ion-col>
                        <ion-label>
                          <ion-item>
                            <ion-input style="float: left;" type="number" [(ngModel)]="cantidad" name="cantidad"></ion-input>
                          </ion-item>
                        </ion-label>
                      </ion-col>
                    </ion-row> -->
                  </div>
                </ion-col>

                <!-- <div>
                  <ion-row>
                    <ion-col>
                      <ion-label style="font-family: 'RobotoCondensed-Light';">
                        CANTIDAD
                      </ion-label>
                    </ion-col>
                  </ion-row>                  
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        <ion-item>
                          <ion-input style="float: left;" type="number" [(ngModel)]="cantidad" name="cantidad"></ion-input>
                        </ion-item>
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </div> -->
                <ion-col>
                  <!-- <ion-item>
                    <ion-label style="font-family: 'RobotoCondensed-Light';">CANTIDAD</ion-label>
                  </ion-item>
                  <ion-item>
                    <ion-input type="number" [(ngModel)]="cantidad" name="cantidad"></ion-input>
                  </ion-item> -->



                  <!-- <ion-item>
                    <ion-label>
                      <p>Cantidad: <input style="width: 30%;" type="number"  [value]="quantity"  (change)="validateInput($event,i)"> </p> -->

                  <!-- <ion-input label="cantidadInput" type="number" placeholder="1"></ion-input> -->
                  <!-- <input type="number" placeholder="1"></input> -->
                  <!-- </ion-label>
                  </ion-item> -->
                </ion-col>

                <!-- <ion-col style="display: flex;align-items: center; justify-content:start ;">
                  <ion-icon name="remove-circle" style="width: 25px;height: 30px;color: #6cdff9;padding-right: 10px;" (click)="decrement()"></ion-icon>
                    <ion-label style="font-size:15px;color: black;font-weight: bold;">{{cantidad}}</ion-label> 
                     <ion-icon name="add-circle" style="width: 25px;height: 30px;color:#6cdff9;padding-left: 10px;" (click)="increment()"></ion-icon> 
                  <ion-input class="text" type="number" min="1" onkeyup="if(this.value<0){this.value= this.value * -1}" [(ngModel)]="cantidad" max="{{producto.existencia}}"></ion-input>
                </ion-col> -->
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-label style="font-family: 'RobotoCondensed-Light'; font-weight: bold;">
                    CANTIDAD
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-label>
                    <ion-item>

                      <!-- <input type="number" min="1" [(ngModel)]="cantidad" name="cantidad" class="inp-manage"> -->
                      <div class="number-input">
                        <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                          (click)="bajame()"></button>
                        <input class="quantity bg-light" min="1" value="1" [(ngModel)]="cantidad" name="cantidad"
                          type="number">
                        <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"
                          (click)="subeme()"></button>
                      </div>

                    </ion-item>
                  </ion-label>
                </ion-col>
              </ion-row>
              <br>
              <!-- <ion-button  (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</ion-button> -->
              <button
                style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
                *ngIf="existencias>0 && cargoprecio == true && precioDyn>0" id="addCarrito"
                (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</button>

              <div *ngIf="existencias==0" class="nohay-cont">
                <span class="textin">{{Agotado}}</span>
                <!-- <i class="fa-solid fa-face-sad-tear"></i> -->
              </div>
              <br>
              <button id="irCarrito" routerLink="/cart"
                style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
                *ngIf="cargoprecio == true && precioDyn>0">Ir
                a Carrito</button>
              <br>
              <button
                style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
                *ngIf="usuario!=null && cargoprecio == true && precioDyn>0" id="wishlist"
                (click)="agregarWishlist()">Agregar a Wishlist</button>
              <!-- <ion-button expand="full" shape="round" color="secondary"  routerLink="/cart"  >Ir a Carrito</ion-button>
              <ion-button expand="full" shape="round" color="secondary"  (click)="agregarWishlist()" >Agregar a Wishlist</ion-button> -->
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>



    <ion-card *ngFor="let producto of productoData" style="-webkit-margin-start: 0px; margin-inline-start: 0px;">
      <!-- <ion-text class="">{{producto.title}}</ion-text> -->



      <!-- <img src="{{producto.image_link}}"> -->
      <!-- <ion-slides pager="true">
      <ion-slide *ngFor="let s of slider;let i = index;">
        <img style="width:100% ; max-height:500px;" src="{{s.slider}}">
       
      </ion-slide>
    </ion-slides> -->



      <!-- <swiper [navigation]="true" [virtual]="true" class="mySwiper2">
              <ng-template style=" border: .5px solid rgb(255, 0, 0,1)" *ngFor="let item of slider" swiperSlide><img  src="{{item.slider}}"></ng-template>
          </swiper>
           -->

      <ion-card-content>
        <ion-grid>
          <!-- <ion-row>
            <ion-col>
              <ion-text class="text" >Precio:</ion-text>
            </ion-col>
            <ion-col *ngIf="!descuentillo">
              <ion-text class="text" >${{precioDyn | number: '1.2-2'}} mxn </ion-text>
              </ion-col>
            <ion-col *ngIf="descuentillo">
             
              <ion-text class="text" >${{precioDyn*descuento | number: '1.2-2'}} <del>${{precioDyn | number: '1.2-2'}}  mxn </del></ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
             <ion-text class="text" >SKU:</ion-text>
            </ion-col>
            <ion-col>
              <p class="text">
                
                  <span id="referencia">{{ producto.sku  }}
                  </span>
              </p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text class="text">Existencia:</ion-text>
            </ion-col>
            <ion-col>
            <ion-text class="text">{{existencias}}</ion-text> 
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text class="text" style="color:rgb(27, 172, 209);">Unidad de Venta:</ion-text>
            </ion-col>
            <ion-col>
              <p class="text"><span id="unidadVenta">{{producto.unitId}}</span></p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text class="text">Color Seleccionado:</ion-text>
            </ion-col>
            <ion-col>
              <p class="text"><span >{{colorSeleccionado}}</span></p>
            </ion-col>
          </ion-row> -->
          <ion-row>
            <!-- 
                <div *ngFor="let atributo of atributos">
                  <ion-col >
                  <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                </ion-col>
                <ion-col >
                  <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop">
                    <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                      class="selector-color id_group_{{atributo.id_attribute_group}}" >




                        <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                        (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                        style="background-color: {{valor.color}}" >
                        <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                        (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                        style="background-image: url({{valor.image}});" >
        
                    </div>
                  </div>
                
                
                  <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos" (change)="cambiaSku()"
                    class="form-group {{atributo.id_attribute_group}}" >
                    <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}</option>
                  </select>
                </ion-col>
                </div> -->
          </ion-row>
          <ion-row>
            <!-- <ion-col>
              <ion-text class="text">Cantidad:</ion-text>   
            </ion-col> -->
            <ion-col style="display: flex;align-items: center; justify-content:start ;">



              <!-- <ion-icon name="remove-circle" style="width: 25px;height: 30px;color: #6cdff9;padding-right: 10px;" (click)="decrement()"></ion-icon> -->
              <!-- <ion-label style="font-size:15px;color: black;font-weight: bold;">{{cantidad}}</ion-label> -->
              <!-- <ion-icon name="add-circle" style="width: 25px;height: 30px;color:#6cdff9;padding-left: 10px;" (click)="increment()"></ion-icon> -->
              <!-- <ion-input class="text" type="number" min="1" onkeyup="if(this.value<0){this.value= this.value * -1}" [(ngModel)]="cantidad" max="{{producto.existencia}}"></ion-input> -->



            </ion-col>
          </ion-row>
          <!-- <ion-button id="addCarrito" expand="full" shape="round" color="secondary"  (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</ion-button>
          <ion-button id="irCarrito" expand="full" shape="round" color="secondary"  routerLink="/cart"  >Ir a Carrito</ion-button>
          <ion-button id="wishlist" expand="full" shape="round" color="secondary"  (click)="agregarWishlist()" >Agregar a Wishlist</ion-button> -->
        </ion-grid>
      </ion-card-content>

      <!-- <ion-card-content>
        <ion-card-title class="ion-text-center">Descripción del Producto</ion-card-title>
       <br>
        <div [innerHTML]="descripcionsilla"></div>
      </ion-card-content> -->

      <ion-card-content id="caracteristicas">
        <ion-card-title style="font-weight: bolder;text-align: center;">Características de Producto</ion-card-title>
        <br>
        <div [innerHTML]="contenidoHTML" class="cont-car"></div>
        <br>
      </ion-card-content>

      <!-- SLIDER PROD RELAC -->


      <div class="hidden-md-down">
        <ion-card-content id="caracteristicas">
          <div class="cont-car" id="relacionados">


            <ion-card-title *ngIf="prodRelac?.length > 0" class="ion-text-center">Productos
              Relacionados</ion-card-title>
            <div class="swiper-container" style="--swiper-navigation-color: #080808;--swiper-navigation-size: 70px"
              id="swiperEscritorio" *ngIf="prodRelac?.length > 0">
              <swiper-container space-between="10" navigation="true" slides-per-view="5" free-mode="true"
                watch-slides-progress="true" class="mySwiper">
                <ng-container *ngFor="let item of prodRelac; index as i">
                  <swiper-slide>
                    <a (click)="navigateToDescripcionProducto(item.cat_rewrite,item.id_product,item.link_rewrite,item.paquete,item.sku)"
                      style="color: #15dbf5;"><img src="{{ item.image_link }}" /> {{item.title}}</a>

                  </swiper-slide>
                </ng-container>
              </swiper-container>
            </div>
          </div>


        </ion-card-content>
      </div>

      <!-- SLIDER PROD RELAC -->












      <div class="hidden-sm-up">
        <ion-card-content id="caracteristicas">
          <div class="cont-car" id="relacionados">


            <ion-card-title *ngIf="relacionados.length>0" class="ion-text-center">Productos de Categorias
              Relacionadas</ion-card-title>
            <div class="swiper-container" style="--swiper-navigation-color: #080808;--swiper-navigation-size: 70px"
              id="swiperEscritorio" *ngIf="relacionados.length>0">
              <swiper-container space-between="10" navigation="true" slides-per-view="2" free-mode="true"
                watch-slides-progress="true" class="mySwiper">
                <ng-container *ngFor="let item of relacionados; index as i">
                  <swiper-slide>
                    <a (click)="navigateToDescripcionProducto(item.linki,item.id_product,item.link_prod,item.paquete,item.sku)"
                      style="color: #15dbf5;"><img src="{{ item.image_link }}" /> {{item.title}}</a>

                  </swiper-slide>
                </ng-container>
              </swiper-container>
            </div>
          </div>

        </ion-card-content>
      </div>

      <div class="hidden-md-down">
        <ion-card-content id="caracteristicas">
          <div class="cont-car" id="relacionados">


            <ion-card-title *ngIf="relacionados.length>0" class="ion-text-center">Productos de Categorias
              Relacionadas</ion-card-title>
            <div class="swiper-container" style="--swiper-navigation-color: #080808;--swiper-navigation-size: 70px"
              id="swiperEscritorio" *ngIf="relacionados.length>0">
              <swiper-container space-between="10" navigation="true" slides-per-view="5" free-mode="true"
                watch-slides-progress="true" class="mySwiper">
                <ng-container *ngFor="let item of relacionados; index as i">
                  <swiper-slide>
                    <a (click)="navigateToDescripcionProducto(item.linki,item.id_product,item.link_prod,item.paquete,item.sku)"
                      style="color: #15dbf5;"><img src="{{ item.image_link }}" /> {{item.title}}</a>

                  </swiper-slide>
                </ng-container>
              </swiper-container>
            </div>
          </div>


          <div class="cont-car" id="reseña">
            <ion-card-title class="ion-text-center reviews-title">Reseñas del producto</ion-card-title>
            <div class="space">
              <div class="separator"></div>
            </div>
            <div class="swiper-container" style="--swiper-navigation-color: #080808;--swiper-navigation-size: 70px"
              id="swiperEscritorio">
              <swiper-container space-between="10" navigation="true" slides-per-view="5" free-mode="true"
                watch-slides-progress="true" class="mySwiper">
                <ng-container>
                  <swiper-slide *ngFor="let producto of reviews">
                    <div class="comment-card">
                      <div class="comment-header">
                        <h3 class="comment-title">{{ producto.title }}</h3>
                        <div class="comment-stars">
                          <img
                            src="{{ 'https://new.lideart.com.mx/modules/lgcomments/views/img/stars/plain/bluelight/' + producto.stars + 'stars.png' }}">
                        </div>
                      </div>

                      <div class="comment-content">
                        <p>{{ producto.comment }}</p>
                      </div>

                      <div class="comment-footer">
                        <span class="comment-nick">- {{ producto.nick }}</span>
                        <span class="comment-date">{{ producto.date }}</span>
                      </div>
                    </div>
                  </swiper-slide>
                </ng-container>
              </swiper-container>
              <button id="open-modal-desk" class="custom-button" (click)="openModalDesk()">¡Registra tu
                comentario!</button>
            </div>
            <div id="modal" class="modal-overlay">
              <div class="modal-content">
                <div class="modal-header">
                  <button class="close-button" (click)="closeModal()">X</button>
                </div>

                <div class="modal-body">
                  <h3>¡Déjanos tu opinión!</h3>
                  <select class="selict" name="id_state" [(ngModel)]="estrellas" required>
                    <option value="0" selected>Selecciona la calificación</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>

                  <input class="inps" type="text" name="nombre" [(ngModel)]="nombre" placeholder="Nombre" required>
                  <input class="inps" type="text" name="titulo" [(ngModel)]="titulo" placeholder="Título" required>
                  <textarea class="inps" rows="5" cols="50" name="comentario" [(ngModel)]="comentario"
                    placeholder="Comentario" required></textarea>

                  <button type="button" class="custom-button" (click)="registraReviewProducto()">Enviar
                    comentario</button>
                  <div *ngIf="mostrarError" class="error-message">
                    <p>Por favor, completa todos los campos, son obligatorios.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style="display: flex; flex-direction:row; align-items: center; justify-content: center; margin-top: 10px;">
            <p style="margin-left: 10px;" *ngFor="let item of todoslossku">{{item.reference}}</p>
          </div>
        </ion-card-content>
      </div>



    </ion-card>



  </div>

  <!-- <div class="prods-related-cont">
    <h1 class="taitl">Productos Relacionados</h1>

    <swiper [navigation]="true" [autoHeight]="true" id="related" [breakpoints]="{'1500':{slidesPerView: 6, spaceBetween: 0},'1200':{slidesPerView: 5, spaceBetween: 0},'992':{slidesPerView: 5, spaceBetween: 0},'768':{slidesPerView: 3, spaceBetween: 0},'480':{slidesPerView: 2, spaceBetween: 0}}">
      <ng-template *ngFor="let item of relacionados" swiperSlide>
        <div class="all-cont">
          <div class="prod-cont">

            <a href="" class="foto-cont" [routerLink]="['/descripcion-producto',item.id_product]">
              <img src="{{item.image_link}}" alt="" id="uno">
              <img src="{{item.image_link}}" alt="" id="dos">
            </a>
            <div class="rate-cont">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <a class="prod-name" [routerLink]="['/descripcion-producto',item.id_product]" style="text-transform: capitalize; max-height: 38px; overflow: hidden;">
              {{item.title}}
            </a>
            <div class="price-cont" style="display: none;">
              <span class="now">$5,639.39</span>
              <span class="bef">$8,288.31</span>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div> -->
  <!-- 
  <div class="loader" *ngIf="cargando">
    <img src="/assets/img/loadersillo.gif">
  </div> -->

</div>

<div style="min-height: 15em;" *ngIf="cargandoProductoInfo ==1 && estoyactivo ==0">

  <div
    style="display: flex;justify-content: center; align-items: center;align-content: center;margin-top: 20px;margin-bottom: 20px;">
    <img src="/assets/img/nocarrito.png">
  </div>
  <div
    style="display: flex;justify-content: center; align-items: center;align-content: center;margin-top: 20px;margin-bottom: 20px;">
    <h1>Articulo no disponible</h1>
  </div>

  <div
    style="display: flex;justify-content: center; align-items: center;align-content: center;margin-top: 20px;margin-bottom: 20px;">
    <button routerLink="/home"
      style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;">Volver
      a Inicio</button>

  </div>


</div>