
  <div class="cont">

    <ion-card>
    
      <img src="assets/img/lideart_logo.png" alt="Logo avance">
    
      <!--<div class="where">
        <a href=""><span>INICIAR SESIÓN</span></a>
        <a id="regis" href="">REGISTRARSE</a>
      </div>-->
    
      <form>
    
        <ion-item>
          <input class="inps" type="text" name="correito" [(ngModel)]="correito"  placeholder="Ingresa Correo"> 
        </ion-item>
              
        <ion-button expand="full" (click)="sendRequest()">Recuperar cuenta</ion-button>
      
      </form>
    
      <div class="forgot">
        <a id="closeModal" (click)="dismiss()">Cerrar</a>
      </div>
          
    </ion-card>
    
  </div>
