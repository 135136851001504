import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import Fuse from 'fuse.js';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-categoria-por-paquetes',
  templateUrl: './categoria-por-paquetes.component.html',
  standalone: true,
  imports: [MatPaginatorModule,CommonModule,RouterModule,IonicModule],
  styleUrls: ['./categoria-por-paquetes.component.scss'],
  providers:[CarritoServiceService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CategoriaPorPaquetesComponent  implements OnInit {

  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  public totalItems: number = 0;
  id_parent:any;
  url = "https://new.lideart.com.mx/img/categoria/"
  categorias: any;
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  public isDesk : boolean = false;
  public isCel : boolean = false;

  constructor(
    private shoppingCart: CarritoServiceService,
    private location: Location,
    private titleService : Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Paquetes");
    if (isPlatformBrowser(this.platformId)) {
      if ( window.innerWidth > 992 ) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
    }
    this.shoppingCart.cartItems.subscribe(d=>{
      this.totalItems = d.length;
    });
  }

  goBack() {
    this.location.back();
  }




  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);
      
      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

}
