import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetCarritosResponse } from '../types/checkoutService';
import { PAY_Kueski_createPaymentResponse } from '../types/PAYMENTS/Kueski';
import { PAY_MercadoPagoPreferenceResponse } from '../types/PAYMENTS/MercadoPago';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

export interface ResumenCompra {
  id_compra: string
  correo: string
  nombre: string
  pais: string
  estado: string
  ciudad: string
  colonia: string
  cp: string
  direccion: string
  fecha: string
  hora: string
  metodo_pago: string
  tipo_entrega: string
  paqueteria: string
  iva: string
  subtotal: string
  total: string
  cotizacion: string
  ov: string
  arregloEtiquetas: string
  sitio: string
  estatus: string
  fechita: string
  referencia_oxxo: string
  mesa_regalos: string
  estadoPedido: string
  arrayCarrito: string
  ambiente: 'Produccion' | 'Pruebas' | 'Test' | ''
  status: 'approved' | 'canceled' | 'denied' | 'error' | 'inProcess'
  gateway: string
  info: null | PAY_MercadoPagoPreferenceResponse | PAY_Kueski_createPaymentResponse //TODO: Falta el tipado de kueski
}

@Injectable({
  providedIn: 'root'
})


export class CheckoutServiceService {
  apiUrl: string = 'https://lideart.aytcloud.com/inaxapiv2/api/venta';
  url: string = this.apiUrl + '';

  constructor(public http: HttpClient) { }

  getDireccion(correo: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getDireccion.php?email=' + correo);

  }

  getCartData(correo: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getCartData.php?email=' + correo);

  }

  getDireccionMesa(correo: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getDireccionMesa.php?email=' + correo);

  }

  getResumen(correo: string) {
    return this.http.get<ResumenCompra[]>('https://new.lideart.com.mx/Automatizacion/apis/getVentaResumen.php?email=' + correo);

  }

  getCarritosItems(correo: string) {
    return this.http.get<GetCarritosResponse[]>('https://new.lideart.com.mx/Automatizacion/apis/getCarritosItems.php?email=' + correo);

  }

  actualizaNuevoCarrito(correo: string) {
    return this.http.get<GetCarritosResponse[]>('https://new.lideart.com.mx/Automatizacion/apis/actualizaNuevoCarrito.php?email=' + correo);

  }

  registraCarrito(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraCarrito.php', datos);
  }

  registraCarrito2(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraCarrito2.php', datos);
  }

  webpay(datosPay: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/webpay.php', datosPay);
  }

  checaPago(carrito: any) {
    return this.http.get<Response>('https://new.avanceytec.com.mx/Automatizacion/apis/checaPagoWebPayLideapp.php?carrito=' + carrito);
  }


  addCotizacionDynamics(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://inax2.aytcloud.com/inaxapiv2/api/ecommerce/addCotizacionDynamics", cotizacion, httpOption).pipe(catchError(this.handleError));
  }

  generarEtiquetas(datosCorreo: any) {
    return this.http.post('https://cc.paquetexpress.com.mx/RadRestFul/api/rad/v1/guia ', datosCorreo);
  }

  mandaMensaje(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://inax2.aytcloud.com/inaxLideartExpoConsAPI/api/email/contactoLideart", cotizacion);
  }

  mandacorreo(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://inax2.aytcloud.com/inaxapilideart/api/email/emailConfirmacionOV", cotizacion);
  }




  liberaAlmacen(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/venta/liberarAlmacen", cotizacion);
  }

  aplicarPago(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/venta/aplicarPago", cotizacion);
  }


  handleError(error: { message: any; }) {
    return throwError(error.message || "Server Error");
  }

  convertirOrdenVenta(parametros: any): Observable<Response> {
    return this.http.post<Response>("https://inax2.aytcloud.com/inaxLideartExpoConsAPI/api/venta/convertirOrdenVenta", parametros, httpOption).pipe(catchError(this.handleError));
  }

  guarda_venta(datosCorreo: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/guardaVenta.php', datosCorreo);
  }

  guardaObjetoCot(objetoCot: any = [], email: string) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/registraObjCot.php', { objetoCot, email });
  }

  kueskiPayTemp(data: { costo: number, ov: string }) {
    return this.http.post<PaymentResponse>("https://payments.lideart.com.mx/payment", data)
  }

  clienteSubdistribuidor(customerID: string) {
    return this.http.get('https://new.lideart.com.mx/Automatizacion/apis/clienteSubdistribuidor.php?customerID=' + customerID);
  }
}

export interface PaymentResponse {
  status: "success" | "fail"
  data: Data
}


interface Data {
  payment_id: string
  order_id: string
  name: string
  callback_url: string
}

