<section class="categoriaDesk" *ngIf="isDesk">
  <div class="contDesk">
    <div class="title-cont">
      <h1>Paquetes por marca:</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo-paquetes','494','paquetes-cricut']">
        <div class="contImg">
          <img src="{{url}}/494/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','493','paquetes-silhouette']">
        <div class="contImg">
          <img src="{{url}}/493/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','495','paquetes-luminos']">
        <div class="contImg">
          <img src="{{url}}/495/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','496','paquetes-sawgrass']">
        <div class="contImg">
          <img src="{{url}}/496/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','497','paquetes-we-r-memory-keepers']">
        <div class="contImg">
          <img src="{{url}}/497/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','498','paquetes-janome']">
        <div class="contImg">
          <img src="{{url}}/498/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','508','paquetes-heidi-swapp']">
        <div class="contImg">
          <img src="{{url}}/508/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','499','paquetes-varias-marcas']">
        <div class="contImg">
          <img src="{{url}}/499/1.png" alt="">
        </div>     
      </a>
    </div>
    <div class="title-cont">
      <h1>Paquetes por tipo de producto:</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo-paquetes','500','paquetes-plotters-de-corte']">
        <div class="contImg">
          <img src="{{url}}/500/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','502','paquetes-laminadora']">
        <div class="contImg">
          <img src="{{url}}/502/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','504','paquetes-estampado-textil']">
        <div class="contImg">
          <img src="{{url}}/504/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','505','paquetes-sublimacion']">
        <div class="contImg">
          <img src="{{url}}/505/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','503','paquetes-decoracion-foil']">
        <div class="contImg">
          <img src="{{url}}/503/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','506','paquetes-scrapbook']">
        <div class="contImg">
          <img src="{{url}}/506/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','501','paquetes-costura']">
        <div class="contImg">
          <img src="{{url}}/501/1.png" alt="">
        </div>     
      </a>
      <a class="cardin" [routerLink]="['/catalogo-paquetes','507','paquetes-otros']">
        <div class="contImg">
          <img src="{{url}}/507/1.png" alt="">
        </div>     
      </a>
    </div>
  </div>
</section>

<div style="" *ngIf="isCel">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <h1 style="color:#5dc0e0!important;font-size: 1.3em; font-family: 'RobotoCondensed-Light'; text-align: center;">Paquetes por marca</h1>
  <ion-grid class="display-grid">
  <ion-row >
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle" >
      <div class="cardContainer" >
        <button block [routerLink]="['/catalogo-paquetes','494','paquetes-cricut']">
          
          <img src="{{url}}/494/1.png" width="250px" height="200px"  
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','493','paquetes-silhouette']">
          <img src="{{url}}/493/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','495','paquetes-luminos']">
          <img src="{{url}}/495/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','496','paquetes-sawgrass']">
          <img src="{{url}}/496/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','497','paquetes-we-r-memory-keepers']">
          <img src="{{url}}/497/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','498','paquetes-janome']">
          <img src="{{url}}/498/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','508','paquetes-heidi-swapp']">
          <img src="{{url}}/508/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3"  class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','499','paquetes-varias-marcas']">
          <img src="{{url}}/499/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<h1 style="color:#5dc0e0!important;font-size: 1.3em; font-family: 'RobotoCondensed-Light'; text-align: center;">Paquetes por tipo de producto</h1>
<ion-grid>
  <ion-row>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','500','paquetes-plotters-de-corte']">
          <img src="{{url}}/500/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','502','paquetes-laminadora']">
          <img src="{{url}}/502/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','504','paquetes-estampado-textil']">
          <img src="{{url}}/504/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','505','paquetes-sublimacion']">
          <img src="{{url}}/505/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','503','paquetes-decoracion-foil']">
          <img src="{{url}}/503/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','506','paquetes-scrapbook']">
          <img src="{{url}}/506/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','501','paquetes-costura']">
          <img src="{{url}}/501/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="elementSyle">
      <div class="cardContainer">
        <button block [routerLink]="['/catalogo-paquetes','507','paquetes-otros']">
          <img src="{{url}}/507/1.png" width="250px" height="200px"
           />
        </button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
</div>
