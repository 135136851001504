<section class="resumenVentaDesk" style=" margin-top: 100px;">
  <div class="contenido-cont">
    <h1 class="ov">
      Orden de Venta: {{OV}}
    </h1>
    <span class="txt-r">
      Resumen de Productos:
    </span>
    <div class="pp">
      <span class="qe">Estado del pedido:</span>
      <span class="result">{{ pedidos.nameEstado }}</span>
    </div>
    <div class="compra-cont" *ngFor="let item of venta; let i = index">
      <div class="line">
        <span class="qe">Nombre:</span>
        <span class="result">{{item.nombre}}</span>
      </div>
      <div class="line">
        <span class="qe">Código:</span>
        <span class="result">{{item.id}}</span>
      </div>
      <div class="line">
        <span class="qe">Cantidad:</span>
        <span class="result">{{item.orderedSalesQuantity}}</span>
      </div>
      <div class="line">
        <span class="qe">Precio:</span>
        <span class="result">{{item.precioUnitarioIVA}}</span>
      </div>
    </div>
  </div>
</section>

<div class="desaparece-desk">
  <h1 class="ion-text-center">Orden de Venta: {{OV}} </h1>
  <hr>
  <h4 class="ion-text-center">Resumen de Productos: </h4>
  <ion-card *ngFor="let item of venta; let i = index" style="height: auto;">
    <ion-card-title class="ion-text-center"></ion-card-title>
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col><ion-label>Nombre: </ion-label></ion-col>
          <ion-col><ion-label>{{item.nombre}}</ion-label></ion-col>
        </ion-row>
        <ion-row>
          <ion-col><ion-label>Código: </ion-label></ion-col>
          <ion-col><ion-label>{{item.id}}</ion-label></ion-col>
        </ion-row>
        <ion-row>
          <ion-col><ion-label>Cantidad:</ion-label></ion-col>
          <ion-col> <ion-label> {{item.orderedSalesQuantity}}</ion-label></ion-col>
        </ion-row>
        <ion-row>
          <ion-col><ion-label>Precio: </ion-label></ion-col>
          <ion-col> <ion-label>${{item.precioUnitarioIVA }}</ion-label></ion-col>
        </ion-row>
        <ion-row>
          <ion-col><ion-label>Estado del pedido: </ion-label></ion-col>
          <ion-col> <ion-label>${{pedidos.nameEstado}}</ion-label></ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>

  </ion-card>
</div>