import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductoCat } from '../types/getCategorias';
import { Paquete } from '../types/getDescripcionPaquete';


const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root'
})
export class ApiProductosService {

  url: string = "https://new.lideart.com.mx/Automatizacion/listaJson.php"
  private paramSource = new BehaviorSubject(null);
  sharedParam = this.paramSource.asObservable();

  constructor(public http: HttpClient) { }

  /*getProductos() {
    return this.http.get<Response>(this.url);
  }*/
  getSpecificProduct(id: number) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/listaJson2.php?id=" + id);
  }



  getCategorias(id_cat: any) {
    // return this.http.get<ProductoCat[]>("https://new.lideart.com.mx/Automatizacion/apis/consultaCategorias.php?id_cat=" + id_cat);
    return this.http.get<ProductoCat[]>("https://new.lideart.com.mx/Automatizacion/apis/consultaCategoriasPruebas.php?id_cat=" + id_cat);
  }

  getRelacionados(id_cat: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/productosRelacionados.php?id_cat=" + id_cat);
  }

  getDescripciones(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/consultaDescripciones.php?id_product=" + id_product);
  }

  getProdRelac(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/getProdRelac.php?id_product=" + id_product);
  }


  getCategoriaItem(id_cat: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/getCategoriaItem.php?email=" + id_cat);
  }



  checaCupon(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaCupon.php', datos);
  }

  canjeaLidepuntos(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/canjeaLidepuntos.php', datos);
  }

  insertaLosPuntos(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/insertaLosPuntos.php', datos);
  }

  borraCupon(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/cancelaCupon.php', datos);
  }


  borraCaneLidepuntos(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/borraCaneLidepuntos.php', datos);
  }

  getDescripcionProducto(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/consultaArticulo.php?id_product=" + id_product);
  }


  getDescripcionProductoRelacionado(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/consultaArticuloRelacionado.php?id_product=" + id_product);
  }

  BorrarProcRelac(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/BorrarProcRelac.php?id_product=" + id_product);
  }

  registraProductoRelacionado(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraProductoRelacionado.php', datos);
  }


  actualizaOrdenProdRelac(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/actualizaOrdenProdRelac.php', datos);
  }

  insertaMenuNuevo(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/insertaMenuNuevo.php', datos);
  }

  EditarMenuBackoffice(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/EditarMenuBackoffice.php', datos);
  }

  BorrarMenuBackoffice(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/BorrarMenuBackoffice.php', datos);
  }









  checaMenuPromociones() {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/checaMenuPromociones.php");
  }



  getSubcategoriaPorProducto(id_padre: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/consultaSubCategorias.php?id_parent=" + id_padre);
  }
  getSubcategoriaPorProducto2() {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/ejemplos.php");
  }

  getDescripcionPaquete(id_product: any) {
    return this.http.get<Paquete[]>("https://new.lideart.com.mx/Automatizacion/apis/consultaArticuloPaquete.php?id_product=" + id_product);
  }

  getDatosPedido(id_customer: any) {
    return this.http.get("https://new.lideart.com.mx/Automatizacion/apis/consultaPedidos.php?id_customer=" + id_customer, { responseType: 'text' });
  }

  getSlaidaHome() {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/traesliderIMG.php", {
      // headers: {
      //   'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0, max-age = 0',
      //   'Pragma': 'no-cache',
      //   'Expires': '0'
      // }
    });
  }

  getSliderImgs(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/getImgSlider.php?id_product=" + id_product);
  }



  getSliderImgs2(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/getImgSlider2.php?id_product=" + id_product);
  }

  getDescripcionEditor(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/getDescripcionEditor.php?id_product=" + id_product);
  }



  getVideos(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/traeVideos.php?id_product=" + id_product);
  }

  getPrecioUnitario(objetoPost: any) {
    return this.http.post<any>('https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/Product/getPrecio', objetoPost);
  }

  getPrecioUnitario2(reference: string) {

    return this.http.get<any>(' https://inax2.aytcloud.com/inaxLideartExpoConsAPI/api/product/getExistenciasAlmacen/' + reference + '/CHIH');
  }



  getExistencias(reference: string, sitio: string) {
    return this.http.get<any>('https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/Product/getExistencias/' + reference + '/CHIH');
  }

  getDescuento(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaDescuento.php', datos);
  }

  getInfoProducto(id_product: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaProducto.php?id_product=' + id_product);
  }

  getInfoPaquetes(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/consultaInfoPaquetes.php?id_product=" + id_product);
  }

  checaPrecioGoogle(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/checaPrecioGoogle.php?id_product=" + id_product);
  }




  esPaquete(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/esPaquete.php?id_product=" + id_product);
  }

  getSliderImgsxColor(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/cambiaColor.php?id_product=" + id_product);
  }

  changeParam(param: any = []) {
    this.paramSource.next(param)
  }

  getDatosBuscador(id_product: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/recuperaDatosBuscador.php?id_product=" + id_product);
  }

  getDescuentoSub(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/descuentosSubdistribuidores.php', datos);
  }

  getCategoriaSub(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/consultaCategoriasSubdistribuidor.php', datos);
  }

  getReviewProducto(id_product: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/reviewsProducto.php?id_product=' + id_product);
  }

  registraReviewsProduct(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraReviewsProducto.php', datos);
  }
}
