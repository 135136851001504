import { CommonModule, ViewportScroller } from '@angular/common';
import { afterRender, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule, IonModal } from '@ionic/angular';
import { Usuario } from '../../Models/usuario';
import { LoginService } from '../../services/login.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reviews',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, IonicModule],
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  providers: [LoginService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReviewsComponent implements OnInit {

  @ViewChild(IonModal) modal!: IonModal;
  @ViewChild('modalDesk') modalDesk!: IonModal;

  loaded = false;
  reviews: any = [];
  reviewsOriginal: any = [];
  private elementosPorPagina: number = 10;
  private paginaActual: number = 0;
  URLestrellas: any;
  nombre: any;
  titulo: any;
  comentario: any
  estrellas = 0;
  usuario!: Usuario;
  datosUsuario: any = [];
  id_customer: any;
  mostrarAlerta: boolean = false;
  mostrarError: boolean = false;

  constructor(
    private loginService: LoginService,
    private titleService: Title,
    private viewportScroller: ViewportScroller,
  ) {

    afterRender(() => {
      this.loginService.usuario.subscribe((res: any) => {
        this.usuario = res;
      }

      );
    });

  }

  paginaSiguiente() {
    if ((this.paginaActual + 1) * this.elementosPorPagina < this.reviewsOriginal.length) {
      this.paginaActual++;
      const inicio = this.paginaActual * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      this.reviews = this.reviewsOriginal.slice(inicio, fin);
    }
  }

  paginaAnterior() {
    if (this.paginaActual > 0) {
      this.paginaActual--;
      const inicio = this.paginaActual * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      this.reviews = this.reviewsOriginal.slice(inicio, fin);
    }
  }

  ngOnInit() {
    this.titleService.setTitle("Reviews");
    this.scrollToTop();
    document.body.scrollTop = 0;
    this.loginService.getReviews().subscribe(res => {
      this.reviewsOriginal = res;
      this.reviews = this.reviewsOriginal.slice(0, 10);
      this.loaded = true;
      this.URLestrellas = "https://new.lideart.com.mx/modules/lgcomments/views/img/stars/plain/bluelight/" + this.reviews.stars + "stars.png"
      this.viewportScroller.scrollToPosition([0, 0]);
      document.body.scrollTop = 0;
      this.scrollToTop();
      //console.log(this.reviews);
    });
  }


  cancel2() {
    this.modal.dismiss(null, 'cancel');
  }

  cancel2desk() {
    this.modalDesk.dismiss(null, 'cancel');
  }

  checalo() {
    this.loginService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.datosUsuario = data;
      this.id_customer = this.datosUsuario.id_customer;

    })

  }

  registraReview() {
    if (!this.formularioValido()) {
      this.mostrarError = true;
      return;
    }
    var jsonCarrito = {
      "estrellas": this.estrellas, "nombre": this.nombre, "titulo": this.titulo, "comentario": this.comentario
      , "id_customer": this.id_customer
    };
    this.loginService.registraReviews(jsonCarrito).subscribe(data => {
      location.reload();
    })
  }

  closeAlerta() {
    this.mostrarAlerta = false;
  }

  openModalDesk() {
    const modal = document.getElementById('modal');
    if (modal) {
      modal.style.display = 'flex';
    }
  }

  closeModal() {
    const modal = document.getElementById('modal');
    if (modal) {
      modal.style.display = 'none';
    }
  }

  formularioValido(): boolean {
    return this.estrellas > 0 && this.nombre.trim() !== '' && this.titulo.trim() !== '' && this.comentario.trim() !== '';
  }

  private scrollToTop(): void {
    if (this.viewportScroller) {
      this.viewportScroller.scrollToPosition([0, 0]); // Método Angular
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Alternativa con animación
    }, 0);
  }
}
