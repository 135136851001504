import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ],
  selector: 'app-blogsito',
  templateUrl: './blogsito.component.html',
  styleUrls: ['./blogsito.component.scss'],
})
export class BlogsitoComponent  implements OnInit {
  blogArray: any = [];
  descricpcionsita: any;

  constructor(
    private blogService: ServiciowishlistService
  ) { }

  ngOnInit() {
    this.traerBlog();
  }

  traerBlog(){
    this.blogService.recuperaBlog().subscribe((data) => {
      this.blogArray = data;
      
    });
  }

}
