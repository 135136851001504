<!--<ion-header>
  <ion-toolbar>
    
  </ion-toolbar>
</ion-header>-->

<section class="loginDesk" style=" margin-top: 100px;" *ngIf="isDesk">
  <div class="contDesk">
    <ion-card class="cardi">
      <img src="assets/img/lideart_logo.png" alt="Logo avance">
      <div class="where">

        <a><span>INICIAR SESIÓN</span></a>
        <a (click)="modalReg()" id="regis">REGISTRARSE</a>
      </div>


      <p style="color:red">{{mensajeTemporal}}</p>

      <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
        <label class="lbl-login">Correo Electrónico</label>
        <ion-item>

          <ion-input appAutofill formControlName="email" type="email" name="email" autocomplete="email"
            [(ngModel)]="email" [ngModelOptions]="{standalone: true}" required email></ion-input>
        </ion-item>
        <label class="lbl-login">Contraseña</label>
        <ion-item style="position: relative;">

          <i class="fa-solid fa-eye btn-pass" (click)="togglePassword()" id="showPassword" #passDesk></i>
          <!-- <i class="fal fa-eye show-password" (click)="togglePassword()" id="showPassword"></i> -->
          <ion-input appAutofill formControlName="password" name="password" id="inputPassword" type="password"
            autocomplete="current-password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
            required></ion-input>

        </ion-item>
        <ion-button type="submit" expand="full">ENTRAR</ion-button>
      </form>
      <div class="forgot">
        <a (click)="modalRec()"> ¿ Olvidaste la contraseña ? </a>
      </div>
    </ion-card>
  </div>
</section>

<div class="login-cont-cel" *ngIf="isCel">

  <div class="cont">

    <ion-card>

      <img src="assets/img/lideart_logo.png" alt="Logo avance">

      <div class="where">
        <a><span>INICIAR SESIÓN</span></a>
        <a (click)="modalReg()" id="regis">REGISTRARSE</a>
      </div>
      <p style="color:red">{{mensajeTemporal}}</p>
      <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
        <label class="lbl-login">Correo Electrónico</label>
        <ion-item>

          <ion-input appAutofill formControlName="email" type="email" name="email" autocomplete="email"
            [(ngModel)]="email" required email></ion-input>
          <!-- <span class="error ion-padding" *ngIf="isSubmitted && errorControl.email.errors?.required">
            Email Obligatorio
          </span> -->
          <!-- <span class="error ion-padding" *ngIf="isSubmitted && errorControl.email.errors?.pattern">
            Ingresa patrón correcto de Email
          </span> -->
          <!-- <span class="correcto ion-padding" *ngIf="isSubmitted && !errorControl.email.errors?.required && !errorControl.email.errors?.pattern">
            Correcto
          </span> -->
        </ion-item>
        <label class="lbl-login">Contraseña</label>
        <ion-item>

          <ion-input appAutofill formControlName="password" id="inputPasswordCel" name="password" type="password"
            autocomplete="current-password" [(ngModel)]="password" required></ion-input>
          <i class="fa-solid fa-eye btn-pass" (click)="togglePasswordCel()" id="showPassword" #passCel></i>
          <!-- <span class="error ion-padding" *ngIf="isSubmitted && errorControl.password.errors?.required">
            Contraseña Obligatoria
          </span>
          <span class="error ion-padding" *ngIf="isSubmitted && errorControl.password.errors?.pattern">
            Ingresa patrón correcto de contraseña
          </span>
          <span class="correcto ion-padding" *ngIf="isSubmitted && !errorControl.password.errors?.required && !errorControl.password.errors?.pattern">
            Correcto
          </span> -->
        </ion-item>

        <ion-button type="submit" expand="full">ENTRAR</ion-button>

      </form>

      <!--<div class="forgot">
        <a (click)="handleHead()"> Entrar como invitado </a>
      </div>-->

      <div class="forgot">
        <a (click)="modalRec()"> ¿ Olvidaste la contraseña ? </a>
      </div>

    </ion-card>

  </div>

</div>