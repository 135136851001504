@import "swiper/css";
@import "swiper/css/navigation";

.inp-manage{
  width: 100%;
  height: 2.5em;
  border: none;
}

.inp-manage:focus{
  outline: none;
}

.price-strikethrough {
  text-decoration: line-through;
  color: #ff7589;
}

ion-content{
    background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
}

.deskheader{
  display: none;
}

.deskfooter{
  display: none;
}

  ion-button {
    --box-shadow: transparent !important;
  }
  
  ion-card{
      background: rgba(255, 255, 255, 0.164); //     #fdfdfd            #14A8CD*
      backdrop-filter: blur(2px);
      /*background: rgb(28,138,242);
      background: linear-gradient(0deg, rgba(28,138,242,1) 47%, rgba(45,166,253,1) 47%); //     #fdfdfd            #14A8CD*/
      display: flex;
      justify-content:space-between;
     
      flex-direction: column;
      //box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      //box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      width: 100%;
      height: 100%;
      border-radius: .25rem;
  
      /* From https://css.glass */
      background: rgba(255, 255, 255, 0.150);
      border-radius: 10px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      border: .5px solid rgba(0, 0, 0, 0.082);
  
      .contImg{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
  
        img{
          width: 80%;
          height: auto;
        }
      }
  
      .text{
        width: 100%;
        height: 40%;
        color: #14add1;
        font-size: 25px;
        ion-text{
          width: 100%;
          font-size: 20vw;
          text-align: center;
          color: rgb(0, 0, 0);
          // text-shadow: 4px 3px 4px rgb(65, 64, 64); 
        }
  
      }
      .textDesc{
        width: 100%;
        height: 40%;
        color: black;
        font-size: 15px;
        // font-weight: bold;
        ion-text{
          width: 100%;
          font-size: 20vw;
          text-align: center;
          color: rgb(0, 0, 0);
          // text-shadow: 4px 3px 4px rgb(65, 64, 64); 
        }
  
      }
  
  
  
    }
  
    .textillo{
        font-size: 20px;
        margin-bottom: 10px;
        color: black;
        font-weight: bold;
    }
  
    .form-group{
  
   margin-left: 100px;
    }

    .color-cont-prop{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: .5em;
    }

    .color-cont-prop-list{
      width: 100%;
      height: auto;
      margin-top: .5em;

      .conten-names-opts{
        width: 100%;
        min-height: 5em;
        max-height: 13em;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1em 0em;
        gap: 1em;

        .btn-list-opt{
          width: 100%;
          padding: 1em 0em;
          color: #1ca3c9;
          background-color: transparent;
          border-top: 1px solid #0abcee;
          border-bottom: 1px solid #0abcee;
          box-shadow: 0px 0px 5px 0px #0abcee;
        }
      }

      .conten-names-opts::-webkit-scrollbar {
        width: .6em;
        background-color: transparent;
        height: .7em;
      }
        
      .conten-names-opts::-webkit-scrollbar-thumb {
        background-color: #00a2c779;
        //border-radius: 1vh;
        width: 0.25em;
      }
    }

    .swiper-pagination{
      margin-top: 10px;
    }
  
    .selector-color {
      display: inline-block;
      margin: 0px 2px;
      width: 40px;
      height: 40px;
      position: relative;
      border-radius: 3px;
      cursor: pointer;
      //overflow: hidden;
      transform: rotate(0deg);
      transition: .5s;
      //border-radius: 50%;
      padding: .5em;
      display: flex; 
      justify-content: center;
      align-items: center;

      .color-name {
        font-size: 0.75em;
        text-align: center;
        // overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 57px;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.1em;
        max-height: 2.2em;
        color: #14add1;
        top: 1px;
      }
    }

    .line-tb{
      padding: 1.5em 0.5em;

      .r-color{
        position: absolute;
        width: 3.5em;
        height: 3.5em;
        border: 1px solid #cfcfcf;
        border-radius: 50%;
        right: 1em;
        top: 0.5em;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .preview{
      position: absolute;
      z-index: 5;
      background-color: #fff;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.7em;
      padding: 1em;
      border-radius: 1em;
      bottom: 3em;
      right: calc(50% - 2em);
      box-shadow: rgb(0 230 241 / 28%) 0px 5px 29px 0px;

      .diva-color-p{
        background-color: rgb(255, 255, 255);
        width: 6em;
        height: 6em;
        border: 1px solid #ddd;
        border-radius: 50%;
      }

      .name-color-p{
        font-size: .9em;
        color: #000;
        font-weight: 500;
      }
    }

    .texto-descripcion {
      color: white;
      font-size: 1.3em;
    }
    
    .selected.selector-color {
      transform: rotate(360deg);
    }
    
    .selector-color input {
      width: 30px;
      background-size: cover;
      position: absolute;
      height: 30px;
      top: 5px;
      left: 5px;
      box-shadow: inset rgb(0 0 0 / 20%) 0px 0px 12px;
      transition: 1s;
      border-radius: 50%;
      border: none;
    }

    @media(max-width: 992px){
      .selector-color:hover{
        .preview{
          display: none;
        }
      }
    }
    
    .selected.selector-color input{
      border-radius: 50%;
      animation: lux 2s ease-in-out infinite;
      -webkit-animation: lux 2s ease-in-out infinite;
    }
    
    .contiene-descripcion > table > tbody > tr > td > div > img {
      max-width: 100%;
      height: auto;
    }

    .swiper {
      width: 100%;
      height: 100%;
    }
    
    .swiper-container-wrapper{
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .swiper-container{
      /*padding-top: 0em;
      width: 25em;
      height: auto;
     
      margin: 0 auto;
      display: flex;
      justify-content: center;*/
      //align-items: center;
    }


    swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    swiper-slide video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    
    swiper-slide {
      background-size: cover;
      background-position: center;
    }
    
    .mySwiperP2 {
      height: 80%;
      width: 100%;
    }
    
    .mySwiperP {
      height: 100%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    
    .mySwiperP swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    
    .mySwiperP .swiper-slide-thumb-active {
      opacity: 1;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    swiper-slide video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tituloDescripcion{
      color: rgb(27,172,209);
      font-family: 'RobotoCondensed-Light';
      font-size: 18px;
      font-style: italic;
      text-align: center;
    }

    .titulillo{
      font-size: 20px;
      margin-bottom: 0em;
      color: black;
      font-weight: 100;
      text-transform: uppercase;
      text-align: center;
      font-size: 26px;
    }
   

    .loader {
      display: flex;
      width: 100%;
      height: 100%;
      background-position: center;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: fixed;
      top: calc(0%);
      left: calc(0%);
      background-color: rgb(255, 255, 255);
      opacity: 0.9;
    
      img{
        width: 200px;
        height: auto;
   
      }
  
    }

    @media screen and (max-width:992px){
      .swiper-container-wrapper{
        display: none;
      }
      #swiperEscritorio{
        display: none;
      }

      .dnPd{
        display: none;
      }   

      // .descripcionProducto{
      //   display: none;
      // }

      .columnas{
        height: min-content;
      }
      .swiper-container{
        height: auto;
      }
    
      @media(min-width: 993px){
        .columnas ion-col{
      
          width: 25%;
       }
      }
      
      .columnas ion-col:nth-of-type(1){
        order: 1;
      .columnas ion-col:nth-of-type(2){
        order: 2;
      }
      .columnas ion-col:nth-of-type(3){
        order: 3;
      }
    }
    #CodigoYColor{
      margin-right: 10em;
    }
    }  
    
    // @media screen and (min-width:993px){
    //     .columnas {
    //       height: min-content;
    //     }
    
    //     .columnas ion-col{
    //       width: 25%;
    //     }
    //     .columnas ion-col:nth-of-type(2){
    //       order: 1;
    //     }
    //     .columnas ion-col:nth-of-type(1){
    //       order: 2;
    //     }
    //     .columnas ion-col:nth-of-type(3){
    //       order: 3;
    //     }
    
    // }
    .precio{
      color: rgb(29,171,209);
      font-family: 'RobotoCondensed-Light';
      font-size: 35px;
      letter-spacing: normal;
      line-height: 38.5px;
      text-size-adjust: 100%;
      text-transform: uppercase;
    }
    
    @media screen  and (min-width:993px){
      .deskheader{
        display: block;
      }

      .deskfooter{
        display: block;
      }

      #swiper-mobile{
        display: none;
      }
      
      // #descripcionProducto{
      //   display: none;
      // }
      #caracteristicas{
        //padding-left: 20%;
        //padding-left: 15em;
        //padding-right: 15em;
        box-sizing: border-box;
        margin: auto;
        width: 100%;
        height: fit-content;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      #irCarrito{
        display: none;
      }
      
      #existencia{
        display: none;
      }
      #colorSeleccionado{
        display: none;
      }

      ion-header{
        display: none;
      }
}

#existenciaDesk{
  display: none;

  .conten-exist{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: .5em 0em;

    .lefti{
      color: #000;
      font-size: 1.2em;  
    }

    .rigti{
      color: #00a3c7;
      font-size: 1.1em;
    }
  }
}

.nohay-cont{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  padding: 0.7em 0em;
  border-bottom: 1px solid #f00;
  margin-bottom: 1em;

  .textin{
    color: #f00;
    font-weight: 600;
    font-size: 1.2em;
  }

  i{
    color: #f00;
    font-size: 2.2em;
  }
}

.cont-car{
  width: 70%;
  height: auto;

  table{
    width: 100%;
    height: auto;
    text-align: center;
  }

  div{
    width: 100%;
    overflow-x: scroll;
  }
  
  ul{
    width: 100%;
    padding: 0em 25%;
  }
  
  td{
    border: 1px solid #cdcdcd;
  }
  
  
  tbody{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  h5{
    span{
      font-size: 1.7em;
    }
  }
  
  p{
    span{
      font-size: 1.4em;
      color: #00a3c7;
    }
  }
  
  hr{
    background-color: #000;
    margin: 2em 0em;
  }
}

@media(min-width: 992px){
  #existenciaDesk{
    display: block;
  }

  .cont-car{
    height: auto;
    // overflow-y: scroll;
    box-shadow: rgb(3 165 198 / 43%) 0px 2px 8px 0px;
    padding: 1em;
  }
}

@media(max-width: 992px){
  .cont-car{
    width: 100%;
  }
}

// Screen size hidden
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}
