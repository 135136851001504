/* Contenedor de reseñas */
.reviews-cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-left: 8%;
  padding-right: 8%;
}

.fixa {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.list-reviews h2 {
  margin-bottom: 25px;
}

/* .desk-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* Botón personalizado */
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  h2 {
    font-size: xx-large;
  }
}

.custom-button {
  background-color: #00a5cb;
  color: white;
  // font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 5px;
  &:hover {
    background-color: #0089a8;
  }
}

.list-reviews-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  gap: 10px;

  i {
    font-size: 28px;
    color: #0089a8;

    &:hover {
      color: rgb(162, 162, 162);
    }
  }
}

/* Tarjeta de comentarios */
.comment-card {
  background-color: #fff;
  width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  border: 2px solid #17d9fd4d;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
  
  &:hover {
  box-shadow: #17d9fd4d 0 7px 29px; // Sombra más pronunciada al pasar el cursor
  border: 2px solid transparent;
  }
}

.space {
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator {
  background-color: #17dafd;
  width: 90%;
  height: 4px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 30px;
}

/* Cabecera de cada comentario */
.comment-header {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

button.custom-button {
  cursor: pointer;
}

.error-message-small {
  color: red;          /* Texto de color rojo */
  font-size: 12px;     /* Tamaño de fuente pequeño */
  margin-top: 5px;     /* Espacio superior para separarlo un poco */
}

button.custom-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.comment-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.comment-stars img {
  width: 120px;
  height: auto;
}

/* Contenido del comentario */
.comment-content {
  font-size: 15px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 15px;
}

/* Pie del comentario */
.comment-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #777;
}

.comment-nick {
  color: #444;
  font-weight: 500;
}

.comment-date {
  font-size: 12px;
  color: #0089a8;
  text-align: end;
}

/* Modal */
.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 450px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
}

.close-button {
  background-color: #d9534f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c9302c;
  }
}

/* Inputs */
.inps, .selict {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Skeleton loader */
.skeleton-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.skeleton-item {
  display: flex;
  gap: 15px;
}

.skeleton-thumbnail {
  width: 60px;
  height: 60px;
  background: #ccc;
  border-radius: 4px;
}

.skeleton-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.skeleton-text {
  background: #ddd;
  height: 15px;
  border-radius: 4px;
}

.skeleton-title {
  width: 80%;
}

.skeleton-subtitle {
  width: 60%;
}
