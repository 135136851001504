import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import Fuse from 'fuse.js';

import { InfiniteScrollCustomEvent, IonicModule, ModalController } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';

import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Producto } from '../../Models/Productos';
import { CommonModule, DatePipe, isPlatformBrowser, Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { ProductService } from '../../services/handle-categorias.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';

@Component({
  selector: 'app-catalogo',
  standalone: true,
  imports: [MatPaginatorModule, CommonModule, RouterModule, IonicModule, FormsModule],
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  providers: [ApiProductosService, CarritoServiceService, ProductService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CatalogoComponent implements OnInit {

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  @ViewChild('modal_wp') modal_wp!: ElementRef<HTMLInputElement>;
  modal_wpIs = false;

  items: any = [];
  sum: number = 1;

  public page = 1;
  perPage = 10;
  muestroStock = "";
  totalData = 0;
  totalPage = 10;
  public grandTotal!: number;
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public totalItems: number = 0;
  public productos: any = [];
  public resultadoBusqueda: any = null;
  private globalClickListener!: () => void;
  public numeroResultados: number = 0;
  productosCatalogo: any;
  cargando = false;
  precio: any;
  id_product: any;
  cp: number = 1;
  descuento: any;
  Agotado = "";
  id_cat: any;
  isModalOpen2 = false;
  public crr_sel = 0;
  public crr_indx = 0;
  public cantidad: number = 1;
  public existencias: any;
  productoSKU: any;
  fechita = new Date();
  contenidoHTML: any;
  nuevaFecha: any;
  unidadVenta: any;
  descripcionsilla: any;
  selectedImageURL: string = "";
  public descripcion: any;
  productoData: any;
  public colorSeleccionado: string = '';
  atributos: any;
  public precioDyn: any;
  public color = 'blanco';
  descripcionsita: any;
  referenciaChida: any;
  fechaInicio: any;
  fechaFin: any;
  fechaActual: any;
  resdesc: any = [];
  descuentillo = false;
  carritoclick = 0;
  cargoprecio = false;
  email: any;
  id_product_attribute: any;
  varColorSelected: any;
  cargandoImagenes = 0;
  cargandoPrecio = 0;
  cargandoInventario = 0;
  cargandoProductoInfo = 0;
  parametros: any = [];
  descripcionsillas: any = [];
  productName: any;
  alto: any
  largo: any

  ancho: any
  peso: any
  maxVisibleColors = 10; // Número máximo de colores visibles antes de mostrar los puntos
  showAllColors = false;
  showAllColorsMap: { [key: number]: boolean } = {};
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  public isDesk: boolean = false;
  public isCel: boolean = false;

  catalogo_cat = "";
  public currentItemsToShow: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  public tamanoLista: number = 0;

  private productSubscription!: Subscription;
  public productCat: any;

  usuario!: Usuario;
  public infoUser: any = [];
  public infoUser2: any = [];

  constructor(
    private productoService: ApiProductosService,
    public modalCtrl: ModalController,
    private activatedRoute: ActivatedRoute,
    private shoppingCart: CarritoServiceService,
    private productService: ProductService,
    private location: Location,
    private router: Router,
    private titleService: Title,
    private datePipe: DatePipe,
    private metaService: Meta,
    private checkoutService: CheckoutServiceService,
    private usuarioService: LoginService,
    private apiFacebook: FacebookApiService,
    public loginService: LoginService,
    private servicioWishlist: ServiciowishlistService,
    private el: ElementRef,
    private renderer2: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  idk(event: Event, productData: any) {
    event.stopPropagation();
    const userData = this.loginService.getUserData();
    if (!userData) return

    const jsonWishlist = {
      usuario: this.usuario.usuario,
      id_product: productData.id,
      title: productData.title,
      sku: productData.sku,
      quantiity: 1,
      imagen: productData.image_link,
      precio: productData.precio
    }

    this.servicioWishlist.sendWishlist(jsonWishlist).subscribe(data => {
      if (data == 0) {
        // Pendiente alerta para decir que el producto ya se encuentra en la wishlist;
      } else {
        // Pendiente alerta para decir que el producto fue agregado correctamente;
      }
    })

  }

  ngOnInit() {
    this.currentItemsToShow.forEach((producto: any) => {
      producto.showAllColors = false;
    });
    if (isPlatformBrowser(this.platformId)) {
      this.usuarioService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;
        if (this.usuario) {
          this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
            this.infoUser = data;
            //console.log(this.infoUser);
            this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
              this.infoUser2 = res;
              //console.log(this.infoUser2);
              this.apiFacebook.sendEvent('PageView', undefined, this.usuario.usuario, this.infoUser2[0].phone, {
                external_id: this.infoUser.customerID,
                nombre: this.infoUser2[0].firstname,
                apellido: this.infoUser2[0].lastname,
                estado: this.infoUser2[0].id_state,
                ciudad: this.infoUser2[0].city,
                country: 'México',
                codigo_postal: this.infoUser2[0].postcode
              });
            });
          });
        }
      });
    }
    this.scrollUp();
    /*if (!localStorage.getItem('foo')) { 
    localStorage.setItem('foo', 'no reload') 
    location.reload() 
  } else {
    localStorage.removeItem('foo') 
  }*/
    this.activatedRoute.params.subscribe(params => {
      this.id_cat = params['id_categoria'];

      if (this.usuario && this.usuario.usuario) {
        this.obtenerDescuentoUsuario();
      } else {
        this.productoService.getCategorias(this.id_cat).subscribe((data: any) => {
          this.productosCatalogo = data;
          this.productService.setCatalogo(this.productosCatalogo);
          this.productSubscription = this.productService.selectedCata$.subscribe((product: any) => {
            this.productCat = product;
          });
          this.tamanoLista = this.productCat.length;
          this.currentItemsToShow = this.productCat.slice(0, 24);
          this.totalData = this.productCat.length;
          this.precio = this.productCat.precio;
          this.id_product = this.productCat.id_product;


          for (let i = 0; i < 4; i++) {
            let imp = this.sum;
            if (this.sum <= this.totalData) {
              this.items.push(this.productCat[imp - 1]);
            }
            this.sum++;
          }
        });
      }

      // this.productoService.getCategorias(this.id_cat).subscribe((data: any) => {
      //   this.productosCatalogo = data;
      //   this.productService.setCatalogo(this.productosCatalogo);
      //   this.productSubscription = this.productService.selectedCata$.subscribe((product: any) => {
      //     this.productCat = product;
      //   });
      //   this.tamanoLista = this.productCat.length;
      //   this.currentItemsToShow = this.productCat.slice(0, 24);
      //   this.totalData = this.productCat.length;
      //   this.precio = this.productCat.precio;
      //   this.id_product = this.productCat.id_product;


      //   for (let i = 0; i < 4; i++) {
      //     let imp = this.sum;
      //     if (this.sum <= this.totalData) {
      //       this.items.push(this.productCat[imp - 1]);
      //     }
      //     this.sum++;
      //   }
      // })

      //this.generateItems();

    })
    if (this.id_cat == 91) {
      this.catalogo_cat = "Accesorios para planchas";
    } else if (this.id_cat == 92) {
      this.catalogo_cat = "Accesorios para plotters";
    } else if (this.id_cat == 41) {
      this.catalogo_cat = "Adhesivos";
    } else if (this.id_cat == 25 || this.id_cat == 794) {
      this.catalogo_cat = "Bisutería";
    } else if (this.id_cat == 23) {
      this.catalogo_cat = "Costura";
    } else if (this.id_cat == 31) {
      this.catalogo_cat = "Cubiertas, totes";
    } else if (this.id_cat == 405) {
      this.catalogo_cat = "Cubiertas, totes";
    } else if (this.id_cat == 54) {
      this.catalogo_cat = "Cursos";
    } else if (this.id_cat == 22) {
      this.catalogo_cat = "Embossing";
    } else if (this.id_cat == 58) {
      this.catalogo_cat = "Engargolado";
    } else if (this.id_cat == 46) {
      this.catalogo_cat = "Foil Quill";
    } else if (this.id_cat == 93) {
      this.catalogo_cat = "Foil";
    } else if (this.id_cat == 48) {
      this.catalogo_cat = "Grabado fotobotones";
    } else if (this.id_cat == 124) {
      this.catalogo_cat = "Fotografía y Video";
    } else if (this.id_cat == 94) {
      this.catalogo_cat = "Glitter";
    } else if (this.id_cat == 26) {
      this.catalogo_cat = "Grabado cristal";
    } else if (this.id_cat == 28 || this.id_cat == 406 || this.id_cat == 478 || this.id_cat == 802) {
      this.catalogo_cat = "Herramientas de corte manual";
    } else if (this.id_cat == 57) {
      this.catalogo_cat = "Herramientas de troquelado";
    } else if (this.id_cat == 52 || this.id_cat == 122) {
      this.catalogo_cat = "Herramientas para acrílico";
    } else if (this.id_cat == 95 || this.id_cat == 407) {
      this.catalogo_cat = "Herramientas para depilación y aplicación de vinil";
    } else if (this.id_cat == 33 || this.id_cat == 408) {
      this.catalogo_cat = "Hoja transportadora - Tapetes";
    } else if (this.id_cat == 40) {
      this.catalogo_cat = "Hojas para laminar";
    } else if (this.id_cat == 96) {
      this.catalogo_cat = "Impresión 3D Equipos y Accesorios";
    } else if (this.id_cat == 99) {
      this.catalogo_cat = "Impresión 3D Filamentos";
    } else if (this.id_cat == 51) {
      this.catalogo_cat = "Impresión de tarjetas";
    } else if (this.id_cat == 37) {
      this.catalogo_cat = "Laminadoras";
    } else if (this.id_cat == 42 || this.id_cat == 2047) {
      this.catalogo_cat = "Listones";
    } else if (this.id_cat == 101) {
      this.catalogo_cat = "Mochilas, morrales y gorras";
    } else if (this.id_cat == 102) {
      this.catalogo_cat = "Moldes de plástico";
    } else if (this.id_cat == 103 || this.id_cat == 402) {
      this.catalogo_cat = "Navajas y Accesorios para Plotter de corte";
    } else if (this.id_cat == 104) {
      this.catalogo_cat = "Ojillos";
    } else if (this.id_cat == 27) {
      this.catalogo_cat = "Papeles de transferencia";
    } else if (this.id_cat == 105 || this.id_cat == 409) {
      this.catalogo_cat = "Papeles, cartulinas y más";
    } else if (this.id_cat == 19) {
      this.catalogo_cat = "Paquetes";
    } else if (this.id_cat == 32) {
      this.catalogo_cat = "Perforadoras";
    } else if (this.id_cat == 107) {
      this.catalogo_cat = "Pintura y pinceles";
    } else if (this.id_cat == 35) {
      this.catalogo_cat = "Planchas de calor";
    } else if (this.id_cat == 20) {
      this.catalogo_cat = "Plotters de corte";
    } else if (this.id_cat == 108) {
      this.catalogo_cat = "Plumas Quill";
    } else if (this.id_cat == 53 || this.id_cat == 401 || this.id_cat == 475) {
      this.catalogo_cat = "Plumas y marcadores";
    } else if (this.id_cat == 109) {
      this.catalogo_cat = "Productos aplicación foil reactivo láser";
    } else if (this.id_cat == 45) {
      this.catalogo_cat = "Pulseras para eventos";
    } else if (this.id_cat == 44) {
      this.catalogo_cat = "Resina";
    } else if (this.id_cat == 21) {
      this.catalogo_cat = "Sellos";
    } else if (this.id_cat == 24) {
      this.catalogo_cat = "Serigrafía casera";
    } else if (this.id_cat == 36) {
      this.catalogo_cat = "Software y digital";
    } else if (this.id_cat == 111) {
      this.catalogo_cat = "Sublimación";
    } else if (this.id_cat == 112 || this.id_cat == 411) {
      this.catalogo_cat = "Sublimación blancos";
    } else if (this.id_cat == 113) {
      this.catalogo_cat = "Sublimación equipos";
    } else if (this.id_cat == 13) {
      this.catalogo_cat = "Sublimación impresora";
    } else if (this.id_cat == 16) {
      this.catalogo_cat = "Sublimación papel";
    } else if (this.id_cat == 15) {
      this.catalogo_cat = "Sublimación tintas";
    } else if (this.id_cat == 114) {
      this.catalogo_cat = "Tabletas para trazado y depilado";
    } else if (this.id_cat == 115) {
      this.catalogo_cat = "Tape decorativo";
    } else if (this.id_cat == 56) {
      this.catalogo_cat = "Troqueladoras";
    } else if (this.id_cat == 55) {
      this.catalogo_cat = "Troqueles";
    } else if (this.id_cat == 17) {
      this.catalogo_cat = "Vinil textil";
    } else if (this.id_cat == 18) {
      this.catalogo_cat = "Vinil y papel para Rotulación";
    } else if (this.id_cat == 123) {
      this.catalogo_cat = "Outlet";
    } else if (this.id_cat == 67) {
      this.catalogo_cat = "3M";
    } else if (this.id_cat == 71) {
      this.catalogo_cat = "Acribend";
    } else if (this.id_cat == 125) {
      this.catalogo_cat = "Arkon Mounts";
    } else if (this.id_cat == 70) {
      this.catalogo_cat = "Armour Etch";
    } else if (this.id_cat == 840) {
      this.catalogo_cat = "Brother";
    } else if (this.id_cat == 134 || this.id_cat == 179) {
      this.catalogo_cat = "Color Pour";
    } else if (this.id_cat == 137 || this.id_cat == 189) {
      this.catalogo_cat = "Colortex";
    } else if (this.id_cat == 61) {
      this.catalogo_cat = "Cricut";
    } else if (this.id_cat == 136) {
      this.catalogo_cat = "Decofoil";
    } else if (this.id_cat == 224) {
      this.catalogo_cat = "Doodlebug";
    } else if (this.id_cat == 65) {
      this.catalogo_cat = "Eclectic";
    } else if (this.id_cat == 183 || this.id_cat == 181) {
      this.catalogo_cat = "Ektools";
    } else if (this.id_cat == 78) {
      this.catalogo_cat = "Forever";
    } else if (this.id_cat == 66) {
      this.catalogo_cat = "Glue Dots";
    } else if (this.id_cat == 69 || this.id_cat == 166) {
      this.catalogo_cat = "Gütermann";
    } else if (this.id_cat == 68) {
      this.catalogo_cat = "Janome";
    } else if (this.id_cat == 79) {
      this.catalogo_cat = "Metalix";
    } else if (this.id_cat == 135) {
      this.catalogo_cat = "Minc";
    } else if (this.id_cat == 74) {
      this.catalogo_cat = "Moxy";
    } else if (this.id_cat == 63) {
      this.catalogo_cat = "Oracal";
    } else if (this.id_cat == 62 || this.id_cat == 159) {
      this.catalogo_cat = "Sawgrass";
    } else if (this.id_cat == 59) {
      this.catalogo_cat = "Silhouette";
    } else if (this.id_cat == 64) {
      this.catalogo_cat = "Siser";
    } else if (this.id_cat == 81 || this.id_cat == 177) {
      this.catalogo_cat = "Sizzix";
    } else if (this.id_cat == 75) {
      this.catalogo_cat = "Sublimarts";
    } else if (this.id_cat == 127) {
      this.catalogo_cat = "Surebonder";
    } else if (this.id_cat == 60) {
      this.catalogo_cat = "WeR Memory Keepers";
    } else if (this.id_cat == 77) {
      this.catalogo_cat = "Zebra";
    } else if (this.id_cat == 84) {
      this.catalogo_cat = "Arte en papel";
    } else if (this.id_cat == 117) {
      this.catalogo_cat = "Costura";
    } else if (this.id_cat == 356 || this.id_cat == 185 || this.id_cat == 473) {
      this.catalogo_cat = "Decoración";
    } else if (this.id_cat == 141) {
      this.catalogo_cat = "Decoración de acrílico";
    } else if (this.id_cat == 86) {
      this.catalogo_cat = "Decoración de rígidos";
    } else if (this.id_cat == 85) {
      this.catalogo_cat = "Decoración textil";
    } else if (this.id_cat == 90) {
      this.catalogo_cat = "Engargolado";
    } else if (this.id_cat == 133) {
      this.catalogo_cat = "Fotografía y Video";
    } else if (this.id_cat == 118) {
      this.catalogo_cat = "Grabado";
    } else if (this.id_cat == 88) {
      this.catalogo_cat = "Impresión 3D";
    } else if (this.id_cat == 121) {
      this.catalogo_cat = "Impresión de tarjetas";
    } else if (this.id_cat == 120) {
      this.catalogo_cat = "Moldes de plástico";
    } else if (this.id_cat == 126) {
      this.catalogo_cat = "Promocionales";
    } else if (this.id_cat == 119) {
      this.catalogo_cat = "Repujado";
    } else if (this.id_cat == 82) {
      this.catalogo_cat = "Rotulación de paredes";
    } else if (this.id_cat == 128) {
      this.catalogo_cat = "Scrapbook";
    } else if (this.id_cat == 83) {
      this.catalogo_cat = "Sublimación";
    } else if (this.id_cat == 89) {
      this.catalogo_cat = "Troquelado";
    } else if (this.id_cat == 59) {
      this.catalogo_cat = "Luster";
    } else if (this.id_cat == 1738) {
      this.catalogo_cat = "Pa'Quete Mandes Solo";
    }
    else if (this.id_cat == 851) {
      this.catalogo_cat = "Almohadillas";
    }
    else if (this.id_cat == 852) {
      this.catalogo_cat = "Maletines y cubiertas";
    }
    else if (this.id_cat == 853) {
      this.catalogo_cat = "Resistencias";
    }
    else if (this.id_cat == 1822) {
      this.catalogo_cat = "Pines";
    }
    else if (this.id_cat == 911) {
      this.catalogo_cat = "Aditamentos";
    }
    else if (this.id_cat == 912) {
      this.catalogo_cat = "Fotobotonera";
    }
    else if (this.id_cat == 913) {
      this.catalogo_cat = "Fotobotoneras";
    }
    else if (this.id_cat == 914) {
      this.catalogo_cat = "Fotobotones";
    }
    else if (this.id_cat == 915) {
      this.catalogo_cat = "Herramientas";
    }
    else if (this.id_cat == 916) {
      this.catalogo_cat = "Moldes";
    }
    else if (this.id_cat == 917) {
      this.catalogo_cat = "Acuarelas";
    }
    else if (this.id_cat == 918) {
      this.catalogo_cat = "Godetes";
    }
    else if (this.id_cat == 921) {
      this.catalogo_cat = "Herramientas para tintas de alcohol";
    }
    else if (this.id_cat == 922) {
      this.catalogo_cat = "Kits";
    }
    else if (this.id_cat == 923) {
      this.catalogo_cat = "Liensos";
    }
    else if (this.id_cat == 924) {
      this.catalogo_cat = "Pinceles";
    }
    else if (this.id_cat == 925) {
      this.catalogo_cat = "Pinturas acrilicas";
    }
    else if (this.id_cat == 926) {
      this.catalogo_cat = "Tintas de alcohol";
    }
    else if (this.id_cat == 1823) {
      this.catalogo_cat = "Resinas/Kits de inicio";
    }
    else if (this.id_cat == 929) {
      this.catalogo_cat = "Resina epoxica/silhouette";
    }
    else if (this.id_cat == 1824) {
      this.catalogo_cat = "Rhinestone";
    }
    else if (this.id_cat == 931) {
      this.catalogo_cat = "Herramientas";
    }
    else if (this.id_cat == 930) {
      this.catalogo_cat = "Lentejuelas";
    }
    else if (this.id_cat == 932) {
      this.catalogo_cat = "Piedras";
    }
    else if (this.id_cat == 942) {
      this.catalogo_cat = "Plantillas";
    }
    else if (this.id_cat == 943) {
      this.catalogo_cat = "Almohadillas";
    }
    else if (this.id_cat == 944) {
      this.catalogo_cat = "DIY";
    }
    else if (this.id_cat == 945) {
      this.catalogo_cat = "Herramientas de aplicación";
    }
    else if (this.id_cat == 946) {
      this.catalogo_cat = "Mint (termicos)";
    }
    else if (this.id_cat == 947) {
      this.catalogo_cat = "Sellos clasicos";
    }
    else if (this.id_cat == 948) {
      this.catalogo_cat = "Sellos de goma";
    }
    else if (this.id_cat == 1827) {
      this.catalogo_cat = "Bases de acrílico";
    }
    else if (this.id_cat == 864) {
      this.catalogo_cat = "Albums";
    }
    else if (this.id_cat == 865) {
      this.catalogo_cat = "Artículos de mercería";
    }
    else if (this.id_cat == 866) {
      this.catalogo_cat = "Artículos de papelería";
    }
    else if (this.id_cat == 1177) {
      this.catalogo_cat = "Consumibles para 3D";
    }
    else if (this.id_cat == 1178) {
      this.catalogo_cat = "Consumibles para plotter";
    }
    else if (this.id_cat == 910) {
      this.catalogo_cat = "Crop a dile";
    }
    else if (this.id_cat == 872) {
      this.catalogo_cat = "Grabado laser";
    }
    else if (this.id_cat == 873) {
      this.catalogo_cat = "Herramientas manuales todo en uno";
    }
    else if (this.id_cat == 888) {
      this.catalogo_cat = "Hojas de aluminio";
    }
    else if (this.id_cat == 889) {
      this.catalogo_cat = "Hojas teflonadas";
    }
    else if (this.id_cat == 892) {
      this.catalogo_cat = "Material para troquelar";
    }
    else if (this.id_cat == 1426) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    }
    else if (this.id_cat == 1424) {
      this.catalogo_cat = "Productos para foil";
    }
    else if (this.id_cat == 927) {
      this.catalogo_cat = "Removedores";
    }
    else if (this.id_cat == 928) {
      this.catalogo_cat = "Repuestos para plotters";
    }
    else if (this.id_cat == 949) {
      this.catalogo_cat = "Shakers";
    }
    else if (this.id_cat == 950) {
      this.catalogo_cat = "Stencil";
    }
    else if (this.id_cat == 951) {
      this.catalogo_cat = "Stickers";
    }
    else if (this.id_cat == 954) {
      this.catalogo_cat = "Tableros para notas";
    }
    else if (this.id_cat == 1168) {
      this.catalogo_cat = "Tarjetas";
    }
    else if (this.id_cat == 1175) {
      this.catalogo_cat = "Textiles";
    }
    else if (this.id_cat == 955) {
      this.catalogo_cat = "Texturizadores";
    }
    else if (this.id_cat == 956) {
      this.catalogo_cat = "Washi tape";
    }
    else if (this.id_cat == 1900) {
      this.catalogo_cat = "Cintas";
    }
    else if (this.id_cat == 1899) {
      this.catalogo_cat = "Cartulinas decoradas";
    }
    else if (this.id_cat == 868) {
      this.catalogo_cat = "Cortadores de esquinas";
    }
    else if (this.id_cat == 1904) {
      this.catalogo_cat = "Ojillado";
    }
    else if (this.id_cat == 1905) {
      this.catalogo_cat = "Aditamentos";
    }
    else if (this.id_cat == 1906) {
      this.catalogo_cat = "Blancos";
    }
    else if (this.id_cat == 1907) {
      this.catalogo_cat = "Cintas termicas";
    }
    else if (this.id_cat == 1908) {
      this.catalogo_cat = "Especiales";
    }
    else if (this.id_cat == 889) {
      this.catalogo_cat = "Hojas teflonadas";
    }
    else if (this.id_cat == 1909) {
      this.catalogo_cat = "Horno 3D mini";
    }
    else if (this.id_cat == 1910) {
      this.catalogo_cat = "Infusible INK";
    }
    else if (this.id_cat == 1911) {
      this.catalogo_cat = "Papeles para sublimar";
    }
    else if (this.id_cat == 1912) {
      this.catalogo_cat = "Tintas";
    }
    else if (this.id_cat == 1172) {
      this.catalogo_cat = "Estabilizadores";
    }
    else if (this.id_cat == 1173) {
      this.catalogo_cat = "Fieltro";
    }
    else if (this.id_cat == 1170) {
      this.catalogo_cat = "Gorras";
    }
    else if (this.id_cat == 1174) {
      this.catalogo_cat = "Mochilas";
    }
    else if (this.id_cat == 1919) {
      this.catalogo_cat = "Cricut";
    }
    else if (this.id_cat == 1915) {
      this.catalogo_cat = "Silhouette";
    }
    // else if (this.id_cat == 1920) {
    //   this.catalogo_cat = "Creality";
    // }
    else if (this.id_cat == 1921) {
      this.catalogo_cat = "Silhouette";
    }
    else if (this.id_cat == 1922) {
      this.catalogo_cat = "Cricut";
    }
    else if (this.id_cat == 1923) {
      this.catalogo_cat = "Silhouette";
    }
    else if (this.id_cat == 1924) {
      this.catalogo_cat = "Decoraciones de papel";
    }
    else if (this.id_cat == 1008) {
      this.catalogo_cat = "Herramientas para acrílico";
    }
    else if (this.id_cat == 1019 || this.id_cat == 871) {
      this.catalogo_cat = "Grabado en cristal";
    }
    else if (this.id_cat == 1022) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 523) {
      this.catalogo_cat = "Bazzil";
    }
    else if (this.id_cat == 1925 || this.id_cat == 404) {
      this.catalogo_cat = "Costura";
    }
    else if (this.id_cat == 1023) {
      this.catalogo_cat = "Resina";
    }
    else if (this.id_cat == 1926) {
      this.catalogo_cat = "Vinil textil";
    }
    else if (this.id_cat == 1927) {
      this.catalogo_cat = "Productos para foil";
    }
    else if (this.id_cat == 1062) {
      this.catalogo_cat = "Adhesivos";
    }
    else if (this.id_cat == 1060) {
      this.catalogo_cat = "Papeles de transferencia";
    }
    else if (this.id_cat == 1046) {
      this.catalogo_cat = "Albums";
    }
    else if (this.id_cat == 1047) {
      this.catalogo_cat = "Artículos de papelería";
    }
    else if (this.id_cat == 1907) {
      this.catalogo_cat = "Cintas termicas";
    }
    else if (this.id_cat == 1908) {
      this.catalogo_cat = "Especiales";
    }
    else if (this.id_cat == 889) {
      this.catalogo_cat = "Hojas teflonadas";
    }
    else if (this.id_cat == 1909) {
      this.catalogo_cat = "Horno 3D mini";
    }
    else if (this.id_cat == 1910) {
      this.catalogo_cat = "Infusible INK";
    }
    else if (this.id_cat == 1911) {
      this.catalogo_cat = "Papeles para sublimar";
    }
    else if (this.id_cat == 1912) {
      this.catalogo_cat = "Tintas";
    }
    else if (this.id_cat == 488) {
      this.catalogo_cat = "Corvus";
    }
    else if (this.id_cat == 1028 || this.id_cat == 487) {
      this.catalogo_cat = "Grabado laser";
    }
    else if (this.id_cat == 140) {
      this.catalogo_cat = "Luminos";
    }
    else if (this.id_cat == 1075) {
      this.catalogo_cat = "Foil";
    }
    else if (this.id_cat == 1076) {
      this.catalogo_cat = "Laminadoras";
    }
    else if (this.id_cat == 934) {
      this.catalogo_cat = "Luster";
    }
    else if (this.id_cat == 1077) {
      this.catalogo_cat = "Embossing";
    }
    else if (this.id_cat == 77) {
      this.catalogo_cat = "Zebra";
    }
    else if (this.id_cat == 1144) {
      this.catalogo_cat = "Impresión de Tarjetas PVC";
    }
    else if (this.id_cat == 143) {
      this.catalogo_cat = "Artesprix";
    }
    else if (this.id_cat == 1020) {
      this.catalogo_cat = "Sublimación";
    }
    else if (this.id_cat == 522) {
      this.catalogo_cat = "ColorBok";
    }
    else if (this.id_cat == 1025) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 1936) {
      this.catalogo_cat = "Herramientas manuales";
    }
    else if (this.id_cat == 1939) {
      this.catalogo_cat = "Impresión 3D";
    }
    else if (this.id_cat == 1937) {
      this.catalogo_cat = "Creality";
    }
    else if (this.id_cat == 1938) {
      this.catalogo_cat = "Silhouette";
    }
    else if (this.id_cat == 1106) {
      this.catalogo_cat = "Embossing";
    }
    else if (this.id_cat == 1107) {
      this.catalogo_cat = "Glitters";
    }
    else if (this.id_cat == 1108) {
      this.catalogo_cat = "Herramientas para troquelado";
    }
    else if (this.id_cat == 1109) {
      this.catalogo_cat = "Organizadores";
    }
    else if (this.id_cat == 1948) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    }
    else if (this.id_cat == 1114) {
      this.catalogo_cat = "Shakers";
    }
    else if (this.id_cat == 1949) {
      this.catalogo_cat = "Stencil";
    }
    else if (this.id_cat == 1116 || this.id_cat == 919) {
      this.catalogo_cat = "Troqueladoras";
    }
    else if (this.id_cat == 1117) {
      this.catalogo_cat = "Troqueles";
    }
    else if (this.id_cat == 1010) {
      this.catalogo_cat = "Organizadores";
    }
    else if (this.id_cat == 1011) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 1012) {
      this.catalogo_cat = "Pinturas y pinceles";
    }
    else if (this.id_cat == 1014) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 1015) {
      this.catalogo_cat = "Stickers";
    }
    else if (this.id_cat == 1016) {
      this.catalogo_cat = "Tarjetas";
    }
    else if (this.id_cat == 1017) {
      this.catalogo_cat = "Washi tape";
    }
    else if (this.id_cat == 144 || this.id_cat == 180) {
      this.catalogo_cat = "American Crafts";
    }
    else if (this.id_cat == 1951) {
      this.catalogo_cat = "Accesorios para plotters";
    }
    else if (this.id_cat == 1030) {
      this.catalogo_cat = "Consumibles para plotter";
    }
    else if (this.id_cat == 1952) {
      this.catalogo_cat = "Foil";
    }
    else if (this.id_cat == 1953) {
      this.catalogo_cat = "Herramientas para vinil";
    }
    else if (this.id_cat == 1036) {
      this.catalogo_cat = "Plotters de corte";
    }
    else if (this.id_cat == 1037) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    }
    else if (this.id_cat == 1038) {
      this.catalogo_cat = "Sublimación";
    }
    else if (this.id_cat == 1041) {
      this.catalogo_cat = "Vinil textil";
    }
    else if (this.id_cat == 1042) {
      this.catalogo_cat = "Viniles y materiales para rotulación";
    }
    else if (this.id_cat == 524) {
      this.catalogo_cat = "DCWV";
    }
    else if (this.id_cat == 1043) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 1044) {
      this.catalogo_cat = "Tableros para notas";
    }
    else if (this.id_cat == 1054) {
      this.catalogo_cat = "Adhesivos";
    }
    else if (this.id_cat == 1055) {
      this.catalogo_cat = "Herramientas para corte manual y dobleses";
    }
    else if (this.id_cat == 1057) {
      this.catalogo_cat = "Perforadoras";
    }
    else if (this.id_cat == 1058) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    }
    else if (this.id_cat == 1127) {
      this.catalogo_cat = "Accesorios para fotografía y video";
    }
    else if (this.id_cat == 1128) {
      this.catalogo_cat = "Albums";
    }
    else if (this.id_cat == 1129) {
      this.catalogo_cat = "Consumibles y accesorios para engargolado";
    }
    else if (this.id_cat == 1131) {
      this.catalogo_cat = "Engargoladoras";
    }
    else if (this.id_cat == 1134) {
      this.catalogo_cat = "Herramientas para corte manual y dobleses";
    }
    else if (this.id_cat == 1136) {
      this.catalogo_cat = "Organizadores";
    }
    else if (this.id_cat == 1137 || this.id_cat == 476) {
      this.catalogo_cat = "Perforadoras";
    }
    else if (this.id_cat == 1139) {
      this.catalogo_cat = "Pines";
    }
    else if (this.id_cat == 1957) {
      this.catalogo_cat = "Accesorios para plotters";
    }
    else if (this.id_cat == 1958) {
      this.catalogo_cat = "Consumibles para 3D";
    }
    else if (this.id_cat == 1093 || this.id_cat == 1999) {
      this.catalogo_cat = "Consumible para plotter";
    }
    else if (this.id_cat == 1094) {
      this.catalogo_cat = "Cursos";
    }
    else if (this.id_cat == 1959 || this.id_cat == 2027) {
      this.catalogo_cat = "Impresión 3D";
    }
    else if (this.id_cat == 1960) {
      this.catalogo_cat = "Plotters de corte";
    }
    else if (this.id_cat == 1961) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    }
    else if (this.id_cat == 1962) {
      this.catalogo_cat = "Viniles y materiales para rotulación";
    }
    else if (this.id_cat == 1103) {
      this.catalogo_cat = "Sublimación";
    }
    else if (this.id_cat == 1104) {
      this.catalogo_cat = "Vinil textil";
    }
    else if (this.id_cat == 1123) {
      this.catalogo_cat = "Tyvek";
    }
    else if (this.id_cat == 145 || this.id_cat == 1125) {
      this.catalogo_cat = "Versamark";
    }
    else if (this.id_cat == 1095) {
      this.catalogo_cat = "Licencias de software";
    }
    else if (this.id_cat == 1098) {
      this.catalogo_cat = "Repuestos para plotters";
    }
    else if (this.id_cat == 1088) {
      this.catalogo_cat = "Royal sovereign";
    }
    else if (this.id_cat == 1032) {
      this.catalogo_cat = "Herramientas para corte manual y dobleses";
    }
    else if (this.id_cat == 1954) {
      this.catalogo_cat = "Papeles, cartulinas y cartónes";
    }
    else if (this.id_cat == 1035) {
      this.catalogo_cat = "Planchas de calor";
    }
    else if (this.id_cat == 1963) {
      this.catalogo_cat = "Foil Quill";
    }
    else if (this.id_cat == 1070 || this.id_cat == 187) {
      this.catalogo_cat = "Hotronix";
    }
    else if (this.id_cat == 1157 || this.id_cat == 881) {
      this.catalogo_cat = "Kronaline";
    }
    else if (this.id_cat == 1081) {
      this.catalogo_cat = "Adhesivos";
    }
    else if (this.id_cat == 1082 || this.id_cat == 1150 || this.id_cat == 474) {
      this.catalogo_cat = "Embossing";
    }
    else if (this.id_cat == 1964) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 1084) {
      this.catalogo_cat = "Pinturas y pinceles";
    }
    else if (this.id_cat == 1085) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 1086) {
      this.catalogo_cat = "Texturizadores";
    }
    else if (this.id_cat == 393) {
      this.catalogo_cat = "Ranger";
    }
    else if (this.id_cat == 1072) {
      this.catalogo_cat = "Accesorios para planchas";
    }
    else if (this.id_cat == 1073) {
      this.catalogo_cat = "Planchas de calor";
    }
    else if (this.id_cat == 1074) {
      this.catalogo_cat = "Textiles";
    }
    else if (this.id_cat == 129) {
      this.catalogo_cat = "Lideart";
    }
    else if (this.id_cat == 1216 || this.id_cat == 130) {
      this.catalogo_cat = "Signcal";
    }
    else if (this.id_cat == 1967) {
      this.catalogo_cat = "Calor";
    }
    else if (this.id_cat == 1965) {
      this.catalogo_cat = "Impacto";
    }
    else if (this.id_cat == 1966) {
      this.catalogo_cat = "Reactivo laser";
    }
    else if (this.id_cat == 887) {
      this.catalogo_cat = "Herramientas para vinil";
    }
    else if (this.id_cat == 1968) {
      this.catalogo_cat = "Cricut";
    }
    else if (this.id_cat == 1969) {
      this.catalogo_cat = "Silhouette";
    }
    else if (this.id_cat == 1970 || this.id_cat == 157) {
      this.catalogo_cat = "Wer memory keepers";
    }
    else if (this.id_cat == 1061 || this.id_cat == 151) {
      this.catalogo_cat = "Gisarron";
    }
    else if (this.id_cat == 1063 || this.id_cat == 154) {
      this.catalogo_cat = "Grafimag";
    }
    else if (this.id_cat == 1079) {
      this.catalogo_cat = "Orafol";
    }
    else if (this.id_cat == 1973) {
      this.catalogo_cat = "Almohadillas";
    }
    else if (this.id_cat == 1974) {
      this.catalogo_cat = "Papel adhesivo para polvos";
    }
    else if (this.id_cat == 1975) {
      this.catalogo_cat = "Pistolas de calor";
    }
    else if (this.id_cat == 1976) {
      this.catalogo_cat = "Plumas";
    }
    else if (this.id_cat == 1977 || this.id_cat == 1933) {
      this.catalogo_cat = "Polvos";
    }
    else if (this.id_cat == 1971) {
      this.catalogo_cat = "Cricut";
    }
    else if (this.id_cat == 1972 || this.id_cat == 172) {
      this.catalogo_cat = "Sublimarts";
    }
    else if (this.id_cat == 1979) {
      this.catalogo_cat = "Cricut";
    }
    else if (this.id_cat == 1980) {
      this.catalogo_cat = "Silhouette";
    }
    else if (this.id_cat == 1981 || this.id_cat == 158) {
      this.catalogo_cat = "Cricut";
    }
    else if (this.id_cat == 1982) {
      this.catalogo_cat = "Foil quill";
    }
    else if (this.id_cat == 1984) {
      this.catalogo_cat = "Marcadores";
    }
    else if (this.id_cat == 1983) {
      this.catalogo_cat = "Marcadores para plotter";
    }
    else if (this.id_cat == 1985) {
      this.catalogo_cat = "Plumas para plotter";
    }
    else if (this.id_cat == 1986) {
      this.catalogo_cat = "Aplicación";
    }
    else if (this.id_cat == 1987) {
      this.catalogo_cat = "Herramientas";
    }
    else if (this.id_cat == 1989 || this.id_cat == 1940) {
      this.catalogo_cat = "Decoraciones";
    }
    else if (this.id_cat == 1990) {
      this.catalogo_cat = "Epoxica";
    }
    else if (this.id_cat == 1991 || this.id_cat == 2006) {
      this.catalogo_cat = "Herramientas";
    }
    else if (this.id_cat == 1992 || this.id_cat == 893) {
      this.catalogo_cat = "Moldes";
    }
    else if (this.id_cat == 1993) {
      this.catalogo_cat = "Pigmentos";
    }
    else if (this.id_cat == 1994) {
      this.catalogo_cat = "Resina";
    }
    else if (this.id_cat == 1995) {
      this.catalogo_cat = "Tintas";
    }
    else if (this.id_cat == 72) {
      this.catalogo_cat = "Heidi Swapp";
    }
    else if (this.id_cat == 1996) {
      this.catalogo_cat = "Foil";
    }
    else if (this.id_cat == 1065) {
      this.catalogo_cat = "Laminadoras";
    }
    else if (this.id_cat == 1066) {
      this.catalogo_cat = "Organizadores";
    }
    else if (this.id_cat == 1998) {
      this.catalogo_cat = "Productos para foil";
    }
    else if (this.id_cat == 1068) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 1069) {
      this.catalogo_cat = "Washi tape";
    }
    else if (this.id_cat == 2001) {
      this.catalogo_cat = "Viniles y materiales para rotulación";
    }
    else if (this.id_cat == 2002 || this.id_cat == 1130) {
      this.catalogo_cat = "Crop a diles";
    }
    else if (this.id_cat == 2003 || this.id_cat == 1140 || this.id_cat == 1152 || this.id_cat == 491 || this.id_cat == 804) {
      this.catalogo_cat = "Resina";
    }
    else if (this.id_cat == 1141) {
      this.catalogo_cat = "Rhinestone";
    }
    else if (this.id_cat == 1143) {
      this.catalogo_cat = "Stickers";
    }
    else if (this.id_cat == 2004 || this.id_cat == 1133) {
      this.catalogo_cat = "Herramientas manuales todo en uno";
    }
    else if (this.id_cat == 2005) {
      this.catalogo_cat = "Agujas";
    }
    else if (this.id_cat == 2007) {
      this.catalogo_cat = "Maquinas de coser";
    }
    else if (this.id_cat == 2008) {
      this.catalogo_cat = "Refacciones";
    }
    else if (this.id_cat == 391) {
      this.catalogo_cat = "Impresoras";
    }
    else if (this.id_cat == 894 || this.id_cat == 933) {
      this.catalogo_cat = "Organizadores";
    }
    else if (this.id_cat == 80) {
      this.catalogo_cat = "Otros";
    }
    else if (this.id_cat == 957) {
      this.catalogo_cat = "Xtool";
    }
    else if (this.id_cat == 2015) {
      this.catalogo_cat = "Adhesivos";
    }
    else if (this.id_cat == 2019) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    }
    else if (this.id_cat == 1026) {
      this.catalogo_cat = "Sublimación";
    }
    else if (this.id_cat == 2023) {
      this.catalogo_cat = "Vinil textil";
    }
    else if (this.id_cat == 1039) {
      this.catalogo_cat = "Tarjetas";
    }
    else if (this.id_cat == 1045) {
      this.catalogo_cat = "Tarjetas";
    }
    else if (this.id_cat == 1056) {
      this.catalogo_cat = "Herramientas para troquelado";
    }
    else if (this.id_cat == 1059) {
      this.catalogo_cat = "Texturizadores";
    }
    else if (this.id_cat == 1099) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 2024) {
      this.catalogo_cat = "Sublimación";
    }
    else if (this.id_cat == 2025) {
      this.catalogo_cat = "Textiles";
    }
    else if (this.id_cat == 532) {
      this.catalogo_cat = "Creality";
    }
    else if (this.id_cat == 1947) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 1112) {
      this.catalogo_cat = "Rhinestone";
    }
    else if (this.id_cat == 1113) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 1792) {
      this.catalogo_cat = "Varali";
    }
    else if (this.id_cat == 149) {
      this.catalogo_cat = "Color print";
    }
    else if (this.id_cat == 2020) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 2022) {
      this.catalogo_cat = "Vinil textil";
    }
    else if (this.id_cat == 1120 || this.id_cat == 400) {
      this.catalogo_cat = "Planchas de calor";
    }
    else if (this.id_cat == 1121) {
      this.catalogo_cat = "Sublimación";
    }
    else if (this.id_cat == 1132 || this.id_cat == 870) {
      this.catalogo_cat = "Glitters";
    }
    else if (this.id_cat == 1135) {
      this.catalogo_cat = "Herramientas para vinil";
    }
    else if (this.id_cat == 1142) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 2033) {
      this.catalogo_cat = "Troqueles";
    }
    else if (this.id_cat == 2028) {
      this.catalogo_cat = "Consumibles y accesorios para engargolado";
    }
    else if (this.id_cat == 2029) {
      this.catalogo_cat = "Arillos";
    }
    else if (this.id_cat == 2031) {
      this.catalogo_cat = "Discos";
    }
    else if (this.id_cat == 2030) {
      this.catalogo_cat = "Pastas";
    }
    else if (this.id_cat == 1119) {
      this.catalogo_cat = "Stati-k max";
    }
    else if (this.id_cat == 2034) {
      this.catalogo_cat = "Reactivos laser";
    }
    else if (this.id_cat == 2032 || this.id_cat == 2043) {
      this.catalogo_cat = "Quelli";
    }
    else if (this.id_cat == 1955) {
      this.catalogo_cat = "Textiles";
    }
    else if (this.id_cat == 2035) {
      this.catalogo_cat = "Cartulinas decoradas";
    }
    else if (this.id_cat == 2036) {
      this.catalogo_cat = "Masking";
    }
    else if (this.id_cat == 155) {
      this.catalogo_cat = "Productos nuevos";
    }
    else if (this.id_cat == 1828) {
      this.catalogo_cat = "Borradores";
    }
    else if (this.id_cat == 854) {
      this.catalogo_cat = "Decorativos";
    }
    else if (this.id_cat == 855) {
      this.catalogo_cat = "Doble cara";
    }
    else if (this.id_cat == 856) {
      this.catalogo_cat = "Liquido";
    }
    else if (this.id_cat == 1156) {
      this.catalogo_cat = "Masking";
    }
    else if (this.id_cat == 857) {
      this.catalogo_cat = "Para joyeria";
    }
    else if (this.id_cat == 858) {
      this.catalogo_cat = "Para relieve";
    }
    else if (this.id_cat == 859) {
      this.catalogo_cat = "Para telas";
    }
    else if (this.id_cat == 860) {
      this.catalogo_cat = "Pistolas de silicón";
    }
    else if (this.id_cat == 862) {
      this.catalogo_cat = "Silicón caliente";
    }
    else if (this.id_cat == 863) {
      this.catalogo_cat = "Silicón liquido";
    }
    else if (this.id_cat == 869 || this.id_cat == 156) {
      this.catalogo_cat = "Silhouette";
    }
    else if (this.id_cat == 1825 || this.id_cat == 1122 || this.id_cat == 1124) {
      this.catalogo_cat = "Herramientas para corte manual y dobleses";
    }
    else if (this.id_cat == 874) {
      this.catalogo_cat = "Cortadores rotatorios";
    }
    else if (this.id_cat == 875) {
      this.catalogo_cat = "Cutters/Exactos";
    }
    else if (this.id_cat == 876) {
      this.catalogo_cat = "Guillotinas";
    }
    else if (this.id_cat == 877) {
      this.catalogo_cat = "Guillotinas/Tableros de dobles";
    }
    else if (this.id_cat == 878) {
      this.catalogo_cat = "Herramientas de encuadernado";
    }
    else if (this.id_cat == 879) {
      this.catalogo_cat = "Kits";
    }
    else if (this.id_cat == 880) {
      this.catalogo_cat = "Pinzas";
    }
    else if (this.id_cat == 882) {
      this.catalogo_cat = "Reglas";
    }
    else if (this.id_cat == 883) {
      this.catalogo_cat = "Tableros de dobles";
    }
    else if (this.id_cat == 884) {
      this.catalogo_cat = "Tableros de dobles y corte";
    }
    else if (this.id_cat == 885) {
      this.catalogo_cat = "Tapetes de corte";
    }
    else if (this.id_cat == 886) {
      this.catalogo_cat = "Tijeras";
    }
    else if (this.id_cat == 1821) {
      this.catalogo_cat = "Kits De Inicio/Rhinestone";
    }
    else if (this.id_cat == 890) {
      this.catalogo_cat = "KITS";
    }
    else if (this.id_cat == 1820) {
      this.catalogo_cat = "Laminadores";
    }
    else if (this.id_cat == 891) {
      this.catalogo_cat = "LAMINADORES";
    }
    else if (this.id_cat == 895) {
      this.catalogo_cat = "Acetatos";
    }
    else if (this.id_cat == 896) {
      this.catalogo_cat = "Cartones";
    }
    else if (this.id_cat == 897) {
      this.catalogo_cat = "Cartulinas doble vista";
    }
    else if (this.id_cat == 898) {
      this.catalogo_cat = "Cartulinas glitter";
    }
    else if (this.id_cat == 899) {
      this.catalogo_cat = "Cartulinas holograficas";
    }
    else if (this.id_cat == 900) {
      this.catalogo_cat = "Cartulinas lisas";
    }
    else if (this.id_cat == 901) {
      this.catalogo_cat = "Cartulinas lisas/premium";
    }
    else if (this.id_cat == 902) {
      this.catalogo_cat = "Cartulinas metalicas";
    }
    else if (this.id_cat == 903) {
      this.catalogo_cat = "Foamy/Goma eva";
    }
    else if (this.id_cat == 904) {
      this.catalogo_cat = "Glitter";
    }
    else if (this.id_cat == 905) {
      this.catalogo_cat = "Papel tipo cuero";
    }
    else if (this.id_cat == 906) {
      this.catalogo_cat = "Papel con adhesivo";
    }
    else if (this.id_cat == 907) {
      this.catalogo_cat = "Papeles especiales";
    }
    else if (this.id_cat == 908) {
      this.catalogo_cat = "Premium";
    }
    else if (this.id_cat == 909) {
      this.catalogo_cat = "Texturizados/Premium";
    }
    else if (this.id_cat == 2056) {
      this.catalogo_cat = "Promociones Mayo";
    }
    // else if (this.id_cat == 2058) {
    //   this.catalogo_cat = "HOT FEST";
    // }
    else if (this.id_cat == 861) {
      this.catalogo_cat = "Reposisionable";
    }
    else if (this.id_cat == 2045) {
      this.catalogo_cat = "Aplicación";
    }
    else if (this.id_cat == 1950) {
      this.catalogo_cat = "Accesorios para planchas";
    }
    else if (this.id_cat == 2049) {
      this.catalogo_cat = "Hojas de aluminio";
    }
    else if (this.id_cat == 2050 || this.id_cat == 1115) {
      this.catalogo_cat = "Stencil";
    }
    else if (this.id_cat == 2051) {
      this.catalogo_cat = "Hilos";
    }
    else if (this.id_cat == 2052) {
      this.catalogo_cat = "Tela";
    }
    else if (this.id_cat == 1048) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    }
    else if (this.id_cat == 1049) {
      this.catalogo_cat = "Sellos";
    }
    else if (this.id_cat == 1051) {
      this.catalogo_cat = "Tarjetas";
    }
    else if (this.id_cat == 1052 || this.id_cat == 1126 || this.id_cat == 920) {
      this.catalogo_cat = "Troqueles";
    }
    else if (this.id_cat == 1053) {
      this.catalogo_cat = "Washi tape";
    } else if (this.id_cat == 10) {
      this.catalogo_cat = "Tipos de producto";
    } else if (this.id_cat == 1009) {
      this.catalogo_cat = "Adhesivos";
    } else if (this.id_cat == 1013) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    } else if (this.id_cat == 1018 || this.id_cat == 850) {
      this.catalogo_cat = "Accesorios para fotografía y video";
    } else if (this.id_cat == 1024) {
      this.catalogo_cat = "Vinil textil";
    } else if (this.id_cat == 1027) {
      this.catalogo_cat = "Vinil textil";
    } else if (this.id_cat == 1029 || this.id_cat == 403) {
      this.catalogo_cat = "Accesorios para planchas";
    } else if (this.id_cat == 1031) {
      this.catalogo_cat = "Foil";
    } else if (this.id_cat == 1033) {
      this.catalogo_cat = "Herramientas para vinil";
    } else if (this.id_cat == 1034) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    } else if (this.id_cat == 1040 || this.id_cat == 1100) {
      this.catalogo_cat = "Textiles";
    } else if (this.id_cat == 1050) {
      this.catalogo_cat = "Stickers";
    } else if (this.id_cat == 1064 || this.id_cat == 1155) {
      this.catalogo_cat = "Foil";
    } else if (this.id_cat == 1067) {
      this.catalogo_cat = "Productos para foil";
    } else if (this.id_cat == 1071) {
      this.catalogo_cat = "Sublimación";
    } else if (this.id_cat == 1078) {
      this.catalogo_cat = "Novart";
    } else if (this.id_cat == 1080 || this.id_cat == 1101 || this.id_cat == 1102 || this.id_cat == 1105) {
      this.catalogo_cat = "Viniles y materiales para rotulación";
    } else if (this.id_cat == 1083 || this.id_cat == 1110) {
      this.catalogo_cat = "Papeles, cartulinas y cartones";
    } else if (this.id_cat == 1090 || this.id_cat == 410) {
      this.catalogo_cat = "Sublimación";
    } else if (this.id_cat == 1091) {
      this.catalogo_cat = "Viniles y materiales para rotulación";
    } else if (this.id_cat == 1092) {
      this.catalogo_cat = "Accesorios para plotters";
    } else if (this.id_cat == 1096 || this.id_cat == 399) {
      this.catalogo_cat = "Plotters de corte";
    } else if (this.id_cat == 1097 || this.id_cat == 1111) {
      this.catalogo_cat = "Plumas, marcadores y rotuladores";
    } else if (this.id_cat == 11) {
      this.catalogo_cat = "Por marca";
    } else if (this.id_cat == 1118) {
      this.catalogo_cat = "Stahls";
    } else if (this.id_cat == 1138) {
      this.catalogo_cat = "Perforadoras crop a dile";
    } else if (this.id_cat == 1145) {
      this.catalogo_cat = "Decoración ambiental";
    } else if (this.id_cat == 1146) {
      this.catalogo_cat = "Rotulación";
    } else if (this.id_cat == 1147) {
      this.catalogo_cat = "Impresión textil";
    } else if (this.id_cat == 1148) {
      this.catalogo_cat = "Estampado textil";
    } else if (this.id_cat == 1149) {
      this.catalogo_cat = "Troquelado y repujado";
    } else if (this.id_cat == 1151 || this.id_cat == 1819 || this.id_cat == 1931 || this.id_cat == 527) {
      this.catalogo_cat = "Encuadernado";
    } else if (this.id_cat == 1153) {
      this.catalogo_cat = "Pintura";
    } else if (this.id_cat == 1154) {
      this.catalogo_cat = "Modelado de acrílico";
    } else if (this.id_cat == 1171) {
      this.catalogo_cat = "Poliéster";
    } else if (this.id_cat == 12) {
      this.catalogo_cat = "Por técnica";
    } else if (this.id_cat == 1215) {
      this.catalogo_cat = "Paquetes aniversario";
    } else if (this.id_cat == 1327 || this.id_cat == 1393) {
      this.catalogo_cat = "Octubre rosa";
    } else if (this.id_cat == 1419 || this.id_cat == 358) {
      this.catalogo_cat = "Halloweeen";
    } else if (this.id_cat == 142) {
      this.catalogo_cat = "Pinmaker";
    } else if (this.id_cat == 146) {
      this.catalogo_cat = "Skytone";
    } else if (this.id_cat == 148) {
      this.catalogo_cat = "Stardream";
    } else if (this.id_cat == 150) {
      this.catalogo_cat = "Main tape";
    } else if (this.id_cat == 1522) {
      this.catalogo_cat = "Super fin";
    } else if (this.id_cat == 1661) {
      this.catalogo_cat = "Navidad";
    } else if (this.id_cat == 1666) {
      this.catalogo_cat = "Magic dot";
    } else if (this.id_cat == 1711) {
      this.catalogo_cat = "Silhouette cameo 5";
    } else if (this.id_cat == 178) {
      this.catalogo_cat = "Otras marcas";
    } else if (this.id_cat == 1794) {
      this.catalogo_cat = "Schmetz";
    } else if (this.id_cat == 1806) {
      this.catalogo_cat = "Viniles para decorar Cameo";
    } else if (this.id_cat == 1829) {
      this.catalogo_cat = "Pa´quete pagues la vacación";
    } else if (this.id_cat == 186) {
      this.catalogo_cat = "Fiesta";
    } else if (this.id_cat == 188) {
      this.catalogo_cat = "Astroparche";
    } else if (this.id_cat == 2) {
      this.catalogo_cat = "Home";
    } else if (this.id_cat == 2021) {
      this.catalogo_cat = "Promos Wer";
    } else if (this.id_cat == 204) {
      this.catalogo_cat = "Mes patrio";
    } else if (this.id_cat == 2082) {
      this.catalogo_cat = "Handi print";
    } else if (this.id_cat == 2105) {
      this.catalogo_cat = "Nuevos paquetes";
    } else if (this.id_cat == 226) {
      this.catalogo_cat = "Semana scrapbook";
    } else if (this.id_cat == 284) {
      this.catalogo_cat = "Descuentos";
    } else if (this.id_cat == 285) {
      this.catalogo_cat = "Semana rosa";
    } else if (this.id_cat == 320) {
      this.catalogo_cat = "Semana azul";
    } else if (this.id_cat == 381) {
      this.catalogo_cat = "Buen fin";
    } else if (this.id_cat == 413 || this.id_cat == 414) {
      this.catalogo_cat = "Cricut tintas para sublimación";
    } else if (this.id_cat == 415) {
      this.catalogo_cat = "Cricut vinil textil";
    } else if (this.id_cat == 416) {
      this.catalogo_cat = "Silhouette accesorios para plotters";
    } else if (this.id_cat == 417) {
      this.catalogo_cat = "Silhouette bisuteria";
    } else if (this.id_cat == 418) {
      this.catalogo_cat = "Silhouette costura";
    } else if (this.id_cat == 419) {
      this.catalogo_cat = "Silhouette cubiertas totes";
    } else if (this.id_cat == 420) {
      this.catalogo_cat = "Silhouette embossing";
    } else if (this.id_cat == 421 || this.id_cat == 803) {
      this.catalogo_cat = "Silhouette herramientas para depilacion y aplicacion de vinil";
    } else if (this.id_cat == 422) {
      this.catalogo_cat = "Silhouette hoja transportadora tapetes";
    } else if (this.id_cat == 423) {
      this.catalogo_cat = "Silhouette impresion 3d equipos y accesorios";
    } else if (this.id_cat == 424) {
      this.catalogo_cat = "Silhouette impresion 3d filamentos";
    } else if (this.id_cat == 425) {
      this.catalogo_cat = "Silhouette navajas y accesorios para plotter de corte";
    } else if (this.id_cat == 426) {
      this.catalogo_cat = "Silhouette papeles de transferencia";
    } else if (this.id_cat == 427) {
      this.catalogo_cat = "Silhouette papeles cartulinas y mas";
    } else if (this.id_cat == 428) {
      this.catalogo_cat = "Silhouette plotter de corte";
    } else if (this.id_cat == 429) {
      this.catalogo_cat = "Silhouette plumas y marcadores";
    } else if (this.id_cat == 430) {
      this.catalogo_cat = "Silhouette resina";
    } else if (this.id_cat == 431) {
      this.catalogo_cat = "Silhouette sellos";
    } else if (this.id_cat == 432) {
      this.catalogo_cat = "Silhouette serigrafia casera";
    } else if (this.id_cat == 433) {
      this.catalogo_cat = "Silhouette software y digital";
    } else if (this.id_cat == 434) {
      this.catalogo_cat = "Silhouette vinil y papel para rotulacion";
    } else if (this.id_cat == 435) {
      this.catalogo_cat = "Sizzix troqueladoras";
    } else if (this.id_cat == 436) {
      this.catalogo_cat = "Sizzix herramientas troquelado";
    } else if (this.id_cat == 437) {
      this.catalogo_cat = "Sizzix bisuteria";
    } else if (this.id_cat == 438) {
      this.catalogo_cat = "Sizzix troqueles";
    } else if (this.id_cat == 439) {
      this.catalogo_cat = "Sizzix embossing";
    } else if (this.id_cat == 440) {
      this.catalogo_cat = "Wer ojillos";
    } else if (this.id_cat == 441) {
      this.catalogo_cat = "Wer plumas quill";
    } else if (this.id_cat == 443) {
      this.catalogo_cat = "Wer foil quill";
    } else if (this.id_cat == 444) {
      this.catalogo_cat = "Wer plumas y marcadores";
    } else if (this.id_cat == 445) {
      this.catalogo_cat = "Wer accesorios para video y fotografia";
    } else if (this.id_cat == 446) {
      this.catalogo_cat = "Wer engargolado";
    } else if (this.id_cat == 447) {
      this.catalogo_cat = "Wer fotobotones";
    } else if (this.id_cat == 448) {
      this.catalogo_cat = "Wer herramientas de corte manual";
    } else if (this.id_cat == 449) {
      this.catalogo_cat = "Wer moldes de plastico";
    } else if (this.id_cat == 450) {
      this.catalogo_cat = "Wer navajas y accesorios para plotter de corte";
    } else if (this.id_cat == 451) {
      this.catalogo_cat = "Wer papeles cartulinas y más";
    } else if (this.id_cat == 452) {
      this.catalogo_cat = "Wer perforadoras";
    } else if (this.id_cat == 454) {
      this.catalogo_cat = "Wer sellos";
    } else if (this.id_cat == 455) {
      this.catalogo_cat = "Wer sublimacion blancos";
    } else if (this.id_cat == 456) {
      this.catalogo_cat = "Wer tabletas para trazado y depilado";
    } else if (this.id_cat == 457) {
      this.catalogo_cat = "Sawgrass sublimacion impresora";
    } else if (this.id_cat == 458) {
      this.catalogo_cat = "Sawgrass sublimacion papel";
    } else if (this.id_cat == 459) {
      this.catalogo_cat = "Sawgrass tintas para sublimacion";
    } else if (this.id_cat == 460) {
      this.catalogo_cat = "Doodlebug sellos";
    } else if (this.id_cat == 461) {
      this.catalogo_cat = "Doodlebug tape decorativo";
    } else if (this.id_cat == 462) {
      this.catalogo_cat = "Doodlebug papeles cartulinas y mas";
    } else if (this.id_cat == 463) {
      this.catalogo_cat = "Sublimarts accesorios para planchas";
    } else if (this.id_cat == 464) {
      this.catalogo_cat = "Sublimarts papeles de transferencia";
    } else if (this.id_cat == 465) {
      this.catalogo_cat = "Sublimarts planchas de calor";
    } else if (this.id_cat == 466) {
      this.catalogo_cat = "Sublimarts sublimación blancos";
    } else if (this.id_cat == 468) {
      this.catalogo_cat = "Minc foil reactivo impresion laser";
    } else if (this.id_cat == 469) {
      this.catalogo_cat = "Minc laminadoras";
    } else if (this.id_cat == 470) {
      this.catalogo_cat = "Minc pintura y pinceles";
    } else if (this.id_cat == 471) {
      this.catalogo_cat = "Minc productos aplicacion foil reactivo laser";
    } else if (this.id_cat == 472 || this.id_cat == 477) {
      this.catalogo_cat = "Adhesivos";
    } else if (this.id_cat == 490) {
      this.catalogo_cat = "Arte y pintura";
    } else if (this.id_cat == 492) {
      this.catalogo_cat = "Escolar y oficina";
    } else if (this.id_cat == 493) {
      this.catalogo_cat = "Paquetes silhouette";
    } else if (this.id_cat == 529) {
      this.catalogo_cat = "Cajas misteriosas cricut";
    } else if (this.id_cat == 531) {
      this.catalogo_cat = "Dia del maestro";
    } else if (this.id_cat == 586) {
      this.catalogo_cat = "Back to school";
    } else if (this.id_cat == 650) {
      this.catalogo_cat = "Foil reactivo impresión láser";
    } else if (this.id_cat == 736) {
      this.catalogo_cat = "Promociones decoración foil";
    } else if (this.id_cat == 753) {
      this.catalogo_cat = "Día de las madres";
    } else if (this.id_cat == 798) {
      this.catalogo_cat = "Foil luminos holográfico";
    } else if (this.id_cat == 799) {
      this.catalogo_cat = "Foil luminos sálidos";
    }


    if (this.id_cat == 405) {
      this.titleService.setTitle('Cubiertas, totes 2');
      this.metaService.addTag({ name: 'description', content: 'Cubiertas, totes 2' });
    } else if (this.id_cat == 1017) {
      this.titleService.setTitle('Washi tape 2');
      this.metaService.addTag({ name: 'description', content: 'Washi tape 2' });
    } else if (this.id_cat == 1053) {
      this.titleService.setTitle('Washi tape 3');
      this.metaService.addTag({ name: 'description', content: 'Washi tape 3' });
    } else if (this.id_cat == 1069) {
      this.titleService.setTitle('Washi tape 4');
      this.metaService.addTag({ name: 'description', content: 'Washi tape 4' });
    } else if (this.id_cat == 120) {
      this.titleService.setTitle('Moldes de plástico 2');
      this.metaService.addTag({ name: 'description', content: 'Moldes de plástico 2' });
    } else if (this.id_cat == 1033) {
      this.titleService.setTitle('Herramientas para vinil 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para vinil 2' });
    } else if (this.id_cat == 1135) {
      this.titleService.setTitle('Herramientas para vinil 3');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para vinil 3' });
    } else if (this.id_cat == 1953) {
      this.titleService.setTitle('Herramientas para vinil 4');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para vinil 4' });
    } else if (this.id_cat == 1958) {
      this.titleService.setTitle('Consumibles para 3D 2');
      this.metaService.addTag({ name: 'description', content: 'Consumibles para 3D 2' });
    } else if (this.id_cat == 1080) {
      this.titleService.setTitle('Viniles y materiales para rotulación 2');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 2' });
    } else if (this.id_cat == 1105) {
      this.titleService.setTitle('Viniles y materiales para rotulación 3');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 3' });
    } else if (this.id_cat == 1962) {
      this.titleService.setTitle('Viniles y materiales para rotulación 4');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 4' });
    } else if (this.id_cat == 2001) {
      this.titleService.setTitle('Viniles y materiales para rotulación 5');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 5' });
    } else if (this.id_cat == 1102) {
      this.titleService.setTitle('Viniles y materiales para rotulación 6');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 6' });
    } else if (this.id_cat == 1101) {
      this.titleService.setTitle('Viniles y materiales para rotulación 7');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 7' });
    } else if (this.id_cat == 1091) {
      this.titleService.setTitle('Viniles y materiales para rotulación 8');
      this.metaService.addTag({ name: 'description', content: 'Viniles y materiales para rotulación 8' });
    } else if (this.id_cat == 2049) {
      this.titleService.setTitle('Hojas de aluminio 2');
      this.metaService.addTag({ name: 'description', content: 'Hojas de aluminio 2' });
    } else if (this.id_cat == 2025) {
      this.titleService.setTitle('Textiles 2');
      this.metaService.addTag({ name: 'description', content: 'Textiles 2' });
    } else if (this.id_cat == 1074) {
      this.titleService.setTitle('Textiles 3');
      this.metaService.addTag({ name: 'description', content: 'Textiles 3' });
    } else if (this.id_cat == 1175) {
      this.titleService.setTitle('Textiles 4');
      this.metaService.addTag({ name: 'description', content: 'Textiles 4' });
    } else if (this.id_cat == 1955) {
      this.titleService.setTitle('Textiles 5');
      this.metaService.addTag({ name: 'description', content: 'Textiles 5' });
    } else if (this.id_cat == 1100) {
      this.titleService.setTitle('Textiles 6');
      this.metaService.addTag({ name: 'description', content: 'Textiles 6' });
    } else if (this.id_cat == 1822) {
      this.titleService.setTitle('Pines 2');
      this.metaService.addTag({ name: 'description', content: 'Pines 2' });
    } else if (this.id_cat == 1905) {
      this.titleService.setTitle('Aditamentos 2');
      this.metaService.addTag({ name: 'description', content: 'Aditamentos 2' });
    } else if (this.id_cat == 1019) {
      this.titleService.setTitle('Grabado en cristal 2');
      this.metaService.addTag({ name: 'description', content: 'Grabado en cristal 2' });
    } else if (this.id_cat == 931) {
      this.titleService.setTitle('Herramientas 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas 2' });
    } else if (this.id_cat == 2006) {
      this.titleService.setTitle('Herramientas 3');
      this.metaService.addTag({ name: 'description', content: 'Herramientas 3' });
    } else if (this.id_cat == 1991) {
      this.titleService.setTitle('Herramientas 4');
      this.metaService.addTag({ name: 'description', content: 'Herramientas 4' });
    } else if (this.id_cat == 1987) {
      this.titleService.setTitle('Herramientas 5');
      this.metaService.addTag({ name: 'description', content: 'Herramientas 5' });
    } else if (this.id_cat == 1059) {
      this.titleService.setTitle('Texturizadores 2');
      this.metaService.addTag({ name: 'description', content: 'Texturizadores 2' });
    } else if (this.id_cat == 1086) {
      this.titleService.setTitle('Texturizadores 3');
      this.metaService.addTag({ name: 'description', content: 'Texturizadores 3' });
    } else if (this.id_cat == 1045) {
      this.titleService.setTitle('Tarjetas 2');
      this.metaService.addTag({ name: 'description', content: 'Tarjetas 2' });
    } else if (this.id_cat == 1168) {
      this.titleService.setTitle('Tarjetas 3');
      this.metaService.addTag({ name: 'description', content: 'Tarjetas 3' });
    } else if (this.id_cat == 1051) {
      this.titleService.setTitle('Tarjetas 4');
      this.metaService.addTag({ name: 'description', content: 'Tarjetas 4' });
    } else if (this.id_cat == 1039) {
      this.titleService.setTitle('Tarjetas 5');
      this.metaService.addTag({ name: 'description', content: 'Tarjetas 5' });
    } else if (this.id_cat == 1977) {
      this.titleService.setTitle('Polvos 2');
      this.metaService.addTag({ name: 'description', content: 'Polvos 2' });
    } else if (this.id_cat == 180) {
      this.titleService.setTitle('American Crafts 2');
      this.metaService.addTag({ name: 'description', content: 'American Crafts 2' });
    } else if (this.id_cat == 157) {
      this.titleService.setTitle('WeR Memory Keepers 2');
      this.metaService.addTag({ name: 'description', content: 'WeR Memory Keepers 2' });
    } else if (this.id_cat == 1970) {
      this.titleService.setTitle('WeR Memory Keepers 3');
      this.metaService.addTag({ name: 'description', content: 'WeR Memory Keepers 3' });
    } else if (this.id_cat == 1046) {
      this.titleService.setTitle('Albums 2');
      this.metaService.addTag({ name: 'description', content: 'Albums 2' });
    } else if (this.id_cat == 1128) {
      this.titleService.setTitle('Albums 3');
      this.metaService.addTag({ name: 'description', content: 'Albums 3' });
    } else if (this.id_cat == 1937) {
      this.titleService.setTitle('Creality 2');
      this.metaService.addTag({ name: 'description', content: 'Creality 2' });
    } else if (this.id_cat == 189) {
      this.titleService.setTitle('Colortex 2');
      this.metaService.addTag({ name: 'description', content: 'Colortex 2' });
    } else if (this.id_cat == 407) {
      this.titleService.setTitle('Herramientas para depilación y aplicación de vinil 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para depilación y aplicación de vinil 2' });
    } else if (this.id_cat == 129) {
      this.titleService.setTitle('Categoría Lideart');
      this.metaService.addTag({ name: 'description', content: 'Categoría Lideart' });
    } else if (this.id_cat == 1167) {
      this.titleService.setTitle('Semana minc Lideart');
      this.metaService.addTag({ name: 'description', content: 'Semana minc Lideart' });
    } else if (this.id_cat == 155) {
      this.titleService.setTitle('Productos nuevos Lideart');
      this.metaService.addTag({ name: 'description', content: 'Productos nuevos Lideart' });
    } else if (this.id_cat == 2081) {
      this.titleService.setTitle('Handi print Lideart');
      this.metaService.addTag({ name: 'description', content: 'Handi print Lideart' });
    } else if (this.id_cat == 1963) {
      this.titleService.setTitle('Foil Quill 2');
      this.metaService.addTag({ name: 'description', content: 'Foil Quill 2' });
    } else if (this.id_cat == 1982) {
      this.titleService.setTitle('Foil Quill 3');
      this.metaService.addTag({ name: 'description', content: 'Foil Quill 3' });
    } else if (this.id_cat == 411) {
      this.titleService.setTitle('Sublimación blancos 2');
      this.metaService.addTag({ name: 'description', content: 'Sublimación blancos 2' });
    } else if (this.id_cat == 1115) {
      this.titleService.setTitle('Stencil 2');
      this.metaService.addTag({ name: 'description', content: 'Stencil 2' });
    } else if (this.id_cat == 2050) {
      this.titleService.setTitle('Stencil 3');
      this.metaService.addTag({ name: 'description', content: 'Stencil 3' });
    } else if (this.id_cat == 1949) {
      this.titleService.setTitle('Stencil 4');
      this.metaService.addTag({ name: 'description', content: 'Stencil 4' });
    } else if (this.id_cat == 1047) {
      this.titleService.setTitle('Artículos de papelería 2');
      this.metaService.addTag({ name: 'description', content: 'Artículos de papelería 2' });
    } else if (this.id_cat == 794) {
      this.titleService.setTitle('Bisutería 2');
      this.metaService.addTag({ name: 'description', content: 'Bisutería 2' });
    } else if (this.id_cat == 919) {
      this.titleService.setTitle('Troqueladoras 2');
      this.metaService.addTag({ name: 'description', content: 'Troqueladoras 2' });
    } else if (this.id_cat == 1116) {
      this.titleService.setTitle('Troqueladoras 3');
      this.metaService.addTag({ name: 'description', content: 'Troqueladoras 3' });
    } else if (this.id_cat == 2028) {
      this.titleService.setTitle('Consumibles y accesorios para engargolado 2');
      this.metaService.addTag({ name: 'description', content: 'Consumibles y accesorios para engargolado 2' });
    } else if (this.id_cat == 1094) {
      this.titleService.setTitle('Cursos 2');
      this.metaService.addTag({ name: 'description', content: 'Cursos 2' });
    } else if (this.id_cat == 1058) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 2');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 2' });
    } else if (this.id_cat == 1948) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 3');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 3' });
    } else if (this.id_cat == 1426) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 4');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 4' });
    } else if (this.id_cat == 2019) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 5');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 5' });
    } else if (this.id_cat == 1097) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 6');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 6' });
    } else if (this.id_cat == 1037) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 7');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 7' });
    } else if (this.id_cat == 1961) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 8');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 8' });
    } else if (this.id_cat == 1111) {
      this.titleService.setTitle('Plumas, marcadores y rotuladores 9');
      this.metaService.addTag({ name: 'description', content: 'Plumas, marcadores y rotuladores 9' });
    } else if (this.id_cat == 401) {
      this.titleService.setTitle('Plumas y marcadores 2');
      this.metaService.addTag({ name: 'description', content: 'Plumas y marcadores 2' });
    } else if (this.id_cat == 475) {
      this.titleService.setTitle('Plumas y marcadores 3');
      this.metaService.addTag({ name: 'description', content: 'Plumas y marcadores 3' });
    } else if (this.id_cat == 121) {
      this.titleService.setTitle('Impresión de tarjetas 2');
      this.metaService.addTag({ name: 'description', content: 'Impresión de tarjetas 2' });
    } else if (this.id_cat == 1136) {
      this.titleService.setTitle('Organizadores 2');
      this.metaService.addTag({ name: 'description', content: 'Organizadores 2' });
    } else if (this.id_cat == 933) {
      this.titleService.setTitle('Organizadores 3');
      this.metaService.addTag({ name: 'description', content: 'Organizadores 3' });
    } else if (this.id_cat == 1109) {
      this.titleService.setTitle('Organizadores 4');
      this.metaService.addTag({ name: 'description', content: 'Organizadores 4' });
    } else if (this.id_cat == 1010) {
      this.titleService.setTitle('Organizadores 5');
      this.metaService.addTag({ name: 'description', content: 'Organizadores 5' });
    } else if (this.id_cat == 1066) {
      this.titleService.setTitle('Organizadores 6');
      this.metaService.addTag({ name: 'description', content: 'Organizadores 6' });
    } else if (this.id_cat == 1063) {
      this.titleService.setTitle('Grafimag 2');
      this.metaService.addTag({ name: 'description', content: 'Grafimag 2' });
    } else if (this.id_cat == 890) {
      this.titleService.setTitle('KITS 2');
      this.metaService.addTag({ name: 'description', content: 'KITS 2' });
    } else if (this.id_cat == 922) {
      this.titleService.setTitle('KITS 3');
      this.metaService.addTag({ name: 'description', content: 'KITS 3' });
    } else if (this.id_cat == 1940) {
      this.titleService.setTitle('Decoraciones 2');
      this.metaService.addTag({ name: 'description', content: 'Decoraciones 2' });
    } else if (this.id_cat == 1070) {
      this.titleService.setTitle('Hotronix 2');
      this.metaService.addTag({ name: 'description', content: 'Hotronix 2' });
    } else if (this.id_cat == 1050) {
      this.titleService.setTitle('Stickers 2');
      this.metaService.addTag({ name: 'description', content: 'Stickers 2' });
    } else if (this.id_cat == 1015) {
      this.titleService.setTitle('Stickers 3');
      this.metaService.addTag({ name: 'description', content: 'Stickers 3' });
    } else if (this.id_cat == 1143) {
      this.titleService.setTitle('Stickers 4');
      this.metaService.addTag({ name: 'description', content: 'Stickers 4' });
    } else if (this.id_cat == 1056) {
      this.titleService.setTitle('Herramientas para troquelado 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para troquelado 2' });
    } else if (this.id_cat == 1043) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 2');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 2' });
    } else if (this.id_cat == 1947) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 3');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 3' });
    } else if (this.id_cat == 1025) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 4');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 4' });
    } else if (this.id_cat == 1964) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 5');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 5' });
    } else if (this.id_cat == 1048) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 6');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 6' });
    } else if (this.id_cat == 1034) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 7');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 7' });
    } else if (this.id_cat == 2020) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 8');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 8' });
    } else if (this.id_cat == 1110) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 9');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 9' });
    } else if (this.id_cat == 1083) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 10');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 10' });
    } else if (this.id_cat == 1011) {
      this.titleService.setTitle('Papeles, cartulinas y cartones 11');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y cartones 11' });
    } else if (this.id_cat == 2045) {
      this.titleService.setTitle('Aplicación 2');
      this.metaService.addTag({ name: 'description', content: 'Aplicación 2' });
    } else if (this.id_cat == 1152) {
      this.titleService.setTitle('Resina 2');
      this.metaService.addTag({ name: 'description', content: 'Resina 2' });
    } else if (this.id_cat == 491) {
      this.titleService.setTitle('Resina 3');
      this.metaService.addTag({ name: 'description', content: 'Resina 3' });
    } else if (this.id_cat == 2003) {
      this.titleService.setTitle('Resina 4');
      this.metaService.addTag({ name: 'description', content: 'Resina 4' });
    } else if (this.id_cat == 804) {
      this.titleService.setTitle('Resina 5');
      this.metaService.addTag({ name: 'description', content: 'Resina 5' });
    } else if (this.id_cat == 1023) {
      this.titleService.setTitle('Resina 6');
      this.metaService.addTag({ name: 'description', content: 'Resina 6' });
    } else if (this.id_cat == 1994) {
      this.titleService.setTitle('Resina 7');
      this.metaService.addTag({ name: 'description', content: 'Resina 7' });
    } else if (this.id_cat == 1140) {
      this.titleService.setTitle('Resina 8');
      this.metaService.addTag({ name: 'description', content: 'Resina 8' });
    } else if (this.id_cat == 179) {
      this.titleService.setTitle('Color Pour 2');
      this.metaService.addTag({ name: 'description', content: 'Color Pour 2' });
    } else if (this.id_cat == 1081) {
      this.titleService.setTitle('Adhesivos 2');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 2' });
    } else if (this.id_cat == 1054) {
      this.titleService.setTitle('Adhesivos 3');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 3' });
    } else if (this.id_cat == 477) {
      this.titleService.setTitle('Adhesivos 4');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 4' });
    } else if (this.id_cat == 472) {
      this.titleService.setTitle('Adhesivos 5');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 5' });
    } else if (this.id_cat == 1009) {
      this.titleService.setTitle('Adhesivos 6');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 6' });
    } else if (this.id_cat == 2015) {
      this.titleService.setTitle('Adhesivos 7');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 7' });
    } else if (this.id_cat == 1062) {
      this.titleService.setTitle('Adhesivos 8');
      this.metaService.addTag({ name: 'description', content: 'Adhesivos 8' });
    } else if (this.id_cat == 1393) {
      this.titleService.setTitle('Octubre rosa 2');
      this.metaService.addTag({ name: 'description', content: 'Octubre rosa 2' });
    } else if (this.id_cat == 356) {
      this.titleService.setTitle('Decoración 2');
      this.metaService.addTag({ name: 'description', content: 'Decoración 2' });
    } else if (this.id_cat == 473) {
      this.titleService.setTitle('Decoración 3');
      this.metaService.addTag({ name: 'description', content: 'Decoración 3' });
    } else if (this.id_cat == 2047) {
      this.titleService.setTitle('Listones 2');
      this.metaService.addTag({ name: 'description', content: 'Listones 2' });
    } else if (this.id_cat == 2002) {
      this.titleService.setTitle('Crop a diles 2');
      this.metaService.addTag({ name: 'description', content: 'Crop a diles 2' });
    } else if (this.id_cat == 1114) {
      this.titleService.setTitle('Shakers 2');
      this.metaService.addTag({ name: 'description', content: 'Shakers 2' });
    } else if (this.id_cat == 1035) {
      this.titleService.setTitle('Planchas de calor 2');
      this.metaService.addTag({ name: 'description', content: 'Planchas de calor 2' });
    } else if (this.id_cat == 1120) {
      this.titleService.setTitle('Planchas de calor 3');
      this.metaService.addTag({ name: 'description', content: 'Planchas de calor 3' });
    } else if (this.id_cat == 1073) {
      this.titleService.setTitle('Planchas de calor 4');
      this.metaService.addTag({ name: 'description', content: 'Planchas de calor 4' });
    } else if (this.id_cat == 400) {
      this.titleService.setTitle('Planchas de calor 5');
      this.metaService.addTag({ name: 'description', content: 'Planchas de calor 5' });
    } else if (this.id_cat == 881) {
      this.titleService.setTitle('Kronaline 2');
      this.metaService.addTag({ name: 'description', content: 'Kronaline 2' });
    } else if (this.id_cat == 166) {
      this.titleService.setTitle('Gütermann 2');
      this.metaService.addTag({ name: 'description', content: 'Gütermann 2' });
    } else if (this.id_cat == 1938) {
      this.titleService.setTitle('Silhouette 2');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 2' });
    } else if (this.id_cat == 1923) {
      this.titleService.setTitle('Silhouette 3');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 3' });
    } else if (this.id_cat == 1921) {
      this.titleService.setTitle('Silhouette 4');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 4' });
    } else if (this.id_cat == 1915) {
      this.titleService.setTitle('Silhouette 5');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 5' });
    } else if (this.id_cat == 869) {
      this.titleService.setTitle('Silhouette 6');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 6' });
    } else if (this.id_cat == 1969) {
      this.titleService.setTitle('Silhouette 7');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 7' });
    } else if (this.id_cat == 1980) {
      this.titleService.setTitle('Silhouette 8');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 8' });
    } else if (this.id_cat == 156) {
      this.titleService.setTitle('Silhouette 9');
      this.metaService.addTag({ name: 'description', content: 'Silhouette 9' });
    } else if (this.id_cat == 1061) {
      this.titleService.setTitle('Gisarron 2');
      this.metaService.addTag({ name: 'description', content: 'Gisarron 2' });
    } else if (this.id_cat == 117) {
      this.titleService.setTitle('Costura 2');
      this.metaService.addTag({ name: 'description', content: 'Costura 2' });
    } else if (this.id_cat == 1925) {
      this.titleService.setTitle('Costura 3');
      this.metaService.addTag({ name: 'description', content: 'Costura 3' });
    } else if (this.id_cat == 404) {
      this.titleService.setTitle('Costura 4');
      this.metaService.addTag({ name: 'description', content: 'Costura 4' });
    } else if (this.id_cat == 1075) {
      this.titleService.setTitle('Foil 2');
      this.metaService.addTag({ name: 'description', content: 'Foil 2' });
    } else if (this.id_cat == 1952) {
      this.titleService.setTitle('Foil 3');
      this.metaService.addTag({ name: 'description', content: 'Foil 3' });
    } else if (this.id_cat == 1155) {
      this.titleService.setTitle('Foil 4');
      this.metaService.addTag({ name: 'description', content: 'Foil 4' });
    } else if (this.id_cat == 1031) {
      this.titleService.setTitle('Foil 5');
      this.metaService.addTag({ name: 'description', content: 'Foil 5' });
    } else if (this.id_cat == 1996) {
      this.titleService.setTitle('Foil 6');
      this.metaService.addTag({ name: 'description', content: 'Foil 6' });
    } else if (this.id_cat == 1064) {
      this.titleService.setTitle('Foil 7');
      this.metaService.addTag({ name: 'description', content: 'Foil 7' });
    } else if (this.id_cat == 1126) {
      this.titleService.setTitle('Troqueles 2');
      this.metaService.addTag({ name: 'description', content: 'Troqueles 2' });
    } else if (this.id_cat == 1117) {
      this.titleService.setTitle('Troqueles 3');
      this.metaService.addTag({ name: 'description', content: 'Troqueles 3' });
    } else if (this.id_cat == 2033) {
      this.titleService.setTitle('Troqueles 4');
      this.metaService.addTag({ name: 'description', content: 'Troqueles 4' });
    } else if (this.id_cat == 1052) {
      this.titleService.setTitle('Troqueles 5');
      this.metaService.addTag({ name: 'description', content: 'Troqueles 5' });
    } else if (this.id_cat == 920) {
      this.titleService.setTitle('Troqueles 6');
      this.metaService.addTag({ name: 'description', content: 'Troqueles 6' });
    } else if (this.id_cat == 2036) {
      this.titleService.setTitle('Masking 2');
      this.metaService.addTag({ name: 'description', content: 'Masking 2' });
    } else if (this.id_cat == 2023) {
      this.titleService.setTitle('Vinil textil 2');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 2' });
    } else if (this.id_cat == 1027) {
      this.titleService.setTitle('Vinil textil 3');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 3' });
    } else if (this.id_cat == 1104) {
      this.titleService.setTitle('Vinil textil 4');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 4' });
    } else if (this.id_cat == 1024) {
      this.titleService.setTitle('Vinil textil 5');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 5' });
    } else if (this.id_cat == 1926) {
      this.titleService.setTitle('Vinil textil 6');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 6' });
    } else if (this.id_cat == 2022) {
      this.titleService.setTitle('Vinil textil 7');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 7' });
    } else if (this.id_cat == 1041) {
      this.titleService.setTitle('Vinil textil 8');
      this.metaService.addTag({ name: 'description', content: 'Vinil textil 8' });
    } else if (this.id_cat == 90) {
      this.titleService.setTitle('Engargolado 2');
      this.metaService.addTag({ name: 'description', content: 'Engargolado 2' });
    } else if (this.id_cat == 1899) {
      this.titleService.setTitle('Cartulinas decoradas 2');
      this.metaService.addTag({ name: 'description', content: 'Cartulinas decoradas 2' });
    } else if (this.id_cat == 1972) {
      this.titleService.setTitle('Sublimarts 2');
      this.metaService.addTag({ name: 'description', content: 'Sublimarts 2' });
    } else if (this.id_cat == 172) {
      this.titleService.setTitle('Sublimarts 3');
      this.metaService.addTag({ name: 'description', content: 'Sublimarts 3' });
    } else if (this.id_cat == 1106) {
      this.titleService.setTitle('Embossing 2');
      this.metaService.addTag({ name: 'description', content: 'Embossing 2' });
    } else if (this.id_cat == 474) {
      this.titleService.setTitle('Embossing 3');
      this.metaService.addTag({ name: 'description', content: 'Embossing 3' });
    } else if (this.id_cat == 1082) {
      this.titleService.setTitle('Embossing 4');
      this.metaService.addTag({ name: 'description', content: 'Embossing 4' });
    } else if (this.id_cat == 1150) {
      this.titleService.setTitle('Embossing 5');
      this.metaService.addTag({ name: 'description', content: 'Embossing 5' });
    } else if (this.id_cat == 1077) {
      this.titleService.setTitle('Embossing 6');
      this.metaService.addTag({ name: 'description', content: 'Embossing 6' });
    } else if (this.id_cat == 2024) {
      this.titleService.setTitle('Sublimación 2');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 2' });
    } else if (this.id_cat == 1071) {
      this.titleService.setTitle('Sublimación 3');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 3' });
    } else if (this.id_cat == 1026) {
      this.titleService.setTitle('Sublimación 4');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 4' });
    } else if (this.id_cat == 410) {
      this.titleService.setTitle('Sublimación 5');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 5' });
    } else if (this.id_cat == 1121) {
      this.titleService.setTitle('Sublimación 6');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 6' });
    } else if (this.id_cat == 1090) {
      this.titleService.setTitle('Sublimación 7');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 7' });
    } else if (this.id_cat == 1038) {
      this.titleService.setTitle('Sublimación 8');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 8' });
    } else if (this.id_cat == 1103) {
      this.titleService.setTitle('Sublimación 9');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 9' });
    } else if (this.id_cat == 111) {
      this.titleService.setTitle('Sublimación 10');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 10' });
    } else if (this.id_cat == 1020) {
      this.titleService.setTitle('Sublimación 11');
      this.metaService.addTag({ name: 'description', content: 'Sublimación 11' });
    } else if (this.id_cat == 1084) {
      this.titleService.setTitle('Pinturas y pinceles 2');
      this.metaService.addTag({ name: 'description', content: 'Pinturas y pinceles 2' });
    } else if (this.id_cat == 1999) {
      this.titleService.setTitle('Consumible para plotter 2');
      this.metaService.addTag({ name: 'description', content: 'Consumible para plotter 2' });
    } else if (this.id_cat == 1141) {
      this.titleService.setTitle('Rhinestone 2');
      this.metaService.addTag({ name: 'description', content: 'Rhinestone 2' });
    } else if (this.id_cat == 1824) {
      this.titleService.setTitle('Rhinestone 3');
      this.metaService.addTag({ name: 'description', content: 'Rhinestone 3' });
    } else if (this.id_cat == 803) {
      this.titleService.setTitle('Silhouette herramientas para depilacion y aplicacion de vinil 2');
      this.metaService.addTag({ name: 'description', content: 'Silhouette herramientas para depilacion y aplicacion de vinil 2' });
    } else if (this.id_cat == 1216) {
      this.titleService.setTitle('Signcal 2');
      this.metaService.addTag({ name: 'description', content: 'Signcal 2' });
    } else if (this.id_cat == 1419) {
      this.titleService.setTitle('Halloweeen 2');
      this.metaService.addTag({ name: 'description', content: 'Halloweeen 2' });
    } else if (this.id_cat == 476) {
      this.titleService.setTitle('Perforadoras 2');
      this.metaService.addTag({ name: 'description', content: 'Perforadoras 2' });
    } else if (this.id_cat == 1137) {
      this.titleService.setTitle('Perforadoras 3');
      this.metaService.addTag({ name: 'description', content: 'Perforadoras 3' });
    } else if (this.id_cat == 1057) {
      this.titleService.setTitle('Perforadoras 4');
      this.metaService.addTag({ name: 'description', content: 'Perforadoras 4' });
    } else if (this.id_cat == 1939) {
      this.titleService.setTitle('Impresión 3D 2');
      this.metaService.addTag({ name: 'description', content: 'Impresión 3D 2' });
    } else if (this.id_cat == 2027) {
      this.titleService.setTitle('Impresión 3D 3');
      this.metaService.addTag({ name: 'description', content: 'Impresión 3D 3' });
    } else if (this.id_cat == 1959) {
      this.titleService.setTitle('Impresión 3D 4');
      this.metaService.addTag({ name: 'description', content: 'Impresión 3D 4' });
    } else if (this.id_cat == 1065) {
      this.titleService.setTitle('Laminadoras 2');
      this.metaService.addTag({ name: 'description', content: 'Laminadoras 2' });
    } else if (this.id_cat == 1076) {
      this.titleService.setTitle('Laminadoras 3');
      this.metaService.addTag({ name: 'description', content: 'Laminadoras 3' });
    } else if (this.id_cat == 1125) {
      this.titleService.setTitle('Versamark 2');
      this.metaService.addTag({ name: 'description', content: 'Versamark 2' });
    } else if (this.id_cat == 177) {
      this.titleService.setTitle('Sizzix 2');
      this.metaService.addTag({ name: 'description', content: 'Sizzix 2' });
    } else if (this.id_cat == 1107) {
      this.titleService.setTitle('Glitters 2');
      this.metaService.addTag({ name: 'description', content: 'Glitters 2' });
    } else if (this.id_cat == 1132) {
      this.titleService.setTitle('Glitters 3');
      this.metaService.addTag({ name: 'description', content: 'Glitters 3' });
    } else if (this.id_cat == 159) {
      this.titleService.setTitle('Sawgrass 2');
      this.metaService.addTag({ name: 'description', content: 'Sawgrass 2' });
    } else if (this.id_cat == 19) {
      this.titleService.setTitle('Paquetes en el catalogo');
      this.metaService.addTag({ name: 'description', content: 'Paquetes en el catalogo' });
    } else if (this.id_cat == 409) {
      this.titleService.setTitle('Papeles, cartulinas y más 2');
      this.metaService.addTag({ name: 'description', content: 'Papeles, cartulinas y más 2' });
    } else if (this.id_cat == 122) {
      this.titleService.setTitle('Herramientas para acrílico 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para acrílico 2' });
    } else if (this.id_cat == 1008) {
      this.titleService.setTitle('Herramientas para acrílico 3');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para acrílico 3' });
    } else if (this.id_cat == 1028) {
      this.titleService.setTitle('Grabado laser 2');
      this.metaService.addTag({ name: 'description', content: 'Grabado laser 2' });
    } else if (this.id_cat == 872) {
      this.titleService.setTitle('Grabado laser 3');
      this.metaService.addTag({ name: 'description', content: 'Grabado laser 3' });
    } else if (this.id_cat == 1178) {
      this.titleService.setTitle('Consumibles para plotter 2');
      this.metaService.addTag({ name: 'description', content: 'Consumibles para plotter 2' });
    } else if (this.id_cat == 414) {
      this.titleService.setTitle('Cricut tintas para sublimación 2');
      this.metaService.addTag({ name: 'description', content: 'Cricut tintas para sublimación 2' });
    } else if (this.id_cat == 1072) {
      this.titleService.setTitle('Accesorios para planchas 2');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para planchas 2' });
    } else if (this.id_cat == 1029) {
      this.titleService.setTitle('Accesorios para planchas 3');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para planchas 3' });
    } else if (this.id_cat == 403) {
      this.titleService.setTitle('Accesorios para planchas 4');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para planchas 4' });
    } else if (this.id_cat == 1950) {
      this.titleService.setTitle('Accesorios para planchas 5');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para planchas 5' });
    } else if (this.id_cat == 1018) {
      this.titleService.setTitle('Accesorios para fotografía y video 2');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para fotografía y video 2' });
    } else if (this.id_cat == 1127) {
      this.titleService.setTitle('Accesorios para fotografía y video 3');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para fotografía y video 3' });
    } else if (this.id_cat == 943) {
      this.titleService.setTitle('Almohadillas 2');
      this.metaService.addTag({ name: 'description', content: 'Almohadillas 2' });
    } else if (this.id_cat == 1973) {
      this.titleService.setTitle('Almohadillas 3');
      this.metaService.addTag({ name: 'description', content: 'Almohadillas 3' });
    } else if (this.id_cat == 133) {
      this.titleService.setTitle('Fotografía y Video 2');
      this.metaService.addTag({ name: 'description', content: 'Fotografía y Video 2' });
    } else if (this.id_cat == 904) {
      this.titleService.setTitle('Glitter 2');
      this.metaService.addTag({ name: 'description', content: 'Glitter 2' });
    } else if (this.id_cat == 1820) {
      this.titleService.setTitle('LAMINADORES 2');
      this.metaService.addTag({ name: 'description', content: 'LAMINADORES 2' });
    } else if (this.id_cat == 478) {
      this.titleService.setTitle('Herramientas de corte manual 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas de corte manual 2' });
    } else if (this.id_cat == 406) {
      this.titleService.setTitle('Herramientas de corte manual 3');
      this.metaService.addTag({ name: 'description', content: 'Herramientas de corte manual 3' });
    } else if (this.id_cat == 802) {
      this.titleService.setTitle('Herramientas de corte manual 4');
      this.metaService.addTag({ name: 'description', content: 'Herramientas de corte manual 4' });
    } else if (this.id_cat == 1957) {
      this.titleService.setTitle('Accesorios para plotters 2');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para plotters 2' });
    } else if (this.id_cat == 1092) {
      this.titleService.setTitle('Accesorios para plotters 3');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para plotters 3' });
    } else if (this.id_cat == 1951) {
      this.titleService.setTitle('Accesorios para plotters 4');
      this.metaService.addTag({ name: 'description', content: 'Accesorios para plotters 4' });
    } else if (this.id_cat == 1098) {
      this.titleService.setTitle('Repuestos para plotters 2');
      this.metaService.addTag({ name: 'description', content: 'Repuestos para plotters 2' });
    } else if (this.id_cat == 1134) {
      this.titleService.setTitle('Herramientas para corte manual y dobleses 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para corte manual y dobleses 2' });
    } else if (this.id_cat == 1122) {
      this.titleService.setTitle('Herramientas para corte manual y dobleses 3');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para corte manual y dobleses 3' });
    } else if (this.id_cat == 1055) {
      this.titleService.setTitle('Herramientas para corte manual y dobleses 4');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para corte manual y dobleses 4' });
    } else if (this.id_cat == 1124) {
      this.titleService.setTitle('Herramientas para corte manual y dobleses 5');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para corte manual y dobleses 5' });
    } else if (this.id_cat == 1825) {
      this.titleService.setTitle('Herramientas para corte manual y dobleses 6');
      this.metaService.addTag({ name: 'description', content: 'Herramientas para corte manual y dobleses 6' });
    } else if (this.id_cat == 1424) {
      this.titleService.setTitle('Productos para foil 2');
      this.metaService.addTag({ name: 'description', content: 'Productos para foil 2' });
    } else if (this.id_cat == 1927) {
      this.titleService.setTitle('Productos para foil 3');
      this.metaService.addTag({ name: 'description', content: 'Productos para foil 3' });
    } else if (this.id_cat == 1998) {
      this.titleService.setTitle('Productos para foil 4');
      this.metaService.addTag({ name: 'description', content: 'Productos para foil 4' });
    } else if (this.id_cat == 408) {
      this.titleService.setTitle('Hoja transportadora - Tapetes 2');
      this.metaService.addTag({ name: 'description', content: 'Hoja transportadora - Tapetes 2' });
    } else if (this.id_cat == 1060) {
      this.titleService.setTitle('Papeles de transferencia 2');
      this.metaService.addTag({ name: 'description', content: 'Papeles de transferencia 2' });
    } else if (this.id_cat == 402) {
      this.titleService.setTitle('Navajas y Accesorios para Plotter de corte 2');
      this.metaService.addTag({ name: 'description', content: 'Navajas y Accesorios para Plotter de corte 2' });
    } else if (this.id_cat == 1931) {
      this.titleService.setTitle('Encuadernado 2');
      this.metaService.addTag({ name: 'description', content: 'Encuadernado 2' });
    } else if (this.id_cat == 1819) {
      this.titleService.setTitle('Encuadernado 3');
      this.metaService.addTag({ name: 'description', content: 'Encuadernado 3' });
    } else if (this.id_cat == 1151) {
      this.titleService.setTitle('Encuadernado 4');
      this.metaService.addTag({ name: 'description', content: 'Encuadernado 4' });
    } else if (this.id_cat == 1960) {
      this.titleService.setTitle('Plotters de corte 2');
      this.metaService.addTag({ name: 'description', content: 'Plotters de corte 2' });
    } else if (this.id_cat == 399) {
      this.titleService.setTitle('Plotters de corte 3');
      this.metaService.addTag({ name: 'description', content: 'Plotters de corte 3' });
    } else if (this.id_cat == 1096) {
      this.titleService.setTitle('Plotters de corte 4');
      this.metaService.addTag({ name: 'description', content: 'Plotters de corte 4' });
    } else if (this.id_cat == 1036) {
      this.titleService.setTitle('Plotters de corte 5');
      this.metaService.addTag({ name: 'description', content: 'Plotters de corte 5' });
    } else if (this.id_cat == 916) {
      this.titleService.setTitle('Moldes 2');
      this.metaService.addTag({ name: 'description', content: 'Moldes 2' });
    } else if (this.id_cat == 1992) {
      this.titleService.setTitle('Moldes 3');
      this.metaService.addTag({ name: 'description', content: 'Moldes 3' });
    } else if (this.id_cat == 1995) {
      this.titleService.setTitle('Tintas 2');
      this.metaService.addTag({ name: 'description', content: 'Tintas 2' });
    } else if (this.id_cat == 183) {
      this.titleService.setTitle('Ektools 2');
      this.metaService.addTag({ name: 'description', content: 'Ektools 2' });
    } else if (this.id_cat == 2043) {
      this.titleService.setTitle('Quelli 2');
      this.metaService.addTag({ name: 'description', content: 'Quelli 2' });
    } else if (this.id_cat == 1044) {
      this.titleService.setTitle('Tableros para notas 2');
      this.metaService.addTag({ name: 'description', content: 'Tableros para notas 2' });
    } else if (this.id_cat == 1142) {
      this.titleService.setTitle('Sellos 2');
      this.metaService.addTag({ name: 'description', content: 'Sellos 2' });
    } else if (this.id_cat == 1014) {
      this.titleService.setTitle('Sellos 3');
      this.metaService.addTag({ name: 'description', content: 'Sellos 3' });
    } else if (this.id_cat == 1085) {
      this.titleService.setTitle('Sellos 4');
      this.metaService.addTag({ name: 'description', content: 'Sellos 4' });
    } else if (this.id_cat == 1049) {
      this.titleService.setTitle('Sellos 5');
      this.metaService.addTag({ name: 'description', content: 'Sellos 5' });
    } else if (this.id_cat == 1113) {
      this.titleService.setTitle('Sellos 6');
      this.metaService.addTag({ name: 'description', content: 'Sellos 6' });
    } else if (this.id_cat == 1068) {
      this.titleService.setTitle('Sellos 7');
      this.metaService.addTag({ name: 'description', content: 'Sellos 7' });
    } else if (this.id_cat == 1099) {
      this.titleService.setTitle('Sellos 8');
      this.metaService.addTag({ name: 'description', content: 'Sellos 8' });
    } else if (this.id_cat == 1133) {
      this.titleService.setTitle('Herramientas manuales todo en uno 2');
      this.metaService.addTag({ name: 'description', content: 'Herramientas manuales todo en uno 2' });
    } else if (this.id_cat == 2004) {
      this.titleService.setTitle('Herramientas manuales todo en uno 3');
      this.metaService.addTag({ name: 'description', content: 'Herramientas manuales todo en uno 3' });
    } else if (this.id_cat == 1919) {
      this.titleService.setTitle('Cricut 2');
      this.metaService.addTag({ name: 'description', content: 'Cricut 2' });
    } else if (this.id_cat == 1968) {
      this.titleService.setTitle('Cricut 3');
      this.metaService.addTag({ name: 'description', content: 'Cricut 3' });
    } else if (this.id_cat == 1979) {
      this.titleService.setTitle('Cricut 4');
      this.metaService.addTag({ name: 'description', content: 'Cricut 4' });
    } else if (this.id_cat == 1971) {
      this.titleService.setTitle('Cricut 5');
      this.metaService.addTag({ name: 'description', content: 'Cricut 5' });
    } else if (this.id_cat == 1922) {
      this.titleService.setTitle('Cricut 6');
      this.metaService.addTag({ name: 'description', content: 'Cricut 6' });
    } else if (this.id_cat == 1981) {
      this.titleService.setTitle('Cricut 7');
      this.metaService.addTag({ name: 'description', content: 'Cricut 7' });
    } else if (this.id_cat == 158) {
      this.titleService.setTitle('Cricut 8');
      this.metaService.addTag({ name: 'description', content: 'Cricut 8' });
    } else if (this.id_cat == 2) {
      this.titleService.setTitle('Catalogo Home');
      this.metaService.addTag({ name: 'description', content: 'Catalogo Home' });
    } else {
      this.titleService.setTitle(this.catalogo_cat);
      this.metaService.addTag({ name: 'description', content: this.catalogo_cat });
    }


    this.shoppingCart.cartItems.subscribe((d: any) => {
      this.totalItems = d.length;
    });
  }

  navigateToDescripcionProducto(c: any, b: any, a: any, d: any, e: any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    const paquete = d; // Set the link_rewrite dynamically
    const sku = e; // Set the link_rewrite dynamically
    if (d == 1) {
      this.router.navigateByUrl(`/paquetes/${id_product}-${sku}` + ".html");
    } else {
      this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}` + ".html");
    }
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  }
  onPageChange($event: PageEvent) {
    /*this.productoService.getDescuento(jsondescuento).subscribe(resdesc =>{
        this.resdesc = resdesc;
  
        if (this.resdesc === 1) {
        
          this.descuentillo = false;
          this.descuento = 1;
        }else{
        
          this.descuentillo = true;
          this.descuento = this.resdesc;
          this.descuento = 1- this.descuento
        }
    });*/
    if (this.productCat && Array.isArray(this.productCat)) {
      this.currentItemsToShow = this.productCat.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
    } else {
      console.warn('productCat está indefinido o no es un array');
      this.currentItemsToShow = []; // O cualquier valor por defecto que tenga sentido en tu contexto
    }
    this.scrollUp();
  }

  private generateItems() {
    for (let i = 0; i < 4; i++) {
      let imp = this.sum;
      if (this.sum <= this.totalData) {
        this.items.push(this.productCat[imp - 1]);
      }
      this.sum++;
    }
  }

  scrollUp(): void {
    setTimeout(() => {
      if (this.tableInput && this.tableInput.nativeElement) {
        this.tableInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        console.warn('tableInput o nativeElement no están definidos');
      }
    });
  }

  top() {
    window.scrollTo(0, 0);
  }

  onIonInfinite(ev: any) {
    if (this.sum <= this.totalData) {
      this.generateItems();
    }
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

  getArticulos(id_cat: any) {
    this.cargando = true;
    this.productoService.getCategorias(id_cat).subscribe(data => {
      this.productosCatalogo = data;
      this.productService.setCatalogo(this.productosCatalogo);
      this.productSubscription = this.productService.selectedCata$.subscribe((product) => {
        this.productCat = product;
        this.cargando = false;
      });
      this.tamanoLista = this.productCat.length;
      this.currentItemsToShow = this.productCat.slice(0, 24);

      this.totalData = this.productCat.length;
      this.precio = this.productCat.precio;
      this.id_product = this.productCat.id_product;


      this.generateItems();
    })

    //this.generateItems();
  }


  confirmarCompra(id: any) {
    this.cargando = true;
    this.handleWP()
    this.productoService.getDescripcionProducto(id).subscribe((data: any) => {
      try {
        this.productoData = data;
        //  this.existencias = this.productoData[0].existencia;
        this.atributos = this.productoData[0].detalles.atributos;
        //  this.precioDyn = this.productoData[0].precio;
        this.unidadVenta = this.productoData[0].unitId;
        this.descripcionsita = this.productoData[0].description;
        this.descripcionsilla = this.productoData[0].description_short;
        this.alto = this.productoData[0].height;
        this.ancho = this.productoData[0].width;
        this.largo = this.productoData[0].depth;
        this.peso = this.productoData[0].weight;
        let id_cat = this.productoData[0].id_category;
        this.cargandoProductoInfo = 1;
        var jsondescuento = {
          "descuento": this.id_product
        }
        this.productoService.getDescuento(jsondescuento).subscribe((resdesc: any) => {
          try {

            this.resdesc = resdesc;
            //console.log(this.resdesc)

            if (this.resdesc === 1) {

              this.descuentillo = false;
              this.descuento = 1;

            } else {

              this.descuentillo = true;
              this.descuento = this.resdesc;
              this.descuento = 1 - this.descuento
            }
          } catch (error) {

            this.descuentillo = true;
            this.descuento = this.resdesc;
            this.descuento = 1 - this.descuento
          }

          this.cargando = false;
          this.colorDefault()

        })
        // this.getDescripciones();
      } catch (error) {
        this.cargandoProductoInfo = 1;
        this.cargandoImagenes = 1;
        this.cargando = false;
      }
    })
    this.nuevaFecha = this.datePipe.transform(this.fechita, "MM-dd-yyyy");
  }

  comprarRegalo(producto: Producto) {
    producto.existencia = this.existencias;
    producto.color = this.color;

    if (producto.color == "") {

      this.ShowError2();

    } else {

      producto.quantity = this.cantidad;
      if (this.existencias < this.cantidad) {

        this.ShowError();

      } else {
        let pasa = true;
        this.shoppingCart.cartItems.subscribe((d: any) => {
          this.items = d;
          this.totalItems = d.length;
        });

        if (this.totalItems > 0) {
          for (let index = 0; index < this.items.length; index++) {
            const element = this.items[index];
            if (producto.sku == element.sku) {
              if (this.existencias < (element.quantity + producto.quantity)) {
                this.ShowError3();
                pasa = false;
              }
            }
          }
        }
        if (pasa) {
          // this.showSuccess();
          if (this.descuento == 0) {
            producto.precio = this.precioDyn;
            producto.title = this.productName


            /* Read more about isConfirmed, isDenied below */

            this.shoppingCart.addItem(producto);

            var jsonRegalito = {
              "id_product": producto.id_product,
              "title": producto.title,
              "quantity": producto.quantity,
              "sku": producto.sku,
              "image_link": producto.image_link,
              "existencia": producto.existencia,
              "medida": this.unidadVenta,
              "alto": this.alto,
              "largo": this.largo,
              "ancho": this.ancho,
              "peso": this.peso,
              "paquete": producto.paquete,
              "link_rewrite": producto.link_rewrite,
              "category_rewrite": producto.category_rewrite,
              "correo": this.usuario.usuario,
            }

            this.usuarioService.registraRegaloMesa(jsonRegalito).subscribe(registrado => {

            })

            this.shoppingCart.cartItems.subscribe(data => {
              this.items = data;
              this.totalItems = data.length;
              if (this.items) {
                this.getTotal(this.items);

              }
              window.location.href = '/cart'

              // this.registraCarrito2();
            });



          }
          if (this.descuento > 0) {
            producto.precio = this.precioDyn * this.descuento
            producto.title = this.productName


            this.shoppingCart.addItem(producto);

            var jsonRegalito2 = {
              "id_product": producto.id_product,
              "title": producto.title,
              "quantity": producto.quantity,
              "sku": producto.sku,
              "image_link": producto.image_link,
              "existencia": producto.existencia,
              "medida": producto.medida,
              "alto": producto.alto,
              "largo": producto.largo,
              "ancho": producto.ancho,
              "peso": producto.peso,
              "paquete": producto.paquete,
              "link_rewrite": producto.link_rewrite,
              "category_rewrite": producto.category_rewrite,
              "correo": this.usuario.usuario,
            }

            this.usuarioService.registraRegaloMesa(jsonRegalito2).subscribe(registrado => {

            })

            this.shoppingCart.cartItems.subscribe(data => {
              this.items = data;
              this.totalItems = data.length;
              if (this.items) {
                this.getTotal(this.items);

              }
              // this.router.navigate(['/checkout']);
              window.location.href = '/cart'
              // this.registraCarrito2();
            });



          }

        }
      }

    }

  }

  stopPropagation2(event: MouseEvent): void {
    event.stopPropagation();
  }

  openModal2(): void {
    this.isModalOpen2 = true;
  }

  closeModal2(): void {
    this.isModalOpen2 = false;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent): void {
    this.handleWP()
  }


  comprarRegalo2(producto: Producto) {
    producto.existencia = this.existencias;
    producto.color = this.color;

    if (producto.color == "") {

      this.ShowError2();

    } else {

      producto.quantity = this.cantidad;
      if (this.existencias < this.cantidad) {

        this.ShowError();

      } else {
        let pasa = true;
        this.shoppingCart.cartItems.subscribe((d: any) => {
          this.items = d;
          this.totalItems = d.length;
        });

        if (this.totalItems > 0) {
          for (let index = 0; index < this.items.length; index++) {
            const element = this.items[index];
            if (producto.sku == element.sku) {
              if (this.existencias < (element.quantity + producto.quantity)) {
                this.ShowError3();
                pasa = false;
              }
            }
          }
        }
        if (pasa) {
          // this.showSuccess();
          if (this.descuento == 0) {
            producto.precio = this.precioDyn;
            producto.title = this.productName


            /* Read more about isConfirmed, isDenied below */

            this.shoppingCart.addItem(producto);
            this.borradito()




            this.shoppingCart.cartItems.subscribe(data => {
              this.items = data;
              this.totalItems = data.length;
              if (this.items) {
                this.getTotal(this.items);

              }
              location.reload()

              // this.registraCarrito2();
            });



          }
          if (this.descuento > 0) {
            producto.precio = this.precioDyn * this.descuento
            producto.title = this.productName


            this.shoppingCart.addItem(producto);
            this.borradito()



            this.shoppingCart.cartItems.subscribe(data => {
              this.items = data;
              this.totalItems = data.length;
              if (this.items) {
                this.getTotal(this.items);

              }
              // this.router.navigate(['/checkout']);
              location.reload()
              // this.registraCarrito2();
            });



          }

        }
      }

    }

  }

  getTotal(data: any) {
    let subs = 0;

    for (const item of data) {
      subs += item.precio * item.quantity;

      this.grandTotal = subs;

    }
  }


  handleWP() {
    // this.globalClickListener = this.renderer2.listen('document', 'click', (event: Event) => {
    //   const clickedInside = this.el.nativeElement.contains(event.target);
    //   if (!clickedInside) {
    //     this.handleWP();
    //   }
    // });
    if (this.modal_wpIs == false) {
      this.renderer2.setStyle(this.modal_wp.nativeElement, 'display', 'flex');

      this.modal_wpIs = true
    } else if (this.modal_wpIs == true) {
      this.renderer2.setStyle(this.modal_wp.nativeElement, 'display', 'none');

      this.modal_wpIs = false
    }
  }

  seleccionacolor(name: string, id_attribute: number, id_attribute_group: number, color: string): void {
    this.varColorSelected = color;
    this.colorSeleccionado = name;
    // Lógica adicional para manejar la selección del color
  }
  cambiaSku() {
    try {
      var ladata = this.productoData[0];
      var variables = ladata.detalles.variables;
      if (document.getElementsByClassName('selected selector-color').length > 0) {
        var selecciones = [];
        selecciones.push({
          id_group: document
            .getElementsByClassName('selected selector-color')[0]
            .className.split('id_group_')[1]
            .split(' ')[0],
          id_attribute: document.getElementsByClassName(
            'selected selector-color'
          )[0].id,
        });
        if (document.getElementsByName('atributos').length > 0) {
          var selectores = document.getElementsByName('atributos');
          for (var i = 0; i < selectores.length; i++) {
            var sl = selectores[i];
            selecciones.push({
              id_group: sl.classList[0],
              id_attribute: (<HTMLInputElement>sl).value,
            });
          }
        }
        var pasa = true;

        for (var i = 0; i < variables.length; i++) {
          pasa = false
          var nkeys = Object.keys(variables[i].atributos)
          var aciertos = 0
          var nvalor = 0

          for (var j = 0; j < nkeys.length; j++) {
            for (var k = 0; k < selecciones.length; k++) {
              if (selecciones[k].id_group == nkeys[j]) {
                if (variables[i].atributos[selecciones[k].id_group] == selecciones[k].id_attribute) {
                  aciertos++
                }
              }
            }
          }
          if (aciertos == nkeys.length) {

            pasa = true
            nvalor = i
            i = variables.length + 10
          }

          if (pasa) {


            ladata.sku = variables[nvalor].reference;


            var lrf = document.getElementById('referencia');
            var uv = document.getElementById('unidadVenta');
            this.id_product_attribute = variables[nvalor].id_product_attribute


            if (lrf) {
              lrf.innerText = variables[nvalor].reference.replace(/-R$/, '');
              var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
              referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
              this.referenciaChida = referenciaLimpia;

              this.getExistencias(referenciaLimpia, 'CHIH');
              this.getPrecioUnitario(referenciaLimpia, this.unidadVenta);
              this.getExistencias2(referenciaLimpia);

              // if(uv)
              // {
              //   uv.innerText = variables[nvalor].unidadVenta.replace(/-R$/, '');
              //   var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              //   unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];


              // }
            }
            i = variables.length + 100;
            this.crr_sel = 0;
            this.crr_indx = 0;
          }
        }
        if (!pasa) {
          if (
            this.crr_sel <=
            document.getElementsByName('atributos').length - 1
          ) {
            var elselect = document.getElementsByName('atributos')[this.crr_sel];
            if (
              (<HTMLSelectElement>elselect).options.length >
              this.crr_indx + 1
            ) {
              (<HTMLSelectElement>elselect).selectedIndex = this.crr_indx;
              this.crr_indx++;
            } else {
              this.crr_sel++;
              this.crr_indx = 0;
            }
            this.cambiaSku();
            var lrf = document.getElementById('referencia');

            var uv = document.getElementById('unidadVenta');
            this.id_product_attribute = variables[this.crr_indx].id_product_attribute


            if (lrf) {
              lrf.innerText = variables[i].reference.replace(/-R$/, '');
              var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
              referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
              this.referenciaChida = referenciaLimpia;

              this.getExistencias(referenciaLimpia, 'CHIH');
              this.getPrecioUnitario(referenciaLimpia, this.unidadVenta);
              this.getExistencias2(referenciaLimpia);
              // if(uv)
              // {
              //   uv.innerText = variables[i].unidadVenta.replace(/-R$/, '');
              //   var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              //   unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];

              // this.getPrecioUnitario(referenciaLimpia,unidadventLimpia);
              // }
            }


          } else {
            this.crr_sel = 0;
            this.crr_indx = 0;
          }
        }
      }
    } catch (error) {

    }

  }

  colorDefault() {
    setTimeout(() => {



      try {
        let span = document.getElementsByClassName('id_group_2 selector-color')[0].children[0] as HTMLElement;
        if (span) {
          span.click();
        }
      } catch (error) {
        this.getExistencias(this.productoData[0].sku, 'CHIH');
        this.getPrecioUnitario(this.productoData[0].sku, this.unidadVenta);
        this.getExistencias2(this.productoData[0].sku);
      }

      try {
        let span2 = document.getElementsByClassName('id_group_7 selector-color')[0].children[0] as HTMLElement;
        if (span2) {
          span2.click();
        }
      } catch (error) {
        this.getExistencias(this.productoData[0].sku, 'CHIH');
        this.getPrecioUnitario(this.productoData[0].sku, this.unidadVenta);
        this.getExistencias2(this.productoData[0].sku);

      }




    }, 1500);


  }

  getPrecioUnitario(reference: string, medida: string) {
    this.cargando = true;
    ////console.log(reference);
    ////console.log(medida);
    this.cargandoPrecio = 0
    /*if (reference == '' || reference == null || reference == undefined || reference == 'undefined' || medida == '' || medida == null || medida == undefined || medida == 'undefined') {
      return;
    }*/
    let objetoPost = {
      // CustAccount: "C000041928",
      // InventLocationId: "CHIHCONS",
      // InventSiteId: "CHIH",
      // ItemId: reference,
      // PercentCharges: 0,
      // UnitId: medida,
      // amountQty: 1,
      // company: "ATP",
      // currencyCode: "MXN",
      // transDate: this.nuevaFecha

      "custAccount": "CL0001348",
      "itemId": reference,
      "amountQty": 1,
      "transDate": this.nuevaFecha,
      "currencyCode": "MXN",
      "inventSiteId": "CHIH",
      "inventLocationId": "CHIHCONS",
      "company": "LIN",
      "unitId": medida,
      "percentCharges": 0
    }


    this.productoService.getPrecioUnitario(objetoPost).subscribe((response: any) => {

      try {
        this.precioDyn = response.data;
        this.precioDyn = this.precioDyn * 1.16
        this.cargandoPrecio = 1;
        this.cargoprecio = true;
        this.cargando = false;
      } catch (error) {
        this.precioDyn = 0
        this.cargandoPrecio = 1;
        this.cargoprecio = true;
        this.cargando = false;
        //console.log('error precio unitario ', objetoPost);
      }


    })


  }

  getExistencias(reference: string, sitio: any) {
    this.cargando = true;
    this.cargandoInventario = 0;
    ////console.log(reference);
    //this.cargando = true;
    /*if (reference == '' || reference == null || reference == undefined || reference == 'undefined') {
      return;
    }*/
    // this.existencias = [];
    this.cargandoInventario = 0;

    this.productoService.getExistencias(reference, sitio).subscribe((response: any) => {
      let existenciasTem: any;
      existenciasTem = response;
      try {
        this.productName = response.data.productName;
        this.productName = this.productName.replace(/"/, "")
        this.cargando = false;

        if (response.data != null) {
          // this.existencias = response.data.availableOnHandQuantity;
          this.cargando = false;
          this.cargandoInventario = 1;

        }
      } catch (error) {
        this.productName = this.productoData[0].title + " " + this.color
        this.cargando = false;
        // this.existencias = 0;
        this.cargandoInventario = 1;

        //console.log('error get existencias ', reference, sitio);
      }

    });



  }

  getExistencias2(reference: any) {
    this.cargandoInventario = 0;
    this.existencias = [];
    this.productoService.getPrecioUnitario2(reference).subscribe(otroprecios => {
      try {
        let existenciasTem: any;
        existenciasTem = otroprecios;

        let invavance = otroprecios.data.availableOnHandQuantityATP;

        invavance = Math.floor(invavance * .3);

        let invlideart = otroprecios.data.availableOnHandQuantityLideart;

        this.existencias = invlideart + invavance;

        if (this.existencias > 0) {
          this.muestroStock = "Disponible"

        } else {
          this.muestroStock = "Agotado"
          this.Agotado = "Producto Agotado"
        }



        this.cargandoInventario = 1;
        this.muestroStock = "Disponible"

      } catch (error) {
        this.existencias = 0
        this.cargandoInventario = 1;
        this.muestroStock = "No Disponible"
      }

    })
  }



  // async modalDescripcion(id_product:any) {
  //   const modal = await this.modalCtrl.create({
  //     component: ModalDescripcionProductoPage,
  //     componentProps:{
  //       id_product: id_product
  //     },
  //     cssClass: 'modalRegistroCss',
  //     initialBreakpoint: 1,
  //     breakpoints: [0, 0.5, 1]
  //   });
  //   return await modal.present();
  // }

  obtenerDescuentoUsuario() {
    if (this.usuario && this.usuario.usuario) {
      const datosDescuento = {
        email: this.usuario.usuario,
        id_cat: this.id_cat
      };

      this.productoService.getCategoriaSub(datosDescuento).subscribe((data: any) => {
        if (data && data.length > 0) {
          this.productosCatalogo = data;
          this.productService.setCatalogo(this.productosCatalogo);
          this.productSubscription = this.productService.selectedCata$.subscribe((product: any) => {
            this.productCat = product;
          });
          this.tamanoLista = this.productCat.length;
          this.currentItemsToShow = this.productCat.slice(0, 24);
          this.totalData = this.productCat.length;
          this.precio = this.productCat.precio;
          this.id_product = this.productCat.id_product;

          for (let i = 0; i < 4; i++) {
            let imp = this.sum;
            if (this.sum <= this.totalData) {
              this.items.push(this.productCat[imp - 1]);
            }
            this.sum++;
          }
        } else {
          this.productoService.getCategorias(this.id_cat).subscribe((data: any) => {
            this.productosCatalogo = data;
            this.productService.setCatalogo(this.productosCatalogo);
            this.productSubscription = this.productService.selectedCata$.subscribe((product: any) => {
              this.productCat = product;
            });
            this.tamanoLista = this.productCat.length;
            this.currentItemsToShow = this.productCat.slice(0, 24);
            this.totalData = this.productCat.length;
            this.precio = this.productCat.precio;
            this.id_product = this.productCat.id_product;

            for (let i = 0; i < 4; i++) {
              let imp = this.sum;
              if (this.sum <= this.totalData) {
                this.items.push(this.productCat[imp - 1]);
              }
              this.sum++;
            }
          });
        }
      });
    } else {
      this.productoService.getCategorias(this.id_cat).subscribe((data: any) => {
        this.productosCatalogo = data;
      });
    }
  }


  async ShowError() {
    // const toast = await this.toastController.create({
    //   message: 'Inventario Insuficiente',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();

    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Inventario Insuficiente",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });
  }

  async borradito() {
    // const toast = await this.toastController.create({
    //   message: 'Inventario Insuficiente',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();

    Swal.fire({
      icon: "success",
      title: "Guardado!",
      text: "Producto Añadido a tu carrito",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });
  }

  async ShowError3() {
    // const toast = await this.toastController.create({
    //   message: 'No puedes llevar al Carrito mas de la Cantidad en Existencias',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No puedes llevar al Carrito mas de la Cantidad en Existencias",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  async ShowError2() {
    // const toast = await this.toastController.create({
    //   message: 'Necesitas Seleccionar Color',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Necesitas Seleccionar Color",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  toggleShowAllColors(productId: number) {
    this.showAllColorsMap[productId] = !this.showAllColorsMap[productId];
  }

}
