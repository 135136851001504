<section class="distribuidorDesk" style=" margin-top: 100px;">
  <div class="dist-cont">
    <div class="info-cont">
      <p>Escriba el SKU o ID Producto en el buscador del producto al que agregara RELACIONADOS</p>
      <div class="cont-form">

        <div class="inputContainer">

          <input type="text" class="input" [(ngModel)]="id_product" (keyup.enter)="consultalo()" onenter
            placeholder="a">
          <label for="" class="label">Id Producto</label>

        </div>

      </div>
      <div>
        <input type="button" (click)="consultalo()" class="submitBtn" value="Buscar">
      </div>
    </div>
  </div>

  <p *ngIf="buscado == 1" style="color: rgb(3, 160, 50);">Producto Encontrado:</p>
  <div style="display: flex; flex-direction: row;" *ngIf="buscado == 1">
    <div>
      <p>{{nombre_producto}}</p>
      <img src="{{imagen}}" width="100px" height="100px">
      <p>{{sku}}</p>


    </div>

    <div style="margin-left: 20px;" *ngIf="sitiene==0">
      <p style="color: red;">Este Producto no tiene productos Relacionados</p>



    </div>
    <div style="margin-left: 20px;" *ngIf="sitiene==1">
      <p style="color: green;">Este Producto ya cuenta con 1 o mas Productos Relacionados</p>

      <div *ngFor="let item of prodRelac; let i = index;">
        <p>{{item.title}}</p>
        <img src="{{item.image_link}}" width="100px" height="100px">
        <p>Orden</p>

        <div>
          <input type="text" [(ngModel)]="item.orden" id="orden{{i}}">
          <input type="button" (click)="actualizaOrden(item.id_relacion,item.orden)" value="Actualizar Orden">
          <input type="button" (click)="borrameProdRelac(item.id_relacion)" value="Eliminar">
        </div>

      </div>

    </div>
  </div>



  <div *ngIf="buscado == 1">

    <div class="dist-cont">
      <div class="info-cont">
        <p>Escriba AQUI el SKU o ID de los productos relacionados que va a agrega</p>
        <div class="cont-form">

          <div class="inputContainer">

            <input type="text" class="input" [(ngModel)]="id_product2" (keyup.enter)="consultalo2()" placeholder="a">

            <label for="" class="label">ID o SKU Producto</label>

          </div>

        </div>
        <div>
          <input type="button" (click)="consultalo2()" class="submitBtn" value="Buscar">
        </div>
      </div>
    </div>
  </div>




  <div *ngIf="buscado2 == 1">

    <p>{{nombre_producto2}}</p>
    <img src="{{imagen2}}" width="100px" height="100px">
    <p>{{sku2}}</p>
    <div>
      <input type="button" (click)="registraProductoRelacionado()" class="submitBtn"
        value="Agregar Producto Relacionado">
    </div>
  </div>
</section>