<section class="contactoDesk">
  <div class="deskcont">
    <div class="taitl-cont">
      <span class="taitl">Ponte en contacto con nosotros.</span>
    </div>
    <div class="desk-info">
      <div class="info-cont">
        <span class="titulo-contin">En Lideart estamos para servirle. <i class="fa-solid fa-heart fa-beat"></i></span>
        <div class="partin">
          <span class="sub-titles">Horario de atención</span>
          <span class="sub-titles">Lunes a Viernes</span>
          <span class="info">8:00 am - 6:00 pm</span>
        </div>
        <div class="partin">
          <span class="sub-titles">Sábados</span>
          <span class="info">CERRADO</span>
          <span class="info">**Horario del estado de Chihuahua Chih. Mx.</span>
        </div>
        <div class="partin">
          <span class="sub-titles">Teléfono</span>
          <span class="info">(614) 432-61-22</span>
        </div>
        <div class="partin">
          <span class="sub-titles">Whatsapp</span>
          <span class="info">(614) 605-12-78</span>
        </div>
        <div class="partin">
          <span class="sub-titles">Correo electrónico de contacto</span>
          <span class="info">contacto&#64;lideart.com.mx</span>
        </div>
      </div>
      <form>
        <div class="part">
          <span class="line-txt">Nombre (requerido)</span>
          <input type="text" class="inp">
        </div>
        <div class="part">
          <span class="line-txt">Correo electrónico (requerido)</span>
          <input type="text" class="inp">
        </div>
        <div class="part">
          <span class="line-txt">Número telefónico (requerido)</span>
          <input type="text" class="inp">
        </div>
        <div class="part">
          <span class="line-txt">Mensaje</span>
          <textarea class="tarea" name="" id="" cols="30" rows="3"></textarea>
        </div>
      </form>
    </div>
  </div>
</section>