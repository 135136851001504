import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { APPCONTEXTService } from '../../services/app-context.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { LoginService } from '../../services/login.service';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    IonicModule,
    CommonModule,
    NgOptimizedImage
  ],
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeComponent implements OnInit {
  slider: any = [];
  urlIMG = 'https://new.lideart.com.mx/modules/ps_imageslider/images/';


  id_product: any;
  datosUsuario: any = [];
  apellido!: string;
  nombre!: string;
  public totalItems: number = 0;
  usuario!: Usuario;
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  reviews: any = [];
  reviews2: any[] = [];
  URLestrellas: any;
  isDesk!: boolean;
  isCel!: boolean;

  public url: string = 'https://new.lideart.com.mx/';

  APP_CONTEXT = inject(APPCONTEXTService)


  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  @ViewChild('nav_cont') nav_cont!: ElementRef<HTMLInputElement>;
  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;
  nav_contIs = false;
  cargando = false;
  respaq: any;

  public infoUser: any = [];
  public infoUser2: any = [];

  isLoading = true;

  constructor(
    private usuarioService: LoginService,
    private apiProductos: ApiProductosService,
    public modalCtrl: ModalController,
    private activatedRoute: ActivatedRoute,
    private shoppingCart: CarritoServiceService,
    private renderer: Renderer2,
    private router: Router,
    private loginService: LoginService,
    private titleService: Title,
    private apiFacebook: FacebookApiService,
    private checkoutService: CheckoutServiceService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  ngOnInit() {
    this.titleService.setTitle("Home");
    document.body.scrollTop = 0;

    if (isPlatformBrowser(this.platformId)) {
      if (window.screen.width <= 992) {
        if (this.usuario) {
          this.checalo();
        }
      }

      this.usuarioService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;

        if (this.usuario) {
          this.loadUserInfo();
        }

      });

      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
    }
    this.traeImagenesBanner()
    this.loginService.getReviews().subscribe(res => {
      this.reviews = res;
      for (let index = 0; index < this.reviews.length; index++) {
        this.reviews2.push(this.reviews[index]);
      }
      //this.loaded = true;
      this.URLestrellas = "https://new.lideart.com.mx/modules/lgcomments/views/img/stars/plain/bluelight/" + this.reviews.stars + "stars.png";
      // this.reviews2 = this.reviews2.reverse();
      ////console.log(this.reviews2);
    });

    /*this.apiProductos.getProductos().subscribe((response) => {
      this.productos = response;
     
    });*/
    this.shoppingCart.cartItems.subscribe(d => {
      if (d && Array.isArray(d)) {
        this.totalItems = d.length;
      } else {
        this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
      }
    });
  }

  private loadUserInfo() {
    if (this.usuario.usuario === undefined) return
    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.infoUser = data;
      this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
        this.infoUser2 = res;
        if (this.infoUser2 && this.infoUser2.length > 0) {
          this.apiFacebook.sendEvent('PageView', undefined, this.usuario.usuario, this.infoUser2[0].phone, {
            external_id: this.infoUser.customerID,
            nombre: this.infoUser2[0].firstname,
            apellido: this.infoUser2[0].lastname,
            estado: this.infoUser2[0].id_state,
            ciudad: this.infoUser2[0].city,
            country: 'México',
            codigo_postal: this.infoUser2[0].postcode
          });
        } else {
          console.warn('infoUser2 está indefinido o vacío');
          // Manejar el caso en que infoUser2 es indefinido o vacío
        }
      });
    });
  }

  onImageLoad() {
    this.isLoading = false;
  }

  handleNav() {
    if (this.nav_contIs == false) {
      this.renderer.setStyle(this.nav_cont.nativeElement, 'top', '5.3em');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'visibility', 'visible');

      this.nav_contIs = true;
    } else if (this.nav_contIs == true) {
      this.renderer.setStyle(this.nav_cont.nativeElement, 'top', '-25em');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'opacity', '0');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'visibility', 'hidden');

      this.nav_contIs = false;
    }
  }

  traeImagenesBanner() {
    this.apiProductos.getSlaidaHome().subscribe(imagenes => {
      this.slider = imagenes;
      console.log(this.slider)
    })
  }

  checalo() {

    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.datosUsuario = data;
      this.nombre = this.datosUsuario.nombre;
      this.apellido = this.datosUsuario.apellido;
    })
  }

  navigation() {
    this.router.navigateByUrl('/reviews').then(() => {
      window.location.reload();
    });
  }
}
