import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import Fuse from 'fuse.js';
import { Subscription } from 'rxjs';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { ProductService } from '../../services/handle-categorias.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-categorias',
  standalone: true,
  imports: [MatPaginatorModule, CommonModule, RouterModule, IonicModule],
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  providers: [ApiProductosService, CarritoServiceService, ProductService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CategoriasComponent implements OnInit {

  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public totalItems: number = 0;
  public numeroResultados: number = 0;
  url = "https://new.lideart.com.mx/img/categoria/";
  desc: any;
  id_parent: any;
  categorias: any;
  cates: any;
  subcats: any;
  id_cat1: any;
  id_cat2: any;
  cargando = false;
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false, 
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  public isDesk: boolean = false;
  public isCel: boolean = false;


  public taitlpage = "";
  private productSubscription!: Subscription;

  usuario!: Usuario;
  public infoUser: any = [];
  public infoUser2: any = [];

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ProductoService: ApiProductosService,
    private shoppingCart: CarritoServiceService,
    private productService: ProductService,
    private titleService: Title,
    private checkoutService: CheckoutServiceService,
    private usuarioService: LoginService,
    private apiFacebook: FacebookApiService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {


  }

  ngOnInit() {
    this.scrollUp();
    if (isPlatformBrowser(this.platformId)) {

      this.usuarioService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;
        if (this.usuario) {
          this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
            this.infoUser = data;
            //console.log(this.infoUser);
            this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
              this.infoUser2 = res;
              //console.log(this.infoUser2);
              this.apiFacebook.sendEvent('PageView', undefined, this.usuario.usuario, this.infoUser2[0].phone, {
                external_id: this.infoUser.customerID,
                nombre: this.infoUser2[0].firstname,
                apellido: this.infoUser2[0].lastname,
                estado: this.infoUser2[0].id_state,
                ciudad: this.infoUser2[0].city,
                country: 'México',
                codigo_postal: this.infoUser2[0].postcode
              });
            });
          });
        }
      });

      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
    }
    this.cargando = true;
    this.activatedRoute.params.subscribe(params => {
      this.id_parent = params['id_parent'];
      this.cargando = false;

      this.getSubCategorias(this.id_parent);
      //this.cargando = false;
    })
    /*this.activatedRoute.params.subscribe(params=>{ 
      this.id_parent = params['id_parent']; 
      this.cargando = false;
      this.cargando = true;
      this.ProductoService.getSubcategoriaPorProducto(this.id_parent).subscribe(data =>{
        this.categorias =  data;
        this.subcats= this.categorias[0].arreglo;
        this.productService.setCategoria(this.subcats);
        this.desc = this.subcats.description;
        this.productSubscription = this.productService.selectedCate$.subscribe((product) => {
          this.product = product;
        });
        this.cargando = false;
      })
  
      //this.cargando = false;
    })*/
    if (this.id_parent == 10) {
      this.taitlpage = "Productos";
      this.titleService.setTitle("Productos");
    } else if (this.id_parent == 11) {
      this.taitlpage = "Marcas";
      this.titleService.setTitle("Marcas");
    } else if (this.id_parent == 12) {
      this.taitlpage = "Técnicas";
      this.titleService.setTitle("Técnicas");
    }
    this.shoppingCart.cartItems.subscribe(d => {
      this.totalItems = d.length;
    });

  }

  // ngOnDestroy() {
  //   this.productSubscription.unsubscribe();
  // }


  top() {
    window.scrollTo(0, 0);
  }


  scrollUp(): void {
    setTimeout(() => {
      if (this.tableInput && this.tableInput.nativeElement) {
        this.tableInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        console.warn('tableInput o nativeElement no están definidos');
      }
    });
  }


  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

  getSubCategorias(id_parent: any) {
    ////console.log(id_parent);
    this.cargando = true;
    this.ProductoService.getSubcategoriaPorProducto(id_parent).subscribe(data => {
      this.categorias = data;
      this.subcats = this.categorias[0].arreglo;
      this.productService.setCategoria(this.subcats);
      this.desc = this.subcats.description;
      this.productSubscription = this.productService.selectedCate$.subscribe((product) => {
        this.product = product;
      });
      this.cargando = false;
    })
  }

}
