.head-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .buscador{
        width: 100%;
        height: 7rem;
        background: #00abcf;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        form{
            width: 100%;
            height: auto;
            margin-bottom: .5rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            
            button{
                width: 3rem;
                height: 2rem;
                background: #fff;
                color: #00abcf;
                font-size: 2rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            input{
                width: 80%;
                height: 2rem;
                background: #fff;
                border: 0px;
            }

            input:focus{
                outline: none;
            }
        }
    }

    .rest{
        width: 100%;
        height: 3rem;
        background: #4ed4e0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .burgir{
            width: 15%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            ion-icon{
                color: #fff;
                font-size: 2rem;
            }
        }

        .titled{
            width: 70%;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p{
                color: #fff;
                font-size: 1rem;
            }
        }

        .icons{
            width: 30%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .i1{
                width: 30%;
                height: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                ion-icon{
                    color: #fff;
                    font-size: 1.8rem;
                }
            }

            .i2{
                width: 70%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                ion-icon{
                    color: #fff;
                    font-size: 1.8rem;
                }
            }
        }
    }
}




.wishlist{
    width: 100%;
    height: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .titulo{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0rem;

        p{
            font-size: .9rem;
            color: #4ed4e0;
            font-weight: 600;
            margin-block-start: 0em;
            margin-block-end: 0em;
        }
    }

    .invitar{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .btn{
            width: 70%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #00abcf;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            border-radius: 5rem;

            ion-icon{
                font-size: 1.8rem;
                color: #fff;
                margin-right: 1rem;
            }

            p{
                font-size: 1.2rem;
                color: #fff;
                font-weight: lighter;
                margin-block-start: 0em;
                margin-block-end: 0em;
            }
        }
    }

    .productos{
        width: 100%;
        height: auto;
        padding: 1rem .5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .prod{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 1rem;

            .imagen{
                width: 9rem;
                height: 9rem;
                border: 1px solid #000;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                img{
                    width: 100%;
                    height: auto;
                }
            }

            .info{
                width: 60%;
                height: 9rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-left: .5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;


                .parrafos{
                    margin-bottom: .2rem;

                    p{
                        font-size: 1rem;
                        color: black;
                        font-weight: lighter;
                        margin-block-start: 0em;
                        margin-block-end: 0em;
                    }
                }

                .links{
                    display: flex;
                    flex-direction: column;

                    a{
                        text-decoration: none;
                        color: #4ed4e0;
                        border-left: 1px solid #4ed4e0;
                        padding-left: .4rem;
                        margin: .4rem 0rem;
                        font-weight: 600;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .seguir{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0rem;

        .comp{
            width: 70%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #00abcf;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            border-radius: 5rem;

            p{
                font-size: 1.2rem;
                color: #fff;
                font-weight: lighter;
                margin-block-start: 0em;
                margin-block-end: 0em;
            }
        }
    }
}

.invitar-cont{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.666);
    position: fixed;
    top: 0;
    opacity: 0;
    z-index: -1;

    #cerrar{
        font-size: 1.8rem;
        color: #000;
        right: .3rem;
        top: .3rem;
        position: absolute;
    }

    .opts{
        width: 90%;
        height: auto;
        background: #fff;
        position: absolute;
        left: 5%;
        top: 35%;
        padding: 1rem;
    }

    .lectura{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0rem 2rem;

        .bl{
            font-size: 1.1rem;
            font-weight: 600;
            //color: #35a7dc; //Azul si es seleccionado
            color: rgb(94, 94, 94);
        }

        p{
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-size: .9rem;
            color: rgb(94, 94, 94);
        }
    }

    .mid{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0rem 1rem;
        border-top: 1.2px solid rgb(65, 65, 65);
        border-bottom: 1.2px solid rgb(65, 65, 65);
        padding: 0rem 0rem;                                                     //Este
        padding-left: 1rem;
        margin: 1rem 0rem;

        .midl{
            width: 100%;
            height: auto;
            display: none;                                                      //Este
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            ion-icon{
                color: #000;
                font-size: 1.7rem;
            }

            p{
                margin-block-start: 1em;
                margin-block-end: 1em;
                font-size: .9rem;
                color: rgb(94, 94, 94);
                margin-left: 1rem;
            }

            .copys{
                position: relative;

                span{
                    display: none;                                              //Este
                    position: absolute;
                    top: -1rem;
                    left: 0;
                    width: 10rem;
                    height: auto;
                    font-size: .8rem;
                    color: #4ed4e0;
                }
            }
        }
    }

    .ve{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0rem 2rem;

        .bl{
            font-size: 1.1rem;
            font-weight: 600;
            //color: #35a7dc; //Azul si es seleccionado
            color: rgb(94, 94, 94);
        }

        p{
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-size: .9rem;
            color: rgb(94, 94, 94);
        }
    }

    .correo{
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: -5;

        .cancelar{
            position: absolute;
            top: 10rem;
            color: #00abcf;
            font-size: 1.2rem;
            border-bottom: 1px solid #00abcf;
            border-bottom-style: dashed;
            width: 100%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 1rem;

            ion-icon{ margin-right: .4rem; }
        }

        .cont-next{
            width: 100%;
            height: auto;
            position: absolute;
            top: 13rem;
            display: flex;
            flex-direction: column;

            .titled{
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                p{
                    margin-block-start: .5em;
                    margin-block-end: .5em;
                    font-size: 1.8rem;
                    color: #000;
                    font-weight: 600;
                }
            }

            form{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .pas{   
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 1rem;

                    p{
                        margin-block-start: 0em;
                        margin-block-end: 0em;
                        font-size: .9rem;
                        color: rgb(94, 94, 94); 
                    }

                    input{
                        background: none;
                        border: 0px;
                        border-bottom: 1px solid #00abcf;
                        width: 80%;
                        height: 2rem;
                        margin-left: 1rem;
                    }

                    input:focus{
                        outline: none;
                    }
                }

                button{
                    width: 40%;
                    height: 2rem;
                    background: #00abcf;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    font-size: 1.2rem;
                    color: #fff;
                    border-radius: 5rem;
                    margin-top: 1rem;
                }
            }
        }
    }
}

.wishDesk{
    width: 100%;
    height: auto;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;

    .wishCont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3em 0em;
        gap: 2em;

        .taitl-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: .2em;

            .prin{
                color: #000000;
                font-size: 1.5em;
            }

            .secon{
                color: #00a3c7;
                font-size: 1.2em;
            }
        }

        .wish-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0em 2em;
            gap: 2em;

            .prod{
                width: 20em;
                height: 20em;
                border-radius: 20em 20em 20em 20em;
                overflow: hidden;
                position: relative;
                box-shadow: rgb(0 163 197 / 20%) 0px 4px 30px;
                border: 1px solid #00a3c742;
                transition: .5s all ease-in-out;

                .imag{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .parrafos{
                    position: absolute;
                    width: 100%;
                    height: 7em;
                    background-color: rgb(0 163 199 / 80%);
                    z-index: 2;
                    bottom: -7em;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: .7em;
                    gap: .2em;
                    transition: .5s all ease-in-out;

                    .nom{
                        color: #fff;
                        font-size: .8em;
                        font-weight: 600;
                    }

                    .rest{
                        color: #fff;
                        font-size: .8em;
                    }
                }

                .links{
                    position: absolute;
                    top: -3em;
                    width: 100%;
                    height: 3em;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: .5em;
                    transition: .5s all ease-in-out;

                    a{
                        background-color: #00a3c7;
                        width: 2em;
                        height: 2em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        cursor: pointer;

                        i{
                            color: #fff;
                        }
                    }
                }
            }

            .prod:hover{
                border-radius: 20em 20em 3em 3em;

                .parrafos{
                    bottom: 0em;
                }

                .links{
                    top: 0em;
                }
            }
        }
    }
}

@media(min-width: 992px){
    ion-header{
        display: none;
    }

    ion-content{
        display: none;
    }

    .wishDesk{
        display: flex;
    }
}

.wishDesk::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
}
  
.wishDesk::-webkit-scrollbar-thumb {
    background-color: #00a2c779;
    //border-radius: 1vh;
    width: 0.25em;
}

.loader {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: fixed;
    top: calc(0%);
    left: calc(0%);
    background-color: rgb(255, 255, 255);
    opacity: 0.9;
  
    img{
      width: 200px;
      height: auto;
  
    }
  
  }