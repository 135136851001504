<section class="lidepuntosDesk" style=" margin-top: 100px;">
    <div class="conten-cont" style="width: 99em;">
        <p class="texto-azul-desk" style="font-size: 1.5em;"><strong>¡COMPRA Y RECOGE EN ALMACÉN CHIHUAHUA!</strong></p>
        <div class="container"
            style="display: flex; flex-direction: row; justify-content: center; align-items: flex-start; gap: 5em;">
            <!-- Imagen del Banner -->
            <div class="banner-container"
                style="align-items: center;width: 50%;height: auto;padding-right: 0em;border-right: 2px solid #dd8385;border-right-style: dashed;">
                <img src="/assets/img/compraYRecoge/Banner-CompraOnline-y-Recoge-en-Sucursal-935px420px-06.webp" alt=""
                    style="max-width: 100%;height: auto;">
            </div>
            <div class="images-container"
                style="display: flex;flex-direction: column;gap: 1em;width: 50%;height: auto;padding-top: 0.13em;">
                <div class="top-images" style="display: flex; gap: 1em;">
                    <div
                        style="flex-direction: column;text-align: justify;width: 33%;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                        <img src="/assets/img/compraYRecoge/1.webp" alt="" style="width: 100%;height: auto;">
                        <span
                            style="color: #adadad;text-align: justify;font-weight: 100;font-size: .8em;margin-left: 4px;">1°
                            Debes de ingresar a nuestra página Lideart.com.mx e iniciar sesión.</span>
                    </div>
                    <div
                        style="flex-direction: column;text-align: justify;width: 33%;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                        <img src="/assets/img/compraYRecoge/2.webp" alt="" style="width: 100%;height: auto;">
                        <span
                            style="color: #adadad;text-align: justify;font-weight: 100;font-size: .8em;margin-left: 4px;">2°
                            Agregas los artículos a tu carrito de compra.</span>
                    </div>
                    <div
                        style="flex-direction: column;text-align: justify;width: 33%;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                        <img src="/assets/img/compraYRecoge/3.webp" alt="" style="width: 100%;height: auto;">
                        <span
                            style="color: #adadad;text-align: justify;font-weight: 100;font-size: .8em;margin-left: 4px;">3°
                            Procedes a pagar tu carrito.</span>
                    </div>
                </div>
                <div class="bottom-images" style="display: flex; gap: 1em;">
                    <div
                        style="flex-direction: column;text-align: justify;width: 33%;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                        <img src="/assets/img/compraYRecoge/4.webp" alt="" style="width: 100%;height: auto;">
                        <span
                            style="color: #adadad;text-align: justify;font-weight: 100;font-size: .8em;margin-left: 4px;">4°
                            En el apartado MÉTODO DE ENVÍO te damos dos opciones: Paquetería o recoger después de dos
                            hora en sucursal Chihuahua. Selecciona la opción de recoger en sucursal.</span>
                    </div>
                    <div
                        style="flex-direction: column;text-align: justify;width: 33%;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                        <img src="/assets/img/compraYRecoge/5.webp" alt="" style="width: 100%;height: auto;">
                        <span
                            style="color: #adadad;text-align: justify;font-weight: 100;font-size: .8em;margin-left: 4px;">5°
                            Si tu pago fue con tarjeta de crédito o débito puedes pasar inmediatamente después de haber
                            realizado tu pedido; si tu pago fue por transferencia tendrás que esperar dos horas después
                            de haber enviado tu comprobante de pago.</span>
                    </div>
                    <div
                        style="flex-direction: column;text-align: justify;width: 33%;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                        <img src="/assets/img/compraYRecoge/6.webp" alt="" style="width: 100%;height: auto;">
                        <span
                            style="color: #adadad;text-align: justify;font-weight: 100;font-size: .8em;margin-left: 4px;">6°
                            Por último al llegar a la sucursal y acercarte a la ventanilla de almacén, comenta que tu
                            pedido es de Lideart y proporciona tu orden de venta (que se generó al terminar la compra),
                            ¡allí te entregarán tu mercancía. <strong>Disfruta tu compra!</strong></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="desaparece-desk">
    <div class="banner-container">
        <img src="/assets/img/compraYRecoge/Banner-CompraOnline-y-Recoge-en-Sucursal-935px420px-06.webp" alt=""
            style="max-width: 100%;height: auto;">
    </div>

    <ion-grid>
        <ion-row>

            <ion-col> <img src="/assets/img/compraYRecoge/1.webp" alt=""></ion-col>
            <p>1° - Debes de ingresar a nuestra página Lideart.com.mx e iniciar sesión.</p>

        </ion-row>
        <ion-row>

            <ion-col> <img class="center-rows" src="/assets/img/compraYRecoge/2.webp" alt=""></ion-col>
            <br>
            <p> 2° - Explora en nuestro catalogo de productos y agregas los artículos a tu carrito de compra.</p>

        </ion-row>

        <ion-row>

            <ion-col> <img class="center-rows" src="/assets/img/compraYRecoge/3.webp" alt=""></ion-col>
            <br>
            <p>3° - Accede a tu carrito de compras y selecciona en proceder a pagar tu carrito. </p>



        </ion-row>

        <ion-row>

            <ion-col> <img class="center-rows" src="/assets/img/compraYRecoge/4.webp" alt=""></ion-col>
            <p>4° - En el apartado MÉTODO DE ENVÍO te damos dos opciones: Paquetería o recoger después de dos hora en
                sucursal Chihuahua. Selecciona la opción de recoger en sucursal.</p>

        </ion-row>

        <ion-row>

            <ion-col> <img class="center-rows" src="/assets/img/compraYRecoge/5.webp" alt=""></ion-col>
            <p> 5° - Si tu pago fue con tarjeta de crédito o débito puedes pasar inmediatamente después de haber
                realizado tu pedido; si tu pago fue por transferencia tendrás que esperar dos horas después de haber
                enviado tu comprobante de pago. </p>

        </ion-row>

        <ion-row>

            <ion-col> <img src="/assets/img/compraYRecoge/6.webp" alt=""></ion-col>
            <p> 6° - Por último al llegar a la sucursal y acercarte a la ventanilla de almacén, comenta que tu pedido es
                de Lideart y proporciona tu orden de venta (que se generó al terminar la compra), ¡allí te entregarán tu
                mercancía. </p>

        </ion-row>
    </ion-grid>






</div>