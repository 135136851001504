<section class="cursosDesk" style=" margin-top: 100px;">
  <div class="cursosCont">
    <div class="taitl-cont">
      <span class="taitl">Cursos Gratis</span>
    </div>
    <div class="content-cont">
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/242945030206142/" class="ir" target="blank">- Curso Easypress +
            Explore Air 2: corte y aplicación básica de vinil textil</a>
          <a href="https://www.facebook.com/groups/563318844391386/" class="ir" target="blank">- Curso Easypress +
            Maker: corte y aplicación básica de vinil textil</a>
          <a href="https://www.facebook.com/groups/658708114895173/" class="ir" target="blank">- Curso Easypress:
            aplicación vinil textil básico</a>
          <a href="https://www.facebook.com/groups/242216730282933/" class="ir" target="blank">- Curso Easypress Mini +
            Explore Air 2: corte y aplicación vinil textil básico</a>
          <a href="https://www.facebook.com/groups/685465395233323/" class="ir" target="blank">- Curso online Explore
            Air 2 básico</a>
          <a href="https://www.facebook.com/groups/4840776332618340/" class="ir" target="blank">- Curso online básico
            Explore 3</a>
          <a href="https://www.facebook.com/groups/207324630532408/" class="ir" target="blank">- Curso online básico
            Cricut Joy</a>
          <a href="https://www.facebook.com/groups/376944303175071/" class="ir" target="blank">- Curso online Maker
            básico</a>
          <a href="https://www.facebook.com/groups/3663712350519664/" class="ir" target="blank">- Curso online básico
            Maker 3</a>
          <a href="https://www.facebook.com/groups/542288737082970/" class="ir" target="blank">- Curso online básico Mug
            Press</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/332512787795612/" class="ir" target="blank">- Curso Silhouette Alta
            básico</a>
          <a href="https://www.facebook.com/groups/704771843263368/" class="ir" target="blank">- Curso Cameo 4 básico
            online</a>
          <a href="https://www.facebook.com/groups/456080841823862/" class="ir" target="blank">- Curso online Curio
            básico</a>
          <a href="https://www.facebook.com/groups/2014676408674698/" class="ir" target="blank">- Curso básico
            Silhouette Portrait 3</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/148720093124464/" class="ir" target="blank">- Curso Técnicas de
            aplicación de foil</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/235701960889815/" class="ir" target="blank">- Curso Sawgrass
            básico</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/520293581964682/" class="ir" target="blank">- Curso costura Mini
            Deluxe: elaboración de cubrebocas</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/285037092782788/" class="ir" target="blank">- Curso planchas
            Sublimarts</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.facebook.com/groups/199339961487210/" class="ir" target="blank">- Curso técnicas para
            Embossing</a>
        </div>
      </div>
    </div>
  </div>
</section>