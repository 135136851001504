import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';


@Injectable({
  providedIn: 'root'
})
export class APPCONTEXTService {
  isApp = Capacitor.isNativePlatform()

  constructor() { }
}
