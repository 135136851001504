import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [

  ],
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss'],
})
export class NosotrosComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Nosotros");
  }

}
