import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonRow, IonSkeletonText, IonText, ToastController } from '@ionic/angular/standalone';
import Swal from 'sweetalert2';
import { Producto } from '../../Models/Productos';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';

@Component({
  selector: 'app-mesa-regalos-ver',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonCardContent,
    IonText,
    IonGrid,
    IonCardTitle,
    IonButton,
    IonInput,
  ],
  templateUrl: './mesa-regalos-ver.component.html',
  styleUrls: ['./mesa-regalos-ver.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MesaRegalosVerComponent implements OnInit {

  public currentItemsToShow: any = [];
  usuario!: Usuario;
  mesita: any = []
  regalillos: any = []
  nombre_evento: any;
  referencia: any;
  @ViewChild('modal_wp') modal_wp!: ElementRef<HTMLInputElement>;
  modal_wpIs = false;

  id_customer: any;
  listaWishlist: any = []
  datosCliente: any = []
  nombre: any;
  apellido: any;
  correo: any;
  cargando = false;
  notiene = 0;

  //DESSSSSSSSSC PRODUCTO VARIABLEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEES
  selectedImageURL: string = "";
  id_product: any;
  public descripcion: any;
  productoData: any;
  public colorSeleccionado: string = '';
  atributos: any;
  public precioDyn: any;
  public color = 'blanco';
  items: any = [];
  descripcionsita: any;
  referenciaChida: any;
  public baseUrl: string = 'https://new.lideart.com.mx/';
  slider: any = [];
  slidervideos: any = [];
  relacionados: any = [];
  sliderOne: any;
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };

  public totalItems: number = 0;
  public crr_sel = 0;
  public crr_indx = 0;
  public cantidad: number = 1;
  public existencias: any;
  productoSKU: any;
  fechita = new Date();
  contenidoHTML: any;
  nuevaFecha: any;
  unidadVenta: any;
  descripcionsilla: any;
  descuento: any;
  fechaInicio: any;
  fechaFin: any;
  fechaActual: any;
  resdesc: any = [];
  descuentillo = false;
  carritoclick = 0;
  cargoprecio = false;
  email: any;
  id_product_attribute: any;
  varColorSelected: any;
  cargandoImagenes = 0;
  cargandoPrecio = 0;
  cargandoInventario = 0;
  cargandoProductoInfo = 0;
  parametros: any = [];
  descripcionsillas: any = [];
  datosUsuario: any = [];
  mensaje: any;

  productName: any;
  public arrayMarcas: any = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58
  ];

  constructor(private activatedRoute: ActivatedRoute, private servicioWishlist: ServiciowishlistService, private usuarioService: LoginService,
    private productoService: ApiProductosService,
    private shoppingCart: CarritoServiceService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private router: Router,
    private satanizador: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    public toastController: ToastController,
    private renderer2: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('foo')
    }
    try {
      this.usuarioService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;

        this.checaMesa();

        this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
          this.datosUsuario = data;
          this.id_customer = this.datosUsuario.id_customer

        });

      });
    } catch (error) {


    }
    // this.productos.getSubcategoriaPorProducto(11).subscribe(datitos =>{

    // })
    // this.currentItemsToShow = this.arrayMarcas.slice(0,3);
  }

  checaMesa() {
    this.usuarioService.checaMesa(this.usuario.usuario).subscribe(mesita => {


      this.mesita = mesita;

      this.nombre_evento = this.mesita[0].nombre_evento;
      this.referencia = this.mesita[0].referencia;
      this.mensaje = this.mesita[0].mensaje;

      this.usuarioService.checaRegalos(this.usuario.usuario).subscribe(regalitos => {

        this.regalillos = regalitos;

      })




    })
  }

  comprarRegalo(producto: Producto) {
    producto.existencia = this.existencias;
    producto.color = this.color;

    if (producto.color == "") {

      this.ShowError2();

    } else {

      producto.quantity = this.cantidad;
      if (this.existencias < this.cantidad) {

        this.ShowError();

      } else {
        let pasa = true;
        this.shoppingCart.cartItems3.subscribe((d: any) => {
          this.items = d;
          this.totalItems = d.length;
        });

        if (this.totalItems > 0) {
          for (let index = 0; index < this.items.length; index++) {
            const element = this.items[index];
            if (producto.sku == element.sku) {
              if (this.existencias < (element.quantity + producto.quantity)) {
                this.ShowError3();
                pasa = false;
              }
            }
          }
        }
        if (pasa) {
          // this.showSuccess();
          if (this.descuento == 0) {
            producto.precio = this.precioDyn;
            producto.title = this.productName

            Swal.fire({
              title: "¿Proceder a la compra del Producto?",
              showCancelButton: true,
              confirmButtonText: "Confirmar",
              cancelButtonText: "Cancelar"
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.shoppingCart.addItem3(producto);
                this.router.navigate(['/mesa-regalos-caja/' + this.id_customer]);
              }
            });

          }
          if (this.descuento > 0) {
            producto.precio = this.precioDyn * this.descuento
            producto.title = this.productName
            Swal.fire({
              title: "¿Proceder a la compra del Producto?",
              showCancelButton: true,
              confirmButtonText: "Confirmar",
              cancelButtonText: "Cancelar"
            }).then((result) => {
              if (result.isConfirmed) {
                this.shoppingCart.addItem3(producto);
                this.router.navigate(['/mesa-regalos-caja/' + this.id_customer]);
              }
            });

          }

        }
      }

    }

  }

  handleWP() {
    if (this.modal_wpIs == false) {
      this.renderer2.setStyle(this.modal_wp.nativeElement, 'display', 'flex');

      this.modal_wpIs = true
    } else if (this.modal_wpIs == true) {
      this.renderer2.setStyle(this.modal_wp.nativeElement, 'display', 'none');

      this.modal_wpIs = false
    }
  }

  confirmarCompra(id: any) {
    this.cargando = true;
    this.handleWP()
    this.productoService.getDescripcionProducto(id).subscribe((data: any) => {
      try {
        this.productoData = data;
        //  this.existencias = this.productoData[0].existencia;
        this.atributos = this.productoData[0].detalles.atributos;
        //  this.precioDyn = this.productoData[0].precio;
        this.unidadVenta = this.productoData[0].unitId;
        this.descripcionsita = this.productoData[0].description;
        this.descripcionsilla = this.productoData[0].description_short;
        let id_cat = this.productoData[0].id_category;
        this.cargandoProductoInfo = 1;
        var jsondescuento = {
          "descuento": this.id_product
        }
        this.productoService.getDescuento(jsondescuento).subscribe((resdesc: any) => {
          try {

            this.resdesc = resdesc;
            //console.log(this.resdesc)

            if (this.resdesc === 1) {

              this.descuentillo = false;
              this.descuento = 1;

            } else {

              this.descuentillo = true;
              this.descuento = this.resdesc;
              this.descuento = 1 - this.descuento
            }
          } catch (error) {

            this.descuentillo = true;
            this.descuento = this.resdesc;
            this.descuento = 1 - this.descuento
          }

          this.cargando = false;
          this.colorDefault()

        })
        // this.getDescripciones();
      } catch (error) {
        this.cargandoProductoInfo = 1;
        this.cargandoImagenes = 1;
        this.cargando = false;
      }
    })
    this.nuevaFecha = this.datePipe.transform(this.fechita, "MM-dd-yyyy");
  }

  onPageChange($event: PageEvent) {
    /*this.productoService.getDescuento(jsondescuento).subscribe(resdesc =>{
        this.resdesc = resdesc;
  
        if (this.resdesc === 1) {
        
          this.descuentillo = false;
          this.descuento = 1;
        }else{
        
          this.descuentillo = true;
          this.descuento = this.resdesc;
          this.descuento = 1- this.descuento
        }
    });*/
    this.currentItemsToShow = this.arrayMarcas.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  seleccionacolor(nombreColor: any, id_attribute: number, id_group: number, colvar: any) {
    this.varColorSelected = colvar;
    this.colorSeleccionado = nombreColor;
    this.carritoclick = 1;
    if (document.getElementsByClassName('selected selector-color').length > 0) {
      document.getElementsByClassName('selected selector-color')[0].className =
        document
          .getElementsByClassName('selected selector-color')[0]
          .className.replace('selected', '');
    }
    var cuadro = document.getElementById('' + id_attribute);
    if (cuadro) cuadro.className = 'selected ' + cuadro.className;

    this.cambiaSku();
    this.color = document.getElementsByClassName(
      'selected selector-color'
    )[0].className;
    let colorsito = document.getElementsByClassName('selected');
    if (colorsito.length > 0) {
      this.color = '' + colorsito[0].children[0].getAttribute('title');
    }
  }
  cambiaSku() {
    try {
      var ladata = this.productoData[0];
      var variables = ladata.detalles.variables;
      if (document.getElementsByClassName('selected selector-color').length > 0) {
        var selecciones = [];
        selecciones.push({
          id_group: document
            .getElementsByClassName('selected selector-color')[0]
            .className.split('id_group_')[1]
            .split(' ')[0],
          id_attribute: document.getElementsByClassName(
            'selected selector-color'
          )[0].id,
        });
        if (document.getElementsByName('atributos').length > 0) {
          var selectores = document.getElementsByName('atributos');
          for (var i = 0; i < selectores.length; i++) {
            var sl = selectores[i];
            selecciones.push({
              id_group: sl.classList[0],
              id_attribute: (<HTMLInputElement>sl).value,
            });
          }
        }
        var pasa = true;

        for (var i = 0; i < variables.length; i++) {
          pasa = false
          var nkeys = Object.keys(variables[i].atributos)
          var aciertos = 0
          var nvalor = 0

          for (var j = 0; j < nkeys.length; j++) {
            for (var k = 0; k < selecciones.length; k++) {
              if (selecciones[k].id_group == nkeys[j]) {
                if (variables[i].atributos[selecciones[k].id_group] == selecciones[k].id_attribute) {
                  aciertos++
                }
              }
            }
          }
          if (aciertos == nkeys.length) {

            pasa = true
            nvalor = i
            i = variables.length + 10
          }

          if (pasa) {


            ladata.sku = variables[nvalor].reference;


            var lrf = document.getElementById('referencia');
            var uv = document.getElementById('unidadVenta');
            this.id_product_attribute = variables[nvalor].id_product_attribute


            if (lrf) {
              lrf.innerText = variables[nvalor].reference.replace(/-R$/, '');
              var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
              referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
              this.referenciaChida = referenciaLimpia;

              this.getExistencias(referenciaLimpia, 'CHIH');
              this.getPrecioUnitario(referenciaLimpia, this.unidadVenta);
              this.getExistencias2(referenciaLimpia);

              // if(uv)
              // {
              //   uv.innerText = variables[nvalor].unidadVenta.replace(/-R$/, '');
              //   var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              //   unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];


              // }
            }
            i = variables.length + 100;
            this.crr_sel = 0;
            this.crr_indx = 0;
          }
        }
        if (!pasa) {
          if (
            this.crr_sel <=
            document.getElementsByName('atributos').length - 1
          ) {
            var elselect = document.getElementsByName('atributos')[this.crr_sel];
            if (
              (<HTMLSelectElement>elselect).options.length >
              this.crr_indx + 1
            ) {
              (<HTMLSelectElement>elselect).selectedIndex = this.crr_indx;
              this.crr_indx++;
            } else {
              this.crr_sel++;
              this.crr_indx = 0;
            }
            this.cambiaSku();
            var lrf = document.getElementById('referencia');

            var uv = document.getElementById('unidadVenta');
            this.id_product_attribute = variables[this.crr_indx].id_product_attribute


            if (lrf) {
              lrf.innerText = variables[i].reference.replace(/-R$/, '');
              var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
              referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
              this.referenciaChida = referenciaLimpia;

              this.getExistencias(referenciaLimpia, 'CHIH');
              this.getPrecioUnitario(referenciaLimpia, this.unidadVenta);
              this.getExistencias2(referenciaLimpia);
              // if(uv)
              // {
              //   uv.innerText = variables[i].unidadVenta.replace(/-R$/, '');
              //   var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              //   unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];

              // this.getPrecioUnitario(referenciaLimpia,unidadventLimpia);
              // }
            }


          } else {
            this.crr_sel = 0;
            this.crr_indx = 0;
          }
        }
      }
    } catch (error) {

    }

  }


  colorDefault() {
    setTimeout(() => {



      try {
        let span = document.getElementsByClassName('id_group_2 selector-color')[0].children[0] as HTMLElement;
        if (span) {
          span.click();
        }
      } catch (error) {
        this.getExistencias(this.productoData[0].sku, 'CHIH');
        this.getPrecioUnitario(this.productoData[0].sku, this.unidadVenta);
        this.getExistencias2(this.productoData[0].sku);
      }

      try {
        let span2 = document.getElementsByClassName('id_group_7 selector-color')[0].children[0] as HTMLElement;
        if (span2) {
          span2.click();
        }
      } catch (error) {
        this.getExistencias(this.productoData[0].sku, 'CHIH');
        this.getPrecioUnitario(this.productoData[0].sku, this.unidadVenta);
        this.getExistencias2(this.productoData[0].sku);

      }




    }, 1500);


  }

  getPrecioUnitario(reference: string, medida: string) {
    this.cargando = true;
    ////console.log(reference);
    ////console.log(medida);
    this.cargandoPrecio = 0
    /*if (reference == '' || reference == null || reference == undefined || reference == 'undefined' || medida == '' || medida == null || medida == undefined || medida == 'undefined') {
      return;
    }*/
    let objetoPost = {
      // CustAccount: "C000041928",
      // InventLocationId: "CHIHCONS",
      // InventSiteId: "CHIH",
      // ItemId: reference,
      // PercentCharges: 0,
      // UnitId: medida,
      // amountQty: 1,
      // company: "ATP",
      // currencyCode: "MXN",
      // transDate: this.nuevaFecha

      "custAccount": "CL0001348",
      "itemId": reference,
      "amountQty": 1,
      "transDate": this.nuevaFecha,
      "currencyCode": "MXN",
      "inventSiteId": "CHIH",
      "inventLocationId": "CHIHCONS",
      "company": "LIN",
      "unitId": medida,
      "percentCharges": 0
    }


    this.productoService.getPrecioUnitario(objetoPost).subscribe((response: any) => {

      try {
        this.precioDyn = response.data;
        this.precioDyn = this.precioDyn * 1.16
        this.cargandoPrecio = 1;
        this.cargoprecio = true;
        this.cargando = false;
      } catch (error) {
        this.precioDyn = 0
        this.cargandoPrecio = 1;
        this.cargoprecio = true;
        this.cargando = false;
        //console.log('error precio unitario ', objetoPost);
      }


    })


  }

  getExistencias(reference: string, sitio: any) {
    this.cargando = true;
    this.cargandoInventario = 0;
    ////console.log(reference);
    //this.cargando = true;
    /*if (reference == '' || reference == null || reference == undefined || reference == 'undefined') {
      return;
    }*/
    // this.existencias = [];
    this.cargandoInventario = 0;

    this.productoService.getExistencias(reference, sitio).subscribe((response: any) => {
      let existenciasTem: any;
      existenciasTem = response;
      try {
        this.productName = response.data.productName;
        this.productName = this.productName.replace(/"/, "")
        this.cargando = false;

        if (response.data != null) {
          // this.existencias = response.data.availableOnHandQuantity;
          this.cargando = false;
          this.cargandoInventario = 1;
        }
      } catch (error) {
        this.productName = this.productoData[0].title + " " + this.color
        this.cargando = false;
        // this.existencias = 0;
        this.cargandoInventario = 1;
        //console.log('error get existencias ', reference, sitio);
      }

    });



  }

  getExistencias2(reference: any) {
    this.cargandoInventario = 0;
    this.existencias = [];
    this.productoService.getPrecioUnitario2(reference).subscribe(otroprecios => {
      try {
        let existenciasTem: any;
        existenciasTem = otroprecios;

        let invavance = otroprecios.data.availableOnHandQuantityATP;

        invavance = Math.floor(invavance * .3);

        let invlideart = otroprecios.data.availableOnHandQuantityLideart;

        this.existencias = invlideart + invavance;


        this.cargandoInventario = 1;
      } catch (error) {
        this.existencias = 0
        this.cargandoInventario = 1;
      }

    })
  }
  async ShowError() {
    // const toast = await this.toastController.create({
    //   message: 'Inventario Insuficiente',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();

    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Inventario Insuficiente",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });
  }

  async ShowError3() {
    // const toast = await this.toastController.create({
    //   message: 'No puedes llevar al Carrito mas de la Cantidad en Existencias',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No puedes llevar al Carrito mas de la Cantidad en Existencias",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  async ShowError2() {
    // const toast = await this.toastController.create({
    //   message: 'Necesitas Seleccionar Color',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Necesitas Seleccionar Color",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }
}
