<section class="blogsitoDesk" style=" margin-top: 100px;">
  <div class="bodyBlog">
    <div class="taitl-cont">
      <span class="taitl">Blog</span>
    </div>
    <div class="grida-cont">
      <div class="carda" *ngFor="let item of blogArray">
        <a [routerLink]="['/articulo-blog',item.id_post]" style="width: 100%;">
          <img src="https://new.lideart.com.mx/img/ybc_blog/post/thumb/{{item.thumb}}" />
        </a>
        <a class="title-blog">{{item.title}}</a>
        <p class="textito" [innerHTML]="item.short_description"></p>
        <a class="rmore" [routerLink]="['/articulo-blog',item.id_post]">Leer Mas</a>
      </div>
    </div>
  </div>
</section>

<div class="cont-bog-cel">
  <div class="wrap-bloc-cont">
    <div class="blog-card" *ngFor="let item of blogArray">
      <img class="img-blog" src="https://new.lideart.com.mx/img/ybc_blog/post/thumb/{{item.thumb}}" />
      <span class="blog-title">{{item.title}}</span>
      <p class="textito" [innerHTML]="item.short_description"></p>
      <a class="leer-blog" href="https://new.lideart.com.mx/#/articulo-blog/{{item.id_post}}" target="blank">Leer
        Mas</a>
    </div>
  </div>

</div>