import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
import Fuse from 'fuse.js';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';

@Component({
  selector: 'app-catalogo-paquetes',
  standalone: true,
  imports: [MatPaginatorModule, CommonModule, RouterModule, IonicModule],
  templateUrl: './catalogo-paquetes.component.html',
  styleUrls: ['./catalogo-paquetes.component.scss'],
  providers: [ApiProductosService, CarritoServiceService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CatalogoPaquetesComponent implements OnInit {

  public page = 1;
  perPage = 10;
  totalData = 0;
  totalPage = 10;
  public totalItems: number = 0;
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  productosCatalogo: any;
  precio: any;
  id_product: any;
  cp: number = 1;
  id_cat: any;
  respaq: any;
  cargando = false;
  public isDesk: boolean = false;
  public isCel: boolean = false;
  maxVisibleColors = 10; // Número máximo de colores visibles antes de mostrar los puntos
  showAllColors = false;
  showAllColorsMap: { [key: number]: boolean } = {};

  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  }

  constructor(
    private productoService: ApiProductosService,
    public modalCtrl: ModalController,
    private activatedRoute: ActivatedRoute,
    private shoppingCart: CarritoServiceService,
    private location: Location,
    private router: Router,
    private titleService: Title,
    public loginService: LoginService,
    private servicioWishlist: ServiciowishlistService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
    }

    this.activatedRoute.params.subscribe(params => {
      this.id_cat = params['id_categoria'];
    })
    this.shoppingCart.cartItems.subscribe(d => {
      if (d && Array.isArray(d)) {
        this.totalItems = d.length;
      } else {
        this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
      }
    });

    if (this.id_cat == 494) {
      this.titleService.setTitle("Paquetes Cricut");
    }

    //ssr antes
    /*this.activatedRoute.params.subscribe(params=>{
      this.id_cat = params['id_categoria'];
      this.productoService.getCategorias(this.id_cat).subscribe(data =>{
        this.productosCatalogo = data;
        this.totalData = this.productosCatalogo.length;
        this.precio = this.productosCatalogo.precio;
        this.id_product = this.productosCatalogo.id_product;
      })
    })*/

    this.getArticulos(this.id_cat);

  }

  idk(event: Event, productData: any) {
    event.stopPropagation();
    const userData = this.loginService.getUserData();

    if (!userData) return


    const jsonWishlist = {
      usuario: userData.correo,
      id_product: productData.id,
      title: productData.title,
      sku: productData.sku,
      quantiity: 1,
      imagen: productData.image_link,
      precio: productData.precio
    }

    this.servicioWishlist.sendWishlist(jsonWishlist).subscribe(data => {
      if (data == 0) {
        // Pendiente alerta para decir que el producto ya se encuentra en la wishlist;
      } else {
        // Pendiente alerta para decir que el producto fue agregado correctamente;
      }
    })

  }

  goBack() {
    this.location.back();
  }

  navigateToDescripcionProducto(c: any, b: any, a: any, d: any, e: any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    const paquete = d; // Set the link_rewrite dynamically
    const sku = e; // Set the link_rewrite dynamically
    this.productoService.esPaquete(sku).subscribe(paquetillo => {
      this.respaq = paquetillo;
      if (this.respaq == 1) {
        this.router.navigateByUrl(`/paquetes/${id_product}-${sku}` + ".html");
      } else {
        this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}` + ".html");
      }
    })


  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

  getArticulos(id_cat: any) {
    this.cargando = true;
    this.productoService.getCategorias(id_cat).subscribe(data => {
      this.productosCatalogo = data;
      this.totalData = this.productosCatalogo.length;
      this.precio = this.productosCatalogo.precio;
      this.id_product = this.productosCatalogo.id_product;
      this.cargando = false;
    })
  }

  toggleShowAllColors(productId: number) {
    this.showAllColorsMap[productId] = !this.showAllColorsMap[productId];
  }
}
