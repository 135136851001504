import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Producto } from '../../Models/Productos';
import { Usuario } from '../../Models/usuario';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe, isPlatformBrowser } from '@angular/common';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ICreateOrderRequest, IPayPalConfig, NgxPayPalModule } from 'ngx-paypal';
import { LoginService } from '../../services/login.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { AlertController, IonModal, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { ConektaService } from '../../services/conekta.service';
import { ApiProductosService } from '../../services/api-productos.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';

@Component({
  selector: 'app-mesa-regalos-caja',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatSlideToggleModule,
    NgxPayPalModule
  ],
  templateUrl: './mesa-regalos-caja.component.html',
  styleUrls: ['./mesa-regalos-caja.component.scss'],
})
export class MesaRegalosCajaComponent implements OnInit {

  @ViewChild('paypalcont') paypalcont!: ElementRef<HTMLInputElement>;
  paypalIs = false;

  @ViewChild('modal_wp') modal_wp!: ElementRef<HTMLInputElement>;
  modal_wpIs = false;

  @ViewChild('modal_nd') modal_nd!: ElementRef<HTMLInputElement>;
  modalNdIs = false;
  @ViewChild(IonModal) modal!: IonModal;
  @ViewChild(IonModal) modal2!: IonModal;
  @ViewChild(IonModal) modal3!: IonModal;
  @ViewChild(IonModal) modal4!: IonModal;
  @ViewChild(IonModal) modal5!: IonModal;

  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean = false;
  public showCancel: boolean = false;
  public showError: boolean = false;

  public product: any;
  public searchbarVisible: boolean = false;
  usuario!: Usuario;
  public grandTotal!: number;
  nombre!: string;
  apellido!: string;
  datosUsuario: any = [];
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  nombreEstado: any;
  public numeroResultados: number = 0;
  direccion: any = [];
  radioDireccion = 0;
  checarDireccion = true;
  lineas: any = [];
  lineasBomnitas: any = [];
  public respuestaCotizacion: string = "";
  cotDatos: any;
  items: Producto[] = [];
  peso: any;
  paqueteExpress: any;
  estafeta: any;
  recoge = false;
  valorRadio = 0;
  productitos: any = []
  valorPaqueteExpress = true;
  id_regalo: any;
  iframe = "";
  datosWebPay: any = [];
  carrito: any;
  urlsafe!: SafeResourceUrl;
  tipoEnvio = "Paquete Express";
  radioEnvio = false;
  respuestapago: any;
  respuesta: any = [];
  valorEnvio = 1;
  radioPago = true;
  metodoPago = "";
  postcode = "";
  address1 = ""
  id_state = ""
  city = ""
  colony = "";
  name!: string;
  totalEnvio = 191.40;
  facturame = false;
  facturame2 = false;
  paymentMethod: any;
  PaymentTermsName: any;
  countyId: any;
  modoEntrega = "PAQUETERIA";
  codigo: any;
  datoscounty: any = []
  respuestillaOV: any = [];
  respuestaOV: any;
  datosdelcarro: any = [];
  calculo = 0;
  numero_carro: any;
  cargando = false;
  sepuede = false;
  arregloitems: any[] = [];
  urlconekta = "";
  iframeconekta!: SafeResourceUrl;
  paquetaxos: any = [];
  arreglito: any;
  codigoPostal: any;
  oxxxo = "";
  metodinPago = "00";
  respFact: any;
  noCuenta = '65-50630503-1';
  clabe = '014150655063050317';
  banco = 'Santander';
  jsonMensaje: any;
  porcentaje = 0;
  email: any;
  htmlfactura: any;
  alias: any;
  empresa: any;
  telefono = ""
  telefonito: any;
  zipcode: any;
  address1Form: any;
  address2: any;
  id_stateForm: any;
  cityForm: any;
  colonyForm: any;
  estado: any;
  razonSocial: any;
  rfc: any;
  DirFiscal: any;
  FormaPago: any;
  UsoCFDI: any;
  customerID: any;
  usocfdi = "S01";
  regimenfiscal = "606";
  now!: string;
  public fechaActual: number = Date.now();
  public fechaActual2: number = Date.now();
  cliente: any = [];
  orden: any = [];
  formattedDate!: any;
  facturitadatos: any = [];
  objetoOrdenVenta: any;
  mensajazo: any;
  objetoCompra: any;
  porcentajeparalinea: any;
  mensajepasa = "";
  medioErrorAlv = 0;
  totalPaypal = "";
  intervalopago: any;
  acepto = false;
  currentDateTime2: any
  totalpaypalsito: any;
  faltaInformacionDireccion = false;
  codiguito: any;

  constructor(private usuarioService: LoginService,
    private checkoutService: CheckoutServiceService,
    private shoppingCart: CarritoServiceService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastController: ToastController, public modalCtrl: ModalController,
    public loadingController: LoadingController, /*private payPal: PayPal*/ public http: HttpClient, public renderer: Renderer2,
    private conekta: ConektaService,
    private productoService: ApiProductosService, public alertController: AlertController, private datePipe: DatePipe,
    @Inject(PLATFORM_ID) private platformId: Object, private servicioWishlist: ServiciowishlistService) { }

  ngOnInit() {


    if (isPlatformBrowser(this.platformId)) {


      this.activatedRoute.params.subscribe(params => {
        this.id_regalo = params['id_regalo'];
      })

      this.usuarioService.usuario.subscribe((res: any) => {
        this.cargando = true;
        this.usuario = res;

        this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
          this.datosUsuario = data;
          this.nombre = this.datosUsuario.nombre;
          this.apellido = this.datosUsuario.apellido;
          this.customerID = this.datosUsuario.customerID;
          //console.log('cicla 5');
          this.cargando = true;

          this.checkoutService.getDireccionMesa(this.usuario.usuario).subscribe(res => {
            this.cargando = true;
            try {


              this.direccion = res;
              this.nombreEstado = this.direccion[0].estado
              this.address1 = this.direccion[0].direccion;
              this.address2 = this.direccion[0].direccion;
              this.alias = this.direccion[0].nombre;
              this.colony = this.direccion[0].colonia;
              this.city = this.direccion[0].ciudad;
              this.telefono = this.direccion[0].celular;
              this.telefonito = this.direccion[0].celular;
              this.postcode = this.direccion[0].codigo_postal;

              var countyJson = {
                "codigo_postal": this.direccion[0].postcode,
                "asentamiento": this.direccion[0].colony

              }

              this.usuarioService.checaCountyID(JSON.stringify(countyJson)).subscribe(countyID => {
                this.datoscounty = countyID;

                this.countyId = this.datoscounty[0].CountyId;

                if (this.countyId == null || this.countyId == undefined) {
                  this.countyId = this.datoscounty[0].id_codigo_postal + "_" + this.direccion[0].postcode
                }

                this.city = this.nombreEstado.toUpperCase()
                if (this.city == "CHIHUAHUA") {
                  this.codigo = "CHH"
                }
                if (this.city == "AGUASCALIENTES") {
                  this.codigo = "AGS"
                }
                if (this.city == "BAJA CALIFORNIA") {
                  this.codigo = "BCA"
                }
                if (this.city == "BAJA CALIFORNIA SUR") {
                  this.codigo = "BCS"
                }
                if (this.city == "CAMPECHE") {
                  this.codigo = "CAM"
                }
                if (this.city == "CIUDAD DE MEXICO") {
                  this.codigo = "CDM"
                }
                if (this.city == "COAHUILA") {
                  this.codigo = "COA"
                }
                if (this.city == "COLIMA") {
                  this.codigo = "COL"
                }
                if (this.city == "DURANGO") {
                  this.codigo = "DGO"
                }
                if (this.city == "DISTRITO FEDERAL") {
                  this.codigo = "DIF"
                }
                if (this.city == "GUERRERO") {
                  this.codigo = "GRO"
                }
                if (this.city == "GUANAJUATO") {
                  this.codigo = "GTO"
                }
                if (this.city == "HIDALGO") {
                  this.codigo = "HGO"
                }
                if (this.city == "JALISCO") {
                  this.codigo = "JAL"
                }
                if (this.city == "ESTADO DE MEXICO") {
                  this.codigo = "MEX"
                }
                if (this.city == "MICHOACAN") {
                  this.codigo = "MIC"
                }
                if (this.city == "MORELOS") {
                  this.codigo = "MOR"
                }
                if (this.city == "NAYARIT") {
                  this.codigo = "NAY"
                }
                if (this.city == "NUEVO LEON") {
                  this.codigo = "NLE"
                }
                if (this.city == "OAXACA") {
                  this.codigo = "OAX"
                }
                if (this.city == "PUEBLA") {
                  this.codigo = "PUE"
                }
                if (this.city == "QUERETARO") {
                  this.codigo = "QRO"
                }
                if (this.city == "QUINTANA ROO") {
                  this.codigo = "ROO"
                }
                if (this.city == "SINALOA") {
                  this.codigo = "SIN"
                }
                if (this.city == "SAN LUIS POTOSI") {
                  this.codigo = "SLP"
                }
                if (this.city == "SONORA") {
                  this.codigo = "SON"
                }
                if (this.city == "TABASCO") {
                  this.codigo = "TAB"
                }
                if (this.city == "TAMAULIPAS") {
                  this.codigo = "TAM"
                }
                if (this.city == "TLAXCALA") {
                  this.codigo = "TLA"
                }
                if (this.city == "VERACRUZ") {
                  this.codigo = "VER"
                }
                if (this.city == "YUCATAN") {
                  this.codigo = "YUC"
                }
                if (this.city == "ZACATECAS") {
                  this.codigo = "ZAC"
                }

              })


            } catch (error) {
              this.medioErrorAlv = 1;
              this.cargando = false;
            }
            try {

              this.shoppingCart.cartItems3.subscribe(data => {
                this.cargando = true;
                this.items = data;
                if (this.items) {
                  // this.getPeso(this.items);
                  let subs1 = 0;

                  for (const item of data) {
                    subs1 += item.weight * item.quantity;

                    this.peso = subs1;

                  }
                  // this.getTotal(this.items);
                  // this.agarrameElTotal(this.items);
                  let subs2 = 0;

                  let jsonCupon = { "email": this.usuario.usuario }
                  this.usuarioService.revisaDescuento(jsonCupon).subscribe(codigos => {
                    this.datosdelcarro = codigos;

                    if (this.datosdelcarro && this.datosdelcarro.length > 0) {
                      this.codiguito = this.datosdelcarro[0].codigo;
                    } else {
                      console.warn('datosdelcarro está indefinido o vacío');
                      this.codiguito = null; // O cualquier valor por defecto que tenga sentido en tu contexto
                    }

                    if (this.datosdelcarro && this.datosdelcarro.length > 0) {
                      this.numero_carro = this.datosdelcarro[0].id_cart;
                    } else {
                      console.warn('datosdelcarro está indefinido o vacío');
                      this.numero_carro = null; // O cualquier valor por defecto que tenga sentido en tu contexto
                    }


                    if (this.datosdelcarro[0].descuento == 1) {
                      for (const item of data) {
                        subs2 += item.precio * item.quantity;

                        this.porcentaje = this.datosdelcarro[0].porcentaje;

                        this.grandTotal = subs2;
                        this.calculo = this.grandTotal * this.datosdelcarro[0].porcentaje;
                        this.grandTotal = this.grandTotal - this.calculo;


                      }
                    } else {
                      for (const item of data) {
                        subs2 += item.precio * item.quantity;

                        this.grandTotal = subs2;
                        this.grandTotal = this.grandTotal + this.totalEnvio;
                      }
                    }
                    this.cargando = false;

                  })

                }
              });
              this.cargando = false;
            } catch (error) {
              this.medioErrorAlv = 1;
              this.cargando = false;
            }


          })
        })


      });



    }


  }


  private initConfig(): void {




    this.payPalConfig = {

      currency: 'MXN',
      clientId: 'AV3FnonRlJMuV_2PPWLJucZbVSMwTPeU6hDfMOnSQHZ2TWR6pBK7E9xAZLy3VPqCsWLOdquZk8Vfz-0Z',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'MXN',
            value: this.totalpaypalsito.toString(),
            breakdown: {
              item_total: {
                currency_code: 'MXN',
                value: this.totalpaypalsito.toString(),

              }
            }
          },
          // items: this.getItemsCarrito()
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {



        actions.order.get().then((details: any) => {

        });
        this.relizarCompra();

      },
      onClientAuthorization: (data) => {

        this.showSuccess = true;
      },
      onCancel: (data, actions) => {

        this.showCancel = true;

      },
      onError: (err: any) => {

        this.showError = true;
      },
      onClick: (data, actions) => {

        // this.resetStatus();
      }
    };
  }

  relizarCompra() {

    //console.log(1)
    // this.transferencia();
    //console.log("paso funcion transferencia");


    if (this.facturame2 == false) {
      //console.log("no debe entrar aqui")

      this.facturameprro();
    } else {

      //console.log(2)

      this.cargando = true;

      //console.log(3)

      for (let item of this.items) {
        var jsonWishlist = {
          "usuario": this.usuario.usuario,
          "sku": item.sku

        }
        this.servicioWishlist.eliminarWishlist(jsonWishlist).subscribe(data => {


        })


        var jsonRegalo = {
          "usuario": this.usuario.usuario,
          "sku": this.id_regalo

        }

        this.servicioWishlist.eliminaRegalo(jsonRegalo).subscribe(data => {


        })

        if (item.paquete == 1) {

          this.productoService.getInfoPaquetes(item.sku).subscribe(paquetaxo => {
            this.paquetaxos = paquetaxo;

            if (this.datosdelcarro[0].descuento == 1) {
              for (let index = 0; index < this.paquetaxos.length; index++) {
                let lineasLocal = {
                  salesQuotationNumber: null,
                  dataAreaId: "lin",
                  itemNumber: this.paquetaxos[index].sku,
                  requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                  shippingWarehouseId: "CHIHCONS",
                  fixedPriceCharges: 0,
                  salesTaxGroupCode: "VTAS",
                  stF_Category: null,
                  inventoryLotId: null,
                  orderedSalesQuantity: 0,
                  salesOrderNumber: null,
                  descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                  usuario: null,
                  salesPrice: Number(this.paquetaxos[index].precio_total) * this.porcentajeparalinea,
                  ShippingSiteId: "CHIH",
                  LineDiscountPercentage: 0
                };
                this.lineas.push(lineasLocal);
              }
            } else {
              for (let index = 0; index < this.paquetaxos.length; index++) {
                let lineasLocal = {
                  salesQuotationNumber: null,
                  dataAreaId: "lin",
                  itemNumber: this.paquetaxos[index].sku,
                  requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                  shippingWarehouseId: "CHIHCONS",
                  fixedPriceCharges: 0,
                  salesTaxGroupCode: "VTAS",
                  stF_Category: null,
                  inventoryLotId: null,
                  orderedSalesQuantity: 0,
                  salesOrderNumber: null,
                  descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                  usuario: null,
                  salesPrice: Number(this.paquetaxos[index].precio_total),
                  ShippingSiteId: "CHIH",
                  LineDiscountPercentage: 0
                };
                this.lineas.push(lineasLocal);
              }
            }


          })
        }

        if (item.paquete != 1) {

          if (this.datosdelcarro[0].descuento == 1) {
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: item.sku,
              requestedSalesQuantity: Number(item.quantity),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title.replace(/"/, ""),
              usuario: null,
              salesPrice: Number(item.precio / 1.16) * this.porcentajeparalinea,
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };

            this.lineas.push(lineasLocal);
          } else {
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: item.sku,
              requestedSalesQuantity: Number(item.quantity),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title.replace(/"/, ""),
              usuario: null,
              salesPrice: Number(item.precio / 1.16),
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };

            this.lineas.push(lineasLocal);
          }

        }
      }
      //console.log(4)

      if (this.recoge == false && this.valorEnvio == 2) {
        let lineasPaqueteria = {
          salesQuotationNumber: null,
          dataAreaId: "lin",
          itemNumber: '9999-0850',
          requestedSalesQuantity: 1,
          shippingWarehouseId: "CHIHCONS",
          fixedPriceCharges: 0,
          salesTaxGroupCode: "VTAS",
          stF_Category: null,
          inventoryLotId: null,
          orderedSalesQuantity: 0,
          salesOrderNumber: null,
          descripcionLinea: "GUIA VIRTUAL FLETE TERRESTRE ESTAFETA 5 KILOS",
          usuario: null,
          salesPrice: Number(this.estafeta),
          ShippingSiteId: "CHIH",
          LineDiscountPercentage: 0
        };
        this.lineas.push(lineasPaqueteria);
      }

      //console.log(5)

      if (this.recoge == false && this.valorEnvio == 1) {
        let lineasPaqueteria = {
          salesQuotationNumber: null,
          dataAreaId: "lin",
          itemNumber: '9999-9003',
          requestedSalesQuantity: 1,
          shippingWarehouseId: "CHIHCONS",
          fixedPriceCharges: 0,
          salesTaxGroupCode: "VTAS",
          stF_Category: null,
          inventoryLotId: null,
          orderedSalesQuantity: 0,
          salesOrderNumber: null,
          descripcionLinea: "SERVICIO PAQUETE EXPRESS",
          usuario: null,
          salesPrice: Number(this.totalEnvio),
          ShippingSiteId: "CHIH",
          LineDiscountPercentage: 0

        };
        this.lineas.push(lineasPaqueteria);
      }

      //console.log(6)


      this.arreglito = this.lineas;

      setTimeout(() => {

        if (this.facturame == true) {
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: this.customerID,
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: "MOTV_00032",
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.direccion[0].address1 + "\n" + this.direccion[0].city + "," + this.codigo + ",MEX\n" + this.direccion[0].postcode,
            deliveryAddressStreet: this.address1,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.city,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.postcode,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }

        } else if (this.usuario.usuario == "bemixom200@bongcs.com") {
          //console.log("Si entro a prueba");
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: "CL0001348",
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: "MOTV_00049",
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.direccion[0].address1 + "\n" + this.direccion[0].city + "," + this.codigo + ",MEX\n" + this.direccion[0].postcode,
            deliveryAddressStreet: this.address1,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.city,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.postcode,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }
        } else {
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: "CL0001348",
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: "MOTV_00032",
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.direccion[0].address1 + "\n" + this.direccion[0].city + "," + this.codigo + ",MEX\n" + this.direccion[0].postcode,
            deliveryAddressStreet: this.address1,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.city,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.postcode,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }
        }



        this.checkoutService.guardaObjetoCot(JSON.stringify(this.objetoCompra), this.usuario.usuario).subscribe(guarda => {

        })


        this.checkoutService.addCotizacionDynamics(this.objetoCompra).subscribe(response => {


          this.cotDatos = response;
          this.respuestaCotizacion = this.cotDatos.data.salesQuotationNumber;
          // this.lineas = this.cotDatos.lineas;



          if (this.facturame == true) {
            this.objetoOrdenVenta = {
              DeliveryAddressCity: this.direccion[this.radioDireccion].city,
              DeliveryAddressCountryRegionId: "MEX",
              DeliveryAddressCountyId: this.countyId,
              DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressStateId: this.codigo,
              DeliveryAddressStreet: this.direccion[this.radioDireccion].address1,
              DeliveryAddressStreetNumber: "380",
              DeliveryAddressZipCode: this.direccion[this.radioDireccion].postcode,
              lineas: this.lineas,
              PaymentTermsName: this.PaymentTermsName,
              SATPurpose: "",
              Usuario: "ventascat22",
              dataAreaId: "LIN",
              quotationId: this.respuestaCotizacion,
              _AccountNum: "CL0001348",
            }
          } else {
            this.objetoOrdenVenta = {
              DeliveryAddressCity: this.direccion[this.radioDireccion].city,
              DeliveryAddressCountryRegionId: "MEX",
              DeliveryAddressCountyId: this.countyId,
              DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressStateId: this.codigo,
              DeliveryAddressStreet: this.direccion[this.radioDireccion].address1,
              DeliveryAddressStreetNumber: "380",
              DeliveryAddressZipCode: this.direccion[this.radioDireccion].postcode,
              lineas: this.lineas,
              PaymentTermsName: this.PaymentTermsName,
              SATPurpose: this.usocfdi,
              Usuario: "ventascat22",
              dataAreaId: "LIN",
              quotationId: this.respuestaCotizacion,
              _AccountNum: this.customerID,
            }
          }




          this.checkoutService.convertirOrdenVenta(this.objetoOrdenVenta).subscribe(respuestilla => {



            var jsonCupon = { "email": this.usuario.usuario, "total": this.grandTotal.toFixed(2) };
            this.productoService.insertaLosPuntos(jsonCupon).subscribe(codigos => {

            })

            this.respuestillaOV = respuestilla

            this.respuestaOV = this.respuestillaOV.data.salesOrderNumber;


            if (this.metodoPago == 'Transferencia') {
              this.jsonMensaje = {
                "nombreCliente": this.nombre + " " + this.apellido,
                "noCliente": this.customerID,
                "folio": this.respuestaOV,
                "importe": (this.grandTotal + this.totalEnvio).toFixed(2),
                "correoCliente": this.usuario.usuario,
                "direccion": this.address1,
                "CFDI": this.usocfdi,
                "razonSocial": this.razonSocial,
                "rfc": this.rfc,
                "DirFiscal": this.DirFiscal,
                "FormadePago": this.metodoPago,
                "Cuenta": this.noCuenta,
                "Clabe": this.clabe,
                "Banco": this.banco,
                "TelPrincipal": "6146051278",
                "TelComprador": "6143609156"
              }
            } else {
              this.jsonMensaje = {
                "nombreCliente": this.nombre + " " + this.apellido,
                "noCliente": this.customerID,
                "folio": this.respuestaOV,
                "importe": (this.grandTotal + this.totalEnvio).toFixed(2),
                "correoCliente": this.usuario.usuario,
                "direccion": this.address1,
                "CFDI": this.usocfdi,
                "razonSocial": this.razonSocial,
                "rfc": this.rfc,
                "DirFiscal": this.DirFiscal,
                "FormadePago": this.metodoPago,
                "TelPrincipal": "6146051278",
                "TelComprador": "6143609156"
              }
            }


            // formatDate(this.fechaActual2,'YYYY-MM-DD','');

            for (let item of this.items) {

              let lineasBomnitas = {
                nombreProducto: item.title,
                salesQuotationNumber: null,
                dataAreaId: "lin",
                itemNumber: item.sku,
                requestedSalesQuantity: Number(item.quantity),
                shippingWarehouseId: "CHIHCONS",
                fixedPriceCharges: 0,
                salesTaxGroupCode: "VTAS",
                stF_Category: null,
                inventoryLotId: null,
                orderedSalesQuantity: 0,
                salesOrderNumber: null,
                descripcionLinea: item.title,
                usuario: null,
                salesPrice: Number(item.precio),
                ShippingSiteId: "CHIH",
                LineDiscountPercentage: 0
              };

              this.lineasBomnitas.push(lineasBomnitas);

            }

            let html = "<p>Pais: Mexico</p>"
              + "<p>Estado: " + this.nombreEstado + "</p>"
              + "<p>Ciudad: " + this.direccion[this.radioDireccion].city + "</p>"
              + "<p>Colonia: " + this.direccion[this.radioDireccion].colony + "</p>"
              + "<p>CP: " + this.direccion[this.radioDireccion].postcode + "</p>"
              + "<p>Direccion: " + this.direccion[this.radioDireccion].address1 + "</p>";


            if (this.facturame == true) {
              this.htmlfactura = "<p>Pais: Mexico</p>"
                + "<p>Estado: " + this.nombreEstado + "</p>"
                + "<p>Ciudad: " + this.direccion[this.radioDireccion].city + "</p>"
                + "<p>Colonia: " + this.direccion[this.radioDireccion].colony + "</p>"
                + "<p>CP: " + this.direccion[this.radioDireccion].postcode + "</p>"
                + "<p>Direccion: " + this.direccion[this.radioDireccion].address1 + "</p>";
            } else {
              this.htmlfactura == "";
            }



            if (this.metodoPago == "Transferencia") {
              this.mensajazo = {

                "customerName": this.nombre + " " + this.apellido,
                "order_name": this.respuestaOV,
                "date": this.formattedDate,
                "payment": this.metodoPago + " || Datos de Deposito, CLABE: " + this.clabe + ", Banco :" + this.banco + ", Cuenta: " + this.noCuenta,
                "products":
                  this.lineasBomnitas
                ,
                "discounts": [
                  Number(this.calculo)
                ],
                "name_discount": this.codiguito,
                "total_products": this.grandTotal,
                "total_discounts": Number(this.calculo),
                "total_wrapping": 0,
                "total_shipping": this.totalEnvio,
                "total_tax_paid": this.grandTotal / 1.16,
                "total_paid": this.grandTotal,
                "carrier": this.modoEntrega + " " + this.mensajepasa,
                "delivery_block_html": html,
                "invoice_block_html": this.htmlfactura,
                "emailDestino": this.usuario.usuario + ',ventas@lideart.com.mx',
                "telefono": this.telefonito
              }

            } if (this.metodoPago == "Oxxo Pay") {
              this.mensajazo = {

                "customerName": this.nombre + " " + this.apellido,
                "order_name": this.respuestaOV,
                "date": this.formattedDate,
                "payment": this.metodoPago + " || Referencia de Oxxo: " + this.oxxxo,
                "products":
                  this.lineasBomnitas
                ,
                "discounts": [
                  Number(this.calculo)
                ],
                "name_discount": this.codiguito,
                "total_products": this.grandTotal,
                "total_discounts": Number(this.calculo),
                "total_wrapping": 0,
                "total_shipping": this.totalEnvio,
                "total_tax_paid": this.grandTotal / 1.16,
                "total_paid": this.grandTotal,
                "carrier": this.modoEntrega + " " + this.mensajepasa,
                "delivery_block_html": html,
                "invoice_block_html": this.htmlfactura,
                "emailDestino": this.usuario.usuario + ',ventas@lideart.com.mx',
                "telefono": this.telefonito
              }
            } if (this.metodoPago != 'Transferencia' && this.metodoPago != 'Oxxo Pay') {
              this.mensajazo = {

                "customerName": this.nombre + " " + this.apellido,
                "order_name": this.respuestaOV,
                "date": this.formattedDate,
                "payment": this.metodoPago,
                "products":
                  this.lineasBomnitas
                ,
                "discounts": [
                  Number(this.calculo)
                ],
                "name_discount": this.codiguito,
                "total_products": this.grandTotal,
                "total_discounts": Number(this.calculo),
                "total_wrapping": 0,
                "total_shipping": this.totalEnvio,
                "total_tax_paid": this.grandTotal / 1.16,
                "total_paid": this.grandTotal,
                "carrier": this.modoEntrega + " " + this.mensajepasa,
                "delivery_block_html": html,
                "invoice_block_html": this.htmlfactura,
                "emailDestino": this.usuario.usuario + ',ventas@lideart.com.mx',
                "telefono": this.telefonito
              }
            }


            this.checkoutService.mandacorreo(this.mensajazo).subscribe(masdatos => {


              if (this.usuario) {
                this.email = this.usuario.usuario
              } else {
                this.email = "";
              }
              let datos = {
                "email": this.email
              }
              this.shoppingCart.carritoFinalizo(datos).subscribe(data => {

              })




              if (this.metodoPago == 'Oxxo Pay') {
                const customerData = {
                  name: this.nombre + " " + this.apellido,
                  email: this.usuario.usuario
                }
                this.conekta.createCustomer(customerData).subscribe(datitos => {
                  this.cargando = true;
                  this.cliente = datitos;

                  let totalcompra = (this.grandTotal * 100).toString();
                  let totalenvio = (this.totalEnvio * 100).toString();


                  const thirty_days_from_now = (Math.round(Date.now() / 1000 + 60 * 60 * 24 * 30)).toString()

                  const orderData = {

                    "line_items": [{
                      "name": "Pedido Lideart App",
                      "unit_price": parseInt(totalcompra),
                      "quantity": 1
                    }],
                    "shipping_lines": [{
                      "amount": 0,
                    }],
                    "currency": "MXN",
                    "customer_info": {
                      "name": this.nombre + " " + this.apellido,
                      "email": this.usuario.usuario,
                      "phone": "+5215555555555"
                    },
                    shipping_contact: {
                      phone: '+5215555555555',
                      receiver: this.nombre + " " + this.apellido,
                      address: {
                        street1: "Direccion de Envio",
                        country: 'MX',
                        postal_code: this.postcode
                      }
                    },
                    "metadata": {
                      "datos_extra": "1234"
                    },
                    "charges": [{
                      "payment_method": {
                        "type": "cash",
                        "expires_at": thirty_days_from_now
                      }
                    }]
                  }


                  this.conekta.createOrder(orderData).subscribe(orderdata => {
                    this.cargando = false;
                    this.orden = orderdata;
                    this.oxxxo = this.orden.charges.data[0].payment_method.reference;
                    this.productitos = this.items;
                    // this.shoppingCart.deleteCart();

                    let objetoVentita = {
                      "correo": this.usuario.usuario,
                      "nombre": this.nombre + " " + this.apellido,
                      "pais": "Mexico",
                      "estado": this.nombreEstado,
                      "ciudad": this.direccion[this.radioDireccion].city,
                      "colonia": this.direccion[this.radioDireccion].colony,
                      "cp": this.direccion[this.radioDireccion].postcode,
                      "direccion": this.direccion[this.radioDireccion].address1,
                      "fecha": this.fechaActual,
                      "hora": this.now,
                      "tipo_entrega": this.tipoEnvio,
                      "metodo_pago": this.metodoPago,
                      "paqueteria": this.totalEnvio,
                      "iva": ((this.grandTotal + this.totalEnvio) * .16).toFixed(2),
                      "subtotal": (this.grandTotal / 1.16).toFixed(2),
                      "total": this.grandTotal + this.totalEnvio.toFixed(2),
                      "cotizacion": this.respuestaCotizacion,
                      "ov": this.respuestaOV,
                      "oxxo": this.oxxxo,
                      "mesa_regalos": 1
                    }

                    this.checkoutService.guarda_venta(objetoVentita).subscribe(datosVenta => {



                      // (document.getElementById("pago") as any).style.display = "none";

                      // if(window.innerWidth<992){
                      //   this.animacion('comprita');
                      // }
                      // else{
                      //   this.animacion('resumen')
                      // }
                      this.cargando = false;
                      this.router.navigate(['/resumen-compra']);
                      // (document.getElementById("resumen") as any).style.display = "flex";
                    });
                  })
                })
              } else {

                let objetoVentita = {
                  "correo": this.usuario.usuario,
                  "nombre": this.nombre + " " + this.apellido,
                  "pais": "Mexico",
                  "estado": this.nombreEstado,
                  "ciudad": this.direccion[this.radioDireccion].city,
                  "colonia": this.direccion[this.radioDireccion].colony,
                  "cp": this.direccion[this.radioDireccion].postcode,
                  "direccion": this.direccion[this.radioDireccion].address1,
                  "fecha": this.fechaActual,
                  "hora": this.now,
                  "tipo_entrega": this.tipoEnvio,
                  "metodo_pago": this.metodoPago,
                  "paqueteria": this.totalEnvio,
                  "iva": ((this.grandTotal + this.totalEnvio) * .16).toFixed(2),
                  "subtotal": (this.grandTotal / 1.16).toFixed(2),
                  "total": this.grandTotal + this.totalEnvio.toFixed(2),
                  "cotizacion": this.respuestaCotizacion,
                  "ov": this.respuestaOV,
                  "oxxo": "",
                  "mesa_regalos": 1

                }

                this.checkoutService.guarda_venta(objetoVentita).subscribe(datosVenta => {
                  try {
                    if (this.metodoPago == "Web Pay") {


                      var aplicameJson = {

                        "nombreDiario": "L-CB_ECOM",
                        "description": this.currentDateTime2 + ", ventas01.lideart," + this.respuestaOV,
                        "factura": this.respuestaOV,
                        "diarioCuentaContra": "PTE_WEBPAY",
                        "montoFactura": parseFloat(this.totalpaypalsito),
                        "tipoPago": this.paymentMethod,
                        "numTarjeta": "",
                        "customer": this.customerID,
                        "paymentReference": this.respuestaOV,
                        "fecha": this.formattedDate
                      }

                      this.checkoutService.aplicarPago(aplicameJson).subscribe(aplicado => {

                        var liberameJson = {
                          "SalesOrderNum": this.respuestaOV,
                          "DeliveryMode": this.modoEntrega,
                          "PaymentMethod": this.paymentMethod,
                          "PaymentTermsName": this.PaymentTermsName,
                          "NumeroTelefono": "",
                          "CorreoElectronico": this.usuario.usuario,
                          "Sucursal": "CHIHUAHUA",
                        }

                        // this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{

                        // })

                      })


                    }

                    if (this.metodoPago == "PayPal") {
                      var aplicameJson = {

                        "nombreDiario": "L-CB_ECOM",
                        "description": this.currentDateTime2 + ", ventas01.lideart," + this.respuestaOV,
                        "factura": this.respuestaOV,
                        "diarioCuentaContra": "PTE_PAYP",
                        "montoFactura": parseFloat(this.totalpaypalsito),
                        "tipoPago": this.paymentMethod,
                        "numTarjeta": "",
                        "customer": this.customerID,
                        "paymentReference": this.respuestaOV,
                        "fecha": this.formattedDate
                      }

                      this.checkoutService.aplicarPago(aplicameJson).subscribe(aplicado => {

                        var liberameJson = {
                          "SalesOrderNum": this.respuestaOV,
                          "DeliveryMode": this.modoEntrega,
                          "PaymentMethod": this.paymentMethod,
                          "PaymentTermsName": this.PaymentTermsName,
                          "NumeroTelefono": "",
                          "CorreoElectronico": this.usuario.usuario,
                          "Sucursal": "CHIHUAHUA",
                        }

                        // this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{

                        // })

                      })
                    }
                  } catch (error) {

                  }

                  this.cargando = false;
                  this.router.navigate(['/resumen-compra']);
                  // (document.getElementById("pago") as any).style.display = "none";

                  // if(window.innerWidth<992){
                  //   this.animacion('comprita');
                  // }
                  // else{
                  //   this.animacion('resumen')
                  // }

                  // (document.getElementById("resumen") as any).style.display = "flex";
                });


                // this.shoppingCart.deleteCart();

                // if(window.innerWidth<992){
                //   this.animacion('comprita');
                // }
                // else{
                //   this.animacion('resumen')
                // }
                // (document.getElementById("resumen") as any).style.display = "flex";
              }





            })

          })

        })
      }, 5000);


    }




  }

  async facturameprro() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Debes aceptar los terminos y condiciones de pago',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  facturado() {
    if (this.facturame == false) {
      this.facturame = true;

    } else {
      this.facturame = false;
    }

  }

  guardaDatosFactura() {
    let arregloDir = {
      "email": this.usuario.usuario,
      "uso_cfdi": this.usocfdi,
      "razon_social": this.razonSocial,
      "rfc": this.rfc,
      "cp": this.codigoPostal,
      "direccion_fiscal": this.DirFiscal,
      "metodo_pago": this.metodinPago,
      "regimen_fiscal": this.regimenfiscal
    }
    this.usuarioService.guardaDatosFactura(arregloDir).subscribe(datosFact => {
      this.respFact = datosFact;
      if (datosFact == 0) {
        this.presentAlertConfirm3();
      } else {
        this.presentAlertConfirm4();
      }



    })
  }

  transferencia() {
    //console.log(1.1)
    clearInterval(this.intervalopago);
    //console.log(1.2)
    this.sepuede = true;
    //console.log(1.3)
    // document.getElementById('comprame').style.display = "block";

    this.valorRadio = 1;
    this.radioPago = false;
    this.metodoPago = "Transferencia"
    this.paymentMethod = "28";
    this.PaymentTermsName = "CONTADO";
    //console.log(1.4)
    // this.paypal();
  }



  oxxoPay() {
    this.sepuede = true;
    clearInterval(this.intervalopago);
    // document.getElementById('comprame').style.display = "block";
    this.paymentMethod = "28";
    this.PaymentTermsName = "CONTADO";
    this.radioPago = false;
    this.metodoPago = "Oxxo Pay"
    this.valorRadio = 2;
    // this.setOpen3(true);

  }


  paypal() {

    clearInterval(this.intervalopago);
    // document.getElementById('comprame').style.display = "none";
    this.sepuede = false;
    this.radioPago = false;
    this.valorRadio = 4;
    this.metodoPago = "PayPal";
    this.PaymentTermsName = "CONTADO";
    this.paymentMethod = "03";
    // document.getElementById('open-modal10')!.click();

    // document.getElementById('modalsito10')!.style.display = "flex";

    // if (this.paypalIs == false) {
    //   this.renderer.setStyle(this.paypalcont.nativeElement, 'display', 'block');
    //   this.paypalIs = true;
    // }else if (this.paypalIs == true) {
    //   this.renderer.setStyle(this.paypalcont.nativeElement, 'display', 'none');
    //   this.paypalIs = false;
    // }

  }

  tarjetas() {

    //console.log("webpay 1")
    // document.getElementById('comprame').style.display = "none";
    this.sepuede = false;
    this.radioPago = false;
    this.metodoPago = "Web Pay"
    this.valorRadio = 3;
    this.paymentMethod = "28";
    this.PaymentTermsName = "CONTADO";
    // this.metodoPago = "Web Pay";
    //console.log("webpay 2")

    //document.getElementById('open-modal')!.click();
    //console.log("webpay 3")
    //document.getElementById('modalsito')!.style.display = "flex";
    //console.log("webpay 4")
    this.webPay();
    // (<HTMLInputElement>document.getElementsByName("paypal")[0].children[0].children[0].children[2]).disabled = false;
    // (<HTMLInputElement>document.getElementsByName("transferencia")[0].children[0].children[0].children[2]).disabled = false;
    // (<HTMLInputElement>document.getElementsByName("tarjetas")[0].children[0].children[0].children[2]).disabled = true;


  }

  webPay() {
    //console.log("webpay 5")
    clearInterval(this.intervalopago);
    //console.log("webpay 6")
    const roundTo = function (num: number, places: number) {
      const factor = 10 ** places;
      return Math.round(num * factor) / factor;
    };
    //console.log("webpay 7")
    var lejson = { "total": roundTo(this.grandTotal, 2), "email": this.usuario.usuario }
    this.checkoutService.webpay(lejson).subscribe(data => {

      this.datosWebPay = data;
      this.iframe = this.datosWebPay.ligaWebPay;
      this.urlsafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
      this.intervalopago = setInterval(() => {

        this.checkoutService.checaPago(this.numero_carro).subscribe(response => {
          this.respuestapago = response;
          var resPago = this.respuestapago;



          if (resPago > 0) {
            if (resPago == 1) {
              clearInterval(this.intervalopago);
              this.relizarCompra();
              this.cancel();



            }
            else {
              alert('Error al Procesar pago');
              clearInterval(this.intervalopago);
            }
          }

        })
      }, 3000)

    })

  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
    document.getElementById('modalsito')!.style.display = "none";
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Debes aceptar los terminos y condiciones del servicio antes de seleccionar metodo de pago',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertConfirm3() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Esta cuenta ya tiene datos de facturacion guardados',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertConfirm4() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Exito!',
      message: 'Datos de Facturacion guardados correctamente',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertConfirm2() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Debes aceptar los terminos y condiciones de pago',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  handleWP() {
    if (this.modal_wpIs == false) {
      this.renderer.setStyle(this.modal_wp.nativeElement, 'display', 'flex');

      this.modal_wpIs = true
    } else if (this.modal_wpIs == true) {
      this.renderer.setStyle(this.modal_wp.nativeElement, 'display', 'none');

      this.modal_wpIs = false
    }
  }

  regresaPago() {
    location.reload();
    document.getElementById("envio")!.style.display = "block";
    document.getElementById("pago")!.style.display = "none";
  }

  aceptacion() {
    this.acepto = true;
  }

}
