<section>
    <div class="dist-cont" style=" margin-top: 100px;">
        <h2 style="color: #1ea9d1; font-size: 2em; text-align: center; font-weight: bold;">¿Quieres llevar tu negocio al
            siguiente nivel?</h2>
        <p style="text-align: center; color: #1ea9d1; font-size: 1.5em;">Se parte de nuestro equipo de distribuidores
        </p>
        <div style="display: block; text-align: center;">
            <div style="display: inline-block; width: 70%; max-width: 700px;"><iframe width="300" height="150"
                    style="width: 100%; height: 350px;"
                    src="https://www.youtube.com/embed/lNXXd3uz1gw?autoplay=1&mute=1" title="YouTube video player"
                    frameborder="0" allow="autoplay" allowfullscreen="allowfullscreen"></iframe></div>
        </div>
        <div style="width: 100%; display:flex;align-items: center; flex-direction: column;justify-content: center">
            <p style="width: 50%;">Estamos buscando empresas, personas y negocios que estén relacionadas con el mundo de
                las manualidades, scrapbook, tarjetería social, Papelerías, Agencias de publicidad, Tiendas de arte o
                cualquier negocio afín, o bien, personas que se dediquen a dar cursos en este giro.</p>
            <p>¡Descubre como nuestros ejecutivos de cuentas clave te ayudarán a transformar tu empresa y llevarla al
                siguiente nivel!</p>
            <p></p>
            <p>Podemos ayudarte a:</p>
            <ul>
                <li>Tener un acceso a un gran catálogo de materiales de alta calidad con las macas de mayor prestigio en
                    el mercado</li>
                <li>Crecer tu negocio con inversiones mínimas, conoce todos nuestros esquemas</li>
                <li>Facilitar la entrega a tus clientes.</li>
                <li>Formar parte de las comunidades más increíbles en el mercado del emprendimiento en manualidades,
                    tarjetería social, personalización, entre muchas otras.</li>
                <li>Equipo de soporte para llevar las garantías de tus clientes por medio de una plataforma creada
                    exclusivamente para ese fin “Craftroom”</li>
                <li>Precios exclusivos para distribuidores</li>
                <li>Conocer todos los materiales, tendencias y aplicaciones para reinventar tu negocio.</li>
                <li>Explora nuevos mercados, te asesoramos para diversificar tu negocio.</li>
            </ul>
            <p></p>
            <h3><span style="color: #1ea9d1; text-decoration: underline; font-weight: bold;">¡Conoce todos los
                    beneficios que tenemos para ti!</span></h3>
            <p>¡Puedes dejar tu información en el formulario de uno de nuestros ejecutivos de cuenta clave, la elección
                es tuya, Conócelos!</p>
        </div>
        <hr
            style="height: 2pt; background: #0089c152; border: 0px; margin: 32px auto; width: 80%; font-family: 'Open Sans', arial, sans-serif; font-size: 16px;" />
        <h2 style="color: #1ea9d1; text-align: center; font-weight: bold; padding-top: 1em;"><span
                style="font-size: 1.2em;">Conoce a nuestros </span><br /> <span style="font-size: 1.8em;">Ejecutivos de
                cuentas clave </span></h2>

        <div style="width: 100%; text-align: center;">
            <figure style="width: 100%; max-width: 390px; display: inline-block; vertical-align: top; padding: 15px;">
                <img src="https://new.lideart.com.mx/KAM/rocio-munoz.jpg" style="width: 100%; padding: 5px;" />
                <figcaption>
                    <h3 style="padding-top: 10px; font-weight: bold; font-size: 2em;">Rocio Muñoz</h3>
                    <ul
                        style="background-color: #48b9db; padding: 20px; list-style: none; color: white; font-weight: bold; font-size: 1.1em; text-align: left; text-shadow: 0 0 2px #000000;">
                        <li>Ejecutivo de cuentas clave</li>
                        <li><span class="fa fa-phone"></span> Teléfono: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;" href="tel:+526144326122">614
                                432 61 22</a></li>
                        <li><span class="fa fa-whatsapp"></span> Whatsapp: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;"
                                href="https://web.whatsapp.com/send?l=en&phone=+526141016274">614 101 6274</a></li>
                        <li><span class="fa fa-envelope"></span> Correo: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;"
                                href="mailto:rmunoz&#64;lideart.com.mx">rmunoz&#64;lideart.com.mx</a></li>
                        <li style="padding-top: 15px; text-align: center;">*frase inspiradora*</li>
                        <li style="padding-top: 15px; text-align: center;"><a
                                href="https://web.whatsapp.com/send?l=en&phone=+526141016274"><img
                                    src="https://new.lideart.com.mx/img/cms/whatsapp.png"
                                    style="width: 50%; max-width: 60px; margin: 5px;" /></a><a
                                href="https://lideart.com.mx/contacto"><img
                                    src="https://new.lideart.com.mx/img/cms/contacto.png"
                                    style="width: 50%; max-width: 60px; margin: 5px;" /></a></li>
                    </ul>
                </figcaption>
            </figure>
            <figure style="width: 100%; max-width: 390px; display: inline-block; vertical-align: top; padding: 15px;">
                <img src="https://new.lideart.com.mx/KAM/karina-nakamura.jpg" style="width: 100%; padding: 5px;" />
                <figcaption>
                    <h3 style="padding-top: 10px; font-weight: bold; font-size: 2em;">Karina Nakamura</h3>
                    <ul
                        style="background-color: #48b9db; padding: 20px; list-style: none; color: white; font-weight: bold; font-size: 1.1em; text-align: left; text-shadow: 0 0 2px #000000;">
                        <li>Ejecutivo de cuentas clave</li>
                        <li><span class="fa fa-phone"></span> Teléfono: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;" href="tel:+526144326122">614
                                432 61 22</a></li>
                        <li><span class="fa fa-whatsapp"></span> Whatsapp: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;"
                                href="https://web.whatsapp.com/send?l=en&phone=+526146030676">614 603 06 76</a></li>
                        <li><span class="fa fa-envelope"></span> Correo: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;"
                                href="mailto:knakamura&#64;lideart.com.mx">knakamura&#64;lideart.com.mx</a></li>
                        <li style="padding-top: 15px; text-align: center;">*frase inspiradora*</li>
                        <li style="padding-top: 15px; text-align: center;"><a
                                href="https://web.whatsapp.com/send?l=en&phone=+526146030676"><img
                                    src="https://new.lideart.com.mx/img/cms/whatsapp.png"
                                    style="width: 50%; max-width: 60px; margin: 5px;" /></a><a
                                href="https://lideart.com.mx/contacto"><img
                                    src="https://new.lideart.com.mx/img/cms/contacto.png"
                                    style="width: 50%; max-width: 60px; margin: 5px;" /></a></li>
                    </ul>
                </figcaption>
            </figure>
            <figure style="width: 100%; max-width: 390px; display: inline-block; vertical-align: top; padding: 15px;">
                <img src="https://new.lideart.com.mx/KAM/gabriela-tarango.jpg" style="width: 100%; padding: 5px;" />
                <figcaption>
                    <h3 style="padding-top: 10px; font-weight: bold; font-size: 2em;">Gabriela Tarango</h3>
                    <ul
                        style="background-color: #48b9db; padding: 20px; list-style: none; color: white; font-weight: bold; font-size: 1.1em; text-align: left; text-shadow: 0 0 2px #000000;">
                        <li>Ejecutivo de cuentas clave</li>
                        <li><span class="fa fa-phone"></span> Teléfono: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;" href="tel:+526144326122">614
                                432 61 22</a></li>
                        <li><span class="fa fa-whatsapp"></span> Whatsapp: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;"
                                href="https://web.whatsapp.com/send?l=en&phone=+526145986850">614 598 68 50</a></li>
                        <li><span class="fa fa-envelope"></span> Correo: <a
                                style="color: white; font-weight: bold; font-size: 1.1em;"
                                href="mailto:gtarango&#64;lideart.com.mx">gtarango&#64;lideart.com.mx</a></li>
                        <li style="padding-top: 15px; text-align: center;">*frase inspiradora*</li>
                        <li style="padding-top: 15px; text-align: center;"><a
                                href="https://web.whatsapp.com/send?l=en&phone=+526145986850"><img
                                    src="https://new.lideart.com.mx/img/cms/whatsapp.png"
                                    style="width: 50%; max-width: 60px; margin: 5px;" /></a><a
                                href="https://lideart.com.mx/contacto"><img
                                    src="https://new.lideart.com.mx/img/cms/contacto.png"
                                    style="width: 50%; max-width: 60px; margin: 5px;" /></a></li>
                    </ul>
                </figcaption>
            </figure>
        </div>
        <hr
            style="height: 2pt; background: #0089c152; border: 0px; margin: 32px auto; width: 80%; font-family: 'Open Sans', arial, sans-serif; font-size: 16px;" />
        <div style="display: block; text-align: center; padding-top: 1em;">
            <div style="display: inline-block; width: 70%; max-width: 700px;"><iframe width="300" height="150"
                    style="width: 100%; height: 320px;" src="https://www.youtube.com/embed/lNXXd3uz1gw"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"></iframe></div>
        </div>
        <p></p>
        <h3 style="color: #1ea9d1; text-align: center; font-weight: bold; font-size: 1.2em;">Testimonios</h3>
        <div style="width: 100%; display:flex;align-items: center; flex-direction: column;justify-content: center">
            <p style="width: 50%;">Con nuestra asesoría estratégica, nuestros clientes han experimentado un aumento en
                la rentabilidad, eficiencia y éxito sostenible en sus negocios. ¡Si tu empresa está lista para llegar al
                siguiente nivel no dudes en programar una asesoría con nuestros ejecutivos de cuentas clave!</p>
            <p></p>
        </div>
        <h3 style="color: #1ea9d1; text-align: center; font-weight: bold; font-size: 1.4em; padding-top: 1em;">
            ¡Contáctanos hoy y comienza a trazar el camino de tu negocio hacia el éxito!</h3>
        <hr
            style="height: 2pt; background: #0089c152; border: 0px; margin: 32px auto; width: 80%; font-family: 'Open Sans', arial, sans-serif; font-size: 16px;" />


        <div style="width: 100%; display:flex;align-items: center; flex-direction: column;justify-content: center">
            <h3 style="color: #1ea9d1; text-align: center; font-weight: bold; font-size: 1.4em; padding-top: 1em;">
                Preguntas frecuentes</h3>
            <p style="width: 50%;"><button id="pregunta1-show"
                    style="width: 100%; font-size: 1.3em; line-height: 2em; text-align: left; background-color: white; border-radius: 3px; border: 2px solid #0000004d;">¿Qué
                    es un ejecutivo de Cuentas clave? <span style="float: right; right: 0;">▾</span></button></p>
            <div id="pregunta1-show-div" class="hiddenDiv" style="background-color: #e9e9e9; padding: 10px;width: 50%;">
                <p style="width: 50%;">Somos expertos en la gestión de relaciones comerciales, nos enfocamos en
                    desempeñar un papel activo y estratégico en el crecimiento y éxito de tu negocio. Ofrecemos asesoría
                    gratuita y personalizada para ayudar a tu empresa a alcanzar todo su potencial.</p>
            </div>
            <p style="width: 50%;"><button id="pregunta2-show"
                    style="width: 100%; font-size: 1.3em; line-height: 2em; text-align: left; background-color: white; border-radius: 3px; border: 2px solid #0000004d;">¿Cómo
                    un ejecutivo de cuentas clave, ayudaría a potencializar mi negocio? <span
                        style="float: right; right: 0;">▾</span></button></p>
            <div id="pregunta2-show-div" class="hiddenDiv" style="background-color: #e9e9e9; padding: 10px;width: 50%;">
                <p style="padding: 1em;">Nuestro enfoque va más allá de la simple maximización de las ventas; buscamos
                    ser un facilitador integral que contribuye al avance de tu negocio a través de los siguientes
                    beneficios:</p>
                <ul>
                    <li>Asesoría personalizada: Analizamos tu empresa y tus objetivos para guiarte en la búsqueda de
                        materiales de calidad mejor posicionados en el mercado para que puedas ampliar tu catalogo de
                        productos y servicio que ofreces a tus clientes.</li>
                    <li>Relaciones a largo plazo: Mantener una relación cercana es la clave, contar con un asesor que
                        conozca tu empresa y proyectos nos ayuda a atenderte de manera más ágil, estar pendientes de tus
                        necesidades y lograr mejores negociaciones para desarrollarnos y crecer juntos.</li>
                    <li>Colaboración y alianza: Somos un puente entre tus clientes, tu negocio y una gran cantidad de
                        proveedores alrededor del mundo. Ayudamos a generar conexiones estratégicas para llevar tu
                        negocio al siguiente nivel.</li>
                </ul>
            </div>
            <p style="width: 50%;"><button id="pregunta3-show"
                    style="width: 100%; font-size: 1.3em; line-height: 2em; text-align: left; background-color: white; border-radius: 3px; border: 2px solid #0000004d;">¿Puedo
                    ser subdistribuidor si tengo solo tienda en linea? <span
                        style="float: right; right: 0;">▾</span></button></p>
            <div id="pregunta3-show-div" class="hiddenDiv" style="background-color: #e9e9e9; padding: 10px;width: 50%;">
                <p style="padding: 1em;">Si puedes ser distribuidor siempre y cuando tengas tu tienda en linea activa y
                    su giro principal sea de venta de productos para manualidades, productos para personalizar, etc. o
                    des cursos de los temas mencionados.</p>
            </div>
            <p style="width: 50%;"><button id="pregunta4-show"
                    style="width: 100%; font-size: 1.3em; line-height: 2em; text-align: left; background-color: white; border-radius: 3px; border: 2px solid #0000004d;">¿Tiene
                    algún costo extra el servicio? <span style="float: right; right: 0;">▾</span></button></p>
            <div id="pregunta4-show-div" class="hiddenDiv" style="background-color: #e9e9e9; padding: 10px;width: 50%;">
                <p style="padding: 1em;">Nuestra asesoría es completamente gratuita. Queremos ayudarte a navegar por las
                    dificultades que puedas enfrentar como dueño de negocio, y estamos comprometidos a ofrecer nuestra
                    experiencia para ayudarte a lograr cada día más.</p>
            </div>
        </div>
        <!-- <div><img src="https://new.lideart.com.mx/img/cms/bannerpie.jpg" style="width: 100%; padding-top: 2em;" /></div> -->
    </div>
</section>