import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, IonicModule, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { IonButton, IonCard, IonInput, IonItem, IonLabel } from '@ionic/angular/standalone';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginService } from '../../services/login.service';
import { DataService } from '../../services/test.service';

import { ModalRecuperarComponent } from '../modal-recuperar/modal-recuperar.component';
import { ModalRegistroComponent } from '../modal-registro/modal-registro.component';
import { Title } from '@angular/platform-browser';
import { CarritoServiceService } from '../../services/carrito-service.service';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonCard,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  ],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginComponent implements OnInit {
  @ViewChild('passDesk') passDesk!: ElementRef<HTMLInputElement>;
  @ViewChild('passCel') passCel!: ElementRef<HTMLInputElement>;
  loginForm!: UntypedFormGroup;
  isSubmitted = false;
  isLoading = false;
  items: any = [];
  password: any;
  email: any;
  mensajeTemporal: any = "";

  public isDesk: boolean = false;
  public isCel: boolean = false;

  constructor(
    public toastController: ToastController,
    public modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public usuariosLogin: LoginService,
    public router: Router,
    private authService: AuthenticationService,
    private dataService: DataService,
    private renderer: Renderer2,
    private cartService: CarritoServiceService,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Login");
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
    }
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', [Validators.required]]
      /* https://localcoder.org/how-to-validate-password-strength-with-angular-5-validator-pattern  */
      /* 
      - At least 8 characters in length
      - Lowercase letters
      - Uppercase letters
      - Numbers
      - Special characters-
      */
    });
  }

  handleHead() {
    this.router.navigate(['/home']);
    this.dataService.setData(2);
  }

  async presentLoading() {
    // this.isLoading = true;
    // return await this.loadingController.create({
    //   cssClass: 'my-custom-class',
    //   message: 'Iniciando Sesion...',
    //   duration: 50000,
    //   spinner: 'bubbles'
    // }).then(a => {
    //   a.present().then(() => {
    //     //console.log('presented');
    //     if (!this.isLoading) {
    //       a.dismiss().then(() => //console.log('abort presenting'));
    //     }
    //   });
    // });

  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  login() {
    this.mensajeTemporal = ""

    this.isSubmitted = true;

    if (!this.loginForm.valid) {
      this.mensajeTemporal = "Datos de inicio de sesion invalidos";
      //this.presentLoading();
      this.presentAlert();
    } else {


      this.presentLoading();
      this.usuariosLogin.login(this.loginForm.value).subscribe(res => {
        if (res.exito === 1) {
          //this.dismiss();
          const toka = res.token;
          this.authService.login(toka);
          if (this.items.length > 0) {

            // var carrito = localStorage.getItem("cart");
            this.cartService.getCartData();
            this.router.navigate(['/carrito']);
            this.dataService.setData(2);
          } else {
            //this.router.navigate(['/home']);
            this.router.navigateByUrl('/home', { replaceUrl: true });
            this.dataService.setData(2);
          }
        }

        if (res.exito === 2) {
          this.mensajeTemporal = "Correo no registrado"
          // this.dismiss();
          this.correoNoExiste();
        }

        if (res.exito === 3) {
          this.mensajeTemporal = "Correo inactivo"
          //this.dismiss();
          this.correoNoActivo();
        }

        if (res.exito === 4) {
          this.mensajeTemporal = "Contraseña incorrecta"
          //this.dismiss();
          // this.passwordMalo();
        }
      });

    }
  }

  async correoNoExiste() {
    // const alert = await this.alertController.create({
    //   cssClass: 'loginAlertComp',
    //   header: 'Error',
    //   //subHeader: 'Subtitle',
    //   message: 'El correo ingresado no existe.',
    //   buttons: ['OK']
    // });

    // await alert.present();

    // const { role } = await alert.onDidDismiss();

  }

  async correoNoActivo() {
    // const alert = await this.alertController.create({
    //   cssClass: 'loginAlertComp',
    //   header: 'Error',
    //   //subHeader: 'Subtitle',
    //   message: 'El correo ingresado no esta activo.',
    //   buttons: ['OK']
    // });

    // await alert.present();

    // const { role } = await alert.onDidDismiss();

  }

  async passwordMalo() {
    //console.log("sigue el swal")
    const toast = await this.toastController.create({
      message: 'Password Incorrecto',
      color: "error",
      position: "top",
      duration: 1500

    });
    toast.present();
    // const alert = await this.alertController.create({
    //   cssClass: 'loginAlertComp',
    //   header: 'Error',
    //   //subHeader: 'Subtitle',
    //   message: 'Password Incorrecto.',
    //   buttons: ['OK']
    // });

    // await alert.present();

    // const { role } = await alert.onDidDismiss();

  }

  errorNotificationPassword() {
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Error',
    //   text: 'Password Incorrecto!',
    // })

  }

  errorNotificationActivo() {
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Error',
    //   text: 'Correo no Activado!',
    // })

  }

  errorNotificationCorreo() {
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Error',
    //   text: 'Correo no Existe!',
    // })

  }

  get errorControl() {
    return this.loginForm.controls;
  }

  async presentAlert() {
    // const alert = await this.alertController.create({
    //   cssClass: 'loginAlertComp',
    //   header: 'Error',
    //   //subHeader: 'Subtitle',
    //   message: 'Completa correctamente los campos.',
    //   buttons: ['OK']
    // });

    // await alert.present();

    // const { role } = await alert.onDidDismiss();

  }

  togglePassword() {
    var input = document.getElementById('inputPassword');
    if ((<HTMLInputElement>input).type == 'password') {
      (<HTMLInputElement>input).setAttribute('type', 'text')
      this.renderer.removeClass(this.passDesk.nativeElement, 'fa-eye');
      this.renderer.addClass(this.passDesk.nativeElement, 'fa-eye-slash');
    } else {
      (<HTMLInputElement>input).setAttribute('type', 'password')
      this.renderer.removeClass(this.passDesk.nativeElement, 'fa-eye-slash');
      this.renderer.addClass(this.passDesk.nativeElement, 'fa-eye');
    }
  }

  togglePasswordCel() {
    var input = document.getElementById('inputPasswordCel');
    if ((<HTMLInputElement>input).type == 'password') {
      (<HTMLInputElement>input).setAttribute('type', 'text')
      this.renderer.removeClass(this.passCel.nativeElement, 'fa-eye');
      this.renderer.addClass(this.passCel.nativeElement, 'fa-eye-slash');
    } else {
      (<HTMLInputElement>input).setAttribute('type', 'password')
      this.renderer.removeClass(this.passCel.nativeElement, 'fa-eye-slash');
      this.renderer.addClass(this.passCel.nativeElement, 'fa-eye');
    }
  }

  async modalRec() {
    this.router.navigate(['/recuperar-cuenta']);
    /*const modal = await this.modalCtrl.create({
      component: ModalRecuperarComponent,
      cssClass: 'modalRecuperarCss',
      initialBreakpoint: 0.8,
      breakpoints: [0, 0.5, 1]
    });
    return await modal.present();*/
  }

  async modalReg() {
    ////console.log("registro")
    this.router.navigate(['/registro']);
    //   const modal = await this.modalCtrl.create({

    //     component: ModalRegistroComponent,
    //     cssClass: 'modalRegistroCss',
    //     initialBreakpoint: 1,
    //     breakpoints: [0, 0.5, 1]
    //   });
    //   //console.log("inicio modal registro");
    //   return await modal.present();
  }
}
