<table width="100%" style="text-align: center; border-collapse: collapse;">
    <tbody>
        <tr>
            <td style="border: 1px solid #000;"><strong></strong></td>
            <td style="border: 1px solid #000;"><strong><img src="/assets/img/especificaciones-plotters-cricut/joy_480x480.webp" alt="" /><br />Cricut Joy</strong></td>
            <td style="border: 1px solid #000;"><strong><img src="/assets/img/especificaciones-plotters-cricut/explore-air_480x480.webp" alt="" /><br />Cricut Explore Air 2</strong></td>
            <td style="border: 1px solid #000;"><strong><img src="/assets/img/especificaciones-plotters-cricut/explore3.webp" alt="" /><br />Cricut Explore 3</strong></td>
            <td style="border: 1px solid #000;"><strong><img src="/assets/img/especificaciones-plotters-cricut/maker_480x480.webp" alt="" /><br />Cricut Maker</strong></td>
            <td style="border: 1px solid #000;">
                <p><strong><img src="/assets/img/especificaciones-plotters-cricut/maker3.webp" alt="" /></strong><br><strong>Cricut Maker 3</strong><strong><br /></strong></p>
            </td>
            <td style="border: 1px solid #000;">
                <p><img src="/assets/img/especificaciones-plotters-cricut/33745.webp" alt="" width="110" height="110" /></p>
                <p><strong>Cricut Joy Xtra</strong></p>
            </td>
            <td style="border: 1px solid #000;">
                <p><strong><img src="/assets/img/especificaciones-plotters-cricut/Tarjeta_Agradecimiento_Moderno_Beige.webp" width="110" height="79" /><br />Cricut Venture</strong></p>
            </td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Ideal para</td>
            <td style="border: 1px solid #000;">Proyectos sencillos, rápidos y divertidos</td>
            <td style="border: 1px solid #000;">Múltiples tipos de manualidades</td>
            <td style="border: 1px solid #000;">Múltiples tipos de manualidades</td>
            <td style="border: 1px solid #000;">Versatilidad y creación de proyectos a nivel profesional</td>
            <td style="border: 1px solid #000;">Versatilidad y creación de proyectos a nivel profesional</td>
            <td style="border: 1px solid #000;">Proyectos pequeños, medianos y proyectos sencillos</td>
            <td style="border: 1px solid #000;">Proyectos grandes y comerciales</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Anchura máxima del material</td>
            <td style="border: 1px solid #000;">13,9 cm</td>
            <td style="border: 1px solid #000;">30,5 cm</td>
            <td style="border: 1px solid #000;">33 cm<br />(con Smart Materials)</td>
            <td style="border: 1px solid #000;">30,5 cm</td>
            <td style="border: 1px solid #000;">33 cm<br />(con Smart Materials)</td>
            <td style="border: 1px solid #000;">8.5 pulgadas (21,59 cm)</td>
            <td style="border: 1px solid #000;">25 pulgadas (63,5 cm)</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Longitud máxima de corte</td>
            <td style="border: 1px solid #000;">1,2 m</td>
            <td style="border: 1px solid #000;">61 cm</td>
            <td style="border: 1px solid #000;">3,6 m</td>
            <td style="border: 1px solid #000;">61 cm</td>
            <td style="border: 1px solid #000;">3,6 m</td>
            <td style="border: 1px solid #000;">48 pulgadas (1,2 m) para una imagen individual y 20 pies (6 m) con materiales inteligentes</td>
            <td style="border: 1px solid #000;">12 pies (3,6 m) para una imagen individual y hasta 75 pies (22,8 m) usando materiales inteligentes</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Compatibilidad de materiales</td>
            <td style="border: 1px solid #000;">50+</td>
            <td style="border: 1px solid #000;">100+</td>
            <td style="border: 1px solid #000;">100+</td>
            <td style="border: 1px solid #000;">300+</td>
            <td style="border: 1px solid #000;">300+</td>
            <td style="border: 1px solid #000;">50+</td>
            <td style="border: 1px solid #000;">100+</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Herramientas / Funciones</td>
            <td style="border: 1px solid #000;">3 (para cortar y escribir, marcar y laminar)</td>
            <td style="border: 1px solid #000;">6 (para cortar, escribir, marcar y laminar)</td>
            <td style="border: 1px solid #000;">6 (para cortar, escribir, marcar y laminar)</td>
            <td style="border: 1px solid #000;">13 (para cortar, escribir, marcar, estampar, grabar y más)</td>
            <td style="border: 1px solid #000;">13 (para cortar, escribir, marcar, estampar, grabar y más)</td>
            <td style="border: 1px solid #000;">Corta, dibuja, escribe, y aplica foil.</td>
            <td style="border: 1px solid #000;">Corta, dibuja, marca y aplica foil</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">¿Qué es lo que sobra en la caja?</td>
            <td style="border: 1px solid #000;">Máquina Cricut Joy<br />Cuchillas Joy y Portacuchillas<br />Bolígrafo de Punta Fina Joy, Negro<br />Tapete de agarre estándar Joy (verde)<br />Materiales para realizar proyecto de prueba</td>
            <td style="border: 1px solid #000;">Cuchilla premium de punta fina y portacuchillas<br />Bolígrafo de Punta Fina, Negro<br />Tapete de corte de agarre ligero 30.5cm x 30.5cm<br />Materiales para realizar proyecto de prueba</td>
            <td style="border: 1px solid #000;">Cuchilla premium de punta fina y portacuchillas<br />Material para realizar un proyecto de prueba</td>
            <td style="border: 1px solid #000;">Cuchilla premium de punta fina y portacuchillas<br />Cuchilla rotatoria y portacuchillas<br />Bolígrafo de Punta Fina, Negro<br />Tapete para telas 30.5cm x 30.5cm<br />Tapete de agarre ligero 30.5cm x 30.5cm<br />Materiales para realizar proyecto de prueba</td>
            <td style="border: 1px solid #000;">Cuchilla premium de punta fina y portacuchillas<br />Material para realizar un proyecto de prueba</td>
            <td style="border: 1px solid #000;">Máquina Cricut Joy Xtra, cuchilla y soporte, adaptador de corriente, bolígrafo Cricut Joy, mini herramienta de depilado, membresía de prueba a Cricut Access, materiales para cortes de práctica</td>
            <td style="border: 1px solid #000;">Máquina Cricut Venture, cable de alimentación, cuchilla de punta fina, herramienta de corte manual, manual</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Velocidad (cm por segundo en un eje)</td>
            <td style="border: 1px solid #000;">10,16</td>
            <td style="border: 1px solid #000;">10,16</td>
            <td style="border: 1px solid #000;">20,32</td>
            <td style="border: 1px solid #000;">10,16</td>
            <td style="border: 1px solid #000;">20,32</td>
            <td style="border: 1px solid #000;">4-5 cm por segundo</td>
            <td style="border: 1px solid #000;">11 pulgadas (28 cm) por segundo</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Funciona con Smart Materials</td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">✓</td>
            <td style="border: 1px solid #000;">✓</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Funciona con un Tapete para tarjetas personalizadas rápidas</td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">✓</td>
            <td style="border: 1px solid #000;">-</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Se puede imprimir en una impresora doméstica, luego recorta la imagen impresa sin problemas</td>
            <td style="border: 1px solid #000;">
                <div class="no">-</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">✓</td>
            <td style="border: 1px solid #000;">✓</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Aplicación de diseño gratis para iOS, Android™, Windows® y Mac®</td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">
                <div class="yes">✓</div>
            </td>
            <td style="border: 1px solid #000;">✓</td>
            <td style="border: 1px solid #000;">✓</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;">Conectividad</td>
            <td style="border: 1px solid #000;">Bluetooth®</td>
            <td style="border: 1px solid #000;">Bluetooth®, USB</td>
            <td style="border: 1px solid #000;">Bluetooth®, USB</td>
            <td style="border: 1px solid #000;">Bluetooth®, USB</td>
            <td style="border: 1px solid #000;">Bluetooth®, USB</td>
            <td style="border: 1px solid #000;">Bluetooth®</td>
            <td style="border: 1px solid #000;"> Bluetooth®, USB</td>
        </tr>
        <tr>
            <td style="border: 1px solid #000;"></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx//plotters-de-corte/4885-4425-4000.html" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx//plotters-de-corte/4468-4446-4425-0010.html#/49-color-menta" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx//plotters-de-corte/5087-5611-4425-0102.html#/49-color-menta" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx//plotters-de-corte/4469-4447-4425-0100.html#/138-color-champagne" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx//plotters-de-corte/5086-5609-4425-0013.html#/14-color-azul" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx/cricut-plotter-de-corte/6709-plotter-de-corte-cricut-joy-xtra--2010313.html" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
            <td style="border: 1px solid #000;"><a href="https://lideart.com.mx/cricut-plotter-de-corte/6710-plotter-de-corte-profesional-cricut-venture-smart--2008739.html" class="button more-info" style="color: #38b7d9; font-weight: bold; text-decoration: underline;">SABER MÁS</a></td>
        </tr>
    </tbody>
</table>
<br><br>