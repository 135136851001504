import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [

  ],
  selector: 'app-software-de-equipos',
  templateUrl: './software-de-equipos.component.html',
  styleUrls: ['./software-de-equipos.component.scss'],
})
export class SoftwareDeEquiposComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("software-de-equipos");
  }

}
