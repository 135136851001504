<section class="ante-crear-cont" style=" margin-top: 100px;">
  <div class="fix-width">
    @if( id === '1' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-1.webp" alt="">
    </div>
    }
    @else if( id === '2' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-2.webp" alt="">
    </div>
    }
    @else if( id === '3' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-3.webp" alt="">
    </div>
    }
    @else if( id === '4' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-4.webp" alt="">
    </div>
    }
    @else if( id === '5' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-5.webp" alt="">
    </div>
    }
    @else if( id === '6' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-6.webp" alt="">
    </div>
    }
    @else if( id === '7' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-7.webp" alt="">
    </div>
    }
    @else if( id === '8' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-8.webp" alt="">
    </div>
    }
    @else if( id === '9' ) {
    <div class="banner-cont">
      <img src="assets/img/mesa-regalos/tipo-9.webp" alt="">
    </div>
    }

    <div class="titles-sect-cont">
      <span class="title-sect"><span style="font-weight: 900;">¡¡Felicidades!!</span> Nos alegra mucho tu valor y
        esfuerzo.</span>
      <span class="subt-sect">Crea tu mesa de regalos perfecta para que emprendas con el pie derecho.</span>
    </div>

    <button class="open-form-btn" (click)="handleForm()">Arma tu mesa de regalos aquí</button>

    <div class="beneficios-cont">
      <span class="title-beneficios">Celebra con nosotros esta ocasión tan especial, y obtén los siguientes <span
          style="font-weight: 500;">beneficios:</span></span>
      <div class="bens-contan-r">
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-1.webp" alt="" class="img-ben">
          <span class="txt-ben">¡Celebramos contigo cualquier evento!</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-2.webp" alt="" class="img-ben">
          <span class="txt-ben">Es un servicio gratuito</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-3.webp" alt="" class="img-ben">
          <span class="txt-ben">Podemos asesorarte en cualquier momento</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-4.webp" alt="" class="img-ben">
          <span class="txt-ben">Nuestros productos tienen garantía</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-5.webp" alt="" class="img-ben">
          <span class="txt-ben">Recibe 50 lidepuntos al crear una mesa de regalos con nosotros</span>
        </div>
      </div>
    </div>

    <!-- <div class="datos-cont">
      <span class="title-datos">Datos importantes</span>
      <span class="desp-btn-datos"><i class="fa-solid fa-circle"></i>Crea tu evento con dos meses de anticipación</span>
      <span class="desp-btn-datos"><i class="fa-solid fa-circle"></i>Dato importante 2</span>
      <span class="desp-btn-datos"><i class="fa-solid fa-circle"></i>Dato importante 3</span>
      <span class="desp-btn-datos"><i class="fa-solid fa-circle"></i>Dato importante 4</span>
      <span class="desp-btn-datos"><i class="fa-solid fa-circle"></i>Dato importante 5</span>
    </div> -->
  </div>
</section>

<section class="formulario-crear-mesa-regalos-cont" #formulario_crear_mesa_regalos_cont>
  <div class="step-1-cont" #step_1_cont>
    <div class="ban-cont">
      <img src="/assets/img/mesa-regalos/b-form-1.webp" alt="">
    </div>
    <div class="ovfw-y">
      <div class="txt-cont-pres">
        <h1 class="title-1">¡¡Estas apunto de crear tu mesa de regalos!!</h1>
        <span class="desc-1">Agrega todos los datos del evento y dirección donde se mandaran tus regalos.</span>
        <span class="obli-txt">* <span class="obli-txt-alt">Campos obligatorios</span></span>
        <!-- <span class="recuerda">Recuerda que tus invitados podrán encontrar tu mesa por tu nombre o numero de
          evento</span> -->
      </div>
      <form class="form-mr">
        <div class="large-inp">
          <div class="txt-box">
            <span class="force">*</span>
            <span class="inp-title">Nombre Completo</span>
          </div>
          <div class="inp-cont">
            <input type="text" class="form-inpa" [(ngModel)]="nombreCompleto" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="large-inp">
          <div class="txt-box">
            <span class="force">*</span>
            <span class="inp-title">Dirección</span>
          </div>
          <div class="inp-cont">
            <input type="text" class="form-inpa" [(ngModel)]="direccion" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="mitad-inp-cont">
          <div class="fifty-box">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Colonia</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" [(ngModel)]="colonia" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
        <div class="mitad-inp-cont">
          <div class="fifty-box">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Ciudad</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" [(ngModel)]="ciudad" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="fifty-box">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Código Postal</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" [(ngModel)]="codigoPostal" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
        <div class="mitad-inp-cont">
          <div class="fifty-box">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Estado</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" [(ngModel)]="estado" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>

        <div class="mitad-inp-cont">
          <div class="fifty-box">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Teléfono Celular</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" [(ngModel)]="telefonoCelular" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
        <div class="mitad-inp-cont">
          <div class="fifty-box">
            <div class="txt-box" style="padding-left: 1.5em;">
              <span class="inp-title">Correo Electrónico</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" placeholder="Por Favor usa el correo registrado a esta cuenta"
                [(ngModel)]="correoElectronico" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </form>
      <div class="last-msg-cont">
        <span class="last-msg">
          IMPORTANTE: Los productos de tu mesa de regalos se pueden devolver hasta un plazo de 90 días naturales una vez
          recibidos. Si la
          mesa de regalos supera los $2000 de compra no generará costo de envío.
        </span>
      </div>
      <div class="btns-cont">
        <button class="salir-form" (click)="handleForm()">Cerrar</button>
        <button class="sp" (click)="paso2()">Siguiente paso</button>
      </div>
    </div>
    <div class="msg-no-completado" #msg_no_completado>
      <span class="msg"><span class="aster">*</span> No has completado los campos obligatorios.</span>
    </div>
  </div>

  <div class="step-2-cont" #step_2_cont>
    <div class="ban-cont">
      <img src="/assets/img/mesa-regalos/b-form-2.webp" alt="">
    </div>
    <div class="ovfw-y">
      <div class="txt-cont-pres">
        <h1 class="title-1">¡¡Dale vida a tu evento!!</h1>
        <span class="desc-1">Entre más personalices tu evento más sencillo será para tus invitados encontrar tu mesa de
          regalos.</span>
        <span class="obli-txt">* <span class="obli-txt-alt">Campos obligatorios</span></span>
      </div>
      <form class="form-mr">
        <div class="large-inp">
          <div class="txt-box">
            <span class="force">*</span>
            <span class="inp-title">Dale nombre a tu evento</span>
          </div>
          <div class="tip-cont">
            <span class="tip">TIP: Puede ser la celebración en sí más tu nombre o apodo de cariño. <span
                class="ejemplo">Ejemplo "Cumple peque"</span></span>
          </div>
          <div class="inp-cont">
            <input type="text" class="form-inpa" [(ngModel)]="nombreEvento" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="fecha-cont">
          <div class="txt-box">
            <span class="force">*</span>
            <span class="inp-title">Fecha del evento.</span>
          </div>
          <div class="tip-cont">
            <span class="tip">Recuerda que puedes debes crear una mesa de regalos con un mes o más de
              anticipación.</span>
          </div>
          <div class="fecha-cont">
            <mat-form-field>
              <mat-label>Elige la fecha</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="fechaEvento" [ngModelOptions]="{standalone: true}">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker" style="z-index: 9999999;"></mat-datepicker-toggle>
              <mat-datepicker #picker style="z-index: 9999999;"></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- <div class="image-cont">
            <div class="txt-box">
              <span class="inp-title">Sube una imagen de tu evento</span>
            </div>
            <div class="custom-file-input">
              <button type="button" (click)="fileInput.click()" class="btn-img">
                <img src="assets/img/mesa-regalos/icon-step-2-1.webp" alt="">
              </button>
              <input type="file" accept="image/*" #fileInput (change)="onFileSelected($event)" style="display: none;">
              <span class="file-name">{{ fileName }}</span>
            </div>
            <div class="msg-cont-img">
              <span class="msg">La imagen puede ser una foto tuya, de una invitación fisica, el logotipo de tu empresa,
                la fechada de tu nueva casa, etc.</span>
            </div>
          </div> -->
          <div class="txtbox-mensaje-cont">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Deja un mensaje para tus invitados</span>
            </div>
            <div class="tip-cont">
              <span class="tip">Escribe un breve mensaje de agradecimiento en menos de 500 caracteres.</span>
            </div>
            <div class="text-area-cont">
              <textarea name="" id="" class="ta-step-2" [(ngModel)]="mensajeInvitados"
                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>
        </div>
      </form>
      <div class="btns-cont">
        <button class="salir-form" (click)="handleForm()">Cerrar</button>
        <button class="sp" (click)="paso4()">Siguiente paso</button>
      </div>
    </div>
    <div class="msg-no-completado" #msg_no_completado_paso_dos>
      <span class="msg"><span class="aster">*</span> No has completado los campos obligatorios.</span>
    </div>
  </div>

  <div class="step-3-cont" #step_3_cont>
    <div class="ban-cont">
      <img src="/assets/img/mesa-regalos/b-form-3.webp" alt="">
    </div>
    <div class="ovfw-y">
      <!-- <div class="txt-cont-pres">
        <h1 class="title-1">¿A donde vamos a enviarte tus regalos?</h1>
        <span class="obli-txt">* <span class="obli-txt-alt">Campos obligatorios</span></span>
      </div> -->
      <form class="form-mr">
        <!-- <div class="select-dir-cont">
          <div class="txt-box">
            <span class="force">*</span>
            <span class="inp-title">Selecciona una dirección</span>
          </div>
          <div class="conten-direcs">
            <input type="radio" id="dir1" name="radioDir" value="miDirección" [(ngModel)]="miDireccion"
              [ngModelOptions]="{standalone: true}" (change)="desactivarFormDireccion()">
            <label for="dir1" class="dir-txt">
              A mi domicilio
            </label>
            <input type="radio" id="dir2" name="radioDir" value="2" (change)="activarFormDireccion()">
            <label for="dir2" class="dir-txt">
              Enviar a otra dirección
            </label>
          </div>
        </div> -->
        <!-- <form class="form-od" *ngIf="banderaFormularioDireccion">
          <div class="large-inp">
            <div class="txt-box">
              <span class="force">*</span>
              <span class="inp-title">Enviar a OTRA DIRECCIÓN</span>
            </div>
            <div class="inp-cont">
              <input type="text" class="form-inpa" [(ngModel)]="otraDireccion" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="mitad-inp-cont">
            <div class="fifty-box">
              <div class="txt-box">
                <span class="force">*</span>
                <span class="inp-title">Ciudad</span>
              </div>
              <div class="inp-cont">
                <input type="text" class="form-inpa" [(ngModel)]="otraCiudad" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="fifty-box">
              <div class="txt-box">
                <span class="force">*</span>
                <span class="inp-title">Código Postal</span>
              </div>
              <div class="inp-cont">
                <input type="text" class="form-inpa" [(ngModel)]="otroCodigoPostal"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
          <div class="mitad-inp-cont">
            <div class="fifty-box">
              <div class="txt-box">
                <span class="force">*</span>
                <span class="inp-title">Estado</span>
              </div>
              <div class="inp-cont">
                <input type="text" class="form-inpa" [(ngModel)]="otroEstado" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
        </form> -->
        <!-- <div class="fecha-cont">
          <div class="txt-box">
            <span class="force">*</span>
            <span class="inp-title">Fecha del evento.</span>
          </div>
          <div class="tip-cont">
            <span class="tip">Recuerda que puedes debes crear una mesa de regalos con un mes o más de
              anticipación.</span>
          </div>
          <div class="inp-fecha-cont">
            <mat-form-field>
              <mat-label>Elige la fecha</mat-label>
              <input matInput [matDatepicker]="picker2" [(ngModel)]="fechaEventoDos"
                [ngModelOptions]="{standalone: true}">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker2" style="z-index: 9999999;"></mat-datepicker-toggle>
              <mat-datepicker #picker2 style="z-index: 9999999;"></mat-datepicker>
            </mat-form-field>
          </div>
        </div> -->
      </form>
      <div class="btns-cont">
        <button class="salir-form" (click)="handleForm()">Cerrar</button>
        <button class="sp" (click)="paso4()">Siguiente paso</button>
      </div>
      <div class="msg-no-completado" #msg_no_completado_paso_tres>
        <span class="msg"><span class="aster">*</span> No has completado los campos obligatorios.</span>
      </div>
    </div>
  </div>
  <div class="step-4-cont" #step_4_cont>
    <div class="ban-cont">
      <img src="/assets/img/mesa-regalos/b-form-4.webp" alt="">
    </div>
    <div class="ovfw-y">
      <div class="txt-cont-pres">
        <h1 class="title-1">Contrato de la mesa de regalos</h1>
        <span class="desc-1">Siempre podrás consultar el contrato las veces que lo necesites desde tu cuenta de
          usuario.</span>
      </div>
      <div class="contrato-txt">
        <span>
          PRIMERA.- Por virtud del presente contrato EL (LOS) CLIENTE(S) “Usuarios Organizadores” participará(n) en el
          PROGRAMA, para lo cual deberá(n) registrarse en cualquiera de la(s) página(s) web www.lideart.com.mx/home en
          la sección de “Mesa de Regalos” y/o en el App de Lideart del territorio nacional.
        </span>
        <span>
          SEGUNDA.- El Registro y operación del PROGRAMA consiste en que EL (LOS) CLIENTE(S) podrán elegir el tipo de
          Evento que van a celebrar, así como sus regalos en cualquiera de la(s) pagina(s) web www.lideart.com.mx/home
          en la sección de “Mesa de Regalos”, en el App de Lideart formando la lista de regalos, EL (LOS) CLIENTE(S)
          reconocen y aceptan, que la lista creada mediante los insumos de LIDEART y dado que está alojada en los
          sistemas informáticos y operativos de LIDEART es propiedad de este, por lo que EL (LOS) CLIENTE(S) se
          comprometen a no compartir dicha lista con proveedores terceros ajenos a LIDEART que presten servicios iguales
          y/o similares.
        </span>
        <span>
          TERCERA.- EL (LOS) CLIENTE(S) no podrán tener dos o más tipos de eventos y/o PROGRAMAS iguales durante un año,
          es decir a partir del registro de su primer Evento y/o durante un año no podrán tener activos dos (2)
          registros de boda, 2 registros de bautizo, etc., sin importar el estatus de los mismos.
        </span>
        <span>
          CUARTA.- EL (LOS) CLIENTE(S) al momento de su registro al PROGRAMA, decidirán qué artículos (Sku) de los que
          integran su Lista de Regalos su importe neto de compra se convertirá y se abonará a certificado o regalo a
          favor de EL (LOS) CLIENTE(S) , así como cuales artículos se deberán entregar físicamente en el domicilio que
          EL (LOS) CLIENTE(S) haya(n) asignado. El domicilio que se registrará en el evento para la entrega de las
          compras personales y/o regalos deberá ser particular, Lideart no se responsabiliza de las entregas realizadas
          al registrar direcciones que sean de uso comercial como: Almacenes Lideart, domicilio de comercios, hoteles,
          oficinas corporativas etc.)
        </span>
        <span>
          Los regalos que hayan sido seleccionados para entrega a domicilio serán entregados en el domicilio que les
          haya sido asignado por EL (LOS) CLIENTE(S) para su entrega al momento de su registro en el PROGRAMA cuando
          sean adquiridos por los invitados enla página de Lideart o App en la cláusula primera del presente contrato
          y/o en LIDEART, o a través del App de Lideart, Ventas por teléfono, siempre y cuando el domicilio se encuentre
          dentro del área de entregas de Lideart.
        </span>
        <span>
          Asimismo, los regalos y compras personales, deberán ser entregados en el domicilio proporcionado por EL (LOS)
          CLIENTE(S) para su entrega durante el proceso, EL (LOS)CLIENTE(S) solamente podrá(n) hacer dos cambios de
          domicilio para la entrega de los regalos respecto de aquellos que aún no hayan sido comprados.
        </span>
        <span>
          QUINTA.- Además de los bienes que soliciten EL (LOS) CLIENTE(S) en la lista de regalos, aparecerán como
          opciones de compra para los invitados “Certificados Electrónicos de Regalo”, debiendo LIDEART abonar a su
          certificado favor de EL (LOS) CLIENTE(S) los montos indicados por los invitados al evento, de conformidad con
          las opciones ofrecidas por LIDEART, cuando lo solicite EL (LOS) CLIENTE(S) o en la fecha de conclusión del
          PROGRAMA.
        </span>
        <span>
          SEXTA.-EL (LOS) CLIENTE(S) en el presente acto, expresamente manifiestan su consentimiento y conformidad, que
          en el caso de que la orden de compra no se complete en el tiempo estimado (aproximadamente 10 días naturales
          después de la compra) por diversas circunstancias de stock como: falta de inventario y/o el artículo se
          encuentre descontinuado, LIDEART tendrá la facultad de abonar el monto del valor del o los artículo(s)
          faltante(s) al certificado de EL (LOS) CLIENTE(S).
        </span>
        <span>
          SEPTIMA.- EL (LOS) CLIENTE(S) podrán NO hacer devoluciones de productos que le(s) hayan sido regalados ,
          SOLAMENTE aplica devolución por garantía.
        </span>
        <span>
          OCTAVA.- Será obligación para EL (LOS) CLIENTE(S), entregar a LIDEART, copia de su identificación oficial
          cuando su registro cierre y/o cualquier movimiento que dentro del evento se realice.
        </span>
        <span>
          EL (LOS) CLIENTE(S) contará(n) con el plazo de hasta un 1 mes para cerrar su evento posterior a la fecha del
          evento registrada al momento de la apertura, después de esta fecha ya no podrán hacerlo, ni podrán exigir
          ningún tipo de reclamación o beneficio del PROGRAMA, ya que LIDEART después del plazo señalado, no conservará
          ningún tipo de información y/o documentación respecto de programa y/o evento alguno, la presente cláusula será
          aplicable para todo tipo de evento.
        </span>
        <span>
          CASO FORTUITO Y/O FUERZA MAYOR Ante un evento de caso fortuito y/o fuerza mayor, que impida a cualquiera de
          las partes el cumplimiento total o parcial, de las obligaciones a su cargo, dentro de los plazos establecidos
          de conformidad con el presente contrato, estarán exentas de su cumplimiento, sin responsabilidad alguna, en
          tanto subsista el evento de caso fortuito y/o fuerza mayor. Las partes procurarán notificar o hacer del
          conocimiento de la otra, del evento de caso fortuito y/o fuerza mayor, que le impida el cumplimiento de las
          obligaciones antes referidas.
        </span>
        <span>
          Asimismo, LIDEART se reserva el derecho de solicitar a EL (LOS) CLIENTE(S) en cualquier momento, presentar la
          siguiente documentación:
        </span>
        <span>
          a. En su caso, la constancia y/o documento verídico que acredité fehacientemente la realización de su evento
          en la fecha indicada al momento de su registro en el PROGRAMA, como acta de matrimonio, acta de nacimiento, Fe
          de Bautismo del bebé, certificado de primera comunión, acta de confirmación entre otros, cuya fecha deberá
          coincidir exactamente con la fecha registrada del evento y este por ningún motivo puede ser falsificado. Si
          con Posterioridad al registro, existe un cambio en la fecha del evento, será obligación de EL (LOS) CLIENTE(S)
          dar aviso a LIDEARTde dicha situación proporcionando la nueva fecha para el evento. En caso de que EL(LOS)
          FESTEJADO(S) sea(n) menor(es) de edad, no podrá(n) contratar servicio alguno ni firmar el presente contrato,
          por lo que deberán ser los padres o tutores quienes contraten, firmen y den cumplimiento a las obligaciones
          contenidas en el presente contrato.
        </span>
        <span>
          b. Lideart se reserva el derecho de eliminar los eventos que presenten anomalías, compras en volumen donde no
          se acredite la veracidad del evento, duplicidad de eventos (registros de varios eventos del mismo tipo, o
          mismo titular), y cualquier mal uso que a consideración de Lideart sea pertinente no entregar bonificación del
          evento.
        </span>
        <span>
          c. Los beneficios del programa aplican a partir de la fecha en el que este se contrató, por ningún motivo
          pueden otorgarse beneficios retroactivos a las vigencias establecidas, si se realiza algún cambio de fecha en
          el evento los beneficios de bonificación cambian su temporalidad a lo estipulado en el contrato por cada tipo
          de evento.
        </span>
        <span>
          NOVENA.- LIDEART se reserva el derecho de realizar modificaciones en cualquier momento al presente contrato y
          al programa de Mesa de Regalos, ya sea por medios electrónicos como la sección de Mesa de Regalos de las
          páginas web señaladas en la cláusula primera del presente contrato, correo electrónico, mensajes SMS y/o por
          medios impresos, la continuidad de EL (LOS) CLIENTE(S) en el PROGRAMA significa la total aceptación de los
          cambios realizados.
        </span>
        <span>
          DECIMA.- Para el cumplimiento e interpretación del presente contrato, las partes acuerdan someterse
          expresamente por la ley aplicable de la Ciudad de Chihuahua, renunciando a cualquier otra jurisdicción que les
          corresponda o pudiere corresponderles con motivo de sus domicilios presentes o actuales o por cualquier otra
          causa.
          Enteradas de su contenido y alcance legal, se firma de conformidad en la fecha que aparece en la carátula del
          presente documento.
        </span>
      </div>
      <div class="check-contrato-cont">
        <button class="btn-contrato" (click)="aceptarContrato()">
          <div class="quada" #quada></div>
          <span class="txt-contrato">Acepto los términos y condiciones</span>
        </button>
      </div>
      <div class="btns-cont">
        <button class="salir-form" (click)="handleForm()">Cerrar</button>
        <button class="sp" (click)="finalizar()">Siguiente paso</button>
      </div>
      <div class="msg-no-completado" #msg_no_completado_paso_cuatro>
        <span class="msg"><span class="aster">*</span> No has aceptado el contrato.</span>
      </div>
    </div>
  </div>
</section>