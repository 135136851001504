.iconos{
    height: 13px;
    width: 13px;
    margin-left: 4px;
    margin-right: 4px;
  }
  
  .catalogoDesk{
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
  
    .contDesk{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 2em 0em;
      gap: 2em;
  
      .title-cont{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    
        h1{
          color: #1e7285;
          font-weight: 100;
          font-size: 2.2em;
          text-align: center;
        }
      }
  
      .conten-cats{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2em;
  
        .cardin{
          width: 16em;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          padding: 1em 0em;
          //border-bottom: 1px solid #0ba7c7;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          transition: .4s all ease-in-out;
          gap: 1em;
  
          .cat-name{
            color: #00a3c7;
            font-weight: 100;
            text-align: center;
            padding: 0em 1em;
          }
  
          .contImg{
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1em;
  
            img{
              width: 100%;
              max-height: 15em;
            }
          }

          .line-tb{
            // padding: 1.5em 0.5em;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: .5em;

            .colors-container {
              display: flex;
            }
      
            .r-color{
              // position: absolute;
              margin: 0.5px;
              width: 20px;
              height: 20px;
              border: 1px solid #cfcfcf;
              border-radius: 50%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              cursor: default;
              // right: 1em;
              // top: 0.5em;
            }
      
            .r-color.hidden {
              display: none;
            }
      
            .r-color.more-colors {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              border: 1px solid #cfcfcf;
              border-radius: 50%;
              background-color: #f0f0f0;
              margin: 0.1em;
            }
          }
  
          .pric{
            color: #222;
            font-weight: 100;
            text-align: center;
            padding: 0em 1em;
          }
        }
  
        .cardin:hover{
          box-shadow: rgba(23, 171, 202, 0.5) 0px 6px 24px;
        }
      }
    }
  }
  
  @media(min-width:992px){
    .display-grid{
      display: grid;
      grid-template-columns: repeat(1,1fr);   
    }  
  
    ion-content{
      display: none;
    }
  
    ion-header{
      display: none;
    }
  
    .catalogoDesk{
      display: flex;
    }
  }
  
  .fixin{
    width: 100%; 
    height: 8.5em;
    margin: 0; 
    // margin-top: .4em 0; 
    padding: 0em 0.5em; 
    text-align: center;
    text-transform: uppercase; 
    font-weight: normal; 
    font-size: .9em; 
    overflow-wrap: break-word; 
    overflow: hidden;
  }
  
  .catalogoDesk::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
  }
  
  .catalogoDesk::-webkit-scrollbar-thumb {
    background-color: #00a2c779;
    //border-radius: 1vh;
    width: 0.25em;
  }
    
  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .skeleton-text__body {
    width: 75%;
  }
  
  .skeleton-footer {
    width: 30%;
  }
  

  .loader {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: fixed;
    top: calc(0%);
    left: calc(0%);
    background-color: white;
    z-index: 9999999;
    
    
    
    img{
      width: 200px;
      height: auto;
    
    }
    
    }