import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { IonLoading } from '@ionic/angular/standalone';
import Swal from 'sweetalert2';
import { ModalCargaComponent } from '../../components/modal-carga/modal-carga.component';
import { Registro } from '../../Models/registro.interface';
import { MailchimpServiceService } from '../../services/mailchimp-service.service';
import { RegistroService } from '../../services/registro.service';

@Component({
  selector: 'app-modal-registro',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    IonLoading,
    RouterModule,
    ModalCargaComponent
  ],
  templateUrl: './modal-registro.component.html',
  styleUrls: ['./modal-registro.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalRegistroComponent implements OnInit {
  isSubmitted = false;
  @ViewChild('modal_wp') modal_wp!: ElementRef<HTMLInputElement>;
  modal_wpIs = false;
  // modal_wpIs = false;
  datosDynamics: any = [];
  customerID!: string;
  correo!: string;
  regis!: Registro;
  isLoading = false;
  newsActive: any;
  newsletter!: number;
  regisnew: any;
  facturame = false;
  UsoCFDI: any;
  usocfdi: any;
  regimenFiscal: any;
  ciudadilla: any;
  cargando = false;
  codiguillo: any = [];
  arrayCiudades: any = [];
  arrayColonias: any = []
  codigoEscrito = 0;
  arraicitoCiudades: any = [];
  arraisitoColonias: any = []
  arraicitoEstado: any = [];
  jsonReg: any = [];
  nombresito: any;
  apellidito: any;
  correito: any;
  Direccionsilla: any;
  ciudadsinCodigo: any;
  nombreColonia: any;
  ciudadSeleccionada: any;
  countyId: any;
  dropDir: any;
  rfc: any;
  captcha = "";
  regisForm: FormGroup = new FormGroup({
    nombre: new FormControl('')
  });

  constructor(
    private modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public router: Router,
    public renderer: Renderer2,
    public registroService: RegistroService,
    private titleService: Title,
    private mailchimp: MailchimpServiceService
    //private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Registro");
    this.regisForm = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), Validators.minLength(2), Validators.maxLength(36)]],
      apellidos: ['', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), Validators.minLength(5), Validators.maxLength(36)]],
      rfc: ['', [Validators.pattern('^[A-Za-z0-9]+$'), Validators.minLength(12), Validators.maxLength(13)]],
      razon: [''],
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      /* https://localcoder.org/how-to-validate-password-strength-with-angular-5-validator-pattern  */
      /* 
      - At least 8 characters in length
      - Lowercase letters
      - Uppercase letters
      - Numbers
      - Special characters
      */
      news: [true],
      priv: [false, [Validators.pattern('true')]],
      term: [false, [Validators.pattern('true')]],
      zipcode: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      stateValue: [''],
      cityValue: [''],
      colonyValue: [''],
      countryValue: [''],
      alias: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      phonenumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      correo2: ['']
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.regisForm.controls;
  }

  register() {
    this.presentLoading()
    this.isSubmitted = true;
    if (this.regisForm.invalid) {

      return;
    }
    /*this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
      this.captcha = token;
   
      if (this.captcha =="") {
        return;
      }
    });*/
    this.regis = this.regisForm.value;
    this.newsActive = this.regis.news;
    ////console.log(this.newsActive);
    if (this.newsActive == true) {
      this.newsletter = 1;
    } else {
      this.newsletter = 0;
    }

    this.regis.newsletter = this.newsletter;


    //this.SpinnerService.show();
    this.registroService.registroUsuario(this.regis).subscribe(response => {

      if (response == 1) {

        this.addUserDynamics(this.regis);
        this.dismiss();

      }
      if (response == 2) {
        this.dismiss();
        //this.errorNotificationCorreo();
        //this.SpinnerService.hide();

      }

    });


  }


  imprimir() {
    var separame = this.regisForm.controls['colonyValue'].value.split('-');
    var a = separame[0];
    var b = separame[1]
    this.nombreColonia = a;
    this.countyId = b;

  }

  facturado() {
    if (this.facturame == false) {
      this.facturame = true;

    } else {
      this.facturame = false;
    }

  }


  registrame() {

    this.isSubmitted = true;
    //console.log(1)
    if (this.regisForm.invalid) {

      //console.log(2222)
      return;
    } else {
      this.cargando = true;
      //console.log(3)
      // this.cargando = true;
      // this.submittedAddress = true;
      //console.log(this.regisForm.value)
      this.registroService.registroUsuario(this.regisForm.value).subscribe(response => {
        //console.log(response)
        if (response == 1) {


          // var nombre = datosUsuario[0].toUpperCase();
          // var apellidos = datosUsuario[1].toUpperCase();
          // var rfc = datosUsuario[2].toUpperCase();
          // var email = datosUsuario[4].toUpperCase();

          var correoUsuario: any = Object.values(this.regisForm.value);
          var emailUsuario = correoUsuario[4];
          this.correito = correoUsuario[4];
          var ciudad = this.ciudadSeleccionada
          this.regisForm.controls['cityValue'].setValue(ciudad);
          // var colonia = (<HTMLSelectElement>document.getElementsByName("colony")[0]).selectedOptions[0].innerText;
          // var estado = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;
          var phone = correoUsuario[17];
          var stateValue = correoUsuario[10]
          // var nc = colonia.split(' ')
          let colonyArray = correoUsuario[12];
          let separados = colonyArray.split('-');

          let colony = separados[0];
          // nc.pop()
          // var coloniaFinal = nc.join(' ')
          // this.regisForm.controls.colonyValue.setValue(coloniaFinal);
          // var nc = colonia.split(' ')
          // nc.pop()
          // var coloniaFinal = nc.join(' ')
          // this.regisForm.controls.colonyValue.setValue(coloniaFinal);
          this.regisForm.controls['correo2'].setValue(emailUsuario);
          // this.regisForm.controls.stateValue.setValue(estado);
          // this.regisForm.controls.stateValue.setValue(estado);
          this.regisForm.controls['phonenumber'].setValue(phone);
          this.regisForm.controls['colonyValue'].setValue(colony);
          this.regisForm.controls['stateValue'].setValue(this.ciudadsinCodigo);
          this.registroService.enviaDireccion(this.regisForm.value).subscribe(data => {

            var correoUsuario: any = Object.values(this.regisForm.value);
            var emailUsuario = correoUsuario[4];
            this.regisForm.controls['correo2'].setValue(emailUsuario);
            // this.addUserDynamics(this.registroForm.value);
            // this.cargando = true;

            var datosUsuario: any = Object.values(this.regisForm.value);

            this.rfc = datosUsuario[2];
            // this.regimenFiscal = datosUsuario[9];
            // this.usoCfdi = datosUsuario[10];
            //console.log(4)
            if (datosUsuario[2] == '' || datosUsuario[2] == null) {
              this.rfc = "XAXX010101000";

            }
            if (this.regimenFiscal == '' || this.regimenFiscal == null) {
              this.regimenFiscal = "616";


            }
            if (this.usocfdi == '' || this.usocfdi == null) {
              this.usocfdi = "S01";

            }
            //console.log(5.1)
            var nombre = datosUsuario[0].toUpperCase();

            //console.log(5.2)
            var apellidoPat = datosUsuario[1].toUpperCase();
            this.nombresito = datosUsuario[0].toUpperCase();
            this.apellidito = datosUsuario[1].toUpperCase();
            // var apellidoMat = datosUsuario[2].toUpperCase();
            // var rfc = datosUsuario[4].toUpperCase();
            //console.log(5.3)
            var correoElectronico = datosUsuario[4];



            var datosDireccion: any = Object.values(this.regisForm.value);
            //console.log(5.4)
            var alias = datosDireccion[14].toUpperCase();
            //console.log(5.5)
            var codigoPostal = datosDireccion[9];
            //console.log(5.6)
            var direccion = datosDireccion[15].toUpperCase();

            this.Direccionsilla = datosDireccion[15].toUpperCase();
            //console.log(5.7)
            var direccionComp = datosDireccion[16].toUpperCase();
            //console.log(5.9)
            var pais = datosDireccion[13].toUpperCase();
            //console.log(5.10)
            var estado = datosDireccion[10].toUpperCase();
            //console.log(5.11)
            var ciudad = this.ciudadSeleccionada;


            //var colonia = datosDireccion[12].toUpperCase();

            var telefono = datosDireccion[17];
            //console.log(5.12)
            var countyJson = {
              "codigo_postal": codigoPostal,
              "asentamiento": datosDireccion[14]

            }
            // this.usuariosService.checaCountyID(JSON.stringify(countyJson)).subscribe(countyID=>{
            //   this.datoscounty = countyID;

            //   this.countyId = this.datoscounty[0].CountyId;

            //   if (this.countyId == null || this.countyId == undefined) {
            //     this.countyId = this.datoscounty[0].id_codigo_postal+"_"+codigoPostal;
            //   }


            // var jsonUsuario = {
            //   "CustomerAccount": "",
            //   "OrganizationName": nombre+" "+apellidoPat,
            //   "SalesCurrencyCode": "MXN",
            //   "CustomerGroupId": "CONTADO",
            //   "SalesDistrict": "CHIH",
            //   "CompanyType": "2",
            //   "RFCNumber": this.rfc,
            //   "SiteId": "CHIH",
            //   "LineDiscountCode": "LID SUB 2",
            //   "SalesSegmentId": "MANUALIDAD",
            //   "PrimaryContactPhone": telefono,
            //   "PrimaryContactEmail": correoElectronico,
            //   "satPurpose_MX": this.usocfdi,
            //   "Regimen":Number(this.regimenFiscal),
            //   "Direcciones": [
            //   {
            //   "dataAreaId": "LIN",
            //   "customerLegalEntityId": "LIN",
            //   "customerAccountNumber": "",
            //   "addressDescription": alias,
            //   "addressZipCode": codigoPostal,
            //   "addressState": this.ciudadilla.toUpperCase(),
            //   "addressCountryRegionId": "MEX",
            //   "addressCity": this.ciudadSeleccionada.toUpperCase(),
            //   "addressStreet": direccion,
            //   "addressStreetNumber": "",
            //   "addressCountyId":this.countyId,
            //   "addressCountyName": this.nombreColonia.toUpperCase(),
            //   "addressLocationRoles": "Business",
            //   "isPrimary": "Yes",
            //   "addressUniqueId": "New"
            //   }
            //   ],
            //   "Contactos": [
            //   {
            //   "partyNumber": "0",
            //   "description": nombre,
            //   "locator": telefono,
            //   "locatorExtension": "",
            //   "type": "1",
            //   "isPrimary": "Yes",
            //   "partyUniqueId": "New"
            //   },
            //   {
            //     "partyNumber": "0",
            //     "description": nombre,
            //     "locator": correoElectronico,
            //     "locatorExtension": "",
            //     "type": "2",
            //     "isPrimary": "Yes",
            //     "partyUniqueId": "New"
            //     }
            //   ]
            //   }
            //console.log(5.13)


            ////////////////////////////////////////////////////////////////////////////////////////////////////////

            //MAIL CHIMP

            if (this.regisForm.controls['news'].value) {
              try {
                let mailchonJSON =
                {
                  "email_address": correoElectronico,
                  "status": "subscribed",
                  "merge_fields": {
                    "FNAME": nombre,
                    "LNAME": apellidoPat,
                    "ADDRESS": {
                      "addr1": direccion,
                      "addr2": "",
                      "city": ciudad,
                      "state": estado,
                      "zip": codigoPostal,
                      "country": "MX"
                    },
                    "PHONE": telefono
                  }
                }

                this.mailchimp.MailChimpReg(JSON.stringify(mailchonJSON)).subscribe(mandaChimp => {
                  console.log("Registrado en MailChimp", mandaChimp);
                })
              } catch (error) {
                console.error("Error al registrar en MailChimp", error);
              }
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            var jsonUsuario =
            {
              "CustomerAccount": "",
              "OrganizationName": nombre + " " + apellidoPat,
              "SalesCurrencyCode": "MXN",
              "CustomerGroupId": "CONTADO",
              "SalesDistrict": "CHIH",
              "CompanyType": "2",
              "RFCNumber": this.rfc,
              "SiteId": "CHIH",
              "LineDiscountCode": "LID SUB 2",
              "SalesSegmentId": "MANUALIDAD",
              "Direcciones": [
                {
                  "dataAreaId": "LIN",
                  "customerLegalEntityId": "LIN",
                  "customerAccountNumber": "",
                  "addressDescription": alias,
                  "addressZipCode": codigoPostal,
                  "addressState": this.ciudadilla,
                  "addressCountryRegionId": "MEX",
                  "addressCity": this.ciudadSeleccionada,
                  "addressStreet": direccion,
                  "addressStreetNumber": "",
                  "AddressCounty": this.countyId,
                  "addressCountyName": this.nombreColonia,
                  "addressLocationRoles": "Delivery;Business;Invoice",
                  "isPrimary": "Yes",
                  "addressUniqueId": "New"
                }
              ],
              "Contactos": [
                {
                  "partyNumber": "0",
                  "description": nombre,
                  "locator": telefono,
                  "locatorExtension": "",
                  "type": "1",
                  "isPrimary": "Yes",
                  "partyUniqueId": "New"
                },
                {
                  "partyNumber": "0",
                  "description": nombre,
                  "locator": correoElectronico,
                  "locatorExtension": "",
                  "type": "2",
                  "isPrimary": "No",
                  "partyUniqueId": "New"
                }
              ],
              "Regimen": this.regimenFiscal,
              "SATPurpose_MX": this.usocfdi
            }
            //console.log(6)
            this.presentLoading();

            this.registroService.registroDynamics(JSON.stringify(jsonUsuario)).subscribe(dataUser => {
              ////console.log(dataUser);
              if (dataUser.exito == 1) {
                this.datosDynamics = Object.values(dataUser);
                var arrayDatos = JSON.parse(this.datosDynamics[2]);

                this.customerID = arrayDatos.CustomerAccount;
                this.correo = correoElectronico;
                // this.correo = arrayDatos.PrimaryContactEmail;

                var datosJson = {
                  'customerID': this.customerID,
                  'correo': this.correo
                }

                this.registroService.actualizaCustomerID(JSON.stringify(datosJson)).subscribe(data => { });



                let jsonBienvenida = {
                  "nombrePersona": nombre + " " + apellidoPat,
                  "email": this.correo
                }

                try {
                  this.registroService.correoRegistro(jsonBienvenida).subscribe(adasdd => {
                    // this.handleWP();
                    this.cargando = false;
                    this.router.navigate(['/login'])

                  })

                } catch (error) {
                  this.router.navigate(['/login'])
                  this.cargando = false;
                }


              }

            });





          });

        }
        if (response == 2) {
          //console.log("correo ya existe")
          this.direccionMala();
          this.cargando = false;
        }

      });



    }



  }

  handleWP() {
    if (this.modal_wpIs == false) {
      this.renderer.setStyle(this.modal_wp.nativeElement, 'display', 'flex');

      this.modal_wpIs = true
    } else if (this.modal_wpIs == true) {
      this.renderer.setStyle(this.modal_wp.nativeElement, 'display', 'none');

      this.modal_wpIs = false
      this.router.navigate(['/login'])
    }
  }

  mandaDatos(county: any, nombreColonia: any) {

    this.countyId = county
    this.nombreColonia = nombreColonia;

  }

  buscameXCodigoPostal(codigo: any) {
    if (!codigo || codigo.length < 1) return
    try {
      this.cargando = true;

      this.cargandoCosas();
      this.registroService.getEstados(codigo).subscribe(codigazo => {
        this.arraicitoEstado = codigazo
        this.codiguillo = this.arraicitoEstado.data;
        if (this.codiguillo == null || this.codiguillo == undefined) {
          this.ErrorCodigo();
          this.cargando = false;
          this.codigoEscrito = 0;
        } else {
          this.codigoEscrito = 1;
          this.ciudadilla = this.codiguillo.stateId;
          if (this.ciudadilla == 'AGS') { this.ciudadsinCodigo = "Aguascalientes" }
          if (this.ciudadilla == 'BCA') { this.ciudadsinCodigo = "Baja California" }
          if (this.ciudadilla == 'BCS') { this.ciudadsinCodigo = "Baja California Sur" }
          if (this.ciudadilla == 'CAM') { this.ciudadsinCodigo = "Campeche" }
          if (this.ciudadilla == 'CHI') { this.ciudadsinCodigo = "Chiapas" }
          if (this.ciudadilla == 'CHH') { this.ciudadsinCodigo = "Chihuahua" }
          if (this.ciudadilla == 'COA') { this.ciudadsinCodigo = "Coahuila" }
          if (this.ciudadilla == 'COL') { this.ciudadsinCodigo = "Colima" }
          if (this.ciudadilla == 'DIF') { this.ciudadsinCodigo = "Distrito Federal" }
          if (this.ciudadilla == 'DGO') { this.ciudadsinCodigo = "Durango" }
          if (this.ciudadilla == 'GTO') { this.ciudadsinCodigo = "Guanajuato" }
          if (this.ciudadilla == 'GRO') { this.ciudadsinCodigo = "Guerrero" }
          if (this.ciudadilla == 'HGO') { this.ciudadsinCodigo = "Hidalgo" }
          if (this.ciudadilla == 'JAL') { this.ciudadsinCodigo = "Jalisco" }
          if (this.ciudadilla == 'CDMX') { this.ciudadsinCodigo = "Estado de México" }
          if (this.ciudadilla == 'MEX') { this.ciudadsinCodigo = "Estado de México" }
          if (this.ciudadilla == 'MIC') { this.ciudadsinCodigo = "Michoacán" }
          if (this.ciudadilla == 'MOR') { this.ciudadsinCodigo = "Morelos" }
          if (this.ciudadilla == 'NAY') { this.ciudadsinCodigo = "Nayarit" }
          if (this.ciudadilla == 'NLE') { this.ciudadsinCodigo = "Nuevo León" }
          if (this.ciudadilla == 'OAX') { this.ciudadsinCodigo = "Oaxaca" }
          if (this.ciudadilla == 'PUE') { this.ciudadsinCodigo = "Puebla" }
          if (this.ciudadilla == 'QRO') { this.ciudadsinCodigo = "Querétaro" }
          if (this.ciudadilla == 'ROO') { this.ciudadsinCodigo = "Quintana Roo" }
          if (this.ciudadilla == 'SLP') { this.ciudadsinCodigo = "San Luis Potosí" }
          if (this.ciudadilla == 'SIN') { this.ciudadsinCodigo = "Sinaloa" }
          if (this.ciudadilla == 'SON') { this.ciudadsinCodigo = "Sonora" }
          if (this.ciudadilla == 'TAB') { this.ciudadsinCodigo = "Tabasco" }
          if (this.ciudadilla == 'TAM') { this.ciudadsinCodigo = "Tamaulipas" }
          if (this.ciudadilla == 'TLA') { this.ciudadsinCodigo = "Tlaxcala" }
          if (this.ciudadilla == 'VER') { this.ciudadsinCodigo = "Veracruz" }
          if (this.ciudadilla == 'YUC') { this.ciudadsinCodigo = "Yucatán" }
          if (this.ciudadilla == 'ZAC') { this.ciudadsinCodigo = "Zacatecas" }

          this.registroService.getCiudades(codigo, this.ciudadilla).subscribe(ciudadcillas => {

            this.arraicitoCiudades = ciudadcillas
            this.arrayCiudades = this.arraicitoCiudades.data;
            this.ciudadSeleccionada = this.arraicitoCiudades.data[0].ciudadSeleccionada


            this.registroService.getColonias(codigo).subscribe(coloniecillas => {
              this.arraisitoColonias = coloniecillas
              this.arrayColonias = this.arraisitoColonias.data;



              this.cargando = false;
            })
          })
        }

      })
    } catch (error) {
      this.cargando = false;
    }
  }

  async presentLoading() {
    //console.log("presentLoading()")
    // this.isLoading = true;
    // return await this.loadingController.create({
    //   cssClass: 'my-custom-class',
    //   message: 'Registrando Usuario...',
    //   duration: 3000,
    //   spinner: 'bubbles'
    // }).then(a => {
    //   a.present().then(() => {
    //     //console.log('presented');
    //     if (!this.isLoading) {
    //       a.dismiss().then(() => //console.log('abort presenting'));
    //     }
    //   });
    // });

  }

  async cargandoCosas() {
    //console.log("cargandocosas()")
    // this.isLoading = true;
    // return await this.loadingController.create({
    //   cssClass: 'my-custom-class',
    //   message: 'Cargando Direccion...',
    //   duration: 2000,
    //   spinner: 'bubbles'
    // }).then(a => {
    //   a.present().then(() => {
    //     //console.log('presented');
    //     if (!this.isLoading) {
    //       a.dismiss().then(() => //console.log('abort presenting'));
    //     }
    //   });
    // });

  }



  async ErroCorreo() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Este correo ya ha sido registrado.',
      duration: 3000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        //console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });

  }


  async ErrorCodigo() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'No existe el codigo postal ingresado',
      duration: 3000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        //console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });

  }


  async direccionMala() {
    Swal.fire({
      icon: "error",
      title: "Este correo ya se encuentra Registrado",
      showConfirmButton: false,
      timer: 2000
    });
  }

  togglePassword() {
    var pass = document.getElementById('showPassword')!;

    pass.classList.toggle('fa-eye-slash');

    var input = document.getElementById('inputPassword');
    if ((<HTMLInputElement>input).type == 'password') {
      (<HTMLInputElement>input).setAttribute('type', 'text')
    } else {
      (<HTMLInputElement>input).setAttribute('type', 'password')
    }
  }

  addUserDynamics(registroUsuario: any) {
    //this.SpinnerService.show();
    var datosUsuario: any = Object.values(registroUsuario);

    var nombre = datosUsuario[0].toUpperCase();
    var apellidos = datosUsuario[1].toUpperCase();
    var rfc = datosUsuario[2].toUpperCase();
    var email = datosUsuario[4].toUpperCase();

    if (rfc == '' || rfc == null) {
      rfc = "XAXX010101000";
    }

    var jsonUsuario = {
      "CustomerAccount": "",
      "OrganizationName": nombre + " " + apellidos,
      "SalesCurrencyCode": "MXN",
      "CustomerGroupId": "CONTADO",
      "SalesDistrict": "CHIH",
      "CompanyType": "2",
      "RFCNumber": rfc,
      "SiteId": "CHIH",
      "LineDiscountCode": "CHIH-D-BS",
      "SalesSegmentId": "COMERCIO",
      "PrimaryContactPhone": "",
      "PrimaryContactEmail": email,
      "Direcciones": [
        {
          "dataAreaId": "LIN",
          "customerLegalEntityId": "LIN",
          "customerAccountNumber": "",
          "addressDescription": "",
          "addressZipCode": "",
          "addressState": "",
          "addressCountryRegionId": "MEX",
          "addressCity": "",
          "addressStreet": "",
          "addressStreetNumber": "",
          "addressCountyId": "",
          "addressCountyName": "",
          "addressLocationRoles": "Business",
          "isPrimary": "Yes",
          "addressUniqueId": "New"
        }
      ],
      "Contactos": [
        {
          "partyNumber": "0",
          "description": nombre,
          "locator": "",
          "locatorExtension": "",
          "type": "1",
          "isPrimary": "Yes",
          "partyUniqueId": "New"
        },
        {
          "partyNumber": "0",
          "description": nombre,
          "locator": email,
          "locatorExtension": "",
          "type": "2",
          "isPrimary": "Yes",
          "partyUniqueId": "New"
        }
      ]
    }

    // //console.log('Datos = ' + JSON.stringify(jsonUsuario));
    this.registroService.registroDynamics(JSON.stringify(jsonUsuario)).subscribe(dataUser => {
      if (dataUser == 1) {
        this.datosDynamics = Object.values(dataUser);
        var arrayDatos = JSON.parse(this.datosDynamics[2]);

        this.customerID = arrayDatos.CustomerAccount;
        this.correo = arrayDatos.PrimaryContactEmail;

        var datosJson = {
          'customerID': this.customerID,
          'correo': this.correo
        }

        this.registroService.actualizaCustomerID(JSON.stringify(datosJson)).subscribe(data => { });
        //this.SpinnerService.hide();
        //this.exitoRegistro();

        this.router.navigate(['/login']);
      }

    });
  }

  get errorControl() {
    return this.regisForm.controls;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
