<section class="reviews-cont" style="margin-top: 125px;">
  <div class="desk-review">
    <div class="button-container">
      <h2>¿Quieres dejarnos tu reseña?</h2>
      <button id="open-modal-desk" class="custom-button" (click)="openModalDesk()">
        ¡Adelante!
      </button>
    </div>
    <div class="space">
      <div class="separator"></div>
    </div>
    <div class="list-reviews">
      <div class="list-reviews-page">
        <h2>Reseñas recientes</h2>
        <div class="pagination">
          <button (click)="paginaAnterior()"><i class="fa-light fa-circle-chevron-left"></i></button>
          <button (click)="paginaSiguiente()"><i class="fa-light fa-circle-chevron-right"></i></button>
        </div>
      </div>

      <div class="fixa" *ngIf="loaded">
        <div *ngFor="let producto of reviews" class="comment-card">
          <div class="comment-header">
            <h3 class="comment-title">{{ producto.title }}</h3>
            <div class="comment-stars">
              <img
                src="{{ 'https://new.lideart.com.mx/modules/lgcomments/views/img/stars/plain/bluelight/' + producto.stars + 'stars.png' }}">
            </div>
          </div>

          <div class="comment-content">
            <p [innerHTML]="producto.comment"></p>
          </div>

          <div class="comment-footer">
            <span class="comment-nick">- {{ producto.nick }}</span>
            <span class="comment-date">{{ producto.date }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="modal-alerta" class="modal-overlay" [style.display]="mostrarAlerta ? 'flex' : 'none'">
  <div class="modal-content">
    <div class="modal-header">
      <button class="close-button" (click)="closeAlerta()">X</button>
    </div>

    <div class="modal-body">
      <h3>¡Oops!</h3>
      <p>Por favor, completa todos los campos obligatorios:</p>
      <ul>
        <li *ngIf="!nombre">Nombre</li>
        <li *ngIf="!titulo">Título</li>
        <li *ngIf="!comentario">Comentario</li>
        <li *ngIf="estrellas <= 0">Calificación</li>
      </ul>
    </div>
  </div>
</div>

<!-- Modal para agregar comentario -->
<div id="modal" class="modal-overlay">
  <div class="modal-content">
    <div class="modal-header">
      <button class="close-button" (click)="closeModal()">X</button>
    </div>

    <div class="modal-body">
      <h3>¡Déjanos tu opinión!</h3>
      <select class="selict" name="id_state" [(ngModel)]="estrellas" required>
        <option value="0" selected>Selecciona la calificación</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>

      <input class="inps" type="text" name="nombre" [(ngModel)]="nombre" placeholder="Nombre" required>
      <input class="inps" type="text" name="titulo" [(ngModel)]="titulo" placeholder="Título" required>
      <textarea class="inps" rows="5" cols="50" name="comentario" [(ngModel)]="comentario" placeholder="Comentario"
        required></textarea>

      <button class="custom-button" (click)="registraReview()">Enviar comentario</button>
      <div *ngIf="mostrarError" class="error-message">
        <p>Por favor, completa todos los campos son obligatorios.</p>
      </div>
    </div>
  </div>
</div>

<!-- Lista de carga (skeleton) -->
<div *ngIf="!loaded" class="skeleton-list">
  <div class="skeleton-item" *ngFor="let item of [1, 2, 3, 4]">
    <div class="skeleton-thumbnail"></div>
    <div class="skeleton-content">
      <div class="skeleton-text skeleton-title"></div>
      <div class="skeleton-text skeleton-subtitle"></div>
      <div class="skeleton-text skeleton-subtitle"></div>
    </div>
  </div>
</div>