import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, debounceTime, finalize, map, mergeMap, Observable, of, Subject, switchMap } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';
import { Attribute, Item, searchResult } from '../types/googleSearch';
import { ApiProductosService } from './api-productos.service';

// Esto se utiliza para no respoder un atributo vacio en caso de que haya error en la consulta o no haya resultados
export const DEFAULT_RESPONSE_GOOGLE: searchResult = {
  kind: "",
  url: {
    type: "",
    template: ""
  },
  queries: {
    request: [
      {
        title: "",
        totalResults: "",
        searchTerms: "",
        count: 0,
        startIndex: 0,
        inputEncoding: "",
        outputEncoding: "",
        safe: "",
        cx: ""
      }
    ]
  },
  context: {
    title: ""
  },
  searchInformation: {
    searchTime: 0,
    formattedSearchTime: "",
    totalResults: "",
    formattedTotalResults: ""
  },
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class ApiBuscadorGoogleService {
  preciosos: any = []
  precioInd: any;
  descuento: any;
  infoProd: any;
  resultsPerPage: number = 10;
  private apiKey: string = 'AIzaSyAk5j4McvDwgABOseW9NtWJ4U5npeKPHyk'; //Nueva apikey
  private searchEngineId: string = '72f3d3fcd275e4e7e';
  private apiUrl: string = 'https://www.googleapis.com/customsearch/v1';

  private http = inject(HttpClient)

  private loadingBool = signal(true);
  private lastSearch = "";

  private searchSubject = new Subject<{ query: string, start: number, sort: string }>();
  private searchResultSubject = new Subject<searchResult>();

  totalResultados = 0;


  constructor(private apiProductos: ApiProductosService, private sanitizer: DomSanitizer) {
    this.searchSubject.pipe(
      debounceTime(500), // Ajusta el tiempo de debounce según sea necesario
      switchMap(params => this.realSearch(params.query, params.start, params.sort))
    ).subscribe({
      next: (response) => this.searchResultSubject.next(response),
      error: (error) => this.searchResultSubject.error(error)
    });
  }

  setResultsPerPage(results: number): void {
    this.resultsPerPage = results;
  }

  search(query: string, start: number = 1, sort: string = ''): Observable<searchResult> {
    this.loadingBool.set(true);

    this.searchSubject.next({ query, start, sort });
    return this.searchResultSubject.asObservable();
  }

  realSearch(query: string, start: number = 1, sort: string = ''): Observable<searchResult> {
    const responseItems: Item[] = [];
    this.loadingBool.set(true);
    let params = new HttpParams()
      .set('key', this.apiKey)
      .set('cx', this.searchEngineId)
      .set('q', query)
      .set('start', start.toString())
      .set('num', this.resultsPerPage.toString());

    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http.get<searchResult>(this.apiUrl, { params }).pipe(
      mergeMap(async resp => {
        console.log('Queries:', resp)
        this.lastSearch = query;
        this.totalResultados = Number(resp.searchInformation.totalResults);

        // const tmpPreResp: Item[] = []
        resp.items = resp.items?.filter(e => e.link.split("/").length > 4);


        //Se agregan los resultados forzados en pagina 1:
        if (resp.queries.request[0].startIndex < 10) {
          for (let [clave, v] of miMapa) {
            const incluyeClave = clave.some(c => query.toLocaleLowerCase().includes(c.toLocaleLowerCase()));
            if (incluyeClave) {
              const tmpResp: Item[] = v.concat(resp.items || [])
              resp.items = tmpResp
            }
          }
          if (query.includes('-') || query.length > 3) {
            const r = await this.getProductsFromDb(query).toPromise()
            if (r) {
              const tmpResp: Item[] = r.concat(resp.items || [])
              resp.items = tmpResp
            }
          }
        }

        if (resp.items) {
          const usedIds: string[] = []

          for (let e of resp.items) {

            let tipo: "Categoría" | "Producto" | "Paquete" | null = null;
            const link = e.link

            //Esta secciona cambia el orden de los resultados (Mostrando primero siempre plotters)
            if (link.toLocaleLowerCase().includes('plotter')) {
              resp.items.splice(resp.items.indexOf(e), 1);
              resp.items.unshift(e)
            }


            const casi = link.split("/")
            if (true) {



              let ids = ''

              const titulo = e.title
              if (link.includes('/catalogo/') || link.includes('/categor')) {
                tipo = "Categoría"
              }
              else if ((link.includes('/catalogo/') || link.includes('/producto')) && !link.includes('/paquete')) {
                tipo = "Producto"
              }
              else {
                tipo = "Paquete"
              }

              if (tipo == "Producto") {
                ids = casi[casi.length - 1].split("-")[0]
                if (usedIds.includes(ids)) {
                  console.warn(`Producto repetido: ${titulo} ${ids}`)
                  resp.items.splice(resp.items.indexOf(e), 1);

                }
                usedIds.push(ids)
              }
              else if (tipo == 'Categoría') {
                ids = casi[casi.length - 2]
              }
              else {
                const casi2 = casi[casi.length - 1].split("-")
                ids = casi2[0];
              }

              if (!e.pagemap.metatags[0]['product:price'] || e.pagemap.metatags[0]['product:price'] == '0') {
                // CODIGO AQUI CUANDO NO TENGA PRECIO;

                if (tipo !== "Categoría") {
                  this.apiProductos.checaPrecioGoogle(ids).subscribe(async preciosos => {
                    this.preciosos = preciosos
                    // console.log(this.preciosos);

                    //Asignasion de precio; QUITAR EN PRODUCCION
                    e.pagemap.metatags[0]['product:price'] = this.preciosos
                    e.pagemap.metatags[0]['product:priceD'] = this.preciosos

                    let id = { "descuento": ids }
                    const response = await this.apiProductos.getDescuento(id).toPromise()
                    this.descuento = response;


                    if (this.descuento < 1) {
                      e.pagemap.metatags[0]['product:descuento'] = (this.descuento)
                    }

                    //Asignasion de precio; QUITAR EN PRODUCCION
                    // e.pagemap.metatags[0]['product:price'] = this.preciosos
                  })
                }

              }
              if (true) {
                //CODIGO DE IMAGEN EQUIBOCADA AQUI

                // console.log(`Paquete con imagen mal: ${titulo} ${ids}`)

                if (tipo != 'Categoría') {
                  this.apiProductos.getDescripcionPaquete(ids).subscribe(r => {
                    if (r && r.length > 0 && r[0].image_link) {
                      e.pagemap.cse_image[0].src = r[0].image_link;
                    }
                  })
                }
                else if (tipo == "Categoría") {
                  //TODO: Fix: categoria
                  this.apiProductos.getCategorias(ids).subscribe(r => {
                    if (r && r.length > 0 && r[0].image_link) {
                      e.pagemap.cse_image[0].src = r[0].image_link;
                    } else {
                      console.warn(`No se encontró image_link para la categoría con id: ${ids}`);
                    }
                  });
                }
              }
            }
            // Ordenar los resultados según la coincidencia de la frase en el nombre del producto, la posición de la frase y el descuento
            resp.items.sort((a, b) => {
              const queryLower = query.toLowerCase();

              const aTitle = a.title.toLowerCase();
              const bTitle = b.title.toLowerCase();

              const aIndex = aTitle.indexOf(queryLower);
              const bIndex = bTitle.indexOf(queryLower);

              if (aIndex === -1 && bIndex === -1) {
                return 0;
              } else if (aIndex === -1) {
                return 1;
              } else if (bIndex === -1) {
                return -1;
              } else if (aIndex !== bIndex) {
                // Priorizar resultados con la palabra clave más cerca del principio
                return aIndex - bIndex;
              } else {
                const aExactMatch = aTitle === queryLower ? 1 : 0;
                const bExactMatch = bTitle === queryLower ? 1 : 0;

                if (aExactMatch !== bExactMatch) {
                  return bExactMatch - aExactMatch;
                } else {
                  const aHasDiscount = a.pagemap.metatags[0]['product:descuento'] ? 1 : 0;
                  const bHasDiscount = b.pagemap.metatags[0]['product:descuento'] ? 1 : 0;

                  if (aHasDiscount !== bHasDiscount) {
                    return bHasDiscount - aHasDiscount;
                  } else {
                    // Priorizar los productos que contienen "resina" al principio del título
                    const aStartsWithQuery = aTitle.startsWith(queryLower) ? 1 : 0;
                    const bStartsWithQuery = bTitle.startsWith(queryLower) ? 1 : 0;

                    if (aStartsWithQuery !== bStartsWithQuery) {
                      return bStartsWithQuery - aStartsWithQuery;
                    }

                    // Si ambos comienzan con la palabra clave, compara con los otros criterios
                    const preferenceOrder = ['cricut', 'silhouette', 'lideart'];
                    const aPreference = preferenceOrder.findIndex(keyword => aTitle.includes(keyword));
                    const bPreference = preferenceOrder.findIndex(keyword => bTitle.includes(keyword));

                    if (aPreference === -1 && bPreference === -1) {
                      return 0;
                    } else if (aPreference === -1) {
                      return 1;
                    } else if (bPreference === -1) {
                      return -1;
                    } else {
                      return aPreference - bPreference;
                    }
                  }
                }
              }
            });

          }
        }
        return resp;
      }),
      catchError(this.handleError<searchResult>('getData', DEFAULT_RESPONSE_GOOGLE)),
      finalize(() => this.loadingBool.set(false))
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    this.totalResultados = 0;
    return (error: HttpErrorResponse): Observable<T> => {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Error del lado del cliente
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // Error del lado del servidor
        errorMessage = `Código de error: ${error.status}\nMensaje: ${error.message}`;
      }
      // console.error(`${operation} falló: ${errorMessage}`);
      // Retorna un resultado vacío o por defecto para mantener la aplicación funcionando
      return of(result as T);
    };
  }

  isLoading() {
    return this.loadingBool()
  }

  getProductsFromDb(sku: string): Observable<Item[]> {
    return this.http.get<searchResultSelf[]>(`https://new.lideart.com.mx/Automatizacion/apis/buscarSKU.php?partial_reference=${sku}`).pipe(
      map(r => r.map(e => {
        //Esto sirve para definir la ruta previa al id del producto. Su funcion principal es identificar si es un producto normal o paquete
        //TODO: Este preurl, puede que en un futuro se le adicioone la categoria para mejorar SEO
        const preUrl = isNaN(Number(e.reference.charAt(0))) ? 'paquetes' : 'producto'; ``; //? Se verifica que el primer caracter sea un numero

        return ({
          kind: "customsearch#result",
          title: e.name,
          htmlTitle: e.name, // Assuming htmlTitle is the same as title
          link: `//${preUrl}/${e.id_product}-${e.link_rewrite}`,
          displayLink: "lideart.com.mx",
          snippet: e.reference,
          htmlSnippet: e.reference, // Assuming htmlSnippet is the same as snippet
          formattedUrl: `https://www.lideart.com.mx/${preUrl}/${e.id_product}-${e.link_rewrite}`,
          htmlFormattedUrl: `https://www.lideart.com.mx/${preUrl}/${e.id_product}-${e.link_rewrite}`,
          pagemap: {
            cse_thumbnail: [
              {
                src: this.idToImageUrl(e.id_image),
                width: "225",
                height: "225"
              }
            ],
            metatags: [
              {
                og_image: this.idToImageUrl(e.id_image),
                viewport: "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
                product_price: e.price ? e.price : '0'
              }
            ],
            cse_image: [
              {
                src: this.idToImageUrl(e.id_image)
              }
            ]
          }
        })
      })),
    );
  }

  private idToImageUrl(input: string): string {
    const BASE_URL = 'https://new.lideart.com.mx/img/p';

    // Dividir la cadena en caracteres individuales
    const splitString = input.split('').join('/');

    // Concatenar la cadena original con ".jpg"
    const result = `${BASE_URL}/${splitString}/${input}.jpg`;

    return result;
  }

}

interface searchResultSelf {
  id_product: string
  reference: string
  name: string
  link_rewrite: string
  id_image: string
  price?: string
}


// Hardcode respuesta de busquedas que nos pidan;
// Devuelve productos especificos para una palabra (en la pagina 1),  el producto se puede sacar del console log
const miMapa = new Map<string[], Item[]>([
  //Coduigo de ejemplo para forzar resultados; comentado por si algun dia es necesario volver a usarlo

  // [['plumi', 'silhouette', 'curio', '4420-1854'], [{
  //   "kind": "customsearch#result",
  //   "title": "PORTA PLUMILLA SILHOUETTE CURIO2 Y CAMEO 5 | 4420-1854",
  //   "htmlTitle": "PORTA PLUMILLA SILHOUETTE CURIO2 Y CAMEO 5 <b>Venture</b> Smart | 4420-1854",
  //   "link": "//producto/7166-porta-plumilla-silhouette-curio2-y-cameo-5-pen-holder-cmb2",
  //   "displayLink": "lideart.com.mx",
  //   "snippet": "Conoce Cricut Venture, nuestra máquina de corte profesional de gran formato. Corta con precisión más de 100 materiales hasta 22.8 m (75 pies) y funciona a ...",
  //   "htmlSnippet": "Conoce Cricut <b>Venture</b>, nuestra máquina de corte profesional de gran formato. Corta con precisión más de 100 materiales hasta 22.8 m (75 pies) y funciona a&nbsp;...",
  //   "formattedUrl": "https://www.lideart.com.mx/producto/7166-porta-plumilla-silhouette-curio2-y-cameo-5-pen-holder-cmb2",
  //   "htmlFormattedUrl": "https://www.lideart.com.mx/producto/7166-porta-plumilla-silhouette-curio2-y-cameo-5-pen-holder-cmb2",
  //   "pagemap": {
  //     "cse_thumbnail": [
  //       {
  //         "src": "https://new.lideart.com.mx/img/p/3/9/4/1/7/39417.jpg",
  //         "width": "225",
  //         "height": "225"
  //       }
  //     ],
  //     "metatags": [
  //       {
  //         "og:image": "https://new.lideart.com.mx/img/p/3/9/4/1/7/39417.jpg",
  //         "viewport": "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
  //         "product:price": "290.96"
  //       }
  //     ],
  //     "cse_image": [
  //       {
  //         "src": "https://new.lideart.com.mx/img/p/3/9/4/1/7/39417.jpg"
  //       }
  //     ]
  //   }
  // }]],
])