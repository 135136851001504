import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Usuario } from '../../Models/usuario';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { LoginService } from '../../services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mi-perfil',
  standalone: true,
  imports: [  RouterModule,
    FormsModule,
    CommonModule
  ],
  providers:[LoginService],
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss'],
})
export class MiPerfilComponent  implements OnInit {


  arte=0
  decoracion=0;
  costura=0;
  troquelado=0;
  grabado=0;
  engargolado=0;
  arteResina=0;
  impresion3d=0;
  otroTecnica="";
  bazzil=0;
  brother=0;
  colorPour=0;
  colorPrint=0;
  colortex=0;
  corvus=0;
  creality=0;
  cricut=0;
  dcwv=0;
  doodlebug=0;
  ektools=0;
  foilquill=0;
  forever=0;
  gutermann=0;
  heidi=0;
  hotronix=0;
  kronaline=0;
  luminos=0;
  luster=0;
  minc=0;
  oracal=0;
  sawgrass=0;
  siluet=0;
  siser=0;
  sizzix=0;
  sublimarts=0;
  wer=0;
  otraMarca="";
  bazzil2=0;
  brother2=0;
  colorPour2=0;
  colorPrint2=0;
  colortex2=0;
  corvus2=0;
  creality2=0;
  cricut2=0;
  dcwv2=0;
  doodlebug2=0;
  ektools2=0;
  foilquill2=0;
  forever2=0;
  gutermann2=0;
  heidi2=0;
  hotronix2=0;
  kronaline2=0;
  luminos2=0;
  luster2=0;
  minc2=0;
  oracal2=0;
  sawgrass2=0;
  siluet2=0;
  siser2=0;
  sizzix2=0;
  sublimarts2=0;
  wer2=0;
  otraMarca2="";
  usuario!: Usuario;
  estoycargando =false;
  respuestaPerfil: any=[];
  mostrarprogreso= false
  progresito = 100;
  nohay1 = false;
  nohay2 = false;
  nohay3 = false;


  constructor(@Inject(PLATFORM_ID) private platformId: Object,private usuarioService: LoginService) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
  
      this.usuarioService.usuario.subscribe((res:any) => {
        this.usuario = res;

        this.usuarioService.checaPerfil(this.usuario.usuario).subscribe(checarPerfil =>{
            this.respuestaPerfil = checarPerfil;
            


            if (this.respuestaPerfil != 0) {
              this.mostrarprogreso= true;
              this.arte=this.respuestaPerfil[0].arte;
              this.decoracion=this.respuestaPerfil[0].decoracion;
              this.costura=this.respuestaPerfil[0].costura;
              this.troquelado=this.respuestaPerfil[0].troquelado;
              this.grabado=this.respuestaPerfil[0].grabado;
              this.engargolado=this.respuestaPerfil[0].engargolado;
              this.arteResina=this.respuestaPerfil[0].arteResina;
              this.impresion3d=this.respuestaPerfil[0].impresion3d;
              this.otroTecnica= this.respuestaPerfil[0].otroTecnica
              this.bazzil=this.respuestaPerfil[0].bazzil;
              this.brother=this.respuestaPerfil[0].brother;
              this.colorPour=this.respuestaPerfil[0].colorPour;
              this.colorPrint=this.respuestaPerfil[0].colorPrint;
              this.colortex=this.respuestaPerfil[0].colortex;
              this.corvus=this.respuestaPerfil[0].corvus;
              this.creality=this.respuestaPerfil[0].creality;
              this.cricut=this.respuestaPerfil[0].cricut;
              this.dcwv=this.respuestaPerfil[0].dcwv;
              this.doodlebug=this.respuestaPerfil[0].doodlebug;
              this.ektools=this.respuestaPerfil[0].ektools;
              this.foilquill=this.respuestaPerfil[0].foilquill;
              this.forever=this.respuestaPerfil[0].forever;
              this.gutermann=this.respuestaPerfil[0].gutermann;
              this.heidi=this.respuestaPerfil[0].heidi;
              this.hotronix=this.respuestaPerfil[0].hotronix;
              this.kronaline=this.respuestaPerfil[0].kronaline;
              this.luminos=this.respuestaPerfil[0].luminos;
              this.luster=this.respuestaPerfil[0].luster;
              this.minc=this.respuestaPerfil[0].minc;
              this.oracal=this.respuestaPerfil[0].oracal;
              this.sawgrass=this.respuestaPerfil[0].sawgrass;
              this.siluet=this.respuestaPerfil[0].siluet;
              this.siser=this.respuestaPerfil[0].siser;
              this.sizzix=this.respuestaPerfil[0].sizzix;
              this.sublimarts=this.respuestaPerfil[0].sublimarts;
              this.wer=this.respuestaPerfil[0].wer;
              this.otraMarca= this.respuestaPerfil[0].otraMarca;
              this.bazzil2=this.respuestaPerfil[0].bazzil2;
              this.brother2=this.respuestaPerfil[0].brother2;
              this.colorPour2=this.respuestaPerfil[0].colorPour2;
              this.colorPrint2=this.respuestaPerfil[0].colorPrint2;
              this.colortex2=this.respuestaPerfil[0].colortex2;
              this.corvus2=this.respuestaPerfil[0].corvus2;
              this.creality2=this.respuestaPerfil[0].creality2;
              this.cricut2=this.respuestaPerfil[0].cricut2;
              this.dcwv2=this.respuestaPerfil[0].dcwv2;
              this.doodlebug2=this.respuestaPerfil[0].doodlebug2;
              this.ektools2=this.respuestaPerfil[0].ektools2;
              this.foilquill2=this.respuestaPerfil[0].foilquill2;
              this.forever2=this.respuestaPerfil[0].forever2;
              this.gutermann2=this.respuestaPerfil[0].gutermann2;
              this.heidi2=this.respuestaPerfil[0].heidi2;
              this.hotronix2=this.respuestaPerfil[0].hotronix2;
              this.kronaline2=this.respuestaPerfil[0].kronaline2;
              this.luminos2=this.respuestaPerfil[0].luminos2;
              this.luster2=this.respuestaPerfil[0].luster2;
              this.minc2=this.respuestaPerfil[0].minc2;
              this.oracal2=this.respuestaPerfil[0].oracal2;
              this.sawgrass2=this.respuestaPerfil[0].sawgrass2;
              this.siluet2=this.respuestaPerfil[0].siluet2;
              this.siser2=this.respuestaPerfil[0].siser2;
              this.sizzix2=this.respuestaPerfil[0].sizzix2;
              this.sublimarts2=this.respuestaPerfil[0].sublimarts2;
              this.wer2=this.respuestaPerfil[0].wer2;
              this.otraMarca2= this.respuestaPerfil[0].otraMarca2;

              if (this.arte == 0 && this.decoracion ==0 && this.costura ==0 && this.troquelado ==0 && this.grabado ==0 && this.engargolado ==0 && this.arteResina ==0 && this.impresion3d ==0 && this.otroTecnica =="") {
                this.progresito= this.progresito - 33;
               this.nohay1 = true;
              }
              if(
                this.bazzil == 0 && 
                this.brother == 0 && 
                this.colorPour == 0 && 
                this.colorPrint == 0 && 
                this.colortex == 0 && 
                this.corvus == 0 && 
                this.creality == 0 && 
                this.cricut == 0 && 
                this.dcwv == 0 && 
                this.doodlebug == 0 && 
                this.ektools == 0 && 
                this.foilquill == 0 && 
                this.forever == 0 && 
                this.gutermann == 0 && 
                this.heidi == 0 && 
                this.hotronix == 0 && 
                this.kronaline == 0 && 
                this.luminos == 0 && 
                this.luster == 0 && 
                this.minc == 0 && 
                this.oracal == 0 && 
                this.sawgrass == 0 && 
                this.siluet == 0 && 
                this.siser == 0 && 
                this.sizzix == 0 && 
                this.sublimarts == 0 && 
                this.wer == 0 && 
                this.otraMarca == "")
            {
              this.progresito= this.progresito - 33;
              this.nohay2 = true;
            }

              if (
                this.bazzil2 == 0 && 
                this.brother2 == 0 && 
                this.colorPour2 == 0 && 
                this.colorPrint2 == 0 && 
                this.colortex2 == 0 && 
                this.corvus2 == 0 && 
                this.creality2 == 0 && 
                this.cricut2 == 0 && 
                this.dcwv2 == 0 && 
                this.doodlebug2 == 0 && 
                this.ektools2 == 0 && 
                this.foilquill2 == 0 && 
                this.forever2 == 0 && 
                this.gutermann2 == 0 && 
                this.heidi2 == 0 && 
                this.hotronix2 == 0 && 
                this.kronaline2 == 0 && 
                this.luminos2 == 0 && 
                this.luster2 == 0 && 
                this.minc2 == 0 && 
                this.oracal2 == 0 && 
                this.sawgrass2 == 0 && 
                this.siluet2 == 0 && 
                this.siser2 == 0 && 
                this.sizzix2 == 0 && 
                this.sublimarts2 == 0 && 
                this.wer2 == 0 && 
                this.otraMarca2 == ""
                
              ) {
                this.progresito= this.progresito - 33;
                this.nohay3 = true;
              }
              if (this.nohay1 == true && this.nohay2 == true && this.nohay3 == true) {
                this.progresito = 0
              }
          }else{
            this.progresito =0;
          }

        })

      });
     
 

    }


  }

  arteCheck(){
    
    if(this.arte==1){
      this.arte = 0;
    }else{
      this.arte = 1;
    }
  }

  decoracionCheck(){
    if(this.decoracion ==1){
      this.decoracion = 0;
    }else{
      this.decoracion = 1;
    }
  }

  costuraCheck(){
    
    if(this.costura ==1){
      this.costura = 0;
    }else{
      this.costura = 1;
    }
  }
  troqueladocheck(){
    
    if(this.troquelado==1){
      this.troquelado = 0;
    }else{
      this.troquelado = 1;
    }
  }
  grabadocheck(){
    
    if(this.grabado ==1){
      this.grabado = 0;
    }else{
      this.grabado = 1;
    }
  }
  engargoladocheck(){
    
    if(this.engargolado==1){
      this.engargolado = 0;
    }else{
      this.engargolado = 1;
    }
  }
  arteResinacheck(){
    
    if(this.arteResina==1){
      this.arteResina = 0;
    }else{
      this.arteResina = 1;
    }
  }


  impresion3dcheck(){
    
    if(this.impresion3d==1){
      this.impresion3d = 0;
    }else{
      this.impresion3d = 1;
    }
  }


  bazzilcheck(){
    
    if(this.bazzil==1){
      this.bazzil = 0;
    }else{
      this.bazzil = 1;
    }
  }
  brothercheck(){
    
    if(this.brother==1){
      this.brother = 0;
    }else{
      this.brother = 1;
    }
  }
  colorPourcheck(){
    
    if(this.colorPour==1){
      this.colorPour = 0;
    }else{
      this.colorPour = 1;
    }
  }
  colorPrintcheck(){
    
    if(this.colorPrint==1){
      this.colorPrint = 0;
    }else{
      this.colorPrint = 1;
    }
  }
  colortexcheck(){
    
    if(this.colortex==1){
      this.colortex = 0;
    }else{
      this.colortex = 1;
    }
  }
  corvuscheck(){
    
    if(this.corvus==1){
      this.corvus = 0;
    }else{
      this.corvus = 1;
    }
  }
  crealitycheck(){
    
    if(this.creality==1){
      this.creality = 0;
    }else{
      this.creality = 1;
    }
  }  
  cricutcheck(){
    
    if(this.cricut==1){
      this.cricut = 0;
    }else{
      this.cricut = 1;
    }
  }  
  dcwvcheck(){
    
    if(this.dcwv==1){
      this.dcwv = 0;
    }else{
      this.dcwv = 1;
    }
  }  
  
  doodlebugcheck(){
    
    if(this.doodlebug==1){
      this.doodlebug = 0;
    }else{
      this.doodlebug = 1;
    }
  }  
  
  ektoolscheck(){
    
    if(this.ektools==1){
      this.ektools = 0;
    }else{
      this.ektools = 1;
    }
  }  
  
  foilquillcheck(){
    
    if(this.foilquill==1){
      this.foilquill = 0;
    }else{
      this.foilquill = 1;
    }
  }  
  
  forevercheck(){
    
    if(this.forever==1){
      this.forever = 0;
    }else{
      this.forever = 1;
    }
  }  
  
  gutermanncheck(){
    
    if(this.gutermann==1){
      this.gutermann = 0;
    }else{
      this.gutermann = 1;
    }
  }  
  
  heidicheck(){
    
    if(this.heidi==1){
      this.heidi = 0;
    }else{
      this.heidi = 1;
    }
  }  
  
  hotronixcheck(){
    
    if(this.hotronix==1){
      this.hotronix = 0;
    }else{
      this.hotronix = 1;
    }
  }  
  
  kronalinecheck(){
    
    if(this.kronaline==1){
      this.kronaline = 0;
    }else{
      this.kronaline = 1;
    }
  }  
  
  luminoscheck(){
    
    if(this.luminos==1){
      this.luminos = 0;
    }else{
      this.luminos = 1;
    }
  }

  lustercheck(){
    
    if(this.luster==1){
      this.luster = 0;
    }else{
      this.luster = 1;
    }
  }
  minccheck(){
    
    if(this.minc==1){
      this.minc = 0;
    }else{
      this.minc = 1;
    }
  }
  oracalcheck(){
    
    if(this.oracal==1){
      this.oracal = 0;
    }else{
      this.oracal = 1;
    }
  }
  sawgrasscheck(){
    
    if(this.sawgrass==1){
      this.sawgrass = 0;
    }else{
      this.sawgrass = 1;
    }
  }
  siluetcheck(){
    
    if(this.siluet==1){
      this.siluet = 0;
    }else{
      this.siluet = 1;
    }
  }
  sisercheck(){
    
    if(this.siser==1){
      this.siser = 0;
    }else{
      this.siser = 1;
    }
  }
  sizzixcheck(){
    
    if(this.sizzix==1){
      this.sizzix = 0;
    }else{
      this.sizzix = 1;
    }
  }
  sublimartscheck(){
    
    if(this.sublimarts==1){
      this.sublimarts = 0;
    }else{
      this.sublimarts = 1;
    }
  }
  wercheck(){
    
    if(this.wer==1){
      this.wer = 0;
    }else{
      this.wer = 1;
    }
  }

  bazzilcheck2(){
    
    if(this.bazzil2==1){
      this.bazzil2 = 0;
    }else{
      this.bazzil2 = 1;
    }
  }
  brothercheck2(){
    
    if(this.brother2==1){
      this.brother2 = 0;
    }else{
      this.brother2 = 1;
    }
  }
  colorPourcheck2(){
    
    if(this.colorPour2==1){
      this.colorPour2 = 0;
    }else{
      this.colorPour2 = 1;
    }
  }
  colorPrintcheck2(){
    
    if(this.colorPrint2==1){
      this.colorPrint2 = 0;
    }else{
      this.colorPrint2 = 1;
    }
  }
  colortexcheck2(){
    
    if(this.colortex2==1){
      this.colortex2= 0;
    }else{
      this.colortex2 =1;
    }
  }
  corvuscheck2(){
    
    if(this.corvus2==1){
      this.corvus2 = 0;
    }else{
      this.corvus2 = 1;
    }
  }
  crealitycheck2(){
    
    if(this.creality2==1){
      this.creality2= 0;
    }else{
      this.creality2 =1;
    }
  }  
  cricutcheck2(){
    
    if(this.cricut2==1){
      this.cricut2 = 0;
    }else{
      this.cricut2 = 1;
    }
  }  
  dcwvcheck2(){
    
    if(this.dcwv2==1){
      this.dcwv2 = 0;
    }else{
      this.dcwv2 = 1;
    }
  }  
  
  doodlebugcheck2(){
    
    if(this.doodlebug2==1){
      this.doodlebug2 = 0;
    }else{
      this.doodlebug2 = 1;
    }
  }  
  
  ektoolscheck2(){
    
    if(this.ektools2==1){
      this.ektools2 = 0;
    }else{
      this.ektools2 = 1;
    }
  }  
  
  foilquillcheck2(){
    
    if(this.foilquill2==1){
      this.foilquill2 = 0;
    }else{
      this.foilquill2 = 1;
    }
  }  
  
  forevercheck2(){
    
    if(this.forever2==1){
      this.forever2 = 0;
    }else{
      this.forever2 = 1;
    }
  }  
  
  gutermanncheck2(){
    
    if(this.gutermann2==1){
      this.gutermann2 = 0;
    }else{
      this.gutermann2 = 1;
    }
  }  
  
  heidicheck2(){
    
    if(this.heidi2==1){
      this.heidi2 = 0;
    }else{
      this.heidi2 = 1;
    }
  }  
  
  hotronixcheck2(){
    
    if(this.hotronix2==1){
      this.hotronix2 = 0;
    }else{
      this.hotronix2 = 1;
    }
  }  
  
  kronalinecheck2(){
    
    if(this.kronaline2==1){
      this.kronaline2 = 0;
    }else{
      this.kronaline2 = 1;
    }
  }  
  
  luminoscheck2(){
    
    if(this.luminos2==1){
      this.luminos2 = 0;
    }else{
      this.luminos2 = 1;
    }
  }

  lustercheck2(){
    
    if(this.luster2==1){
      this.luster2 = 0;
    }else{
      this.luster2 = 1;
    }
  }
  minccheck2(){
    
    if(this.minc2==1){
      this.minc2 = 0;
    }else{
      this.minc2 = 1;
    }
  }
  oracalcheck2(){
    
    if(this.oracal2==1){
      this.oracal2 = 0;
    }else{
      this.oracal2 = 1;
    }
  }
  sawgrasscheck2(){
    
    if(this.sawgrass2==1){
      this.sawgrass2 = 0;
    }else{
      this.sawgrass2 = 1;
    }
  }
  siluetcheck2(){
    
    if(this.siluet2==1){
      this.siluet2 = 0;
    }else{
      this.siluet2 = 1;
    }
  }
  sisercheck2(){
    
    if(this.siser2==1){
      this.siser2 = 0;
    }else{
      this.siser2 = 1;
    }
  }
  sizzixcheck2(){
    
    if(this.sizzix2==1){
      this.sizzix2 = 0;
    }else{
      this.sizzix2 = 1;
    }
  }
  sublimartscheck2(){
    
    if(this.sublimarts2==1){
      this.sublimarts2 = 0;
    }else{
      this.sublimarts2 = 1;
    }
  }
  wercheck2(){
    
    if(this.wer2==1){
      this.wer2 = 0;
    }else{
      this.wer2 = 1;
    }
  }

  mandame(){
      var jsonPerfil = 
      {
"correo":  this.usuario.usuario,
"arte" : this.arte,
"decoracion" : this.decoracion,
"costura" : this.costura,
"troquelado" : this.troquelado,
"grabado" : this.grabado,
"engargolado" : this.engargolado,
"arteResina" : this.arteResina,
"impresion3d" : this.impresion3d,
"otroTecnica" : this.otroTecnica,
"bazzil" : this.bazzil,
"brother" : this.brother,
"colorPour" : this.colorPour,
"colorPrint" : this.colorPrint,
"colortex" : this.colortex,
"corvus" : this.corvus,
"creality" : this.creality,
"cricut" : this.cricut,
"dcwv" : this.dcwv,
"doodlebug" : this.doodlebug,
"ektools" : this.ektools,
"foilquill" : this.foilquill,
"forever" : this.forever,
"gutermann" : this.gutermann,
"heidi" : this.heidi,
"hotronix" : this.hotronix,
"kronaline" : this.kronaline,
"luminos" : this.luminos,
"luster" : this.luster,
"minc" : this.minc,
"oracal" : this.oracal,
"sawgrass" : this.sawgrass,
"siluet" : this.siluet,
"siser" : this.siser,
"sizzix" : this.sizzix,
"sublimarts" : this.sublimarts,
"wer" : this.wer,
"otraMarca" : this.otraMarca,
"bazzil2" : this.bazzil2,
"brother2" : this.brother2,
"colorPour2" : this.colorPour2,
"colorPrint2" : this.colorPrint2,
"colortex2" : this.colortex2,
"corvus2" : this.corvus2,
"creality2" : this.creality2,
"cricut2" : this.cricut2,
"dcwv2" : this.dcwv2,
"doodlebug2" : this.doodlebug2,
"ektools2" : this.ektools2,
"foilquill2" : this.foilquill2,
"forever2" : this.forever2,
"gutermann2" : this.gutermann2,
"heidi2" : this.heidi2,
"hotronix2" : this.hotronix2,
"kronaline2" : this.kronaline2,
"luminos2" : this.luminos2,
"luster2" : this.luster2,
"minc2" : this.minc2,
"oracal2" : this.oracal2,
"sawgrass2" : this.sawgrass2,
"siluet2" : this.siluet2,
"siser2" : this.siser2,
"sizzix2" : this.sizzix2,
"sublimarts2" : this.sublimarts2,
"wer2" : this.wer2,
"otraMarca2" : this.otraMarca2
      }

      this.usuarioService.mandaPerfilCliente(JSON.stringify(jsonPerfil)).subscribe(perfilazo=>{
        this.sisearmo();

        setTimeout(() => {
          location.reload();
        }, 1500);

      })
  }

  actualizaPerfil(){

this.arte =this.arte;
this.decoracion =this.decoracion;
this.costura =this.costura;
this.troquelado =this.troquelado;
this.grabado =this.grabado;
this.engargolado =this.engargolado;
this.arteResina =this.arteResina;
this.impresion3d =this.impresion3d;
this.otroTecnica =this.otroTecnica;
this.bazzil =this.bazzil;
this.brother =this.brother;
this.colorPour =this.colorPour;
this.colorPrint =this.colorPrint;
this.colortex =this.colortex;
this.corvus =this.corvus;
this.creality =this.creality;
this.cricut =this.cricut;
this.dcwv =this.dcwv;
this.doodlebug =this.doodlebug;
this.ektools =this.ektools;
this.foilquill =this.foilquill;
this.forever =this.forever;
this.gutermann =this.gutermann;
this.heidi =this.heidi;
this.hotronix =this.hotronix;
this.kronaline =this.kronaline;
this.luminos =this.luminos;
this.luster =this.luster;
this.minc =this.minc;
this.oracal =this.oracal;
this.sawgrass =this.sawgrass;
this.siluet =this.siluet;
this.siser =this.siser;
this.sizzix =this.sizzix;
this.sublimarts =this.sublimarts;
this.wer =this.wer;
this.otraMarca =this.otraMarca;
this.bazzil2 =this.bazzil2;
this.brother2 =this.brother2;
this.colorPour2 =this.colorPour2;
this.colorPrint2 =this.colorPrint2;
this.colortex2 =this.colortex2;
this.corvus2 =this.corvus2;
this.creality2 =this.creality2;
this.cricut2 =this.cricut2;
this.dcwv2 =this.dcwv2;
this.doodlebug2 =this.doodlebug2;
this.ektools2 =this.ektools2;
this.foilquill2 =this.foilquill2;
this.forever2 =this.forever2;
this.gutermann2 =this.gutermann2;
this.heidi2 =this.heidi2;
this.hotronix2 =this.hotronix2;
this.kronaline2 =this.kronaline2;
this.luminos2 =this.luminos2;
this.luster2 =this.luster2;
this.minc2 =this.minc2;
this.oracal2 =this.oracal2;
this.sawgrass2 =this.sawgrass2;
this.siluet2 =this.siluet2;
this.siser2 =this.siser2;
this.sizzix2 =this.sizzix2;
this.sublimarts2 =this.sublimarts2;
this.wer2 =this.wer2;
this.otraMarca2 =this.otraMarca2;

    var jsonPerfil = 
    {
"correo":  this.usuario.usuario,
"arte" : this.arte,
"decoracion" : this.decoracion,
"costura" : this.costura,
"troquelado" : this.troquelado,
"grabado" : this.grabado,
"engargolado" : this.engargolado,
"arteResina" : this.arteResina,
"impresion3d" : this.impresion3d,
"otroTecnica" : this.otroTecnica,
"bazzil" : this.bazzil,
"brother" : this.brother,
"colorPour" : this.colorPour,
"colorPrint" : this.colorPrint,
"colortex" : this.colortex,
"corvus" : this.corvus,
"creality" : this.creality,
"cricut" : this.cricut,
"dcwv" : this.dcwv,
"doodlebug" : this.doodlebug,
"ektools" : this.ektools,
"foilquill" : this.foilquill,
"forever" : this.forever,
"gutermann" : this.gutermann,
"heidi" : this.heidi,
"hotronix" : this.hotronix,
"kronaline" : this.kronaline,
"luminos" : this.luminos,
"luster" : this.luster,
"minc" : this.minc,
"oracal" : this.oracal,
"sawgrass" : this.sawgrass,
"siluet" : this.siluet,
"siser" : this.siser,
"sizzix" : this.sizzix,
"sublimarts" : this.sublimarts,
"wer" : this.wer,
"otraMarca" : this.otraMarca,
"bazzil2" : this.bazzil2,
"brother2" : this.brother2,
"colorPour2" : this.colorPour2,
"colorPrint2" : this.colorPrint2,
"colortex2" : this.colortex2,
"corvus2" : this.corvus2,
"creality2" : this.creality2,
"cricut2" : this.cricut2,
"dcwv2" : this.dcwv2,
"doodlebug2" : this.doodlebug2,
"ektools2" : this.ektools2,
"foilquill2" : this.foilquill2,
"forever2" : this.forever2,
"gutermann2" : this.gutermann2,
"heidi2" : this.heidi2,
"hotronix2" : this.hotronix2,
"kronaline2" : this.kronaline2,
"luminos2" : this.luminos2,
"luster2" : this.luster2,
"minc2" : this.minc2,
"oracal2" : this.oracal2,
"sawgrass2" : this.sawgrass2,
"siluet2" : this.siluet2,
"siser2" : this.siser2,
"sizzix2" : this.sizzix2,
"sublimarts2" : this.sublimarts2,
"wer2" : this.wer2,
"otraMarca2" : this.otraMarca2
    }

    this.usuarioService.actualizaPerfil(JSON.stringify(jsonPerfil)).subscribe(perfilazo=>{
      this.sisearmo2();

      setTimeout(() => {
        location.reload();
      }, 1500);

    })

  }

  async sisearmo() {
    Swal.fire({
      icon: "success",
      title: "¡Datos de Perfil Guardados!",
      showConfirmButton: false,
      timer: 2000
    });
  }

  async sisearmo2() {
    Swal.fire({
      icon: "success",
      title: "¡Datos de Perfil Actualizados!",
      showConfirmButton: false,
      timer: 2000
    });
  }

}
