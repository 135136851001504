import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';

@Component({
  standalone: true,
  imports: [
    CommonModule
  ],
  selector: 'app-articulo-blog',
  templateUrl: './articulo-blog.component.html',
  styleUrls: ['./articulo-blog.component.scss'],
})
export class ArticuloBlogComponent  implements OnInit {
  id_post:any;
  blogaso: any = [];
  image:any;
  descripcion:any;

  constructor(
    private blogService: ServiciowishlistService,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      //console.log(params)
      this.id_post = params['id_blog'];
      this.traerArticulo(this.id_post);
    })
  }

  traerArticulo(id_post:any){
    this.blogService.recuperaArticulo(id_post).subscribe((data) => {
      this.blogaso = data;
      this.image = this.blogaso[0].image;
      this.descripcion = this.blogaso[0].description;

      var posicion = this.descripcion.indexOf("<img");
      var posicion2 = posicion + 4;
      this.descripcion = this.descripcion.slice(0, posicion2) + ' class="testas"' + this.descripcion.slice(posicion + 4);

      ////console.log(this.descripcion);
    });
  }
}
