
import { CommonModule, DatePipe, isPlatformBrowser, Location } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
//import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import Swal from 'sweetalert2';

import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { IonModal, ToastController } from '@ionic/angular';
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonRow, IonSkeletonText, IonText } from '@ionic/angular/standalone';
import { Producto } from '../../Models/Productos';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';
import { TiktokEventsService } from '../../services/tiktok-events.service';

declare var fbq: any;

declare var KueskipayAdvertising: any;

@Component({
  selector: 'app-descripcion-producto',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonCardContent,
    IonText,
    IonGrid,
    IonCardTitle,
    IonButton,
    IonInput,
  ],
  templateUrl: './descripcion-producto.component.html',
  styleUrl: './descripcion-producto.component.scss',
  providers: [DatePipe, ServiciowishlistService, ApiProductosService, LoginService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DescripcionProductoComponent {
  selectedImageURL: string = "";


  id_product: any;
  public descripcion: any;
  productoData: any;
  public colorSeleccionado: string = '';
  atributos: any;
  public precioDyn: any;
  public color = 'blanco';
  items: any = [];
  descripcionsita: any;
  categoriaadfdf: any = []
  referenciaChida: any;
  public baseUrl: string = 'https://new.lideart.com.mx/';
  slider: any = [];
  slidervideos: any = [];
  relacionados: any = [];
  sliderOne: any;
  todoslossku: any = []
  prodRelac: any = [];
  jsonExistencia: any = [];
  precioFacebok: any;
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };

  public totalItems: number = 0;
  public crr_sel = 0;
  public crr_indx = 0;
  public cantidad: number = 1;
  public existencias: any;
  usuario!: Usuario | null;
  productoSKU: any;
  fechita = new Date();
  contenidoHTML: any;
  nuevaFecha: any;
  unidadVenta: any;
  descripcionsilla: any;
  descuento: any;
  fechaInicio: any;
  fechaFin: any;
  fechaActual: any;
  resdesc: any = [];
  descuentillo = false;
  muestroStock = "";
  carritoclick = 0;
  //cargando = false;
  cargoprecio = false;
  email: any;
  id_product_attribute: any;
  // sus : Subscription;
  // urlsafe! : SafeResourceUrl;
  // videoURL = "https://www.you.tube.com/rKOQgNd8_rQ";
  varColorSelected: any;
  cargandoImagenes = 0;
  cargandoPrecio = 0;
  cargandoInventario = 0;
  cargandoProductoInfo = 0;
  parametros: any = [];
  descripcionsillas: any = [];
  productName: any;
  alto: any
  largo: any
  ancho: any
  peso: any
  estoyactivo: any;
  Disponibilidad = "";
  Agotado = "";
  pruebaDescuento: any;
  reviews2: any[] = [];
  public mostrarError: boolean = false;

  public fbqCategory: any;
  public fbqPrecio: any;
  public fbqNombreProducto: any;
  public fbqId: any = ['LIDEARTMX4819'];

  public infoUser: any = [];
  public infoUser2: any = [];

  public imgUrlGoogle: any = '';

  @ViewChild(IonModal) modal!: IonModal;
  @ViewChild('modalDesk') modalDesk!: IonModal;

  loaded = false;
  reviews: any[] = [];
  reviewsOriginal: any = [];
  private elementosPorPagina: number = 10;
  private paginaActual: number = 0;
  URLestrellas: any;
  nombre: any;
  titulo: any;
  comentario: any
  estrellas = 0;
  usuario2!: Usuario;
  datosUsuario: any = [];
  id_customer: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private productoService: ApiProductosService,
    private shoppingCart: CarritoServiceService,
    private servicioWishlist: ServiciowishlistService,
    private usuarioService: LoginService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private router: Router,
    private location: Location,
    private satanizador: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    public toastController: ToastController,
    private renderer: Renderer2,
    private el: ElementRef,
    private apiFacebook: FacebookApiService,
    private checkoutService: CheckoutServiceService,
    private tiktokEvent: TiktokEventsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.sliderOne =
    {
      isBeginningSlide: true,
      isEndSlide: false,
    };
  }

  closeModal() {
    const modal = document.getElementById('modal');
    if (modal) {
      modal.style.display = 'none';
    }
  }

  cancel2() {
    this.modal.dismiss(null, 'cancel');
  }

  cancel2desk() {
    this.modalDesk.dismiss(null, 'cancel');
  }

  checalo() {
    this.usuarioService.checaUsuario(this.usuario2.usuario).subscribe(data => {
      this.datosUsuario = data;
      this.id_customer = this.datosUsuario.id_customer;

    })
  }

  openModalDesk() {
    this.mostrarError = false;
    const modal = document.getElementById('modal');
    if (modal) {
      modal.style.display = 'flex';
    }
  }

  ngOnInit() {
    this.titleService.setTitle("Reviews");

    // this.usuarioService.getReviews().subscribe(res => {
    //   this.reviewsOriginal = res;
    //   this.reviews = this.reviewsOriginal.slice(0, 10);
    //   this.loaded = true;
    //   this.URLestrellas = "https://new.lideart.com.mx/modules/lgcomments/views/img/stars/plain/bluelight/" + this.reviews.stars + "stars.png"
    //   //console.log(this.reviews);
    // });
    this.activatedRoute.params.subscribe(params => {
      //console.log(params);
      this.parametros = params['id_product-:link_rewrite'].split('-');
      this.fbqCategory = params['category'];
      this.id_product = params['id_product-:link_rewrite'].split('-');
      this.id_product = this.id_product[0];

      //console.log(this.id_product);
      this.reviewProducto();
      //this.getDescripcionProducto();
    })
    this.usuarioService.usuario.subscribe((res: Usuario | null) => {
      this.usuario = res;

      if (this.usuario && this.usuario.usuario) {
        this.obtenerDescuentoUsuario();
      } else {
        // Si no hay usuario logeado, aplica el descuento general
        this.obtenerDescuentoGeneral();
      }

      if (this.usuario != null) {
        this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
          this.infoUser = data;
          //console.log(this.infoUser);
          if (this.usuario)
            this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
              this.infoUser2 = res;
              //console.log(this.infoUser2);
            });
        });
      }

    });
    if (isPlatformBrowser(this.platformId)) {
      if (typeof KueskipayAdvertising !== 'undefined') {
        new KueskipayAdvertising().init();
      } else {
        console.error('KueskipayAdvertising no está definida');
      }
    }

    this.productoService.getDescripcionProducto(this.id_product).subscribe((data: any) => {
      try {

        this.productoData = data;

        this.todoslossku = this.productoData[0].detalles.variables
        //  this.existencias = this.productoData[0].existencia;
        this.atributos = this.productoData[0].detalles.atributos;
        //this.fbqPrecio = this.productoData[0].precio;
        this.fbqNombreProducto = this.productoData[0].title;
        this.unidadVenta = this.productoData[0].unitId;

        this.estoyactivo = this.productoData[0].activo;
        this.alto = this.productoData[0].height;
        this.ancho = this.productoData[0].width;
        this.largo = this.productoData[0].depth;
        this.peso = this.productoData[0].weight;


        this.descripcionsita = this.productoData[0].description;
        this.descripcionsilla = this.productoData[0].description_short;




        //  this.contenidoHTML= this.sanitizer.bypassSecurityTrustHtml(this.descripcionsilla.replace(/src="\/(.*?)"/g, 'src="' + this.baseUrl + '$1"'));
        var meta_title = this.productoData[0].meta_title
        var meta_description = this.productoData[0].meta_description
        var keywords = this.productoData[0].tags
        if (this.id_product == 4448) {
          this.titleService.setTitle('Maletín Ligero para Plotter de Corte Silhouette Cameo 4');
        } else if (this.id_product == 4623) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3 (Azul rey)');
        } else if (this.id_product == 4621) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3 (Dorado metálico)');
        } else if (this.id_product == 4340) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3');
        } else if (this.id_product == 4619) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3 (]Negro)');
        } else if (this.id_product == 4620) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3 (Plata metálico)');
        } else if (this.id_product == 4622) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3 (Amarillo)');
        } else if (this.id_product == 4618) {
          this.titleService.setTitle('Hoja Papel de transferencia Forever Flex-Soft (no cut) tamaño Carta y A3 (Blanco)');
        } else if (this.id_product == 4449) {
          this.titleService.setTitle('| Maletín Ligero para Plotter de Corte Silhouette Cameo 4 |');
        } else if (this.id_product == 5476) {
          this.titleService.setTitle('| Paquete viniles de corte Oracal 641 24" |');
        } else if (this.id_product == 4603) {
          this.titleService.setTitle('| Termos de aluminio para sublimación en presentación blanco y plata |');
        } else if (this.id_product == 6933) {
          this.titleService.setTitle('| ROLLO LAMINADOR EN FRÍO TEXTURA 3D KRONALINE 31CMX50MT GROSOR 4.0MM |');
        } else if (this.id_product == 6659) {
          this.titleService.setTitle('| Kit Completo Navajas Cricut Maker |');
        } else if (this.id_product == 6701) {
          this.titleService.setTitle('| DUO Silhouette Cameo 5 Color Blanco + Laminadora Foil |');
        } else if (this.id_product == 5486) {
          this.titleService.setTitle('| Paquete Colección Vintage Heidi Swapp |');
        } else if (this.id_product == 5480) {
          this.titleService.setTitle('| Paquete Vinil de Corte Signcal 30 pies |');
        } else if (this.id_product == 6286) {
          this.titleService.setTitle('| Paquete de Ojilladora Crop-a-Dile Wer 1/8" y 3/16" con surtido de colores de ojillos |');
        } else if (this.id_product == 6352) {
          this.titleService.setTitle('| DUO BLANCO MINC Silhouette Cameo 4 + Laminadora 12" |');
        } else if (this.id_product == 6700) {
          this.titleService.setTitle('| Paquete Hot Stamping con Silhouette Cameo 5 |');
        } else if (this.id_product == 4968) {
          this.titleService.setTitle('| Funda para cojín cuadrado acabado satín y felpa Sublimarts |');
        } else if (this.id_product == 5488) {
          this.titleService.setTitle('| Kit para Arte en Acuarela |');
        } else if (this.id_product == 5091) {
          this.titleService.setTitle('| Rollo de vinil glitter Cricut |');
        } else if (this.id_product == 4690) {
          this.titleService.setTitle('| Funda para cojín cuadrado con lentejuelas reversibles Sublimarts |');
        } else if (this.id_product == 6931) {
          this.titleService.setTitle('| ROLLO LAMINADOR EN FRÍO TEXTURA LINO FINO KRONALINE 31CMX50MT |');
        } else if (this.id_product == 4276) {
          this.titleService.setTitle('| Pie de Vinil Textil Siser Brick |');
        } else if (this.id_product == 6932) {
          this.titleService.setTitle('| ROLLO LAMINADOR EN FRÍO TEXTURA ARENA KRONALINE 31CMX50MT |');
        } else if (this.id_product == 6295) {
          this.titleService.setTitle('| Paquete para encuadernado Cinch disc WeR |');
        } else if (this.id_product == 4537) {
          this.titleService.setTitle('| Rollo de Filamento para Impresora 3D Silhouette Alta |');
        } else if (this.id_product == 4535) {
          this.titleService.setTitle('| Rollo de Filamento para Impresora 3D Silhouette Alta ||');
        } else {
          this.titleService.setTitle(this.productoData[0].title);
        }
        if (meta_description === '' || meta_description === null || meta_description === undefined || meta_description === "undefined") {

          this.metaService.addTag({ name: 'description', content: this.productoData[0].title });
        } else {

          if (this.id_product == 4028) {
            this.metaService.addTag({ name: 'description', content: meta_description + " |" });
          } else if (this.id_product == 4030) {
            this.metaService.addTag({ name: 'description', content: meta_description + " ||" });
          } else if (this.id_product == 4108) {
            this.metaService.addTag({ name: 'description', content: meta_description + " |||" });
          } else if (this.id_product == 6755) {
            this.metaService.addTag({ name: 'description', content: meta_description + " |" });
          } else {
            this.metaService.addTag({ name: 'description', content: meta_description });
          }
        }
        this.metaService.addTag({ name: 'keywords', content: keywords });

        let id_cat = this.productoData[0].id_category;
        this.cargandoProductoInfo = 1;
        var jsondescuento = {
          "descuento": this.id_product
        }
        this.productoService.getDescuento(jsondescuento).subscribe((resdesc: any) => {
          this.resdesc = resdesc;
          //console.log(this.resdesc)
          // try {
          //   if (this.resdesc === 1) {

          //     this.descuentillo = false;
          //     this.descuento = 1;

          //   }else{

          //     this.descuentillo = true;
          //   this.descuento = this.resdesc;
          //   this.descuento = 1- this.descuento
          //   }
          // } catch (error) {

          //   this.descuentillo = true;
          //   this.descuento = this.resdesc;
          //   this.descuento = 1- this.descuento 
          // }


          this.colorDefault()

          /*try {

            if (this.usuario) {
              setTimeout(() => {
                this.apiFacebook.sendEvent('ViewContent', this.precioFacebok.toFixed(2), this.usuario.usuario, this.infoUser2[0].phone, {
                  external_id: this.infoUser.customerID,
                  nombre: this.infoUser2[0].firstname,
                  apellido: this.infoUser2[0].lastname,
                  estado: this.infoUser2[0].id_state,
                  ciudad: this.infoUser2[0].city,
                  country: 'México',
                  codigo_postal: this.infoUser2[0].postcode,
                  num_items: 1,
                  content_type: 'producto',
                  content_name: this.productoData[0].title,
                  content_ids: [this.id_product]
                });
              }, 3000);
            }
 
          } catch (error) {
            
          }*/


          this.productoService.getSliderImgs(this.id_product).subscribe((datazos: any) => {
            this.cargandoImagenes = 1;
            this.slider = datazos;

            this.metaService.addTag({ property: 'og:image', content: this.slider[0].slider });

            this.productoService.getVideos(this.id_product).subscribe((videostruncos: any) => {
              this.slidervideos = videostruncos;

              this.productoService.getCategoriaItem(this.id_product).subscribe((fdsfdfsdfsdf: any) => {
                this.categoriaadfdf = fdsfdfsdfsdf

                let id_category = this.categoriaadfdf[0].id_category


                this.productoService.getRelacionados(id_category).subscribe((productillos: any) => {
                  try {
                    this.relacionados = productillos;
                  } catch (error) {
                    this.relacionados = [];
                  }



                  this.productoService.getProdRelac(this.id_product).subscribe(prodRelac => {
                    this.prodRelac = prodRelac;
                  })


                })

              })
            })

          })
          //this.addFbqScript();
        })
        this.getDescripciones();
      } catch (error) {
        this.cargandoProductoInfo = 1;
        this.cargandoImagenes = 1;
      }
    })

    // if (!localStorage.getItem('foo')) { 
    //   localStorage.setItem('foo', 'no reload') 
    //   location.reload() 
    // } else {
    //   localStorage.removeItem('foo')  
    // }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.nuevaFecha = this.datePipe.transform(this.fechita, "MM-dd-yyyy");


    this.shoppingCart.cartItems.subscribe((d: any) => {
      if (d && Array.isArray(d)) {
        this.totalItems = d.length;
      } else {
        this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
      }
    });



    // this.sus = this.platform.backButton.subscribe(() =>{
    //   this.location.back();
    // });

  }

  navigateToDescripcionProducto(c: any, b: any, a: any, d: any, e: any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    const paquete = d; // Set the link_rewrite dynamically
    const sku = e; // Set the link_rewrite dynamically
    if (d == 1) {
      this.router.navigateByUrl(`/paquetes/${id_product}-${sku}` + ".html");
    } else {
      this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}` + ".html");
    }
  }

  getDescripciones() {
    this.productoService.getDescripciones(this.id_product).subscribe(data => {
      this.descripcionsillas = data;
      try {
        this.descripcionsita = this.descripcionsillas[0].description;
        this.descripcionsilla = this.descripcionsillas[0].description_short;


        this.contenidoHTML = this.sanitizer.bypassSecurityTrustHtml(this.descripcionsita.replace(/src="\/(.*?)"/g, 'src="' + this.baseUrl + '$1"'));
      } catch (error) {
        this.descripcionsita = "";
        this.descripcionsilla = "";
      }
    })
  }
  async presentToast() {
    /*const toast = await this.toastController.create({
      message: 'Articulo Agregado a Carrito',
      color: "success",
      position: "top",
      duration: 1500
      
    });
    toast.present();*/
    Swal.fire({
      icon: "success",
      title: "Articulo Agregado a Carrito",
      showConfirmButton: false,
      timer: 1500
    });

  }

  goBack() {
    this.location.back();
  }


  increment() {
    if (this.cantidad > this.existencias) {
      this.ShowError2;
    } else {
      this.cantidad++;
    }
  }

  decrement() {
    if (this.cantidad <= 1) {
      this.ShowError7()
    } else {
      this.cantidad--;
    }
  }


  getDescripcionProducto() {


  }
  getSafeYoutubeUrl(url: string): SafeResourceUrl {
    return this.satanizador.bypassSecurityTrustResourceUrl(url);
  }

  //Move to Next slide
  slideNext(object: any, slideView: any) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  //Move to previous slide
  slidePrev(object: any, slideView: any) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  //Method called when slide is changed by drag or navigation
  SlideDidChange(object: any, slideView: any) {
    this.checkIfNavDisabled(object, slideView);
  }

  //Call methods to check if slide is first or last to enable disbale navigation  
  checkIfNavDisabled(object: any, slideView: any) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object: any, slideView: any) {
    slideView.isBeginning().then((istrue: any) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object: any, slideView: any) {
    slideView.isEnd().then((istrue: any) => {
      object.isEndSlide = istrue;
    });
  }


  changeSelectedImage(newImageURL: string) {
    //console.log('Image clicked:', newImageURL, 'Selected image: ',this.selectedImageURL);

    this.selectedImageURL = newImageURL;
    //console.log('Selected image: ',this.selectedImageURL);
  }

  // changeSelectedImage(index: number) {

  //   if (this.slidprincipal && this.slidprincipal.swiper) {
  //     this.slidprincipal.swiper.slideTo(index); // Slide to the selected image
  //   }
  // }


  agregarAlCarrito(producto: Producto) {


    if (this.carritoclick == 1) {
      // producto.precio = this.productoData.precio;
      producto.existencia = this.existencias;
      producto.color = this.color.replace(/["'\\/\n]/g, "");;
      producto.alto = this.alto
      producto.ancho = this.ancho
      producto.largo = this.largo
      producto.peso = this.peso,
        producto.jsonExistencias = this.jsonExistencia;
      producto.link_rewrite = this.productoData[0].link_rewrite;
      producto.category_rewrite = this.fbqCategory;

      if (producto.color == "") {

        this.ShowError2();

      } else {

        producto.quantity = this.cantidad;
        if (this.existencias < this.cantidad) {

          this.ShowError();

        } else {
          let pasa = true;
          this.shoppingCart.cartItems.subscribe((d: any) => {
            if (d && Array.isArray(d)) {
              this.items = d;
              this.totalItems = d.length;
            } else {
              this.items = [];
              this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
            }
          });

          if (this.totalItems > 0) {
            if (this.items && Array.isArray(this.items)) {
              for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];
                if (producto.sku == element.sku) {
                  if (this.existencias < (element.quantity + producto.quantity)) {
                    this.ShowError3();
                    pasa = false;
                  }
                }
              }
            } else {
              console.warn('items está indefinido o no es un array');
            }
          }
          if (pasa) {
            this.showSuccess();
            if (this.descuento == 0) {
              if (this.usuario) {
                this.email = this.usuario.usuario
              } else {
                this.email = "";
              }
              if (this.totalItems < 1) {

                let datos = {
                  "email": this.email
                }
                this.shoppingCart.registraNuevoCarrito(datos).subscribe((data: any) => {
                })


              }

              producto.precio = this.precioDyn;
              producto.title = this.productName
              producto.nombreMuestra = this.fbqNombreProducto;
              this.shoppingCart.addItem(producto);
              const precio: number = parseFloat(Number(this.precioFacebok).toFixed(2))
              if (this.usuario) {

                alert(precio)
                this.apiFacebook.sendEvent('AddToCart', precio as unknown as string, this.usuario.usuario, this.infoUser2[0].phone, {
                  external_id: this.infoUser.customerID,
                  nombre: this.infoUser2[0].firstname,
                  apellido: this.infoUser2[0].lastname,
                  estado: this.infoUser2[0].id_state,
                  ciudad: this.infoUser2[0].city,
                  country: 'México',
                  codigo_postal: this.infoUser2[0].postcode
                })
              }
            }
            if (this.descuento > 0) {
              if (this.usuario) {
                this.email = this.usuario.usuario
              } else {
                this.email = "";
              }
              if (this.totalItems < 1) {

                let datos = {
                  "email": this.email
                }
                this.shoppingCart.registraNuevoCarrito(datos).subscribe((data: any) => {
                })

              }
              producto.precio = this.precioDyn * this.descuento
              producto.title = this.productName
              producto.nombreMuestra = this.fbqNombreProducto;
              this.shoppingCart.addItem(producto);
              const precio: number = parseFloat(Number(this.precioFacebok * this.descuento).toFixed(2))
              if (this.usuario) {
                this.apiFacebook.sendEvent('AddToCart', (this.precioFacebok * this.descuento).toFixed(2), this.usuario.usuario, this.infoUser2[0].phone, {
                  external_id: this.infoUser.customerID,
                  nombre: this.infoUser2[0].firstname,
                  apellido: this.infoUser2[0].lastname,
                  estado: this.infoUser2[0].id_state,
                  ciudad: this.infoUser2[0].city,
                  country: 'México',
                  codigo_postal: this.infoUser2[0].postcode
                });
              }
            }

          }
        }

      }
    } else {
      this.ShowError2();
    }

  }

  seleccionacolor(nombreColor: any, id_attribute: number, id_group: number, colvar: any) {
    this.varColorSelected = colvar;
    this.colorSeleccionado = nombreColor;
    this.carritoclick = 1;
    if (document.getElementsByClassName('selected selector-color').length > 0) {
      document.getElementsByClassName('selected selector-color')[0].className =
        document
          .getElementsByClassName('selected selector-color')[0]
          .className.replace('selected', '');
    }
    var cuadro = document.getElementById('' + id_attribute);
    if (cuadro) cuadro.className = 'selected ' + cuadro.className;

    this.cambiaSku();
    this.color = document.getElementsByClassName(
      'selected selector-color'
    )[0].className;
    let colorsito = document.getElementsByClassName('selected');
    if (colorsito.length > 0) {
      this.color = '' + colorsito[0].children[0].getAttribute('title');
    }
  }
  cambiaSku() {
    try {
      var ladata = this.productoData[0];
      var variables = ladata.detalles.variables;
      if (document.getElementsByClassName('selected selector-color').length > 0) {
        var selecciones = [];
        selecciones.push({
          id_group: document
            .getElementsByClassName('selected selector-color')[0]
            .className.split('id_group_')[1]
            .split(' ')[0],
          id_attribute: document.getElementsByClassName(
            'selected selector-color'
          )[0].id,
        });
        if (document.getElementsByName('atributos').length > 0) {
          var selectores = document.getElementsByName('atributos');
          for (var i = 0; i < selectores.length; i++) {
            var sl = selectores[i];
            selecciones.push({
              id_group: sl.classList[0],
              id_attribute: (<HTMLInputElement>sl).value,
            });
          }
        }
        var pasa = true;

        for (var i = 0; i < variables.length; i++) {
          pasa = false
          var nkeys = Object.keys(variables[i].atributos)
          var aciertos = 0
          var nvalor = 0

          for (var j = 0; j < nkeys.length; j++) {
            for (var k = 0; k < selecciones.length; k++) {
              if (selecciones[k].id_group == nkeys[j]) {
                if (variables[i].atributos[selecciones[k].id_group] == selecciones[k].id_attribute) {
                  aciertos++
                }
              }
            }
          }
          if (aciertos == nkeys.length) {

            pasa = true
            nvalor = i
            i = variables.length + 10
          }

          if (pasa) {


            ladata.sku = variables[nvalor].reference;


            var lrf = document.getElementById('referencia');
            var uv = document.getElementById('unidadVenta');
            this.id_product_attribute = variables[nvalor].id_product_attribute

            this.productoService.getSliderImgsxColor(this.id_product_attribute).subscribe((datazos: any) => {
              this.slider = datazos;
              if (this.slider == null || this.slider == "") {
                this.productoService.getSliderImgs(this.id_product).subscribe((datazos: any) => {
                  this.slider = datazos;
                });
              }
            });

            if (lrf) {
              lrf.innerText = variables[nvalor].reference.replace(/-R$/, '');
              var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
              referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
              this.referenciaChida = referenciaLimpia;

              //Redirigir en caso que se detecte un SKU de paquete
              if (this.referenciaChida !== undefined) {
                const regex = /^[A-Za-z]/;
                if (regex.test(this.referenciaChida)) {
                  document.location = `/paquetes/${this.id_product}`
                }
              }

              this.getExistencias(referenciaLimpia, 'CHIH');
              this.getPrecioUnitario(referenciaLimpia, this.unidadVenta);
              this.getExistencias2(referenciaLimpia);


              /*console.log(this.precioDyn);
              console.log(this.productoData[0].title);
              const script = this.renderer.createElement('script');     
              script.type = 'application/ld+json';     
              script.text = JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                "name":this.productoData[0].title,
                "offers": {
                  "@type": "Offer",
                  "priceCurrency": "MXN",
                  "price": this.precioDyn
                }
              }); 
              this.renderer.appendChild(document.head, script);*/



              // if(uv)
              // {
              //   uv.innerText = variables[nvalor].unidadVenta.replace(/-R$/, '');
              //   var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              //   unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];


              // }
            }
            i = variables.length + 100;
            this.crr_sel = 0;
            this.crr_indx = 0;
          }
        }
        if (!pasa) {
          if (
            this.crr_sel <=
            document.getElementsByName('atributos').length - 1
          ) {
            var elselect = document.getElementsByName('atributos')[this.crr_sel];
            if (
              (<HTMLSelectElement>elselect).options.length >
              this.crr_indx + 1
            ) {
              (<HTMLSelectElement>elselect).selectedIndex = this.crr_indx;
              this.crr_indx++;
            } else {
              this.crr_sel++;
              this.crr_indx = 0;
            }
            this.cambiaSku();
            var lrf = document.getElementById('referencia');

            var uv = document.getElementById('unidadVenta');
            this.id_product_attribute = variables[this.crr_indx].id_product_attribute

            this.productoService.getSliderImgsxColor(this.id_product_attribute).subscribe((datazos: any) => {
              this.slider = datazos;
              if (this.slider == null || this.slider == "") {
                this.productoService.getSliderImgs(this.id_product).subscribe((datazos: any) => {
                  this.slider = datazos;
                });
              }
            });
            if (lrf) {
              lrf.innerText = variables[i].reference.replace(/-R$/, '');
              var referenciaLimpia = lrf.outerHTML.replace(/-R$/, '');
              referenciaLimpia = referenciaLimpia.split(/>/)[1].split(/</)[0];
              this.referenciaChida = referenciaLimpia;

              this.getExistencias(referenciaLimpia, 'CHIH');
              this.getPrecioUnitario(referenciaLimpia, this.unidadVenta);
              this.getExistencias2(referenciaLimpia);


              // if(uv)
              // {
              //   uv.innerText = variables[i].unidadVenta.replace(/-R$/, '');
              //   var unidadventLimpia = uv.outerHTML.replace(/-R$/, '');
              //   unidadventLimpia = unidadventLimpia.split(/>/)[1].split(/</)[0];

              // this.getPrecioUnitario(referenciaLimpia,unidadventLimpia);
              // }
            }


          } else {
            this.crr_sel = 0;
            this.crr_indx = 0;
          }
        }
      }
    } catch (error) {

    }

  }

  colorDefault() {
    setTimeout(() => {



      try {
        let span = document.getElementsByClassName('id_group_2 selector-color')[0].children[0] as HTMLElement;
        if (span) {
          span.click();
        }
      } catch (error) {
        this.getExistencias(this.productoData[0].sku, 'CHIH');
        this.getPrecioUnitario(this.productoData[0].sku, this.unidadVenta);
        this.getExistencias2(this.productoData[0].sku);
      }

      try {
        let span2 = document.getElementsByClassName('id_group_7 selector-color')[0].children[0] as HTMLElement;
        if (span2) {
          span2.click();
        }
      } catch (error) {
        this.getExistencias(this.productoData[0].sku, 'CHIH');
        this.getPrecioUnitario(this.productoData[0].sku, this.unidadVenta);
        this.getExistencias2(this.productoData[0].sku);


      }




    }, 1500);


  }

  async ShowError() {
    // const toast = await this.toastController.create({
    //   message: 'Inventario Insuficiente',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();

    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Inventario Insuficiente",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });
  }

  async ShowError7() {
    // const toast = await this.toastController.create({
    //   message: 'No puedes agregar menos de 1 en carrito',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();

    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No puedes agregar menos de 1 en carrito",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });
  }

  async ShowError3() {
    // const toast = await this.toastController.create({
    //   message: 'No puedes llevar al Carrito mas de la Cantidad en Existencias',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No puedes llevar al Carrito mas de la Cantidad en Existencias",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  async showSuccess() {
    // const toast = await this.toastController.create({
    //   message: 'Producto agregado al carrito',
    //   color: "success",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "success",
      title: "Exito!",
      text: "Producto Agregado a Carrito",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  async ShowError2() {
    // const toast = await this.toastController.create({
    //   message: 'Necesitas Seleccionar Color',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Necesitas Seleccionar Color",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }


  async yaexiste() {
    // const toast = await this.toastController.create({
    //   message: 'Este Producto ya se encuentra en tu Wishlist.',
    //   color: "danger",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Este Producto ya se encuentra en tu Wishlist",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  async agregado() {
    // const toast = await this.toastController.create({
    //   message: 'Este Producto se agrego a tu Wishlist.',
    //   color: "success",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "success",
      title: "Exito!",
      text: "Este Producto se agrego a tu Wishlist",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }

  async noagregado() {
    // const toast = await this.toastController.create({
    //   message: 'Este Producto se agrego a tu Wishlist.',
    //   color: "success",
    //   position: "top",
    //   duration: 1500

    // });
    // toast.present();
    Swal.fire({
      icon: "success",
      title: "Exito!",
      text: "Este Producto se agrego a tu Wishlist.",
      // footer: '<a href="#">Why do I have this issue?</a>'
    });

  }


  agregarWishlist() {
    if (this.usuario === null) return
    var jsonWishlist = {
      "usuario": this.usuario.usuario,
      "id_product": this.productoData[0].id_product,
      "title": this.productoData[0].title,
      "sku": this.productoData[0].sku,
      "quantiity": this.cantidad,
      "imagen": this.productoData[0].image_link,
      "sku_imagen": this.referenciaChida,
      "color": this.colorSeleccionado,
      "medida": this.productoData[0].unitId,
      "link": this.productoData[0].link,
      "link_rewrite": this.productoData[0].link_rewrite,
      precio: this.precioDyn
    }
    this.servicioWishlist.sendWishlist(jsonWishlist).subscribe((data: any) => {
      if (data == 0) {
        this.yaexiste();
      } else {
        /*if (this.usuario) {
          this.apiFacebook.sendEvent('AddToWishlist', this.precioFacebok.toFixed(2), this.usuario.usuario, this.infoUser2[0].phone, {
            external_id: this.infoUser.customerID,
            nombre: this.infoUser2[0].firstname,
            apellido: this.infoUser2[0].lastname,
            estado: this.infoUser2[0].id_state,
            ciudad: this.infoUser2[0].city,
            country: 'México',
            codigo_postal: this.infoUser2[0].postcode
          });
        }*/

        this.agregado();
      }
    })

  }


  getPrecioUnitario(reference: string, medida: string) {
    ////console.log(reference);
    ////console.log(medida);
    this.cargandoPrecio = 0
    /*if (reference == '' || reference == null || reference == undefined || reference == 'undefined' || medida == '' || medida == null || medida == undefined || medida == 'undefined') {
      return;
    }*/
    let objetoPost = {
      // CustAccount: "C000041928",
      // InventLocationId: "CHIHCONS",
      // InventSiteId: "CHIH",
      // ItemId: reference,
      // PercentCharges: 0,
      // UnitId: medida,
      // amountQty: 1,
      // company: "ATP",
      // currencyCode: "MXN",
      // transDate: this.nuevaFecha

      "custAccount": "CL0001348",
      "itemId": reference,
      "amountQty": 1,
      "transDate": this.nuevaFecha,
      "currencyCode": "MXN",
      "inventSiteId": "CHIH",
      "inventLocationId": "CHIHCONS",
      "company": "LIN",
      "unitId": medida,
      "percentCharges": 0
    }


    this.productoService.getPrecioUnitario(objetoPost).subscribe((response: any) => {

      try {
        this.precioDyn = response.data;
        this.precioDyn = this.precioDyn * 1.16
        this.precioFacebok = this.precioDyn;

        //console.log(this.precioDyn);
        const metaTag = this.renderer.createElement('meta');
        this.renderer.setAttribute(metaTag, 'name', 'product:price');
        this.renderer.setAttribute(metaTag, 'content', this.precioDyn);
        this.renderer.appendChild(document.head, metaTag);

        this.cargandoPrecio = 1;
        this.cargoprecio = true;
      } catch (error) {
        this.precioDyn = 0
        this.cargandoPrecio = 1;
        this.cargoprecio = true;
        // this.Disponibilidad = "NO DISPONIBLE"
        //console.log('error precio unitario ', objetoPost);
      }

      if (this.imgUrlGoogle === '') {
        this.productoService.getSliderImgs(this.id_product).subscribe((datazos: any) => {
          this.imgUrlGoogle = datazos[0].slider;
          //console.log(this.imgUrlGoogle);
          /*const script = this.renderer.createElement('script');     
          script.type = 'application/ld+json';     
          script.text = JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.productoData[0].title,
            "image": this.imgUrlGoogle,
            "offers": {
              "@type": "Offer",
              "priceCurrency": "MXN",
              "price": this.precioDyn,
              "availability": "https://schema.org/InStock"
            }
          }); 
          this.renderer.appendChild(document.head, script);*/
        });
      }

    })


  }

  getExistencias2(reference: any) {
    this.cargandoInventario = 0;
    this.existencias = [];
    this.productoService.getPrecioUnitario2(reference).subscribe(otroprecios => {
      try {
        let existenciasTem: any;
        existenciasTem = otroprecios;
        this.jsonExistencia = existenciasTem.data;
        if (reference === "4915-0915") {
          this.existencias = 0;
        } else {

          if (reference == '4425-0007') {

            this.existencias = existenciasTem.data.availableOnHandQuantityLideratEDMXEXHB

          } else {
            if (reference == '4410-0141') {

              this.existencias = existenciasTem.data.availableOnHandQuantityLideratEDMXEXHB + otroprecios.data.availableOnHandQuantityATP + otroprecios.data.availableOnHandQuantityLideart
            }

            let invavance = otroprecios.data.availableOnHandQuantityATP;

            invavance = Math.floor(invavance * .3);

            let invlideart = otroprecios.data.availableOnHandQuantityLideart;

            this.existencias = invlideart + invavance;
          }

        }
        this.cargandoInventario = 1;
        if (this.existencias > 0) {
          this.muestroStock = "Disponible"

        } else {
          this.muestroStock = "Agotado"
          this.Agotado = "Producto Agotado"
        }

      } catch (error) {
        this.muestroStock = "Agotado"
        this.existencias = 0
        this.cargandoInventario = 1;
      }

    })
  }


  subeme() {
    if (this.cantidad > 0) {

      this.cantidad = this.cantidad + 1;
    } else {
      this.cantidad = 1
    }
  }


  bajame() {
    if (this.cantidad > 1) {

      this.cantidad = this.cantidad - 1;
    } else {
      this.cantidad = 1
    }
  }

  getExistencias(reference: string, sitio: any) {
    this.cargandoInventario = 0;
    ////console.log(reference);
    //this.cargando = true;
    /*if (reference == '' || reference == null || reference == undefined || reference == 'undefined') {
      return;
    }*/
    // this.existencias = [];
    this.cargandoInventario = 0;

    this.productoService.getExistencias(reference, sitio).subscribe((response: any) => {
      let existenciasTem: any;
      existenciasTem = response;
      try {
        //console.log(response);
        this.productName = response.data.productName;
        this.productName = this.productName.replace(/["'\\/\n]/g, "");
        this.productName = this.productName.replace(/[()]/g, '');



        if (response.data != null) {
          // this.existencias = response.data.availableOnHandQuantity;

          this.cargandoInventario = 1;
          if (this.existencias) {

          }

        } else {

          this.Agotado = "Producto Agotado"
        }
      } catch (error) {
        this.productName = this.productoData[0].title.replace(/["'\\/\n]/g, ""); +" " + this.color.replace(/["'\\/\n]/g, "");
        this.Agotado = "Producto Agotado"
        // this.existencias = 0;
        this.cargandoInventario = 1;
        //console.log('error get existencias ', reference, sitio);
      }

    });



  }

  hola(img: any) {
    //console.log("Si llego");
    Swal.fire({
      title: 'Si funciono'
    })
    //console.log("Si salgo");

    //   Swal.fire({    
    //     title:'Holis',
    //     imageUrl: img,
    //     imageWidth: 200,
    //     imageHeight: 200,
    //     confirmButtonText: 
    // 'Cerrar',  

    //   })
  }

  obtenerDescuentoUsuario() {
    if (this.usuario && this.usuario.usuario) {
      const datosDescuento = {
        email: this.usuario.usuario,
        id_product: this.id_product
      };
      console.log("ID del producto:", this.id_product);

      this.productoService.getDescuentoSub(datosDescuento).subscribe((resdesc: any) => {
        try {
          if (resdesc != null && resdesc < 1) {
            // this.descuento = 1 - resDescSub.descuento;
            this.descuento = 1 - resdesc;
            this.descuentillo = resdesc;
            this.pruebaDescuento = resdesc;
          } else {
            this.obtenerDescuentoGeneral();
          }
        } catch (error) {
          this.obtenerDescuentoGeneral();
        }
        // console.log("Descuento recibido:", resdesc);
        // console.log("Descuentillo:", this.descuentillo);
        // console.log("Descuento:", this.descuento);
      });
    }
  }

  obtenerDescuentoGeneral() {
    const jsondescuento = { descuento: this.id_product };

    this.productoService.getDescuento(jsondescuento).subscribe((resdesc: any) => {
      try {
        this.pruebaDescuento = resdesc;
        if (resdesc < 1) {
          this.descuento = 1 - resdesc; // Calcula el factor de descuento
        } else {
          this.descuento = 1; // No hay descuento
        }
        this.descuentillo = this.descuento < 1;
      } catch (error) {
        this.descuento = 1; // En caso de error, no se aplica descuento
        this.descuentillo = false;
      }
    });
  }
  // obtenerDescuentoGeneral() {
  //   const jsondescuento = { descuento: this.id_product };

  //   this.productoService.getDescuento(jsondescuento).subscribe((resdesc: any) => {
  //     try {
  //       this.pruebaDescuento = resdesc;
  //       if (resdesc === 1) {
  //         this.descuentillo = false;
  //         this.descuento = 1;
  //       } else {
  //         this.descuentillo = resdesc < 1;
  //         this.descuento = resdesc < 1 ? resdesc : 1;
  //       }
  //     } catch (error) {
  //       this.descuentillo = resdesc < 1;
  //       this.descuento = resdesc < 1 ? resdesc : 1;
  //     }
  //     // console.log("Descuento recibido:", resdesc);
  //     // console.log("Descuentillo:", this.descuentillo);
  //     // console.log("Descuento1:", this.pruebaDescuento);
  //   });
  // }

  reviewProducto() {
    this.productoService.getReviewProducto(this.id_product).subscribe((datos: any) => {
      this.reviews = datos;
      this.loaded = true;
      console.log('Datos recibidos:', this.reviews);
    });
  }

  registraReviewProducto() {
    if (!this.formularioValido()) {
      this.mostrarError = true;
      console.log('Formulario inválido. Mostrar error:', this.mostrarError);
      return;
    }
    var jsonReview = {
      "id_product": this.id_product, "estrellas": this.estrellas, "nombre": this.nombre, "titulo": this.titulo, "comentario": this.comentario
      , "id_customer": this.id_customer
    };
    this.productoService.registraReviewsProduct(jsonReview).subscribe(data => {
      this.mostrarError = false;
      location.reload();
    })
  }

  formularioValido(): boolean {
    return this.estrellas > 0 && this.nombre.trim() !== undefined && this.titulo.trim() !== undefined && this.comentario.trim() !== undefined;
  }

}
