import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mesa-regalos-tipos',
  standalone: true,
  imports: [

  ],
  templateUrl: './mesa-regalos-tipos.component.html',
  styleUrls: ['./mesa-regalos-tipos.component.scss'],
})
export class MesaRegalosTiposComponent implements OnInit {

  public isDesk!: boolean;
  public isCel!: boolean;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    document.body.scrollTop = 0;
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }
    }
  }

  send(id: any) {
    this.router.navigateByUrl(`/mesa-regalos-tipo/${id}`);
  }

}
