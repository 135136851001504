<section class="check-step-cont">
  <div class="personal" id="resumen"
    style="width: 100%; height: auto; flex-direction: column; justify-content: flex-start; align-items: center;">

    @if (datosVentillaCargado) {

    <div class="flex flex-col items-center">
      <img class="w-[10em] mb-8" src="/assets/img/loguito.jpg" />
      @if(["canceled", "denied", "error"].includes(datosVentilla[0].status)){
      <label class="font-thin text-2xl text-red-600 underline">
        La compra no se ha procesado.
      </label>
      }
      @else if (datosVentilla[0].status === "approved" || !this.METODO_ASINCRONOS.includes(datosVentilla[0].metodo_pago)
      ){
      <!-- ! ELIMINAR EL OR (|| DE LO ANTERIOR) -->
      <label class="font-thin text-3xl ">
        ¡GRACIAS POR TU COMPRA!
      </label>
      }
      @else{
      <label class="font-thin text-2xl text-yellow-500 uppercase">
        Compra en proceso... 😊
      </label>
      @if (this.paymentURL !== null) {
      <a [href]="this.paymentURL"
        class="inline-block px-4 py-2 bg-[#1ca3c9] text-white font-semibold rounded-lg shadow-md hover:bg-[#178aa6] focus:outline-none focus:ring-2 focus:ring-[#1ca3c9] focus:ring-opacity-75">
        ¡Click aquí para ir al sitio de pago!
      </a>
      }
      }
      <p style="font-size: 1.2em; font-weight: 100; text-align: center; color: #ff5c6a;">
        Valoramos tu apoyo y estamos emocionados de tenerte como cliente.
      </p>
    </div>

    <div class="imgCont ion-float-start" style="padding-top: 1em; padding-bottom: 1em;">
      <h1 class="rowResumen"
        style="font-size: 2.2em; margin-block-start: 0em; margin-block-end: 0em; font-weight: 400; color: #1f99bb; text-shadow: 2px 2px 1px #71cffb; text-align: center;">
        Resumen de compra
      </h1>
    </div>

    <div
      style="width: 320px; height: auto; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
      <div
        style="width: 100%; height: auto; padding: 1em; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 1em;"
        *ngFor="let item of itemsdelcarrito">
        <div style="width: 5em; height: 5em;">
          <img style="width: 100%; height: 100%;" src="{{item.image_link}}" />
        </div>
        <p style="margin-block-start: 0em; margin-block-end: 0em; width: calc(100% - 6em); font-size: 1em; font-weight: 100;"
          class="tituloItemCompra">{{item.title}}</p>
        <p>x{{item.quantity}}</p>
      </div>
    </div>

    <div
      style="width: 320px; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: .3em;">


      <div style="width: 100%; display: flex; gap: 0.5em;" *ngIf="metodo_pago == 'Transferencia'">
        <span style="color: #df0b0b; font-size: 1em; font-weight: bold;">Importante: </span>
        <span style="color: #000; font-size: 1em;font-weight: bold">Recuerda que para hacer valido tu pago es importante
          que nos envíes
          tu comprobante de pago al correo de
          ventas&#64;lideart.com.mx y contacto&#64;lideart.com.mx, en el asunto agregar la orden de venta.</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Total:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;" class="cantidadesResumen">{{total | currency}}
          MXN</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Método de Pago:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{metodo_pago}}</span>
      </div>
      @if(this.METODO_ASINCRONOS.includes(datosVentilla[0].metodo_pago)){
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Estado del pago:</span>
        <span class="capitalize"
          style="font-weight: 100; font-size: .9em; color: #000;">{{datosVentilla[0].status}}</span>
      </div>
      }
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodo_pago == 'Transferencia'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">No. de Cuenta</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{noCuenta}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodo_pago == 'Transferencia'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Clabe Interbancaria</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{clabe}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodo_pago == 'Transferencia'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Banco</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{banco}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodo_pago == 'Oxxo Pay'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Referencia</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{oxxo}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodo_pago == 'Oxxo Pay'">
        <span style="color: #ff0000; font-size: .8em;font-weight: bold;" class="etiquetasResumen">Nota:</span>
        <span class="text-end"
          style="font-weight: 100; font-size: .9em; color: #000; text-align: right;font-weight: bold;">Favor de
          verificar spam, para tu correo con la referencia</span>
      </div>

      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Tipo de Envio:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{modoEntrega}}</span>
      </div>
      @if (modoEntrega != 'Recoger en Sucursal'){
      <div class="rowResumen mb-2" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Nota:</span>
        <span class="text-end" style="font-weight: 100; font-size: .9em; color: #000;">Tu número de guía se estará
          enviando a tu correo
          en las próximas 24 o 48 horas hábiles segun la hora en la que realizaste tu compra.</span>
      </div>
      }
      @else {
      <div class="rowResumen mb-2" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Nota:</span>
        <span class="text-end" style="font-weight: 100; font-size: .9em; color: #000;">Puedes pasar a recoger tu pedido
          dentro de 2 horas de haber realizado tu compra, en caso de pagar con transferencia u oxxo es 2 horas despues
          de enviar el comprobante de pago.</span>
      </div>
      }




      <div *ngIf="modoEntrega == 'Paquete Express'" class="rowResumen"
        style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Código de Rastreo:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{etiquetas}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Cotización:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{cotizacion}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Orden de Venta:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{ov}}</span>
      </div>

      <div class="rowResumen mb-2" style="width: 100%; display: flex; justify-content: space-between;">
        <!-- <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Aviso:</span> -->
        <span style="font-weight: bold; font-size: .7em; color: #27c6f7; text-align: center;"> Si no visualiza el correo
          con
          la confirmación de su compra en la bandeja de entrada, favor de verificar su bandeja de Spam (Correo no
          Deseado)</span>
      </div>
    </div>



    @if(datosVentilla[0].gateway){
    <div class="p-8 mt-3 rounded-md border-solid border-2 text-center bg-yellow-100 border-yellow-200 text-gray-500">
      {{datosVentilla[0].gateway}}
    </div>
    }

    <div class="contB" style="padding: 1em 0em;">
      <button
        style="font-size: 1.2em; font-weight: 100; color: #ff5c6a; background-color: #fff; border: 1px solid #ff5c6a; border-radius: 3em; padding: .5em 2em;"
        (click)="finalizar()" class="full" routerLinkActive="router-link-active">Aceptar</button>
    </div>
    }
    @else {
    <span>Cargando...</span>
    }
  </div>
</section>