import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule,RouterModule,IonicModule],
  templateUrl: './distribuidor.component.html',
  styleUrl: './distribuidor.component.scss',
  providers:[LoginService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DistribuidorComponent  implements OnInit {

  nombre_negocio:any;
  nombre_cliente:any;
  correo:any;
  telefono:any;
  mensaje:any;
  cargando=false;

  constructor(
    private loginService: LoginService,
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Distribuidores");
  }

  enviaEncuesta(){

    this.cargando =true;
let datosEncuesta = {
  "nombre_negocio": this.nombre_negocio,
  "nombre_cliente": this.nombre_cliente,
  "correo":this.correo,
  "telefono": this.telefono,
   "mensaje": this.mensaje
}

    this.loginService.mandaEscuestaSub(datosEncuesta).subscribe(datillos =>{
      this.cargando =false;
      this.searmo();
    this.nombre_negocio = "";
    this.nombre_cliente = "";
    this.correo = "";
    this.telefono = "";
    this.mensaje = "";

    })
  }

  async searmo() {
    // const toast = await this.toastController.create({
    //   message: 'Formlario enviado con exito.',
    //   color: "success",
    //   position: "top",
    //   duration: 1500
      
    // });
    // toast.present();
    Swal.fire({
      icon: "success",
      title: "Formlario enviado con exito",
      showConfirmButton: false,
      timer: 1500
    });
  }



}
