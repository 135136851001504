@if(isDesk) {
@if(!tieneMesa) {
<section class="mesa-regalos-principal-cont" style=" margin-top: 100px;">
  <div class="fix-width">
    <div class="banner-1-cont">
      <img src="/assets/img/mesa-regalos/banner-mesa.webp">
    </div>
    <div class="banner-no-hay-cont">
      <img src="/assets/img/mesa-regalos/nohaymesa.jpg">
    </div>
    <div class="links-cont">
      <a [routerLink]="[ '/mesa-regalos-landing' ]" class="link-regresar">Regresar</a>
      <a class="link-crear" [routerLink]="[ '/mesa-regalos-tipos' ]">Crear una mesa de regalos</a> <!--  -->
    </div>
  </div>
</section>
}
@else if(tieneMesa) {
<section class="hay-mesa-regalos-principal-cont" style=" margin-top: 100px;">
  <div class="fix-width">
    <div class="banner-1-cont">
      <img src="/assets/img/mesa-regalos/banner-mesa.webp">
    </div>
    <div class="banner-si-hay-cont">
      <img src="/assets/img/mesa-regalos/banner-regalos-principal.webp">
      <div class="inside-info-cont">
        <div class="first-part-cont">
          <span class="bienv"><span class="strong-bienv">¡¡HOLA!! </span>{{nombre}}</span>
          <span class="evento">Evento: <span class="strong-evento">{{nombre_evento}} </span>No. Evento: <span
              class="strong-evento">{{referencia}}</span></span>
        </div>
        <div class="second-part-cont">
          <span class="second-title">Comparte este enlace para que tus invitados puedan ver tu lista de regalos</span>
          <div class="copy-cont">
            <div class="muestra-link">
              <span class="link-txt-muestra">
                {{linkMesa}}
              </span>
            </div>
            <button class="copiar-link-btn" [cdkCopyToClipboard]="linkMesa">Copiar enlace</button>
          </div>
          <div class="count-cont">
            <div class="col-count">
              <div class="cifra-count-cont">
                {{diasparaEvento}}
              </div>
              <span class="desc-count">Días para tu evento</span>
            </div>
            <div class="col-count">
              <div class="cifra-count-cont">
                {{cuantos}}
              </div>
              <span class="desc-count">Regalos en tu mesa</span>
            </div>
            <div class="col-count">
              <div class="cifra-count-cont">
                {{cuantoshaycomprados}}
              </div>
              <span class="desc-count">Regalos comprados</span>
            </div>
          </div>
        </div>
        <div class="third-part-cont">
          <span class="title-third-part">MIS DATOS</span>
          <span class="third-email">Correo electrónico: {{correo}}</span>
          <span class="third-celular">Celular: {{celular}}</span>
          <span class="third-direccion">Dirección: {{direccion}}, {{colonia}} {{ciudad}},{{estado}}. México C.P.
            {{codigo_postal}}</span>
        </div>
        <div class="third-part-cont">
          <span class="title-third-part">MENSAJE: </span>
          <span class="third-email">{{mensaje}}</span>

        </div>
      </div>
    </div>
    <div class="buttons-cont">
      <!-- <button class="btn-mesa-bottom color-1">Ver el contrato de la mesa de regalos</button> -->
      <a class="btn-mesa-bottom color-2" [routerLink]="[ '/mesa-regalos-ver' ]"
        style="text-decoration: none; text-align: center; display: flex; align-items: center;">Ver mi lista de
        regalos</a>
      <!-- <button class="btn-mesa-bottom color-3">Solicitar asesoría</button> -->
    </div>
  </div>
</section>
}
}
@else if(isCel) {
@if(!tieneMesa) {
<section class="wish-cel-cont">
  <div class="banner-1-cont">
    <img src="/assets/img/mesa-regalos/banner-1-cel.webp">
  </div>
  <div class="banner-no-hay-cont">
    <img src="/assets/img/mesa-regalos/nohaymesa.jpg">
  </div>
  <div class="links-cont">
    <a [routerLink]="[ '/mesa-regalos-landing' ]" class="link-regresar">Regresar</a>
    <a class="link-crear" [routerLink]="[ '/mesa-regalos-tipos' ]">Crear una mesa de regalos</a> <!--  -->
  </div>
</section>
}
@else if(tieneMesa) {
<section class="hay-mesa-regalos-principal-cont">
  <div class="fix-width">
    <div class="banner-1-cont">
      <img src="/assets/img/mesa-regalos/banner-1-cel.webp">
    </div>
    <div class="banner-si-hay-cont">
      <div class="inside-info-cont">
        <div class="first-part-cont">
          <span class="bienv"><span class="strong-bienv">¡¡HOLA!! </span>{{nombre}}</span>
          <span class="evento">Evento: <span class="strong-evento">{{nombre_evento}} </span>No. Evento: <span
              class="strong-evento">0313</span></span>
        </div>
        <div class="second-part-cont">
          <span class="second-title">Comparte este enlace para que tus invitados puedan ver tu lista de regalos</span>
          <div class="copy-cont">
            <div class="muestra-link">
              <span class="link-txt-muestra">
                {{linkMesa}}
              </span>
            </div>
            <button class="copiar-link-btn" [cdkCopyToClipboard]="linkMesa">Copiar enlace</button>
          </div>
          <div class="count-cont">
            <div class="col-count">
              <div class="cifra-count-cont">
                {{diasparaEvento}}
              </div>
              <span class="desc-count">Días para tu evento</span>
            </div>
            <div class="col-count">
              <div class="cifra-count-cont">
                {{cuantos}}
              </div>
              <span class="desc-count">Regalos en tu mesa</span>
            </div>
            <div class="col-count">
              <div class="cifra-count-cont">
                {{cuantoshaycomprados}}
              </div>
              <span class="desc-count">Regalos comprados</span>
            </div>
          </div>
        </div>
        <div class="third-part-cont">
          <span class="title-third-part">MIS DATOS</span>
          <span class="third-email">Correo electrónico: {{correo}}</span>
          <span class="third-celular">Celular: {{celular}}</span>
          <span class="third-direccion">Dirección: {{direccion}}, {{colonia}} {{estado}}, {{ciudad}} México C.P.
            {{codigo_postal}}</span>
        </div>
      </div>
    </div>
    <div class="buttons-cont">
      <!-- <button class="btn-mesa-bottom color-1">Ver el contrato de la mesa de regalos</button> -->
      <a class="btn-mesa-bottom color-2" [routerLink]="[ '/mesa-regalos-ver' ]"
        style="text-decoration: none; text-align: center; display: flex; align-items: center;">Ver mi lista de
        regalos</a>
      <!-- <button class="btn-mesa-bottom color-3">Solicitar asesoría</button> -->
    </div>
  </div>
</section>
}
}