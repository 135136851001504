ion-searchbar {
  --icon-color: #1ca3c9;
  width: 95%;
  margin-left: 10px;
}


// ion-button {
//     --box-shadow: transparent !important;
//   }

.elementSyle{
  margin: 0;
  border-bottom: none;
}

.categoriaDesk{
  margin-top: 100px;
width: 100%;
height: auto;
display: none;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
overflow-y: scroll;

.contDesk{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 2em 0em;
  gap: 2em;

  .title-cont{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    h1{
      color: #1e7285;
      font-weight: 100;
      font-size: 2.2em;
      text-align: center;
    }
  }

  .conten-cats{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;

    .cardin{
      width: 16em;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      padding: 1em 0em;
      border-bottom: 1px solid #0ba7c7;
      transition: .4s all ease-in-out;
      gap: 1em;

      .cat-name{
        color: #00a3c7;
        font-weight: 100;
        text-align: center;
        padding: 0em 1em;
      }

      .contImg{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
      }
    }

    .cardin:hover{
      border-bottom: 1px solid #0ba8c700;
      box-shadow: rgba(23, 171, 202, 0.5) 0px 6px 24px;
    }
  }
}
}

@media(min-width:992px){
  .display-grid{
    display: grid;
    grid-template-columns: repeat(1,1fr); 
    //grid-template-columns: repeat(auto-fit,minmax(300px,1fr));    
   }    
  
  ion-content{
    display: none;
  }

  ion-header{
    display: none;
  }

  .categoriaDesk{
    display: flex;
  }
}
.cardContainer{
  border-bottom: none; /* Remove any borders */
  outline: none; /* Remove the button's outline when clicked */
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoriaDesk::-webkit-scrollbar {
width: .35em;
background-color: transparent;
height: .7em;
}

.categoriaDesk::-webkit-scrollbar-thumb {
background-color: #00a2c779;
//border-radius: 1vh;
width: 0.25em;
}