<div #hedin *ngIf="isCel">
  <ion-header [translucent]="true" class="gorgori">
    <div class="opts-cont-menu">
      <button class="tog-menu-btn" (click)="togmenu()"><i class="fa-solid fa-bars"></i>
        <!--<img src="assets/icon/opciones.png" style="width: 2em; height: auto;">--> </button>
      <!-- <ion-title color="light" class="name-client"> {{nombre+" "+apellido}}</ion-title> -->
      <img [routerLink]="['/home']" src="assets/img/lidelogo.webp" style="width: 100px; height: auto;">


      <!-- <button class="tog-search-bar" (click)="openSearchBar()"><i class="fa-solid fa-magnifying-glass"></i></button> -->
      <a *ngIf="usuario" class="cart-go2" [routerLink]="['/perfil/0']">
        <i class="fa-solid fa-circle-user grapi-up"></i></a>
      <a *ngIf="usuario" class="cart-go" [routerLink]="['/cart']">
        <i class="fa-solid fa-bag-shopping grapi-up"></i>
        <!--<img src="assets/icon/compras.png" style="width: 1.5em; height: auto;">-->
        <!-- <span class="i-l">{{totalItems}}</span> -->
      </a>
      <a *ngIf="usuario" class="cart-go3" [routerLink]="['/wishlist']">
        <i class="fa-solid fa-heart grapi-up"></i></a>
      <a *ngIf="!usuario" class="cart-go4" [routerLink]="['/cart']">
        <i class="fa-solid fa-bag-shopping grapi-up"></i>
        <!--<img src="assets/icon/compras.png" style="width: 1.5em; height: auto;">-->
        <!-- <span class="i-l">{{totalItems}}</span> -->
      </a>

    </div>

    <div class="search_toggle cel-toggle" style="background: #17c2fc; font-size: 1.1em; position: relative;">
      <div class="py-2" id="buscador">
        <div id="search_plugin">
          <i class="fa-solid fa-magnifying-glass grapi ssd" style=" position: absolute;
          font-size: 1em;
          color: #fff;
          left: 4em;
          top: .6em;
          z-index: 1;"></i>
          <form class="form-busc-cont">
            <button class="btn-busc" (click)="onEnterCel(searchInputCel.value)">Buscar</button>

            <input type="text" class="buscainp" autocomplete="off" style="border-radius: 15px; padding-left: 35px;"
              id="pabuscar" (ngModelChange)="onSearchChange($event)" [(ngModel)]="txtBuscador" #searchInput
              #searchInputCel [ngModelOptions]="{standalone: true}">
          </form>
          <!--<form method="get" routerLinkActive="router-link-active" style="position: relative;"> 
            <button style="position: absolute; font-size: .8em; font-weight: 100; padding: .4em 1em; border-radius: 1em; background-color: #52d3ff; color: #fff; right: 1em; top: .4em; z-index: 1;" type="submit" (click)="onEnterCel(searchInputCel.value)">Buscar</button>
            <input type="text" name="s" placeholder="Busque en nuestro catalogo" autocomplete="off" id="pabuscar" class="buscainpcel" onkeyup="buscaleprevio(this,event)" [(ngModel)]="txtBuscador" (ngModelChange)="onSearchChange($event)" #searchInput #searchInputCel>
            <input type="text" name="p" 	value="1"  		style="display: none;">
            <input type="text" name="v" 	value="12" 		style="display: none;">
            <input type="text" name="view" 	value="list" 	style="display: none;">
            <input type="text" name="sort" 	value="0" 	style="display: none;">
          </form>-->
        </div>
      </div>
      <div class="results-cont-google" *ngIf="txtBuscador">
        <!-- <button class="clearing" (click)="cleanBusk()">Limpiar</button> -->
        <div class="links-cont-results" #focus>
          @if (customSearchService.isLoading()) {
          <div class="flex justify-center items-center flex-col w-full h-full">
            <img class="w-[150px] h-auto" src="/assets/img/loadersillo.gif">
            <p class="text-gray-400 font-normal text-[15px]">Cargando...</p>
          </div>
          }
          @else if (results && results.items && results.items.length > 0) {
          <a *ngFor="let item of results.items" class="prod-cont" [routerLink]="[ '/'+item.link ]"
            (click)="cleanBusk()">
            <div class="pp pp-cat" *ngIf="item.link.includes('/catalogo/')">
              <i class="fa-sharp fa-solid fa-layer-group"></i>
              <span class="txt-pp">Categoría</span>
            </div>
            <div class="pp pp-prod" *ngIf="!item.link.includes('/catalogo/') && !item.link.includes('/paquetes/')">
              <i class="fa-sharp fa-solid fa-tags"></i>
              <span class="txt-pp">Producto</span>
            </div>
            <div class="pp pp-paq" *ngIf="item.link.includes('/paquetes/')">
              <i class="fa-sharp fa-regular fa-boxes-stacked"></i>
              <span class="txt-pp">Paquete</span>
            </div>
            <div class="info-prod">
              <div class="img-cont-prod">
                <img class="img-prod" *ngIf="item.pagemap?.cse_image" [src]="item.pagemap.cse_image[0].src"
                  alt="{{ item.title }}">
              </div>
              <div class="txt-cont">
                <span class="title-prod">{{ item.title }}</span>
                <div class="line-tb">
                  <div
                    *ngFor="let variable of (coloresMostrados.includes(item.link) ? item.variables : item.variables?.slice(0, 10)) ?? []"
                    class="r-color"
                    [ngStyle]="{'background-color': variable.color, 'background-image': variable.image ? 'url(' + variable.image + ')' : 'none'}">
                  </div>
                  <div *ngIf="(item.variables?.length ?? 0) > 10 && !coloresMostrados.includes(item.link)"
                    class="r-color more-colors" (click)="toggleShowColors($event, item.link)">
                    ...
                  </div>
                  <div *ngIf="coloresMostrados.includes(item.link)" class="r-color more-colors"
                    (click)="toggleShowColors($event, item.link)">
                    ↑
                  </div>
                </div>
                <div class="price-cont-prod"
                  *ngIf="item.pagemap.metatags[0]['product:price'] && item.pagemap.metatags[0]['product:price'] && item.pagemap.metatags[0]['product:price'] >'0'">
                  @if (item.pagemap.metatags[0]['product:descuento'] && item.pagemap.metatags[0]['product:priceD']) {
                  <span class="price-strikethrough">
                    $ {{ item.pagemap.metatags[0]['product:price'] | number: '1.2-2' }}
                  </span><br>
                  <span class="price-prod">$ {{ (item.pagemap.metatags[0]['product:priceD']) * (1 -
                    item.pagemap.metatags[0]['product:descuento']) | number: '1.2-2' }} <span
                      class="curr">MXN</span></span>
                  <!-- <del>$ {{ item.pagemap.metatags[0]['product:price'] }} </del> -->
                  } @else {
                  <span class="price-prod">$ {{ item.pagemap.metatags[0]['product:price'] | number: '1.2-2' }} <span
                      class="curr">MXN</span></span>
                  }
                  <!-- <span class="price-prod">{{ items.descuento }} <span class="curr">MXN</span></span> -->
                </div>
              </div>
            </div>
            <!--
              
              <div class="txt-cont-prod">
                
              </div>
              <div class="rowins">
                <div class="price-cont-prod" *ngIf="item.pagemap.metatags[0]['product:price'] && item.pagemap.metatags[0]['product:price']>0">
                  <span class="price-prod">$ {{ item.pagemap.metatags[0]['product:price'] | number:'1.2-2' }} <span class="curr">MXN</span></span>
                </div>
              </div>-->
          </a>
          } @else {
          <div class="flex justify-center items-center flex-col w-full h-full">
            <i class="fa-light fa-face-sad-sweat text-[#1ca3c9] text-[35px] mb-1"></i>
            <p class="text-gray-400 text-[15px]">No se encontraron resultados</p>
          </div>
          }
        </div>
        <!-- <div class="btns-cont">
          @if (results.queries.previousPage) {
          <button class="prev-forw-btns" (click)="prevPage()">Atrás</button>
          }
          @if (results.queries.nextPage) {
          <button class="prev-forw-btns" (click)="nextPage()">Siguiente</button>
          }
        </div> -->
      </div>
      <!--<div class="conten-abs conten-abs-cel" *ngIf="txtBuscador != ''">
        <div style="width: 100%; display: flex; justify-content: center;">
          <img src="assets/img/cargando.gif" alt="" style="width: 7em;" *ngIf="txtBuscador != '' && banderaLi == false">
        </div>
        <div class="container-fluid m-0 cuerpoPistas" id="cuerpoPistas" style="padding: 0px; display: block;">
          <div class="row m-0" style="padding: 0px;background: transparent;">
            <div class="col-lg-12 col-xs-12 col-sm-12 m-lg-0 p-lg-0" id="contenedorPistas">-->

      <!--<div class="card text-center text-dark"  id="contResultadosPistas" style="display: none;" >
                <div class="card-body">
                  <h1 class="txt-decir" (click)="handlePistas()"><div style="display: inline;">Quiza quisiste decir:</div> <i class="fas fa-chevron-up ud1" #ud1></i></h1>
                  <div id="resultadosPistas" class="text-left" style="padding: 0px 15px 15px 15px;text-align: left;">
                    <div class="row container p-lg-0 m-lg-0 lasPistas" id="lasPistas" #pistasCont>
    
                    </div>
                  </div>
                </div>
              </div>-->

      <!--</div>
          </div>
        </div>
        <div class="container-fluid m-0" id="cuerpoBusqueda" style="padding: 0px; display: none;">
          <div class="row m-0 dowi" style="padding: 0px;background: transparent;">-->
      <!--<div class="col-lg-12 col-xs-12 col-sm-12 m-lg-0 p-lg-0 lefti" id="contenedorFiltros" style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: .3em;">
              <div class="card text-center text-dark"  id="contResultadosMarcas" #cardMarcas>
                <div class="card-body">
                  <p class="taitl-filt" (click)="handleCard1()">Marcas <i class="fas fa-chevron-up" style="margin-left:10px" #ud2></i></p>
                  <div id="resultadosMarcas" class="text-left lista" style="padding:0px 15px;text-align: left;"></div>
                </div>
              </div>
              
            </div>-->
      <!--<div class="col-lg-12 col-xs-12 col-sm-12 rigti"  id="padreResultados" >
              <div class="card text-center text-dark">
                <div class="card-body">
                  <p class="card-title text-uppercase result-taitl" id="res-tit" style="text-align: center;background: transparent;">resultados</p>
                  <ul class="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all" #ulElement id="axbody" tabindex="0"></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </ion-header>
</div>

<!-- #################################################################### Menú cel #################################################################### -->
<section class="cel-menu" #celMen>
  <div class="list-cont-opcs" #jik>
    <div class="porta-cel-cont">
      <div class="log-cont">
        <a [routerLink]="['/home']" routerLinkActive="router-link-active" (click)="togmenu()">
          <img class="imag" src="assets/img/logo-lide.png" alt="">
        </a>
      </div>
    </div>
    <div class="blue-zone">
      <a *ngIf="!usuario" class="opt-blue-zone" [routerLink]="['/login']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-user icona-opt"></i>
        <span class="txt-opt-bz">Iniciar Sesión</span>
      </a>
      <a *ngIf="usuario" class="opt-blue-zone" [routerLink]="['/perfil/0']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Cuenta.png" height="20x" width="20px">
        <span class="txt-opt-bz">Ver Cuenta</span>
      </a>
      <a *ngIf="usuario" class="opt-blue-zone" [routerLink]="['/perfil/1']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-trophy icona-opt"></i>
        <span class="txt-opt-bz">Lidepuntos</span>
      </a>
      <a *ngIf="usuario" class="opt-blue-zone" [routerLink]="['/perfil/2']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-clock-rotate-left icona-opt"></i>
        <span class="txt-opt-bz">Historial de Pedidos</span>
      </a>
      <a class="opt-blue-zone" [routerLink]="['/cart']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-cart-shopping icona-opt"></i>
        <span class="txt-opt-bz">Ver Carrito</span>
      </a>
      <a *ngIf="usuario" class="opt-blue-zone" routerLinkActive="router-link-active" (click)="close()"
        (click)="togmenu()">
        <i class="fa-solid fa-user-xmark icona-opt"></i>
        <span class="txt-opt-bz">Cerrar Sesión</span>
      </a>
    </div>
    <div class="rest-opts-cont">
      <a class="opt-rest-zone" [routerLink]="['/home']" routerLinkActive="router-link-active" (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Inicio.png" height="25px" width="25px">
        <span class="txt-opt-r">Ir a Inicio</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/categorias']" routerLinkActive="router-link-active" (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Categorias.png" height="25px" width="25px">
        <span class="txt-opt-r">Categorías de Productos</span>
      </a>
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2115/Just-Glow']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">JUST GLOW!</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/promociones-cricut']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">PROMOCIONES CRICUT</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2742/super-fin']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">SUPER FIN</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2412/promociones-halloween']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">PROMOCIONES HALLOWEEN</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2621/promociones-brother']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">PROMOCIONES BROTHER</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2782/black-friday']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">BLACK FRIDAY</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2639/mega-fin-crafter']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">MEGA FIN CRAFTER</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2254/promociones-para-stickers']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">¡PROMOCIONES PARA STICKERS!</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2137/para-tus-agendas-2025']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">¡PARA TUS AGENDAS 2025!</span>
      </a> -->
      <a class="opt-rest-zone" [routerLink]="['/catalogo/2141/nuevos-paquetes']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">NUEVOS PAQUETES</span>
      </a>
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2315/back-to-school']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">BACK TO SCHOOL</span>
      </a> -->
      <!-- <a class="opt-rest-zone" [routerLink]="['/catalogo/2363/dale-un-toque-de-brillo']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-box-open icona-opt"></i>
        <span class="txt-opt-r">¡DALE UN TOQUE DE BRILLO!</span>
      </a> -->
      <a class="opt-rest-zone" [routerLink]="['/catalogo/123/outlet']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Promociones.png" height="25px" width="25px">
        <span class="txt-opt-r">Ver Promociones</span>
      </a>
      <a *ngIf="usuario" class="opt-rest-zone" [routerLink]="['/wishlist']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Wishlist.png" height="25px" width="25px">
        <span class="txt-opt-r">Mi Lista de Deseos</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/blog']" routerLinkActive="router-link-active" (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Blog.png" height="25px" width="25px">
        <span class="txt-opt-r">Ir a Blog</span>
      </a>
      <!-- <a class="opt-rest-zone" [routerLink]="['/facturacion']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <img src="/assets/img/iconosMenu/Boton-Blog.png" height="25px" width="25px">
        <span class="txt-opt-r">Facturación</span>
      </a> -->
      <a class="opt-rest-zone" [routerLink]="['/faq']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-person-circle-question icona-opt"></i>
        <span class="txt-opt-r">Preguntas Frecuentes</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/lidepuntos']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-medal icona-opt"></i>
        <span class="txt-opt-r">Lidepuntos</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/compra-y-recoge']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-medal icona-opt"></i>
        <span class="txt-opt-r">¡COMPRA Y RECOGE!</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/terminos-politicas/3']" routerLinkActive="router-link-active"
        (click)="togmenu()">
        <i class="fa-solid fa-handshake icona-opt"></i>
        <span class="txt-opt-r">Políticas</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/reviews']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-face-smile-wink icona-opt"></i>
        <span class="txt-opt-r">Reviews</span>
      </a>
      <a class="opt-rest-zone" [routerLink]="['/chat']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-message icona-opt"></i>
        <span class="txt-opt-r">Chat</span>
      </a>
      <a class="opt-rest-zone" href="https://soporte.craftroom.mx/hc/es-419" target="blank" (click)="togmenu()">
        <i class="fa-solid fa-headset icona-opt"></i>
        <span class="txt-opt-r">Soporte</span>
      </a>
      <!-- <a class="opt-rest-zone" [routerLink]="['/contacto']" routerLinkActive="router-link-active" (click)="togmenu()">
        <i class="fa-solid fa-envelope-open-text icona-opt"></i>
        <span class="txt-opt-r">Contacto</span>
      </a> -->
    </div>
  </div>
  <div class="rest-close" (click)="togmenu()"></div>
</section>
<!-- #################################################################### Menú cel #################################################################### -->


<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>

<div class="desk-header-app">
  <app-desk-header style="width: 100%;"></app-desk-header>
</div>

<router-outlet></router-outlet>

<div class="desk-footer-app" *ngIf="isDesk">
  <app-desk-footer></app-desk-footer>
</div>

<!--<ion-router-outlet id="first" id="main-content"></ion-router-outlet>-->


<!-- <div class="fixed bottom-4 left-4 bg-white rounded-lg shadow-lg p-6 w-80 z-50" *ngIf="showPopup">

  <button class="absolute top-2 right-2 text-gray-500 hover:text-black focus:outline-none" (click)="closePopup()">
    ✕
  </button>


  <h2 class="text-lg font-bold text-gray-800">¡Oferta del Día!</h2>
  <p class="mt-2 text-gray-600">Aprovecha el descuento exclusivo en este producto.</p>


  <div class="mt-4 flex justify-center">
    <img src="assets/img/oferta-producto.jpg" alt="Producto en oferta" class="w-24 h-24 object-contain" />
  </div>


  <div class="mt-4 text-center">
    <p class="text-lg font-semibold text-gray-800">Solo por: <span class="text-red-500">$99.99</span></p>
    <button class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none">
      Ir a Producto
    </button>
    <button style="margin-left: 10px;"
      class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none">
      Comprar
    </button>
  </div>
</div> -->