<section class="">
    <div class="conten-cont" style="">
        <!-- <p class="texto-azul-desk" style="font-size: 1.5em;"><strong>¡COMPRA Y RECOGE EN ALMACÉN CHIHUAHUA!</strong></p> -->
        <div class="container-1" style="justify-content: center; align-items: flex-start; gap: 5em;">
            <!-- Imagen del Banner -->
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <video style="max-width: 100%; height: auto;" autoplay muted loop>
                    <source src="/assets/videos/codigo-qr/01-VIDEO-BANNER-PAGINA-LIDEART-versión PC.mp4" type="video/mp4">
                </video>
            </div>
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <img src="/assets/img/codigo-qr/02-Banner-Landingpage-QR-PC.webp" alt="" style="max-width: 100%;height: auto;">
            </div>
            <div class="banner-container-movil" style="align-items: center;width: auto;height: auto;padding-right: 0em;display: none;">
                <video style="max-width: 100%; height: auto;" autoplay muted loop>
                    <source src="/assets/videos/codigo-qr/01-VIDEO-BANNER-PAGINA-LIDEART-version-MOVIL.mp4" type="video/mp4">
                </video>
            </div>
            <div class="banner-container-movil" style="align-items: center;width: auto;height: auto;padding-right: 0em;display: none;">
                <img src="/assets/img/codigo-qr/02-Banner-Landingpage-QR-MOVIL-30.webp" alt="" style="max-width: 100%;height: auto;">
            </div>
            <p class="regular-text" style="font-size: 2.5em;color: #939497;padding: 0em 4em; text-align: justify;">Somos una tienda en línea especializada en materiales y equipos para manualidades. Ofrecemos una amplia variedad de productos como plotters de corte, viniles, papelería creativa, insumos de sublimación, resina, costura, engargolado, ecuadernado, scrapbook y más.</p>
            <p class="regular-text" style="font-size: 2.5em;color: #939497;padding: 0em 4em; text-align: center;">¡En Lideart queremos que tus <strong><span style='font-family: "Dancing Script", cursive;'>ideas</span></strong> te hagan <strong>crecer!</strong></p>
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <img src="/assets/img/codigo-qr/03-Banner-Landingpage-QR-PC-31.webp" alt="" style="max-width: 100%;height: auto;">
            </div>
            <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 0px 6.7em;">
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/01-Arte-en-papel.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Arte en papel</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/02-Decoracion-de-rigidos-con-vinil.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Decoración de rígidos con vinil</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/03-Decoracion-con-vinil-textil.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Decoración con vinil textil</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/04-Decoracion-con-foil.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Decoración con foil</span>
                </div>
            </div><br>

            <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 0px 6.7em;">
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/05-Sublimacion.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Sublimación</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/06-Costura.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Costura</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/07-Resina.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Resina</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/08-Grabado-y-repujado.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Grabado y repujado</span>
                </div>
            </div><br>

            <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 0px 6.7em;">
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/09-Engargolado-y-encuadernado.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Engargolado y encuadernado</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/10-Scrapbook.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Scrapbook</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/11-Troquelado.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Troquelado</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 25%;align-items: center;">
                    <img src="/assets/img/codigo-qr/12-Impresion-3D.webp" alt="" style="width: 90%;height: auto;">
                    <span class="regular-text-d" style="color: #939497;text-align: justify;font-size: 1.5em;margin-left: 4px;">Impresión 3D</span>
                </div>
            </div><br>
            <div style="background: #f4f4f4;">
                <div class="banner-container-d" style="text-align: center;height: auto;justify-content: flex-start;align-items: center;padding: 50px 6.7em;background: #f4f4f4;">
                    <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;align-items: center;">
                        <a href="https://lideart.com.mx/home" target="_blank">
                            <img src="/assets/img/codigo-qr/Landingpage-QR-del-display-Lideart-en-suc-avance.webp" alt="" style="width: 1685px;height: auto;">
                        </a>
                    </div>
                </div>
                <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 10px 6.7em;background: #f4f4f4;">
                    <div class="banner-container-2" style="flex-direction: column;text-align: center;height: auto;display: flex;justify-content: flex-start;gap: .7em;align-items: center;">
                        <a href="https://lideart.com.mx/registro" target="_blank">
                            <img src="/assets/img/codigo-qr/07-Boton-Landingpage.webp" alt="" style="width: 99%;height: auto;">
                        </a>
                    </div>
                </div>
                <p class="regular-text" style="font-size: 2.5em;color: #939497;padding: 0em 4em; text-align: center;"><strong style="color: #00a7ce;font-size: 1.1em;">•</strong><strong style="color: #63d5e0;font-size: 1.1em;">•</strong><strong style="color: #73dfd8;font-size: 1.1em;">•</strong> Mantente informado con las últimas noticias y tendencias del momento <strong style="color: #73dfd8;font-size: 1.1em;">•</strong><strong style="color: #63d5e0;font-size: 1.1em;">•</strong><strong style="color: #00a7ce;font-size: 1.1em;">•</strong></p>
                <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 50px 6.7em;background: #f4f4f4;">
                    <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 33%;align-items: center;">
                        <a href="https://lideart.com.mx/catalogo/155/lo-m%C3%A1s-nuevo" target="_blank">
                            <img src="/assets/img/codigo-qr/08-Botón-Landingpage.webp" alt="" style="width: 97%;height: auto;">
                        </a>
                    </div>
                    <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 33%;align-items: center;">
                        <a href="https://lideart.com.mx/catalogo/2315/back-to-school" target="_blank">
                            <img src="/assets/img/codigo-qr/09-Botón-Landingpage.webp" alt="" style="width: 97%;height: auto;">
                        </a>
                    </div>
                    <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 33%;align-items: center;">
                        <a href="https://lideart.com.mx/blog" target="_blank">
                            <img src="/assets/img/codigo-qr/10-Botón-Landingpage.webp" alt="" style="width: 97%;height: auto;">
                        </a>
                    </div>
                </div>
            </div>
            <p class="regular-text" style="font-size: 2.5em;color: #939497;padding: 0em 4em; text-align: center;">Conoce las <strong style="font-size: 1.3em;">HISTORIAS de ÉXITO</strong> de algunos clientes con quienes hemos tenido el privilegio de colaborar.</p>
            <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 50px 30em;">
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 50%;align-items: center;">
                    <a href="https://youtu.be/ENz-SKY0YVY?si=gnThLFK1yMaF0CMT" target="_blank">
                        <img src="/assets/img/codigo-qr/Landingpage-QR-Lideart-paola.webp" alt="" style="width: 97%;height: auto;">
                    </a>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;width: 50%;align-items: center;">
                    <a href="https://youtu.be/7hPQO2QWL7o?si=BX3Rc6lmbI_gxkDP" target="_blank">
                        <img src="/assets/img/codigo-qr/Landingpage-QR-Lideart-nayeli.webp" alt="" style="width: 97%;height: auto;">
                    </a>
                </div>
            </div>
            <p class="regular-text" style="font-size: 2.5em;color: #939497;padding: 0em 4em; text-align: center;">
                Tenemos para ti los catálogos digitales de tus marcas favoritas
            </p>
            
            <div class="banner-container-d" style="text-align: center;height: auto;display: flex;justify-content: space-between;align-items: center;padding: 0px 6.7em;">
                
                <div class="banner-container-2" style="flex-direction: column;text-align: center;height: auto;display: inline-flex;justify-content: flex-start;gap: 0.1em;width: 20%;">
                    <a href="https://heyzine.com/flip-book/9716bc4a6f.html">
                        <img src="/assets/img/codigo-qr/CRICUT.webp" alt="" style="width: 90%;height: auto;">
                    </a>
                    <span class="regular-text-d2" style="color: #939497;text-align: center;font-size: 1.5em;margin-top: -1em; margin-left: -151px;">| Cricut</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: center;height: auto;display: inline-flex;justify-content: flex-start;gap: 0.1em;width: 20%;">
                    <a href="https://heyzine.com/flip-book/5fa36e6d45.html">
                        <img src="/assets/img/codigo-qr/SIZZIX.webp" alt="" style="width: 90%;height: auto;">
                    </a>
                    <span class="regular-text-d2" style="color: #939497;text-align: center;font-size: 1.5em;margin-top: -1em; margin-left: -149px;">| Sizzix</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: center;height: auto;display: inline-flex;justify-content: flex-start;gap: 0.1em;width: 20%;">
                    <a href="https://heyzine.com/flip-book/1f512685b7.html">
                        <img src="/assets/img/codigo-qr/DECO-FOIL.webp" alt="" style="width: 90%;height: auto;">
                    </a>
                    <span class="regular-text-d2" style="color: #939497;text-align: center;font-size: 1.5em;margin-top: -1em; margin-left: -115px;">| Deco foil</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: center;height: auto;display: inline-flex;justify-content: flex-start;gap: 0.1em;width: 20%;">
                    <a href="https://heyzine.com/flip-book/32931f82e3.html">
                        <img src="/assets/img/codigo-qr/LUMINOS.webp" alt="" style="width: 90%;height: auto;">
                    </a>
                    <span class="regular-text-d2" style="color: #939497;text-align: center;font-size: 1.5em;margin-top: -1em; margin-left: -115px;">| Luminos</span>
                </div>
                <div class="banner-container-2" style="flex-direction: column;text-align: center;height: auto;display: inline-flex;justify-content: flex-start;gap: 0.1em;width: 20%;">
                    <a href="https://heyzine.com/flip-book/155556e50b.html">
                        <img src="/assets/img/codigo-qr/QUELLI.webp" alt="" style="width: 90%;height: auto;">
                    </a>
                    <span class="regular-text-d2" style="color: #939497;text-align: center;font-size: 1.5em;margin-top: -1em; margin-left: -149px;">| Quelli</span>
                </div>
            </div><br>

            <p class="regular-text" style="font-size: 2.5em;color: #939497;padding: 0em 4em; text-align: center;">En nuestra página encontraras marcas como:</p>
            <div class="banner-container-d" style="padding: 50px 15em;">
                <swiper-container space-between="10" slides-per-view="6" navigation="true" id="swiperDesk">
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-75.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-76.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-77.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-78.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-79.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-80.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-50.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-51.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-52.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-53.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-54.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-55.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-56.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-57.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-58.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-59.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-60.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-61.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-62.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-63.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-64.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-65.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-66.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-67.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-68.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-69.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-70.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-71.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-72.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-73.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                    <swiper-slide>
                        <img src="/assets/img/codigo-qr/Logos-para-carrusel-74.webp" alt="" style="width: 97%; height: auto;">
                    </swiper-slide>
                </swiper-container>
            </div>
        </div>
    </div>
</section>