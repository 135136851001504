import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [
    IonicModule
  ],
  selector: 'app-conoce-mas-de-luminos',
  templateUrl: './conoce-mas-de-luminos.component.html',
  styleUrls: ['./conoce-mas-de-luminos.component.scss'],
})
export class ConoceMasDeLuminosComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Conoce mas sobre Luminos");
  }

}
