
<section class="distribuidorDesk">
  <div class="dist-cont">
    <div class="taitl-cont">
      <span class="taitl"></span>
    </div>
    <div class="info-cont">
      <div class="cont-form">
        <div class="signupFrm">
          <div action="" class="form">
            <h1 class="title">Facturación</h1>

            <div class="inputContainer">
              <input type="text" [(ngModel)]="ov"   class="input" placeholder="a">
              <label for="" class="label">Folio de Compra</label>
            </div>

            <div class="inputContainer" >
              <select [(ngModel)]="metodoPago" class="input">
                <option value="01" selected="selected" >EFECTIVO</option>
                <option value="02">CHEQUE NOMINATIVO</option>
                <option value="03">TRANSFERENCIA ELECTRÓNICA DE FONDOS</option>
                <option value="04">TARJETA DE CRÉDITO</option>
                <option value="28">TARJETA DE DÉBITO</option>
                <option value="04-1" >TARJETA DE CREDITO 3 MSI</option>
                <option value="04-2" >TARJETA DE CREDITO 6 MSI</option>
                <option value="04-3" >TARJETA DE CREDITO 9 MSI</option>
                <option value="04-4" >TARJETA DE CREDITO 12 MSI</option>
            </select>
 
            </div>

            <div class="inputContainer">
              <input type="text" [(ngModel)]="rfc" class="input" placeholder="a">
              <label for="" class="label">RFC</label>
            </div>

            <div class="inputContainer">
              <input type="text" style="text-transform:uppercase" [(ngModel)]="nombre"  class="input"  placeholder="a">
              <label for="" class="label">Razón Social</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="codigoPostal" placeholder="a">
              <label for="" class="label">Codigo Postal</label>
            </div>

            <div class="inputContainer">

              <input type="text" [(ngModel)]="telefono"  class="input" placeholder="a">
              <label for="" class="label">Telefono</label>
            </div>

            <div class="inputContainer">

              <input type="text" [(ngModel)]="correo"  class="input" placeholder="a">
              <label for="" class="label">Correo Electrónico</label>
            </div>

           

            
            <div class="inputContainer">
              <select name="select" [(ngModel)]="usocdfi"   class="input">
                <option value="00" selected>USO CFDI</option>
                <option value="G01">G01 - Adquisición de mercancías</option>
                <option value="G02">G02 - Devoluciones, descuentos o bonificaciones</option>
                <option value="G03">G03 - Gastos en general</option>
                <option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</option>
                <option value="I04">I04 - Equipo de cómputo y accesorios</option>
                <option value="I08">I08 - Otra maquinaria y equipo</option>
                <option value="S01">S01 - Sin efectos fiscales</option>
              </select>
            </div>

            
            <div class="inputContainer">

              <select name="select" [(ngModel)]="regimen" class="input">
                <option value="00" selected>Regimen Fiscal</option>
                <option value="601">601 - General de Ley Personas Morales</option>
                <option value="603">603 - Personas Morales con Fines no Lucrativos</option>
                <option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                <option value="606">606 - Arrendamiento</option>
                <option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
                <option value="607">607 - Demás ingresos</option>
                <option value="607">607 - Consolidación</option>
                <option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                <option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
                <option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
                <option value="614">614 - Ingresos por intereses</option>
                <option value="615">615 - Régimen de los ingresos por obtención de premios</option>
                <option value="616">616 - Sin obligaciones fiscales</option>
                <option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                <option value="621">621 - Incorporación Fiscal</option>
                <option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                <option value="623">623 - Opcional para Grupos de Sociedades</option>
                <option value="624">624 - Coordinados</option>
                <option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                <option value="626">626 - Régimen Simplificado de Confianza</option>
                <option value="628">628 - Hidrocarburos</option>
                <option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
                <option value="630">630 - Enajenación de acciones en bolsa de valores</option>
              </select>
            </div>
            <div class="inputContainer" *ngIf=" sisearmo ==1">
                  <p style="color:green;font-weight:bold; font-size:20px">{{mensaje}}</p>
            </div>

            <div class="inputContainer" *ngIf=" error ==1">
              <p style="color:rgb(255, 2, 2);font-weight:bold; font-size:20px">Datos fiscales incorrectos</p>
        </div>

            <input type="button" class="submitBtn" *ngIf="facturameee == 1" (click)="facturar()"  value="Solicitar Factura">
            <!-- <input type="button" class="submitBtn" *ngIf="facturameee2 == 1" (click)="facturar2()"  value="Solicitar Factura"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>