import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ApiProductosService } from '../../services/api-productos.service';
import { Usuario } from '../../Models/usuario';

@Component({
  selector: 'app-mesa-regalos-tipo',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ClipboardModule
  ],
  templateUrl: './mesa-regalos-tipo.component.html',
  styleUrls: ['./mesa-regalos-tipo.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  provideNativeDateAdapter()
  ],
  encapsulation: ViewEncapsulation.None
})
export class MesaRegalosTipoComponent implements OnInit {

  public id: any;

  // Formulario
  // Paso 1
  public nombreCompleto: string = '';
  public direccion: string = '';
  public ciudad: string = '';
  public codigoPostal: string = '';
  public estado: string = '';
  public telefonoCelular: string = '';
  public correoElectronico: string = '';
  public colonia: string = '';
  public fileName: string = '';

  // Paso 2
  public nombreEvento: string = '';
  public fechaEvento: string = '';
  public mensajeInvitados: string = '';

  // paso 3
  public banderaFormularioDireccion: boolean = false;
  public otraDireccion: string = '';
  public otraCiudad: string = '';
  public otroCodigoPostal: string = '';
  public otroEstado: string = '';
  public fechaEventoDos: string = '';
  public miDireccion: string = '';

  usuario!: Usuario;
  // paso 4
  public aceptadoContrato: boolean = false;

  @ViewChild('formulario_crear_mesa_regalos_cont') formulario_crear_mesa_regalos_cont!: ElementRef<HTMLInputElement>;
  public formulario_crear_mesa_regalos_contIs: boolean = false;

  @ViewChild('quada') quada!: ElementRef<HTMLInputElement>;
  public quadaIs: boolean = false

  @ViewChild('msg_no_completado') msg_no_completado!: ElementRef<HTMLInputElement>;
  @ViewChild('msg_no_completado_paso_dos') msg_no_completado_paso_dos!: ElementRef<HTMLInputElement>;
  @ViewChild('msg_no_completado_paso_tres') msg_no_completado_paso_tres!: ElementRef<HTMLInputElement>;
  @ViewChild('msg_no_completado_paso_cuatro') msg_no_completado_paso_cuatro!: ElementRef<HTMLInputElement>;
  @ViewChild('step_1_cont') step_1_cont!: ElementRef<HTMLInputElement>;
  @ViewChild('step_2_cont') step_2_cont!: ElementRef<HTMLInputElement>;
  @ViewChild('step_3_cont') step_3_cont!: ElementRef<HTMLInputElement>;
  @ViewChild('step_4_cont') step_4_cont!: ElementRef<HTMLInputElement>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    private usuarioService: LoginService,
    private productosService: ApiProductosService
  ) { }

  ngOnInit() {
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('foo')
    }
    document.body.scrollTop = 0;
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    })

    this.usuarioService.usuario.subscribe(res => {
      if (!res) return
      this.usuario = res;
      if (this.usuario) {
        this.correoElectronico = this.usuario.usuario
      }
    });
  }

  handleForm() {
    if (this.formulario_crear_mesa_regalos_contIs === false) {
      this.renderer.setStyle(this.formulario_crear_mesa_regalos_cont.nativeElement, 'display', 'flex');

      this.formulario_crear_mesa_regalos_contIs = true;
    } else if (this.formulario_crear_mesa_regalos_contIs === true) {
      this.renderer.setStyle(this.formulario_crear_mesa_regalos_cont.nativeElement, 'display', 'none');

      this.formulario_crear_mesa_regalos_contIs = false;
    }
  }

  paso2() {
    if (this.nombreCompleto === '' || this.direccion === '' || this.ciudad === '' || this.codigoPostal === '' || this.estado === '' || this.telefonoCelular === '') {
      this.renderer.setStyle(this.msg_no_completado.nativeElement, 'left', 'calc(50% - 45%)');

      setTimeout(() => {
        this.renderer.setStyle(this.msg_no_completado.nativeElement, 'left', '-100%');
      }, 2000);

      return;
    }

    this.renderer.setStyle(this.step_1_cont.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.step_2_cont.nativeElement, 'display', 'flex');
  }

  paso3() {
    if (this.nombreEvento === '' || this.fechaEvento === '' || this.mensajeInvitados === '') {
      this.renderer.setStyle(this.msg_no_completado_paso_dos.nativeElement, 'left', 'calc(50% - 45%)');

      setTimeout(() => {
        this.renderer.setStyle(this.msg_no_completado_paso_dos.nativeElement, 'left', '-100%');
      }, 2000);

      return;
    }

    this.renderer.setStyle(this.step_2_cont.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.step_3_cont.nativeElement, 'display', 'flex');
  }

  paso4() {

    this.renderer.setStyle(this.step_2_cont.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.step_4_cont.nativeElement, 'display', 'flex');
    // if (this.banderaFormularioDireccion == false) {
    //   if (this.miDireccion === '' || this.fechaEventoDos === '') {
    //     this.renderer.setStyle(this.msg_no_completado_paso_tres.nativeElement, 'left', 'calc(50% - 45%)');

    //     setTimeout(() => {
    //       this.renderer.setStyle(this.msg_no_completado_paso_tres.nativeElement, 'left', '-100%');
    //     }, 2000);

    //     return;
    //   }

    //   this.renderer.setStyle(this.step_3_cont.nativeElement, 'display', 'none');
    //   this.renderer.setStyle(this.step_4_cont.nativeElement, 'display', 'flex');

    // } else if (this.banderaFormularioDireccion == true) {
    //   if (this.fechaEventoDos === '' || this.otraDireccion === '' || this.otraCiudad === '' || this.otroCodigoPostal === '' || this.otroEstado === '') {
    //     this.renderer.setStyle(this.msg_no_completado_paso_tres.nativeElement, 'left', 'calc(50% - 45%)');

    //     setTimeout(() => {
    //       this.renderer.setStyle(this.msg_no_completado_paso_tres.nativeElement, 'left', '-100%');
    //     }, 2000);

    //     return;
    //   }

    //   this.renderer.setStyle(this.step_3_cont.nativeElement, 'display', 'none');
    //   this.renderer.setStyle(this.step_4_cont.nativeElement, 'display', 'flex');
    // }
  }

  finalizar() {
    if (this.quadaIs == false) {

      this.renderer.setStyle(this.msg_no_completado_paso_cuatro.nativeElement, 'left', 'calc(50% - 45%)');

      setTimeout(() => {
        this.renderer.setStyle(this.msg_no_completado_paso_cuatro.nativeElement, 'left', '-100%');
      }, 2000);

      return;
    }

    //this.handleForm();
    var numeroAleatorio = Math.floor(Math.random() * (1 - 10000000 + 1)) + 1;
    var letraaas = Math.random().toString(36).slice(2, 7);
    var token = letraaas + "-" + numeroAleatorio;

    let JsonRegistraMesa = {
      "referencia": token,
      "nombreCompleto": this.nombreCompleto,
      "direccion": this.direccion,
      "ciudad": this.ciudad,
      "codigoPostal": this.codigoPostal,
      "estado": this.estado,
      "telefonoCelular": this.telefonoCelular,
      "correoElectronico": this.correoElectronico,
      "nombreEvento": this.nombreEvento,
      "fechaEvento": this.fechaEvento,
      "mensajeInvitados": this.mensajeInvitados,
      "colonia": this.colonia

    }
    this.usuarioService.registraMesa(JsonRegistraMesa).subscribe(datillos => {
      this.router.navigateByUrl(`/mesa-regalos-construct`);
    })


  }

  aceptarContrato() {
    if (this.quadaIs == false) {
      this.renderer.setStyle(this.quada.nativeElement, 'background-color', '#00a0c6');

      this.quadaIs = true;
    } else if (this.quadaIs == true) {
      this.renderer.setStyle(this.quada.nativeElement, 'background-color', '#fff');

      this.quadaIs = false;
    }
  }

  activarFormDireccion() {
    this.banderaFormularioDireccion = true;
  }

  desactivarFormDireccion() {
    this.banderaFormularioDireccion = false;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.fileName = input.files[0].name;
    }
  }

}
