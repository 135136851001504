
ion-button {
  --box-shadow: transparent !important;
}



.cont{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //padding: .5rem;
  width: 100%;
  min-height: 100%;
  //background: #000;
}

.cont-categoria-cel{
  //--ion-background-color: url('../../../assets/img/categ.png'); //rgb(189, 193, 194) !important 
background-color: white;
  /*--ion-background-color: rgb(4,215,218); //rgb(189, 193, 194) !important
  --ion-background-color: radial-gradient(circle, rgba(4,215,218,1) 23%, rgba(45,166,253,1) 82%);*/
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  
  
  ion-card{
    //background: rgba(255, 255, 255, 0.164); //     #fdfdfd            #14A8CD*
    //backdrop-filter: blur(2px);
    /*background: rgb(28,138,242);
    background: linear-gradient(0deg, rgba(28,138,242,1) 47%, rgba(45,166,253,1) 47%); //     #fdfdfd            #14A8CD*/
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border: none !important;
    box-shadow: none;
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    //box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    width: 100%;
    height: 68%;
    margin: 0px !important;
    //border-radius: .25rem;
    //  padding: 1rem;
    // padding-bottom: 5px;

    /* From https://css.glass */
    //background: rgba(255, 255, 255, 0.150);
    //border-radius: 10px;
    //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    //backdrop-filter: blur(3px);
    //-webkit-backdrop-filter: blur(3px);
    //border: .5px solid rgba(0, 0, 0, 0.082);

    .contImg{
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;

      img{
        width: 80%;
        height: auto;
      }
    }



    .text{
      width: 100%;
      height: 120%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      ion-text{
        width: 100%;
        font-size: 1.2em;
        text-align: center;
        color:rgb(150, 140, 140);
        text-transform: uppercase;
        font-weight: normal;
       
      }

    }

  }
  

}

ion-col {
  flex: unset !important;
  max-width: unset !important;
  width: 50% !important;
}

ion-card::after{
  content: "";
  display: block;
  width: 100%;
  height: 1px; 
  background-color: #89daf3;
  position: absolute;
  bottom: 0;
  left: 0;
}

.categoriaDesk{
  margin-top: 100px;
  width: 100%;
  height: auto;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 15em;
  //overflow-y: scroll;

  .contDesk{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 2em 0em;
    gap: 2em;

    .title-cont{
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
  
      h1{
        color: #1e7285;
        font-weight: 100;
        font-size: 2.2em;
        text-align: center;
      }
    }

    .conten-cats{
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2em;

      .cardin{
        width: 22em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 1em 0em;
        border-bottom: 1px solid #0ba7c7;
        transition: .4s all ease-in-out;
        gap: 1em;

        .cat-name{
          color: #00a3c7;
          font-weight: 100;
          text-align: center;
          padding: 0em 1em;
        }

        .contImg{
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1em;
        }
      }

      .cardin:hover{
        border-bottom: 1px solid #0ba8c700;
        box-shadow: rgba(23, 171, 202, 0.5) 0px 6px 24px;
      }
    }
  }
}

@media(min-width:992px){
  .display-grid{
    display: grid;
    grid-template-columns: repeat(1,1fr);   
   
  }  

  .cont-categoria-cel{
    display: none;
  }

  ion-header{
    display: none;
  }

  .categoriaDesk{
    display: flex;
  }
}


.pp{
  width: 100%;
  height: 100%;
  padding: .25rem;
 
}

.categoriaDesk::-webkit-scrollbar {
  width: .35em;
  background-color: transparent;
  height: .7em;
}

.categoriaDesk::-webkit-scrollbar-thumb {
  background-color: #00a2c779;
  //border-radius: 1vh;
  width: 0.25em;
}

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: fixed;
  top: calc(0%);
  left: calc(0%);
  background-color: rgb(255, 255, 255);
  opacity: 0.9;

  img{
    width: 200px;
    height: auto;

  }

}

.container-cat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5rem;
}

@media screen and (max-width: 600px) {
  .container {
    margin: -0.5rem;
  }
}

.card-categoria {
  background-color: #f5f8fc;
  -webkit-box-shadow: 0 0 transparent, 0 0 transparent, 0 0.375rem 0.375rem -0.125rem rgba(168, 179, 207, 0.4);
          box-shadow: 0 0 transparent, 0 0 transparent, 0 0.375rem 0.375rem -0.125rem rgba(168, 179, 207, 0.4);
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(82, 88, 102, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 330px;
  height: auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin: 2rem;
}

.card__header {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0.5rem 0 0.5rem 0.5rem;
}

.card__header .header__img {
  height: 2rem;
  width: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.card__header .header__title {
  font-size: 1.0625rem;
  line-height: 1.375rem;
  color: #0e1217;
  font-weight: 700;
  margin: 0.5rem;
}

.card__body {
  margin: 0 0.5rem;
}

.card__body .body__text {
  color: #525866;
  font-size: 0.8125rem;
}

.card__body .body__img {
  height: 10rem;
  margin: 0.5rem 0;
}

.card__body .body__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: auto;
  border-radius: 0.75rem;
}

.card__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.5rem;
}

.card:hover {
  border-color: rgba(82, 88, 102, 0.4);
}

ion-icon {
  font-size: 1.5rem;
  color: #525866;
  cursor: pointer;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

ion-icon:hover:nth-of-type(1) {
  color: #15ce5c;
}

ion-icon:hover:nth-of-type(2) {
  color: #15ce5c;
}

ion-icon:hover:nth-of-type(3) {
  color: #fa6620;
}

.skeleton {
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate;
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

ion-card {
  // Otros estilos para ion-card

  @media (max-width: 992px) and (min-width: 441px) {
    height: 100% !important;
  }

  @media (max-width: 440px) {
    height: 68% !important;
  }
}