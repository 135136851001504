<section class="mi-lista-cont" style=" margin-top: 100px;">
  <div class="fix-width">
    <div class="banner-cont-construct">

      <div class="right-ban-cont">
        <img src="assets/img/mesa-regalos/banner-mi-lista.webp" alt="">
        <div class="mesa-txts">
          <span class="up-text">
            Evento:
            <span class="boldin">{{nombre_evento}}</span>
          </span>
          <span class="down-text">
            Mensaje:
            <span class="boldin">{{mensaje}}</span>
          </span>
        </div>
      </div>
    </div>

    <div>
      <button [routerLink]="['/mesa-regalos-construct']"
        class="bg-teal-400 text-white font-bold py-3 px-8 rounded-lg shadow-lg hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-opacity-50 w-full sm:w-auto mx-auto block">
        Añadir Más Regalos
      </button>

    </div>

    <!-- <div class="swipers-cont">
      <div class="rowin">
        <div class="prod-cont" *ngFor="let item of regalillos">
          <div class="prod-img-cont">
            <img src="{{item.image_link}}" alt="">
          </div>
          <span class="sku">{{item.sku}}</span>
          <span class="name">
            {{item.title}}
          </span>
      
          <div class="caja-botones">
            <input type="number" value="{{item.quantity}}" class="inp-numb" disabled>
            <button class="btn-add" (click)="confirmarCompra(item.id_product)">Comprar</button>
          </div>
        </div>
      </div>

    </div> -->


    <div class="swipers-cont">

      <div class="rowin">
        <!-- <div class="logo-cont">
                <img src="{{url}}/71/1.webp" alt="">
            </div> -->
        <span class="title-construct"></span>
        <swiper-container navigation="true" space-between="10" id="swiperMarcasProductos"
          breakpoints="{&quot;992&quot;:{&quot;slidesPerView&quot;:4}}">
          <swiper-slide *ngFor="let item of regalillos;let i=index">
            <div class="prod-cont">
              <div class="prod-img-cont">
                <img src="{{item.image_link}}" alt="">
              </div>
              <span class="sku">{{item.sku}}</span>
              <span class="name">
                {{item.title}}
              </span>
              <!-- <span class="price">{{item.precio | currency}}<span class="currency">MXN</span></span> -->
              <div class="caja-botones">
                <input type="number" min="1" value="{{item.quantity}}" class="inp-numb" disabled>
                <button *ngIf="item.comprado ==0" class="btn-add">Pendiente</button>
                <span *ngIf="item.comprado ==1" class="btn-add">Comprado</span>
              </div>
            </div>
          </swiper-slide>

        </swiper-container>
      </div>

    </div>
  </div>
</section>

<div class="modal-c-wp" #modal_wp id="modal_wp">
  <div class="btn-wp-cont">
    <button class="btn-wp-close" (click)="handleWP()">Cerrar</button>
  </div>
  <div style="min-height: 15em;" *ngIf="cargandoProductoInfo ==1">
    <!-- <div class="cont-back-btn">
          <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
        </div> -->
    <div class="columnas">
      <ion-row>
        <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
          <ion-card *ngFor="let producto of productoData"
            style="box-shadow: none;border: none; -webkit-margin-start: 0px; margin-inline-start: 0px;">
            <!-- <div *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></div> -->
            <!-- <ion-text *ngIf="cargandoProductoInfo == 0" class="skeleton skeleton-text"></ion-text>  -->


            <ion-text *ngIf="cargandoProductoInfo == 1" class="titulillo">{{producto.title}}</ion-text>

            <div class="swiper-container-wrapper">

              <div class="swiper-container" id="swiperEscritorio">
                <img src="{{producto.image_link}}" alt="">
              </div>
            </div>


          </ion-card>
        </ion-col>



        <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;">
          <ion-card *ngFor="let producto of productoData"
            style="box-shadow: none; border:none; overflow: unset; -webkit-margin-start: 0px; margin-inline-start: 0px;">
            <ion-card-content>
              <ion-grid>
                <ion-row>

                  <ion-col *ngIf="cargandoPrecio == 0">
                    <ion-list>
                      <ion-item>
                        <ion-label>
                          <h3>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                          </h3>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-col>



                  <ion-col *ngIf="!descuentillo && cargandoPrecio == 1 && precioDyn >0">
                    <ion-text class="precio">${{precioDyn | number: '1.2-2'}}</ion-text>
                    <span
                      style="font-size: 14px;color: rgb(29, 171, 209);font-family: 'RobotoCondensed-Light';text-transform: uppercase;">
                      mxn </span>
                  </ion-col>

                  <ion-col *ngIf="!descuentillo && cargandoPrecio == 1 && precioDyn ==0">
                    <ion-text class="precio" style="color: red;">No Disponible</ion-text>

                  </ion-col>



                  <ion-col *ngIf="descuentillo && cargandoPrecio == 1 && precioDyn >0">

                    <ion-text class="text">${{precioDyn*descuento | number: '1.2-2'}} <del>${{precioDyn | number:
                        '1.2-2'}}mxn </del> - {{resdesc*100 | number: '1.2-2'}}% de descuento</ion-text>

                  </ion-col>

                  <ion-col *ngIf="descuentillo && cargandoPrecio == 1 && precioDyn ==0">
                    <ion-text class="precio" style="color: red;">No Disponible</ion-text>

                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-text style="font-size: 12px; font-family: 'RobotoCondensed-Light';color: rgb(51,51,51);">IVA
                      incluido</ion-text>
                  </ion-col>
                </ion-row>

                <div
                  style="border-top: 1px solid #eaeaea;border-bottom: 1px solid #eaeaea; color: #333333; padding-top: 12px;padding-bottom: 12px;font-size: 18px;">
                  <div>
                    <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                      <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.2em 0.5em;">Código</dt>
                      <dt id="referencia"
                        style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; color: #111; font-size: .9em; padding: 0.2em 0.5em;">
                        {{ producto.sku }}</dt>
                    </dl>
                    <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                      <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.2em 0.5em;">
                        Color
                      </dt>
                      <dt
                        style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; color: #111; font-size: .9em; position: relative;"
                        class="line-tb">
                        {{colorSeleccionado}}

                        <div class="r-color" style="background-color: {{varColorSelected}};"></div>
                        <div class="r-color" style="background-image: url ({{varColorSelected}})"></div>
                      </dt>
                    </dl>
                  </div>

                </div>

                <ion-row>
                </ion-row>
                <ion-row id="existencia">
                  <ion-col>
                    <ion-text class="text">Existencia:</ion-text>
                  </ion-col>
                  <ion-col>
                    <ion-col *ngIf=" cargandoInventario == 0">
                      <ion-list>
                        <ion-item>
                          <ion-label>
                            <h3>
                              <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            </h3>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </ion-col>

                    <ion-text class="text" *ngIf="existencias>0 && cargandoInventario == 1"
                      style="color:green">Disponible</ion-text>
                    <ion-text class="text" *ngIf="existencias<=0 && cargandoInventario == 1"
                      style="color:red">Agotado</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row id="existenciaDesk">
                  <div class="conten-exist">

                    <span class="lefti">Existencia:</span>
                    <ion-col *ngIf=" cargandoInventario == 0">
                      <ion-list>
                        <ion-item>
                          <ion-label>
                            <h3>
                              <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
                            </h3>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                    <span class="rigti" *ngIf="existencias>0 && cargandoInventario == 1"
                      style="color:green">Disponible</span>
                    <span class="rigti" *ngIf="existencias<=0 && cargandoInventario == 1"
                      style="color:red">Agotado</span>
                  </div>
                </ion-row>
                <ion-row id="colorSeleccionado">
                  <ion-col>
                    <ion-text class="text">Color Seleccionado:</ion-text>
                  </ion-col>
                  <ion-col>
                    <p class="text"><span>{{colorSeleccionado}}</span></p>
                  </ion-col>
                </ion-row>
                <ion-row>

                  <div *ngFor="let atributo of atributos" style="width: 100%;">
                    <ion-col>
                      <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                    </ion-col>
                    <ion-col>
                      <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop">
                        <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                          class="selector-color id_group_{{atributo.id_attribute_group}}">
                          <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)"
                            style="background-color: {{valor.color}}">
                          <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.image)"
                            style="background-image: url({{valor.image}});">

                          <div class="preview">
                            <div class="diva-color-p" style="background-color: {{valor.color}};"></div>
                            <span class="name-color-p">{{valor.name}}</span>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop-list">
                        <div class="conten-names-opts">
                          <button class="btn-list-opt" *ngFor="let valor of atributo.valores"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)">{{valor.name}}</button>
                        </div>
                      </div>

                      <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos"
                        (change)="cambiaSku()" class="form-group {{atributo.id_attribute_group}}">
                        <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}
                        </option>
                      </select>
                    </ion-col>
                  </div>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div>

                    </div>
                  </ion-col>
                  <ion-col>
                  </ion-col>

                </ion-row>



                <!-- <button id="irCarrito" routerLink="/cart"  style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;" >Ir a Carrito</button> -->

                <!-- <button style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;" *ngIf="usuario" id="wishlist" (click)="agregarWishlist()" >Agregar a Wishlist</button> -->

              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col size="12" size-md="6" size-lg="4">
          <ion-card *ngFor="let producto of productoData"
            style="box-shadow: none;border: none; height: min-content; -webkit-margin-start: 0px; margin-inline-start: 0px;">
            <ion-row>
              <ion-col>
                <ion-label style="font-family: 'RobotoCondensed-Light'; font-weight: bold;">
                  CANTIDAD
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>
                  <ion-item>
                    <input type="number" min="1" [(ngModel)]="cantidad" name="cantidad" class="inp-manage">
                  </ion-item>
                </ion-label>
              </ion-col>
            </ion-row>

            <div *ngIf="existencias==0" class="nohay-cont">
              <span class="textin">Producto Agotado</span>
              <i class="fa-solid fa-face-sad-tear"></i>
            </div>
            <br>
            <button
              style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
              *ngIf="existencias>0 && cargoprecio == true && precioDyn>0" id="addCarrito"
              (click)="comprarRegalo(productoData[0])">Comprar Regalo</button>

          </ion-card>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>