import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  /* Variables para administrar arreglo de componente categorias */
  private selectedCategoria: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedCate$: Observable<any> = this.selectedCategoria.asObservable();

  /* Variables para administrar arreglo de componente catalogo */
  private selectedCatalogo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedCata$: Observable<any> = this.selectedCatalogo.asObservable();

  constructor() {}

  setCategoria(product : any) {
    this.selectedCategoria.next(product);
  }
  
  setCatalogo(product : any) {
    this.selectedCatalogo.next(product);
  }
}
