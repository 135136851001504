import { Component, OnInit } from '@angular/core';
import { FormsModule, UntypedFormBuilder } from '@angular/forms';
import { IonicModule, ModalController, ToastController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [
    IonicModule,
    FormsModule
  ],
  selector: 'app-modal-recuperar',
  templateUrl: './modal-recuperar.component.html',
  styleUrls: ['./modal-recuperar.component.scss'],
})
export class ModalRecuperarComponent  implements OnInit {
  nombre:any;
  apellido: any;
  link: any;
  datos: any =[];
  correito:any;
  estoycargando =false;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private contactoService: LoginService,
    public toastController: ToastController,
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Recuperar contraseña');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }



  sendRequest(){
    this.titleService.setTitle("Recuperar Contraseña");
    this.estoycargando = true;
    this.contactoService.requestReset(this.correito).subscribe(data =>{
      this.datos = data;

      if (this.datos == null || this.datos == undefined || this.datos == "") {
        this.Errorsito();
        this.estoycargando = true;
      }else{
        this.nombre = this.datos.nombre;
        this.apellido = this.datos.lastname;
        this.link = this.datos.link;
        this.correito = this.datos.correo;
        var lineasCorreo: any[] = []
    
        lineasCorreo.push(
          {
            "nombreCliente": this.nombre+ " "+this.apellido,
            "correoCliente": this.correito,
            "link": this.link
          
          }
        )
        this.contactoService.EnviaCorreoRecuperaPassword(lineasCorreo[0]).subscribe(data =>{
         
          this.estoycargando = false;
          this.Success();
        this.correito ="";
        })
 
      }



      
    })


  }


  async Errorsito() {
    const toast = await this.toastController.create({
      message: 'Correo no registrado',
      color: "danger",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }

  async Success() {
    const toast = await this.toastController.create({
      message: 'Verifica tu correo electronico y sigue los pasos para recuperar tu contraseña',
      color: "success",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }

}
