<section class="wishDesk" style=" margin-top: 100px;">
  <div class="wishCont">
    <div class="taitl-cont">
      <img src="/assets/img/mesa-regalos/registro-01.webp">
    </div>
    <div>
      <h1>¡¡Felicidades!! Nos alegra mucho tu valor y esfuerzo.</h1>
      <p>Crea tu mesa de regalos perfecta para que emprendas con el pie derecho.</p>

    </div>


  </div>
</section>