<section class="nosotrosDesk">
  <div class="conten-cont">
    <div class="taitl-cont">
      <span class="taitl">Nosotros</span>
    </div>
    <span class="filo">Filosofía <img src="assets/img/logo-lide.png" alt=""></span>
    <div class="separt">
      <span class="subts">Nuestra historia</span>
      <span class="texto">
        Avance (nuestra empresa hermana) se presentó en dos exposiciones de manualidades en 2014 para mostrar los productos que tenía para ese mercado, 
        ambas participaciones fueron bajo el concepto de Manualidades Creativas, gracias a la aceptación y actitud emprendedora que se observó en los clientes, 
        se decide avanzar un paso más para ese mercado, adaptar el concepto al comercio electrónico, "Manualidades Creativas, Tú negocio online” especializado en venta 
        de materiales y equipos para manualidades por medio de una página web.
      </span>
      <span class="texto">
        Con la finalidad de integrarnos a un mercado moderno, innovador, vanguardista, unisex, emprendedor, joven, y creativo; pasamos por un proceso 
        de trasformación que nos llevó a “Lideart, Proveedor líder en equipos de vanguardia y materiales para manualidades, proyectos DIY o negocios emprendedores.”
      </span>
      <span class="texto">
        Sabemos que estamos en un mundo de constantes cambios, el tiempo en el que caduca la tecnología cada vez es más corto, nuestra responsabilidad es ofrecerle al 
        cliente innovación, acercarles productos de calidad que no son tan accesibles en una tienda física incluso que aún no han llegado al mercado local, nuestra 
        responsabilidad es ser la mejor opción.
      </span>
    </div>
    <div class="separt">
      <span class="subts">¿Quiénes somos?</span>
      <span class="texto">
        Somos una empresa de productos para manualidades innovadora con visión al futuro, buscamos siempre ser la mejor alternativa de compra para nuestros clientes 
        manteniendo capacitado a nuestro personal a través de diferentes técnicas, logrando así tener expertos en nuestro equipo, promovemos abiertamente el trabajo 
        en equipo y hacemos partícipe a todos nuestros empleados en las actividades laborales. Somos una empresa en proceso de crecimiento por lo tanto con constantes 
        cambios para lograr nuestros objetivos. En Lideart no es solo ofrecemos sustento a varias familias, sino un lugar para aprender, crecer y sobresalir; 
        nuestro principal objetivo es el servicio y así lo proyectamos día con día buscando también opciones para mejorar.
      </span>
      <span class="texto">
        Perseguimos una meta en común; satisfacer a nuestros clientes y hacerles la vida más fácil. Para eso trabajamos en equipo, nuestros esfuerzos están dirigidos 
        a multiplicar logros, sin dejar de lado nuestras necesidades, por eso estamos interesados que nuestros empleados tengan una actitud positiva referente a sus 
        actividades laborales, preocupándonos porque el tiempo y energía que invierten en su trabajo los haga sentir satisfechos, comprometidos con la organización, 
        estar orgullosos de ¡Ponerse la camiseta!
      </span>
      <span class="texto">
        Fomentamos en nuestros colaboradores humildad, hacemos conciencia en que trabajamos con personas que tanto ellas como nosotros nos ayudamos mutuamente a 
        alcanzar nuestros objetivos.
      </span>
      <span class="texto">
        Buscamos fomentar el crecimiento económico de nuestra comunidad, brindado inspiración, capacitación, servicio, soporte y los mejores productos a cualquiera 
        que busque crear o crecer su propio negocio. Apoyamos a cualquier persona que busque emprender, ya sea que no tengan mucho tiempo o que busquen hacerlo desde 
        la comodidad de su hogar, haciendo algo que en realidad disfruten.
      </span>
    </div>
    <div class="separt">
      <span class="subts">Misión</span>
      <span class="texto">
        Satisfacer las necesidades de nuestra sociedad en productos de manualidades brindando una solución de sus requerimientos mediante una compra en línea. 
        Inspirando y brindando orientación a personas que deseen iniciar o crecer su negocio, ofreciendo no solo productos de calidad, innovadores y vanguardistas, 
        sino el mejor servicio, asesoría altamente competente, trasparencia y capacitación, dándole a nuestros clientes lo que necesitan, con la mejor calidad, rapidez, 
        seguridad y comodidad, siempre tomando en cuenta el sentido de responsabilidad y ética.
      </span>
    </div>
    <div class="separt">
      <span class="subts">Visión</span>
      <span class="texto">
        Ser los líderes en el mercado de las manualidades posicionándonos como la empresa más confiable, asegurando la satisfacción total de nuestros clientes y 
        subdistribuidores a través de productos innovadores con la mejor calidad y un servicio impecable, superando las expectativas de una compra en línea; 
        asimismo impulsar el emprendimiento y desarrollo económico de los mexicanos.
      </span>
    </div>
    <div class="separt">
      <span class="subts">Valores</span>
      <ul>
        <li><strong>Abrazar al cambio</strong> <br>Para crecer se necesita cambio, por eso estamos en constates cambios, siempre buscando mejorar en todos los aspectos.</li>
        <li><strong>Honestidad</strong> <br>Toda la información que otorguemos a nuestros colaboradores y clientes debe ser real, justificada, confiable y comprobable.</li>
        <li><strong>Confianza</strong> <br>Cultivaremos cada relación con credibilidad, nos comprometemos haciendo buen uso de los recursos que nos asignan, basándonos en la responsabilidad y respeto mutuo.</li>
        <li><strong>Respeto</strong> <br>Proveemos atención y cortesía, reconociendo el valor propio y derechos de todos nuestros clientes y equipo, siempre bajo un ambiente de amabilidad y aceptación.</li>
        <li><strong>Trabajo en equipo</strong> <br>Gracias a nuestro trabajo en equipo y compañerismo logramos llegar a nuestro fin en común, que es lograr brindar los mejores productos y servicio.</li>
        <li><strong>Compromiso</strong> <br>Nos enfocamos para el cumplimiento de nuestras metas y servicios, motivamos a nuestro equipo de trabajo a actuar con integridad.</li>
        <li><strong>Pasión</strong> <br>Nos apasionamos por lo que hacemos, sintiendo e inspirando pasión por medio de nuestro servicio y proyectos, luchando día a día por mejorar lo que ofrecemos.</li>
        <li><strong>Servicio</strong> <br>Brindamos una atención integral encaminada a superar las expectativas, buscando la satisfacción de todos nuestros clientes.</li>
        <li><strong>Innovación</strong> <br>No solo brindamos los productos más novedosos, también impulsamos nuevos modelos de negocio, ofrecemos nuevos servicios y mejoramos los procesos para mejorar en todos los aspectos como en la calidad y servicio que ofrecemos.</li>
      </ul>
    </div>
    <div class="separt">
      <span class="subts">Nuestros objetivos</span>
      <ul>
        <li>Ser la mejor opción para satisfacer las necesidades de nuestra sociedad en todo tipo de productos para manualidades.</li>
        <li>Brindar una solución de los materiales requeridos mediante una compra en línea con la máxima seguridad y calidad.</li>
        <li>Dar una experiencia excepcional por medio de nuestro servicio, innovación de productos y calidad, buscando crear una emoción positiva y la satisfacción de nuestro cliente</li>
        <li>Impulsar el desarrollo individual ofreciendo las mejores condiciones, productos y asesoría; así contribuir de manera importante al desarrollo económico y social de nuestra comunidad.</li>
        <li>Ayudar al desarrollo de nuestros subdistribuidores con el objetivo de lograr el máximo crecimiento en conjunto.</li>
      </ul>
    </div>
  </div>
</section>