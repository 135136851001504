import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experiencia-compra',
  standalone:true,
  templateUrl: './experiencia-compra.component.html',
  styleUrls: ['./experiencia-compra.component.scss'],
})
export class ExperienciaCompraComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
