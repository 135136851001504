<section class="wishDesk" style=" margin-top: 100px;">

    <h1 style="display: flex; align-items: center;justify-content: center;">Ingresa la dirección a la que se mandaran
        tus regalos</h1>
    <div class="modal-c-wp">
        <form [formGroup]="direccionesForm" class="form-horizontal account-register clearfix">

            <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="input-company">Alias</label>
                <div class="col-sm-10">
                    <input type="text" name="alias" formControlName="alias" placeholder="Alias de Dirección" class="form-control">
                </div>
            </div> -->

            <div class="form-group required">
                <label class="col-sm-2 control-label" for="address1">Dirección</label>
                <div class="col-sm-10">
                    <input type="text" name="address1" formControlName="address1" placeholder="Ingresa calle y numero"
                        class="form-control">
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label" for="address2">Dirección Complementaria</label>
                <div class="col-sm-10">
                    <input type="text" name="address2" formControlName="address2" placeholder="Complementa tu dirección"
                        class="form-control">
                </div>
            </div>
            <div class="form-group required">
                <label class="col-sm-2 control-label" for="phonenumber">Telefono</label>
                <div class="col-sm-10">
                    <input type="text" name="phonenumber" formControlName="phonenumber" placeholder="Ingresa Telefono"
                        class="form-control">
                </div>
            </div>

            <div class="form-group required">
                <label class="col-sm-2 control-label" for="zipcode">Codigo Postal</label>
                <div class="col-sm-10">
                    <input type="text" name="zipcode" formControlName="zipcode" placeholder="Ingresa Codigo Postal"
                        class="form-control"
                        (change)="buscameXCodigoPostal(this.direccionesForm.controls['zipcode'].value)">
                </div>
            </div>

            <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-zone">Provincia o Estado</label>
                <div class="col-sm-10">
                    <select name="id_state" class="form-control" formControlName="stateValue">
                        <option [value]="ciudadsinCodigo" selected>{{ciudadsinCodigo}}</option>
                    </select>
                </div>
            </div>

            <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-country">Ciudad</label>
                <div class="col-sm-10">
                    <select name="city" class="form-control" formControlName="cityValue">
                        <option [value]="ciudadSeleccionada" selected>{{ciudadSeleccionada}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-country">Colonia</label>
                <div class="col-sm-10">
                    <select name="colony" class="form-control" formControlName="colonyValue" (change)="imprimir()">
                        <option [value]="nombreColonia" selected>{{nombreColonia}}</option>
                        <option *ngFor="let item of arrayColonias" value="{{item.colonia+'-'+item.countyId}}">
                            {{item.colonia}}</option>

                    </select>
                </div>
            </div>

            <div class="buttons">
                <div class="pull-right">
                    <input *ngIf="notiene == 1" value="Guardar" type="button" (click)="nuevitaDireccion()"
                        class="btn btn-primary">
                    <input *ngIf="notiene == 0" value="Actualizar" type="button" (click)="guardaDireccion()"
                        class="btn btn-primary">
                </div>
            </div>
        </form>

    </div>
    <br>


</section>





<div class="loader" *ngIf="cargando">
    <img src="/assets/img/loadersillo.gif">
</div>