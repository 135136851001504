.ante-crear-cont{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .fix-width{
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 2em;
        padding: 2em 0em;

        .banner-cont{
            width: 100%;
            height: auto;
        }

        .titles-sect-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: .5em;

            .title-sect{
                color: #49a4b5;
                font-weight: 400;
                font-size: 1.8vw;
            }

            .subt-sect{
                color: #acacac;
                font-weight: 400;
                font-size: 1.2vw;
            }
        }

        .open-form-btn{
            background-color: #00a3c8;
            color: #fff;
            font-weight: 100;
            font-size: 1.8vw;
            border-radius: .2em;
            border: none;
            padding: .3em 2em;
            box-shadow: 0px 3px 10px 1px #0000004f;
            margin: 1em 0em;
        }

        .beneficios-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 1em;

            .title-beneficios{
                color: #ababab;
                font-weight: 400;
                font-size: 1.5vw;
            }

            .bens-contan-r{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                gap: 1em;

                .beneficio{
                    width: 14em;
                    height: 16vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 0.5em;

                    .img-ben{
                        height: auto;
                        width: 10vw;
                    }

                    .txt-ben{
                        color: #a9a9a9;
                        font-weight: 100;
                        font-size: 1.1vw;
                        text-align: center;
                    }
                }
            }
        }

        .datos-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title-datos{
                font-weight: 100;
                font-size: 1.6vw;
                border-bottom: 1px solid #000;
                width: 69%;
                padding-bottom: .5em;
                color: #000;
            }

            .desp-btn-datos{
                background-color: transparent;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: .5em 0em;
                color: #000;
                gap: 1em;
                font-size: 1.4vw;
                font-weight: 100;

                i{
                    font-size: .3vw;
                }
            }
        }
    }
}

.formulario-crear-mesa-regalos-cont{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #00000042;
    display: none;
    //display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .step-1-cont{
        background-color: #fff;
        width: 900px;
        height: 90%;
        border-radius: 1em;
        display: flex;
        //display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: relative;

        .ban-cont{
            width: 100%;
            height: auto;
            
            img{
                width: 100%;
                height: auto;
            }
        }

        .ovfw-y{
            width: 100%;
            height: 100%;
            overflow-y: auto;

            .txt-cont-pres{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding: 1.5em 0em;
    
                .title-1{
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    color: #00acce;
                    font-weight: 600;
                    font-size: 1.5em;
                }
    
                .desc-1{
                    color: #939393;
                    font-weight: 400;
                    font-size: 1em;
                    text-align: center;
                    width: 32em;
                }
    
                .obli-txt{
                    color: #ca212b;
                    font-weight: 900;
                    font-size: 1.2em;
                
                    .obli-txt-alt{
                        font-weight: 100;
                        color: #9d9d9d;
                        font-size: .9em;
                    }
                }
    
                .recuerda{
                    font-weight: 400;
                    color: #898989;
                    font-size: 1.2em;
                }
            }

            .form-mr{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;
                padding: 0em 1em;

                .large-inp{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .5em;
                    //border: 1px solid #000;

                    .txt-box{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1em;

                        .force{
                            font-weight: 900;
                            font-size: 1.2em;
                            color: #d82020;
                        }

                        .inp-title{
                            font-weight: 400;
                            color: #4e98a2;
                            font-size: 1.2em;
                        }
                    }

                    .inp-cont{
                        width: 100%;
                        padding-left: 1.2em;

                        .form-inpa{
                            background-color: #f9f9f9;
                            width: 100%;
                            height: 2.5em;
                            color: #000;
                            font-weight: 100;
                            font-size: .9em;
                            padding: 0em 1em;
                            border-radius: .5em;
                            border: 1px solid #ddd;
                        }

                        .form-inpa:focus{
                            outline: none;
                        }
                    }
                }

                .mitad-inp-cont{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1em;

                    .fifty-box{
                        width: 50%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: .5em;

                        .txt-box{
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 1em;
    
                            .force{
                                font-weight: 900;
                                font-size: 1.2em;
                                color: #d82020;
                            }
    
                            .inp-title{
                                font-weight: 400;
                                color: #4e98a2;
                                font-size: 1.2em;
                            }
                        }
    
                        .inp-cont{
                            width: 100%;
                            padding-left: 1.2em;
    
                            .form-inpa{
                                background-color: #f9f9f9;
                                width: 100%;
                                height: 2.5em;
                                color: #000;
                                font-weight: 100;
                                font-size: .9em;
                                padding: 0em 1em;
                                border-radius: .5em;
                                border: 1px solid #ddd;
                            }
    
                            .form-inpa:focus{
                                outline: none;
                            }
                        }
                    }
                }
            }

            .last-msg-cont{
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .last-msg{
                    text-align: center;
                    font-weight: 400;
                    font-size: 1.2em;
                    color: #9f9f9f;
                    width: 85%;
                    padding: 2em 0em;
                }
            }

            .btns-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding-bottom: 4em;

                .salir-form{
                    background-color: #ff606d;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #ff606d;
                }

                .salir-form:hover{
                    background-color: #ff606d00;
                    color: #ff606d;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }

                .sp{
                    background-color: #00a3c8;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #00a3c8;
                }

                .sp:hover{
                    background-color: #00a3c800;
                    color: #00a3c8;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }
            }
        }

        .ovfw-y::-webkit-scrollbar {
            width: .6em;
            background-color: #f3f3f3;
            height: .7em;
        }
        
        .ovfw-y::-webkit-scrollbar-thumb {
            background-color: #4bd1dd;
            //border-radius: 1vh;
            width: 0.25em;
        }

        .msg-no-completado{
            position: absolute;
            background-color: #fff;
            border-radius: .3em;
            font-size: 1.2em;
            font-weight: 300;
            width: 25em;
            height: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
            top: calc(50% - 2em);
            left: -100%; // calc(50% - 12.5em)
            box-shadow: 0px 0px 10px 1px #0000002e;
            transition: .3s all ease-in-out;

            .msg{
                font-weight: 400;
                font-size: .9em;
                color: #000;

                .aster{
                    font-weight: 900;
                    color: #e22b28;
                    font-size: 1.4em;
                }
            }
        }
    }

    .step-2-cont{
        background-color: #fff;
        width: 900px;
        height: 90%;
        border-radius: 1em;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: relative;

        .ban-cont{
            width: 100%;
            height: auto;
            
            img{
                width: 100%;
                height: auto;
            }
        }

        .ovfw-y{
            width: 100%;
            height: 100%;
            overflow-y: auto;

            .txt-cont-pres{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding: 1.5em 0em;
    
                .title-1{
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    color: #00acce;
                    font-weight: 600;
                    font-size: 1.5em;
                }
    
                .desc-1{
                    color: #939393;
                    font-weight: 400;
                    font-size: 1em;
                    text-align: center;
                    width: 32em;
                }
    
                .obli-txt{
                    color: #ca212b;
                    font-weight: 900;
                    font-size: 1.2em;
                
                    .obli-txt-alt{
                        font-weight: 100;
                        color: #9d9d9d;
                        font-size: .9em;
                    }
                }
    
                .recuerda{
                    font-weight: 400;
                    color: #898989;
                    font-size: 1.2em;
                }
            }

            .form-mr{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;
                padding: 0em 1em;

                .large-inp{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .5em;
                    //border: 1px solid #000;

                    .txt-box{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1em;

                        .force{
                            font-weight: 900;
                            font-size: 1.2em;
                            color: #d82020;
                        }

                        .inp-title{
                            font-weight: 400;
                            color: #4e98a2;
                            font-size: 1.2em;
                        }
                    }

                    .tip-cont{
                        width: 100%;
                        height: auto;
                        padding-left: 1.5em;

                        .tip{
                            color: #b1b1b1;
                            font-weight: 400;
                            font-size: .9em;

                            .ejemplo{
                                font-size: .8em;
                            }
                        }
                    }

                    .inp-cont{
                        width: 100%;
                        padding-left: 1.2em;

                        .form-inpa{
                            background-color: #f9f9f9;
                            width: 100%;
                            height: 2.5em;
                            color: #000;
                            font-weight: 100;
                            font-size: .9em;
                            padding: 0em 1em;
                            border-radius: .5em;
                            border: 1px solid #ddd;
                        }

                        .form-inpa:focus{
                            outline: none;
                        }
                    }
                }

                .fecha-cont{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .5em;

                    .txt-box{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1em;

                        .force{
                            font-weight: 900;
                            font-size: 1.2em;
                            color: #d82020;
                        }

                        .inp-title{
                            font-weight: 400;
                            color: #4e98a2;
                            font-size: 1.2em;
                        }
                    }

                    .tip-cont{
                        width: 100%;
                        height: auto;
                        padding-left: 1.5em;

                        .tip{
                            color: #b1b1b1;
                            font-weight: 400;
                            font-size: .9em;
                        }
                    }

                    .fecha-cont{
                        width: 100%;
                        height: auto;
                        padding-left: 1.5em;
                    }

                    .image-cont{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 1em;

                        .txt-box{
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            padding-left: 1.5em;
    
                            .inp-title{
                                font-weight: 400;
                                color: #4e98a2;
                                font-size: 1.2em;
                            }
                        }

                        .custom-file-input {
                            width: 100%;
                            height: auto;
                            padding-left: 1.2em;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: .5em;
    
                            .btn-img{
                                width: auto;
                                height: auto;
    
                                img{
                                    width: 18em;
                                    height: auto;
                                }
                            }
    
                            .file-name{
                                color: #6f6f6f;
                                font-weight: 100;
                                font-size: .8em;
                            }
                        }

                        .msg-cont-img{
                            width: 100%;
                            height: auto;
                            padding-left: 1.5em;

                            .msg{
                                color: #b7b7b7;
                                font-weight: 400;
                                font-size: 1em;
                            }
                        }
                    }

                    .txtbox-mensaje-cont{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 1em;

                        .txt-box{
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 1em;
    
                            .force{
                                font-weight: 900;
                                font-size: 1.2em;
                                color: #d82020;
                            }
    
                            .inp-title{
                                font-weight: 400;
                                color: #4e98a2;
                                font-size: 1.2em;
                            }
                        }

                        .tip-cont{
                            width: 100%;
                            height: auto;
                            padding-left: 1.5em;
    
                            .tip{
                                color: #b1b1b1;
                                font-weight: 400;
                                font-size: .9em;
                            }
                        }

                        .text-area-cont{
                            width: 100%;
                            height: auto;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            padding-left: 1.2em;

                            .img-ta{
                                width: 10em;
                                height: auto;
                            }

                            .ta-step-2{
                                width: calc(100% - 11.2em);
                                font-size: 1em;
                                font-weight: 100;
                                color: #000;
                                height: 10em;
                                resize: none;
                                background-color: #f9f9f9;
                                border-top: 1px solid #ddd;
                                border-right: 1px solid #ddd;
                                border-bottom: 1px solid #ddd;
                                border-radius: 0em 1em 1em 0em;
                                padding: 1em;
                                border-left: none;
                            }

                            .ta-step-2:focus{
                                outline: none;
                            }
                        }
                    }
                }
            }

            .btns-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding-top: 2em;
                padding-bottom: 4em;

                .salir-form{
                    background-color: #ff606d;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #ff606d;
                }

                .salir-form:hover{
                    background-color: #ff606d00;
                    color: #ff606d;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }

                .sp{
                    background-color: #00a3c8;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #00a3c8;
                }

                .sp:hover{
                    background-color: #00a3c800;
                    color: #00a3c8;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }
            }
        }

        .msg-no-completado{
            position: absolute;
            background-color: #fff;
            border-radius: .3em;
            font-size: 1.2em;
            font-weight: 300;
            width: 25em;
            height: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
            top: calc(50% - 2em);
            left: -100%; // calc(50% - 12.5em)
            box-shadow: 0px 0px 10px 1px #0000002e;
            transition: .3s all ease-in-out;

            .msg{
                font-weight: 400;
                font-size: .9em;
                color: #000;

                .aster{
                    font-weight: 900;
                    color: #e22b28;
                    font-size: 1.4em;
                }
            }
        }
    }

    .step-3-cont{
        background-color: #fff;
        width: 900px;
        height: 90%;
        border-radius: 1em;
        display: none;
        //display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: relative;

        .ban-cont{
            width: 100%;
            height: auto;
            
            img{
                width: 100%;
                height: auto;
            }
        }

        .ovfw-y{
            width: 100%;
            height: 100%;
            overflow-y: auto;
            
            .txt-cont-pres{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding: 1.5em 0em;
    
                .title-1{
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    color: #00acce;
                    font-weight: 600;
                    font-size: 1.5em;
                }
    
                .obli-txt{
                    color: #ca212b;
                    font-weight: 900;
                    font-size: 1.2em;
                
                    .obli-txt-alt{
                        font-weight: 100;
                        color: #9d9d9d;
                        font-size: .9em;
                    }
                }
            }

            .form-mr{
                width: 90%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;
                padding: 0em 1em;

                .select-dir-cont{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .5em;

                    .txt-box{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1em;

                        .force{
                            font-weight: 900;
                            font-size: 1.2em;
                            color: #d82020;
                        }

                        .inp-title{
                            font-weight: 400;
                            color: #4e98a2;
                            font-size: 1.2em;
                        }
                    }

                    .conten-direcs{
                        padding: 1em;
                        background-color: #f9f9f9;
                        border: 1px solid #ddd;
                        border-radius: .5em;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: .5em;
                        margin-left: 1.5em;

                        input[type="radio"]{
                            display: none;
                        }
                
                        input[type="radio"]:checked + label{
                            background-color: #c6f4ff;
                        }

                        .dir-txt{
                            background-color: #fff;
                            font-size: 1em;
                            font-weight: 300;
                            color: #535353;
                            padding: .5em 2em;
                            border-radius: .3em;
                            box-shadow: 0px 0px 10px 1px #00000014;
                            cursor: pointer;
                            transition: .4s all ease-in-out;
                        }
                    }
                }

                .form-od{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1em;

                    .large-inp{
                        width: 90%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: .5em;
                        //border: 1px solid #000;
    
                        .txt-box{
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 1em;
    
                            .force{
                                font-weight: 900;
                                font-size: 1.2em;
                                color: #d82020;
                            }
    
                            .inp-title{
                                font-weight: 400;
                                color: #4e98a2;
                                font-size: 1.2em;
                            }
                        }
    
                        .inp-cont{
                            width: 100%;
                            padding-left: 1.2em;
    
                            .form-inpa{
                                background-color: #f9f9f9;
                                width: 100%;
                                height: 2.5em;
                                color: #000;
                                font-weight: 100;
                                font-size: .9em;
                                padding: 0em 1em;
                                border-radius: .5em;
                                border: 1px solid #ddd;
                            }
    
                            .form-inpa:focus{
                                outline: none;
                            }
                        }
                    }

                    .mitad-inp-cont{
                        width: 90%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 1em;
    
                        .fifty-box{
                            width: 50%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: .5em;
    
                            .txt-box{
                                width: 100%;
                                height: auto;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 1em;
        
                                .force{
                                    font-weight: 900;
                                    font-size: 1.2em;
                                    color: #d82020;
                                }
        
                                .inp-title{
                                    font-weight: 400;
                                    color: #4e98a2;
                                    font-size: 1.2em;
                                }
                            }
        
                            .inp-cont{
                                width: 100%;
                                padding-left: 1.2em;
        
                                .form-inpa{
                                    background-color: #f9f9f9;
                                    width: 100%;
                                    height: 2.5em;
                                    color: #000;
                                    font-weight: 100;
                                    font-size: .9em;
                                    padding: 0em 1em;
                                    border-radius: .5em;
                                    border: 1px solid #ddd;
                                }
        
                                .form-inpa:focus{
                                    outline: none;
                                }
                            }
                        }
                    }
                }

                .fecha-cont{
                    width: 90%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .5em;

                    .txt-box{
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1em;

                        .force{
                            font-weight: 900;
                            font-size: 1.2em;
                            color: #d82020;
                        }

                        .inp-title{
                            font-weight: 400;
                            color: #4e98a2;
                            font-size: 1.2em;
                        }
                    }

                    .tip-cont{
                        width: 100%;
                        height: auto;
                        padding-left: 1.5em;

                        .tip{
                            color: #b1b1b1;
                            font-weight: 400;
                            font-size: .9em;
                        }
                    }

                    .inp-fecha-cont{
                        width: 100%;
                        height: auto;
                        padding-left: 1.5em;
                    }
                }
            }

            .btns-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding-top: 2em;
                padding-bottom: 4em;

                .salir-form{
                    background-color: #ff606d;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #ff606d;
                }

                .salir-form:hover{
                    background-color: #ff606d00;
                    color: #ff606d;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }

                .sp{
                    background-color: #00a3c8;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #00a3c8;
                }

                .sp:hover{
                    background-color: #00a3c800;
                    color: #00a3c8;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }
            }

            .msg-no-completado{
                position: absolute;
                background-color: #fff;
                border-radius: .3em;
                font-size: 1.2em;
                font-weight: 300;
                width: 25em;
                height: 4em;
                display: flex;
                justify-content: center;
                align-items: center;
                top: calc(50% - 2em);
                left: -100%; // calc(50% - 12.5em)
                box-shadow: 0px 0px 10px 1px #0000002e;
                transition: .3s all ease-in-out;
    
                .msg{
                    font-weight: 400;
                    font-size: .9em;
                    color: #000;
    
                    .aster{
                        font-weight: 900;
                        color: #e22b28;
                        font-size: 1.4em;
                    }
                }
            }
        }
    }

    .step-4-cont{
        background-color: #fff;
        width: 900px;
        height: 90%;
        border-radius: 1em;
        display: none;
        //display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: relative;

        .ban-cont{
            width: 100%;
            height: auto;
            
            img{
                width: 100%;
                height: auto;
            }
        }

        .ovfw-y{
            width: 100%;
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .txt-cont-pres{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding: 1.5em 0em;
    
                .title-1{
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    color: #00acce;
                    font-weight: 600;
                    font-size: 1.5em;
                }
    
                .desc-1{
                    color: #939393;
                    font-weight: 400;
                    font-size: 1em;
                    text-align: center;
                    width: 32em;
                }
            }

            .contrato-txt{
                width: 80%;
                height: 16em;
                background-color: #f9f9f9;
                border: 1px solid #dcdcdc;
                border-radius: 0.5em;
                padding: 1em;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 1em;

                span{
                    color: #adadad;
                    font-weight: 400;
                    font-size: 1.05em;
                }
            }

            .contrato-txt::-webkit-scrollbar {
                width: .6em;
                background-color: #f3f3f3;
                height: .7em;
            }
            
            .contrato-txt::-webkit-scrollbar-thumb {
                background-color: #686868;
                //border-radius: 1vh;
                width: 0.25em;
            }

            .check-contrato-cont{
                width: 70%;
                height: auto;
                padding: 2em 0em;

                .btn-contrato{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1em;
                    background-color: transparent;

                    .quada{
                        width: 2em;
                        height: 2em;
                        background-color: #fff;
                        border: 1px solid #d9d9d9;
                        transition: .4s all ease-in-out;
                    }

                    .txt-contrato{
                        font-size: 1.3em;
                        font-weight: 500;
                        color: #b3b3b3;
                    }
                }
            }

            .btns-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1em;
                padding-top: 2em;
                padding-bottom: 4em;

                .salir-form{
                    background-color: #ff606d;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #ff606d;
                }

                .salir-form:hover{
                    background-color: #ff606d00;
                    color: #ff606d;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }

                .sp{
                    background-color: #00a3c8;
                    color: #fff;
                    font-weight: 400;
                    font-size: 1.2em;
                    padding: .4em 2em;
                    border-radius: .3em;
                    box-shadow: 0px 2px 4px 1px #00000057;
                    transition: .4s all ease-in-out;
                    border: 1px solid #00a3c8;
                }

                .sp:hover{
                    background-color: #00a3c800;
                    color: #00a3c8;
                    box-shadow: 0px 2px 4px 1px #00000000;
                }
            }

            .msg-no-completado{
                position: absolute;
                background-color: #fff;
                border-radius: .3em;
                font-size: 1.2em;
                font-weight: 300;
                width: 25em;
                height: 4em;
                display: flex;
                justify-content: center;
                align-items: center;
                top: calc(50% - 2em);
                left: -100%; // calc(50% - 12.5em)
                box-shadow: 0px 0px 10px 1px #0000002e;
                transition: .3s all ease-in-out;
    
                .msg{
                    font-weight: 400;
                    font-size: .9em;
                    color: #000;
    
                    .aster{
                        font-weight: 900;
                        color: #e22b28;
                        font-size: 1.4em;
                    }
                }
            }
        }
    }
}

.cdk-overlay-container {
    z-index: 9999;
}

@media(max-width: 992px){
    .ante-crear-cont{
        .fix-width{
            gap: 1em;
            width: 100%;
            padding: 1em 0em;

            .titles-sect-cont{
                padding: 0em .5em;

                .title-sect{
                    font-size: 5vw;
                    text-align: center;
                }

                .subt-sect{
                    font-size: 4vw;
                    text-align: center;
                }
            }

            .open-form-btn{
                font-size: 4.5vw;
                padding: .7em 2em;
            }

            .beneficios-cont{
                padding: 0em 1em;

                .title-beneficios{
                    font-size: 4vw;
                    text-align: center;
                }

                .bens-contan-r{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .beneficio{
                        width: 40%;
                        height: auto;

                        .img-ben{
                            width: 100%;
                        }

                        .txt-ben{
                            font-size: 3vw;
                        }
                    }
                }
            }

            .datos-cont{
                padding: 0em 1em;

                .title-datos{
                    width: 100%;
                    font-size: 5vw;
                }

                .desp-btn-datos{
                    font-size: 3.6vw;
                    text-align: start;

                    i{
                        font-size: 1vw;
                    }
                }
            }
        }
    }

    .formulario-crear-mesa-regalos-cont{
        .step-1-cont{
            width: 90%;

            .ovfw-y{
                .txt-cont-pres{
                    padding: 1em 1em;
                    gap: .5em;

                    .title-1{
                        text-align: center;
                        line-height: 1em;
                        font-size: 1.3em;
                        font-weight: 400;
                    }

                    .desc-1{
                        width: 100%;
                        font-size: .9em;
                        line-height: 1em;
                    }

                    .obli-txt{
                        font-size: 1em;
                    }

                    .recuerda{
                        text-align: center;
                        line-height: .9em;
                        font-size: .9em;
                    }
                }

                .form-mr{
                    padding: 2em 1em;

                    .large-inp{
                        .txt-box{
                            gap: .5em;

                            .force{
                                font-size: 1.1em;
                            }

                            .inp-title{
                                font-size: 1em;
                            }
                        }
                    }

                    .mitad-inp-cont{
                        flex-direction: column;

                        .fifty-box{
                            width: 100%;

                            .txt-box{
                                gap: .5em;
    
                                .force{
                                    font-size: 1.1em;
                                }
    
                                .inp-title{
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                }

                .last-msg-cont{
                    padding: 0em 1em;

                    .last-msg{
                        font-size: .9em;
                        width: 100%;
                        line-height: 1em;
                    }
                }

                .btns-cont{
                    flex-direction: column;
                    gap: .5em;

                    .salir-form{
                        font-size: 1em;
                        font-weight: 100;
                    }

                    .sp{
                        width: 100%;
                        border-radius: 0em;
                        font-weight: 100;
                        padding: .7em 0em;
                    }
                }
            }

            .msg-no-completado{
                width: 90%;
                height: 3em;

                .msg{
                    font-size: .6em;

                    .aster{
                        font-size: 1.2em;
                    }
                }
            }
        }

        .step-2-cont{
            width: 90%;

            .ovfw-y{
                .txt-cont-pres{
                    padding: 1em 1em;
                    gap: .5em;

                    .title-1{
                        text-align: center;
                        line-height: 1em;
                        font-size: 1.3em;
                        font-weight: 400;
                    }

                    .desc-1{
                        width: 100%;
                        font-size: .9em;
                        line-height: 1em;
                    }

                    .obli-txt{
                        font-size: 1em;
                    }
                }

                .form-mr{
                    padding: 2em 1em;

                    .large-inp{
                        .txt-box{
                            gap: .5em;

                            .force{
                                font-size: 1.1em;
                            }

                            .inp-title{
                                font-size: 1em;
                            }
                        }

                        .tip-cont{
                            .tip{

                            }
                        }
                    }

                    .fecha-cont{
                        .txt-box{
                            gap: .5em;

                            .force{
                                font-size: 1.1em;
                            }

                            .inp-title{
                                font-size: 1em;
                            }
                        }

                        .image-cont{
                            .txt-box{
                                gap: .5em;
    
                                .force{
                                    font-size: 1.1em;
                                }
    
                                .inp-title{
                                    font-size: 1em;
                                }
                            }
                        }

                        .txtbox-mensaje-cont{
                            .txt-box{
                                gap: .5em;
    
                                .force{
                                    font-size: 1.1em;
                                }
    
                                .inp-title{
                                    font-size: 1em;
                                }
                            }

                            .text-area-cont{
                                .ta-step-2{
                                    width: 100%;
                                    border-radius: 1em 1em 1em 1em;
                                    border: 1px solid #ddd;
                                    font-size: .9em;
                                    height: 8em;
                                }
                            }
                        }
                    }
                }

                .btns-cont{
                    flex-direction: column;
                    gap: .5em;

                    .salir-form{
                        font-size: 1em;
                        font-weight: 100;
                    }

                    .sp{
                        width: 100%;
                        border-radius: 0em;
                        font-weight: 100;
                        padding: .7em 0em;
                    }
                }
            }

            .msg-no-completado{
                width: 90%;
                height: 3em;

                .msg{
                    font-size: .6em;

                    .aster{
                        font-size: 1.2em;
                    }
                }
            }
        }

        .step-3-cont{
            width: 90%;

            .ovfw-y{
                position: relative;

                .txt-cont-pres{
                    padding: 1em 1em;
                    gap: .5em;

                    .title-1{
                        text-align: center;
                        line-height: 1em;
                        font-size: 1.3em;
                        font-weight: 400;
                    }

                    .obli-txt{
                        font-size: 1em;
                    }
                }

                .form-mr{
                    padding: 2em 1em;

                    .form-od{
                        .large-inp{
                            .txt-box{
                                gap: .5em;
    
                                .force{
                                    font-size: 1.1em;
                                }
    
                                .inp-title{
                                    font-size: 1em;
                                }
                            }
                        }
    
                        .mitad-inp-cont{
                            flex-direction: column;
    
                            .fifty-box{
                                width: 100%;
    
                                .txt-box{
                                    gap: .5em;
        
                                    .force{
                                        font-size: 1.1em;
                                    }
        
                                    .inp-title{
                                        font-size: 1em;
                                    }
                                }
                            }
                        }
                    }
                }

                .btns-cont{
                    flex-direction: column;
                    gap: .5em;

                    .salir-form{
                        font-size: 1em;
                        font-weight: 100;
                    }

                    .sp{
                        width: 100%;
                        border-radius: 0em;
                        font-weight: 100;
                        padding: .7em 0em;
                    }
                }

                .msg-no-completado{
                    width: 90%;
                    height: 3em;
    
                    .msg{
                        font-size: .6em;
    
                        .aster{
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }

        .step-4-cont{
            width: 90%;

            .ovfw-y{
                position: relative;

                .txt-cont-pres{
                    padding: 1em 1em;
                    gap: .5em;

                    .title-1{
                        text-align: center;
                        line-height: 1em;
                        font-size: 1.3em;
                        font-weight: 400;
                    }

                    .obli-txt{
                        font-size: 1em;
                        width: 100%;
                    }
                }

                .contrato-txt{
                    span{
                        font-size: .9em;
                    }
                }

                .check-contrato-cont{
                    .btn-contrato{
                        .quada{
                            width: 1em;
                            height: 1em;
                        }

                        .txt-contrato{
                            font-size: .9em;
                        }
                    }
                }

                .btns-cont{
                    flex-direction: column;
                    gap: .5em;

                    .salir-form{
                        font-size: 1em;
                        font-weight: 100;
                    }

                    .sp{
                        width: 100%;
                        border-radius: 0em;
                        font-weight: 100;
                        padding: .7em 0em;
                    }
                }

                .msg-no-completado{
                    width: 90%;
                    height: 3em;
    
                    .msg{
                        font-size: .6em;
    
                        .aster{
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }
    }
}