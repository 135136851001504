import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-terminos-politicas',
  standalone: true,
  imports: [CommonModule,FormsModule,RouterModule,IonicModule],
  templateUrl: './terminos-politicas.component.html',
  styleUrls: ['./terminos-politicas.component.scss'],
  providers:[],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TerminosPoliticasComponent  implements OnInit {

  public receptId : number = 0;
  public wi : string = 'No se seleccionó Id';

  constructor(private activatedRoute : ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      this.receptId = params['id'];
      if ( this.receptId == 1 ) {
        this.wi = 'Terminos De Servicio';
      } else if ( this.receptId == 2 ) {
        this.wi = 'Política De Reembolso';
      } else if ( this.receptId == 3 ) {
        this.wi = 'Política De Privacidad';
      }else if ( this.receptId == 4 ) {
        this.wi = 'Política De Envíos';
      }else if ( this.receptId == 5 ) {
        this.wi = 'Política de Garantía';
      }
    });
  }

  print(){
    window.print();
  }

}
