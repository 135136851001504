import { Component, OnInit } from '@angular/core';
import { FormsModule, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  selector: 'app-reseteo-password',
  templateUrl: './reseteo-password.component.html',
  styleUrls: ['./reseteo-password.component.scss'],
})
export class ReseteoPasswordComponent  implements OnInit {
  password:any;
  password2:any;
  token:any;
  key:any;
  estoycargando = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private contactoService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastController: ToastController
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.key = params['key'];
      this.token = params['token'];
    });
  }

  reseteame(){
    this.estoycargando = true;


  this.contactoService.resetMe(this.key,this.token,this.password,this.password2).subscribe(datos =>{
  if(datos == 1){
    this.estoycargando = false;
    this.cambioDePassword();
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 1500);
  }
  if(datos == 3){
    this.estoycargando = false; 
    this.tokenExpiro();
  }
  if(datos == 2){
    this.estoycargando = false;
    this.errorUpdate();
  }
  
  })
  }

  async tokenExpiro() {
    const toast = await this.toastController.create({
      message: 'El token ingresado ya no es valido',
      color: "danger",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }

  async cambioDePassword() {
    const toast = await this.toastController.create({
      message: 'Tu contraseña ah sido actualizada',
      color: "success",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }

  async errorUpdate() {
    const toast = await this.toastController.create({
      message: 'El correo ingresado no es valido',
      color: "danger",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }

}
