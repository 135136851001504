import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [
    IonicModule
  ],
  selector: 'app-compra-y-recoge',
  templateUrl: './compra-y-recoge.component.html',
  styleUrls: ['./compra-y-recoge.component.scss'],
})
export class CompraYRecogeComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Lidepuntos");
  }

}
