import { CommonModule, Location } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import Fuse from 'fuse.js';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { ProductService } from '../../services/handle-categorias.service';
import { Usuario } from '../../Models/usuario';
import { LoginService } from '../../services/login.service';


@Component({
  selector: 'app-categoria-promociones-cricut',
  standalone: true,
  imports: [MatPaginatorModule, CommonModule, RouterModule, IonicModule],
  templateUrl: './categoria-promociones-cricut.component.html',
  styleUrls: ['./categoria-promociones-cricut.component.scss'],
  providers: [ApiProductosService, CarritoServiceService, ProductService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CategoriaPromocionesCricutPage implements OnInit {

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  public totalItems: number = 0;
  id_parent: any;
  url = "https://new.lideart.com.mx/img/categoria/"
  categorias: any;
  maxVisibleColors = 12; // Número máximo de colores visibles antes de mostrar los puntos
  showAllColors = false;
  showAllColorsMap: { [key: number]: boolean } = {};
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  items: any = [];
  sum: number = 1;

  public page = 1;
  perPage = 10;

  totalData = 0;
  totalPage = 10;

  cargando = false;
  productosCatalogo: any;
  precio: any;
  id_product: any;
  cp: number = 1;
  descuento: any;
  public tamanoLista: number = 0;
  public productCat: any = [];
  public currentItemsToShow: any = [];
  usuario!: Usuario | null;


  constructor(
    private shoppingCart: CarritoServiceService,
    private location: Location,
    private productoService: ApiProductosService,
    private router: Router,
    private usuarioService: LoginService,
    public loginService: LoginService
  ) { }

  ngOnInit() {
    this.shoppingCart.cartItems.subscribe((d: any) => {
      this.totalItems = d.length;
      this.getArticulos()
    });
  }


  getArticulos() {
    // this.cargando = true;

    this.usuarioService.usuario.subscribe((res: Usuario | null) => {
      this.usuario = res;
      if (this.usuario && this.usuario.usuario) {
        this.obtenerDescuentoUsuario();
      } else {
        this.productoService.getCategorias(61).subscribe((data: any) => {
          this.productCat = data;

          this.tamanoLista = this.productCat.length;
          this.currentItemsToShow = this.productCat.slice(0, 24);
          this.totalData = this.productCat.length;
          this.precio = this.productCat.precio;
          this.id_product = this.productCat.id_product;

          this.generateItems();
        })
      }
    });

    // this.productoService.getCategorias(61).subscribe((data:any) =>{
    //   this.productCat = data;



    //   this.tamanoLista = this.productCat.length;
    //   this.currentItemsToShow = this.productCat.slice(0,24);
    //   this.totalData = this.productCat.length;
    //   this.precio = this.productCat.precio;
    //   this.id_product = this.productCat.id_product;


    //   this.generateItems();
    // })

    //this.generateItems();
  }

  onPageChange($event: PageEvent) {

    /*this.productoService.getDescuento(jsondescuento).subscribe(resdesc =>{
        this.resdesc = resdesc;
  
        if (this.resdesc === 1) {
        
          this.descuentillo = false;
          this.descuento = 1;
        }else{
        
          this.descuentillo = true;
          this.descuento = this.resdesc;
          this.descuento = 1- this.descuento
        }
    });*/
    if (this.productCat && Array.isArray(this.productCat)) {
      this.currentItemsToShow = this.productCat.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
    } else {
      console.warn('productCat está indefinido o no es un array');
      this.currentItemsToShow = []; // O cualquier valor por defecto que tenga sentido en tu contexto
    }
    this.scrollUp();

  }

  scrollUp(): void {
    setTimeout(() => {
      if (this.tableInput && this.tableInput.nativeElement) {
        this.tableInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        console.warn('tableInput o nativeElement no están definidos');
      }
    });
  }

  navigateToDescripcionProducto(c: any, b: any, a: any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}` + ".html");
  }


  private generateItems() {
    for (let i = 0; i < 4; i++) {
      let imp = this.sum;
      if (this.sum <= this.totalData) {
        this.items.push(this.productCat[imp - 1]);
      }
      this.sum++;
    }
  }

  goBack() {
    this.location.back();
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

  obtenerDescuentoUsuario() {
    if (this.usuario && this.usuario.usuario) {
      const datosDescuento = {
        email: this.usuario.usuario,
        id_cat: 61
      };

      this.productoService.getCategoriaSub(datosDescuento).subscribe((data: any) => {
        if (data && data.length > 0) {
          this.productCat = data;
          this.tamanoLista = this.productCat.length;
          this.currentItemsToShow = this.productCat.slice(0, 24);
          this.totalData = this.productCat.length;
          this.precio = this.productCat.precio;
          this.id_product = this.productCat.id_product;

          this.generateItems();
        } else {
          this.productoService.getCategorias(61).subscribe((data: any) => {
            this.productCat = data;
            this.tamanoLista = this.productCat.length;
            this.currentItemsToShow = this.productCat.slice(0, 24);
            this.totalData = this.productCat.length;
            this.precio = this.productCat.precio;
            this.id_product = this.productCat.id_product;

            this.generateItems();
          });
        }
      });
    } else {
      this.productoService.getCategorias(61).subscribe((data: any) => {
        this.productosCatalogo = data;
      });
    }
  }
  toggleShowAllColors(productId: number) {
    this.showAllColorsMap[productId] = !this.showAllColorsMap[productId];
  }
}
