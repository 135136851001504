import { CommonModule, isPlatformBrowser } from '@angular/common';
import { afterRender, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonicModule, IonModal, ModalController } from '@ionic/angular';
import Fuse from 'fuse.js';
import Swal from 'sweetalert2';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-perfil',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, IonicModule],
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  providers: [ApiProductosService, LoginService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PerfilComponent {

  @ViewChild(IonModal) modalito!: IonModal;
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  public historialIs = false;
  public informacionIs = false;
  public LidepunteIs = false;
  isSubmitted = false;
  public direccionIs = false;
  public direccionIs2 = false;
  datosUsuario: any = [];
  informacion: any = [];
  id_customer: any;
  usuario!: Usuario;
  pedidos: any = [];
  direcciones: any = []
  nombre: any;
  apellido: any;
  rfc: any;
  correo: any;
  clientesito: any = [];
  nombreEstado: any;
  nombreDir: any;
  apellidoDir: any;
  alias: any;
  empresa: any;
  telefono: any;
  zipcode: any;
  address1: any;
  address2: any;
  id_state: any;
  city: any;
  colony: any;
  estado: any;
  respuesta: any = [];
  lidepuntos: any = [];
  totalpuntos: any;
  customerID: any;
  ordenes: any = [];
  ordensitas: any = [];
  modalin = false;

  opc: any = this.route.snapshot.paramMap.get('rec');

  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  @ViewChild('historial') historialf!: ElementRef<HTMLInputElement>;
  @ViewChild('informacion') informacionf!: ElementRef<HTMLInputElement>;
  @ViewChild('direccion') direccionf!: ElementRef<HTMLInputElement>;
  @ViewChild('direccion2') direccionf2!: ElementRef<HTMLInputElement>;
  @ViewChild('lidepuntos') lidepuntef!: ElementRef<HTMLInputElement>;

  @ViewChild('info_Desk') info_Desk!: ElementRef<HTMLInputElement>;
  public infoDeskIs = false;

  @ViewChild('dir_Desk') dir_Desk!: ElementRef<HTMLInputElement>;
  public dirDeskIs = false;

  @ViewChild('agrega_Dir') agrega_Dir!: ElementRef<HTMLInputElement>;
  public agregaDirIs = false;

  @ViewChild('hist_Desk') hist_Desk!: ElementRef<HTMLInputElement>;
  public histDeskIs = false;

  @ViewChild('punt_Desk') punt_Desk!: ElementRef<HTMLInputElement>;
  public puntDeskIs = false;

  constructor(
    public modalController: ModalController,
    private renderer2: Renderer2,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private productoService: ApiProductosService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {

    afterRender(() => {
      this.loginService.usuario.subscribe((res) => {
        if (res)
          this.usuario = res;
      }

      );
    });
    if (isPlatformBrowser(this.platformId)) {
      this.loginService.usuario.subscribe((res) => {
        if (!res) return
        this.usuario = res;
        this.checalo();
        this.checalox2();
        this.traePuntos();
      }
      );
    }
  }

  ngAfterViewInit() {



    if (this.opc == 1) {
      this.renderer2.setStyle(this.lidepuntef.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.lidepuntef.nativeElement, 'z-index', '1');
      this.LidepunteIs = true;

    }
    if (this.opc == 2) {
      this.renderer2.setStyle(this.historialf.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.historialf.nativeElement, 'z-index', '1');
      this.historialIs = true;
    }
  }

  historiale() {
    if (this.historialIs == false) {
      this.renderer2.setStyle(this.historialf.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.historialf.nativeElement, 'z-index', '1');
      this.historialIs = true;
    } else if (this.historialIs == true) {
      this.renderer2.setStyle(this.historialf.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.historialf.nativeElement, 'z-index', '-1');
      this.historialIs = false;
      this.opc = 0;
    }
  }

  informacione() {
    if (this.informacionIs == false) {
      this.renderer2.setStyle(this.informacionf.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.informacionf.nativeElement, 'z-index', '1');
      this.informacionIs = true;
    } else if (this.informacionIs == true) {
      this.renderer2.setStyle(this.informacionf.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.informacionf.nativeElement, 'z-index', '-1');
      this.informacionIs = false;
    }
  }

  infoDesk() {
    if (this.infoDeskIs == false) {
      this.renderer2.setStyle(this.info_Desk.nativeElement, 'width', '100%');
      this.renderer2.setStyle(this.info_Desk.nativeElement, 'height', '100%');
      this.infoDeskIs = true;
    } else if (this.infoDeskIs == true) {
      this.renderer2.setStyle(this.info_Desk.nativeElement, 'width', '0%');
      this.renderer2.setStyle(this.info_Desk.nativeElement, 'height', '0%');
      this.infoDeskIs = false;
    }
  }

  dirDesk() {
    if (this.dirDeskIs == false) {
      this.renderer2.setStyle(this.dir_Desk.nativeElement, 'width', '100%');
      this.renderer2.setStyle(this.dir_Desk.nativeElement, 'height', '100%');
      this.dirDeskIs = true;
    } else if (this.dirDeskIs == true) {
      this.renderer2.setStyle(this.dir_Desk.nativeElement, 'width', '0%');
      this.renderer2.setStyle(this.dir_Desk.nativeElement, 'height', '0%');

      if (this.agregaDirIs == true) {
        this.agregaDir();
      }

      this.dirDeskIs = false;
    }
  }

  agregaDir() {
    if (this.agregaDirIs == false) {
      this.renderer2.setStyle(this.agrega_Dir.nativeElement, 'width', '100%');
      this.renderer2.setStyle(this.agrega_Dir.nativeElement, 'height', '100%');
      this.agregaDirIs = true;
    } else if (this.agregaDirIs == true) {
      this.renderer2.setStyle(this.agrega_Dir.nativeElement, 'width', '0%');
      this.renderer2.setStyle(this.agrega_Dir.nativeElement, 'height', '0%');
      this.agregaDirIs = false;
    }
  }

  histDesk() {
    if (this.histDeskIs == false) {
      this.renderer2.setStyle(this.hist_Desk.nativeElement, 'width', '100%');
      this.renderer2.setStyle(this.hist_Desk.nativeElement, 'height', '100%');
      this.histDeskIs = true;
    } else if (this.histDeskIs == true) {
      this.renderer2.setStyle(this.hist_Desk.nativeElement, 'width', '0%');
      this.renderer2.setStyle(this.hist_Desk.nativeElement, 'height', '0%');
      this.histDeskIs = false;
    }
  }

  puntDesk() {
    if (this.puntDeskIs == false) {
      this.renderer2.setStyle(this.punt_Desk.nativeElement, 'width', '100%');
      this.renderer2.setStyle(this.punt_Desk.nativeElement, 'height', '100%');
      this.puntDeskIs = true;
    } else if (this.puntDeskIs == true) {
      this.renderer2.setStyle(this.punt_Desk.nativeElement, 'width', '0%');
      this.renderer2.setStyle(this.punt_Desk.nativeElement, 'height', '0%');
      this.puntDeskIs = false;
    }
  }

  direccione() {
    if (this.direccionIs == false) {
      this.renderer2.setStyle(this.direccionf.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.direccionf.nativeElement, 'z-index', '1');
      this.direccionIs = true;
    } else if (this.direccionIs == true) {
      this.renderer2.setStyle(this.direccionf.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.direccionf.nativeElement, 'z-index', '-1');
      this.direccionIs = false;
    }
  }
  Lidepunte() {
    if (this.LidepunteIs == false) {
      this.renderer2.setStyle(this.lidepuntef.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.lidepuntef.nativeElement, 'z-index', '1');
      this.LidepunteIs = true;
    } else if (this.LidepunteIs == true) {
      this.renderer2.setStyle(this.lidepuntef.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.lidepuntef.nativeElement, 'z-index', '-1');
      this.LidepunteIs = false;
      this.opc = 0;
    }
  }

  direccione2() {
    this.direccione();
    if (this.direccionIs2 == false) {
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'z-index', '1');
      this.direccionIs2 = true;
    } else if (this.direccionIs2 == true) {
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'z-index', '-1');
      this.direccionIs2 = false;
    }
  }

  quita() {

    if (this.direccionIs2 == false) {
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'z-index', '1');
      this.direccionIs2 = true;
    } else if (this.direccionIs2 == true) {
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.direccionf2.nativeElement, 'z-index', '-1');
      this.direccionIs2 = false;
    }
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }



  checalo() {
    this.loginService.checaUsuario(this.usuario.usuario).subscribe((data: any) => {
      this.datosUsuario = data;
      this.id_customer = this.datosUsuario.id_customer;
      this.productoService.getDatosPedido(this.usuario.usuario).subscribe((datoos: any) => {
        if (datoos === "No existen Ordenes") {
          // console.warn('No existen órdenes para este cliente.');
          this.pedidos = []; // O cualquier valor por defecto que tenga sentido en tu contexto
        } else {
          try {
            const parsedData = JSON.parse(datoos);
            this.pedidos = parsedData;
          } catch (e) {
            console.error('La respuesta no es un JSON válido:', datoos);
            // Maneja otros casos de respuesta no JSON aquí
          }
        }
      });
    });

  }

  checalox2() {
    this.loginService.recuperaDatosCliente(this.usuario.usuario).subscribe((data: any) => {
      this.informacion = data;
      this.nombre = this.informacion[0].firstname
      this.apellido = this.informacion[0].lastname
      this.rfc = this.informacion[0].RFC
      this.correo = this.informacion[0].email
      this.customerID = this.informacion[0].customerID
      this.loginService.recuperaOrdenescliente(this.customerID).subscribe((muchosDatos: any) => {
        this.ordenes = muchosDatos;
        this.ordensitas = this.ordenes.data;

      })

    })


  }

  cancel() {
    // this.modalito.dismiss(null, 'cancel');
    this.modalin = false;
  }



  traePuntos() {
    this.loginService.traePuntos(this.usuario.usuario).subscribe((data: any) => {
      this.lidepuntos = data;
      this.totalpuntos = this.lidepuntos[0].Total_Lidepuntos;
    })

  }

  abreme() {

    this.modalin = true;
  }


  actualizar() {
    let datosCliente = {
      "nombre": this.nombre,
      "apellido": this.apellido,
      "rfc": this.rfc,
      "correo": this.correo
    }

    this.loginService.actualizaDatosCliente(datosCliente).subscribe((respuesta: any) => {
      location.reload()
    })
  }

  accionesDireccion(valor: any, accion: any) {

    if (valor != '' && valor != undefined) {

    }

    switch (accion) {
      case 'zipcode':
        if (valor.length >= 4) {
          this.porZipCode(valor.value)
        }
        break;
      case 'state':
        this.porState(valor.value)
        break;
      case 'city':
        this.porCity(valor.value)
        break;
      case 'colony':
        this.porColony()
        break;
      case 'edicion_direccion':
        // this.paraEditar(valor)
        break;
      default:
        break;
    }

  }

  porZipCode(postcode: any) {

    this.loginService.selectDireccionZipCode('zipcode', this.zipcode).subscribe((res: any) => {
      this.respuesta = res;

      let textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
      if (this.respuesta.estado > 0) {
        (<HTMLInputElement>document.getElementsByName('id_state')[0]).value = this.respuesta.estado
        // this.direccionForm.controls.stateValue.setValue(this.respuesta.estado);
        this.estado = this.respuesta.estado

        const ciudades = this.respuesta.ciudades
        const ciudad = this.respuesta.ciudad
        for (var i = 0; i < ciudades.length; i++) {
          if (ciudades[i] == ciudad) {
            textoOpciones += '<option value="' + ciudades[i] + '" selected>' + ciudades[i] + '</option>'
          } else {
            textoOpciones += '<option value="' + ciudades[i] + '">' + ciudades[i] + '</option>'
          }
        }
        document.getElementsByName('city')[0].innerHTML = textoOpciones
        // this.direccionForm.controls.cityValue.setValue(ciudades[i]);
        textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
        const colonias = this.respuesta.colonias
        for (var i = 0; i < colonias.length; i++) {
          textoOpciones += '<option value="' + colonias[i]['colonia'] + '">' + colonias[i]['colonia'] + ' (' + colonias[i]['codigo_postal'] + ')</option>'
        }
        document.getElementsByName('colony')[0].innerHTML = textoOpciones
        // this.direccionForm.controls.colonyValue.setValue(colonias[i]['colonia']);



      } else {
        (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedIndex = 0;
        document.getElementsByName('city')[0].innerHTML = textoOpciones;
        // this.direccionForm.controls.cityValue.setValue(textoOpciones);
        (<HTMLSelectElement>document.getElementsByName('city')[0]).selectedIndex = 0;
        document.getElementsByName('colony')[0].innerHTML = textoOpciones;
        // this.direccionForm.controls.colonyValue.setValue(textoOpciones);
        (<HTMLSelectElement>document.getElementsByName('colony')[0]).selectedIndex = 0;
      }
      // this.SpinnerService.hide();

    });
  }

  porState(state: string) {
    // this.SpinnerService.show();
    // this.direccionForm.controls.zipcode.reset;
    this.loginService.selectDireccionState('porState', this.estado).subscribe((res: any) => {
      this.respuesta = res;

      let textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
      const ciudades = this.respuesta.ciudades
      for (var i = 0; i < ciudades.length; i++) {
        textoOpciones += '<option value="' + ciudades[i] + '">' + ciudades[i] + '</option>'
      }
      document.getElementsByName('city')[0].innerHTML = textoOpciones
      // this.direccionForm.controls.cityValue.setValue(textoOpciones);

      textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
      const colonias = this.respuesta.colonias
      for (var i = 0; i < colonias.length; i++) {
        textoOpciones += '<option value="' + colonias[i]['colonia'] + '">' + colonias[i]['colonia'] + ' (' + colonias[i]['codigo_postal'] + ')</option>'
      }
      document.getElementsByName('colony')[0].innerHTML = textoOpciones

      // colony
      // this.SpinnerService.hide();
    });
  }



  porCity(city: string) {
    // this.SpinnerService.show();
    // this.direccionForm.controls.zipcode.reset;
    // (<HTMLElement>document.getElementsByName('postcode')[0]).value = ""
    this.loginService.selectDireccionCity("porCity", this.city).subscribe((res: any) => {
      this.respuesta = res;
      // this.SpinnerService.hide();
    })


    let textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
    const colonias = this.respuesta.colonias
    for (var i = 0; i < colonias.length; i++) {
      textoOpciones += '<option value="' + colonias[i]['colonia'] + '">' + colonias[i]['colonia'] + ' (' + colonias[i]['codigo_postal'] + ')</option>'
    }
    document.getElementsByName('colony')[0].innerHTML = textoOpciones
    this.porColony();
    // this.SpinnerService.hide();
  }

  porColony() {
    var algo = document.getElementsByName('colony');

    if (algo.length > 0) {
      var opcion = <HTMLSelectElement>algo[0]
      var texto = opcion.selectedOptions[0].innerText
      var arr_codigo_postal = texto.split(' ')
      var codigo_postal = arr_codigo_postal[arr_codigo_postal.length - 1];
      codigo_postal = codigo_postal.replace(/\(/g, '').replace(/\)/g, '');
      (<HTMLInputElement>document.getElementsByName('zipcode')[0]).value = codigo_postal
      this.zipcode = codigo_postal;

    }
    // this.SpinnerService.hide();
    // //console.log(this.direccionForm.controls.colonyValue)
    // 
  }

  agregarDir() {
    var ciudad = (<HTMLSelectElement>document.getElementsByName("city")[0]).selectedOptions[0].innerText;
    this.city = ciudad;
    var colonia = (<HTMLSelectElement>document.getElementsByName("colony")[0]).selectedOptions[0].innerText;
    var estado = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;
    var nc = colonia.split(' ')
    nc.pop()
    var coloniaFinal = nc.join(' ')
    this.colony = coloniaFinal;
    this.estado = estado;
    let arregloDir = {
      "alias": this.alias,
      "phonenumber": this.telefono,
      "zipcode": this.zipcode,
      "address1": this.address1,
      "address2": this.address2,
      "stateValue": this.estado,
      "cityValue": this.city,
      "colony": coloniaFinal,
      "correo": this.usuario.usuario
    }
    this.loginService.enviaDireccion(arregloDir).subscribe((datos: any) => {
      location.reload();
    })



  }

  borrame() {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar su usuario? ¡No podras comprar o ingresar de nuevo con esta cuenta!",
      heightAuto: false,
      confirmButtonText: "Borrar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {

          this.loginService.borrarCliente(this.usuario.usuario).subscribe((holaaaaaa: any) => {
            Swal.fire("¡Borrado!", "Seras redireccionado al Login");
            this.loginService.logout();
            this.router.navigate(['/login']);
          })

        } catch (error) {

        }
      }

    });
  }

}
