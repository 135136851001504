import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule,RouterModule,IonicModule],
  templateUrl: './formulario-expo.component.html',
  styleUrl: './formulario-expo.component.scss',
  providers:[LoginService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FormularioExpoComponent  implements OnInit {

  nombre_completo:any;
  empresa:any;
  remision:any;
  telefono:any;
  correo:any;
  cargando = false;


  constructor(private loginService: LoginService) { }

  ngOnInit() {
    
  }

  enviaEncuesta(){

    this.cargando =true;
let datosEncuesta = {
  "nombre_completo": this.nombre_completo,
  "empresa": this.empresa,
  "remision":this.remision,
  "telefono": this.telefono,
   "correo": this.correo
}

    this.loginService.mandaEncuestaExpo(datosEncuesta).subscribe(datillos =>{
      this.cargando =false;
      this.searmo();
    this.nombre_completo = "";
    this.empresa = "";
    this.remision = "";
    this.telefono = "";
    this.correo = "";

    })
  }

  async searmo() {
    // const toast = await this.toastController.create({
    //   message: 'Formlario enviado con exito.',
    //   color: "success",
    //   position: "top",
    //   duration: 1500
      
    // });
    // toast.present();
    Swal.fire({
      icon: "success",
      title: "Formlario enviado con exito",
      showConfirmButton: false,
      timer: 1500
    });
  }

}
