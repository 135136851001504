import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-mesa-regalos-registro',
  standalone:true,
  imports: [RouterModule,
    CommonModule,
    FormsModule],
  templateUrl: './mesa-regalos-registro.component.html',
  styleUrls: ['./mesa-regalos-registro.component.scss'],
})
export class MesaRegalosRegistroComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
