<section class="catalogoDesk" *ngIf="isDesk">
  <div class="contDesk" *ngIf="cargando">
    <div class="conten-cats">
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
      <div class="cardin">
        <a class="card-catalogo" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>

      </div>
    </div>
  </div>

  <div class="contDesk" *ngIf="!cargando">
    <div class="title-cont">
      <h1>Catalogo</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin" *ngFor="let producto of productosCatalogo"
        (click)="navigateToDescripcionProducto(producto.category_link,producto.id_product,producto.link_rewrite,producto.paquete,producto.sku)">
        <div class="contImg">
          <img src="{{producto.image_link}}" alt="">
        </div>
        <div class="line-tb">
          <div *ngIf="producto?.detalles?.atributos?.[0]?.valores?.length > 1" class="colors-container">
            <div *ngFor="let color of producto?.detalles?.atributos?.[0]?.valores; let i = index" class="r-color"
              [ngStyle]="{'background-color': color.color, 'background-image': color.image ? 'url(' + color.image + ')' : 'none'}"
              [class.hidden]="!showAllColorsMap[producto.id_product] && i >= maxVisibleColors">
            </div>
            <div
              *ngIf="producto?.detalles?.atributos?.[0]?.valores?.length > maxVisibleColors && !showAllColorsMap[producto.id_product]"
              class="r-color more-colors" (click)="toggleShowAllColors(producto.id_product); $event.stopPropagation()">
              ...
            </div>
            <div *ngIf="showAllColorsMap[producto.id_product]" class="r-color more-colors up-arrow"
              (click)="toggleShowAllColors(producto.id_product); $event.stopPropagation()">
              ↑
            </div>
          </div>
        </div>
        <span class="cat-name">{{producto.title}}</span>
        <ng-container *ngIf="producto.reduction !== undefined && producto.reduction !== null else noReduction">
          <span class="pric">${{producto.precio - (producto.precio * producto.reduction) | number: '1.2-2' }} mxn</span>
          <del class="pric">{{producto.precio | currency: '$'}} mxn</del>
        </ng-container>
        <ng-template #noReduction>
          <span class="pric">{{producto.precio | currency: '$'}} mxn</span>
        </ng-template>
        <!-- <span class="pric">${{producto.precio | number: '1.2-2'}} mxn</span> -->
        @if(this.loginService.getUserData()){
        <!-- <button (click)="idk($event, producto)"
          class="p-2 border-2 border-solid text-[#17add6] border-[#17add6] hover:text-white rounded-md hover:bg-[#17add6]">
          <i class="fa-solid fa-bag-shopping"></i>
          AGREGAR A MI BOLSA
        </button> -->
        }
      </div>
    </div>
  </div>
</section>

<div class="desaparece-desk" *ngIf="isCel">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <ion-grid class="display-grid">
    <ion-row>
      <ion-col size="12" size-md="6" size-lg="4" size-xl="3" *ngFor="let producto of productosCatalogo"
        style="display: flex; justify-content: center;">
        <div
          style="padding-top: 5px;width: 250px ;margin: 20px;border:5px solid #fafafa; border-radius: 10px;height: 25rem; display: flex; flex-direction: column; justify-content: space-around; align-items: center; font-family: 'RobotoCondensed-Light'"
          (click)="navigateToDescripcionProducto(producto.category_link,producto.id_product,producto.link_rewrite,producto.paquete,producto.sku)">
          <img class="imgItem" src="{{producto.image_link}}" width="250px" height="250px">
          <p class="fixin" style="margin-left:20px;font-size: 15px;">{{producto.title}}</p>
          <p class="ion-text-center"><b
              style="color:#5dc0e0!important;font-size: 1.3em; font-family: 'RobotoCondensed-Light';">${{producto.precio
              | number: '1.2-2'}} mxn</b></p>
          <ion-row>
          </ion-row>
        </div>
        <span>b</span>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>