import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [
    IonicModule
  ],
  selector: 'app-codigo-qr',
  templateUrl: './codigo-qr.component.html',
  styleUrls: ['./codigo-qr.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None
})
export class CodigoQrComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Lidepuntos");
  }

  clickChat () {
    (document.getElementsByClassName('container__header')[0].children[0] as HTMLElement).click();
    // var boton2 = document.getElementsByClassName('header--offline')[0].children[0] as HTMLElement;
   
    // if (boton2) {
    //     boton2.click();
    // }
  }
}
