<section class="">
    <div class="conten-cont" style="">
        <!-- <p class="texto-azul-desk" style="font-size: 1.5em;"><strong>¡COMPRA Y RECOGE EN ALMACÉN CHIHUAHUA!</strong></p> -->
        <div class="container-1" style="justify-content: center; align-items: flex-start; gap: 5em;">
            <!-- Imagen del Banner -->
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <img src="/assets/img/rally/01-Banner-Landingpage-RALLY-Lideart-9no.webp" alt="" style="max-width: 100%;height: auto;">
            </div>
            <p class="main-title" style="font-size: 3.2em;text-align: center;color: #606060;">¡¡¡En septiembre Lideart festeja su 9° cumpleaños!!!</p>
            <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;padding: 0em 4em;">Por ello festejaremos con un RALLY POR ANIVERSARIO, en el cual queremos ver toda tu creatividad e inspiración para festejar y premiarte con grandes sorpresas. TODOS pueden participar, desde nuestros primeros clientes hasta los más nuevos, solo contempla que debes encontrarte dentro de la REPÚBLICA MEXICANA. Tendremos 3 grandes premios para el primer, segundo y tercer lugar.</p>
            <p class="texto-azul-desk" style="font-size: 2em;"><strong>BASES</strong></p>
            <p class="sub-title" style="font-size: 1.5em;text-align: justify;color: #606060;padding: 0em 4em;">El Rally por Aniversario de Lideart constará de 3 proyectos los cuales deberán ser creativos, originales y detallados.</p>
            <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;padding: 0em 4em;">Este año nos quisimos inspirar en nuestro colorido, diverso y muy querido MÉXICO, no encontramos una mejor manera de festejar que honrando al lugar donde vivimos.</p>
            <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;padding: 0em 4em;">Los requisitos para participar en el RALLY son los siguientes:</p>
            <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;padding: 0em 12em;">1. Deberán llenar el formulario para el registro del evento en el siguiente link: <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhYYYqculHN18qTthTjT9zYNF-GGbLm8uFsG18M0VSpSnARQ/viewform">Formulario</a></p>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 6.7em;">
                <img class="logos" class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-01.webp" alt="" style="width: 9%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">2. <strong>El primer proyecto</strong> será solicitado por correo con las especificaciones necesarias el día 13/09/2024 a las 10:00a.m horario de la CDMX</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 12.5em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-04.webp" alt="" style="width: 4%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">¿No te llegó el correo? Avísanos ese mismo día antes de las 5:30 pm por mensaje en Facebook o Instagram de LIDEART, después de este horario ya no se reenviará el correo.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 12.5em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-05.webp" alt="" style="width: 4%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Elabora tu proyecto con mucha creatividad OJO no se te olvide grabar el proceso de elaboración.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 9em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-06.webp" alt="" style="width: 7%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Publica el video de tu proceso y del proyecto terminado, en tu REEL DE INSTAGRAM y TIKTOK el día 16/09/2024, tienes hasta las 11:59 p.m. horario de la CDMX, contempla que tu perfil debe estar en modo público para que podamos visualizar los videos.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-07.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Usa los hashtags #aniversariolideart #rallylideart #cricutmexico en el copy de tu publicación.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-08.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Etiqueta a &#64;lideart y a &#64;cricutmexico</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-09.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Una vez que este publicado en ambas redes (Tiktok e Instagram) deberás mandar los dos links al WhatsApp +52 614 605 1278.</p>
            </div>

            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 6.7em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-02.webp" alt="" style="width: 9%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">3. <strong>El segundo proyecto</strong> será solicitado por correo con las especificaciones necesarias el día 17/09/2024 a las 10:00a.m horario de la CDMX</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 12.5em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-04.webp" alt="" style="width: 4%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">¿No te llegó el correo? Avísanos ese mismo día antes de las 5:30 pm por mensaje en Facebook o Instagram de LIDEART, después de este horario ya no se reenviará el correo.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 12.5em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-05.webp" alt="" style="width: 4%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Elabora tu proyecto con mucha creatividad OJO no se te olvide grabar el proceso de elaboración.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 9em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-06.webp" alt="" style="width: 7%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Publica el video de tu proceso y del proyecto terminado, en tu REEL DE INSTAGRAM y TIKTOK el día 20/09/2024, tienes hasta las 11:59 p.m. horario de la CDMX, contempla que tu perfil debe estar en modo público para que podamos visualizar los videos.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-07.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Usa los hashtags #aniversariolideart #rallylideart #cricutmexico en el copy de tu publicación.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-08.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Etiqueta a &#64;lideart y a &#64;cricutmexico</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-09.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Una vez que este publicado en ambas redes (Tiktok e Instagram) deberás mandar los dos links al WhatsApp +52 614 605 1278.</p>
            </div>

            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 6.7em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-03.webp" alt="" style="width: 9%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">4. <strong>El tercer proyecto</strong> será solicitado por correo con las especificaciones necesarias el día 20/09/2024 a las 10:00a.m horario de la CDMX</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 12.5em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-04.webp" alt="" style="width: 4%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">¿No te llegó el correo? Avísanos ese mismo día antes de las 5:30 pm por mensaje en Facebook o Instagram de LIDEART, después de este horario ya no se reenviará el correo.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 12.5em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-05.webp" alt="" style="width: 4%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Elabora tu proyecto con mucha creatividad OJO no se te olvide grabar el proceso de elaboración.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 9em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-06.webp" alt="" style="width: 7%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Publica el video de tu proceso y del proyecto terminado, en tu REEL DE INSTAGRAM y TIKTOK el día 23/09/2024, tienes hasta las 11:59 p.m. horario de la CDMX, contempla que tu perfil debe estar en modo público para que podamos visualizar los videos.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-07.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Usa los hashtags #aniversariolideart #rallylideart #cricutmexico en el copy de tu publicación.</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-08.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Etiqueta a &#64;lideart y a &#64;cricutmexico</p>
            </div>
            <div  class="banner-container-d" style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;gap: 1.5em;padding: 0px 13em;">
                <img class="logos" src="/assets/img/rally/02-Íconos-laningpage-RALLY-Lideart-9no-09.webp" alt="" style="width: 3%;height: auto;">
                <p class="regular-text" style="font-size: 1.5em;text-align: justify;color: #606060;">Una vez que este publicado en ambas redes (Tiktok e Instagram) deberás mandar los dos links al WhatsApp +52 614 605 1278.</p>
            </div>

            <p class="texto-azul-desk" style="font-size: 2em;"><strong>PREMIOS</strong></p>
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <img src="/assets/img/rally/03-PREMIOS-Rally-Lideart-9no-12.webp" alt="" style="max-width: 100%;height: auto;">
            </div>
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhYYYqculHN18qTthTjT9zYNF-GGbLm8uFsG18M0VSpSnARQ/viewform" target="_blank">
                    <img src="/assets/img/rally/04-Banner-PieDePag-Rally-Lideart-9no-13.webp" alt="" style="max-width: 100%;height: auto;" href="https://docs.google.com/forms/d/e/1FAIpQLSfhYYYqculHN18qTthTjT9zYNF-GGbLm8uFsG18M0VSpSnARQ/viewform">
                </a>
            </div>
        </div>
    </div>
</section>