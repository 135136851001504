*{
    padding: 0;
    margin: 0;
}

.cont-colorin{
    background: linear-gradient(90deg, #feece8, #9dd8e6);
    padding: 1em;

    .cont-info{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1em;
        padding: 1em;
        border-radius: 1em;
        background-color: #fff;
    }
}

.registro-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2em 1em;
    overflow-y: scroll;

    .logo-lide-registro{
        width: 14em;
        height: auto;
    }

    .titl-registro{
        font-weight: 400;
        font-size: 2.5em;
        color: #3bc1e5;
        text-shadow: 2px 3px 1px #1da8ce;
        margin-block-start: .1em;
        margin-bottom: .7em;
    }

    .formRegistro{
        width: 40%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        .list-msgs{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
    
            .msgs-error{
                color: #f10707;
                font-weight: 100;
                font-size: .84em;
            }
        }
    
        .msgs-correcto{
            color: #39da08;
            font-weight: 100;
            font-size: .84em;
        }
    
        mat-form-field{
            width: 100%;
            height: auto;
    
            input{
                width: 100%;
            }
        }
    
        .tog-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0em 2em;
            margin-bottom: 1em;
    
            .txtito{
                color: #000;
                font-weight: 100;
                font-size: 1em;
            }
        }
    
        .chk-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
    
            .vrp{
                text-decoration: none;
                font-size: 1em;
                font-weight: 100;
                color: #00a8cb;
                transition: .5s all ease-in-out;
            }
    
            .vrp:hover{
                color: #000;
            }
    
            .fil{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0em 3em;
                gap: 1em;
                margin-bottom: 1em;
    
                mat-checkbox{
    
                }
    
                .anun{
                    color: #000;
                    font-weight: 100;
                    text-align: center;
                    width: 80%;
                }
            }
        }
    
        .btn-reg{
            background-color: #00a8cb;
            width: 100%;
            height: 3em;
            color: #fff;
            font-size: 1.3em;
            font-weight: 100;
        }
    }
}

@media(max-width: 992px){
    .registro-cont{
        padding: 1em;

        .formRegistro{
            width: 100%;
        }
    }
}

.mdc-switch:enabled .mdc-switch__track::after {
    background: #00a8cb!important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #00a8cb!important;
}

.cdk-overlay-container {
    z-index: 99999;
}

.mat-form-field-appearance-outline .mat-form-field-outline { color: black; }

.cont{ 
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    backdrop-filter: blur(50px);
}

ion-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-card{
    background: #fdfdfd;//     #fdfdfd            #14A8CD
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
    .where{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        margin-top: 2rem;
        margin-bottom: 1rem;

        a{
            font-size: 1rem;
            font-family: 'Roboto', sans-serif;
            text-decoration: none;
            font-weight: 600;
            color: #000;

            span{
                display:inline-block;
                border-bottom: 3px solid #14A8CD;
                padding-bottom: .5rem;
            }
        }

        #regis{
            font-weight: 600;
            color: #14A8CD;
        }
    }

    img{
        width: 60%;
        padding: 1rem;
    }

    form{
        width: 100%;
        height: auto;
    }

    .error{
        color: red;
        font-size: 1rem;
        font-weight: bolder;
    }

    .correcto{
        color: green;
        font-size: 1rem;
        font-weight: bolder;
    }

    ion-item{
        --ion-item-background: none !important;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        
    }

    ion-button{
        height: 4rem;
        font-family: 'Roboto', sans-serif;
        font-size: .8rem;
        letter-spacing: .1rem;
        font-weight: 600;
        --background: #14A8CD;
        color: #fff;
    }

    ion-button:hover{
        --background: #14A8CD!important;
        color: #fff;
        transition: 1s;
    }

    ion-input{
        color: #000;
        font-weight: 600;

        /*--placeholder-color: #000;
        --placeholder-weight: 600;*/
    }

    .custom-placeholder{
        color: #000;
        font-size: 1.1rem;
        font-weight: bolder;
    }

    ion-label{
        color: black;
        font-weight: 600;
    }

    .item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color){
        color: #000;
    }

    .item-interactive.item-has-focus{
        --highlight-background: #000;
    }

    .forgot{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        a{
            font-size: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: #000;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
        }
    }

    ion-text{
        color: #000;
    }

    ion-list{
        background: none!important;

        ion-item{
            --background: none !important;
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-left: 0;
            padding-right: 0;

            ion-checkbox{
                //border: 1.5px solid black;
                width: 1.2rem;
                height: 1.2rem;
                --border-radius: .6rem;
                text-align: center;
            }

            ion-label{
                margin-left: 1rem;
                white-space: normal !important;
                text-align: center;
            }
        }
    }
}

.mod{
    --background: black;
}

#closeModal{
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

@media(min-width: 992px){
    ion-card{
        width: 30em;

        form{
            ion-item{
                margin-top: 0rem;
                margin-bottom: 0rem;
                margin: 1em 0em;

                ion-toggle{
                    padding: 1em 0em;
                }

                ion-label{
                    font-weight: 100;
                    font-size: .9em;
                }
            }
        }

        ion-list{
            .vrp{
                text-decoration: none;
                font-size: 1.1em;
            }
        }

        ion-button{
            height: 4em;
            font-family: "Roboto", sans-serif;
            font-size: 0.9em;
            /* letter-spacing: 0.1rem; */
            font-weight: 100;
            --background: #14A8CD;
            color: #fff;
        }

        ion-text{
            h1{
                font-size: 1.7em;
                font-weight: 100;
            }
        }

        .forgot{
            #closeModal{
                margin-top: 0rem;
                margin-bottom: 0rem;
                margin: 1em 0em;
                transition: .4s all ease-in-out;
                font-size: 1.1em;
            }

            #closeModal:hover{
                color: #14A8CD;
            }
        }
    }
}

.modalRecuperarCss::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
}
  
.modalRecuperarCss::-webkit-scrollbar-thumb {
    background-color: #00a2c779;
    //border-radius: 1vh;
    width: 0.25em;
}

.modal-c-wp{
    width: 800px;
    height: 800px;
    position: fixed;
    overflow-y: auto;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    top: calc(50% - 400px);
    left: calc(50% - 300px);
    z-index: 99;
    box-shadow: 0px 0px 12px 1px #42dcffbf;

    .btn-wp-cont{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;

      .btn-wp-close{
        background-color: #df0b0b;
        color: #fff;
        font-size: 1.1em;
        padding: .7em 2em;
      }
    }

    .title-mdls{
        text-align: center;
        color: #a9a9a9;
    }

    .subt-mdls{
        text-align: center;
        color: #1da8ce;
        font-weight: 500;
        font-size: 1.1em;
    }

    .subt2-mdls{
        text-align: center;
        font-weight: 400;
        color: #63d2dd;
        font-size: 1em;
    }

    .warn-mdls{
        color: #ff5c6a;
        padding: 2em 6em;
        text-align: center;
    }
}

@media(max-width: 800px){
    .modal-c-wp{
        width: 300px;
        height: 90%;
        top: calc(50% - 45%);
        left: calc(50% - 150px);

        .title-mdls{
            text-align: center;
            color: #a9a9a9;
            font-size: 1.3em;
            padding: 0em 1em;
        }
    
        .subt-mdls{
            font-weight: 500;
            font-size: 1em;
        }
    
        .subt2-mdls{
            font-weight: 400;
            font-size: .9em;
            padding: 1em;
        }

        .warn-mdls{
            padding: 2em 1em;
            font-size: .9em;
            font-weight: 100;
        }
    }
}

.loader {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: fixed;
    top: calc(0%);
    left: calc(0%);
    background-color: white;
    z-index: 9999999;
    
    
    
    img{
      width: 200px;
      height: auto;
    
    }
    
    }