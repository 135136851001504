<section class="categoriaDesk">
  <div class="contDesk">
    <div class="title-cont">
      <!-- <h1>Paquetes por marca:</h1> -->
      <img src="{{url}}/por-marca/cricut.png" alt="" style="max-width: 400px;width: 100%;">
    </div>
    <!-- <div class="conten-cats">
          <a class="cardin" [routerLink]="['/catalogo','529','cajas-misteriosas-cricut']" style="width: 99%;border-bottom: 0px solid #0ba7c7;padding: 0em 0em;">
              <div class="contImg">
                  <img src="{{url}}/por-marca/caja-misteriosa.png" alt="" style="width: 100%; margin: 0px 25px 0px 25px;">
              </div>
            </a>
      </div> -->
    <div class="conten-cats">
      <a class="cardin" [routerLink]="['/catalogo','399','cricut-plotter-de-corte']">
        <div class="contImg">
          <img src="{{url}}/399/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','400','cricut-planchas-de-calor']">
        <div class="contImg">
          <img src="{{url}}/400/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','414','cricut-vinil-y-papel-para-rotulacion']">
        <div class="contImg">
          <img src="{{url}}/414/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','415','cricut-vinil-textil']">

        <div class="contImg">
          <img src="{{url}}/415/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','409','cricut-papeles-cartulinas-y-mas']">
        <div class="contImg">
          <img src="{{url}}/409/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','650','foil-reactivo']">
        <div class="contImg">
          <img src="{{url}}/650/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','410','silhouette-cubiertas-totes']">
        <div class="contImg">
          <img src="{{url}}/410/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','411','cricut-sublimacion-blancos']">
        <div class="contImg">
          <img src="{{url}}/411/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','413','cricut-tintas-para-sublimacion']">
        <div class="contImg">
          <img src="{{url}}/413/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','401','cricut-plumas-y-marcadores']">
        <div class="contImg">
          <img src="{{url}}/401/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','402','cricut-navajas-y-accesorios-para-plotter-de-corte']">
        <div class="contImg">
          <img src="{{url}}/402/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','403','cricut-accesorios-para-planchas']">
        <div class="contImg">
          <img src="{{url}}/403/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','404','cricut-costura']">
        <div class="contImg">
          <img src="{{url}}/404/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','406','cricut-herramientas-de-corte-manual']">
        <div class="contImg">
          <img src="{{url}}/406/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','407','cricut-herramientas-de-corte-manual']">
        <div class="contImg">
          <img src="{{url}}/407/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','408','cricut-hoja-transportadora-tapetes']">
        <div class="contImg">
          <img src="{{url}}/408/1.png" alt="">
        </div>
      </a>
      <a class="cardin" [routerLink]="['/catalogo','405','cricut-cubiertas-totes']">
        <div class="contImg">
          <img src="{{url}}/405/1.png" alt="">
        </div>
      </a>
    </div>
  </div>

  <div class="contDesk" *ngIf="!cargando">

    <div class="title-cont">
      <h1 #focus>Todos los Productos</h1>
    </div>
    <div class="conten-cats">
      <div class="cardin" *ngFor="let producto of currentItemsToShow"
        (click)="navigateToDescripcionProducto(producto.category_link,producto.id_product,producto.link_rewrite)">
        <!-- items [routerLink]="['/descripcion-producto',producto.id_product,producto.link_rewrite]" -->
        <div class="contImg">
          <img src="{{producto.image_link}}" alt="">
        </div>
        <div class="line-tb">
          <div *ngIf="producto?.detalles?.atributos?.[0]?.valores?.length > 1" class="colors-container">
            <div *ngFor="let color of producto?.detalles?.atributos?.[0]?.valores; let i = index" class="r-color"
              [ngStyle]="{'background-color': color.color, 'background-image': color.image ? 'url(' + color.image + ')' : 'none'}"
              [class.hidden]="!showAllColorsMap[producto.id_product] && i >= maxVisibleColors">
            </div>
            <div
              *ngIf="producto?.detalles?.atributos?.[0]?.valores?.length > maxVisibleColors && !showAllColorsMap[producto.id_product]"
              class="r-color more-colors" (click)="toggleShowAllColors(producto.id_product); $event.stopPropagation()">
              ...
            </div>
            <div *ngIf="showAllColorsMap[producto.id_product]" class="r-color more-colors up-arrow"
              (click)="toggleShowAllColors(producto.id_product); $event.stopPropagation()">
              ↑
            </div>
          </div>
        </div>
        <span class="cat-name">{{producto.title}}</span>
        <ng-container *ngIf="producto.reduction !== undefined && producto.reduction !== null else noReduction">
          <span class="pric">${{producto.precio - (producto.precio * producto.reduction) | number: '1.2-2' }} mxn</span>
          <del class="pric">{{producto.precio | currency: '$'}} mxn</del>
        </ng-container>
        <ng-template #noReduction>
          <span class="pric">{{producto.precio | currency: '$'}} mxn</span>
        </ng-template>
      </div>
    </div>

    <label for="d" class="paginatorS">
      <mat-paginator (page)="onPageChange($event)" [pageSize]="24" [length]="tamanoLista"
        [pageSizeOptions]="[24,48,60,120]" aria-label="d" showFirstLastButtons></mat-paginator>
    </label>

  </div>
</section>