<div class="tipos-cont-cel">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <div class="conttipscat">
    <a class="enlacbtn" [routerLink]="['/categoria','10','categorías-por-tipo-de-producto']">       <!-- Este es el div boton -->
      <span>Por Producto</span>
      <div class="topcontad"></div>
      <div class="botcontad"></div>
    </a>
    <a class="enlacbtn" [routerLink]="['/categoria','11','categorías-por-marca']">       <!-- Este es el div boton --> 
      <span>Por Marca</span>
      <div class="topcontad"></div>
      <div class="botcontad"></div>
    </a>
    <a class="enlacbtn" [routerLink]="['/categoria','12','categorías-por-técnica']">       <!-- Este es el div boton -->
      <span>Por Técnica</span>
      <div class="topcontad"></div>
      <div class="botcontad"></div>
    </a>
    <a class="enlacbtn" routerLink="/paquetes">       <!-- Este es el div boton -->
      <span>Por Paquetes</span>
      <div class="topcontad"></div>
      <div class="botcontad"></div>
    </a>
    <a class="enlacbtn" [routerLink]="['/catalogo','123','outlet']">       <!-- Este es el div boton -->
      <span>Outlet</span>
      <div class="topcontad"></div>
      <div class="botcontad"></div>
    </a>
  </div>
</div>