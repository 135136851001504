import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Direccion } from '../Models/direccion';
import { Registro } from '../Models/registro.interface';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  constructor(
    public http: HttpClient
  ) { }

  registroUsuario(registro: Registro) {
    ////console.log(registro);
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/registro.php', registro);
  }

  registroDynamics(datosUsuario: any): Observable<any> {
    // return this.http.post('https://lideart.aytcloud.com/inaxapiv2/api/cliente/addClientdynamics365',datosUsuario,httpOption);
    return this.http.post('https://inax2.aytcloud.com/inaxapilideart/api/cliente/addClientdynamics365', datosUsuario, httpOption);
  }

  actualizaCustomerID(datos: any): Observable<any> {

    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/actualizaCustomerID.php', datos);
  }

  correoRegistro(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/Email/emailBienvenida", cotizacion);
  }



  MailchimpReg(cotizacion: any): Observable<Response> {

    return this.http.post<Response>("https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/Email/emailBienvenida", cotizacion);
  }

  getEstados(codigopostal: any) {
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/cliente/getStateCountry/' + codigopostal);
  }
  getCiudades(codigopostal: any, ciudad: any) {
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/CodigosPostales/ciudadesPostal/' + ciudad + '/' + codigopostal);
  }
  getColonias(codigopostal: any) {
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/codigospostales/cpPostal/' + codigopostal);
  }

  enviaDireccion(direccion: Direccion) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/registraDireccion.php', direccion);
  }

  // enviaMailchimp(datos: any): Observable<any> {
  //   return this.http.post('https://us1.api.mailchimp.com/3.0/lists/9478bd752d/members', datos);
  // }

}

