<div class="logincont">
    
  <div class="login">
      <div class="titulo">
          <p>¡Hola! <strong>{{key}}</strong> Por favor ingresa tu nueva contraseña</p>
          <div class="separator"></div>
      </div>
      <form>
          <div class="sup">
              <p class="texti">Contraseña*</p>
              <input class="form-control" type="password" name="password" [(ngModel)]="password"  placeholder="Contraseña" required="required" id="inputPassword"/>
    
              <p class="texti">Confirmar Contraseña*</p>
              <input class="form-control"  type="password" name="password2" [(ngModel)]="password2"  placeholder="Confirmar Contraseña" required="required" id="inputPassword2"/>
              <button (click)="reseteame()" >Solicitar Recuperación de contraseña</button>
          </div>
      </form>
  </div>
</div>