<div class="registro-cont" style=" margin-top: 100px;">

  <div class="modal-c-wp" #modal_wp id="modal_wp">
    <div class="btn-wp-cont">
      <button class="btn-wp-close" (click)="handleWP()">Cerrar</button>

    </div>
    <img src="assets/img/hola_lideart.jpg" style="width: 100%;" class="logo-lide-registro" alt="Logo Lideart">
    <h1 class="title-mdls">¡Hola! {{nombresito+" "+apellidito}}</h1>
    <p class="subt-mdls">Gracias por crear una cuenta de cliente en Lideart.</p>
    <p class="subt2-mdls">Estas a un paso de pertenecer a nuestra comunidad, solo necesitamos verificar tu información.
    </p>
    <br>
    <div class="cont-colorin">
      <div class="cont-info">
        <p style="color: #606060; font-weight: 500; font-size: 1.2em;">Tus datos de cliente son:</p>
        <p style="color: #606060; font-weight: 300; font-size: .9em;">Nombre: {{nombresito}}</p>
        <p style="color: #606060; font-weight: 300; font-size: .9em;">Apellidos: {{apellidito}}</p>
        <p style="color: #606060; font-weight: 300; font-size: .9em;">RFC: {{rfc}}</p>
        <p style="color: #606060; font-weight: 300; font-size: .9em;">Correo Electronico: {{correito}} </p>
        <p style="color: #606060; font-weight: 300; font-size: .9em;">Direccion: {{Direccionsilla}}</p>
      </div>
    </div>
    <br>
    <img src="assets/img/datos_lideart.jpg" routerLink="/login" style="width: 100%;cursor: pointer;"
      class="logo-lide-registro" alt="Logo Lideart">
    <p class="warn-mdls">Puedes llenar esta información siempre que quieras en el apartado de "Mi Perfil" dentro de las
      opciones de "Mi Cuenta".</p>
  </div>

  <img src="assets/img/lideart_logo.png" class="logo-lide-registro" alt="Logo Lideart">
  <h1 class="titl-registro">Crea tu cuenta</h1>

  <!--<div class="where">
    <a href=""><span>INICIAR SESIÓN</span></a>
    <a id="regis" href="">REGISTRARSE</a>
  </div>-->

  <form class="formRegistro" [formGroup]="regisForm" (ngSubmit)="registrame()">

    <mat-form-field appearance="fill">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="nombre" required>
      @if (isSubmitted && f['nombre'].errors) {
      <div class="list-msgs">
        @if (f['nombre'].errors['required']) {
        <mat-hint class="msgs-error">
          Nombre obligatorio.
        </mat-hint>
        }
        @if (f['nombre'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Solo letras.
        </mat-hint>
        }
        @if (f['nombre'].errors['minlength']) {
        <mat-hint class="msgs-error">
          Nombre muy corto.
        </mat-hint>
        }
        @if (f['nombre'].errors['maxlength']) {
        <mat-hint class="msgs-error">
          Nombre muy largo.
        </mat-hint>
        }
      </div>
      }
      @if (f['nombre'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Apellidos</mat-label>
      <input matInput formControlName="apellidos" type="text" required>
      @if (isSubmitted && f['apellidos'].errors) {
      <div class="list-msgs">
        @if (f['apellidos'].errors['required']) {
        <mat-hint class="msgs-error">
          Apellido obligatorio.
        </mat-hint>
        }
        @if (f['apellidos'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Solo letras.
        </mat-hint>
        }
        @if (f['apellidos'].errors['minlength']) {
        <mat-hint class="msgs-error">
          Apellido muy corto.
        </mat-hint>
        }
        @if (f['apellidos'].errors['maxlength']) {
        <mat-hint class="msgs-error">
          Apellido muy largo.
        </mat-hint>
        }
      </div>
      }
      @if (f['apellidos'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }



    </mat-form-field>

    <div class="tog-cont">
      <span class="txtito">¿Desea Facturar?</span>
      <mat-slide-toggle (click)="facturado()" value="accent"></mat-slide-toggle>
    </div>

    <mat-form-field *ngIf="facturame == true">
      <mat-label>RFC</mat-label>
      <input matInput formControlName="rfc" type="text" class="custom-placeholder"
        placeholder="Opcional, solo si se quiere factura">
      @if (isSubmitted && f['rfc'].errors) {
      <div class="list-msgs">
        @if (f['rfc'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Solo letras y números.
        </mat-hint>
        }
        @if (f['rfc'].errors['minlength']) {
        <mat-hint class="msgs-error">
          RFC muy corto.
        </mat-hint>
        }
        @if (f['rfc'].errors['maxlength']) {
        <mat-hint class="msgs-error">
          RFC muy largo.
        </mat-hint>
        }
      </div>
      }
    </mat-form-field>
    <mat-form-field *ngIf="facturame == true">
      <mat-label>Razon Social</mat-label>
      <input matInput formControlName="razon" type="text" class="custom-placeholder"
        placeholder="Opcional, solo si se quiere factura">
    </mat-form-field>
    <mat-form-field *ngIf="facturame == true">
      <mat-label>Select</mat-label>
      <mat-select [(ngModel)]="usocfdi" [ngModelOptions]="{standalone: true}" name="test">
        <mat-option value="G01">G01 - Adquisición de mercancías</mat-option>
        <mat-option value="G02">G02 - Devoluciones, descuentos o bonificaciones</mat-option>
        <mat-option value="G03">G03 - Gastos en general</mat-option>
        <mat-option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</mat-option>
        <mat-option value="I04">I04 - Equipo de cómputo y accesorios</mat-option>
        <mat-option value="I08">I08 - Otra maquinaria y equipo</mat-option>
        <mat-option value="P01">P01 - Por definir</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="facturame == true">
      <mat-label>Select</mat-label>
      <mat-select [(ngModel)]="regimenFiscal" [ngModelOptions]="{standalone: true}">
        <mat-option value="601">601 - General de Ley Personas Morales</mat-option>
        <mat-option value="603">603 - Personas Morales con Fines no Lucrativos</mat-option>
        <mat-option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</mat-option>
        <mat-option value="606">606 - Arrendamiento</mat-option>
        <mat-option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</mat-option>
        <mat-option value="607">607 - Demás ingresos</mat-option>
        <mat-option value="607">607 - Consolidación</mat-option>
        <mat-option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</mat-option>
        <mat-option value="611">611 - Ingresos por Dividendos (socios y accionistas)</mat-option>
        <mat-option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</mat-option>
        <mat-option value="614">614 - Ingresos por intereses</mat-option>
        <mat-option value="615">615 - Régimen de los ingresos por obtención de premios</mat-option>
        <mat-option value="616">616 - Sin obligaciones fiscales</mat-option>
        <mat-option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus
          ingresos</mat-option>
        <mat-option value="621">621 - Incorporación Fiscal</mat-option>
        <mat-option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</mat-option>
        <mat-option value="623">623 - Opcional para Grupos de Sociedades</mat-option>
        <mat-option value="624">624 - Coordinados</mat-option>
        <mat-option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas
          Tecnológicas</mat-option>
        <mat-option value="626">626 - Régimen Simplificado de Confianza</mat-option>
        <mat-option value="628">628 - Hidrocarburos</mat-option>
        <mat-option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas
          Multinacionales</mat-option>
        <mat-option value="630">630 - Enajenación de acciones en bolsa de valores</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label position="floating">Correo Electrónico</mat-label>
      <input matInput formControlName="email" type="email">
      @if (isSubmitted && f['email'].errors) {
      <div class="list-msgs">
        @if (f['email'].errors['required']) {
        <mat-hint class="msgs-error">
          El correo electrónico es obligatorio.
        </mat-hint>
        }
        @if (f['email'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Debe ser formato de correo electrónico (ejemplo&#64;ejem.com).
        </mat-hint>
        }
      </div>
      }
      @if (f['email'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Contraseña</mat-label>
      <input matInput formControlName="password" type="password">
      @if (isSubmitted && f['password'].errors) {
      <div class="list-msgs">
        @if (f['password'].errors['required']) {
        <mat-hint class="msgs-error">
          La contraseña es obligatoria.
        </mat-hint>
        }
        @if (f['password'].errors['minlength']) {
        <mat-hint class="msgs-error">
          Debe tener al menos 8 carácteres.
        </mat-hint>
        }
      </div>
      }
      @if (f['password'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Alias</mat-label>
      <input matInput name="alias" formControlName="alias" type="text">
      <input matInput type="text" formControlName="correo2" name="correo2" hidden="true">
      @if (isSubmitted && f['alias'].errors) {
      <div class="list-msgs">
        @if (f['alias'].errors['required']) {
        <mat-hint class="msgs-error">
          El alias es obligatorio.
        </mat-hint>
        }
      </div>
      }
      @if (f['alias'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dirección</mat-label>
      <input matInput type="text" name="address1" formControlName="address1">
      @if (isSubmitted && f['address1'].errors) {
      <div class="list-msgs">
        @if (f['address1'].errors['required']) {
        <mat-hint class="msgs-error">
          La dirección es obligatoria.
        </mat-hint>
        }
      </div>
      }
      @if (f['address1'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dirección Complementaria</mat-label>
      <input matInput type="text" name="address2" formControlName="address2">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Telefono</mat-label>
      <input matInput type="tel" name="phonenumber" formControlName="phonenumber">
      @if (isSubmitted && f['phonenumber'].errors) {
      <div class="list-msgs">
        @if (f['phonenumber'].errors['required']) {
        <mat-hint class="msgs-error">
          El número telefónico es obligatorio.
        </mat-hint>
        }
        @if (f['phonenumber'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Solo números.
        </mat-hint>
        }
      </div>
      }
      @if (f['phonenumber'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Código Postal</mat-label>
      <input matInput type="text" name="zipcode" formControlName="zipcode"
        (change)="buscameXCodigoPostal(this.regisForm.controls['zipcode'].value)">
      @if (isSubmitted && f['zipcode'].errors) {
      <div class="list-msgs">
        @if (f['zipcode'].errors['required']) {
        <mat-hint class="msgs-error">
          El código postal es obligatorio.
        </mat-hint>
        }
        @if (f['zipcode'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Solo números.
        </mat-hint>
        }
      </div>
      }
      @if (f['zipcode'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </mat-form-field>


    <mat-form-field *ngIf="codigoEscrito == 1">
      <!-- <ion-label position="floating">Código Postal</ion-label> -->
      <input matInput type="text" name="id_state" formControlName="stateValue">{{ciudadsinCodigo}}
    </mat-form-field>


    <mat-form-field *ngIf="codigoEscrito == 1">
      <!-- <ion-label position="floating">Código Postal</ion-label> -->
      <input matInput type="text" name="city" formControlName="cityValue" value="{{ciudadSeleccionada}}">
    </mat-form-field>

    <mat-form-field *ngIf="codigoEscrito == 1">
      <mat-select style="color: black;background-color: white;" name='colonyValue' (selectionChange)="imprimir()"
        formControlName="colonyValue">
        <mat-option value="" selected>--- Selecciona Colonia ---</mat-option>
        <mat-option *ngFor="let item of arrayColonias"
          value="{{item.colonia+'-'+item.countyId}}">{{item.colonia}}</mat-option>
      </mat-select>
    </mat-form-field>


    <div class="chk-cont">
      <div
        style="width: 100%; height: auto; display: flex; justify-content: center; align-items: center; margin-bottom: 1em;">
        <a [routerLink]="['/terminos-politicas/3']" class="vrp" target="blank">Ver políticas de seguridad</a>
      </div>

      <div class="fil">
        <mat-checkbox formControlName="news" [checked]="true"></mat-checkbox>
        <span class="anun">Suscribirse a nuestro boletín de noticias</span>
      </div>

      <div class="fil">
        <mat-checkbox formControlName="priv"></mat-checkbox>
        <span class="anun">Privacidad de los datos del cliente</span>
      </div>
      @if (isSubmitted && f['priv'].errors) {
      <div class="list-msgs" style="align-items: center;">
        @if (f['priv'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Se debe aceptar "Privacidad de los datos del cliente".
        </mat-hint>
        }
      </div>
      }
      @if (f['priv'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }

      <div class="fil">
        <mat-checkbox formControlName="term"></mat-checkbox>
        <span class="anun">Acepto las condiciones generales y la política de confidencialidad</span>
      </div>
      @if (isSubmitted && f['term'].errors) {
      <div class="list-msgs" style="align-items: center;">
        @if (f['term'].errors['pattern']) {
        <mat-hint class="msgs-error">
          Se debe aceptar "Condiciones generales y la política de confidencialidad".
        </mat-hint>
        }
      </div>
      }
      @if (f['term'].valid){
      <mat-hint class="msgs-correcto">
        Correcto
      </mat-hint>
      }
    </div>

    <button type="submit" class="btn-reg">Registrarse</button>

  </form>

  <div class="forgot">
    <!-- <a id="closeModal" (click)="dismiss()">Cerrar</a> -->
  </div>
</div>



<app-modal-carga icon="warning" [ttl]="180" [isLoading]="cargando"
  alertText="La página está tardando un poco más de lo habitual en cargar. Gracias por tu paciencia. Mientras tanto, puedes seguir explorando nuestros productos y ofertas"
  alertTitle="Cargando…"></app-modal-carga>