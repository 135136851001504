<section class="softDesk">
  <div class="softCont">
    <div class="taitl-cont">
      <span class="taitl">Software De Equipos</span>
    </div>
    <div class="conten-cont">
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.sawgrassink.com/applications/designmate/" class="ir" target="blank">Ir al curso</a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://www.silhouetteamerica.com/software" class="linka" target="blank" title="Silhouette Studio">
            <img src="https://new.lideart.com.mx/img/cms/silhouette/silohuette_studio.jpg" alt="">
          </a>
          <a href="https://www.silhouetteamerica.com/software/sm" class="linka" target="blank" title="Silhouette Go">
            <img src="https://new.lideart.com.mx/img/cms/silhouette/silohuette_go.jpg" alt="">
          </a>
          <a href="https://www.silhouetteamerica.com/software/3d" class="linka" target="blank" title="Silhouette 3D">
            <img src="https://new.lideart.com.mx/img/cms/silhouette/silohuette_3D.jpg" alt="">
          </a>
          <a href="https://www.silhouetteamerica.com/software/ms" class="linka" target="blank" title="Silhouette Mint">
            <img src="https://new.lideart.com.mx/img/cms/silhouette/silohuette_mint.jpg" alt="">
          </a>
        </div>
      </div>
      <div class="opt">
        <div class="tapa1">
          <a href="https://design.cricut.com/#/" class="ir2" target="blank">Ir al curso</a>
        </div>
      </div>
    </div>
  </div>
</section>