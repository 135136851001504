import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-facturacion',
  standalone: true,
  imports: [ CommonModule,
    FormsModule,],
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.scss'],
})
export class FacturacionComponent  implements OnInit {


  

 
datillos :any=[]
  cargando = false;
  mensaje:any;
  sisearmo = 0;
  telefono:any;
  usocdfi = "00"
  error = 0;
  datosVenta:any=[]
  venta : any = []

   nombre="";
  codigoPostal="";
   ov=""
  fechita="";
  fechaActual="";
  correo ="";
   metodoPago = "01"
   regimen='00';
    rfc:any;

  CustomerID:any;
  facturameee = 1
  facturameee2 = 0

 

  constructor(public usuariosLogin: LoginService) { }

  ngOnInit() {}


  facturar(){

    if (this.ov == "" || this.rfc == "" || this.nombre =="" || this.codigoPostal =="" || this.correo =="" || this.regimen =="") {
      Swal.fire({
        title: "Error!",
        text: "Favor de llenar todos los campos",
        icon: "error"
      });
    }else{

      this.usuariosLogin.DetalledeVenta2(this.ov).subscribe(resumen =>{
        this.datosVenta = resumen;
        this.fechita = this.datosVenta.data.fechaCreacion;
       
      
  
      })
  
  this.cargando =  true;
  
  let jsonFactura = {
    "RFCNumber":this.rfc,
    "OrganizationName":this.nombre,
    "AddressZipCode":this.codigoPostal,
    "Regimen":this.regimen,
    "SATPurpose_MX":this.usocdfi,
    "PrimaryContactEmail":this.correo,
    "PrimaryContactPhone":this.telefono
  }
  
  
  
  
  
    this.usuariosLogin.VerificaDatosSAT(jsonFactura).subscribe(datillos =>{
  this.datillos = datillos
    if (this.datillos.exito ==1) {
      this.error = 0;
      this.sisearmo = 1;
      this.mensaje = this.datillos.mensaje
    Swal.fire({
  title: "Los datos son correctos",
  text: "¿Desea Refacturar la Orden de venta " +this.ov+ " a con el ID "+this.datillos.data,
  icon: "success",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Si, Facturar"
}).then((result) => {
  if (result.isConfirmed) {
    let jsonFactura2 = {
      "datosOV":[{
          "folio":this.ov,
          "tipoPago":this.metodoPago,
          "cliente": this.datillos.data
   
   
  }]
  }
  this.cargando =true;

    this.usuariosLogin.RefacturarSat(jsonFactura2).subscribe(datillos =>{
      this.cargando =false;
      
      this.nombre="";
      this.codigoPostal="";
      this.ov=""
      this.fechita="";
      this.fechaActual="";
      this.correo ="";
      this.metodoPago = "01"
      this.regimen='00';
      this.rfc="";
      this.telefono= ""
      this.usocdfi = "00"
      this.mensaje = "";
      Swal.fire({
        title: "Facturado!",
        text: "Tu orden de venta ha sido facturada, la recibiras en un maximo de 24 a 72 horas en tu correo, recuerda revisar en SPAM.",
        icon: "success"
      });
    })



  }
});
    }else{
      this.sisearmo = 0;
      this.error = 1;
      Swal.fire({
        title: "Error!",
        text: "Datos Fiscales Incorrectos",
        icon: "error"
      });
    }
    this.cargando =  false;
  
  })
  
    }

  }



}
