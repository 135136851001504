.content-cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2em 1em;

    .contenido{
        width: 70%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;

        h1{
            margin: 0em;
            font-size: 1.6em;
            font-weight: 100;
            color: #0187a5;
            padding-bottom: 0.5em;
            position: relative;

            .adorn{
                position: absolute;
                width: 3em;
                height: 2px;
                background-color: #00a3c7;
                bottom: 0;
                left: calc(50% - 1.5em);
            }
        }

        ul{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: .75em;

            li{
                font-size: .9em;
                color: #555;
            }
        }

        ol{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: .75em;

            li{
                font-size: .9em;
                color: #555;
            }
        }

        .cont-btn{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .btn-print{
                padding: 1em 2em;
                color: #fff;
                font-size: .8em;
                font-weight: 100;
                letter-spacing: .5px;
                background-color: #000;
                transition: .6s all ease-in-out;
                text-align: center;
            }

            .btn-print:hover{
                background-color: #009bba;
            }
        }

        .info-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: .2em;

            span{
                color: #222;
                font-size: .8em;
                font-weight: 100;
            }
        }
        
        .spans-cont{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: .25em;

            span{
                color: #222;
                font-size: .9em;
                font-weight: 100;
            }

            .taitl{
                font-size: 1em;
                font-weight: 400;
                margin-bottom: 1em;
            }
        }

        .download{
            padding: 1em 2em;
            color: #fff;
            font-size: .8em;
            font-weight: 100;
            letter-spacing: .5px;
            background-color: #000;
            transition: .6s all ease-in-out;
            text-decoration: none;
            text-align: center;
        }

        .download:hover{
            background-color: #009bba;
        }
    }
}

.dheader{
    display: none;
}

.dfooter{
    display: none;
}

@media(min-width: 992px){
    ion-header{
        display: none;
    }

    .dheader{
        display: block;
    }
    
    .dfooter{
        display: block;
    }
}

@media(max-width: 992px){
    .content-cont{
        .contenido{
            width: 100%;
        }
    }
}