import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ApiProductosService } from '../../services/api-productos.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule,RouterModule,IonicModule],
  selector: 'app-productos-relacionados-tool',
  templateUrl: './productos-relacionados-tool.component.html',
  styleUrls: ['./productos-relacionados-tool.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductosRelacionadosToolComponent  implements OnInit {

   id_product:any;
   id_product2:any;
   descripcion:any=[]
   buscado = 0;
   buscado2 = 0;
   nombre_producto:any;
  sku:any;
  imagen:any;

  id_product_principal:any;
  prodRelac:any =[]
  sitiene=0
  descripcion2:any=[]
nombre_producto2:any;
 sku2:any;
 imagen2:any;
 orden:any;
 id_product_relacionado: any;
 link_rewrite:any;
 cat_rewrite:any;
 paquete:any;
 sku_product_relacionado:any;
 title:any;
 image_link:any;
 registrado:any;


  constructor(private productoService: ApiProductosService) { }

  ngOnInit() {}



  consultalo(){



    
    this.productoService.getDescripcionProductoRelacionado(this.id_product.trim()).subscribe(productazo =>{

      try {
        this.descripcion = productazo;
        if (this.descripcion.length>0) {
          this.buscado = 1
        }
    
        this.nombre_producto = this.descripcion[0].title;
        this.sku = this.descripcion[0].sku
        this.imagen = this.descripcion[0].image_link
        this.id_product_principal = this.descripcion[0].id_product
       


        this.productoService.getProdRelac(this.id_product_principal).subscribe(prodRelac =>{

          this.prodRelac = prodRelac;

    

        
            try {
              if (this.prodRelac.length>0) {
                this.sitiene =1
              }
            } catch (error) {
              this.sitiene =0
            }
      

             if (this.prodRelac == null || this.prodRelac == undefined || this.prodRelac == "") {
            this.sitiene =0
          }

        })
    
      } catch (error) {
    
      }

    })
  }


  borrameProdRelac(id:any){


    Swal.fire({
      title: "¿Borrar Este Producto Relacionado?",
      text: "Una vez borrado no se podra recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.productoService.BorrarProcRelac(id).subscribe(borradito =>{
          Swal.fire({
            title: "Eliminado!",
            text: "Producto Borrado Correctamente",
            icon: "success"
          });
          this.consultalo();
    
        })
 
      }
    });


  }


  
async showSuccess() {

  Swal.fire({
    icon: "success",
    title: "Exito!",
    text: "Producto Relacionado Agregado Correctamente",

  });


}

async showSuccessf() {

  Swal.fire({
    icon: "error",
    title: "Error!",
    text: "Este Producto ya se encuentra relacionado",

  });


}

  actualizaOrden(id_relacion:any,orden:any){



    

    // (<HTMLInputElement>document.getElementById('orden'+num)).value = orden;

    // document.getElementById('orden'+num).)value as HTMLInputElement

    let jsonActualiza = {
      "id_relacion": id_relacion,
      "orden":orden

    }
    this.productoService.actualizaOrdenProdRelac(jsonActualiza).subscribe(borradito =>{
      this.consultalo()
      
    })
  }

  registraProductoRelacionado(){


    let jsonRegistra = {
"id_product_relacionado": this.id_product_principal,
"id_product":  this.id_product_relacionado,
"title":this.title,
"image_link":this.image_link,
"link_rewrite":  this.link_rewrite,
"cat_rewrite":  this.cat_rewrite,
"paquete":  this.paquete,
"sku": this.sku_product_relacionado

    }

this.productoService.registraProductoRelacionado(JSON.stringify(jsonRegistra)).subscribe(registrado =>{

  this.registrado = registrado;

  if (this.registrado == 1) {
    this.showSuccessf();
  }else{
    this.consultalo();
    this.showSuccess() 
  }



})

  }

  consultalo2(){



    
    this.productoService.getDescripcionProductoRelacionado(this.id_product2.trim()).subscribe(productazo2 =>{

      try {
        this.descripcion2 = productazo2;
        if (this.descripcion2.length>0) {
          this.buscado2 = 1
        }else{
          this.buscado2 = 0
        }

        
    
        this.nombre_producto2 = this.descripcion2[0].title;
        this.sku2 = this.descripcion2[0].sku
        this.imagen2 = this.descripcion2[0].image_link
        this.id_product_relacionado = this.descripcion2[0].id_product;
        this.link_rewrite = this.descripcion2[0].link_rewrite;
        this.cat_rewrite = this.descripcion2[0].cat_rewrite;
        this.paquete = this.descripcion2[0].paquete;
        this.sku_product_relacionado = this.descripcion2[0].sku;
        this.title = this.descripcion2[0].title;
        this.image_link = this.descripcion2[0].image_link;
    
      } catch (error) {
        
      }

    })
  }


}
