<section class="categoriaDesk" *ngIf="isDesk">
  <div class="contDesk" *ngIf="cargando == true">

    <div class="conten-cats">
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
      <div class="cardin">
        <a class="card-categoria" id="card-link" target="_blank">
          <div class="card__header">
            <div>
              <img class="header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 class="card__header header__title" id="card-title">
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div class="card__body">
            <div class="card__body body__text" id="card-details">
              <div class="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div class="card__body body__img">
              <img class="skeleton" alt="" id="cover-img" />
            </div>
          </div>

          <div class="card__footer" id="card-footer">
            <div class="skeleton skeleton-text skeleton-footer"></div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="contDesk" *ngIf="cargando == false">
    <div class="title-cont">
      <h1>{{taitlpage}}</h1>
    </div>
    <div class="conten-cats">
      <a class="cardin" *ngFor="let subCat of product"
        [routerLink]="['/catalogo',subCat.id_category,subCat.link_rewrite]" style="text-decoration: none;">
        <span class="cat-name">{{subCat.name}}</span>
        <div class="contImg">
          <img src="{{url+subCat.id_category+'/'+subCat.img}}" alt="">
        </div>
      </a>
    </div>
  </div>
</section>

<div class="cont-categoria-cel" *ngIf="isCel">

  <div class="cont" *ngIf="cargando == true">
    <!-- <div style="position:fixed; width:1px;height:100%;background-color: black;" ></div> -->
    <a class="card-categoria" id="card-link" target="_blank">
      <div class="card__header">
        <div>
          <img class="header__img skeleton" id="logo-img" alt="" />
        </div>
        <h3 class="card__header header__title" id="card-title">
          <div class="skeleton skeleton-text"></div>
          <div class="skeleton skeleton-text"></div>
        </h3>
      </div>

      <div class="card__body">
        <div class="card__body body__text" id="card-details">
          <div class="skeleton skeleton-text skeleton-text__body"></div>
        </div>

        <div class="card__body body__img">
          <img class="skeleton" alt="" id="cover-img" />
        </div>
      </div>

      <div class="card__footer" id="card-footer">
        <div class="skeleton skeleton-text skeleton-footer"></div>
      </div>
    </a>
    <a class="card-categoria" id="card-link" target="_blank">
      <div class="card__header">
        <div>
          <img class="header__img skeleton" id="logo-img" alt="" />
        </div>
        <h3 class="card__header header__title" id="card-title">
          <div class="skeleton skeleton-text"></div>
          <div class="skeleton skeleton-text"></div>
        </h3>
      </div>

      <div class="card__body">
        <div class="card__body body__text" id="card-details">
          <div class="skeleton skeleton-text skeleton-text__body"></div>
        </div>

        <div class="card__body body__img">
          <img class="skeleton" alt="" id="cover-img" />
        </div>
      </div>

      <div class="card__footer" id="card-footer">
        <div class="skeleton skeleton-text skeleton-footer"></div>
      </div>
    </a>
    <a class="card-categoria" id="card-link" target="_blank">
      <div class="card__header">
        <div>
          <img class="header__img skeleton" id="logo-img" alt="" />
        </div>
        <h3 class="card__header header__title" id="card-title">
          <div class="skeleton skeleton-text"></div>
          <div class="skeleton skeleton-text"></div>
        </h3>
      </div>

      <div class="card__body">
        <div class="card__body body__text" id="card-details">
          <div class="skeleton skeleton-text skeleton-text__body"></div>
        </div>

        <div class="card__body body__img">
          <img class="skeleton" alt="" id="cover-img" />
        </div>
      </div>

      <div class="card__footer" id="card-footer">
        <div class="skeleton skeleton-text skeleton-footer"></div>
      </div>
    </a>


  </div>
  <div class="cont" *ngIf="cargando == false">
    <!-- <div style="position:fixed; width:1px;height:100%;background-color: black;" ></div> -->

    <ion-grid class="ion-card" style="width: 85%;">
      <ion-row style="width: 100%; height: 23rem;">
        <ion-col size="12" size-md="6" size-lg="4" size-xl="3" *ngFor="let subCat of subcats"
          style="width: 100%; height: 100%;">
          <a class="pp" [routerLink]="['/catalogo',subCat.id_category,subCat.link_rewrite]"
            style="text-decoration: none;">
            <!-- <ion-card style=" border: solid 1px #89daf3;"> -->
            <ion-card>

              <div class="contImg">
                <img src="{{url+subCat.id_category+'/'+subCat.img}}" alt="">
              </div>

              <div class="text">
                <ion-text class="ion-text-center textito ion-text-wrap">{{subCat.name}}</ion-text>
              </div>
              <!-- <hr style="border: black 1px; width: 80%;" > -->
            </ion-card>

          </a>
        </ion-col>

      </ion-row>
    </ion-grid>

  </div>
</div>


<!-- <div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div> -->