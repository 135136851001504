<section class="wishDesk" style=" margin-top: 100px;" *ngIf="notiene ==1">
  <div class="wishCont">
    <div class="taitl-cont">
      <span class="prin">Bienvenido</span>
      <div class="secon">Por el momento no tienes articulos en tu lista de deseos</div>
      <div class="secon">Dirigite a nuestro <a style="cursor:pointer"
          routerLink="/categoria/10/categorías-por-tipo-de-producto">catalogo</a> para empezar a verlos.</div>
    </div>
  </div>
</section>


<section class="wishDesk" style=" margin-top: 100px;" *ngIf="notiene !=1">
  <div class="wishCont">
    <div class="taitl-cont">
      <span class="prin">Bienvenido {{nombre}}</span>
      <div class="secon">a tu lista de deseos</div>
    </div>
    <div class="wish-cont">
      <div class="prod" *ngFor="let item of listaWishlist">
        <img class="imag" src="{{item.imagen}}" alt="">
        <div class="parrafos">
          <span class="nom">- {{item.title}}</span>
          <span class="rest">Código: {{item.sku}}</span>
          <span class="rest">Color: {{item.color}}</span>
        </div>
        <div class="links">
          <a (click)="comprar(item.id_product,item.sku,item.link_rewrite)"><i class="fa-solid fa-bag-shopping"></i></a>
          <a (click)="eliminarWishlist(item.sku)"><i class="fa-solid fa-trash"></i></a>
        </div>
      </div>
    </div>
    <div class="taitl-cont">
      <span class="prin">¿Te gustaria compartir tu lista de deseos?</span>
      <div class="secon"><a style="cursor: pointer;" (click)="copyHeroName()">Click Aqui</a></div>
    </div>
  </div>
</section>

<div class="desaparece-desk">
  <div class="wishlist">
    <div class="titulo">
      <p>Bienvenido {{nombre}}</p>
      <p>a tu lista de deseos</p>
    </div>
    <!-- <div class="invitar">
      <div class="btn" (click)="invitar()">
        <ion-icon name="person-add-outline"></ion-icon>
        <p>Invitar a un amigo</p>
      </div>
    </div> -->
    <div class="productos">
      <div class="prod" *ngFor="let item of listaWishlist">
        <div class="imagen">
          <img src="{{item.imagen}}" alt="">
        </div>
        <div class="info">
          <div class="parrafos">
            <p>{{item.title}}</p>
            <p>Código: {{item.sku}}</p>
            <p>Color: {{item.color}}</p>
            <!-- <p style="font-weight: 900; color: #000;">$1,399.99 MXN</p> -->
          </div>
          <div class="links">
            <!-- <a (click)="copiar(item.link)">Copiar Enlace</a> -->
            <!-- <a (click)="copiar()">Copiar Enlace</a> -->
            <a (click)="comprar(item.id_product,item.sku,item.link_rewrite)">Ir a Comprar</a>
            <a (click)="eliminarWishlist(item.sku)">Eliminar</a>
          </div>
        </div>
      </div>
      <!-- <div class="prod">
        <div class="imagen">
          <img src="" alt="">
        </div>
        <div class="info">
          <div class="parrafos">
            <p>Plancha de Calor sigue la linea </p>
            <p>Código: 4425-0324</p>
            <p>Color: Frambuesa</p>
            <p style="font-weight: 900; color: #000;">$1,399.99 MXN</p>
          </div>
          <div class="links">
            <a href="">Enviar al carrito</a>
            <a href="">Eliminar</a>
          </div>
        </div>
      </div> -->
      <!-- <div class="prod">
        <div class="imagen">
          <img src="" alt="">
        </div>
        <div class="info">
          <div class="parrafos">
            <p>Plancha de Calor sigue la linea </p>
            <p>Código: 4425-0324</p>
            <p>Color: Frambuesa</p>
            <p style="font-weight: 900; color: #000;">$1,399.99 MXN</p>
          </div>
          <div class="links">
            <a href="">Enviar al carrito</a>
            <a href="">Eliminar</a>
          </div>
        </div>
      </div> -->
      <!-- <div class="prod">
        <div class="imagen">
          <img src="" alt="">
        </div>
        <div class="info">
          <div class="parrafos">
            <p>Plancha de Calor sigue la linea </p>
            <p>Código: 4425-0324</p>
            <p>Color: Frambuesa</p>
            <p style="font-weight: 900; color: #000;">$1,399.99 MXN</p>
          </div>
          <div class="links">
            <a href="">Enviar al carrito</a>
            <a href="">Eliminar</a>
          </div>
        </div>
      </div> -->
    </div>
    <div class="seguir">
      <div class="comp">
        <p routerLink="/categorias">Seguir comprando</p>
      </div>
    </div>
  </div>
  <div class="invitar-cont" #invitarCont>
    <div class="opts">
      <ion-icon id="cerrar" name="close-circle-outline" (click)="invitar()"></ion-icon>
      <div class="lectura">
        <p class="bl" #lectura (click)="opclectura()">Solo lectura</p>
        <p>Cualquiera con un enlace puede ver tu lista sin realizar modificaciones.</p>
      </div>
      <div class="mid" #mid>
        <div class="midl" #midl>
          <ion-icon name="link-outline"></ion-icon>
          <p class="copys" (click)="copiar()">
            Copiar enlace
            <span #copy>¡ Enlace copiado !</span>
          </p>
        </div>
        <div class="midl" #midl2>
          <ion-icon name="mail-outline"></ion-icon>
          <p (click)="enviarCorreo()">Correo electrónico</p>
        </div>
        <div class="midl" #midl3>
          <ion-icon name="chatbox-ellipses-outline"></ion-icon>
          <p>Mensaje de texto</p>
        </div>
      </div>
      <div class="ve">
        <p class="bl" #ve (click)="opcve()">Ver y Editar</p>
        <p>Las personas invitadas pueden agregar o eliminar articulos de tu lista.</p>
      </div>
    </div>

    <div class="correo" #correo>
      <div class="cancelar" (click)="enviarCorreo()"><ion-icon name="close-circle-outline"></ion-icon>Cancelar</div>
      <div class="cont-next">
        <div class="titled">
          <p> Ver mi lista en Lideart. </p>
        </div>
        <form action="">
          <div class="pas">
            <p>Para:</p>
            <input type="text" placeholder="Correo electrónico">
          </div>
          <button>Compartir</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>