<section class="distribuidorDesk">
  <div class="dist-cont">
    <div class="taitl-cont">
      <span class="taitl"></span>
    </div>
    <div class="info-cont">
      <div class="cont-form">
        <div class="signupFrm">
          <div action="" class="form">
            <h1 class="title">Ingresa tu Información</h1>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="nombre_completo" placeholder="a">
              <label for="" class="label">Nombre Completo</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="empresa" placeholder="a">
              <label for="" class="label">Empresa</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="remision" placeholder="a">
              <label for="" class="label">Remisión</label>
            </div>

            <div class="inputContainer">
              <input type="text" class="input" [(ngModel)]="telefono" placeholder="a">
              <label for="" class="label">Telefono</label>
            </div>

            <div class="inputContainer">
              <!-- <textarea id="w3review" class="textarea" [(ngModel)]="mensaje"  rows="4" cols="50" placeholder="a"></textarea> -->
              <input type="text" class="input" [(ngModel)]="correo" placeholder="a">
              <label for="" class="label">Correo Electrónico</label>
            </div>


            <input type="button" class="submitBtn" (click)="enviaEncuesta()" value="Enviar">
          </div>
        </div>
        <!-- <iframe src="https://webforms.pipedrive.com/f/1AERpEy5t2p8Ko0OBOEoDdmSbXq57CZvegZQktlfTdPfhSczDIxC0S9zTF7y0Wt7t" height="900px" width="500px" frameborder="0" ></iframe> -->
      </div>
    </div>
  </div>
</section>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>