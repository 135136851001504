@if(isDesk) {
<section class="wish-desk-cont" style=" margin-top: 100px;">
  <div class="fix-width">
    <div class="banner-1-cont">
      <img src="/assets/img/mesa-regalos/banner-mesa.webp">
    </div>
    <div class="banner-2-cont">
      <img src="/assets/img/mesa-regalos/fondo-globos.jpg">
      <a class="boton-1" style="cursor: pointer;" (click)="checaMesa()">Crear Mesa de Regalos</a>
      <a class="boton-2" routerLink="/mesa-regalos-principal">Ver tu mesa de Regalos</a>
    </div>
  </div>
</section>
}
@else if(isCel) {
<section class="wish-cel-cont">
  <div class="banner-1-cont">
    <img src="/assets/img/mesa-regalos/banner-1-cel.webp">
  </div>
  <div class="banner-2-cont">
    <!--<img src="/assets/img/mesa-regalos/fondo-globos.jpg">-->
    <a class="boton-1" style="cursor: pointer;" (click)="checaMesa()">Crear Mesa de Regalos</a>
    <a class="boton-2" routerLink="/mesa-regalos-principal">Ver tu mesa de Regalos</a>
  </div>
</section>
}