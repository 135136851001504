<div>
  <H1 class="header"> Preguntas Frecuentes</H1>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿En cuanto tiempo me llega mi pedido?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Buen día, los tiempos de entrega son ESTIMADOS de 4 a 5 DÍAS HÁBILES, los envíos se hacen por medio de las paqueterías ESTAFETA y PAQUETEXPRESS.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Hacen envíos a toda la republica mexicana?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Sí!</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo se aplica el código de descuento?(Aplica cuando hay códigos promocionales)</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Al momento de proceder a pagar debes de agregar de forma manual tu código de descuento para que así se te aplique tu descuento</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Facturan?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Si claro, es necesario que te registres con los datos de facturación. Al momento de agregar los datos de envío si la dirección discal es diferente te saldrá la opción para agregarla.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo solicito mi factura?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">La factura se solicita solamente al realizar tu compra, debes de registrarte con los datos de facturación, al momento de realizar tu compra te pedira la dirección de facturación</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo le hago para comprar y pasar a recogerlo?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Es necesario que agregues el artículo a tu carrito, después al proceder a pagar y agregar una dirección con CP de Chihuahua te dará la opción de recoger en sucursal. Una vez realizada la compra debes de esperar un mínimo de 2 horas para recoger el material.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Puedo hacer compras a meses sin intereses?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Buen día, si puedes hacerlo, en nuestra página en compras mayores a $3,000 aplica hasta 6 sin intereses con Paypal y tarjeta de crédito.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cuál sería el costo de envío?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Buen día, el costo de envío depende del CP y del artículo a adquirir (peso del paquete), en compras mayores a $2,000 el envío aplica como gratis.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo puedo pagar por paypal?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Seleccionando la opción de pago con paypal en el cobro de sus productos.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong>¿Tienen sucursal?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">No, somos una tienda Online. Nuestro almacén se encuentra en Ciudad Chihuahua. Tenemos envíos a toda la republica méxicana. Es necesario que realices la compra directamente en la página para agregar datos de envío.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong>Ya hice mi transferencia y aun aparece como pago en espera</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Para poder hacer valido el pago es necesario que envies tu comprobante de pago como se te indica en condiciones de pago y en el correo de confirmación de pedido. Una vez enviando el comprobante de pago como se indica, verificamos pago y procedemos a aplicarlo en tu compra</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Hola, Me gustaría saber el costo de envio a ****, ***?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Para saber el precio del envío es necesario que agregues los artículos a tu carrito de compra, al darle click para ver tu carrito de compra te aparecerá precio de envío</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Puedo hacer compras a meses sin intereses?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Buen día, si puedes hacerlo, en nuestra página en compras mayores a $3,000 aplica hasta 6 meses sin intereses con Paypal y tarjeta de crédito, debes de seleccionarlos de forma MANUAL</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Tengo un problema con mi equipo...</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Es necesario que envies tu caso a nuestra sección de soporte para que nuestro técnico pueda apoyarte, te dejo el link donde puedes levantar tu ticket https://soporte.craftroom.mx/hc/es-419</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Hice mi compra elsábado y aun no me llega mi código de rastreo</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Tenemos un horario de lunes a viernes de 8:30 a.m. a 6:00 p.m., tu envío se hace hasta el día lunes y el día martes te proporcionamos tu código de rastreo</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> En la compra de la cameo dan alguna curso para aprender a manejarla?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Si claro, proporcionamos un curso básico online. Es un curso pregrabado en un grupo privado de facebook. Al momento de llegar tu equipos lo solicitas ya sea por whatsapp o por aquí por el chat</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Sabrás cuando estará disponible? (Algun artículo agotado)</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">No tenemos una fecha estimada de llegada pero si fueras tan amable de proporcionarme tu correo y nombre para notificarte por correo cuando nos estaría llegando el artículo</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿En cuanto me sale el envío al siguiente CP?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Para saber precio del envío es necesario que agregues el artículo a tu carrito y al proceder a pagar te aparecerá el precio del envío</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cuáles tarjetas de credito aplican?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Aplican las tarjetas American Express, Master Card y Visa.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Si pago con paypal aplican los meses sin intereses</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">No, es necesario que tu tarjeta sea de crédito</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo sirven los lidepuntos?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Te dejo el link con la la guía para poder usarlos https://new.lideart.com.mx/content/15-guia-de-lidepuntos</div>
    </ion-accordion>
  </ion-accordion-group>


  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo aplico mi garantía?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Es necesario que levantes un caso en nuestra sección de soporte técnico, para que así nuestro técnico te pueda apoyar y en caso de una garantía poder aplicarla. Te dejo link donde puedes levantar tu caso: https://soporte.lideart.com.mx/hc/es-419/requests/new</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Me lo pueden enviar en fin de semana?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">No,  Tenemos un horario de lunes a viernes de 8:30 a.m. a 6:00 p.m., tu envío se hace hasta el día lunes y el día martes te proporcionamos tu código de rastreo</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Dónde puedo reportar un problema con mi maquina?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Es necesario que envies tu caso a nuestra sección de soporte para que nuestro técnico pueda apoyarte, te dejo el link donde puedes levantar tu ticket https://soporte.lideart.com.mx/hc/es-419/requests/new</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Ya vencio mi garantía ¿Con quien puedo llevar mi maquina para que la reparen?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Hola por el momento no contamos con algun servicio de reparación. Te recomendamos enviarlo con algun técnico de tu confianza</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Hola ¿Qué horario tienen?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Tenemos un horario de lunes a viernes de 8:30 a.m. a 6:00 p.m.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo puedo ser subdistribuidor?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Serías tan amable de llenar la siguiente información de este  formulario:https://new.lideart.com.mx/content/13-tiendas-autorizadas</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo funcionan los lidepuntos?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Te dejo el link con la la guía para poder usarlos https://new.lideart.com.mx/content/15-guia-de-lidepuntos</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿A donde envío mi comprobante de pago?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Para poder hacer valido el pago es necesario que envies tu comprobante de pago como se te indica en condiciones de pago y en el correo de confirmación de pedido. Una vez enviando el comprobante de pago como se indica, verificamos pago y procedemos a aplicarlo en tu compra</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Venden repuestos para mi maquina? // Venden refacciones para los equipos?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! No por el momento no vendemos refacciones para los equipos</div>
    </ion-accordion>
  </ion-accordion-group>


  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo le hago para buscar el foil? / ¿Venden foil? / ¿Tienes foil?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Si claro, en nuestro buscador puedes poner papel foil y te apareceran los papel foil que manejamos</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Qué viniles manejan?/¿Que viniles tienen?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Tenemos una gran variedad de viniles ya sean de corte o textiles, así los puedes buscar en nuestra página como: Vinil de corte o Vinil textil</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Que diferencia hay entre Silhouette y Cricut?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Depende mucho el trabajo que deseas hacer. La explore air 2, Explore 3 y la Joy te ayudan a cortar y dibujar mientras la Maker y Maker 3 te hacen esos trabajos y adicional tienen mas herramientas y técnicas de trabajar como lo son grabado, repujado, doblado, entre otras funciones. Mientras Que Cameo 4 y Portrait  te ayudan a cortar, dibujar y marcar doblados,  el equipo Curio te hace eso y adicionar hace trabajos de punteado y repujado.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Si hago hoy la compra ¿Cuándo llega?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Si realizas la compra el día de hoy antes de als 2:00 p.m. el envío se hace hoy mismo, si la compra la realizas despues de las 2:00 p.m. el envío se hace hasta el día de mañana.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Tienen envío express?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">No, los tiempos de envío de manejamos son de 4 a 5 días hábiles.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Puedo cambiar el color *Un equipo*?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Puedes agregar en comentarios de tu compra el color que desees, se tomara en cuenta pero no se asegura ya que depende de disponibilidad.</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Puedo agregar mas productos?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! SI ya realizaste el pago no es posible. Es necesario que realices otra compra</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Compre el sabado /domingo y no me han mandado el código de rastreo</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Nuestros horarios son de LUNES A VIERNES de 8:30 a.m. a 6:00 p.m. serías tan amable de proporcionarme la referencia de ti pedido para verificar el estatus</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> si quiero recoger en la sucursal debo hacer el pedido primero con ustedes o puedo ir directamente a solo comprarlo</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">La compra se hace directamente en la página, una vez realizada la compra debes de esperar un mínimo de 2 horas para pasar a recogerlo al almacén (si se paga con trasferencia bancaría debes de esperar 2 horas después de haber enviado el comprobante de pago). Cualquier duda quedo a la orden :)</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Solicito una cotización </strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Si deseas la cotización dirigida a un persona o empresa es necesario que nos envíes foto del artículo que deseas cotizar y tus datos fiscales al correo contacto&#64;lideart.com.mx. En caso de que no la requieras así puedes ir agregando los artículos a tu carrito y podrás ir viendo el total a pagar</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿A que hora me llega la guía?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">"¡Hola!
        los envíos se hacen por medio de las paqueterías ESTAFETA y PAQUETEXPRESS.
        La guía de envía de 24 a 48 horas despues de que haces tu compra."</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> Quiero regresar lo que compre</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Es necesario que cuando te llegue el kit lo regreses a nuestro almacén (la guía de regreso correo por tu cuenta) y en cuanto nos llegue con gusto hacemos la devolución. Para que sea valida la devolución el artículo debe de estar totalmente nuevo y sin abrir te proporciono la dirección para que puedas hacer el envío  Av. Washington No. 3701 Edificio 48 Parque Industrial de las Américas, 31114 Chihuahua (En avance)</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo selecciono los meses sin intereses?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Debes de procedes a pagar, agregar los datos de tu tarjeta de crédito y despues seleccionar de forma manual los meses sin intereses</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo puedo comprar un curso/licencia?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Para hacer la compra es necesario que agregues los artículos que deseas comprar a tu carrito una vez seleccionado lo que quieras comprar, y el las próximas 24 horas hábiles te estará llegando el código de tu curso/licencia</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Cómo le hago para comprar y pasar a recogerlo?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">Es necesario que agregues el artículo a tu carrito, después al proceder a pagar y agregar una dirección con CP de Chihuahua te dará la opción de recoger en sucursal. Una vez realizada la compra debes de esperar un mínimo de 2 horas para recoger el material. Su tu pago es con transferencia debes de esperar las 2 horas despues de haber enviado tu comproabnte de pago</div>
    </ion-accordion>
  </ion-accordion-group>

  <ion-accordion-group style="padding: 0px 30px 5px 30px;">
    <ion-accordion value="firstA" toggleIcon="add-outline" toggleIconSlot="end">
      <ion-item style="border-radius: 10px;" slot="header" color="lightblue">
        <p> <strong> ¿Qué tarjetas manejan?</strong> </p>
      </ion-item>
      <div class="ion-padding resp" slot="content">¡Hola! Para realizar un pago a meses sin intereses tu tarjeta debe de ser tarjeta de crédito (Visa, master card y americanexpress), debes de agregar tus datos de la tarjeta y despues se te agregará una opción para agregar las mensualidades y debes seleccionarlo de forma MANUAL para que se puedan aplicar.</div>
    </ion-accordion>
  </ion-accordion-group>

</div>