@import "swiper/css";
@import "swiper/css/navigation";

.reviews-title {
  font-size: x-large;
  font-weight: 500;
}

.space {
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator {
  background-color: #17dafd;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.custom-button {
  background-color: #00a5cb;
  color: white;
  // font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 5px;
  &:hover {
    background-color: #0089a8;
  }
}

.price-strikethrough {
  color: #ff7589;
  margin-right: 5px;
}

/* Tarjeta de comentarios */
.comment-card {
  background-color: #fff;
  width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  border: 2px solid #17d9fd4d;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
  
  &:hover {
  box-shadow: #17d9fd4d 0 7px 29px; // Sombra más pronunciada al pasar el cursor
  border: 2px solid transparent;
  }
}

/* Cabecera de cada comentario */
.comment-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.comment-title {
  font-size: 17px !important;
  font-weight: bold !important;
  color: #333;
  margin: 0 !important;
}

.comment-stars img {
  width: 110px;
  height: auto;
  margin: 0 auto;
}

/* Contenido del comentario */
.comment-content {
  font-size: 15px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 15px;
}

/* Pie del comentario */
.comment-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #777;
}

.comment-nick {
  color: #444;
  font-weight: 500;
  text-align: left;
}

.comment-date {
  font-size: 12px;
  color: #0089a8;
  text-align: end;
}

/* Modal */
.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: flex-end;
  z-index: 99;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 450px;
  margin-bottom: 20px
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
}

.close-button {
  background-color: #d9534f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c9302c;
  }
}

.color-container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // text-align: center;
}

.color-container input[type="button"] {
  // width: 50px;
  // height: 50px;
  // margin-bottom: 5px;
}

/* Inputs */
.inps, .selict {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inp-manage{
  width: 100%;
  height: 2.5em;
  border: none;
}

.inp-manage:focus{
  outline: none;
}

ion-content{
    background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
  }

    ion-button {
      --background: #0089c5;     
      --color:white;
      
    
  }
  
  ion-card{
      background: rgba(255, 255, 255, 0.164); //     #fdfdfd            #14A8CD*
      backdrop-filter: blur(2px);
      /*background: rgb(28,138,242);
      background: linear-gradient(0deg, rgba(28,138,242,1) 47%, rgba(45,166,253,1) 47%); //     #fdfdfd            #14A8CD*/
      display: flex;
      justify-content:space-between;
     
      flex-direction: column;
      //box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      //box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      width: 100%;
      height: 100%;
      border-radius: .25rem;
      //padding: 1rem;
  
      /* From https://css.glass */
      background: rgba(255, 255, 255, 0.150);
      border-radius: 10px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      border: .5px solid rgba(0, 0, 0, 0.082);
  
      .contImg{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
  
        img{
          width: 80%;
          height: auto;
        }
      }
  
      .text{
        width: 100%;
        height: 40%;
        color: #14add1;
        font-size: 25px;
        // font-weight: bold;
        ion-text{
          width: 100%;
          font-size: 20vw;
          text-align: center;
          color: rgb(0, 0, 0);
          // text-shadow: 4px 3px 4px rgb(65, 64, 64); 
        }
  
      }

      .textDesc{
        width: 100%;
        height: 40%;
        color: black;
        font-size: 15px;
        // font-weight: bold;
        ion-text{
          width: 100%;
          font-size: 20vw;
          text-align: center;
          color: rgb(0, 0, 0);
          // text-shadow: 4px 3px 4px rgb(65, 64, 64); 
        }
  
      }
  
  
  
    }

    .tituloDescripcion{
      color: rgb(27,172,209);
      font-family: 'RobotoCondensed-Light';
      font-size: 18px;
      font-style: italic;
      text-align: center;
    }

    .titulillo{
      font-size: 20px;
      margin-bottom: 0em;
      color: black;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      font-size: 20px;
      letter-spacing: 2px;
    }
  
    .textillo{
        font-size: 18px;
        margin-bottom: 10px;
        color: black;
        font-weight: normal;
        //text-transform: uppercase;
        text-align: center;
        font-family: 'RobotoCondensed-Light';
    }
  
    .form-group{
  
   margin-left: 100px;
    }

    .color-cont-prop{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: .5em;
    }

    .color-cont-prop-list{
      width: 100%;
      height: auto;
      margin-top: .5em;

      .conten-names-opts{
        width: 100%;
        min-height: 5em;
        max-height: 13em;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1em 0em;
        gap: 1em;

        .btn-list-opt{
          width: 100%;
          padding: 1em 0em;
          color: #1ca3c9;
          background-color: transparent;
          border-top: 1px solid #0abcee;
          border-bottom: 1px solid #0abcee;
          box-shadow: 0px 0px 5px 0px #0abcee;
        }
      }

      .conten-names-opts::-webkit-scrollbar {
        width: .6em;
        background-color: transparent;
        height: .7em;
      }
        
      .conten-names-opts::-webkit-scrollbar-thumb {
        background-color: #00a2c779;
        //border-radius: 1vh;
        width: 0.25em;
      }
    }
  
    .selector-color {
      margin: 0px 5px;
      width: 73px;
      height: auto;
      position: relative;
      border-radius: 3px;
      cursor: pointer;
      //overflow: hidden;
      transform: rotate(0deg);
      transition: .5s;
      //border-radius: 50%;
      padding: .5em;
      padding-bottom: 0px;
      display: flex; 
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0em;
      text-align: center;

      @media (max-width: 720px) {
        margin: 0px 1px;
        width: 65px;
      }
      @media (max-width: 425px) {
        margin: 0px 1px;
        width: 77px;
      }
      @media (max-width: 420px) {
        margin: 0px 1px;
        width: 76px;
      }
      @media (max-width: 415px) {
        margin: 0px 1px;
        width: 75px;
      }
      @media (max-width: 410px) {
        margin: 0px 1px;
        width: 74px;
      }
      @media (max-width: 405px) {
        margin: 0px 1px;
        width: 73px;
      }
      @media (max-width: 400px) {
        margin: 0px 1px;
        width: 72px;
      }
      @media (max-width: 395px) {
        margin: 0px 1px;
        width: 71px;
      }
      @media (max-width: 390px) {
        margin: 0px 1px;
        width: 70px;
      }
      @media (max-width: 385px) {
        margin: 0px 1px;
        width: 69px;
      }
      @media (max-width: 380px) {
        margin: 0px 1px;
        width: 68px;
      }
      @media (max-width: 375px) {
        margin: 0px 1px;
        width: 67px;
      }
      @media (max-width: 370px) {
        margin: 0px 1px;
        width: 66px;
      }
      @media (max-width: 365px) {
        margin: 0px 1px;
        width: 65px;
      }
      @media (max-width: 360px) {
        margin: 0px 1px;
        width: 80px;
      }
      @media (max-width: 353px) {
        margin: 0px 1px;
        width: 78px;
      }
      @media (max-width: 345px) {
        margin: 0px 1px;
        width: 76px;
      }
      @media (max-width: 337px) {
        margin: 0px 1px;
        width: 74px;
      }
      @media (max-width: 330px) {
        margin: 0px 1px;
        width: 72px;
      }
      // @media (max-width: 322px) {
      //   margin: 0px 1px;
      //   width: 70px;
      // }
      @media (max-width: 321px) {
        margin: 0px 1px;
        width: 71px;
      }
      @media (max-width: 317px) {
        margin: 0px 1px;
        width: 69px;
      }
    }

    .color-name {
      font-size: 0.75em;
      text-align: center;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 34px;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.1em;
      max-height: 2.2em;
      color: #14add1;
      top: 1px;
    }

    .selector-color:hover .preview {
      margin-bottom: 5px;
    }

    .texto-descripcion {
      color: white;
      font-size: 1.3em;
    } 
    
    .selected.selector-color input { 
      border-radius: 50%;
      animation: lux 2s ease-in-out infinite;
      -webkit-animation: lux 2s ease-in-out infinite;
    }

    .line-tb{
      padding: 1.5em 0.5em;

      .r-color{
        position: absolute;
        width: 3.5em;
        height: 3.5em;
        border: 1px solid #cfcfcf;
        border-radius: 50%;
        right: 1em;
        top: 0.5em;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .preview{
      position: absolute;
      z-index: 5;
      background-color: #fff;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.7em;
      padding: 1em;
      border-radius: 1em;
      bottom: 3em;
      right: calc(50% - 2em);
      box-shadow: rgb(0 230 241 / 28%) 0px 5px 29px 0px;

      .diva-color-p{
        background-color: rgb(255, 255, 255);
        width: 6em;
        height: 6em;
        border: 1px solid #ddd;
        border-radius: 50%;
      }

      .name-color-p{
        font-size: .9em;
        color: #000;
        font-weight: 500;
      }
    }

    .selector-color:hover{
      .preview{
        display: flex;
      }
    }

    .texto-descripcion {
      color: white;
      font-size: 1.3em;
    }
    
    .selected.selector-color input{
      border-radius: 50%;
      animation: lux 2s ease-in-out infinite;
      -webkit-animation: lux 2s ease-in-out infinite;
    }

    @keyframes lux{
      0%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
      25%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      50%{
        border: 2px solid rgb(14, 240, 221);
        box-shadow: 0px 0px 10px 1px rgb(14, 240, 221);
      }
      75%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      100%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
    }

    @-webkit-keyframes lux {
      0%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
      25%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      50%{
        border: 2px solid rgb(14, 240, 221);
        box-shadow: 0px 0px 10px 1px rgb(14, 240, 221);
      }
      75%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      100%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
    }
    
    .selector-color input {
      width: 30px;
      background-size: cover;
      position: absolute;
      height: 30px;
      box-shadow: inset rgb(0 0 0 / 20%) 0px 0px 12px;
      transition: 1s;
      border-radius: .2em;
      border: none;
      animation: none;
    }

    @media(max-width: 992px){
      .selector-color:hover{
        .preview{
          display: none;
        }
      }
    }
    
    .selected.selector-color input {
      width: 40px;
      height: 40px;
      top: 0px;
    }
    
    .contiene-descripcion > table > tbody > tr > td > div > img {
      max-width: 100%;
      height: auto;
    }

    .swiper {
      width: 100%;
      height: 100%;
    }
    
    swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    swiper-slide video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    
    swiper-slide {
      background-size: cover;
      background-position: center;
    }
    
    .mySwiper2 {
      height: 80%;
      width: 45%;
      border: 5px;
    }

    .swiper-container-wrapper{
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .swiper-container-gallery{
      width: 80%;
      margin: 0 auto;
    }

    swiper-slide{
      text-align: center;
    }
    
    .swiper-image{
      max-width: 5em;
      max-height: 5em;
    }

    .swiper-pagination{
      margin-top: 10px;
    }

    .swiper-button-next,
    .swiper-button-prev{
      color:#0089c5
    }

    .swiper-pagination-bullet{
      background: #1c81a0;
      width: 8px;
      height: 8px;
      margin: 0 3px;      
    }

    /*.swiper-container{
      padding-top: 0em;
      width: 25em;
      height: auto;
     
      margin: 0 auto;
      display: flex;
      justify-content: center;
      //align-items: center;
    }*/

    .mySwiper3{
         
      cursor: default;
      inline-size: 100%;
      block-size: auto;
      inset-inline-start: 0;
      inset-block-start: 0px ;
     
    }
    
    .mySwiper {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    
    .mySwiper swiper-slide {
      width: 25%;
      height: 100%;
     
    }
    
    .mySwiper .swiper-slide-thumb-active {
      opacity: 1;
    }

    .precio{
      color: rgb(29,171,209);
      font-family: 'RobotoCondensed-Light';
      font-size: 35px;
      letter-spacing: normal;
      line-height: 38.5px;
      text-size-adjust: 100%;
      text-transform: uppercase;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    swiper-slide video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
   

    .loader {
      display: flex;
      width: 100%;
      height: 100%;
      background-position: center;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: fixed;
      top: calc(0%);
      left: calc(0%);
      background-color: rgb(255, 255, 255);
      opacity: 0.9;
    
      img{
        width: 200px;
        height: auto;
   
      }
  
    }

    /* Styles for the selected image container */
.selected-image-container {
  margin-bottom: 20px; /* Adjust the spacing as needed */
}

.selected-image-swiper {
  width: 100%;
  max-height: 400px; /* Adjust the height of the selected image */
  margin: 0 auto;
}

/* Styles for the gallery container */
.gallery-container {
  margin-top: 20px; /* Adjust the spacing as needed */
}

.gallery-swiper {
  width: 100%;
  max-height: 100px; /* Adjust the height of the gallery */
  margin: 0 auto;
}

.gallery-image {
  max-width: 100px; /* Adjust the size of gallery images */
  max-height: 80px; /* Adjust the size of gallery images */
}

@media screen and (max-width:992px){
  

  .columnas{
    height: min-content;
  }
  .swiper-container{
    height: auto;
    text-align: center;
  }

  .columnas ion-col:nth-of-type(2){
    order: 1;
  .columnas ion-col:nth-of-type(1){
    order: 2;
  }
}
#CodigoYColor{
  //margin-right: 10em;
}
}

@media screen and (min-width:993px){
    .columnas {
      height: min-content;
    }

    .columnas ion-col{
      width: 25%;
    }
    .columnas ion-col:nth-of-type(2){
      order: 1;
    }
    .columnas ion-col:nth-of-type(1){
      order: 2;
    }
    .columnas ion-col:nth-of-type(3){
      order: 3;
    }

    ion-header{
      display: none;
    }
}

.swal-wide{
  width:20%;
  height: 20%;
}

#existenciaDesk{
  display: none;

  .conten-exist{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: .5em 0em;

    .lefti{
      color: #000;
      font-size: 1.2em;  
    }

    .rigti{
      color: #00a3c7;
      font-size: 1.1em;
    }
  }
}

@media screen  and (min-width:993px){
  #caracteristicas{
    //padding-left: 20%;
    //padding-left: 15em;
    //padding-right: 15em;
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #irCarrito{
    display: none;
  }
  #existencia{
    display: none;
  }
  #colorSeleccionado{
    display: none;
  }
}

.deskheader{
  display: none;
}

.deskfooter{
  display: none;
}

.nohay-cont{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  padding: 0.7em 0em;
  border-bottom: 1px solid #f00;
  margin-bottom: 1em;

  .textin{
    color: #f00;
    font-weight: 600;
    font-size: 1.2em;
  }

  i{
    color: #f00;
    font-size: 2.2em;
  }
}

.cont-car{
  width: 70%;
  height: auto;

  table{
    width: 100%;
    height: auto;
  }
  
  ul{
    width: 100%;
    padding: 0em 25%;
  }
  
  td{
    border: 1px solid #cdcdcd;
  }
  
  div{
    width: 100%;
    overflow-x: scroll;
  }
  
  tbody{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  h5{
    span{
      font-size: 1.7em;
    }
  }
  
  p{
    span{
      font-size: 1.4em;
      color: #00a3c7;
    }
  }
  
  hr{
    background-color: #000;
    margin: 2em 0em;
  }
}

.prods-related-cont{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  background-color: #fff;

  .taitl{
    color: #222;
    font-size: 1.8em;
    font-weight: 100;
  }

  #related{
    width: 100%;
    height: auto;
    //margin-top: 2em;

    .swiper-button-next{
        background-color: #fff;
        border: 1px solid rgb(194, 194, 194);
        color: rgb(194, 194, 194);
        width: 3em;
        height: 3em;
        transition: .5s all ease-in-out;
        --swiper-navigation-size: 1em;
        top: calc(50% - 1.5em);
        opacity: 1;
    }

    .swiper-button-prev{
        background-color: #fff;
        border: 1px solid rgb(194, 194, 194);
        color: rgb(194, 194, 194);
        width: 3em;
        height: 3em;
        transition: .5s all ease-in-out;
        --swiper-navigation-size: 1em;
        top: calc(50% - 1.5em);
        opacity: 1;
    }
    
    .swiper-button-next:hover, .swiper-button-prev:hover{
        background-color: rgb(23, 66, 131);
        border: 1px solid rgb(23, 66, 131);
    }
    
    .swiper-button-next{
        right: 0;
    }
    
    .swiper-button-prev{
        left: 0;
    }
    
    .swiper-pagination-bullet{
        width: 1em;
        height: 1em;
        background-color: #000;
        opacity: .8;
    }
    
    .swiper-pagination-bullet-active{
        background-color: rgb(11, 68, 143);
    }

    swiper-slide{
        text-align: center;
        font-size: 18px;
        background: transparent;
          
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        position: relative;

        .all-cont{
            width: 100%;
            height: auto;

            .prod-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                padding: 1em;
                gap: 1em;
                border: 1px solid #eee;
                padding-bottom: 1.5em;

                .offer{
                    position: absolute;
                    top: 1em;
                    left: 1em;
                    color: #fff;
                    background-color: #e67e22;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 10;
                    font-size: .6em;
                    padding: .3em 1em;
                }

                .new{
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    color: #fff;
                    background-color: #00ABF0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 10;
                    font-size: .6em;
                    padding: .3em 1em;
                }

                .foto-cont{
                    width: 167px;
                    height: 167px;
                    background-position: center;
                    background-size: cover;
                    position: relative;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: .4s all ease-in-out;
                    }

                    #uno{
                        opacity: 1;
                    }

                    #dos{
                        opacity: 0;
                    }
                }

                .time-cont{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .1em;

                    .quad-time{
                        width: 25%;
                        height: 2.2em;
                        background-color: #999999;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: .4em;

                        .up{
                            color: #fff;
                            font-weight: 900;
                            font-size: .8em;
                        }

                        .down{
                            color: #fff;
                            font-weight: 400;
                            font-size: .5em;
                        }
                    }
                }

                .rate-cont{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: .4em;

                    i{
                        font-size: .62em;
                        color: #f1a43e;
                    }
                }

                .prod-name{
                    font-size: 15px;
                    color: #444;
                    font-weight: normal;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                .price-cont{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .now{
                        color: #e74c3c;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .bef{
                        line-height: 20px;
                        padding: 0 5px;
                        display: inline-block;
                        text-decoration: line-through;
                        color: #999;
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }

            .prod-cont:hover{
                //border: 1px solid #aaa;

                .foto-cont{

                    #uno{
                        opacity: 0;
                    }

                    #dos{
                        opacity: 1;
                    }
                }

                .time-cont{

                    .quad-time{

                        background-color: #2e4980;
                    }
                }
            }
        }
    }
  }

  #swiperSupercategory:hover{

      /*.swiper-button-prev{
          opacity: 1;
      }*/

      .swiper-button-prev:hover{
          color: #fff;
      }

      .swiper-button-next{
          opacity: 1;
      }

      .swiper-button-next:hover{
          color: #fff;
      }
  }
}

@media(min-width: 992px){
  .deskheader{
    display: block;
  }

  .deskfooter{
    display: block;
  }

  .titulillo{
    letter-spacing: 0px;
    font-size: 1.8em;
  }

  #existenciaDesk{
    display: block;
  }

  .cont-car{
    height: auto;
    // overflow-y: scroll;
    box-shadow: rgb(3 165 198 / 43%) 0px 2px 8px 0px;
    padding: 1em;
  }
}

@media(max-width: 992px){
  .cont-car{
    width: 100%;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

.supercategory-cont{
    //border: 1px solid red;
    width: 100%;
    height: auto;
    display: block;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2em;


    .top-con{
        position: relative;
        width: 100%;
        height: 4em;
        border-top: 1px solid rgb(218, 218, 218);
        border-right: 1px solid rgb(218, 218, 218);

        .line{
            width: 100%;
            height: .2em;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .title-cont{
            height: 115%;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1em;
            padding: 0em 3em;

            i{
                color: #fff;
                font-size: 1.5em;
            }

            span{
                color: #fff;
                font-size: 1.2em;
            }
        }
    }

    .topin{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .pops-cont{
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-bottom: 1em;

            .title-cont{
                width: 100%;
                height: 2.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #eee;

                span{
                    font-weight: 600;
                    color: #000;
                }
            }

            .opcs-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: .75em;
                padding-top: 1em;
                gap: .5em;

                button{
                    border: none;
                    background-color: transparent;
                    color: #000;
                    transition: .3s all ease-in-out;
                    font-size: 1.2em;
                    text-align: start;
                }

                button:hover{
                    color: rgb(15, 86, 133);
                    padding-left: .5em;
                }
            }
        }

        img{
            width: 75%;
            height: auto;
        }

        @media(min-width: 992px){
            .pops-cont{
                width: 25%;
            }
        }

        @media(max-width: 768px){
            .pops-cont{
                width: 100%;

                .title-cont{

                }
            }

            img{
                display: none;
            }
        }
    }
}

.supercategory-cont:nth-child(1){
    .top-con{
        .line{
            background-color: #009589;
        }

        .title-cont{
            background-color: #009589;
        }
    }
}

.supercategory-cont:nth-child(3){
    .top-con{
        .line{
            background-color: #ff5924;
        }

        .title-cont{
            background-color: #ff5924;
        }
    }
}

.supercategory-cont:nth-child(5){
    .top-con{
        .line{
            background-color: #32ad59;
        }

        .title-cont{
            background-color: #32ad59;
        }
    }
}



.first-cont{

    .firsty-cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mercados-cont{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 2em;

        .opc-cont{
            width: 100%;
            height: 10em;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .shopinkref{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: relative;

                span{
                    font-family: 'Poiret One', cursive;
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-size: 2.8em;
                    transition: .6s all ease-in-out;
                }

                .sh1{
                    color: #f526617e;
                }

                .sh2{
                    color: #00a9bf70;
                }

                .sh3{
                    color: #ffc2336e;
                }

                .got1{
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.398);
                    border-radius: 48% 52% 24% 76% / 70% 41% 59% 30% ;
                    width: 1.8em;
                    height: 1.8em;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    left: calc(50% - 6em);
                    top: 2em;
                    transition: .3s all ease-in-out;
                }

                .got2{
                    position: absolute;
                    background-color: #ffc2336e;
                    border-radius: 71% 29% 53% 47% / 39% 50% 50% 61%  ;
                    width: 1.5em;
                    height: 1.5em;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    left: calc(50% - 4.25em);
                    top: 6em;
                    transition: .3s all ease-in-out;
                }

                .got3{
                    position: absolute;
                    background-color: #f526617e;
                    border-radius: 57% 43% 44% 56% / 59% 29% 71% 41%  ;
                    width: 2.3em;
                    height: 2.3em;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    left: calc(50% + .5em);
                    top: 1.8em;
                    transition: .3s all ease-in-out;
                }

                .got4{
                    position: absolute;
                    background-color: #00a9bf70;
                    border-radius: 61% 39% 67% 33% / 49% 81% 19% 51%   ;
                    width: 2em;
                    height: 2em;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    left: calc(50% + 3.6em);
                    top: 5.5em;
                    transition: .3s all ease-in-out;
                }
            }

            .shopinkref:hover{

                span{
                    font-weight: 900;
                }

                .sh1{
                    color: #f52661;
                }

                .sh2{
                    color: #00a9bf;
                }

                .sh3{
                    color: #ffc233;
                }

                .got1{
                    background-color: rgb(0, 0, 0);
                    border-radius: 50%;
                    top: 1.8em;
                }

                .got2{
                    background-color: #ffc233;
                    border-radius: 50%;
                    top: 6.2em;
                }

                .got3{
                    background-color: #f52661;
                    border-radius: 50%;
                    top: 1.6em;
                }

                .got4{
                    background-color: #00a9bf;
                    border-radius: 50%;
                    top: 5.7em;
                }
            }

            .signotrafref{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: relative;

                span{
                    font-family: 'Poiret One', cursive;
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-size: 2.8em;
                    transition: .6s all ease-in-out;
                }

                .sh1{
                    color: #ffa92974;
                }

                .sh2{
                    color: #16192a74;
                }

                .warnr{
                    width: 3em;
                    height: 3em;
                    background-color: #c70c1579;
                    position: absolute;
                    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 1.1em;
                    left: calc(50% - 9.5em);
                    transition: .6s all ease-in-out;
                }

                .warnr1{
                    position: absolute;
                    width: 2.7em;
                    height: 2.7em;
                    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                    background-color: rgba(255, 255, 255, 0);
                    transition: .5s all ease-in-out;
                    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0);
                }

                .warnr2{
                    width: 3.3em;
                    height: 3.3em;
                    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                    //border: 1px solid #fff;
                    background-color: #c70c1500;
                    position: absolute;
                    transition: .5s all ease-in-out;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .75em;
                    color: rgba(255, 255, 255, 0);
                }

                .warny{
                    width: 3.5em;
                    height: 3.5em;
                    background-color: #fa960082;
                    position: absolute;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 5.1em;
                    right: calc(50% - 10em);
                    transition: .6s all ease-in-out;
                }

                .warny1{
                    position: absolute;
                    width: 3em;
                    height: 3em;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    background-color: rgba(255, 255, 255, 0);
                    transition: .5s all ease-in-out;
                    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0);
                }

                .warny2{
                    width: 3.4em;
                    height: 3.4em;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    //border: 1px solid #fff;
                    background-color: #c70c1500;
                    position: absolute;
                    transition: .5s all ease-in-out;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .8em;
                    color: rgba(255, 255, 255, 0);
                }
            }

            .signotrafref:hover{

                .sh1{
                    color: #ffa929;
                }

                .sh2{
                    color: #16192a;
                }

                .warnr{
                    background-color: #c70c15;
                }

                .warnr1{
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 0px 0px 10px 2px #fff;
                }

                .warnr2{
                    background-color: #c70c15;
                    color: rgba(255, 255, 255, 1);
                    text-shadow: 0px 0px 2px #fff;
                }

                .warny{
                    background-color: #fa9600;
                }

                .warny1{
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 0px 0px 10px 2px #fff;
                }

                .warny2{
                    background-color: #fa9600;
                    color: rgba(255, 255, 255, 1);
                    text-shadow: 0px 0px 2px #fff;
                }
            }

            .wrapref{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: relative;

                span{
                    font-family: 'Poiret One', cursive;
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-size: 2.8em;
                    transition: .6s all ease-in-out;
                    position: relative;
                    z-index: 10;
                }

                .sh1{
                    color: #ff38497c;
                }

                .sh2{
                    color: #0000007e;
                }

                .cari{
                    position: absolute;
                    width: 10em;
                    height: auto;
                    top: calc(2.1em);
                    opacity: 0;
                    transition: .6s all ease-in-out;
                }
            }

            .wrapref:hover{

                .sh1{
                    color: #ff3849;
                    margin-bottom: 1.7em;
                }

                .sh2{
                    color: #000000;
                    margin-bottom: 1.7em;
                }

                .cari{
                    opacity: 1;
                    top: calc(3.7em);
                }
            }

            .signoledref{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: relative;

                span{
                    font-family: 'Poiret One', cursive;
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-size: 2.8em;
                    transition: .6s all ease-in-out;
                    position: relative;
                    z-index: 1;
                }

                .sh1{
                    color: rgba(0, 0, 0, 0.501);
                }

                .sh2{
                    color: #ff2a5c7c;
                }

                .sh3{
                    color: #b2e55b7c;
                }

                .sh4{
                    color: #00acdc79;
                }

                .ad1{
                    position: absolute;
                    width: 4.5em;
                    height: 4.5em;
                    border: 2px solid #ff2a5c7c;
                    border-radius: 50%;
                    animation: ap1 10s linear infinite;
                }

                @keyframes ap1{
                    0%{
                        border: 3px solid #ffffff7c;
                    }

                    25%{
                        border: 3px solid #ff2a5c7c;
                    }

                    50%{
                        border: 3px solid #b2e55b7c;
                    }

                    75%{
                        border: 3px solid #00acdc79;
                    }

                    100%{
                        border: 3px solid #ffffff7c;
                    }
                }
            }

            .signoledref:hover{

                .sh1{
                    color: rgb(0, 0, 0);
                    text-shadow: 0px 0px 8px #fff;
                }

                .sh2{
                    color: #ff2a5c;
                    text-shadow: 0px 0px 8px #ff2a5c;
                }

                .sh3{
                    color: #b2e55b;
                    text-shadow: 0px 0px 8px #b2e55b;
                }

                .sh4{
                    color: #00acdc;
                    text-shadow: 0px 0px 8px #00acdc;
                }

                .ad1{
                    animation: ap2 10s linear infinite;
                }

                @keyframes ap2{
                    0%{
                        border: 3px solid #ffffff;
                        box-shadow: 0px 0px 15px 2px #ffffff;
                    }

                    25%{
                        border: 3px solid #ff2a5c;
                        box-shadow: 0px 0px 15px 2px #ff2a5c;
                    }

                    50%{
                        border: 3px solid #b2e55b;
                        box-shadow: 0px 0px 15px 2px #b2e55b;
                    }

                    75%{
                        border: 3px solid #00acdc;
                        box-shadow: 0px 0px 15px 2px #00acdc;
                    }

                    100%{
                        border: 3px solid #ffffff;
                        box-shadow: 0px 0px 15px 2px #ffffff;
                    }
                }
            }
        }
    }
}

@media(max-width: 992px){
    .first-cont{
        .mercados-cont{
            grid-template-columns: repeat(2, 1fr);

            .opc-cont{

                .shopinkref{

                    .sh1{
                        color: rgb(245, 38, 97);
                    }

                    .sh2{
                        color: rgb(0, 169, 191);
                    }

                    .sh3{
                        color: rgb(255, 194, 51);
                    }

                    .got1{
                        background-color: rgb(0, 0, 0);
                        top: 1.8em;
                    }

                    .got2{
                        background-color: rgb(255, 194, 51);
                        top: 6.2em;
                    }

                    .got3{
                        background-color: rgb(245, 38, 97);
                        top: 1.6em;
                    }

                    .got4{
                        background-color: rgb(0, 169, 191);
                        top: 5.7em;
                    }
                }

                .wrapref{

                    .sh1{
                        color: rgb(255, 56, 73);
                        margin-bottom: 1.7em;
                    }

                    .sh2{
                        color: rgb(0, 0, 0);
                        margin-bottom: 1.7em;
                    }

                    .cari{
                        opacity: 1;
                        top: calc(3.7em);
                    }
                }

                .signotrafref{

                    .sh1{
                        color: rgb(255, 169, 41);
                    }

                    .sh2{
                        color: rgb(22, 25, 42);
                    }

                    .warnr{
                        background-color: rgb(199, 12, 21);

                        .warnr1{
                            background-color: rgb(255, 255, 255);
                        }

                        .warnr2{
                            background-color: rgb(199, 12, 21);
                            color: rgb(255, 255, 255);
                        }
                    }

                    .warny{
                        background-color: rgb(250, 150, 0);

                        .warny1{
                            background-color: rgb(255, 255, 255);
                        }

                        .warny2{
                            background-color: rgb(250, 150, 0);
                            color: rgb(255, 255, 255);
                        }
                    }
                }

                .signoledref{
                    
                    .sh1{
                        color: rgb(0, 0, 0);
                    }

                    .sh2{
                        color: rgb(255, 42, 92);
                    }

                    .sh3{
                        color: rgb(178, 229, 91);
                    }

                    .sh4{
                        color: rgb(0, 172, 220);
                    }

                    .ad1{
                        animation: ap2 10s linear infinite;
                    }
                }
            }
        }
    }
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 2px solid #ddd;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline:none;
  -webkit-appearance: none;
  background-color: #eeeeee;
  border: none;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  cursor: pointer;
  margin: 0;
  position: relative;
  padding:0;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 0.5rem;
  height: 2px;
  background-color: #212121;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
  font-family: sans-serif;
  max-width: 4.5rem;
  padding: .5rem;
  border:0;
  text-align: center;
  outline:none;
}
.number-input{
  border: solid #c2c4c6;
  border-width:2px;
}

@media(max-width: 610px){
    .first-cont{
        .mercados-cont{
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

// Screen size hidden
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  // .hidden-md-down {
  //   display: none !important;
  // }
  #relacionados {
    display: none !important;
  }
  #relacionados2 {
    display: none !important;
  }
  .mySwiper swiper-slide {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.error-message {
  border: 1px solid red;
}