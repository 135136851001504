import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoginService } from '../../services/login.service';

@Component({
  standalone: true,
  imports: [
    IonicModule,
    CommonModule
  ],
  selector: 'app-resumen-venta',
  templateUrl: './resumen-venta.component.html',
  styleUrls: ['./resumen-venta.component.scss'],
})
export class ResumenVentaComponent  implements OnInit {
  OV:any;
  datosVenta:any =[];
  venta:any =[];
  pedidos : any =[];

  constructor(
    private loginService: LoginService,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      this.OV = params["ov"];
      this.loginService.DetalledeVenta(this.OV).subscribe(resumen =>{
        this.datosVenta = resumen;

        this.venta = this.datosVenta.data;

      })
      this.estado();
    })
  }

  estado() {
    this.activatedRoute.params.subscribe(params=>{
    this.OV = params["ov"];
      this.loginService.EstadoPedido(this.OV).subscribe((datoos:any) =>{
        this.pedidos = datoos[0];
      })
    })
  }
}
