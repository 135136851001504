<section class="check-step-cont" style=" margin-top: 100px;">
  <div class="step-3-cont" id="pago">
    <h3 class="titles-steper">3.- PAGO</h3>
    <div class="pago-cont">
      <div class="terms-cont">
        <mat-slide-toggle name="checkacepto" [(ngModel)]="facturame2" (change)="aceptacion()"></mat-slide-toggle>
        <span class="txt-terms">
          Estoy de acuerdo con los
          <a>términos del servicio</a>
          y los
          <a>términos de revocación</a>
          y los acepto sin reservas
        </span>
      </div>
      <span class="debestc" *ngIf="facturame2 == false">Debes aceptar términos y condiciones<br>para proceder con el
        pago.</span>
      <div class="suma-total">
        <span class="txt-mon">Total a pagar:</span>
        <span class="mon">${{grandTotal | number: '1.2-2'}}</span>
      </div>
      <div class="facturas-cont" *ngIf="facturame2">
        <div class="txt-cont-facturas">
          <span class="txt-facts">¿Desea Facturar?</span>
          <mat-slide-toggle name="checkacepto" (change)="facturado()"></mat-slide-toggle>
        </div>
        <div *ngIf="facturame">
          <div class="txt">
            <div class="compra">
              <select class="inps" [(ngModel)]="usocfdi" style="color: black;background-color: white;" value="G00">
                <option value="G01">G01 - Adquisición de mercancías</option>
                <option value="G02">G02 - Devoluciones, descuentos o bonificaciones</option>
                <option value="G03">G03 - Gastos en general</option>
                <option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</option>
                <option value="I04">I04 - Equipo de cómputo y accesorios</option>
                <option value="I08">I08 - Otra maquinaria y equipo</option>
                <option value="P01">P01 - Por definir</option>
              </select>
              <input class="inps" type="text" [(ngModel)]="razonSocial" name="razonSocial" placeholder="RAZON SOCIAL">
              <input class="inps" type="text" name="rfc" [(ngModel)]="rfc" placeholder="RFC">
              <input class="inps" type="text" name="codigoPostal" [(ngModel)]="codigoPostal"
                placeholder="CODIGO POSTAL">
              <input class="inps" type="text" name="DirFiscal" [(ngModel)]="DirFiscal" placeholder="DIRECCIÓN FISCAL">
              <!-- <input class="inps" type="text" name="FormaPago" [(ngModel)]="metodoPago"  placeholder="Forma de Pago"> -->
              <select class="inps" [(ngModel)]="metodinPago" style="color: black;background-color: white;" value="G00">
                <option value="00">METODO DE PAGO</option>
                <option value="Transferencia">TRANSFERENCIA</option>
                <option value="Webpay">WEBPAY</option>
                <option value="Paypal">PAYPAL</option>
                <option value="Oxxo">OXXO</option>
              </select>
              <select class="inps" [(ngModel)]="regimenfiscal" style="color: black;background-color: white;"
                value="600">
                <option value="601">601 - General de Ley Personas Morales</option>
                <option value="603">603 - Personas Morales con Fines no Lucrativos</option>
                <option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                <option value="606">606 - Arrendamiento</option>
                <option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
                <option value="608">608 - Demás ingresos</option>
                <option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                <option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
                <option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
                <option value="614">614 - Ingresos por intereses</option>
                <option value="615">615 - Régimen de los ingresos por obtención de premios</option>
                <option value="616">616 - Sin obligaciones fiscales</option>
                <option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos
                </option>
                <option value="621">621 - Incorporación Fiscal</option>
                <option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                <option value="623">623 - Opcional para Grupos de Sociedades</option>
                <option value="624">624 - Coordinados</option>
                <option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas
                  Tecnológicas</option>
                <option value="626">626 - Régimen Simplificado de Confianza</option>
              </select>

              <!-- <input class="inps" type="text" name="UsoCFDI" [(ngModel)]="UsoCFDI" placeholder="Uso de CFDI"> -->
              <p>Guardar Datos para futuras facturas <button (click)="guardaDatosFactura()"
                  style="background-color: #ff5c6a; color: #fff; font-size: 1.5em; width: 1.5em; height: 1.5em; margin-left: .5em; border-radius: 1em;">+</button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cont-opts-acord">
        <div class="opt-acord">
          <div class="up">
            <input id="tranferenciaR" type="radio" name="radioPagos" value=1 (change)="transferencia()"
              [disabled]="facturame2 == false">
            <label for="tranferenciaR">
              Pago por Transferencia
            </label>
          </div>
          <div style="width: 100%;" *ngIf="metodoPago == 'Transferencia' && facturame2">
            <div class="part4" style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;">
              <button class="compra-ya" id="comprame" (click)="relizarCompra()">Comprar</button>
            </div>
          </div>
        </div>
        <div class="opt-acord">
          <div class="up">
            <input id="webpayR" type="radio" name="radioPagos" value=2 (change)="tarjetas()"
              [disabled]="facturame2 == false">
            <label for="webpayR">
              Pago con Tarjeta (Web Pay)
            </label>
          </div>
          <div style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;"
            *ngIf="metodoPago == 'Web Pay' && facturame2">
            <button class="abirwp" (click)="handleWP()">Abrir</button>
            <div class="modal-c-wp" #modal_wp id="modal_wp">
              <div class="btn-wp-cont">
                <button class="btn-wp-close" (click)="handleWP()">Cerrar</button>
              </div>
              <iframe [src]="urlsafe" scrolling="yes" height="850px"
                style="width: 100%; overflow: hidden; border: 0px; display: inherit;"></iframe>
            </div>
          </div>
        </div>
        <div class="opt-acord" *ngIf="grandTotal>15">
          <div class="up">
            <input id="oxxoR" type="radio" name="radioPagos" value=3 (change)="oxxoPay()"
              [disabled]="facturame2 == false">
            <label for="oxxoR">
              Pago en Efectivo OXXO
            </label>
          </div>
          <div style="width: 100%;" *ngIf="metodoPago == 'Oxxo Pay' && facturame2">
            <div class="part4" style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;">
              <button class="compra-ya" id="comprame" (click)="relizarCompra()">Comprar</button>
            </div>
          </div>
        </div>
        <div class="opt-acord">
          <div class="up">
            <input id="paypalR" type="radio" name="radioPagos" value=4 (change)="paypal()"
              [disabled]="facturame2 == false">
            <label for="paypalR">
              Pago con PayPal
            </label>
          </div>
          <div style="width: 100%; padding-top: 1em;" *ngIf="valorRadio==4 && facturame2">
            <ng-container *ngIf="valorRadio == 4">
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <button class="back-checkout-3" (click)="regresaPago()">Regresar</button>
  </div>



  <div class="loader" *ngIf="cargando">
    <img src="/assets/img/loadersillo.gif">
  </div>
</section>