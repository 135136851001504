<section class="mesa-regalos-tipos-cont" style=" margin-top: 100px;">
  <div class="fix-width">
    @if(isDesk) {
    <div class="banner-1-cont">
      <img src="/assets/img/mesa-regalos/banner-mesa.webp">
    </div>
    }
    @else if(isCel) {
    <div class="banner-1-cont">
      <img src="/assets/img/mesa-regalos/banner-1-cel.webp">
    </div>
    }

    <div class="info-first-cont">
      <span class="title-info-first">
        Enciende tu creatividad con el regalo perfecto para cada ocasión
      </span>
      <span class="desc-info-first">
        ¿Estás por iniciar tu propio negocio, o quizás vas a celebrar un aniversario de inauguración, una boda, un
        cumpleaños,
        una graduación, un retiro o cualquier otro momento importante en tu vida? Crear una lista de regalos te ayuda a
        recibir eso que tanto necesitas.
      </span>
      <span class="anun-info-first">
        Escoge tu evento y crea la mesa de regalo de tus sueños
      </span>
    </div>

    <div class="fila-btns-cont">
      <button class="mesa-opt-btn" (click)="send('1')">
        <img src="assets/img/mesa-regalos/btn-1.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Inicio mi negocio!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('2')">
        <img src="assets/img/mesa-regalos/btn-2.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Mi negocio cumple años!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('3')">
        <img src="assets/img/mesa-regalos/btn-3.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Es mi cumple!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('4')">
        <img src="assets/img/mesa-regalos/btn-4.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Me caso!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('5')">
        <img src="assets/img/mesa-regalos/btn-5.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Voy a tener un bebé!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('6')">
        <img src="assets/img/mesa-regalos/btn-6.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Me gradúo!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('7')">
        <img src="assets/img/mesa-regalos/btn-7.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Me jubilo!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('8')">
        <img src="assets/img/mesa-regalos/btn-8.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Open House!</span>
      </button>
      <button class="mesa-opt-btn" (click)="send('9')">
        <img src="assets/img/mesa-regalos/btn-9.webp" alt="" class="img-btn">
        <span class="txt-opt">¡Personaliza tu evento!</span>
      </button>
    </div>

    <div class="banner-cont-mid">
      <img src="assets/img/mesa-regalos/banner-mid.webp" alt="">
    </div>

    <div class="beneficios-cont">
      <span class="title-beneficios">Celebra con nosotros esta ocasión tan especial, y obtén los siguientes <span
          style="font-weight: 500;">beneficios:</span></span>
      <div class="bens-contan-r">
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-1.webp" alt="" class="img-ben">
          <span class="txt-ben">¡Celebramos contigo cualquier evento!</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-2.webp" alt="" class="img-ben">
          <span class="txt-ben">Es un servicio gratuito</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-3.webp" alt="" class="img-ben">
          <span class="txt-ben">Podemos asesorarte en cualquier momento</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-4.webp" alt="" class="img-ben">
          <span class="txt-ben">Nuestros productos tienen garantía</span>
        </div>
        <div class="beneficio">
          <img src="assets/img/mesa-regalos/icon-5.webp" alt="" class="img-ben">
          <span class="txt-ben">Recibe 50 lidepuntos al crear una mesa de regalos con nosotros</span>
        </div>
      </div>
    </div>

    <!-- <div class="faq-cont">
      <span class="title-faq">Preguntas frecuentes</span>
      <button class="desp-btn-faq">¿Cuanto cuesta abrir una mesa de regalos?<i
          class="fa-solid fa-sort-down"></i></button>
      <button class="desp-btn-faq">¿Puedo dar de baja mi mesa de regalos si mi evento se cancela?<i
          class="fa-solid fa-sort-down"></i></button>
      <button class="desp-btn-faq">¿Donde encuentro una mesa de regalos si perdí el código?<i
          class="fa-solid fa-sort-down"></i></button>
      <button class="desp-btn-faq">¿Puedo modificar la dirección de entrega de mi mesa de regalos una vez creado el
        evento?<i class="fa-solid fa-sort-down"></i></button>
      <button class="mini-desp-btn-faq">Ver más preguntas frecuentes<i class="fa-solid fa-caret-right"></i></button>
    </div> -->
  </div>
</section>