import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { Usuario } from '../../Models/usuario';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-mesa-regalos-principal',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ClipboardModule
  ],
  templateUrl: './mesa-regalos-principal.component.html',
  styleUrls: ['./mesa-regalos-principal.component.scss'],
})
export class MesaRegalosPrincipalComponent implements OnInit {

  public isDesk!: boolean;
  public isCel!: boolean;

  public tieneMesa: boolean = true;
  public linkMesa: string = 'https://lideart.com.mx/mesa-regalos-comparte/';
  usuario!: Usuario;
  mesita: any = []
  celular: any
  ciudad: any
  codigo_postal: any
  correo: any
  direccion: any
  estado: any
  fecha_creada: any
  fecha_evento: any
  id_mesa: any
  json_articulos: any
  mensaje: any
  nombre: any
  nombre_evento: any
  referencia: any
  colonia: any;
  diasparaEvento: any;
  regalillos: any = []
  cuantos: any;
  cuantoshaycomprados = 0




  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private usuarioService: LoginService
  ) { }

  ngOnInit() {
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('foo')
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }

      try {
        this.usuarioService.usuario.subscribe(res => {
          if (!res) return
          this.usuario = res;

          if (this.usuario) {

            this.usuarioService.checaMesa(this.usuario.usuario).subscribe(mesita => {


              this.mesita = mesita;


              if (this.mesita == 0) {
                this.tieneMesa = false;
              }

              this.celular = this.mesita[0].celular;
              this.ciudad = this.mesita[0].ciudad;
              this.codigo_postal = this.mesita[0].codigo_postal;
              this.correo = this.mesita[0].correo;
              this.direccion = this.mesita[0].direccion;
              this.estado = this.mesita[0].estado;
              this.fecha_creada = this.mesita[0].fecha_creada;
              this.fecha_evento = this.mesita[0].fecha_evento;
              this.id_mesa = this.mesita[0].id_mesa;
              this.json_articulos = this.mesita[0].json_articulos;
              this.mensaje = this.mesita[0].mensaje;
              this.nombre = this.mesita[0].nombre;
              this.nombre_evento = this.mesita[0].nombre_evento;
              this.referencia = this.mesita[0].referencia.toString().trim();
              this.colonia = this.mesita[0].colonia;

              this.linkMesa = this.linkMesa + this.referencia;


              const timeDiff = new Date(this.fecha_evento).getTime() - new Date().getTime();

              const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));

              this.diasparaEvento = daysDiff
              this.usuarioService.checaRegalos(this.usuario.usuario).subscribe(regalitos => {

                this.regalillos = regalitos;
                this.cuantos = this.regalillos.length

                for (let index = 0; index < this.regalillos.length; index++) {

                  const cantidad = this.regalillos[index].comprado;
                  if (cantidad == 1) {
                    this.cuantoshaycomprados++
                  }
                }
              })

            })



          }

        });
      } catch (error) {
        this.tieneMesa = false;

      }

    }
  }

}
