.conttipscat{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    padding: 2em 0em;
  
    .enlacbtn{
      width: 70%;
      height: 5em;
      border-radius: 2em 0em 2em 0em;
      display: flex;
      flex-direction: row;
      justify-content: flex-start; 
      align-items: center;
      padding-left: 1.5em;
      position: relative;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      z-index: 1;
  
      span{ 
        position: relative;
        z-index: 1;
        color: rgb(240, 240, 240); 
        font-size: 1.1em;
        font-weight: 600;
      }
  
      .topcontad{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 63%;
        border-radius: 2em 0em 0em 0em;
        background-color: #4ed4e0;
        z-index: 0;
      }
  
      .botcontad{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 37%;
        border-radius: 0em 0em 2em 0em;
        background-color: #81e0e8;
        z-index: 0;
      }
    }
  
    .enlacbtn:nth-child(1){
      span{
        color: #fff;
      }
  
      .topcontad{
        background-color: #4ed4e0;
      }
  
      .botcontad{
        background-color: #81e0e8;
      }
    }
  
    .enlacbtn:nth-child(2){
      span{
        color: #04b1d0;
      }
  
      .topcontad{
        background-color: #78e0da;
      }
  
      .botcontad{
        background-color: #8fe5e0;
      }
    }
  
    .enlacbtn:nth-child(3){
      span{
        color: #2bb9d1;
      }
  
      .topcontad{
        background-color: #c6ecdc;
      }
  
      .botcontad{
        background-color: #ceefe1;
      }
    }
  
    .enlacbtn:nth-child(4){
      span{
        color: #fff;
      }
  
      .topcontad{
        background-color: #ffb6ba;
      }
  
      .botcontad{
        background-color: #ffc3c6;
      }
    }
  
    .enlacbtn:nth-child(5){
      span{
        color: #fff;
      }
  
      .topcontad{
        background-color: #ffaf95;
      }
  
      .botcontad{
        background-color: #ffb9a1;
      }
    }
  
    .enlacbtn:nth-child(6){
      span{
        color: #fff;
      }
  
      .topcontad{
        background-color: #78e0da;
      }
  
      .botcontad{
        background-color: #8fe5e0;
      }
    }
  }
  
  
  .tipos-cont-cel{
      background-image: url('../../../assets/img/iconosCategorias/fondo.png'); //rgb(189, 193, 194) !important
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
  
      /*--ion-background-color: rgb(4,215,218); //rgb(189, 193, 194) !important
      --ion-background-color: radial-gradient(circle, rgba(4,215,218,1) 23%, rgba(45,166,253,1) 82%);*/
  
      }
  
      ion-button {
          --box-shadow: transparent !important;
        }
  
  
  