import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class FacebookApiService {

  private readonly SERVER_URL = 'https://facebook-conversion-server.vercel.app/send-event';
  //private readonly SERVER_URL = 'http://localhost:3000/send-event';

  constructor(private http: HttpClient) {}

  private getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }

  private hashData(data: string): string {
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex); // Use the correct method to get the hex string
  }

  private savePrice(val: string | number ): number | undefined{
    const tipo = typeof val;
    if(tipo == 'number'){
      val = val as number
      return parseFloat(val.toFixed(2));
    }
    else if (tipo === "string"){
      val = val as string
      const digits = val.replace(/[^\d.]/g, '');
      // Convierte la cadena resultante a número
      const number = parseFloat(digits);
      // Ajusta la precisión a 2 decimales
      return parseFloat(number.toFixed(2));
    }
    return undefined;
  }

  sendEvent(eventName: string, value?: string | number, email?: string, phone?: string, additionalParams?: any) {
    const fbc = this.getCookie('_fbc');
    const fbp = this.getCookie('_fbp');
    if (value !== undefined) value = this.savePrice(value)
    const hashedEmail = email ? this.hashData(email) : undefined;
    const hashedPhone = phone ? this.hashData(phone) : undefined;
    const hashedExternalId = additionalParams?.external_id ? this.hashData(additionalParams.external_id) : undefined;

    const data = {
      eventName: eventName,
      value: value,
      email: hashedEmail,
      phone: hashedPhone,
      external_id: hashedExternalId,
      additionalParams: {
        country: additionalParams?.country,
        nombre: additionalParams?.nombre,
        apellido: additionalParams?.apellido,
        estado: additionalParams?.estado,
        ciudad: additionalParams?.ciudad,
        codigo_postal: additionalParams?.codigo_postal,
        external_id: additionalParams?.external_id,
        content_ids: additionalParams?.content_ids,
        content_name: additionalParams?.content_name,
        content_type: additionalParams?.content_type,
        num_items: additionalParams?.num_items,
        fbc: fbc,
        fbp: fbp,
        event_source_url: window.location.href
      }
    };

    this.http.post(this.SERVER_URL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).subscribe({
      next: response => {
        console.log('Success... Respues', response, 'data send: ', data );
      },
      error: error => {
        console.error('Error:', error);
      }
    });
  }

}
