import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [
    IonicModule
  ],
  selector: 'app-rally',
  templateUrl: './rally.component.html',
  styleUrls: ['./rally.component.scss'],
})
export class RallyComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Lidepuntos");
  }

}
