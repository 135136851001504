<div class="md:mt-[130px] min-h[15em]">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <div class="columnas" *ngIf="estoyactivo ==1">
    <ion-row style="width: 100%;">
      <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;" id="descripcionProducto">
        <ion-card *ngFor="let producto of productoData"
          style="box-shadow: none;border: none; height: min-content; margin-inline: 0px; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <ion-text class="tituloDescripcion"><u>{{producto.title}} </u></ion-text>

          <ion-card-content id="">
            <ion-card-title class="ion-text-center">Descripción del Producto</ion-card-title>
            <br>
            <div [innerHTML]="descripcionsilla"></div>
          </ion-card-content>
        </ion-card>
        <!-- <ion-card-title *ngIf="relacionados.length>0" class="ion-text-center">Productos de Categorias Relacionadas</ion-card-title>
        <div class="swiper-container" id="swiperEscritorio" *ngIf="relacionados.length>0">
          <swiper-container space-between="10" slides-per-view="5" free-mode="true" watch-slides-progress="true" class="mySwiper" >
            <ng-container *ngFor="let item of relacionados; index as i">
              <swiper-slide>
                <a (click)="navigateToDescripcionProducto(item.linki,item.id_product,item.link_prod,item.paquete,item.sku)" style="color: #15dbf5;"><img src="{{ item.image_link }}"  /> {{item.title}}</a>
                
              </swiper-slide>
            </ng-container>
          </swiper-container>
         </div>       -->
      </ion-col>
      <ion-col size="12" size-md="6" size-lg="4">
        <ion-card style="box-shadow: none;margin: 0; border: none; -webkit-margin-start: 0px; margin-inline-start: 0px;"
          *ngFor="let producto of productoData">
          <ion-text class="titulillo">{{producto.title}}</ion-text>
          <!-- <img src="{{producto.image_link}}"> -->

          <div class="swiper-container-wrapper">
            <div style="padding-top: 0em;" class="swiper-container" style="align-items: baseline;" id="zoom">
              <swiper-container space-between="0" navigation="true" thumbs-swiper=".mySwiperP" class="mySwiperP2">
                <ng-template ngFor let-item [ngForOf]="slider" let-i="index">
                  <swiper-slide>
                    <img [src]="item.slider" alt="{{producto.title}}" />
                  </swiper-slide>
                </ng-template>
              </swiper-container>
            </div>

            <div class="swiper-container">
              <swiper-container space-between="10" navigation="false" slides-per-view="3" free-mode="true"
                watch-slides-progress="true" class="mySwiperP">
                <ng-container *ngFor="let item of slider; index as i">
                  <swiper-slide>
                    <img src="{{item.slider}}" alt="{{producto.title}}" />
                  </swiper-slide>
                </ng-container>
              </swiper-container>

            </div>

          </div>


          <swiper-container id="swiper-mobile" navigation="true" pagination="false" class="mySwiper2Cel"
            style="width: 100%;">
            <swiper-slide *ngFor="let item of slider"><img src="{{item.slider}}"
                alt="{{producto.title}}"></swiper-slide>
          </swiper-container>
        </ion-card>
      </ion-col>
      <!-- <ion-col size="12" size-md="6" size-lg="4"style="height: min-content;">
          <ion-card *ngFor="let producto of productoData" style="box-shadow: none; border:none">
            <ion-card-content>
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-text class="text" >Precio:</ion-text>
                  </ion-col>
                  <ion-col>
                    <ion-text class="text" >${{producto.precio | number: '1.2-2'}} mxn </ion-text>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                  <ion-text class="text" >SKU:</ion-text>
                  </ion-col>
                  <ion-col>
                  <ion-text class="text">{{producto.sku}}</ion-text> 
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-text class="text">Existencia:</ion-text>
                  </ion-col>
                  <ion-col>
                  <ion-text class="text">{{existencias}}</ion-text> 
                  </ion-col>
                </ion-row>
                <ion-row>
                      <div *ngFor="let atributo of atributos">
                        <ion-col >
                        <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                      </ion-col>
                      <ion-col >
                        <div *ngIf="atributo.is_color_group != '1'">
                          <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                            class="selector-color id_group_{{atributo.id_attribute_group}}" >
                            <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                              style="background-color: {{valor.color}}">
                            <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                            (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                              style="background-image: url({{valor.image}});">
              
                          </div>
                        </div>
                      
                      
                        <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos" (change)="cambiaSku()"
                          class="form-group {{atributo.id_attribute_group}}" >
                          <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}</option>
                        </select>
                      </ion-col>
                      </div>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-text class="text">Cantidad:</ion-text>   
                  </ion-col>
                  <ion-col>
                    <ion-input class="text" type="number" min="1" value="1" max="{{producto.existencia}}"></ion-input>
                  </ion-col>
                </ion-row>
                <ion-button (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</ion-button>
                <ion-button routerLink="/cart" >Ir a Carrito</ion-button>
                <ion-button (click)="agregarWishlist()">Agregar a Wishlist</ion-button>
              </ion-grid>
            </ion-card-content>    
          </ion-card>
        </ion-col> -->

      <ion-col size="12" size-md="6" size-lg="4">
        <ion-card *ngFor="let producto of productoData"
          style="box-shadow: none; border:none; -webkit-margin-start: 0px; margin-inline-start: 0px;">
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col *ngIf="!descuentillo">
                  <ion-text class="precio">${{precioDyn | number: '1.2-2'}}</ion-text>
                  <span
                    style="font-size: 14px;color: rgb(29, 171, 209);font-family: 'RobotoCondensed-Light';text-transform: uppercase;">
                    mxn </span>
                </ion-col>

                <ion-col *ngIf="descuentillo">

                  <!-- <ion-text class="text">${{precioDyn*descuento | number: '1.2-2'}} <del>${{precioDyn | number:
                      '1.2-2'}}mxn </del> - {{resdesc*100 | number: '1.2-2'}}% de descuento</ion-text> -->
                  <ion-text class="textDesc">{{resdesc*100 | number: '1.2-2'}}% de descuento</ion-text><br>
                  <del class="price-strikethrough">${{precioDyn | number: '1.2-2'}}mxn </del>
                  <ion-text class="text">${{precioDyn*descuento | number: '1.2-2'}} </ion-text>

                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-text style="font-size: 12px; font-family: 'RobotoCondensed-Light';color: rgb(51,51,51);">IVA
                    incluido</ion-text>
                </ion-col>
              </ion-row>

              <div
                style="border-top: 1px solid #eaeaea;border-bottom: 1px solid #eaeaea; color: #333333; padding-top: 12px;padding-bottom: 12px;font-size: 18px;">
                <div>
                  <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                    <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.3em 0.5em;">Código</dt>
                    <dt id="referencia"
                      style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.3em 0.5em; color: #111; font-size: .9em;">
                      {{ producto.sku }}</dt>
                  </dl>
                  <dl id="CodigoYColor" style="color: rgb(29, 171, 209);">
                    <dt style="background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.3em 0.5em;">
                      Color
                    </dt>
                    <dt
                      style="text-align: left; background:#F7F7F7;border:1px solid #E0E0E0; padding: 0.3em 0.5em; color: #111; font-size: .9em;">
                      {{colorSeleccionado}}

                      <div class="r-color" style="background-color: {{varColorSelected}};"></div>
                      <div class="r-color" *ngIf="varColorSelected"
                        [style.background-image]="'url(' + varColorSelected + ')'"></div>
                    </dt>
                  </dl>
                </div>


                @if(precioDyn > 300){
                <kueskipay-widget data-kpay-color-scheme="black" data-kpay-widget-font-size="16">
                </kueskipay-widget>
                }
              </div>

              <ion-row>
                <!-- <ion-col>
                 <ion-text class="text" >SKU:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text">
                    
                      <span id="referencia">{{ producto.sku  }}
                      </span>
                  </p>
                </ion-col> -->
              </ion-row>
              <ion-row id="existencia">
                <ion-col>
                  <ion-text class="text">Existencia:</ion-text>
                </ion-col>
                <ion-col>
                  <ion-text class="text" *ngIf="existencias>0" style="color:green">Disponible</ion-text>
                  <ion-text class="text" *ngIf="existencia<=0" style="color:red">Agotado</ion-text>
                </ion-col>
              </ion-row>
              <ion-row id="existenciaDesk">
                <div class="conten-exist">
                  <span class="rigti" *ngIf="existencias>0" style="color:green">Disponible</span>
                  <span class="rigti" *ngIf="existencia<=0" style="color:red">Agotado</span>
                </div>
              </ion-row>
              <!-- <ion-row>
                <ion-col>
                  <ion-text class="text" style="color:rgb(27, 172, 209);">Unidad de Venta:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text"><span id="unidadVenta">{{producto.unitId}}</span></p>
                </ion-col>
              </ion-row> -->
              <ion-row id="colorSeleccionado">
                <ion-col>
                  <ion-text class="text">Color Seleccionado:</ion-text>
                </ion-col>
                <ion-col>
                  <p class="text"><span>{{colorSeleccionado}}</span></p>
                </ion-col>
              </ion-row>
              <ion-row>

                <div *ngFor="let atributo of atributos">
                  <ion-col>
                    <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                  </ion-col>
                  <ion-col>
                    <div *ngIf="atributo.is_color_group != '1'" class="color-cont-prop">
                      <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                        class="selector-color id_group_{{atributo.id_attribute_group}}">
                        <!-- Botón de color -->
                        <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.color)"
                          style="background-color: {{valor.color}}">
                        <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                          (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group,valor.image)"
                          style="background-image: url({{valor.image}});">

                        <!-- Nombre del color debajo del círculo -->
                        <div class="color-name">{{ valor.name }}</div>
                      </div>
                    </div>


                    <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos"
                      (change)="cambiaSku()" class="form-group {{atributo.id_attribute_group}}">
                      <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}
                      </option>
                    </select>
                  </ion-col>
                </div>
              </ion-row>
              <ion-row>
                <ion-col>
                  <!-- <ion-text class="text">Cantidad:</ion-text>    -->
                  <div>
                    <!-- <ion-row >
                      <ion-col>
                        <ion-label style="font-family: 'RobotoCondensed-Light'; font-weight: bold;">
                          CANTIDAD
                        </ion-label>
                      </ion-col>
                    </ion-row>                  
                    <ion-row>
                      <ion-col>
                        <ion-label>
                          <ion-item>
                            <ion-input style="float: left;" type="number" [(ngModel)]="cantidad" name="cantidad"></ion-input>
                          </ion-item>
                        </ion-label>
                      </ion-col>
                    </ion-row> -->
                  </div>
                </ion-col>

                <!-- <div>
                  <ion-row>
                    <ion-col>
                      <ion-label style="font-family: 'RobotoCondensed-Light';">
                        CANTIDAD
                      </ion-label>
                    </ion-col>
                  </ion-row>                  
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        <ion-item>
                          <ion-input style="float: left;" type="number" [(ngModel)]="cantidad" name="cantidad"></ion-input>
                        </ion-item>
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </div> -->
                <ion-col>
                  <!-- <ion-item>
                    <ion-label style="font-family: 'RobotoCondensed-Light';">CANTIDAD</ion-label>
                  </ion-item>
                  <ion-item>
                    <ion-input type="number" [(ngModel)]="cantidad" name="cantidad"></ion-input>
                  </ion-item> -->



                  <!-- <ion-item>
                    <ion-label>
                      <p>Cantidad: <input style="width: 30%;" type="number"  [value]="quantity"  (change)="validateInput($event,i)"> </p> -->

                  <!-- <ion-input label="cantidadInput" type="number" placeholder="1"></ion-input> -->
                  <!-- <input type="number" placeholder="1"></input> -->
                  <!-- </ion-label>
                  </ion-item> -->
                </ion-col>

                <!-- <ion-col style="display: flex;align-items: center; justify-content:start ;">
                  <ion-icon name="remove-circle" style="width: 25px;height: 30px;color: #6cdff9;padding-right: 10px;" (click)="decrement()"></ion-icon>
                    <ion-label style="font-size:15px;color: black;font-weight: bold;">{{cantidad}}</ion-label> 
                     <ion-icon name="add-circle" style="width: 25px;height: 30px;color:#6cdff9;padding-left: 10px;" (click)="increment()"></ion-icon> 
                  <ion-input class="text" type="number" min="1" onkeyup="if(this.value<0){this.value= this.value * -1}" [(ngModel)]="cantidad" max="{{producto.existencia}}"></ion-input>
                </ion-col> -->
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-label style="font-family: 'RobotoCondensed-Light'; font-weight: bold;">
                    CANTIDAD
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-label>
                    <ion-item>
                      <input type="number" min="1" [(ngModel)]="cantidad" name="cantidad" class="inp-manage">
                    </ion-item>
                  </ion-label>
                </ion-col>
              </ion-row>
              <!-- <ion-button  (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</ion-button> -->
              <button
                style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
                *ngIf="existencias>0 && precioDyn >0" id="addCarrito"
                (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</button>
              <div *ngIf="existencias==0" class="nohay-cont">
                <span class="textin">Producto Agotado</span>
                <i class="fa-solid fa-face-sad-tear"></i>
              </div>
              <button id="irCarrito" routerLink="/cart"
                style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
                *ngIf="precioDyn >0">Ir
                a Carrito</button>
              <br>
              <button
                style="display: block; width: 14em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;"
                *ngIf="usuario && precioDyn >0" id="wishlist" (click)="agregarWishlist()">Agregar a
                Wishlist</button>
              <!-- <ion-button expand="full" shape="round" color="secondary"  routerLink="/cart"  >Ir a Carrito</ion-button>
              <ion-button expand="full" shape="round" color="secondary"  (click)="agregarWishlist()" >Agregar a Wishlist</ion-button> -->
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>


      <!-- <ion-col size="12" size-md="6" size-lg="4" style="height: min-content;" id="descripcionProducto">    
        <ion-card *ngFor="let producto of productoData" style="box-shadow: none;border: none; height: min-content;"> 
          <ion-text class="tituloDescripcion"><u>{{producto.title}} </u></ion-text>          
               
                <ion-card-content id="">
                  <ion-card-title class="ion-text-center">Descripción del Producto</ion-card-title>
                 <br>
                  <div [innerHTML]="descripcionsilla"></div>
                </ion-card-content>
        </ion-card>        
     </ion-col> -->

    </ion-row>

    <ion-card *ngFor="let producto of productoData" style="-webkit-margin-start: 0px; margin-inline-start: 0px;">
      <!-- <ion-text class="textillo">{{producto.title}}</ion-text> -->



      <!-- <swiper id="swiper-mobile" [navigation]="true" [pagination]="false" class="mySwiper2">
        <ng-template *ngFor="let item of slider" swiperSlide><img  src="{{item.slider}}"></ng-template>
    </swiper>

      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-text class="text" >Precio:</ion-text>
            </ion-col>
            <ion-col>
              <ion-text class="text" >${{producto.precio | number: '1.2-2'}} mxn </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
             <ion-text class="text" >SKU:</ion-text>
            </ion-col>
            <ion-col>
             <ion-text class="text">{{producto.sku}}</ion-text> 
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text class="text">Existencia:</ion-text>
            </ion-col>
            <ion-col>
            <ion-text class="text">{{existencias}}</ion-text> 
            </ion-col>
          </ion-row>
          <ion-row>
                <div *ngFor="let atributo of atributos">
                  <ion-col >
                  <ion-text class="text" for="">{{atributo.name}}:</ion-text>
                </ion-col>
                <ion-col >
                  <div *ngIf="atributo.is_color_group != '1'">
                    <div *ngFor="let valor of atributo.valores" id="{{valor.id_attribute}}"
                      class="selector-color id_group_{{atributo.id_attribute_group}}" >
                      <input type="button" *ngIf="valor.color != ''" title="{{valor.name}}"
                      (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                        style="background-color: {{valor.color}}">
                      <input type="button" *ngIf="valor.color == ''" title="{{valor.name}}"
                      (click)="seleccionacolor(valor.name,valor.id_attribute,atributo.id_attribute_group)"
                        style="background-image: url({{valor.image}});">
        
                    </div>
                  </div>
                
                
                  <select style="color: black;" *ngIf="atributo.is_color_group == '1'" name="atributos" (change)="cambiaSku()"
                    class="form-group {{atributo.id_attribute_group}}" >
                    <option *ngFor="let valor of atributo.valores" value="{{valor.id_attribute}}">{{valor.name}}</option>
                  </select>
                </ion-col>
                </div>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text class="text">Cantidad:</ion-text>   
            </ion-col>
            <ion-col>
              <ion-input class="text" type="number" min="1" value="1" max="{{producto.existencia}}"></ion-input>
            </ion-col>
          </ion-row>
          <ion-button expand="full" shape="round" color="secondary"  (click)="agregarAlCarrito(productoData[0])">Agregar Al Carrito</ion-button>
          <ion-button expand="full" shape="round" color="secondary"  routerLink="/cart" >Ir a Carrito</ion-button>
          <ion-button expand="full" shape="round" color="secondary"  >Agregar a Wishlist</ion-button>
        </ion-grid>
      </ion-card-content> -->

      <ion-card-content id="caracteristicas">
        <ion-card-title class="font-weight:bolder;text-align:center">Características de Producto</ion-card-title>
        <br>
        <div [innerHTML]="contenidoHTML" class="cont-car"></div>
      </ion-card-content>


      <div class="hidden-sm-up">
        <ion-card-content id="caracteristicas">
          <div class="cont-car">


            <ion-card-title *ngIf="relacionados.length>0" class="ion-text-center">Productos de Categorias
              Relacionadas</ion-card-title>

            <div class="swiper-container">
              <swiper-container space-between="10" navigation="false" slides-per-view="2" free-mode="true"
                watch-slides-progress="true" class="mySwiperP">
                <ng-container *ngFor="let item of relacionados; index as i">
                  <swiper-slide>
                    <a (click)="navigateToDescripcionProducto(item.linki,item.id_product,item.link_prod,item.paquete,item.sku)"
                      style="color: #15dbf5;"><img src="{{ item.image_link }}" /> {{item.title}}</a>
                  </swiper-slide>
                </ng-container>
              </swiper-container>

            </div>



          </div>

        </ion-card-content>
      </div>

      <div class="hidden-md-down">
        <ion-card-content id="caracteristicas">
          <div class="cont-car">


            <ion-card-title *ngIf="relacionados.length>0" class="ion-text-center">Productos de Categorias
              Relacionadas</ion-card-title>
            <div class="swiper-container">
              <swiper-container space-between="10" navigation="false" slides-per-view="5" free-mode="true"
                watch-slides-progress="true" class="mySwiperP">
                <ng-container *ngFor="let item of relacionados; index as i">
                  <swiper-slide>
                    <a (click)="navigateToDescripcionProducto(item.linki,item.id_product,item.link_prod,item.paquete,item.sku)"
                      style="color: #15dbf5;"><img src="{{ item.image_link }}" /> {{item.title}}</a>
                  </swiper-slide>
                </ng-container>
              </swiper-container>

            </div>
          </div>

          <!-- <div style="display: flex; flex-direction:row; align-items: center; justify-content: center;">
          <p style="margin-left: 10px;" *ngFor="let item of todoslossku">{{item.reference}}</p>
        </div> -->
        </ion-card-content>
      </div>


    </ion-card>



  </div>

  <div style="min-height: 15em;" *ngIf="estoyactivo ==0">

    <div
      style="display: flex;justify-content: center; align-items: center;align-content: center;margin-top: 20px;margin-bottom: 20px;">
      <img src="/assets/img/nocarrito.png">
    </div>
    <div
      style="display: flex;justify-content: center; align-items: center;align-content: center;margin-top: 20px;margin-bottom: 20px;">
      <h1>Articulo no disponible</h1>
    </div>

    <div
      style="display: flex;justify-content: center; align-items: center;align-content: center;margin-top: 20px;margin-bottom: 20px;">
      <button routerLink="/home"
        style="display: block; width: 14em; margin-bottom: .5em; padding: .7em 1em; border-radius: .5em; font-size: 1.2em; font-weight: 500; color: #fff; background-color: #0089c5;">Volver
        a Inicio</button>

    </div>


  </div>

  <div class="loader" *ngIf="cargando">
    <img src="/assets/img/loadersillo.gif">
  </div>
</div>