import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConektaService {
  private apiUrl = 'https://api.digitalfemsa.io/customers';
  private accessToken = 'key_8qEer5VSRctJBEx9ztbQJhs';
  private apiUrl2 = 'https://api.digitalfemsa.io/orders';

  private httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/vnd.conekta-v2.1.0+json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.accessToken}`
    })
  };

  constructor(private http: HttpClient) { 
  }

  createCustomer(customerData: any): Observable<any> {
    return this.http.post(this.apiUrl, customerData, this.httpOptions);
  }

  createOrder(orderData: any): Observable<any> {
    return this.http.post(this.apiUrl2, orderData, this.httpOptions);
  }
}
