<section class="cartDesk" *ngIf="isDesk">
  <div class="nohay" *ngIf="totalItems==0">
    <h1 class="ion-text-center">No tienes artículos en el carrito</h1>
    <i class="fa-regular fa-face-sad-tear grap"></i>
    <button class="goshop" routerLink="/categoria/10/categorías-por-tipo-de-producto">Ir a Comprar</button>
  </div>
  <div class="sihay" *ngIf="totalItems>0">
    <h1 class="taitol">Carrito</h1>
    <div class="prodsDesk">
      <div class="prods-cont">
        <div class="fixin">
          <div class="prod" *ngFor="let item of items; let i = index">
            <div class="img-cont">
              <img src="{{item.image_link}}" alt="">
            </div>
            <div class="desc">
              <p class="neim" style="cursor: pointer;"
                (click)="navigateToDescripcionProducto(item.category_rewrite,item.id_product,item.link_rewrite,item.paquete,item.sku)">
                {{item.nombreMuestra}}</p>
              <p class="skiu">SKU: {{item.sku}}</p>
              <p class="rest">Cantidad: <input class="inpa" type="number" [value]="item.quantity"
                  (change)="validateInput($event,i)"> </p>
              <p class="rest">Color: {{item.color}}</p>
              <p class="rest">${{item.precio*item.quantity | number: '1.2-2'}}</p>
            </div>
            <div class="opts-alts">
              <div class="wish">
                <p (click)="presentAlertConfirm(
                  item.id_product,
                item.title,
                item.sku,
                item.quantity
                ,item.image_link,
                item.color,
                item.medida,
                item.precio)"><ion-icon name="heart-outline"></ion-icon> Wishlist</p>

              </div>
              <div class="delete">
                <p (click)="onDelete(i)"><ion-icon name="trash-outline"></ion-icon> Eliminar</p>
              </div>
            </div>
          </div>
        </div>
        <hr style="width: 95%; height: 10px; background: rgb(0 163 197);">
        <div class="subtotal">
          <div class="articulos">
            <p>{{totalItems}} artículo(s)</p>
            <p>${{grandTotal | number: '1.2-2'}}</p>
          </div>
        </div>
      </div>


      <div class="total">

        <!-- <div class="titl">
          <p style="font-weight: bold;">Nombre de la persona que va a recibir este regalo:</p>
        </div>
        <div class="code">
          <input type="text" [(ngModel)]="codigo" name="codigo" >
          <button class="btn"  (click)=" checamelcupon()">Guardar</button>
        </div>

        <div class="titl">
          <p style="font-weight: bold;">Correo electrónico de la persona que va a recibir este regalo:</p>
        </div>
        <div class="code">
          <input type="text" [(ngModel)]="codigo" name="codigo" >
          <button class="btn"  (click)=" checamelcupon()">Guardar</button>
        </div>

        <div class="titl">
          <p style="font-weight: bold;">Escribe un mensaje de felicitación:</p>
        </div>
        <div class="code">
          <input type="text" [(ngModel)]="codigo" name="codigo" >
          <button class="btn"  (click)=" checamelcupon()">Guardar</button>
        </div>
        <br>
        <hr style="border: 1px solid black; width: 100%;">
        <br> -->
        <!--        
          <div class="gift">
          
            <input  [(ngModel)]="codigo" name="codigo" >
          
            <button class="button">
              <img src="/assets/img/botonCarritoCard.jpg"  />
            </button>
          </div>
          <div >
            <p >Correo electrónico de la persona que va a recibir este regalo:</p>
            <input  [(ngModel)]="codigo" name="codigo" >
            <div  style="background-image: url('/assets/img/botonCarritoCard.jpg');" ></div>
          </div>
          <div>
            <p >Escribe un mensaje de felicitación:</p>
            <input  [(ngModel)]="codigo" name="codigo" >
            <div  style="background-image: url('/assets/img/botonCarritoCard.jpg');" ></div>
          </div> -->

        <div class="titl" *ngIf="usuario">
          <p *ngIf="totalpuntos >=10000">Tienes Disponible canjear descuento(s) por tus Lidepuntos</p>
          <button class="btn" (click)="canjea5()" *ngIf="totalpuntos >=10000 && canjieLidepuntos == false">5%
            Descuento</button>
          <button class="btn" style="margin-left: 5px;" (click)=" canjea10()"
            *ngIf="totalpuntos >=30000 && canjieLidepuntos == false">10% Descuento</button>
          <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon2()"
            *ngIf="cancelarLidepuntos == true">Cancelar</button>
          <p *ngIf="canjieLidepuntos == false">¿Tienes un código promocional?</p>
        </div>
        <div class="code" *ngIf="usuario">
          <input type="text" [(ngModel)]="codigo" name="codigo" *ngIf="canjieLidepuntos == false">
          <button class="btn" *ngIf="cancelar == false && canjieLidepuntos == false"
            (click)=" checamelcupon()">Añadir</button>
          <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon()"
            *ngIf="cancelar == true">Cancelar</button>
        </div>
        <div class="tot" *ngIf="descuento == 'no'">
          <p>Total (IVA incluido)</p>
          <p>${{grandTotal | number: '1.2-2'}}</p>
        </div>
        <div class="tot" *ngIf="descuento == 'si'">
          <p *ngIf="descuento == 'si'">Total: </p>
          <p *ngIf="descuento == 'si'">${{grandTotal+calculo | number: '1.2-2'}}</p>

        </div>
        <div class="tot" *ngIf="descuento == 'si'">
          <p *ngIf="descuento == 'si'">Descuento: </p>
          <p *ngIf="descuento == 'si'">${{calculo | number: '1.2-2'}}</p>

        </div>
        <div class="tot" *ngIf="descuento == 'si'">
          <p *ngIf="descuento == 'si'">Total a Pagar: </p>
          <p *ngIf="descuento == 'si'">${{grandTotal | number: '1.2-2'}}</p>

        </div>

        <div class="bot-cont">
          <button class="btn" routerLink="/categoria/10/categorías-por-tipo-de-producto">Seguir comprando</button>
          <button *ngIf="usuario" class="btn" (click)="registraCarrito()">Comprar ahora</button>
          <button class="btn2" (click)="vaciaCarrito()">Vaciar Carrito</button>
          @if (!usuario) {
          <button class="btn noinic" [routerLink]="['/login']" routerLinkActive="router-link-active">
            Inicia sesión</button>
          <!-- <div id="paypal-button-container"></div> -->
          <button class="btn-rosa noinic" [routerLink]="['/checkout-invitado']"
            routerLinkActive="router-link-active">Comprar
            como
            invitado</button>
          }



        </div>
      </div>
    </div>
  </div>
</section>

<div class="cont-cart-cel" *ngIf="totalItems>0 && isCel">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <div class="prods">
    <div class="prod" *ngFor="let item of items; let i = index">
      <div class="f-l">
        <div class="img-cont">
          <img src="{{item.image_link}}" alt="">
        </div>
        <div class="desc">
          <p class="name-prod"
            (click)="navigateToDescripcionProducto(item.category_rewrite,item.id_product,item.link_rewrite,item.paquete,item.sku)">
            {{item.nombreMuestra}}</p>
          <p class="eskiu">SKU: {{item.sku}}</p>
          <!--<p>Cantidad: <input style="width: 30%; background-color: transparent; border: 1px solid #000;" type="number"  [value]="item.quantity"  (change)="validateInput($event,i)"> </p>-->
          <p class="color">Color: {{item.color}}</p>
          <p class="price">${{item.precio*item.quantity | number: '1.2-2'}} <span class="coin">MXN</span></p>
        </div>
      </div>
      <div class="opts-f-1">
        <button class="delete-btn" (click)="onDelete(i)"><i class="fa-solid fa-trash"></i>Eliminar producto(s)</button>
        <div class="cont-cant">
          <div class="cantidad-cont">
            <button class='movercant' style="border-radius: .3em 0em 0em .3em;"
              (click)="handleCantidad(-1, i)">-</button> <!-- (click)="handleCantidad(-1)" -->
            <!--<div class="cantidad">
                {{toggleCantidad}}
            </div>-->
            <input class="cantidad" inputmode="numeric" (change)="validateInput($event,i)" value="{{item.quantity}}">
            <!-- (input)="handleInputChange($event)" -->
            <button class='movercant' style="border-radius: 0em .3em .3em 0em;"
              (click)="handleCantidad(+1, i)">+</button>
          </div>
        </div>
      </div>
      <div class="opts-f-2">
        <button class="btn-wl"
          (click)="presentAlertConfirm(item.id_product, item.title, item.sku, item.quantity, item.image_link, item.color, item.medida, item.precio)"><i
            class="fa-regular fa-heart"></i> Agregar a Wishlist</button>
      </div>
      <!--<div class="delete">
        <p (click)="presentAlertConfirm(
          item.id_product,
        item.title,
        item.sku,
        item.quantity
        ,item.image_link,
        item.color,
        item.medida)"><ion-icon name="heart-outline"></ion-icon> Wishlist</p>
        
      </div>-->
    </div>

    <div class="subtotal">
      <div class="articulos">
        <p class="l-p">{{totalItems}} artículo(s)</p>
        <p class="r-p">${{grandTotal | number: '1.2-2'}} <span class="coin">MXN</span></p>
      </div>
      <!-- <div class="envio">
        <p>Envío</p>
        <p>$191.40</p>
      </div> -->
    </div>

    <div class="total">
      <div class="titl">
        <p *ngIf="totalpuntos >=10000">Tienes Disponible canjear descuento(s) por tus Lidepuntos</p>
        <button class="btn" (click)="canjea5()" *ngIf="totalpuntos >=10000 && canjieLidepuntos == false">5%
          Descuento</button>
        <button class="btn" style="margin-left: 5px;" (click)=" canjea10()"
          *ngIf="totalpuntos >=30000 && canjieLidepuntos == false">10% Descuento</button>
        <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon2()"
          *ngIf="cancelarLidepuntos == true">Cancelar</button>
        <p *ngIf="canjieLidepuntos == false">¿Tienes un código promocional?</p>
      </div>
      <div class="code">
        <input type="text" [(ngModel)]="codigo" name="codigo" *ngIf="canjieLidepuntos == false">
        <button class="btn" *ngIf="cancelar == false && canjieLidepuntos == false"
          (click)=" checamelcupon()">Añadir</button>
        <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon()"
          *ngIf="cancelar == true">Cancelar</button>
      </div>
      <div class="tot" *ngIf="descuento == 'no'">
        <p class="l-p">Total (IVA incluido)</p>
        <p class="r-p">${{grandTotal | number: '1.2-2'}} <span class="coin">MXN</span></p>
      </div>
      <div class="tot" *ngIf="descuento == 'si'">
        <p *ngIf="descuento == 'si'">Total: </p>
        <p *ngIf="descuento == 'si'">${{grandTotal+calculo | number: '1.2-2'}}</p>

      </div>
      <div class="tot" *ngIf="descuento == 'si'">
        <p *ngIf="descuento == 'si'">Descuento: </p>
        <p *ngIf="descuento == 'si'">${{calculo | number: '1.2-2'}}</p>

      </div>
      <div class="tot" *ngIf="descuento == 'si'">
        <p *ngIf="descuento == 'si'">Total a Pagar: </p>
        <p *ngIf="descuento == 'si'">${{grandTotal | number: '1.2-2'}}</p>

      </div>
    </div>

    <div class="bot-cont">
      <button class="btn" *ngIf="usuario" (click)="registraCarrito()">Proceder al pago</button>
      <button class="btn" routerLink="/categorias">Seguir comprando</button>
      <button *ngIf="!usuario" class="btn noinic" [routerLink]="['/login']" routerLinkActive="router-link-active">Para
        comprar, Inicia sesión</button>
      <button class="btn2" (click)="vaciaCarrito()">Vaciar Carrito</button>
      <!-- <div id="paypal-button-container"></div> -->


    </div>

  </div>
  <!--<ng-container *ngIf="items.length>0">
    <div *ngIf="txtBuscador != ''">
      <p class="line-filter">{{numeroResultados}} productos encontrados</p>-->
  <!--contenedor resultados busqueda-->
  <!--<div class="search-container">
        <ion-item
          button
          (click)="openModal(producto.item.id_product)"
          *ngFor="let producto of resultadoBusqueda"
        >
          <ion-thumbnail slot="start">
            <img src="https://new.lideart.com.mx/{{producto.item.image_link}}" />
          </ion-thumbnail>
          <ion-label>
            <h3>{{producto.item.nombre}}</h3>
            <p>
              <span style="font-weight: lighter">
                SKU: {{producto.item.sku[0]}}
              </span>
            </p>
            <b>${{producto.item.precio | number: '1.2-2'}}</b>
          </ion-label>
          <ion-icon name="eye" style="color: #70c2d1" slot="end"></ion-icon>
        </ion-item>
      </div>
    </div>
  
    <div class="modal-cart" *ngFor="let item of items; let i = index">-->
  <!-- <div>
        <img src="{{item.image_link}}"  style="margin-top: 60px; margin-left: 10px;" alt="product" />
      </div> -->
  <!--<div class="info-product" style="padding: 0; margin: 0;white-space: pre-wrap;text-overflow: ellipsis;">
        <ion-card style="background-color: white;">
          <ion-grid>
            <ion-row style="background-color: white">
              <ion-col class="ion-text-center">
                <img src="{{item.image_link}}" width="80px" height="80px" />
              </ion-col>
              <ion-col>
                <p>{{item.title}}</p>
                <p>{{item.sku}}</p>
              </ion-col>
            </ion-row>
          <ion-row style="background-color: white;">
            <ion-col>
              <ion-input type="number" value="{{item.quantity}}" (change)="validateInput($event,i)"></ion-input></ion-col>
            <ion-col>
              <p>${{item.precio*item.quantity | number: '1.2-2'}} mxn</p></ion-col>
          </ion-row>
   
          <ion-row style="background-color: white;">
            <ion-col class="ion-text-center">
              <ion-button color="danger" expand="full" (click)="onDelete(i)" class="delete-product">
                <ion-icon  name="trash"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
    
          </ion-grid>
        </ion-card>

        
        
        
   
      </div>
    </div>

  
    <div class="modal-cart2 ion-padding" >
      <div class="info-payment">-->
  <!-- <span>Subtotal: {{totalItems}} producto(s)</span> -->
  <!-- <span>Descuento:</span> -->
  <!-- <span>Cupones:</span> -->
  <!-- <span>Costo de envío:</span> -->
  <!--<span style="font-weight: bold">Total (IVA inlcuido):</span>
      </div>
      <div class="info-total">-->
  <!-- <span>$23,499.00</span> -->
  <!-- <span>$4,725.60</span> -->
  <!-- <span>$0.00</span> -->
  <!-- <span>Gratis</span> -->
  <!--<span style="font-weight: bold">${{grandTotal | number: '1.2-2'}} mxn</span>
      </div>
    </div>
    <ion-button
      expand="block"
      fill="solid"
      color="light"
      shape="round"
      class="purchase-buttons"
      routerLink="/checkout"
      (click)="registraCarrito()"
    >
      <ion-label style="font-weight: bold; color: black;"> Comprar </ion-label>
    </ion-button>
    <ion-button
    expand="block"
    shape="round"
    fill="solid"
    color="light"
    (click)="vaciaCarrito()"
    class="purchase-buttons"
  >
    <ion-label style="font-weight: bold; color: black;"> Vaciar Carrito </ion-label>
  </ion-button>
    <ion-button
      expand="block"
      fill="solid"
      shape="round"
      color="light"
      routerLink="/categorias"
      class="purchase-buttons"
    >
      <ion-label style="font-weight: bold; color: black;"> Seguir Comprando </ion-label>
    </ion-button>
  </ng-container>
  <ng-container *ngIf="items.length<1">
      <ion-card>
        <ion-card-header class="ion-text-center">
          <img src="/assets/img/Carrito-icono.png">
        </ion-card-header>
        <ion-card-title class="ion-text-center">Tu Carrito esta Vacio</ion-card-title>  
        <ion-card-content>
          <ion-button
            expand="block"
            fill="solid"
            color="secondary"
            shape="round"
            class="purchase-buttons"
            routerLink="/categorias"
          >
            <ion-label style="font-weight: bold; color: black;"> Ir a Comprar </ion-label>
          </ion-button>
        </ion-card-content>
      </ion-card>
  </ng-container>-->

</div>

<div *ngIf="totalItems==0 && isCel">
  <h1 class="ion-text-center">No tienes artículos en el carrito</h1>
  <div style="display:flex;align-items: center;justify-content:center;">
    <ion-button class="ion-text-center" routerLink="/categoria/categorías-por-tipo-de-producto">Ir a
      Comprar</ion-button>
  </div>
</div>

<div class="mensaje-cupon" #mensaje_cupon>
  <span class="mensaje">{{mensaje}}</span>
</div>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>