import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [
    
  ],
  selector: 'app-cursos-gratis',
  templateUrl: './cursos-gratis.component.html',
  styleUrls: ['./cursos-gratis.component.scss'],
})
export class CursosGratisComponent  implements OnInit {

  constructor(
    private titleService : Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Cursos gratis");
  }

}
