import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mesa-regalos-compartir-regalos',
  templateUrl: './mesa-regalos-compartir-regalos.component.html',
  styleUrls: ['./mesa-regalos-compartir-regalos.component.scss'],
})
export class MesaRegalosCompartirRegalosComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
