.hover-area::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 55px; /* Ajusta la altura según sea necesario */
  background: transparent;
  z-index: 50;
}

.desk-header{
  font-family: 'Montserrat', sans-serif;
  width: calc(100%);
  height: auto;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  color: #fff;
  .custom-link {
    color: #ffffff;
    background-color: transparent;
    text-decoration: none;
  
    &:hover {
      color: #000;
    }
  }

  .pagos-cont{
    width: 100%;
    height: 6em;
    background-color: #c4eddc;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .first-cont{
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .txt-cont{
        width: 20em;
        height: 3.5em;
        background-color: #c4eddc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // border-radius: 2em;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        position: relative;
        padding-left: 3.5em;

        .txt:nth-child(1){
          font-size: 1.1em;
          font-weight: 100;
        }

        .txt:nth-child(2){
          font-size: 1.1em;
          font-weight: 900;
        }

        .trokita{
          position: absolute;
          background-color: #c4eddc;
          width: 2em;
          height: 2em;
          // border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.5em;
          left: -.2em;
          // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }
    }

    // .background-gradient {
    //   background: linear-gradient(to bottom, rgba(0, 168, 206, 1), rgba(127, 213, 230, 0.8), rgba(224, 246, 251, 0.5));
    // }


    .banner-formas-de-pago{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1em;
      background-color: #caecdb;
      padding: .5em 2em;
    
      .part-cont{
        width: 33%;
        height: auto;
    
        .txt-p1-2{
          color: #9bbcaa;
          font-weight: 600;
          font-size: 1.5em;
        }
    
        .txt-p2-2{
          color: #9bbcaa;
          font-weight: 100;
          font-size: .9em;
          text-align: center;
        }
    
        .truckin{
          color: #9bbcaa;
          font-size: 2.4em;
        }
    
        .txt-cont-1{
          //width: calc(100% - 3em);
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    
          .span-up{
            color: #9bbcaa;
            font-weight: 600;
            font-size: 2em;
          }
          
          .span-down{
            color: #9bbcaa;
            font-weight: 100;
            font-size: .9em;
    
            .stronger{
              font-weight: 500;
            }
          }
        }
    
        .rin-col{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: .5em;
    
          .pagos-icons{
            width: 2em;
            height: auto;
          }
    
          .txt-pagos{
            color: #9bbcaa;
            font-weight: 100;
            font-size: .8em;
            text-align: center;
          }
        }
      }
    
      .f-col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
      .f-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1em;
      }
    }

    .banner-formas-de-pago{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1em;
      background-color: #caecdb;
      padding: .5em 2em;
    
      .part-cont{
        width: 33%;
        height: auto;
    
        .txt-p1-2{
          color: #9bbcaa;
          font-weight: 600;
          font-size: 1.5em;
        }
    
        .txt-p2-2{
          color: #9bbcaa;
          font-weight: 100;
          font-size: .9em;
          text-align: center;
        }
    
        .truckin{
          color: #9bbcaa;
          font-size: 2.4em;
        }
    
        .txt-cont-1{
          //width: calc(100% - 3em);
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    
          .span-up{
            color: #9bbcaa;
            font-weight: 600;
            font-size: 2em;
          }
          
          .span-down{
            color: #9bbcaa;
            font-weight: 100;
            font-size: .9em;
    
            .stronger{
              font-weight: 500;
            }
          }
        }
    
        .rin-col{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: .5em;
    
          .pagos-icons{
            width: 2em;
            height: auto;
          }
    
          .txt-pagos{
            color: #9bbcaa;
            font-weight: 100;
            font-size: .8em;
            text-align: center;
          }
        }
      }
    
      .f-col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
      .f-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1em;
      }
    }

    .menu-item {
      transition: background-color 0.3s ease;
    }
    .menu-item:hover {
      background-color: #1c3d5a;
    }
    .submenu {
      display: none;
    }
    .menu-item:hover .submenu {
      display: block;
    }

    .second-cont{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .7em;

      .prin{
        background-color: #c4eddc;
        font-size: 1em;
        font-weight: 600;
        padding: 0.2em 0.75em;
        // border-radius: 2em;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }

      .secon{
        font-size: 1em;
        font-weight: 100;
        width: 19em;
        text-align: center;
        text-shadow: 0px 0px 3px #00000024;
      }
    }

    .third-cont{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .4em;

      .prin{
        background-color: #c4eddc;
        font-size: 1em;
        font-weight: 600;
        padding: 0.2em 0.75em;
      
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }

      .contcarmov{
        width: 12em;
        height: 3.5em;
        position: relative;
        overflow: hidden;
     
        .mov{
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 4em;
          position: absolute;
          left: 14em;
          animation: mov 15s linear infinite;

          .soyyo{
            width: 10em;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: .5em;

            .metodo-cont{
              width: 10em;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: .5em;
              //@at-root: 1px solid red;
  
              .grap{
                font-size: 2em;
              }
  
              .txt{
                font-size: .6em;
                text-align: center;
                font-weight: 100;
                text-shadow: 0px 0px 3px #00000082;
              }
            }
          }

          }
    

     
        @keyframes mov{
            0%{
                left: 12em;
            }
     
            100%{
                left: -55em;
            }
        }
      }
    }
  }

  .up{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1em 1em;
    // border-bottom: 1px solid #1ca3c9;
    // border-bottom-style: dashed;
    background-color: #fff;
    position: relative;

    .loguin{
      width: 9em;
      height: auto;
    }




    .centre-second-line{
      // width: 55%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;

      .opt-desp{
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
         gap: .4em;
         cursor: pointer;
         position: relative;

         .log{
            width: 2.5em;
            height: auto;
         }

         .txtin{
            color: #000000;
            font-size: 1.02em;
            transition: .5s all ease-in-out;
         }

         .nuevos-slid{
            position: absolute;
            width: 86em;
            z-index: 20;
            background-color: transparent;
            top: 1.5em;
            display: none;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            left: -22.5em;
            padding-top: 2.1em;
            height: auto;

            .fix-back{
               background-color: #fff;
               width: 100%;
               height: auto;
               box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
            }
         }

         .out-slid{
            position: absolute;
            width: 86em;
            z-index: 20;
            background-color: transparent;
            top: 1.5em;
            display: none;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            left: -31em;
            padding-top: 2.1em;
            height: auto;

            .fix-back{
               background-color: #fff;
               width: 100%;
               height: auto;
               box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
            }
         }

         .ln-slid{
            position: absolute;
            width: 86em;
            z-index: 20;
            background-color: transparent;
            top: 1.5em;
            display: none;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            left: -38em;
            padding-top: 2.1em;
            height: auto;

            .fix-back{
               background-color: #fff;
               width: 100%;
               height: auto;
               box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
            }
         }

         .red-slid{
            position: absolute;
            width: 45em;
            z-index: 20;
            background-color: transparent;
            top: 1.5em;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            left: -25em;
            padding-top: 2.1em;
            height: auto;

            .fix-back{
               background-color: #fff;
               width: 100%;
               height: auto;
               box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
            }
         }

         .divisiones{
            position: absolute;
            width: 75em;
            z-index: 20;
            background-color: transparent;
            top: 4em;
            display: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            // left: 0em;
            background-color: #000;
            // padding-top: 2.1em;

            .linkiud{
               width: 33.33%;
               height: 12em;
               background-position: center;
               background-size: cover;
               position: relative;



            }

         }
      }

      .opt-desp:hover{
         .txtin{
            font-size: 1.05em;
         }

         .divisiones{
            display: flex;
         }

         .nuevos-slid{
            display: flex;
         }

         .out-slid{
            display: flex;
         }

         .ln-slid{
            display: flex;
         }

      }
   }


    .nav-opcs-cont{
      width: 50%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      row-gap: .4em;


      
      .opi{
        margin-right: 1em;
        text-decoration: none;
        font-weight: 100;
        font-size: .9em;
        color: #777;
        //transition: .2s all ease-in-out;
        position: relative;
        z-index: 1;
        cursor: pointer;
      }

      .opi::before{
        content: '';
        width: 110%;
        height: 2em;
        background-color: #00a8cc;
        position: absolute;
        top: -1.2em; // .5em
        visibility: hidden;
        opacity: 0;
        left: calc(50% - 55%);
        z-index: -1;
        transition: .1s all ease-in-out;
        border-radius: .1em;
      }

      .opi:hover{
        //font-size: 1em;
        color: #fff;
      }

      .opi:hover::before{
        top: -.5em; // .5em
        visibility: visible;
        opacity: 1;
      }

      .opi:last-child{
        margin-right: 0em;
      }

      .tagin-mesrosa{
        background-color: #fdb6ff;
        border-radius: 2em;
        padding: 0.2em 1em;
        color: #fff;
      }

      .tagin-mesrosa::before{
        content: '';
        display: none;
      }

      .tagin-halloween{
        background-color: #e16216;
        border-radius: 2em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.2em 1em;
        gap: 0.5em;
        color: #fff;

        .grap{
          font-size: 1em;
        }
      }

      .tagin-halloween::before{
        content: '';
        display: none;
      }
    }

    .nav-cont{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: .3em;
      position: relative;
      padding-left: 9em;
      display: none;

      .tagin-conts{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: .5em;

        .tagin-mesrosa{
          background-color: #fdb6ff;
          border-radius: 2em;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0.2em 1em;
          gap: 0.5em;
          cursor: pointer;
  
          .grap{
            font-size: 1em;
          }
  
          .tag-txt{
            font-size: .9em;
          }
        }
  
        .tagin-halloween{
          background-color: #e16216;
          border-radius: 2em;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0.2em 1em;
          gap: 0.5em;
          cursor: pointer;
  
          .grap{
            font-size: 1em;
          }
  
          .tag-txt{
            font-size: .9em;
          }
        }
      }

      .btn-nav{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 18em;
        height: 2.5em;
        overflow: hidden;
        border-radius: 2em;
        //border: 1px solid red;
        box-shadow: 0px 0px 15px 1px #00a7cc2d;
      
        .cros1{
          background-color: #00a8cc;
          width: 2em;
          height: 19em;
          transform: rotate(84deg);
          animation: rot1 3s linear infinite;
        }

        @keyframes rot1{
          from{
            transform: rotate(84deg);
          }
          to{
            transform: rotate(444deg);
          }
        }

        .blankin-cont{
          background-color: #fff;
          width: 17.8em;
          height: 2.4em;
          border-radius: 4em;
          position: absolute;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          gap: 2em;
          transition: .7s all ease-in-out;

          .blankin-txt{
            color: #00a8cc;
            transition: .7s all ease-in-out;
          }

          .blankin-icon{
            color: #00a8cc;
            transition: .7s all ease-in-out;
          }
        }

        .blankin-cont:hover{
          background-color: #00a8cc;

          .blankin-txt{
            color: #fff;
          }

          .blankin-icon{
            color: #fff;
          }
        }
      }

      .btn-nav:hover{
        .cros1{
          display: none;
        }
      }
    }

    .desp-cont{
      position: absolute;
      top: -25em;
      width: 80%;
      height: 23.5em;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 1em;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      padding: 1em;
      opacity: 0;
      visibility: hidden;
      transition: .5s all ease-in-out;

      .fix{
        width: 100%;
        height: 100%;
        gap: 0.5em;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: .5em;

        .opc{
          width: 100%;
          height: 2.5em;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: #fff;
          color: #00a8cc;
          border-radius: 2em;
          font-size: 0.8em;
          padding: 1.3em 0em;
          padding-left: 1em;
          border: 1px solid #00a8cc;
          cursor: pointer;
          transition: .7s all ease-in-out;
        }

        .opc:hover{
          color: #fff;
          background-color: #00a8cc;
        }
      }

      .fix::-webkit-scrollbar {
        width: .3em;
        background-color: transparent;
        height: .7em;
      }
    
      .fix::-webkit-scrollbar-thumb {
        background-color: #00a3c7;
        //border-radius: 1vh;
        width: 0.25em;
      }
    }

    .third-cont{
      width: 33%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1em;

      .busc-cont{
        width: 18em;
        height: 2.5em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //padding-left: 1em;
        border-radius: 3em;
        border: 1px solid #1ca3c9;
        background-color: #1ca3c9;
        position: relative;
        box-shadow: 
          rgba(0, 163, 199, 0.3) 5px 5px, 
          rgba(0, 163, 199, 0.2) 10px 10px, 
          rgba(0, 163, 199, 0.1) 15px 15px;

        .grapi{
          font-size: .9em;
          position: absolute;
          left: 1em;
        }

        .results-cont-google{
          position: absolute;
          top: 3em;
          right: 0;
          min-width: 26em;
          height: 45vh;
          // padding: 1em;
          padding-left: 1em;
          padding-right: 1em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1em;
          background-color: #fff;
          z-index: 1;
          border-radius: 1em;
          box-shadow: 0px 0px 10px 1px #dbdbdb;

          .clearing{
            border: 1px solid #dd0000;
            color: #dd0000;
            background-color: transparent;
            padding: .5em 2em;
            border-radius: 2em;
          }

          .links-cont-results{
            width: 100%;
            height: 85%;
            //border: 1px solid red;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1em;
            padding: 0.5em 1em;

            .prod-cont{
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              text-decoration: none ;
              box-shadow: 0px 0px 10px 1px #e3e3e3;
              border-radius: .75em;
              padding: .5em;
              gap: .5em;

              .pp{
                width: 60%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: .5em;
                color: #fff;
                border-radius: 3em;
                padding: .3em 0em;
                transition: .4s all ease-in-out;

                i{
                    font-size: .9em;
                }

                .txt-pp{
                    font-weight: 400;
                    font-size: .9em;
                }
              }

              .pp-cat{
                background-color: #fb7388;
                color: #fff;
              }

              .pp-prod{
                background-color: #60b9e6;
                color: #fff;
              }

              .pp-paq{
                background-color: #b098c7;
                color: #fff;
              }

              .info-prod{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;

                .img-cont-prod{
                  width: 6em;
                  height: 6em;
                  border-radius: 50%;
                  overflow: hidden;
                  border: 1px solid #e7e7e7;

                  .img-prod{
                    width: 100%;
                    height: 100%;
                  }
                }

                .txt-cont{
                  width: calc(100% - 7em);
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  // gap: 1em;

                  .title-prod{
                    color: #000;
                    line-height: 1em;
                    font-weight: 300;
                    font-size: .9em;                
                  }

                  .line-tb{
                    // padding: 1.5em 0.5em;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    margin: .5em;

                    .colors-container {
                      display: flex;
                    }
              
                    .r-color{
                      // position: absolute;
                      margin: 0.5px;
                      width: 20px;
                      height: 20px;
                      border: 1px solid #cfcfcf;
                      border-radius: 50%;
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: center;
                      cursor: default;
                      // right: 1em;
                      // top: 0.5em;
                    }
              
                    .r-color.hidden {
                      display: none;
                    }
              
                    .r-color.more-colors {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 20px;
                      height: 20px;
                      border: 1px solid #cfcfcf;
                      border-radius: 50%;
                      background-color: #f0f0f0;
                      margin: 0.1em;
                      font-weight: bold;
                      font-size: 14px;
    
                        &:hover {
                            background-color: #ddd;
                        }
                        ::after {
                            content: "↓";
                        }
                        .prod-cont .r-color.more-colors.up-arrow::after {
                            content: "↑"; /* El símbolo de la flecha hacia arriba */
                          }
                    }
                  }

                  .price-cont-prod{
                    width: 100%;

                    .price-prod{
                        color: #000;
                        font-weight: 300;
                        font-size: 1.1em;
                        transition: .4s all ease-in-out;

                        .curr{
                            font-weight: 500;
                            font-size: .7em;
                        }
                    }
                  }
                }
              }
            }

            .prod-cont:hover{

            }
          }

          .btns-cont{
            width: 100%;
            height: 15%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1em;
            cursor: pointer;

            .prev-forw-btns{
              background-color: #00a0c6;
              color: #fff;
              width: 7em;
              height: 2.5em;
              border-radius: 2em;
              transition: .4s all ease-in-out;
              border: 1px solid #00a0c6;
            }

            .prev-forw-btns:hover{
              background-color: #ffffff;
              color: #00a0c6;
            }
          }

          .links-cont-results::-webkit-scrollbar {
            width: .4em;
            background-color: rgb(255, 255, 255);
            height: .7em;
          }
            
          .links-cont-results::-webkit-scrollbar-thumb {
            background-color: #0096b2;
            //border-radius: 1vh;
            width: 0.25em;
          }
        }

        .form-busc-cont{
          width: 100%;
          height: 100%;
          border-radius: 3em;
          position: relative;
          //border: 1px solid red;

          .btn-busc{
            position: absolute;
            font-size: .8em;
            font-weight: 300;
            padding: .4em 1em;
            border-radius: 1em;
            background-color: #52d3ff;
            color: #fff;
            right: .5em;
            top: .5em;
            z-index: 1;
          }

          .buscainp{
            width: 100%;
            height: 100%;
            color: #fff;
            border-radius: 3em;
            background-color: transparent;
            padding-left: 3em;
            padding-right: 5em;
            border: none;
          }

          .buscainp:focus{
            outline: none;
          }

          .buscainp::placeholder{
            color: #e6e6e6;
            font-weight: 300;
            font-size: .8em;
          }
        }
      }

      .oth-opts-cont{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1em;
        padding-top: 1.4em;

        .opt-cont{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: .1em;
          position: relative;
          cursor: pointer;

          .grapi-up{
            color: #97989b;
            font-size: 1.6em;
            transition: .1s all ease-in-out;
          }

          .grapi-down{
            color: #97989b;
            transition: .1s all ease-in-out;
            font-size: .9em;
          }

          .opts-acount-cont{
            position: absolute;
            right: 0;
            top: 2.3em;
            min-width: 12em;
            height: auto;
            display: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            z-index: 11;
            background-color: #fff;
            border-radius: 0.6em;
            overflow: hidden;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

            .opas{
              width: 100%;
              height: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffff;
              transition: .5s all ease-in-out;
              padding: .5em 0em;
              text-decoration: none;
              gap: 1em;

              span{
                color: #666;
                font-size: .8em;
              }
            }

            .opas:hover{
              background-color: rgb(242, 242, 242);

              span{
                color: #00a3c7;
              }
            }
          }
        }

        .opt-cont:hover{
          .grapi-up{
            color: #000;
          }

          .grapi-down{
            color: #000;
          }

          .opts-acount-cont{
            display: flex;
          } 
        }
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    z-index: 1000;
  }
  
  .menu-list {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
  }
  
  .menu-item {
    position: relative;
    display: inline-block;
  }
  
  .menu-item > a {
    display: block;
    padding: 20px 30px;
    color: white;
    text-decoration: none;
    background-color: #444;
    transition: background-color 0.3s ease;
  }
  
  .menu-item > a:hover {
    background-color: #555;
  }
  
  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #666;
    list-style-type: none;
    padding: 0;
  }
  
  .submenu li a {
    padding: 10px;
    color: white;
    text-decoration: none;
    background-color: #777;
    transition: background-color 0.3s ease;
  }

  
  
  .submenu li a:hover {
    background-color: #888;
  }
  
  .menu-item:hover > .submenu {
    display: block;
  }
  
  .submenu li {
    display: block;
  }

  .down{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: .9em 1em;
    background-color: #fff;

    .horario{
      font-size: .9em;
      color: #666;
      display: flex;
      flex-direction: column;
    }

    .cont-rs{
      width: auto;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: .5em;

      .redin{
        width: 2.5em;
        height: 2.5em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        text-decoration: none;
        transition: .7s all ease-in-out;

        i{
          font-size: 1.1em;
          transition: .7s all ease-in-out;
        }
      }

      .redin:nth-child(1){
        box-shadow: rgb(0 165 200 / 56%) 0px 0px 12px;

        i{
          color: rgb(130 192 205 / 100%);
        }
      }

      .redin:nth-child(1):hover{
        background-color: rgb(0 165 200 / 100%);

        i{
          color: #fff;
        }
      }

      .redin:nth-child(2){
        box-shadow: rgb(101 191 205 / 56%) 0px 0px 12px;

        i{
          color: rgb(101 191 205 / 100%);
        }
      }

      .redin:nth-child(2):hover{
        background-color: rgb(101 191 205 / 100%);

        i{
          color: #fff;
        }
      }

      .redin:nth-child(3){
        box-shadow: rgb(140 205 205 / 56%) 0px 0px 12px;

        i{
          color: rgb(140 205 205 / 100%);
        }
      }

      .redin:nth-child(3):hover{
        background-color: rgb(140 205 205 / 100%);

        i{
          color: #fff;
        }
      }

      .redin:nth-child(4){
        box-shadow: rgb(115 183 224 / 56%) 0px 0px 12px;

        i{
          color: rgb(115 183 224 / 100%);
        }
      }

      .redin:nth-child(4):hover{
        background-color: rgb(115 183 224 / 100%);

        i{
          color: #fff;
        }
      }

      .redin:nth-child(5){
        box-shadow: rgb(243 172 177 / 56%) 0px 0px 12px;

        i{
          color: rgb(243 172 177 / 100%);
        }
      }

      .redin:nth-child(5):hover{
        background-color: rgb(243 172 177 / 100%);

        i{
          color: #fff;
        }
      }

      .redin:nth-child(6){
        box-shadow: rgb(243 165 141 / 56%) 0px 0px 12px;

        i{
          color: rgb(243 165 141 / 100%);
        }
      }

      .redin:nth-child(6):hover{
        background-color: rgb(243 165 141 / 100%);

        i{
          color: #fff;
        }
      }

      .redin:nth-child(7){
        box-shadow: rgb(182 206 220 / 56%) 0px 0px 12px;

        i{
          color: rgb(182 206 220 / 100%);
        }
      }

      .redin:nth-child(7):hover{
        background-color: rgb(182 206 220 / 100%);

        i{
          color: #fff;
        }
      }
    }

    .infocont{
      width: 20%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: .2em;

      .opc{
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: .5s all ease-in-out;
        gap: 1em;
        width: 100%;

        span{
          padding: 0em 0em;
          color: #777;
          font-size: .9em;
        }
      }
    }

    .info-cont{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16em;
      height: 2.5em;
      box-shadow: rgb(0 163 199 / 14%) 0px 1px 12px;
      //border-radius: 2em;
      //border-bottom: 1px solid rgb(0 163 199 / 20%);
      transition: .5s all ease-in-out;
      display: none;

      span{
        color: #198aa3;
        font-size: .9em;
        font-weight: 100;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0em 2em;
        transition: .5s all ease-in-out;
      }

      .opcs-cont{
        position: absolute;
        top: 2.5em;
        width: 100%;
        height: auto;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 1em;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .opc{
          background-color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
          transition: .5s all ease-in-out;
          width: 100%;
          height: auto;
          padding: 0.7em 1em;
          gap: 1em;
          cursor: pointer;
          border-bottom: 1px solid #00a3c726;

          span{
            padding: 0em 0em;
            color: #198aa3;
          }
        }

        .opc:last-child{
          border-bottom: none;
        }

        .opc:hover{
          background-color: rgb(240, 240, 240);
        }
      }
    }

    .info-cont:hover{
      background-color: #1ca3c9;

      span{
        color: #fff;
      }

      .opcs-cont{
        display: flex;
      }
    }
  }
}

#scroll-text {
/* animation properties */
font-size: 20px;
font-weight: bold;
-moz-transform: translateX(10%);
-webkit-transform: translateX(10%);
transform: translateX(10%);

-moz-animation: my-animation 5s linear infinite;
-webkit-animation: my-animation 5s linear infinite;
animation: my-animation 5s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(10%); }
to { -moz-transform: translateX(-5%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(10%); }
to { -webkit-transform: translateX(-5%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(10%);
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
}
to {
  -moz-transform: translateX(-5%);
  -webkit-transform: translateX(-5%);
  transform: translateX(-5%);
}
}

.toggle-desk{
width: 100%;
height: 100%;

#axbody{
  max-height: none!important;
  min-height: 100% !important;
  list-style: none;
  overflow-y: scroll!important;
  width: 100%!important;
  height: 20em;
  padding-inline-start: 0px;
  padding: 0em 1em;

  .ui-corner-all{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid #00d7ff;
    border-bottom-style: dashed;
    padding-bottom: 0.75em;
    margin-bottom: 1em;
  }

  /*.ui-corner-all:last-child{
    border-bottom: none;
    margin-bottom: none;
  }*/

  .search_lewa{
    display: block;
    width: 5em;
    height: 5em;
    border: 1px solid #00d3ff;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
    }
  }

  .search_prawa{
    width: calc(100% - 6em);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.36em;

    .search_marka{
      color: #fff;
      font-weight: 100;
      font-size: .7em;
    }

    .search_nazwa{
      font-size: .85em;
      color: #fff;
      font-weight: 400;
    }

    .search_cena{
      font-size: .9em;
      color: #fff;
      font-weight: 100;
    }
  }
}

ul::-webkit-scrollbar {
  width: .35em;
  background-color: transparent;
  height: .7em;
}
  
ul::-webkit-scrollbar-thumb {
  background-color: #ffffffb7;
  //border-radius: 1vh;
  width: 0.25em;
}

#search_plugin{
  width: 100%;
  height: 100%;

  form{
    width: 100%;
    height: 100%;
    position: relative;

    .charg{
      position: absolute;
      z-index: 10;
      background-color: #00a5cb;
      width: 18em;
      height: 2.5em;
      left: -2em;
      top: -.7em;
      border-radius: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 100;
      font-size: 1em;
    }

    .buscainp{
      width: 100%;
      height: 100%;
      padding: 0em 1em;
      border-radius: 0em 3em 3em 0em;
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: .9em;
    }

    .buscainp:focus{
      outline: none;
    }

    .buscainp::placeholder{
      color: rgba(255, 255, 255, 0.671);
      font-size: .8em;
    }
  }
}

.conten-abs{
  position: absolute;
  min-width: 24em;
  height: auto;
  top: 2.5em;
  right: -4em;
  z-index: 10;
  background-color: #00a3c7;
  padding: 1em;
  border-radius: 1em;

  .cuerpoPistas{

    .txt-decir{
      margin: 0;
      margin-bottom: 0.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4em;
      font-weight: 100;
      cursor: pointer;
    }

    .ud1{
      font-size: .6em;
      margin-left: 1em;
      color: #fff;
    }

    .lasPistas{
      width: 100%;
      max-height: 8em;
      background-color: #0096b5;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: .5em;
      padding: 1em;
      overflow-y: scroll;
      border-radius: 2em 0em 0em 2em;
      transition: .2s all ease-in-out;
    }

    .lasPistas::-webkit-scrollbar {
      width: .35em;
      background-color: transparent;
      height: .7em;
    }
      
    .lasPistas::-webkit-scrollbar-thumb {
      background-color: #ffffffb7;
      //border-radius: 1vh;
      width: 0.25em;
    }

    .lasPistas div{
      width: fit-content;
      display: inline-block;
      padding: 5px 10px;
      color: #00d9ff;
      border: 1px dashed #00d3ff;
      margin: 3px;
      border-radius: 3px;
      cursor: pointer;
      transition: .6s all ease-in-out;
    }

    .lasPistas div:hover{
      color: #ffffff;
      border: 1px dashed #ffffff;
    }
  }

  .dowi{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;

    .lefti{
      width: 0%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.3em;

      .card{
        width: 100%;
        height: 3em;
        background-color: #0096b2;
        padding: 0.1em;
        border-radius: 0.5em;
        transition: .2s all ease-in-out;
        overflow: hidden;

        .lista{
          overflow-y: scroll;
          width: 100%;
          height: 9em;
        }

        .lista::-webkit-scrollbar {
          width: .35em;
          background-color: transparent;
          height: .7em;
        }
          
        .lista::-webkit-scrollbar-thumb {
          background-color: #ffffffb7;
          //border-radius: 1vh;
          width: 0.25em;
        }

        .lista div{
          position: relative;
          border-bottom: 1px solid #ffffff!important;
          width: 100%;
          height: 2.5em;
          display: flex!important;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          font-size: .9em;
          font-weight: 100;

          input[type='checkbox']{
            cursor: pointer;
            width: 1.2em;
            height: 1.2em;
          }
        }

        .taitl-filt{
          text-align: center;
          cursor: pointer;
          color: #fff;
          font-size: .9em;
          font-weight: 100;

          i{
            font-size: .7em;
            color: #fff;
          }
        }

        .category-tree{
          overflow-y: unset!important;
          padding: 0em 0em;
          margin-block-start: 0em;
          margin-block-end: 0em;
          height: auto;

          .less{
            height: auto!important;

            div{
              border-bottom: none;
            }

            .w-100{
              overflow-y: unset!important;
            }
          }
        }
      }
    }

    .rigti{
      width: 100%;
      height: auto;
      height: 25em;
      background-color: #0096b3;
      border-radius: 2em;

      .result-taitl{
        color: #fff;
        font-size: 1em;
        font-weight: 100;
      }
    }
  }
}
}

@media(max-width: 992px){

}

.loader {
display: flex;
width: 100%;
height: 100%;
background-position: center;
justify-content: center;
align-items: center;
background-size: cover;
position: fixed;
top: calc(0%);
left: calc(0%);
background-color: white;
z-index: 9999999;



img{
  width: 200px;
  height: auto;

}

}