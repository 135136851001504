@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import 'swiper/scss';
@import 'swiper/scss/navigation';

img.ng-lazyloaded {
    animation: fadein 1.5s;
}

ion-button {
    --box-shadow: transparent !important;
}

button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
}

ion-searchbar {
    --icon-color: #1ca3c9;
    width: 95%;
    margin-left: 10px;
}

.search-container {
    width: 90vw;
    right: 5vw;
    height: auto;
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;
}

.line-filter {
    text-align: center;
    line-height: 20px;
    background: #70c2d1;
    padding: 0;
    margin: 0;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0;
}

.header-translucent-ios ion-toolbar {
    --opacity: 1;
}

ion-img::part(image) {
    width: 100px;
    height: 30px;
}

.redes img {
    height: 30px;
    width: 30px;
}

.redes {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.marcas img {
    height: 150px;
    width: 150px;
    text-align: center;
    margin: 0px;
}

.marcas {
    text-align: center;
}

.bottomBanner img {
    height: auto;
    width: 100%;
}

.imagensita {
    height: auto!important;
    width: auto!important;
}

.catT {
    width: 100%;
    height: auto;
    background-color: rgb(11, 148, 240);
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-text {
        color: #fff;
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.marcasT {
    width: 100%;
    height: auto;
    background-color: rgb(11, 148, 240);
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-text {
        color: #fff;
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.foot {
    width: 100%;
    height: auto;
    background-color: none;
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #000;

    ion-text {
        color: rgb(0, 0, 0);
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

/* Test Infinite */
@mixin white-gradient {
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 100s; //40s

// Animation
@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * 36))}
}

// Styling
.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 200px;
    margin: auto;
    overflow:hidden;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
        @include white-gradient;
        content: "";
        height: 200px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(250px * 72);
    }

    .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 250px;
        padding: 1rem;

        img {
            width: 80%;
            height: auto;
        }
    }
}

.texto-azul {
    text-align: center;
    color:  #1ca3c9;
    font-size: 24px;
}

.texto-azul2 {
    text-align: center;
    color:  #43d0f7;
    font-size: 21px;
}

.regular-text {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: lighter;
    // font-size: 16px
    color: #000;
}

.center-rows {
    text-align: center;
    align-items: center;
    align-content: center;
    color: #000;
}

.boton {
    color: pink;
    text-align: center;
    align-items: center;
    align-content: center;
}

.bottom-Border {
    border-bottom: 1px solid #333;
    left: 0;
    right: 0;
    width: 350px;
    margin: 0 auto;
    top: 40px;
    box-shadow: 1px 6px 9px #888888;
}

.corners1 {
    border-radius: 12px;
    background: #ffb7be;
    color: #fff;
    margin: auto;
    width: 80%;
    padding: 30px;
}

.corners2 {
    border-radius: 12px;
    background: #ffb193;
    color: #fff;
    margin: auto;
    width: 80%;
    padding: 30px;
}

.lidepuntosDesk {
    width: 100%;
    height: auto;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;

    .conten-cont {
        width: 50em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3em 0em;
    }
}

@media(min-width: 992px) {
    ion-header {
        display: none;
    }

    ion-content {
        display: none;
    }

    .lidepuntosDesk {
        display: flex;
    }

    .texto-azul-desk {
        text-align: center;
        color: #1ca3c9;
        font-size: 1.7em;
    }
}

@media screen and (max-width:992px){
    .main-title {
        font-size: 1.2em !important;
    }
    .regular-text {
        font-size: 1em !important;
        padding: 0em 1em !important;
    }
    .texto-azul-desk {
        font-size: 1.5em !important;
    }
    .sub-title {
        font-size: 1em !important;
        padding: 0em 1em !important;
    }
    .banner-container-d {
        // gap: 0.5em !important;
        padding: 0px 0.5em !important;
        // display: block !important;
    }
    .banner {
        width: 83% !important;
    }
    .regular-text-d {
        font-size: 0.8em !important;
        padding: 0em 1em !important;
    }
    .regular-text-d2 {
      font-size: 0.5em !important;
      padding: 0em 1em !important;
      margin-left: 0px !important;
  }
}

@media screen and (max-width:630px){
    .banner-container {
        display: none;
    }
    .banner-container-movil {
        display: block !important;
    }
}

.lidepuntosDesk::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
}

.lidepuntosDesk::-webkit-scrollbar-thumb {
    background-color: #00a2c779;
    //border-radius: 1vh;
    width: 0.25em;
}

.inp-manage{
  width: 100%;
  height: 2.5em;
  border: none;
}

.inp-manage:focus{
  outline: none;
}

ion-content{
    background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
  }

    ion-button {
      --background: #0089c5;     
      --color:white;
      
    
  }
  
  ion-card{
      background: rgba(255, 255, 255, 0.164); //     #fdfdfd            #14A8CD*
      backdrop-filter: blur(2px);
      /*background: rgb(28,138,242);
      background: linear-gradient(0deg, rgba(28,138,242,1) 47%, rgba(45,166,253,1) 47%); //     #fdfdfd            #14A8CD*/
      display: flex;
      justify-content:space-between;
     
      flex-direction: column;
      //box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      //box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      width: 100%;
      height: 100%;
      border-radius: .25rem;
      //padding: 1rem;
  
      /* From https://css.glass */
      background: rgba(255, 255, 255, 0.150);
      border-radius: 10px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      border: .5px solid rgba(0, 0, 0, 0.082);
  
      .contImg{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
  
        img{
          width: 80%;
          height: auto;
        }
      }
  
      .text{
        width: 100%;
        height: 40%;
        color: black;
        font-size: 15px;
        font-weight: bold;
        ion-text{
          width: 100%;
          font-size: 20vw;
          text-align: center;
          color: rgb(0, 0, 0);
          // text-shadow: 4px 3px 4px rgb(65, 64, 64); 
        }
  
      }
  
  
  
    }

    .tituloDescripcion{
      color: rgb(27,172,209);
      font-family: 'RobotoCondensed-Light';
      font-size: 18px;
      font-style: italic;
      text-align: center;
    }

    .titulillo{
      font-size: 20px;
      margin-bottom: 0em;
      color: black;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      font-size: 20px;
      letter-spacing: 2px;
    }
  
    .textillo{
        font-size: 18px;
        margin-bottom: 10px;
        color: black;
        font-weight: normal;
        //text-transform: uppercase;
        text-align: center;
        font-family: 'RobotoCondensed-Light';
    }
  
    .form-group{
  
   margin-left: 100px;
    }

    .color-cont-prop{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: .5em;
    }

    .color-cont-prop-list{
      width: 100%;
      height: auto;
      margin-top: .5em;

      .conten-names-opts{
        width: 100%;
        height: 21em;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1em 0em;
        gap: 1em;

        .btn-list-opt{
          width: 100%;
          padding: 1em 0em;
          color: #1ca3c9;
          background-color: transparent;
          border-top: 1px solid #0abcee;
          border-bottom: 1px solid #0abcee;
          box-shadow: 0px 0px 5px 0px #0abcee;
        }
      }

      .conten-names-opts::-webkit-scrollbar {
        width: .6em;
        background-color: transparent;
        height: .7em;
      }
        
      .conten-names-opts::-webkit-scrollbar-thumb {
        background-color: #00a2c779;
        //border-radius: 1vh;
        width: 0.25em;
      }
    }
  
    .selector-color {
      display: inline-block;
      margin: 0px 2px;
      width: 40px;
      height: 40px;
      position: relative;
      border-radius: 3px;
      cursor: pointer;
      //overflow: hidden;
      transform: rotate(0deg);
      transition: .5s;
      //border-radius: 50%;
      padding: .5em;
      display: flex; 
      justify-content: center;
      align-items: center;
    }

    .line-tb{
      padding: 1.5em 0.5em;

      .r-color{
        position: absolute;
        width: 3.5em;
        height: 3.5em;
        border: 1px solid #cfcfcf;
        border-radius: 50%;
        right: 1em;
        top: 0.5em;
      }
    }

    .preview{
      position: absolute;
      z-index: 5;
      background-color: #fff;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.7em;
      padding: 1em;
      border-radius: 1em;
      bottom: 3em;
      right: calc(50% - 2em);
      box-shadow: rgb(0 230 241 / 28%) 0px 5px 29px 0px;

      .diva-color-p{
        background-color: rgb(255, 255, 255);
        width: 6em;
        height: 6em;
        border: 1px solid #ddd;
        border-radius: 50%;
      }

      .name-color-p{
        font-size: .9em;
        color: #000;
        font-weight: 500;
      }
    }

    .selector-color:hover{
      .preview{
        display: flex;
      }
    }

    .texto-descripcion {
      color: white;
      font-size: 1.3em;
    }
    
    .selected.selector-color input{
      border-radius: 50%;
      animation: lux 2s ease-in-out infinite;
      -webkit-animation: lux 2s ease-in-out infinite;
    }

    @keyframes lux{
      0%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
      25%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      50%{
        border: 2px solid rgb(14, 240, 221);
        box-shadow: 0px 0px 10px 1px rgb(14, 240, 221);
      }
      75%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      100%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
    }

    @-webkit-keyframes lux {
      0%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
      25%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      50%{
        border: 2px solid rgb(14, 240, 221);
        box-shadow: 0px 0px 10px 1px rgb(14, 240, 221);
      }
      75%{
        border: 2px solid rgb(14, 206, 240);
        box-shadow: 0px 0px 10px 1px rgb(14, 206, 240);
      }
      100%{
        border: 2px solid rgb(16, 169, 196);
        box-shadow: 0px 0px 10px 1px rgb(16, 169, 196);
      }
    }
    
    .selector-color input {
      width: 30px;
      background-size: cover;
      position: absolute;
      height: 30px;
      top: 5px;
      left: 5px;
      box-shadow: inset rgb(0 0 0 / 20%) 0px 0px 12px;
      transition: 1s;
      border-radius: .2em;
      border: none;
      animation: none;
    }

    @media(max-width: 992px){
      .selector-color:hover{
        .preview{
          display: none;
        }
      }
    }
    
    .selected.selector-color input {
      width: 40px;
      height: 40px;
      top: 0px;
      left: 0px;
    }
    
    .contiene-descripcion > table > tbody > tr > td > div > img {
      max-width: 100%;
      height: auto;
    }

    .swiper {
      width: 100%;
      height: 100%;
    }
    
    swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    swiper-slide video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    
    swiper-slide {
      background-size: cover;
      background-position: center;
    }
    
    .mySwiper2 {
      height: 80%;
      width: 45%;
      border: 5px;
    }

    .swiper-container-wrapper{
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .swiper-container-gallery{
      width: 80%;
      margin: 0 auto;
    }

    swiper-slide{
      text-align: center;
    }
    
    .swiper-image{
      max-width: 5em;
      max-height: 5em;
    }

    .swiper-pagination{
      margin-top: 10px;
    }

    .swiper-button-next,
    .swiper-button-prev{
      color:#0089c5
    }

    .swiper-pagination-bullet{
      background: #1c81a0;
      width: 8px;
      height: 8px;
      margin: 0 3px;      
    }

    /*.swiper-container{
      padding-top: 0em;
      width: 25em;
      height: auto;
     
      margin: 0 auto;
      display: flex;
      justify-content: center;
      //align-items: center;
    }*/

    .mySwiper3{
         
      cursor: default;
      inline-size: 100%;
      block-size: auto;
      inset-inline-start: 0;
      inset-block-start: 0px ;
     
    }
    
    .mySwiper {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    
    .mySwiper swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    
    .mySwiper .swiper-slide-thumb-active {
      opacity: 1;
    }

    .precio{
      color: rgb(29,171,209);
      font-family: 'RobotoCondensed-Light';
      font-size: 35px;
      letter-spacing: normal;
      line-height: 38.5px;
      text-size-adjust: 100%;
      text-transform: uppercase;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    swiper-slide video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
   

    .loader {
      display: flex;
      width: 100%;
      height: 100%;
      background-position: center;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: fixed;
      top: calc(0%);
      left: calc(0%);
      background-color: rgb(255, 255, 255);
      opacity: 0.9;
    
      img{
        width: 200px;
        height: auto;
   
      }
  
    }

    /* Styles for the selected image container */
.selected-image-container {
  margin-bottom: 20px; /* Adjust the spacing as needed */
}

.selected-image-swiper {
  width: 100%;
  max-height: 400px; /* Adjust the height of the selected image */
  margin: 0 auto;
}

/* Styles for the gallery container */
.gallery-container {
  margin-top: 20px; /* Adjust the spacing as needed */
}

.gallery-swiper {
  width: 100%;
  max-height: 100px; /* Adjust the height of the gallery */
  margin: 0 auto;
}

.gallery-image {
  max-width: 100px; /* Adjust the size of gallery images */
  max-height: 80px; /* Adjust the size of gallery images */
}

@media screen and (max-width:992px){
  

  .columnas{
    height: min-content;
  }
  .swiper-container{
    height: auto;
  }

  .columnas ion-col:nth-of-type(2){
    order: 1;
  .columnas ion-col:nth-of-type(1){
    order: 2;
  }
}
#CodigoYColor{
  //margin-right: 10em;
}
}

@media screen and (min-width:993px){
    .columnas {
      height: min-content;
    }

    .columnas ion-col{
      width: 25%;
    }
    .columnas ion-col:nth-of-type(2){
      order: 1;
    }
    .columnas ion-col:nth-of-type(1){
      order: 2;
    }
    .columnas ion-col:nth-of-type(3){
      order: 3;
    }

    ion-header{
      display: none;
    }
}

.swal-wide{
  width:20%;
  height: 20%;
}

#existenciaDesk{
  display: none;

  .conten-exist{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: .5em 0em;

    .lefti{
      color: #000;
      font-size: 1.2em;  
    }

    .rigti{
      color: #00a3c7;
      font-size: 1.1em;
    }
  }
}

@media screen  and (min-width:993px){
  #caracteristicas{
    //padding-left: 20%;
    //padding-left: 15em;
    //padding-right: 15em;
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #irCarrito{
    display: none;
  }
  #existencia{
    display: none;
  }
  #colorSeleccionado{
    display: none;
  }
}

.deskheader{
  display: none;
}

.deskfooter{
  display: none;
}

.nohay-cont{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  padding: 0.7em 0em;
  border-bottom: 1px solid #f00;
  margin-bottom: 1em;

  .textin{
    color: #f00;
    font-weight: 600;
    font-size: 1.2em;
  }

  i{
    color: #f00;
    font-size: 2.2em;
  }
}

.cont-car{
  width: 70%;
  height: auto;

  table{
    width: 100%;
    height: auto;
  }
  
  ul{
    width: 100%;
    padding: 0em 25%;
  }
  
  td{
    border: 1px solid #cdcdcd;
  }
  
  div{
    width: 100%;
    overflow-x: scroll;
  }
  
  tbody{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  h5{
    span{
      font-size: 1.7em;
    }
  }
  
  p{
    span{
      font-size: 1.4em;
      color: #00a3c7;
    }
  }
  
  hr{
    background-color: #000;
    margin: 2em 0em;
  }
}

.prods-related-cont{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  background-color: #fff;

  .taitl{
    color: #222;
    font-size: 1.8em;
    font-weight: 100;
  }

  #related{
    width: 100%;
    height: auto;
    //margin-top: 2em;

    .swiper-button-next{
        background-color: #fff;
        border: 1px solid rgb(194, 194, 194);
        color: rgb(194, 194, 194);
        width: 3em;
        height: 3em;
        transition: .5s all ease-in-out;
        --swiper-navigation-size: 1em;
        top: calc(50% - 1.5em);
        opacity: 1;
    }

    .swiper-button-prev{
        background-color: #fff;
        border: 1px solid rgb(194, 194, 194);
        color: rgb(194, 194, 194);
        width: 3em;
        height: 3em;
        transition: .5s all ease-in-out;
        --swiper-navigation-size: 1em;
        top: calc(50% - 1.5em);
        opacity: 1;
    }
    
    .swiper-button-next:hover, .swiper-button-prev:hover{
        background-color: rgb(23, 66, 131);
        border: 1px solid rgb(23, 66, 131);
    }
    
    .swiper-button-next{
        right: 0;
    }
    
    .swiper-button-prev{
        left: 0;
    }
    
    .swiper-pagination-bullet{
        width: 1em;
        height: 1em;
        background-color: #000;
        opacity: .8;
    }
    
    .swiper-pagination-bullet-active{
        background-color: rgb(11, 68, 143);
    }

    swiper-slide{
        text-align: center;
        font-size: 18px;
        background: transparent;
          
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        position: relative;

        .all-cont{
            width: 100%;
            height: auto;

            .prod-cont{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                padding: 1em;
                gap: 1em;
                border: 1px solid #eee;
                padding-bottom: 1.5em;

                .offer{
                    position: absolute;
                    top: 1em;
                    left: 1em;
                    color: #fff;
                    background-color: #e67e22;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 10;
                    font-size: .6em;
                    padding: .3em 1em;
                }

                .new{
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    color: #fff;
                    background-color: #00ABF0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 10;
                    font-size: .6em;
                    padding: .3em 1em;
                }

                .foto-cont{
                    width: 167px;
                    height: 167px;
                    background-position: center;
                    background-size: cover;
                    position: relative;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: .4s all ease-in-out;
                    }

                    #uno{
                        opacity: 1;
                    }

                    #dos{
                        opacity: 0;
                    }
                }

                .time-cont{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .1em;

                    .quad-time{
                        width: 25%;
                        height: 2.2em;
                        background-color: #999999;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: .4em;

                        .up{
                            color: #fff;
                            font-weight: 900;
                            font-size: .8em;
                        }

                        .down{
                            color: #fff;
                            font-weight: 400;
                            font-size: .5em;
                        }
                    }
                }

                .rate-cont{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: .4em;

                    i{
                        font-size: .62em;
                        color: #f1a43e;
                    }
                }

                .prod-name{
                    font-size: 15px;
                    color: #444;
                    font-weight: normal;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                .price-cont{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .now{
                        color: #e74c3c;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .bef{
                        line-height: 20px;
                        padding: 0 5px;
                        display: inline-block;
                        text-decoration: line-through;
                        color: #999;
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }

            .prod-cont:hover{
                //border: 1px solid #aaa;

                .foto-cont{

                    #uno{
                        opacity: 0;
                    }

                    #dos{
                        opacity: 1;
                    }
                }

                .time-cont{

                    .quad-time{

                        background-color: #2e4980;
                    }
                }
            }
        }
    }
  }

  #swiperSupercategory:hover{

      /*.swiper-button-prev{
          opacity: 1;
      }*/

      .swiper-button-prev:hover{
          color: #fff;
      }

      .swiper-button-next{
          opacity: 1;
      }

      .swiper-button-next:hover{
          color: #fff;
      }
  }
}

@media(min-width: 992px){
  .deskheader{
    display: block;
  }

  .deskfooter{
    display: block;
  }

  .titulillo{
    letter-spacing: 0px;
    font-size: 1.8em;
  }

  #existenciaDesk{
    display: block;
  }

  .cont-car{
    height: auto;
    // overflow-y: scroll;
    box-shadow: rgb(3 165 198 / 43%) 0px 2px 8px 0px;
    padding: 1em;
  }
}

@media(max-width: 992px){
  .cont-car{
    width: 100%;
  }
}
#swiperDesk{
    width: 100%;
    height: auto;

    .noExisto{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
     }

     .noExisto2{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
     }

    .swiper-button-next, .swiper-button-prev {

    }

    .swiper {
      width: 100%;
      height: 100%;
    }
    
    swiper-slide {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
    swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }