<section class="" style=" margin-top: 100px;">
    <div class="conten-cont" style="">
        <!-- <p class="texto-azul-desk" style="font-size: 1.5em;"><strong>¡COMPRA Y RECOGE EN ALMACÉN CHIHUAHUA!</strong></p> -->
        <div class="container-1" style="justify-content: center; align-items: flex-start; gap: 5em;">
            <!-- Imagen del Banner -->
            <div class="banner-container" style="align-items: center;width: auto;height: auto;padding-right: 0em;">
                <img src="/assets/img/clasesCreativas/01-Banner-Clases-Landingpage.webp" alt=""
                    style="max-width: 100%;height: auto;">
            </div>
            <p class="main-title" style="font-size: 3.2em;text-align: center;color: #606060;">¡¡¡Celebremos juntos el
                9no aniversario de Lideart!!!</p>
            <p class="regular-text" style="font-size: 1.7em;color: #606060;padding: 0em 4em;">Te invitamos a disfrutar
                de nuestras <strong style="color: #0fa3ca;">clases creativas gratuitas por Zoom.</strong></p>
            <p class="regular-text" style="font-size: 1.7em;color: #606060;padding: 0em 4em;">Es nuestra forma de
                agradecer tu apoyo durante estos años.</p>
            <p class="regular-text" style="font-size: 1.7em;color: #606060;padding: 0em 4em;">¡No te pierdas la
                oportunidad de aprender, inspirarte y crear con nosotros!</p>

            <div class="banner-container-d"
                style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 0px 6.7em;">
                <div class="banner-container-2"
                    style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                    <img src="/assets/img/clasesCreativas/02-Banner-Clases-Landingpage.webp" alt=""
                        style="width: 90%;height: auto;">
                    <a href="https://us06web.zoom.us/j/86910479974" class="regular-text-d"
                        style="color: #000000;text-align: justify;font-weight: 100;font-size: 1.5em;margin-left: 4px;"><strong>https://us06web.zoom.us/j/86910479974</strong></a>
                </div>
                <div class="banner-container-2"
                    style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                    <img src="/assets/img/clasesCreativas/03-Banner-Clases-Landingpage1.webp" alt=""
                        style="width: 90%;height: auto;">
                    <a href="https://us06web.zoom.us/j/87359727574" class="regular-text-d"
                        style="color: #000000;text-align: justify;font-weight: 100;font-size: 1.5em;margin-left: 4px;"><strong>https://us06web.zoom.us/j/87359727574</strong></a>
                </div>
            </div><br><br><br><br>

            <div class="banner-container-d"
                style="text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: center;padding: 0px 6.7em;">
                <div class="banner-container-2"
                    style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                    <img src="/assets/img/clasesCreativas/04-Banner-Clases-Landingpage1.webp" alt=""
                        style="width: 90%;height: auto;">
                    <a href="https://us06web.zoom.us/j/87655074172" class="regular-text-d"
                        style="color: #000000;text-align: justify;font-weight: 100;font-size: 1.5em;margin-left: 4px;"><strong>https://us06web.zoom.us/j/87655074172</strong></a>
                </div>
                <div class="banner-container-2"
                    style="flex-direction: column;text-align: justify;height: auto;display: flex;justify-content: flex-start;align-items: flex-start;gap: .7em;">
                    <img src="/assets/img/clasesCreativas/05-Banner-Clases-Landingpage.webp" alt=""
                        style="width: 90%;height: auto;">
                    <a href="https://us06web.zoom.us/j/82236698930" class="regular-text-d"
                        style="color: #000000;text-align: justify;font-weight: 100;font-size: 1.5em;margin-left: 4px;"><strong>https://us06web.zoom.us/j/82236698930</strong></a>
                </div>
            </div><br><br><br><br>

            <div class="banner-container-2"
                style="flex-direction: column;text-align: center;height: auto;display: flex;justify-content: center;align-items: center;gap: .7em;">
                <img class="banner" src="/assets/img/clasesCreativas/06-Banner-Clases-Landingpage.webp" alt=""
                    style="width: 40%;height: auto;">
                <a href="https://us06web.zoom.us/j/81946620992" class="regular-text-d"
                    style="color: #000000;text-align: justify;font-weight: 100;font-size: 1.5em;margin-left: 4px;"><strong>https://us06web.zoom.us/j/81946620992</strong></a>
            </div><br><br><br><br>
        </div>
    </div>
</section>


<img src="https://new.lideart.com.mx/img/27567-large_default/cartulina-liso-verde-limon-12x12-pulg.jpg">