import { CommonModule, isPlatformBrowser } from '@angular/common';
import { afterRender, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, ToastController } from '@ionic/angular';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';
import { Title } from '@angular/platform-browser';
import { ClipboardModule, Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-wishlist',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, IonicModule, ClipboardModule],
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
  providers: [ServiciowishlistService, LoginService, ApiProductosService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WishlistComponent implements OnInit {

  public invitarIs = false;
  public lecturaIs = false;
  public veIs = false;
  public correoIs = false;
  totalwish = 0;
  listaWishlist: any = [];
  usuario!: Usuario;
  wishlistSKU: any;
  link: any;
  cargando = false;
  respaq: any;
  nombre: any;
  apellido: any;
  datosdelmorro: any = []
  id_customer: any;
  notiene = 0;

  @ViewChild('invitarCont') invitarCont!: ElementRef<HTMLInputElement>;
  @ViewChild('lectura') lectura!: ElementRef<HTMLInputElement>;
  @ViewChild('ve') ve!: ElementRef<HTMLInputElement>;
  @ViewChild('mid') mid!: ElementRef<HTMLInputElement>;
  @ViewChild('midl') midl!: ElementRef<HTMLInputElement>;
  @ViewChild('midl2') midl2!: ElementRef<HTMLInputElement>;
  @ViewChild('midl3') midl3!: ElementRef<HTMLInputElement>;
  @ViewChild('copy') copy!: ElementRef<HTMLInputElement>;
  @ViewChild('correo') correo!: ElementRef<HTMLInputElement>;


  constructor(
    private renderer2: Renderer2,
    private servicioWishlist: ServiciowishlistService,
    private usuarioService: LoginService,
    private toastController: ToastController,
    private apiProductos: ApiProductosService,
    private router: Router, @Inject(PLATFORM_ID)
    private platformId: Object,
    private titleService: Title,
    private clipboard: Clipboard
  ) {

  }

  ngOnInit() {
    this.titleService.setTitle("Wishlist");
    try {
      if (isPlatformBrowser(this.platformId)) {

        this.cargando = true;
        this.usuarioService.usuario.subscribe((res: any) => {
          this.usuario = res;
          this.nombre = this.usuario.usuario
          this.servicioWishlist.recuperaWishlist(this.usuario.usuario).subscribe(res => {

            try {
              this.listaWishlist = res;
              if (this.listaWishlist == 1) {
                this.notiene = 1;
                this.listaWishlist = [];
                this.cargando = false;
              } else {
                this.notiene = 0;
                this.link = this.listaWishlist[0].link;

                this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(datosUser => {
                  this.datosdelmorro = datosUser;
                  this.id_customer = this.datosdelmorro.id_customer;
                  this.cargando = false;
                })
              }
            } catch (error) {
              this.cargando = false;
            }
          });
        });

      }
      this.cargando = false;
    } catch (error) {
      this.cargando = false;
    }

  }

  invitar() {
    if (this.invitarIs == false) {
      this.renderer2.setStyle(this.invitarCont.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.invitarCont.nativeElement, 'z-index', '1');
      this.invitarIs = true;
    } else if (this.invitarIs == true) {
      this.renderer2.setStyle(this.invitarCont.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.invitarCont.nativeElement, 'z-index', '-1');
      this.invitarIs = false;
    }
  }

  opclectura() {
    if (this.lecturaIs == false) {
      this.renderer2.setStyle(this.lectura.nativeElement, 'color', '#00abcf');

      if (this.veIs == true) {
        this.opcve();
      }

      this.lecturaIs = true;
    } else if (this.lecturaIs == true) {
      this.renderer2.setStyle(this.lectura.nativeElement, 'color', 'rgb(94, 94, 94)');

      this.lecturaIs = false;
    }
    this.midRev();
  }

  opcve() {
    if (this.veIs == false) {
      this.renderer2.setStyle(this.ve.nativeElement, 'color', '#00abcf');

      if (this.lecturaIs == true) {
        this.opclectura();
      }

      this.veIs = true;
    } else if (this.veIs == true) {
      this.renderer2.setStyle(this.ve.nativeElement, 'color', 'rgb(94, 94, 94)');

      this.veIs = false;
    }
    this.midRev();
  }

  midRev() {
    if (this.lecturaIs == true || this.veIs == true) {
      this.renderer2.setStyle(this.mid.nativeElement, 'padding', '1rem 0rem');
      this.renderer2.setStyle(this.midl.nativeElement, 'display', 'flex');
      this.renderer2.setStyle(this.midl2.nativeElement, 'display', 'flex');
      this.renderer2.setStyle(this.midl3.nativeElement, 'display', 'flex');
    }
    if (this.lecturaIs == false && this.veIs == false) {
      this.renderer2.setStyle(this.mid.nativeElement, 'padding', '0rem 0rem');
      this.renderer2.setStyle(this.midl.nativeElement, 'display', 'none');
      this.renderer2.setStyle(this.midl2.nativeElement, 'display', 'none');
      this.renderer2.setStyle(this.midl3.nativeElement, 'display', 'none');
    }
  }

  copiar() {
    this.renderer2.setStyle(this.copy.nativeElement, 'display', 'block');

    //this.clipboard.copy(this.link);

    setTimeout(() => {

      this.renderer2.setStyle(this.copy.nativeElement, 'display', 'none');

    }, 500);

  }

  enviarCorreo() {
    if (this.correoIs == false) {
      this.renderer2.setStyle(this.correo.nativeElement, 'opacity', '1');
      this.renderer2.setStyle(this.correo.nativeElement, 'z-index', '1');
      this.correoIs = true;
    } else if (this.correoIs == true) {
      this.renderer2.setStyle(this.correo.nativeElement, 'opacity', '0');
      this.renderer2.setStyle(this.correo.nativeElement, 'z-index', '-5');
      this.correoIs = false;
    }
  }



  eliminarWishlist(sku: any) {
    var jsonWishlist = {
      "usuario": this.usuario.usuario,
      "sku": sku

    }
    this.servicioWishlist.eliminarWishlist(jsonWishlist).subscribe(data => {
      if (data == 0) {
        this.presentToast()
        setTimeout(() => {
          location.reload();
        }, 1000);

      }
    })

  }

  comprar(item: any, sku: any, link_rewrite: any) {
    this.apiProductos.esPaquete(sku).subscribe(paquetillo => {
      this.respaq = paquetillo;
      if (this.respaq == 1) {
        this.cargando = false;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/paquetes/${item}-${sku}` + ".html");
        });
      }
      if (this.respaq == 0) {
        this.cargando = false;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/${link_rewrite}/${item}-${link_rewrite}` + ".html");
        });
      }


    })
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Se elimino el producto de tu wishlist',
      color: "success",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  copyHeroName() {
    this.clipboard.copy('https://lideart.com.mx/mesa-regalos/' + this.id_customer);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "¡Link Copiado! Puedes compartir el enlace.",
      showConfirmButton: false,
      timer: 1500
    });
  }

}
