<section class="lidepuntosDesk">
  <div class="conten-cont">
    <p class="texto-azul-desk">¿Qué son los <strong><br> LIDEPUNTOS?</strong></p>
    <p class="regular-text">Son puntos acumulativos que puedes obtener al hacer tus compras en la tienda Lideart y canjear por descuentos en tus próximas compras. <br> <br> Ademas puedes obtener puntos siguiendonos en nuestras redes sociales, y el día de tu cumpleaños recibe puntos de regalo.</p>
    <p class="texto-azul">¿Cuáles beneficios obtengo con los <strong> <br> LIDEPUNTOS? </strong></p>
    <P class="regular-text">Al realizar compras en la tienda Lideart obtienes LIDEPUNTOS de acuerdo al monto comprado, y en tus próximas compras tienes la libertad de hacer uso
      de tus LIDEPUNTOS para obtener descuentos, entre mas compres, mas LIDEPUNTOS obtienes y mas descuentos obtienes.</P>
    <p class="texto-azul2"> <img src="/assets/img/LidePuntos/MN.png" alt=""> <br> 
      Cada peso comprado ES UN LIDEPUNTO  </p>
    <p class="texto-azul"> ¿Qué descuentos obtengo?</p>
    <p class="regular-text"><strong>10,000 LIDEPUNTOS =</strong> <br> Al 5% de descuento de tu compra</p>
    <p class="regular-text"><strong>30,000 LIDEPUNTOS =</strong> <br> Al 10% de descuento de tu compra</p>
    <p class="texto-azul">¿Como obtengo los <strong> <br> LIDEPUNTOS?</strong></p>
    <p class="regular-text">Es muy fácil obtener LIDEPUNTOS te decimos como a continuacion:</p>

    <div class="grida1" style="width: 100%; height: auto; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; gap: 1em;">
      <div class="line" style="width: 20em; height: auto; display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 1em;">
        <img class="center-rows" src="/assets/img/LidePuntos/FB.png" alt="" style="width: 5em; height: auto;">
        <span style="color: #000;"><strong> Compártenos en Facebook</strong> <br> y obtén 50 LIDEPUNTOS</span>
      </div>
      <div class="line" style="width: 20em; height: auto; display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 1em;">
        <img class="center-rows" src="/assets/img/LidePuntos/IG.png" alt="" style="width: 5em; height: auto;">
        <span style="color: #000;"><strong> Compártenos en Instagram</strong> <br> y obtén 20 LIDEPUNTOS</span>
      </div>
      <div class="line" style="width: 20em; height: auto; display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 1em;">
        <img class="center-rows" src="/assets/img/LidePuntos/Like.png" alt="" style="width: 5em; height: auto;">
        <span style="color: #000;"><strong> Danos like en Facebook </strong> <br> y obtén 50 LIDEPUNTOS</span>
      </div>
      <div class="line" style="width: 20em; height: auto; display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 1em;">
        <img class="center-rows" src="/assets/img/LidePuntos/BD.png" alt="" style="width: 5em; height: auto;">
        <span style="color: #000;"><strong> Si es tu cumpleaños </strong> <br> obtén 20 LIDEPUNTOS</span>
      </div>
    </div>

    <p class="texto-azul"> ¿Cómo usar los <br> <strong>LIDEPUNTOS?</strong> </p>
    <p class="regular-text">Para obtener los beneficios de los Lidepuntos deberás ingresar a tu cuenta en la pagina de <strong>lideart.com.mx</strong></p>

    <div class="bottom-Border"></div>

    <p class="regular-text">Después de ingresar a tu cuenta, podras acceder al menu de Lidepuntos que se encuentra en la parte inferior izquierda, ahi encontraras los siguientes beneficios:</p>

    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/1.png" alt=""></ion-col>
        </ion-col>
        <ion-col>
          <p class="texto-azul"> Your Lidepuntos </p>
          <p class="regular-text">Es la cantidad de puntos que tienes disponibles. Si eres cliente nuevo tu registro de puntos estará vacío hasta que realices tu primera compra</p>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/2.png" alt=""></ion-col>
        </ion-col>
        <ion-col>
          <p class="texto-azul"> Next reward </p>
          <p class="regular-text">Son las recompensas que tienes disponibles para usar segun la cantidad de Lidepuntos que poseas. Al hacer click en "Redeem" se te otorgará un código que podrás aplicar en tu próxima orden de compra. En caso de no contar con los suficientes puntos, el botón "Redeem" no aparecerá.</p>
        </ion-col>
      </ion-row>
    </ion-grid>

      <p class="corners1"> <strong> Redeem </strong> <br> En Redeem se encuentra el codigo de descuento que podrás aplicar en tu próxima orden de compra, dando clic en "Apply code" o copiando directamente el código. </p>
      <br>
      <p class="corners2"> <strong> SOLO CLIENTES CON UN MÍNIMO DE 10,000 PUNTOS PODRÁN CANJEAR </strong></p>
      <br>
    <div class="bottom-Border"></div>

    <p class="texto-azul">Maneras de obtener <br> <strong> LIDEPUNTOS </strong></p>

    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/3.png" alt=""></ion-col>
        </ion-col>
        <ion-col>
          <p class="texto-azul"> Ways to earn </p>
          <p class="regular-text">Esta opción muestra las maneras de obtener puntos en nuestro sistema.</p>
        </ion-col>
      </ion-row>
    </ion-grid>

    <p class="texto-azul">Place an order</p>
    <p class="regular-text">Por cada $1 de compra se te dara <strong>1 LIDEPUNTO</strong></p>
    <p class="texto-azul">Celebrate a Birthday</p>
    <p class="regular-text">En tu cumpleaños obtén <strong>20 LIDEPUNTOS</strong></p>
    <p class="texto-azul">Follow on Instagram</p>
    <p class="regular-text">Sigue nuestro Instagram y obtén <strong>20 LIDEPUNTOS</strong></p>
    <p class="texto-azul">Follow on Facebook</p>
    <p class="regular-text">Sigue nuestra fanpage y obtén <strong>50 LIDEPUNTOS</strong></p>
    <p class="texto-azul">Share on Facebook</p>
    <p class="regular-text">Comparte nuestra fanpage y obtén <strong>50 LIDEPUNTOS</strong></p>

    <div class="bottom-Border"></div>

    <p class="texto-azul"> recompensas con tus <br> <strong> LIDEPUNTOS </strong> </p>

    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/4.png" alt=""></ion-col>
        </ion-col>
        <ion-col>
          <p class="texto-azul"> Ways to Redeem </p>
          <p class="regular-text">Esta opción te muestra las recompensas que puedes obtener con tus puntos.</p>
        </ion-col>
      </ion-row>
      <img style="width: 20em; height: auto;" src="/assets/img/LidePuntos/Lidepuntos.png" alt="">
      <p class="corners2"> <strong> EN ESTE CASO SÓLO HAY RECOMPENSAS AL CANJEAR 10,000 Y 30,000 LIDEPUNTOS </strong></p>
    </ion-grid>

    <p class="texto-azul">Aplicación de código <br><strong>DE DESCUENTO</strong></p>
    <p class="texto-azul"> Apply code</p>
    <p class="regular-text">Al hacer clic en "Apply code" este se aplicará automáticamente en el formato de pago de la orden del cliente.</p>

    <p class="corners2"> Al momento de pagar, podrás ver el código aplicado, la cantidad que se descuenta y el total a pagar con IVA incluido.</p>
  </div>
</section>

<div class="desaparece-desk">
  <p class="texto-azul">¿Qué son los <strong><br> LIDEPUNTOS?</strong></p>
  <p class="regular-text">Son puntos acumulativos que puedes obtener al hacer tus compras en la tienda Lideart y canjear por descuentos en tus próximas compras. <br> <br> Ademas puedes obtener puntos siguiendonos en nuestras redes sociales, y el día de tu cumpleaños recibe puntos de regalo.</p>
  <p class="texto-azul">¿Cuáles beneficios obtengo con los <strong> <br> LIDEPUNTOS? </strong></p>
  <P class="regular-text">Al realizar compras en la tienda Lideart obtienes LIDEPUNTOS de acuerdo al monto comprado, y en tus próximas compras tienes la libertad de hacer uso
    de tus LIDEPUNTOS para obtener descuentos, entre mas compres, mas LIDEPUNTOS obtienes y mas descuentos obtienes.</P>
  <p class="texto-azul2"> <img src="/assets/img/LidePuntos/MN.png" alt=""> <br> 
    Cada peso comprado ES UN LIDEPUNTO  </p>
  <p class="texto-azul"> ¿Qué descuentos obtengo?</p>
  <p class="regular-text"><strong>10,000 LIDEPUNTOS =</strong> <br> Al 5% de descuento de tu compra</p>
  <p class="regular-text"><strong>30,000 LIDEPUNTOS =</strong> <br> Al 10% de descuento de tu compra</p>
  <p class="texto-azul">¿Como obtengo los <strong> <br> LIDEPUNTOS?</strong></p>
  <p class="regular-text">Es muy fácil obtener LIDEPUNTOS te decimos como a continuacion:</p>

  <ion-grid>
    <ion-row>
      <ion-col></ion-col>
      <ion-col> <img class="center-rows" src="/assets/img/LidePuntos/FB.png" alt=""></ion-col>
      <ion-col> <p class="center-rows"> <strong> Compártenos en Facebook</strong> <br> y obtén 50 LIDEPUNTOS </p></ion-col>  
      <ion-col></ion-col>
    </ion-row>

    <ion-row>
      <ion-col></ion-col>
      <ion-col> <img class="center-rows" src="/assets/img/LidePuntos/IG.png" alt=""></ion-col>
      <ion-col> <p class="center-rows"> <strong> Compártenos en Instagram</strong> <br> y obtén 20 LIDEPUNTOS </p></ion-col>  
      <ion-col></ion-col>
    </ion-row>

    <ion-row>
      <ion-col></ion-col>
      <ion-col> <img class="center-rows" src="/assets/img/LidePuntos/Like.png" alt=""></ion-col>
      <ion-col> <p class="center-rows"> <strong> Danos like en Facebook </strong> <br> y obtén 50 LIDEPUNTOS </p></ion-col>  
      <ion-col></ion-col>
    </ion-row>

    <ion-row>
      <ion-col></ion-col>
      <ion-col> <img class="center-rows" src="/assets/img/LidePuntos/BD.png" alt=""></ion-col>
      <ion-col> <p class="center-rows"> <strong> Si es tu cumpleaños </strong> <br> obtén 20 LIDEPUNTOS </p></ion-col>  
      <ion-col></ion-col>
    </ion-row>
  </ion-grid>

  <p class="texto-azul"> ¿Cómo usar los <br> <strong>LIDEPUNTOS?</strong> </p>
  <p class="regular-text">Para obtener los beneficios de los Lidepuntos deberás ingresar a tu cuenta en la pagina de <strong>lideart.com.mx</strong></p>

  <div class="bottom-Border"></div>

  <p class="regular-text">Después de ingresar a tu cuenta, podras acceder al menu de Lidepuntos que se encuentra en la parte inferior izquierda, ahi encontraras los siguientes beneficios:</p>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/1.png" alt=""></ion-col>
      </ion-col>
      <ion-col>
        <p class="texto-azul"> Your Lidepuntos </p>
        <p class="regular-text">Es la cantidad de puntos que tienes disponibles. Si eres cliente nuevo tu registro de puntos estará vacío hasta que realices tu primera compra</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/2.png" alt=""></ion-col>
      </ion-col>
      <ion-col>
        <p class="texto-azul"> Next reward </p>
        <p class="regular-text">Son las recompensas que tienes disponibles para usar segun la cantidad de Lidepuntos que poseas. Al hacer click en "Redeem" se te otorgará un código que podrás aplicar en tu próxima orden de compra. En caso de no contar con los suficientes puntos, el botón "Redeem" no aparecerá.</p>
      </ion-col>
    </ion-row>
  </ion-grid>

     <p class="corners1"> <strong> Redeem </strong> <br> En Redeem se encuentra el codigo de descuento que podrás aplicar en tu próxima orden de compra, dando clic en "Apply code" o copiando directamente el código. </p>
     <br>
     <p class="corners2"> <strong> SOLO CLIENTES CON UN MÍNIMO DE 10,000 PUNTOS PODRÁN CANJEAR </strong></p>
     <br>
  <div class="bottom-Border"></div>

  <p class="texto-azul">Maneras de obtener <br> <strong> LIDEPUNTOS </strong></p>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/3.png" alt=""></ion-col>
      </ion-col>
      <ion-col>
        <p class="texto-azul"> Ways to earn </p>
        <p class="regular-text">Esta opción muestra las maneras de obtener puntos en nuestro sistema.</p>
      </ion-col>
    </ion-row>
  </ion-grid>

  <p class="texto-azul">Place an order</p>
  <p class="regular-text">Por cada $1 de compra se te dara <strong>1 LIDEPUNTO</strong></p>
  <p class="texto-azul">Celebrate a Birthday</p>
  <p class="regular-text">En tu cumpleaños obtén <strong>20 LIDEPUNTOS</strong></p>
  <p class="texto-azul">Follow on Instagram</p>
  <p class="regular-text">Sigue nuestro Instagram y obtén <strong>20 LIDEPUNTOS</strong></p>
  <p class="texto-azul">Follow on Facebook</p>
  <p class="regular-text">Sigue nuestra fanpage y obtén <strong>50 LIDEPUNTOS</strong></p>
  <p class="texto-azul">Share on Facebook</p>
  <p class="regular-text">Comparte nuestra fanpage y obtén <strong>50 LIDEPUNTOS</strong></p>

  <div class="bottom-Border"></div>

  <p class="texto-azul"> recompensas con tus <br> <strong> LIDEPUNTOS </strong> </p>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-col> <img style="padding: 15%; align-items: center;" src="/assets/img/LidePuntos/4.png" alt=""></ion-col>
      </ion-col>
      <ion-col>
        <p class="texto-azul"> Ways to Redeem </p>
        <p class="regular-text">Esta opción te muestra las recompensas que puedes obtener con tus puntos.</p>
      </ion-col>
    </ion-row>
    <img style="width: 100%; padding: 45px;" src="/assets/img/LidePuntos/Lidepuntos.png" alt="">
    <p class="corners2"> <strong> EN ESTE CASO SÓLO HAY RECOMPENSAS AL CANJEAR 10,000 Y 30,000 LIDEPUNTOS </strong></p>
  </ion-grid>

  <p class="texto-azul">Aplicación de código <br><strong>DE DESCUENTO</strong></p>
  <p class="texto-azul"> Apply code</p>
  <p class="regular-text">Al hacer clic en "Apply code" este se aplicará automáticamente en el formato de pago de la orden del cliente.</p>

  <p class="corners2"> Al momento de pagar, podrás ver el código aplicado, la cantidad que se descuenta y el total a pagar con IVA incluido.</p>
</div>
