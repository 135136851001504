img.ng-lazyloaded {
    animation: fadein 1.5s;
  }
  
  a{
    text-decoration: none;
  }
  
  ion-button {
    --box-shadow: transparent !important;
  }
  
  button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
  
  ion-searchbar {
    --icon-color: #1ca3c9;
    width: 95%;
    margin-left: 10px;
  }
  
  .search-container {
    width: 90vw;
    right: 5vw;
    height: auto;
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;
  }
  
  .line-filter {
    text-align: center;
    line-height: 20px;
    background: #70c2d1;
    padding: 0;
    margin: 0;
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .header-ios ion-toolbar:last-of-type {
    --border-width: 0;
  }
  .header-translucent-ios ion-toolbar {
    --opacity: 1;
  }
  
  ion-img::part(image) {
  width: 100px;
  height: 100px;
  }
  
  
  .redes img{
   height: 30px;
   width: 30px;
  }
  
  .redes{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .marcas img{
    height: 150px;
    width: 150px;
    text-align: center;
    margin: 0px;
    
  }
  
  .marcas{
    text-align: center;
  }
  
  .bottomBanner img{
    height: auto;
    width: 100%;
  }
  
  .imagensita{
      height: auto!important;
      width: auto!important;
  }
  
  ion-searchbar {
    --icon-color: #1ca3c9;
    width: 95%;
    margin-left: 10px;
  }
  
  .iconos{
    height: 13px;
    width: 13px;
    margin-left: 4px;
    margin-right: 4px;
  }
  
  
  //  .imgItem{
  //    border:2px  solid #1ca3c9;
  //    margin-left: 5px;
  //    margin-bottom: 5px;
  //  }
  
  
  .fixin{
  width: 100%; 
  height: 8.5em;
  margin: 0; 
  // margin-top: .4em 0; 
  padding: 0em 0.5em; 
  text-align: center;
  text-transform: uppercase; 
  font-weight: normal; 
  font-size: .9em; 
  overflow-wrap: break-word; 
  overflow: hidden;
  
  }
  
  .catalogoDesk{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 15em;
  padding-bottom: 4em;
  
  .contDesk{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 2em 0em;
    gap: 2em;
  
    .title-cont{
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
  
      h1{
        color: #1e7285;
        font-weight: 100;
        font-size: 2.2em;
        text-align: center;
      }
    }
  
    .conten-cats{
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2em;
  
      .cardin{
        width: 16em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 1em 0em;
        //border-bottom: 1px solid #0ba7c7;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: .4s all ease-in-out;
        gap: 1em;
  
        .cat-name{
          color: #00a3c7;
          font-weight: 100;
          text-align: center;
          padding: 0em 1em;
        }
  
        .contImg{
          width: 100%;
          height: 15em;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1em;
  
          img{
            width: 100%;
            max-height: 100%;
          }
        }
  
        .pric{
          color: #222;
          font-weight: 100;
          text-align: center;
          padding: 0em 1em;
        }
      }
  
      .cardin:hover{
        box-shadow: rgba(23, 171, 202, 0.5) 0px 6px 24px;
      }
    }
  }
  }
  
  
  
  
  @media(min-width:992px){
  .display-grid{
    display: grid;
    grid-template-columns: repeat(1,1fr);   
  }  
  
  .cont-categoria-cel{
    display: none;
  }
  
  ion-header{
    display: none;
  }
  
  .catalogoDesk{
    display: flex;
  }
  }
  
  .catalogoDesk::-webkit-scrollbar {
  width: .35em;
  background-color: transparent;
  height: .7em;
  }
  
  .catalogoDesk::-webkit-scrollbar-thumb {
  background-color: #00a2c779;
  //border-radius: 1vh;
  width: 0.25em;
  }
  
  
  .loader {
  display: flex;
  width: 100%;
  height: 100%;
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: fixed;
  top: calc(0%);
  left: calc(0%);
  background-color: white;
  z-index: 9999999;
  
  
  
  img{
    width: 200px;
    height: auto;
  
  }
  
  }
  
  .container-catalogo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5rem;
  
  @media screen and (max-width: 600px) {
    margin: -0.5rem;
  }
  }
  
  .card-catalogo {
  background-color: #f5f8fc;
  box-shadow: 0 0 transparent, 0 0 transparent,
    0 0.375rem 0.375rem -0.125rem rgba(168, 179, 207, 0.4);
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(82, 88, 102, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 330px;
  height: auto;
  transition: all 0.2s ease;
  margin: 2rem;
  
  &__header {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0 0.5rem 0.5rem;
  
    .header__img {
      height: 2rem;
      width: 2rem;
      object-fit: cover;
      border-radius: 50%;
    }
  
    .header__title {
      font-size: 1.0625rem;
      line-height: 1.375rem;
      color: #0e1217;
      font-weight: 700;
      margin: 0.5rem;
    }
  }
  
  &__body {
    margin: 0 0.5rem;
  
    .body__text {
      color: #525866;
      font-size: 0.8125rem;
    }
  
    .body__img {
      height: 10rem;
      margin: 0.5rem 0;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;
        border-radius: 0.75rem;
      }
    }
  }
  
  &__footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0.5rem;
  }
  
  &:hover {
    border-color: rgba(82, 88, 102, 0.4);
  }
  }
  
  ion-icon {
  font-size: 1.5rem;
  color: #525866;
  cursor: pointer;
  transition: color 0.2s ease;
  
  &:hover {
    &:nth-of-type(1) {
      color: #15ce5c;
    }
    &:nth-of-type(2) {
      color: #15ce5c;
    }
    &:nth-of-type(3) {
      color: #fa6620;
    }
  }
  }
  
  .skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
  }
  
  .skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  }
  
  .skeleton-text__body {
  width: 75%;
  }
  
  .skeleton-footer {
  width: 30%;
  }
  