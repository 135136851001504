import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClient } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-form-invitado',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    NgIf,
    MatAutocompleteModule,
    NgFor,
    RouterModule
  ],
  templateUrl: './form-invitado.component.html',
  styleUrls: ['./form-invitado.component.scss'],
})
export class FormInvitadoComponent implements OnInit {
  @Output() formSubmitted = new EventEmitter<any>();

  formInvitado!: FormGroup;
  isEnvio = false;
  cpResponse!: cpResponse;
  options: listaColonias[] = [];
  isCode = false;
  isCpNoEncontrado = false;

  private http: HttpClient = inject(HttpClient);

  constructor() { }

  ngOnInit() {
    this.formInvitado = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
      envio: new FormControl(false),
      direccion: new FormControl('', [Validators.minLength(10)]),
      colonia: new FormControl('', [Validators.minLength(5)]),
      ciudad: new FormControl('', [Validators.minLength(5)]),
      estado: new FormControl('', [Validators.minLength(5)]),
      cp: new FormControl('', [Validators.minLength(5)]),
    });

    this.formInvitado.get('envio')!.valueChanges.subscribe((value) => {
      if (value) {
        this.isEnvio = true;
        this.formInvitado.get('direccion')?.setValidators([Validators.required, Validators.minLength(10)]);
        this.formInvitado.get('colonia')?.setValidators([Validators.required, Validators.minLength(5)]);
        this.formInvitado.get('ciudad')?.setValidators([Validators.required, Validators.minLength(5)]);
        this.formInvitado.get('estado')?.setValidators([Validators.required, Validators.minLength(5)]);
        this.formInvitado.get('cp')?.setValidators([Validators.required, Validators.minLength(5)]);
      } else {
        this.isEnvio = false;
        this.formInvitado.get('direccion')?.clearValidators();
        this.formInvitado.get('colonia')?.clearValidators();
        this.formInvitado.get('ciudad')?.clearValidators();
        this.formInvitado.get('estado')?.clearValidators();
        this.formInvitado.get('cp')?.clearValidators();
      }
      this.formInvitado.get('direccion')?.updateValueAndValidity();
      this.formInvitado.get('colonia')?.updateValueAndValidity();
      this.formInvitado.get('ciudad')?.updateValueAndValidity();
      this.formInvitado.get('estado')?.updateValueAndValidity();
      this.formInvitado.get('cp')?.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.formInvitado.valid) {
      this.formSubmitted.emit(this.formInvitado.value);
      console.log(this.formInvitado.value);
    }
  }

  getCP() {
    const cpValue = this.formInvitado.get('cp')?.value as string;
    if (cpValue.length != 5) return;
    this.http.get<cpResponse>('https://sepomex.icalialabs.com/api/v1/zip_codes?zip_code=' + cpValue).subscribe((response) => {
      if (response.meta.pagination.total_objects != 0) {
        this.formInvitado.get('estado')?.setValue(response.zip_codes[0].d_estado);
        this.formInvitado.get('ciudad')?.setValue(response.zip_codes[0].d_ciudad);
        this.isCode = true;
        this.isCpNoEncontrado = false;
        this.options = response.zip_codes.map((zipCode) => {
          return {
            id: zipCode.id,
            nombre_colonia: zipCode.d_asenta,
          };
        });
      } else {
        this.isCpNoEncontrado = true;
        console.log('CP no encontrado');
      }
      this.cpResponse = response;
      console.log(this.cpResponse);
    });
  }

} import { NgIf } from '@angular/common';
import { NgFor } from '@angular/common';
import { cpResponse, listaColonias } from './types';

